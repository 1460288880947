import { FC } from 'react';

import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';

import InputTextField from 'components/inputfields/InputTextField';
import { IImage } from 'components/images/ImageSorter';

interface ShortTextProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  approve?: boolean;
  sortedImages: IImage[];
  coopExportProduct?: IProduct;
  rootCategory?: IFoodlaCategoryOption;
}

const ShortText: FC<ShortTextProps> = ({ state, setState, approve }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  return (
    <>
      <InputTextField
        style={{ marginTop: 7 }}
        label="Beskriv produkten med max 150 ord"
        multiline
        disabled={state?.isMagazine}
        inputName="short_text"
        onChange={handleChange}
        setState={setState}
        approve={approve}
        state={state}
        multilineRows={2}
        multilineMax={20}
        value={state.short_text}
        withLabel
        inputClassName="data-hj-allow"
        labelOptions={{
          heading: 'Produktbeskrivning',
          required: true,
          text: 'Tänk på att säljande utryck som ger intrycket av att en viss produkt har bättre kvalitet än andra behöver förklaras för att inte vilseleda konsumenten.',
          link: 'https://kontrollwiki.livsmedelsverket.se/artikel/36/saljande-uttryck',
        }}
      />
    </>
  );
};

export default ShortText;
