import { isNil, isEmpty } from 'ramda';
import isNaN from 'lodash/isNaN';
import { countries } from 'constants/domain';

export const isNotDefined = (v: any) => isNil(v) || isEmpty(v);
export const isDefined = (v: any) => !isNil(v) && !isEmpty(v);
export const useOrElse = (v: any, av: any) => (isDefined(v) ? v : av);

export const toSafeNumber = (n: string|number, an: number, dec?: number) => {
  if (isNotDefined(n) || isNaN(n)) return an;
  try {
      let ret = isDefined(dec)
          ? floatWithDec(n, dec) 
          : typeof n === 'string' ? parseInt(n) : n;
      return ret;
  } catch (e) {
      return an;
  }
};

export const hasValue = (value: any) => {
  return isDefined(value) && (
    (typeof(value) === "string" && value.trim() !== "")
    || (typeof(value) === "number")
  );
};

export const toSafeFloat = (f: string|number, af: number) => {
  let val = undefined;
  if (f && typeof(f) === "string") {
    val = f.replace(",", ".");
  } else if (typeof(f) === "number") {
    val = f;
  }
  if (isNotDefined(val) || (typeof(val) === "string" && isNaN(val.replace(".", "")))) return af;
  try {
      if (typeof val === 'string') return parseFloat(val);
      return val;
  } catch (e) {
    return af;
  }
};

export const floatWithDec = (f: string|number, dec?: number) => {
  if (isNotDefined(f) || isNaN(f)) return undefined;
  let float: number|undefined = undefined;
  if (typeof f === 'string') {
      float = parseFloat(f);
  } else {
      float = f;
  }
  return parseFloat(float.toFixed(dec));
};

export const isNumber = (n: number|string) => !isNaN(n);
export const toReadableCoordinate = (c: number) => toSafeNumber(c, c, 3);

export const parseBoolean = (str: any) => {
  if(typeof str === 'string') {
    return str.toUpperCase() === "TRUE" ? true : str.toUpperCase() === "FALSE" ? false : undefined;
  } else if (typeof str === 'boolean') {
    return str;
  }
  return undefined;
}

export const getCountryCode = (countryName: string) => {
  const { code } = countries.find(({ sv }) => sv === countryName) || {};
  if (code) return code;
  if (countryName === 'Beligen') return 56;
  if (countryName === 'China') return 156;
  return null;
};

export const getCountryName = (countryCode: number) => {
  const { sv } = countries.find(({ code }) => code === countryCode) || {};
  if (sv) return sv;
  if (countryCode === 56) return 'Beligen';
  if (countryCode === 156) return 'China';
  if (countryCode === 528) return 'Holland';
  return null;
};

export const isBlank = (s?: any): boolean => {
  if (isNil(s)) return true;
  if (typeof s === 'number') return false;
  if (typeof s === 'string' && s.trim() === '') return true;
  return false;
};
