import React, {useState} from 'react';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

import ProductCardList from '../../components/product/ProductCardList/ProductCardList';

import {SUPERUSER_FAILED_REVIEW_PRODUCTS} from '../../graphql/queries';
import {EListViewType} from '../../components/product/ProductCardList/types';
import {BasicTitle} from '../../components/typography/Title';

interface ISuperuserFailedProductsProps {
    classes: any;
}

const styles = (theme: any) => ({
    toolbarBox: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
});

const SuperuserFailedProducts = ({classes}: ISuperuserFailedProductsProps) => {
    const [totalProducts, setTotalProducts] = useState(0);

    const queryListDataExtractor = (data: any) => {
        // save initial unsorted total amount
        if (!totalProducts) {
            setTotalProducts(data?.failedProducts?.length || 0);
        }
        return data?.failedProducts;
    };

    return (
        <Paper>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.toolbarBox}>
                    <Typography variant="body1" className={classes.title}>
                        <BasicTitle>
                            Products with Failed Review ({totalProducts})
                        </BasicTitle>
                    </Typography>
                </Box>
            </Toolbar>
            <Divider />
            <ProductCardList
                summaryData={{total: totalProducts}}
                showSearch={true}
                showSorting={true}
                showPagination={false}
                productsQuery={SUPERUSER_FAILED_REVIEW_PRODUCTS}
                queryListDataExtractor={queryListDataExtractor}
                listViewType={EListViewType.LIST}
            />
        </Paper>
    );
};

export default withStyles(styles)(SuperuserFailedProducts);
