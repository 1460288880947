import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import InputTextField from '../inputfields/InputTextField';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#56aba9',
    },
}));

const OrganizationForm = ({
    handleChange,
    handleOnSubmit,
    inputContactInfo,
    inputDeliveryAddress,
    inputGroupIdentifier,
    inputInvoiceAddress,
    inputName,
    isCreatingNew,
}) => {
    const classes = useStyles();
    return (
        <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
            <InputTextField
                inputName="name"
                label="Name"
                value={inputName}
                onChange={handleChange}
                required
                autoFocus
            />
            <InputTextField
                inputName="groupIdentifier"
                label="Full group identifier (implicitly prefixing /organization/)"
                value={inputGroupIdentifier}
                onChange={handleChange}
                required
            />
            <InputTextField
                inputName="invoiceAddress"
                label="Invoice address"
                value={inputInvoiceAddress}
                onChange={handleChange}
                required
            />
            <InputTextField
                inputName="deliveryAddress"
                label="Delivery address"
                value={inputDeliveryAddress}
                onChange={handleChange}
                required
            />
            <InputTextField
                inputName="contactInfo"
                label="Contact information"
                multiline
                value={inputContactInfo}
                onChange={handleChange}
                required
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                {isCreatingNew ? 'Create organization' : 'Update organization'}
            </Button>
        </form>
    );
};

export default OrganizationForm;
