import { FC, HTMLAttributes, useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import Button from 'components/button';

import useDataContext from '../hooks/useDataContext';
import useControlContext from '../hooks/useControlContext';
import ConfirmModal from 'components/ConfirmModal';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 24px',
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #C5C9D8',
    boxShadow: '0px -4px 16px 0px rgba(156, 163, 188, 0.32)',
  },
}));

interface FooterWrapperProps extends HTMLAttributes<HTMLDivElement> {
  isLeftChildren?: boolean;
}

const FooterWrapper: FC<FooterWrapperProps> = ({ isLeftChildren, children }) => {
  const classes = useStyles();
  const { selectedCategoryTypeId, step, onStepChange } = useControlContext();
  const { excelFile, isUploadedSuccessfully, onReset } = useDataContext();

  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [fixedElement, setFixedElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const mainElement = document.getElementById('page-main') as HTMLElement;
    if (!mainElement || !fixedElement) return;

    const observer = new ResizeObserver(() => {
      const { left } = mainElement.getBoundingClientRect();
      fixedElement.style.width = `${mainElement.offsetWidth}px`;
      fixedElement.style.left = `${left}px`;
    });
    observer.observe(mainElement);
    return () => observer.disconnect();
  }, [fixedElement]);

  return (
    <div ref={setFixedElement} className={classes.wrapper}>
      <Box sx={{ fontSize: 16 }}>
        {step !== 1 && (step !== 4 || !isUploadedSuccessfully) && (excelFile?.name || '')}
        {isLeftChildren && children}
      </Box>
      <Box display="flex" gridGap={16}>
        {(!!selectedCategoryTypeId || step > 2) && (
          <Button variant="outlined" onClick={() => setShowConfirmReset(true)}>
            Avbryt
          </Button>
        )}
        {step < 4 && (
          <Button
            variant="contained"
            disabled={(step === 1 && !selectedCategoryTypeId) || (step === 2 && !excelFile)}
            onClick={() => onStepChange(step + 1)}
          >
            Nästa ➞
          </Button>
        )}
        {!isLeftChildren && children}
      </Box>

      <ConfirmModal
        open={!!showConfirmReset}
        content="All changes will be lost. Do you want to continue?"
        onCancel={() => setShowConfirmReset(false)}
        onOk={onReset}
      />
    </div>
  );
};

export default FooterWrapper;
