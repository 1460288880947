import React, { FC } from 'react';

const Gas: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 170 170"
    fill="none"
    {...props}
  >
    <g clipPath="url(#Gas_1)">
      <path
        fill="#EB1F26"
        d="m7.942 85.349 77.15-77.282 77.151 77.282-77.15 77.28-77.15-77.28Z"
      />
      <path
        stroke="#ED1C24"
        strokeWidth={0.915}
        d="m162.244 85.349-77.15 77.281L7.942 85.349l77.15-77.282 77.151 77.281Z"
      />
      <path
        fill="#fff"
        d="m20.291 85.352 64.802-64.91 64.802 64.91-64.803 64.913-64.801-64.913Z"
      />
      <mask
        id="Gas_2"
        width={159}
        height={159}
        x={6}
        y={6}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M6.118 164.462h157.957V6.236H6.118v158.226Z" />
      </mask>
      <g mask="url(#Gas_2)">
        <path
          fill="#231F20"
          fillRule="evenodd"
          d="M55.923 98.278c-1.41.377-3.853 1.036-5.732-1.882-1.878-2.917-2.537-8.283-1.785-10.352.752-2.072 1.692-2.447 2.914-2.825 1.221-.376 45.57-12.235 46.886-12.516 1.313-.283 3.298-1.27 5.354-.086 1.127.65 2.912 2.155 3.758 2.438.846.282 1.504.095 2.068-.094.563-.189 10.053-2.447 10.712-2.447.657 0 1.502.47 1.877 1.976.377 1.506 0 2.165-.375 2.54-.375.378-1.032.566-1.502.66-.471.094-9.208 2.353-9.962 2.636-.749.282-1.972 1.284-2.16 2.54-.283 1.883-.879 2.867-1.995 3.5-1.833 1.036-50.058 13.912-50.058 13.912Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="Gas_1">
        <path fill="#fff" d="M0 0h170v170H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default Gas;
