import { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fixedWrapper: {
    position: 'fixed',
    top: 0,
    zIndex: 20,
    width: '100%',
    overflowX: 'auto',
  },
  fixedHeadContent: {
    display: 'flex',
    overflowX: 'scroll',
    zIndex: 10,
    '& td': {
      display: 'block',
      flexGrow: 0,
      flexShrink: 0,
      lineHeight: '24px',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

interface UseFixedHeaderProps {
  tableWrapper?: HTMLDivElement | null;
  fixedWrapper?: HTMLDivElement | null;
  fixedRowElement?: HTMLDivElement | null;
  top?: number;
}

const useFixedHeader = ({ tableWrapper, fixedWrapper, fixedRowElement, top = 0 }: UseFixedHeaderProps) => {
  const classes = useStyles();

  // scroll fixed header
  useEffect(() => {
    if (!fixedRowElement || !tableWrapper) return;
    // add class to fixedRowElement
    if (!fixedRowElement.classList.contains(classes.fixedHeadContent)) {
      fixedRowElement.classList.add(classes.fixedHeadContent);
    }

    const handleTableScroll = () => setTimeout(() => fixedRowElement.scroll({ left: tableWrapper.scrollLeft }), 0);

    tableWrapper.addEventListener('scroll', handleTableScroll);
    return () => tableWrapper.removeEventListener('scroll', handleTableScroll);
  }, [classes, fixedRowElement, tableWrapper]);

  // update fixedWrapper's width
  useEffect(() => {
    if (!fixedWrapper || !tableWrapper) return;
    if (!fixedWrapper.classList.contains(classes.fixedWrapper)) {
      fixedWrapper.classList.add(classes.fixedWrapper);
    }
    const observer = new ResizeObserver(([entry]) => {
      fixedWrapper.style.width = `${entry.contentRect.width}px`;
    });

    observer.observe(tableWrapper);
    return () => observer.disconnect();
  }, [classes, fixedWrapper, tableWrapper]);

  // watch start element
  // hide "Virtual scroll bar" when start element is visible in the screen
  // show "Virtual scroll bar" when start element is NOT visible in the screen
  useEffect(() => {
    if (!fixedWrapper || !tableWrapper || !tableWrapper.parentNode) return;
    const startElement = document.createElement('div');
    // insert [startElement] right after [targetElement]
    tableWrapper.parentNode.insertBefore(startElement, tableWrapper);

    const observer = new IntersectionObserver(
      ([entry]) => {
        const { intersectionRatio, boundingClientRect } = entry || {};
        if (intersectionRatio === 1 || boundingClientRect.bottom > top) {
          fixedWrapper.style.height = '0';
        } else {
          fixedWrapper.style.height = 'auto';
        }
      },
      { threshold: [0, 1], rootMargin: `-${top}px` }
    );

    observer.observe(startElement);
    return () => {
      observer.disconnect();
      tableWrapper.parentNode?.removeChild(startElement);
    };
  }, [top, fixedWrapper, tableWrapper]);
};

export default useFixedHeader;
