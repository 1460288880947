import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';

import InputTextField from "components/inputfields/InputTextField";
import IProduct from "models/product";
import FloatInput from "../FloatInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { isDefined } from "utils/helpers-ts";
import { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const isEmpty = (product?: IProduct) => {
  if (!product) return true;
  const isNotStore = !product?.producerIsStore;
  if (
    // isDefined(product.consumerPackQtyOfBaseItem)
    (isNotStore && product.consumerPackOrderableUnit)
    || product.consumerPackDespatchUnit
    || isDefined(product.consumerPackNetWeight)
  ) {
    return false;
  }
  return true;
};

interface IConsumerPackProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const ConsumerPack = ({ state, setState }: IConsumerPackProps) => {
  const classes = useStyles();
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });
  const onLabelChanged = ({ target }: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setState({...state, [target.name]: checked }); 
  };
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    setExpanded(!isEmpty(state));
  }, []);
  const handleAccordionChange = (event: any, isExpanded?: boolean) => {
    setExpanded(!!isExpanded);
  };
  const isNotStore = !state?.producerIsStore;
  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>Konsumentförpackning / Consumer pack</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {/* <FloatInput
            name="consumerPackQtyOfBaseItem"
            label="Qty of base item"
            state={state}
            setState={setState}
            dense={false}
          /> */}
          {isNotStore && (<FormControlLabel
            control={
              <Checkbox
                checked={state?.consumerPackOrderableUnit}
                onChange={onLabelChanged}
                name="consumerPackOrderableUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Orderable Unit"
          />)}
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.consumerPackDespatchUnit}
                onChange={onLabelChanged}
                name="consumerPackDespatchUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Despatch Unit"
          />
          <FloatInput
            name="consumerPackNetWeight"
            label="Net weight(G)"
            state={state}
            setState={setState}
            dense={false}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConsumerPack;
