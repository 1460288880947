import React from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    button: {
        width: theme.spacing(6),
    },
    root: {
        minHeight: 400,
    },
}));

const OrderList = ({
    listData,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
    isProducer,
}) => {
    const classes = useStyles();
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Order&nbsp;Number
                            </TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="right">
                                Notification&nbsp;
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData.data.map(row => (
                            <TableRow key={isProducer ? row.orderNumber : row.groupOrderNumber}>
                                <TableCell component="th" scope="row">
                                    {isProducer ? row.orderNumber :row.groupOrderNumber}
                                </TableCell>
                                <TableCell align="left">
                                    {new Date(
                                        parseInt(row.createdAt)
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        component={Link}
                                        to={`/notification/${isProducer ? row.orderNumber :row.groupOrderNumber}`}
                                        color="primary"
                                        className={classes.button}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={listData.pageInfo.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
};

export default OrderList;