import React from "react";

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import InputTextField from "components/inputfields/InputTextField";
import ListDropDown, { IListItem } from "components/inputfields/ListDropDown";
import DetailedInputLabel from "components/inputfields/InputLabel";
import IProducer from "models/producer";

export const GET_STRIPE_CUSTOMERS = gql`
  query getStripeCustomers {
    getStripeCustomers {
      id
      name
      email
      deleted
    }
  }
`;

export const DUPLICATE_CUSTOMER_ERROR = gql`
  query($producerId: String!, $customerId: String) {
    duplicateCustomerError(producerId: $producerId, customerId: $customerId) {
      result
      name
    }
  }
`;

interface IStripeCustomersProps {
  state?: IProducer;
  setState: (state: IProducer) => void;
  dataLoading?: boolean;
}

interface IStripeCustomer {
  id: string;
  name?: string;
  email?: string;
  deleted?: boolean;
}

interface IGetStripeCustomers {
  getStripeCustomers?: IStripeCustomer[];
}

const StripeCustomers = ({ state, setState, dataLoading }: IStripeCustomersProps) => {
  const { data, loading, error } = useQuery<IGetStripeCustomers>(GET_STRIPE_CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
  });

  const { data: duplicateCustomerErrorData, refetch: duplicateCustomerErrorRefetch } = useQuery(DUPLICATE_CUSTOMER_ERROR, {
    variables: {
        producerId: state?.id,
        customerId: state?.stripeCustomerId,
    },
  });

  let errorStripe = state?.stripeCustomerId ? undefined: 'Denna kund finns inte Stripe';
  if (!errorStripe) {
    // console.log('!duplicateCustomerErrorData', duplicateCustomerErrorData?.duplicateCustomerError);
    const data = duplicateCustomerErrorData?.duplicateCustomerError;
    if (data) {
      const { result, name } = data;
      errorStripe = result ? `Detta Stripe kund-ID används redan för producenten “${name}”` : undefined;
    }
  }
  if (!errorStripe) {
    if (state?.stripeCustomerDeleted) {
      errorStripe = `Denna kund finns inte längre i Stripe, lägg till kunden i Stripe igen eller välj en annan kund.`;
    }
  }

  React.useEffect(() => {
    if (setState && state) {
      if (!state.duplicateCustomerError && duplicateCustomerErrorData?.duplicateCustomerError?.result) {
        setState({...state, duplicateCustomerError: true });
      } else if (state.duplicateCustomerError && !duplicateCustomerErrorData?.duplicateCustomerError?.result) {
        setState({...state, duplicateCustomerError: false });
      }
    }
  }, [duplicateCustomerErrorData, setState, state]);

  const [stripeCustomers, setStripeCustomers] = React.useState<IListItem[]>([]);

  const sortComparer = (a: IStripeCustomer, b: IStripeCustomer) => {
    if (!b.name) {
      return 1;
    }
    if (!a.name) {
      return -1;
    }
    return a.name.localeCompare(b.name);
  };

  const clearStripeData = () => {
    console.log('initState, clearStripeData, null');
    setState({
      ...state, 
      stripeCustomerName: null, 
      stripeCustomerEmail: null, 
      stripeCustomerId: null,
      stripeCustomerDeleted: null,
    });
  };
  const updateStripeData = () => {
    const val = state?.stripeCustomerId;

    let clear = true;
    if (val) {
      const item = data?.getStripeCustomers?.find((x) => x.id === val);
      if (item) {
        clear = false;
        console.log('initState, updateStripeData');
        setState({
          ...state, 
          stripeCustomerName: item.name, 
          stripeCustomerEmail: item.email, 
          stripeCustomerId: val,
          stripeCustomerDeleted: item.deleted,
        });
      }
    } else {
      console.log('initState, updateStripeData, null');
      setState({
        ...state, 
        stripeCustomerName: null, 
        stripeCustomerEmail: null, 
        stripeCustomerId: null,
        stripeCustomerDeleted: null,
      });
    }
    if (clear) clearStripeData();
  };
  React.useEffect(() => {
    if (data && !loading && !error) {
      const customers = data
        .getStripeCustomers
        ?.sort(sortComparer)
        ?.map((x) => ({
          value: x.id,
          displayName: `${x.name} (${x.email})${x.deleted ? ' (DELETED)' : ''}`,
          style: { color: x.deleted ? 'red' : 'inherit' },
        }));
      if (customers) {
        setStripeCustomers(customers);
        updateStripeData();
      } else {
        setStripeCustomers([]);
      }
    }
  }, [data, loading, error]);

  const handleStripeNameChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    const stateName = event.target.name;
    let stateValue = event.target.value as string;

    const val = !stateValue || stateValue === '' ? null : stateValue;
    // stateName && setState({ ...state, [stateName]: val });

    if (stateName) {
      if (val) {
        const item = data?.getStripeCustomers?.find((x) => x.id === val);
        if (item) {
          console.log('initState, handleStripeNameChange');
          setState({
            ...state, 
            stripeCustomerName: item.name, 
            stripeCustomerEmail: item.email, 
            stripeCustomerId: val,
            stripeCustomerDeleted: item.deleted,
          });
        }
      } else {
        console.log('initState, handleStripeNameChange, null');
        setState({
          ...state, 
          stripeCustomerEmail: null, 
          stripeCustomerId: null, 
          stripeCustomerName: null,
          stripeCustomerDeleted: null,
        });
      }
    }
  };    
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    if (loading || dataLoading) {
      setLoaded(false);
    } else {
      setLoaded(true);
    }
  }, [dataLoading, loading]);
  return (
    <>
      <h3>STRIPE</h3>
      <DetailedInputLabel
        id="stripe"
        heading={`Choose Stripe customer`}
      />
      <ListDropDown
        listData={stripeCustomers}
        value={state?.stripeCustomerId}
        targetName="stripeCustomerId"
        handleChange={handleStripeNameChange}
        displayEmpty={true}
        showSuggested={false}
        showSearch={true}
        errorText={errorStripe}
      />
      {state?.stripeCustomerId && (
        <>
          <InputTextField<IProducer>
            inputName="stripeCustomerId"
            label="Stripe customer ID"
            value={state.stripeCustomerId}
            disabled={true}
            shrink={true}
          />
          <InputTextField<IProducer>
            inputName="stripeCustomerName"
            label="Stripe customer name"
            value={state.stripeCustomerName}
            disabled={true}
            shrink={true}
            required={true}
            showRed={loaded}
            errorText="Denna kund har inget namn i Stripe"
          />
          <InputTextField<IProducer>
            inputName="stripeCustomerEmail"
            label="Stripe billing email"
            value={state.stripeCustomerEmail}
            disabled={true}
            shrink={true}
            required={true}
            showRed={loaded}
            errorText="Denna kund har ingen inlagd mail för fakturering i Stripe"
          />
        </>
      )}
  </>
  );
};

export default StripeCustomers;
