import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";

import ListDropDown from "components/inputfields/ListDropDown";
import IProducer from "models/producer";

const provincies = [
  'Blekinge',
  'Närke',
  'Bohuslän',
  'Skåne',
  'Dalarna',
  'Småland',
  'Dalsland',
  'Södermanland',
  'Gotland',
  'Uppland',
  'Gästrikland',
  'Värmland',
  'Halland',
  'Västerbotten',
  'Hälsingland',
  'Västergötland',
  'Härjedalen',
  'Västmanland',
  'Jämtland', 
  'Ångermanland',
  'Lappland',
  'Öland',
  'Medelpad',
  'Östergötland',
  'Norrbotten',
].sort();

interface IProviceProps {
  state?: IProducer;
  setState: (state: IProducer) => void;
}

const Province = ({ state, setState }: IProviceProps) => {
  const handleChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>, child: React.ReactNode) => {
    if (event?.target?.name) {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  return (
    <>
      <InputLabel id="simple-select-outlined-label">
        Ert landskap:
      </InputLabel>
      <FormControlLabel
        style={{marginLeft: 0}}
        label=""
        control={
          <ListDropDown
            listData={provincies.map(x => ({value: x}))}
            handleChange={handleChange}
            value={state?.province}
            targetName="province"
            disableLabel={true}
            displayEmpty={true}
            // emptyText="Landskap"
            style={{ width: 300 }}
            // dropDownStyle={{ height: 19 }}
          />
        }
      />
    </>
  );
};

export default Province;
