import React from 'react';
import { useDropzone } from 'react-dropzone';

const PDFFileUploader = ({
  containerStyle,
  onAddCertificate,
  isUploading
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    multiple: false,
    disabled: isUploading,
    onDrop: async acceptedFiles => {
      onAddCertificate(acceptedFiles);
    },
  });


  return (
    <section className="container" style={containerStyle}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <span>Drag a PDF here to upload!</span>
      </div>
    </section>
  );
};

export default PDFFileUploader;
