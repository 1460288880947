import ThreeWaySwitch from 'components/inputfields/ThreeWaySwitch';
import { KeycloakContext } from 'components/Secured';
import IProduct from 'models/product';
import React, { useContext } from 'react';
import InputTextField from '../inputfields/InputTextField';
import { isFoodCategory } from './ProductUpdateForm/utils';

// TODO rename
interface IField {
  inputName?: string;
  value?: string | number;
  formLabel?: string;
  info?: string;
}

interface IOrganizationProductNotesProps {
  fields: IField[];
  onFieldValueChanged?: (value: any) => void; // TODO any
  state: IProduct;
  setState: (state: IProduct) => void;
}

const OrganizationProductNotes = ({ fields, onFieldValueChanged, state, setState }: IOrganizationProductNotesProps) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isSuperuser = keycloakCtx.keycloak.hasResourceRole('superuser');
  const isFood = isFoodCategory(state)
  const showApprove = isFood && isSuperuser;
  return (
    <>
    {fields && fields.map(f => {
      const approve = showApprove && f.inputName === 'ItemCategoriesOnline';
      return (
        <InputTextField
          inputName={f.inputName as any} // TODO
          value={f.value}
          label={f.inputName}
          onChange={onFieldValueChanged}
          withLabel={!!f.info}
          state={state}
          setState={setState}
          // approve={approve}
          labelOptions={{ heading: f.formLabel, text: f.info }}
        />
      );
    })}
    </>
  )
}

export default OrganizationProductNotes;
