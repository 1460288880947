import React, { FC } from 'react';

interface ErrorIconProps extends React.SVGProps<SVGSVGElement> {
  symbolColor?: string;
}

const ErrorIcon: FC<ErrorIconProps> = ({ symbolColor = '#D32F2F', ...props }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" {...props}>
      <circle cx="9" cy="9.5" r="9" fill="#FFE6E6" />
      <path d="M9 9.5V5.5" stroke={symbolColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="9" cy="12.5" r="1" fill={symbolColor} />
    </svg>
  );
};

export default ErrorIcon;
