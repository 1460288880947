import { FC, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/button';
import SnackBar from 'components/snackbar/SnackBar';

import IProduct from 'models/product';
import { getProductMutationInput } from 'components/product/ProductUpdateForm/utils';
import {
  APPLICATION_ROLES,
  NON_FOOD_CATEGORY_ID,
  PRODUCT_EAN_FIELDS,
  SUPERUSER_APPROVAL,
} from 'components/constants-ts';
import { ORGANIZATION_SPECIFIC_FIELDS } from 'components/constants';

import useProductData from 'hooks/useProductData';
import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';
import { TUploadedProductStatus } from '../Contexts/DataContext';

import {
  MUTATION_ADD_PRODUCT,
  MUTATION_SUPERUSER_APPROVE,
  MUTATION_UPDATE_PRODUCT_NOTES,
  S3_SIGNED_REQUEST_MUTATION,
} from 'graphql/mutations';
import { isDefined } from 'utils/helpers';
import { IImageFileSource, IImportColumn, IProductExcel } from '../types';
import { getImageDataListToUpload, mapUploadProductList } from '../utils';
import { isBlank } from 'utils/helpers-ts';
import { GROUP_CATEGORY_IDS } from '../constants';
import { isNewFood, isNewNonFood } from 'components/product/ProductUpdateForm/fields/category';
import { checkSameWithSavedValue, checkValueReviewed, getDataType, isErrorCell } from '../utils/excel';
import ImportCompletedModal from './ImportCompletedModal';

type TSignedRequest = {
  signedRequest: string;
  url: string;
  pictureUrl: string;
  picturePath: string;
  fileName: string;
};

type TSignedRequestResponse = {
  getS3SignRequest: {
    requests: TSignedRequest[];
  };
};

interface ImportDataButtonProps {
  dataList: IProductExcel[];
  columnList: IImportColumn[];
}

const ImportDataButton: FC<ImportDataButtonProps> = ({ dataList, columnList }) => {
  const [updateProductNotes] = useMutation(MUTATION_UPDATE_PRODUCT_NOTES);
  const [createProductMutation] = useMutation(MUTATION_ADD_PRODUCT);
  const [updateProductMutation] = useMutation(MUTATION_SUPERUSER_APPROVE);
  const [s3SignedRequest] = useMutation<TSignedRequestResponse>(S3_SIGNED_REQUEST_MUTATION, { refetchQueries: [] });

  const {
    userRole,
    step,
    selectedCategoryTypeId,
    productType,
    producerData,
    uploadProgress,
    setUploadProgress,
    setErrorList,
    hasClickedUploadImage,
  } = useControlContext();
  const {
    excelFile,
    rootCategory,
    commonQuestionData,
    // Todo
    // tempApproveEanStatus,
    isUploadedSuccessfully,
    productImageDataSet,
    onUploadedProductStatusSetChange,
  } = useDataContext();

  const { getProductsByEans } = useProductData();

  const isProducerRole = userRole === APPLICATION_ROLES.PRODUCER;
  const isStoreRole = userRole === APPLICATION_ROLES.STORE;

  const keepRef = useRef({ uploadedProductCount: 0 });

  const [uploading, setUploading] = useState(false);
  const [uploadedProductCount, setUploadedProductCount] = useState(0);
  const [toastError, setToastError] = useState('');
  const [openImportCompletedModal, setOpenImportCompletedModal] = useState(false);

  // Todo
  // const isApprovedAll = useMemo(
  //   () => savedProductList.every(({ EAN }) => tempApproveEanStatus[EAN || ''] === SUPERUSER_APPROVAL.ADMIN_APPROVED),
  //   [savedProductList, tempApproveEanStatus]
  // );

  // Todo
  // const isDisApprovedAll = useMemo(
  //   () => savedProductList.every(({ EAN }) => tempApproveEanStatus[EAN || ''] === SUPERUSER_APPROVAL.ADMIN_DISAPPROVED),
  //   [savedProductList, tempApproveEanStatus]
  // );

  const disableButton = useMemo(() => {
    // Todo
    // const disabledImport = !producerData.id || uploadProgress.isUploading || (!isApprovedAll && !isDisApprovedAll);
    if (isUploadedSuccessfully || !producerData.username || uploadProgress.isUploading) return true;

    const uploadableImageDataList = getImageDataListToUpload(productImageDataSet, dataList);

    if (!uploadableImageDataList.length) return false;

    return !hasClickedUploadImage;
  }, [
    isUploadedSuccessfully,
    producerData.username,
    uploadProgress.isUploading,
    dataList,
    productImageDataSet,
    hasClickedUploadImage,
  ]);

  const prepareDataToUpload = ({ excelFileUrl }: { excelFileUrl?: string }) => {
    const uploadingDataList = dataList
      .filter(product => {
        if (isProducerRole || isStoreRole) return true;

        if (productType === NON_FOOD_CATEGORY_ID) {
          return isNewNonFood(product.foodlaCategory, rootCategory);
        } else {
          return isNewFood(product.foodlaCategory, rootCategory);
        }
      })
      .map(dataItem => {
        const { _excelData, ...uploadingData } = dataItem;

        const reviewedFieldsChange = columnList.filter(
          column => checkValueReviewed(column, dataItem) && !checkSameWithSavedValue(column, dataItem)
        );

        const newProduct: IProduct = {
          ...uploadingData,
          title: dataItem.title || '',
          location: dataItem.location || '',
          short_text: dataItem.short_text || '',
          bulkImportFile: excelFileUrl,
        };

        if (dataItem._savedData?.foodlaCategory) {
          newProduct.foodlaCategory = dataItem._savedData?.foodlaCategory;
        }

        if (reviewedFieldsChange.length) {
          newProduct.adminStatus = SUPERUSER_APPROVAL.AWAITING_APPROVAL;
        }

        return newProduct;
      });

    return mapUploadProductList(uploadingDataList, { rootCategory, producer: producerData });
  };

  const uploadProduct = async (
    dataItemParam: IProduct,
    imageDataList: IImageFileSource[],
    savedProduct?: IProduct
  ): Promise<TUploadedProductStatus> => {
    const dataItem = _.cloneDeep(dataItemParam);

    if (savedProduct) {
      // Prevent blank values overwrite saved field values
      Object.entries(dataItemParam).forEach(([field, value]) => {
        const dataType = getDataType(field as keyof IProduct);
        if (
          (_.isNil(value) && dataType === 'date') ||
          (_.isNil(value) && dataType === 'number') ||
          (_.isNil(value) && dataType === 'boolean') ||
          (isBlank(value) && dataType === 'string')
        ) {
          (dataItem as any)[field] = savedProduct[field as keyof IProduct];
        }
      });
    }

    const result = { dataId: dataItem.id || '', productId: '', error: '' };

    const uploadingProduct: IProduct = {
      ...dataItem,
      ...commonQuestionData,
    };

    uploadingProduct.title = uploadingProduct.title || '';

    const imageUrlList = imageDataList.map(imageData => imageData.url).filter(url => !!url);

    // get existed product

    // ** generate variables **
    const mutationInput = getProductMutationInput(
      savedProduct,
      {
        ...uploadingProduct,
        producerUsername: producerData.username,
        producerName: producerData.name,
        producerEmail: producerData.email,
      },
      'superuser',
      imageUrlList,
      !savedProduct?.id,
      rootCategory
    );
    const inputFields: any = { ...mutationInput.variables.input };
    if (selectedCategoryTypeId === GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER) {
      inputFields.classification = uploadingProduct.classification;
    }

    const variables = {
      ...mutationInput.variables,
      input: inputFields,
      producerUsername: producerData.username,
      forceApprove: true,
    };

    // ** update product **
    if (savedProduct?.id) {
      delete variables.producerUsername;
      try {
        await updateProductMutation({ variables });
        keepRef.current.uploadedProductCount += 1;
      } catch (error: any) {
        result.error = error.message;
      }
      result.productId = savedProduct.id || '';
      return result;
    }

    // ** create product **
    try {
      const createProductResponse = await createProductMutation({ variables });
      const { success, message, productId: newProductId } = createProductResponse?.data?.createProduct || {};

      if (success) {
        result.productId = newProductId;
        keepRef.current.uploadedProductCount += 1;
      } else {
        result.error = message;
      }
    } catch {
      result.productId = '';
      result.error = `Error Upload Product: upload product failed`;
    }

    if (result.productId && dataItem.ItemCategoriesOnline) {
      // upload productNotes
      const productNotes: Record<string, any>[] = [];
      ORGANIZATION_SPECIFIC_FIELDS.COOP.forEach(f => {
        if (isDefined(dataItem[f.fieldName as keyof IProduct])) {
          const note = {
            fieldName: f.fieldName,
            [f.inputType]: f.transformer(dataItem[f.fieldName as keyof IProduct]),
          };
          productNotes.push(note);
        }
      });
      try {
        await updateProductNotes({ variables: { id: result.productId, productNotes } });
      } catch {
        result.error = `Error Upload Product: upload product's notes failed`;
      }
    }

    return result;
  };

  const handleUploadExcelFile = async () => {
    if (!excelFile) return;

    try {
      const { data, errors } = await s3SignedRequest({
        variables: {
          input: {
            producerName: producerData.username,
            files: [{ fileName: excelFile.name, fileType: excelFile.type }],
            renameFiles: false,
          },
        },
      });
      if (errors) return;

      const signedRequest = data?.getS3SignRequest?.requests?.[0] || null;
      if (!signedRequest) return;

      await axios.put(signedRequest.signedRequest, excelFile, {
        timeout: 10 * 60 * 1000,
        headers: { 'Content-Type': excelFile.type },
      });

      return signedRequest.url;
    } catch {
      return;
    }
  };

  const checkDataBeforeUpload = () => {
    const hasEanError = columnList
      .filter(({ field }) => PRODUCT_EAN_FIELDS.includes(field))
      .some(column => dataList.some(data => !!isErrorCell(column, data)));

    setErrorList(state => {
      const newErrorList = state.filter(error => error.step !== step);

      if (hasEanError) newErrorList.push({ step, message: 'Kontrollera att EAN-koder har rätt antal siffror' });

      return newErrorList;
    });

    return hasEanError;
  };

  const handleUploadData = async () => {
    if (checkDataBeforeUpload()) return;

    setUploading(true);
    setUploadProgress({ total: 0, uploadedTotal: 0, uploaded: [], uploading: [], isUploading: true });

    const excelFileUrl = await handleUploadExcelFile();

    if (!excelFileUrl) {
      setToastError('Error: upload excel file failed');
      setUploading(false);
      setUploadProgress(state => ({ ...state, isUploading: false }));
      return;
    }

    const uploadedSet: Record<string, TUploadedProductStatus> = {};
    const uploadingDataList = prepareDataToUpload({ excelFileUrl });

    const eanList = uploadingDataList.map(({ EAN }) => String(EAN)).filter(EAN => EAN);
    const savedProductList = await getProductsByEans(eanList);

    const uploadProductPromises = uploadingDataList.map(async dataItem => {
      const imageDataList = productImageDataSet[dataItem.id || ''] || [];
      const savedProduct = savedProductList.find(product => dataItem.EAN && product.EAN === dataItem.EAN);
      const uploadedProductStatus = await uploadProduct(dataItem, imageDataList, savedProduct);

      if (dataItem.id) {
        uploadedSet[dataItem.id] = uploadedProductStatus;
      }
    });

    await Promise.all(uploadProductPromises);

    onUploadedProductStatusSetChange(uploadedSet);
    setUploadedProductCount(keepRef.current.uploadedProductCount);
    keepRef.current.uploadedProductCount = 0;

    setUploading(false);
    setUploadProgress(state => ({ ...state, isUploading: false }));
    setOpenImportCompletedModal(true);
  };

  return (
    <>
      <Button variant="contained" disabled={uploading || disableButton} onClick={handleUploadData}>
        {uploading ? <CircularProgress size={16} style={{ margin: 4 }} /> : 'Importera data'}
      </Button>

      <ImportCompletedModal open={openImportCompletedModal} setOpen={setOpenImportCompletedModal} />

      {!!toastError &&
        createPortal(
          <SnackBar
            message={toastError}
            duration={3000}
            onClose={() => {
              setToastError('');
              setUploadedProductCount(0);
            }}
          />,
          document.body
        )}

      {!!uploadedProductCount &&
        createPortal(
          <SnackBar
            message={`Successfully Uploaded ${uploadedProductCount} items`}
            duration={3000}
            onClose={() => setUploadedProductCount(0)}
          />,
          document.body
        )}
    </>
  );
};

export default ImportDataButton;
