import { FC, useMemo } from 'react';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import TruncateText from 'components/TruncateText';
import { getCountryCode } from 'utils/helpers-ts';
import { isSameWithSavedValue } from '../../bulkImportUtils';
import { useBulkImportContext } from '../../BulkImportContext';
import { IImportColumn, IProductExcel, STATUS_COLORS, getCellValue, isErrorCell } from 'utils/excelUtils-old';

import { useStyles } from './index.style';

const NON_EDITABLLE_FIELDS = ['search_terms'];

const useCustomStyles = makeStyles(() => ({
  oldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderRadius: 4,
    padding: '4px 0',
    minHeight: 20,
    color: 'red',
  },
  newWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderRadius: 4,
    padding: '2px 4px',
    margin: '-2px -5px', // -4px for padding, -1px for border
    minHeight: 20,
    border: '1px dashed #00000066',
    color: 'green',
  },
  inputDateWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    width: '100%',
    outline: 0,
    background: 'transparent',
    borderWidth: 0,
    padding: 0,
    color: 'green',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
  },
  inputDate: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    '&::-webkit-datetime-edit': {
      opacity: 0,
      width: 0,
    },
    '&::-webkit-calendar-picker-indicator': {
      cursor: 'pointer',
      width: '100%',
      backgroundPosition: 'right center',
    },
  },
}));

export interface ReviewTableBodyCellProps {
  isReview: boolean;
  column: IImportColumn;
  data: IProductExcel;
  className?: string;
}

/**
 *
 * don't use MUI's component in table body
 * - Using <TableCell>: x2 render time
 * - Using <Box>: x2 render time
 */
const ReviewTableBodyCell: FC<ReviewTableBodyCellProps> = ({ isReview, column, data, className }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { onChangeDataValue } = useBulkImportContext();

  const value = useMemo(() => getCellValue(column, data), [column, data]);
  const isError = useMemo(() => isErrorCell(column, { ...data, [column.field]: value }), [column, data, value]);

  const displayValue = useMemo(() => {
    if (column.dataType === 'date') {
      if (!value) return '';
      if (!dateFns.isDate(value as Date)) return value as Date;
      return dateFns.format(value as Date, 'dd MMM yyyy');
    }
    return value;
  }, [column, value]);

  const countryCode = useMemo(() => {
    if (!isReview && column.field === 'country_of_manufacturing_string') {
      return getCountryCode(_.toString(value));
    }
    return undefined;
  }, [column.field, isReview, value]);

  const isEditable = useMemo(() => {
    if (isReview) return false;
    if (NON_EDITABLLE_FIELDS.includes(column.field)) return false;
    const excelValue = getCellValue(column, { id: data.id, _excelData: data._excelData });
    if (_.isNil(excelValue)) return false;
    return !isSameWithSavedValue(column, {
      id: data.id,
      _excelData: data._excelData,
      _savedData: data._savedData,
      _revertedSavedData: data._revertedSavedData,
    });
  }, [isReview, column, data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeDataValue(data.id || '', column.field, event.target.value);
  };

  const backgroundColor = isError ? STATUS_COLORS.ERROR : STATUS_COLORS.NORMAL;

  if (!isEditable) {
    return (
      <td className={clsx(classes.bodyCell, className)}>
        <div data-field={column.field}>
          <TruncateText
            title={displayValue === null ? '' : _.toString(displayValue)}
            className={classes.bodyCellBox}
            style={{ backgroundColor }}
            fixedAfter={countryCode ? `(${countryCode})` : ''}
          />
        </div>
      </td>
    );
  }

  return (
    <td className={clsx(classes.bodyCell, className)}>
      <div data-field={column.field}>
        <div className={customClasses.newWrapper} style={{ backgroundColor, color: 'green' }}>
          {column.dataType === 'date' && (
            <div className={customClasses.inputDateWrapper}>
              <div>{displayValue}</div>
              <input
                className={clsx(customClasses.input, customClasses.inputDate)}
                type="date"
                value={(value as Date)?.toISOString?.().substring?.(0, 10)}
                onChange={handleChange}
              />
            </div>
          )}
          {column.dataType !== 'date' && (
            <input className={customClasses.input} value={value as string} onChange={handleChange} />
          )}
        </div>
      </div>
    </td>
  );
};

export default ReviewTableBodyCell;
