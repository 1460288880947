import React, { FC } from 'react';

const SortIcon: FC<React.SVGProps<SVGSVGElement>> = ({ order, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      style={{ borderRadius: 50, background: order ? 'rgba(61, 81, 101, 0.07)' : '', ...props.style }}
    >
      <path
        d="M4.66666 9.33337L8 12.6667L11.3333 9.33337H4.66666Z"
        fill="#737DA0"
        opacity={order === 'asc' ? 1 : 0.4}
      />
      <path
        d="M4.66666 6.66675L8 3.33342L11.3333 6.66675H4.66666Z"
        fill="#737DA0"
        opacity={order === 'desc' ? 1 : 0.4}
      />
    </svg>
  );
};

export default SortIcon;
