import { FC, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import { KeycloakContext } from 'components/Secured';

import {
  contextDefaultValue,
  ControlContext,
  TUploadProgress,
  TImageSettings,
  TControlContextValue,
  TError,
} from './ControlContext';
import IndexedDB from 'utils/indexedDB';
import { createIndededDB, getStepNumber, getStepPath } from '../utils';
import { DEFAULT_LIMIT, LOCAL_STORE_ITEMS, OBJECT_STORAGE_NAME } from '../constants';
import { APPLICATION_ROLES } from 'components/constants-ts';

import IProduct from 'models/product';
import IProducer from 'models/producer';
import { getColumnsByCategoryType } from '../utils/excel';

interface ControlProviderBaseProps {
  userRole: string;
  indexedDB: IndexedDB;
  clearLocalStore: () => Promise<void>;
}

export const ControlProviderBase: FC<ControlProviderBaseProps> = ({
  userRole,
  indexedDB,
  clearLocalStore,
  children,
}) => {
  const { step: stepPath } = useParams<{ step: string }>();
  const history = useHistory();
  const keycloakContext = useContext(KeycloakContext);
  const [loadingLocal, setLoadingLocal] = useState(!!localStorage.getItem(LOCAL_STORE_ITEMS.PRODUCT_TYPE));
  const [step, setStep] = useState(1);

  const [selectedCategoryTypeId, setSelectedCategoryTypeId] = useState<string>('');

  // excel
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [page, setPage] = useState(1);
  const [productType, setProductType] = useState('');
  const [producerList, setProducerList] = useState<IProducer[]>([]);
  const [producerData, setProducerData] = useState<IProducer>({});

  // product images
  const [hasClickedUploadImage, setHasClickedUploadImage] = useState(false);
  const [isConvertingImages, setConvertingImages] = useState(false);
  const [regexValue, setRegexValue] = useState('');
  const [uploadProgress, setUploadProgress] = useState<TUploadProgress>(contextDefaultValue.uploadProgress);
  const [imageSettings, setImageSettings] = useState<TImageSettings>(contextDefaultValue.imageSettings);
  const [errorList, setErrorList] = useState<TError[]>([]);

  const [tableContainerElement, setTableContainerElement] = useState<HTMLDivElement | null>(null);
  const [tableDownloadContainerElement, setTableDownloadContainerElement] = useState<HTMLDivElement | null>(null);
  const [endPinnedCellElement, setEndPinnedCellElement] = useState<HTMLDivElement | null>(null);

  const categoryColumnList = useMemo(() => {
    return getColumnsByCategoryType(selectedCategoryTypeId);
  }, [selectedCategoryTypeId]);

  const onStepChange = useCallback(
    (newStep: number) => {
      localStorage.setItem(LOCAL_STORE_ITEMS.STEP, String(newStep));
      history.push(getStepPath(newStep));
    },
    [history]
  );

  const onProductTypeChange = useCallback((newProductType: string) => {
    setProductType(newProductType);
    localStorage.setItem(LOCAL_STORE_ITEMS.PRODUCT_TYPE, newProductType);
  }, []);

  const onProducerIdChange = useCallback(
    (newProducerId: string) => {
      localStorage.setItem(LOCAL_STORE_ITEMS.PRODUCER_ID, newProducerId);

      const newProducer = producerList.find(({ id }) => id === newProducerId);
      if (newProducer) setProducerData(newProducer);
    },
    [producerList]
  );

  const onLimitChange = useCallback((newLimit: number) => {
    setLimit(newLimit);
    localStorage.setItem(LOCAL_STORE_ITEMS.LIMIT, String(newLimit));
  }, []);

  const onImageSettingChange = useCallback((newImageSettings: Partial<TImageSettings>) => {
    setImageSettings(state => {
      const newState = { ...state, ...newImageSettings };
      localStorage.setItem(LOCAL_STORE_ITEMS.PARSE_IMAGE_BY, String(newState.parseImageBy));
      localStorage.setItem(LOCAL_STORE_ITEMS.IS_IGNORE_AI, String(newState.aiIgnore));
      localStorage.setItem(LOCAL_STORE_ITEMS.IS_AI_MAIN, String(newState.aiMain));
      return newState;
    });
  }, []);

  const onRegexValueChange = useCallback((newRegex: string) => {
    setRegexValue(newRegex);
    localStorage.setItem(LOCAL_STORE_ITEMS.REGEX, newRegex);
  }, []);

  const initializeProducerData = useCallback(() => {
    // Not 'producer' or 'store'
    if (![APPLICATION_ROLES.PRODUCER, APPLICATION_ROLES.STORE].includes(userRole)) {
      return setProducerData({});
    }

    const { email, preferred_username, given_name } = keycloakContext.keycloak?.tokenParsed || {};
    const userProducer = producerList.find(producer => email && producer.email === email);
    if (userProducer?.email && userProducer?.username) return setProducerData(userProducer);

    // [QUERY_INIT_PRODUCER_CHECK] will update the same data below
    //    when producer data does NOT exist
    // Using same data with [producerInitCheckResolver] (BE) as default value
    //    will make sure producer data will not be null
    // Support for case when loading producers is error.
    setProducerData({ email, username: preferred_username || '', name: given_name });
  }, [userRole, keycloakContext, producerList]);

  const onScrollToColumn = useCallback(
    (field: string) => {
      const targetCell = document.querySelector<HTMLElement>(`[data-field='${field}']`)?.parentElement;

      if (!tableContainerElement || !targetCell || !endPinnedCellElement) return;
      const targetCellLeft = targetCell.getBoundingClientRect().left;
      const endPinnedCellLeft = endPinnedCellElement.getBoundingClientRect().left;

      tableContainerElement.scroll({ left: targetCellLeft - endPinnedCellLeft, behavior: 'smooth' });
    },
    [endPinnedCellElement, tableContainerElement]
  );

  const onReset = useCallback(
    async (isNotAll?: boolean) => {
      await clearLocalStore();
      setUploadProgress(contextDefaultValue.uploadProgress);
      setErrorList([]);

      if (isNotAll) return;

      history.push(getStepPath(1));
      setProductType('');
      initializeProducerData();
      setRegexValue('');
      setSelectedCategoryTypeId('');

      setImageSettings(contextDefaultValue.imageSettings);
    },
    [history, clearLocalStore, initializeProducerData]
  );

  const value = useMemo<TControlContextValue>(
    () => ({
      userRole,

      loadingLocal,
      setLoadingLocal,
      step,
      onStepChange,
      indexedDB,

      selectedCategoryTypeId,
      setSelectedCategoryTypeId,
      categoryColumnList,

      // excel
      limit,
      onLimitChange,
      page,
      setPage,
      productType,
      onProductTypeChange,
      producerList,
      producerData,
      setProducerList,
      onProducerIdChange,

      // product images
      hasClickedUploadImage,
      setHasClickedUploadImage,
      isConvertingImages,
      setConvertingImages,
      regexValue,
      onRegexValueChange,
      uploadProgress,
      setUploadProgress,
      imageSettings,
      onImageSettingChange,

      tableContainerElement,
      setTableContainerElement,
      tableDownloadContainerElement,
      setTableDownloadContainerElement,
      endPinnedCellElement,
      setEndPinnedCellElement,
      onScrollToColumn,

      errorList,
      setErrorList,

      onReset,
    }),
    [
      userRole,

      loadingLocal,
      step,
      onStepChange,
      indexedDB,

      selectedCategoryTypeId,
      categoryColumnList,

      limit,
      onLimitChange,
      page,
      productType,
      onProductTypeChange,
      producerList,
      producerData,
      setProducerList,
      onProducerIdChange,
      hasClickedUploadImage,
      isConvertingImages,
      regexValue,
      onRegexValueChange,
      uploadProgress,
      imageSettings,
      onImageSettingChange,

      tableContainerElement,
      tableDownloadContainerElement,
      endPinnedCellElement,
      onScrollToColumn,

      errorList,
      setErrorList,

      onReset,
    ]
  );

  useLayoutEffect(() => {
    setStep(getStepNumber(stepPath) || 1);
    localStorage.setItem(LOCAL_STORE_ITEMS.STEP, String(getStepNumber(stepPath) || 1));
  }, [stepPath]);

  // load selected category type
  useEffect(() => {
    const newCategoryTypeId = localStorage.getItem(LOCAL_STORE_ITEMS.CATEGORY_TYPE_ID) || '';
    setSelectedCategoryTypeId(newCategoryTypeId);
  }, []);

  // load saved productType
  useLayoutEffect(() => {
    const newProductType = localStorage.getItem(LOCAL_STORE_ITEMS.PRODUCT_TYPE) || '';

    onProductTypeChange(newProductType);
  }, [onProductTypeChange]);

  // select current user as producer automatically
  useEffect(() => {
    initializeProducerData();
  }, [initializeProducerData]);

  // load saved limit and producer
  useEffect(() => {
    const newLimit = localStorage.getItem(LOCAL_STORE_ITEMS.LIMIT) || DEFAULT_LIMIT;
    const newProducerId = localStorage.getItem(LOCAL_STORE_ITEMS.PRODUCER_ID) || '';

    if (newLimit && !Number.isNaN(Number(newLimit))) setLimit(Number(newLimit));
    if (newProducerId) {
      const newProducer = producerList.find(({ id }) => id === newProducerId);
      if (newProducer) setProducerData(newProducer);
    }
  }, [producerList]);

  // load saved regex
  useEffect(() => {
    // loading data from localStorage always finished before loading data from indexDB
    // So don't need to call [updateMatchedImageFileData]
    setRegexValue(localStorage.getItem(LOCAL_STORE_ITEMS.REGEX) || '');
  }, []);

  // load saved image's settings
  useEffect(() => {
    const newImageSettings = { ...contextDefaultValue.imageSettings };
    if (localStorage.getItem(LOCAL_STORE_ITEMS.PARSE_IMAGE_BY)) {
      newImageSettings.parseImageBy = localStorage.getItem(LOCAL_STORE_ITEMS.PARSE_IMAGE_BY)! as keyof IProduct;
    }
    if (localStorage.getItem(LOCAL_STORE_ITEMS.IS_IGNORE_AI)) {
      newImageSettings.aiIgnore = localStorage.getItem(LOCAL_STORE_ITEMS.IS_IGNORE_AI) === 'true';
    }
    if (localStorage.getItem(LOCAL_STORE_ITEMS.IS_AI_MAIN)) {
      newImageSettings.aiMain = localStorage.getItem(LOCAL_STORE_ITEMS.IS_AI_MAIN) === 'true';
    }

    setImageSettings(newImageSettings);
  }, []);

  return <ControlContext.Provider value={value}>{children}</ControlContext.Provider>;
};

interface ControlProviderProps {
  userRole: string;
}

export const ControlProvider: FC<ControlProviderProps> = props => {
  const [indexedDB, setIndexedDB] = useState<IndexedDB | null>(null);

  const clearLocalStore = async () => {
    localStorage.removeItem(LOCAL_STORE_ITEMS.STEP);
    localStorage.removeItem(LOCAL_STORE_ITEMS.CATEGORY_TYPE_ID);
    localStorage.removeItem(LOCAL_STORE_ITEMS.PRODUCT_TYPE);
    localStorage.removeItem(LOCAL_STORE_ITEMS.PRODUCER_ID);
    localStorage.removeItem(LOCAL_STORE_ITEMS.REGEX);
    localStorage.removeItem(LOCAL_STORE_ITEMS.PARSE_IMAGE_BY);
    localStorage.removeItem(LOCAL_STORE_ITEMS.IS_IGNORE_AI);
    localStorage.removeItem(LOCAL_STORE_ITEMS.IS_AI_MAIN);
    localStorage.removeItem(LOCAL_STORE_ITEMS.UPLOADED_PRODUCT_STATUS_SET);
    localStorage.removeItem(LOCAL_STORE_ITEMS.REMOVED_PRODUCT_INDEXS);
    await indexedDB?.clearObjectStore(OBJECT_STORAGE_NAME);
  };

  useEffect(() => {
    createIndededDB().then(database => setIndexedDB(database));
  }, [setIndexedDB]);

  if (!indexedDB) return null;

  return (
    <ErrorBoundary onError={clearLocalStore}>
      <ControlProviderBase {...props} indexedDB={indexedDB} clearLocalStore={clearLocalStore} />
    </ErrorBoundary>
  );
};
