import React from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemAvatar, Avatar } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import IProduct from '../models/product';

interface IOrderItem {
    product: IProduct;
    quantity: number;
}

interface IOrder {
    items: IOrderItem[];
}

interface IOrderDetailsProps {
    order: IOrder;
}

export const OrderDetails = (props: IOrderDetailsProps) => {
    const { order, ...rest } = props;
    return (
        <List dense {...rest}>
            {order.items.map(value => {
                let labelId = `order-detail-${value.product.id}`;
                let unit = value.product.consumer_size_to_order_size_ratio ? value.product.consumer_size_to_order_size_ratio + ' st' : '-';
                return (
                    <ListItem key={labelId}>
                        <ListItemAvatar>
                            <Avatar
                                alt="Product Picture"
                                src={value?.product?.pictures?.small?.url}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            id={labelId}
                            primary={
                                <Typography
                                    component={Link}
                                    to={`/products/${value.product.id}/show`}
                                    target="_blank"
                                    color="inherit"
                                >
                                    {value.product.name}
                                </Typography>
                            }
                            secondary={`Quantity: ${value.quantity}, Unit: ${unit} | EAN: ${value.product.EAN}`}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default OrderDetails;
