import { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useBulkImportContext } from '../../BulkImportContext';
import EditProductImageModal from '../EditProductImageModal';
import TruncateText from 'components/TruncateText';

import useFixedHeader from '../../useFixedHeader';
import { IImportColumn, IProductExcel } from 'utils/excelUtils-old';
import { SUPERUSER_APPROVAL } from 'components/constants-ts';

import ReviewTableTemplateHeaderCell from './ReviewTableTemplateHeaderCell';
import ReviewTableBodyCell from './ReviewTableBodyCell';
import ReviewTablePagination from './ReviewTablePagination';
// special columns
import FixedColumnList, { PIN_COLUMN } from './SpecialColumns/FixedColumnList';
// import ApproveUpdateColumn from './SpecialColumns/ApproveUpdateColumn';
import ImageColumn from './SpecialColumns/ImageColumn';

import { useStyles } from './index.style';

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 20;

interface ReviewTableProps {
  dataList: IProductExcel[];
  columnList: IImportColumn[];
  isReview?: boolean;
  showErrorOnly?: boolean;
  errorDataList?: IProductExcel[];
  defaultLimit?: number;
  setScrollElement?: (element: HTMLDivElement | null) => void;
  fixedHeadWrapper?: HTMLDivElement | null;
}

const ReviewTable: FC<ReviewTableProps> = ({
  showErrorOnly,
  errorDataList,
  dataList,
  columnList,
  isReview,
  defaultLimit = DEFAULT_LIMIT,
  setScrollElement,
  fixedHeadWrapper,
}) => {
  const classes = useStyles();
  const {
    excelFile,
    limit: limitState,
    onLimitChange,
    editingImageProductId,
    setEditingImageProductId,
    tempApproveEanStatus,
  } = useBulkImportContext();

  const [tableWrapper, setTableWrapper] = useState<HTMLDivElement | null>(null);
  const [fixedRowElement, setFixedRowElement] = useState<HTMLTableRowElement | null>(null);

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [limit, setLimit] = useState(defaultLimit);

  useFixedHeader({
    tableWrapper,
    fixedWrapper: fixedHeadWrapper,
    fixedRowElement,
    top: 64,
  });

  const filteredColumnList = useMemo(() => {
    if (isReview) return columnList;
    // remove pin column
    return columnList.filter(({ field }) => field !== PIN_COLUMN);
  }, [columnList, isReview]);

  const displayDataList = useMemo(() => {
    if (!isReview && showErrorOnly && errorDataList?.length) {
      return errorDataList.slice((currentPage - 1) * limit, currentPage * limit);
    }

    return dataList.slice((currentPage - 1) * limit, currentPage * limit);
  }, [isReview, showErrorOnly, errorDataList, dataList, currentPage, limit]);

  const handleLimitChange = (limit: number) => {
    if (!isReview) onLimitChange(limit);
    setLimit(limit);
    setCurrentPage(DEFAULT_PAGE);
  };

  useEffect(() => {
    if (limitState) {
      setLimit(limitState);
    }
  }, [limitState]);

  const renderPagination = () => (
    <ReviewTablePagination
      limit={limit}
      page={currentPage}
      onPageChange={setCurrentPage}
      onLimitChanged={handleLimitChange}
    />
  );

  const renderTemplateHeader = () => {
    return (
      <>
        <FixedColumnList.TemplateHeaderCellList isReview={isReview} columnList={columnList} />

        <ImageColumn.TemplateHeaderCell isReview={isReview} dataList={dataList} />

        {filteredColumnList.map(({ field, color, title, isError, firstErrorCellId }, index) => (
          <ReviewTableTemplateHeaderCell key={index} field={field} style={{ background: color }}>
            <TruncateText title={title} fixedAfter={!isReview && isError ? `(${firstErrorCellId})` : ''} />
          </ReviewTableTemplateHeaderCell>
        ))}

        {/* Todo */}
        {/* <ApproveUpdateColumn.TemplateHeaderCell dataList={dataList} /> */}
      </>
    );
  };

  const renderFieldHeader = () => (
    <>
      <FixedColumnList.FieldHeaderCellList isReview={isReview} columnList={columnList} />

      <ImageColumn.FieldHeaderCell />

      {filteredColumnList.map(({ field }, index) => (
        <TableCell key={index} className={classes.headCell}>
          <div data-field={`${field}`}>
            <TruncateText title={field} />
          </div>
        </TableCell>
      ))}

      {/* Todo */}
      {/* <ApproveUpdateColumn.FieldHeaderCell /> */}
    </>
  );

  return (
    <Box position="relative">
      {!!fixedHeadWrapper &&
        createPortal(<TableRow ref={setFixedRowElement}>{renderTemplateHeader()}</TableRow>, fixedHeadWrapper)}

      {renderPagination()}

      {!!excelFile?.name && (
        <Box mb={1} sx={{ fontWeight: 'bold' }}>
          {excelFile.name}
        </Box>
      )}

      <TableContainer
        ref={(element: HTMLDivElement) => {
          setScrollElement?.(element);
          setTableWrapper(element);
        }}
        component={Paper}
      >
        <Table id="review-table" className={classes.table}>
          <TableHead>
            <TableRow>{renderTemplateHeader()}</TableRow>
            {!isReview && <TableRow>{renderFieldHeader()}</TableRow>}
          </TableHead>

          <TableBody>
            {displayDataList.map(data => {
              const adminStatus = tempApproveEanStatus[data.EAN || ''];
              const isApproved = adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED;

              return (
                <TableRow key={data.id}>
                  <FixedColumnList.BodyCellList
                    isReview={isReview}
                    isApproved={isApproved}
                    columnList={columnList}
                    data={data}
                  />

                  <ImageColumn.BodyCell
                    isReview={isReview}
                    data={data}
                    onClick={() => setEditingImageProductId(String(data.id || ''))}
                  />

                  {filteredColumnList.map((column, index) => (
                    <ReviewTableBodyCell key={index} isReview={!!isReview} column={column} data={data} />
                  ))}

                  {/* Todo */}
                  {/* <ApproveUpdateColumn.BodyCell data={data} /> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {renderPagination()}

      {!!editingImageProductId && (
        <EditProductImageModal
          open={!!editingImageProductId}
          productId={editingImageProductId}
          onClose={() => setEditingImageProductId('')}
        />
      )}
    </Box>
  );
};

export default ReviewTable;
