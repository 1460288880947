import React from 'react';
import IModel from '../../../../models/model';

import InputTextField from '../../../inputfields/InputTextField';

interface IProductField<T extends IModel> {
  state: T;
  setState: (state: T) => void;
  label?: string;
  field: keyof T;
  required?: boolean;
}

export const GenericField = <T extends IModel, >({ state, setState, label, field, required = true }: IProductField<T>) => {
  // TODO any
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });

  return (
    <InputTextField
      label={label}
      dense={false}
      inputName={field}
      required={required}
      value={state[field]}
      onChange={handleChange}
      withLabel
      labelOptions={{
        heading: label,
      }}
    />
  );
};

export default GenericField;
