import { FC, ReactNode } from 'react';
import _ from 'lodash';

import { Box, IconButton } from '@material-ui/core';

import TickIcon from 'components/icons/TickIcon';
import FileIcon from 'components/icons/FileIcon';
import CloseIcon from 'components/icons/CloseIcon';
import LinearProgress from '../components/LinearProgress';

const formatBytes = (bytes: number) => {
  if (!+bytes) return '0 B';
  const unit = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const powTime = Math.floor(Math.log(bytes) / Math.log(unit));

  return `${parseFloat((bytes / Math.pow(unit, powTime)).toFixed(2))} ${sizes[powTime]}`;
};

interface ImportProgressCardProps {
  label: string;
  size: number;
  value?: number;
  customProgressBar?: ReactNode;
  onCancel?: () => void;
}

const ImportProgressCard: FC<ImportProgressCardProps> = ({ label, size, value, customProgressBar, onCancel }) => {
  return (
    <Box bgcolor="#FFFFFF" px={1.5} py={1} border="1px solid #EDEEF3" borderRadius={4}>
      <Box display="flex" alignItems="start" gridGap={8} mb={0.5}>
        <FileIcon />
        <Box flexGrow={1}>
          <Box sx={{ fontSize: 14, color: '#383D52' }}>{label}</Box>
          <Box sx={{ fontSize: 14, color: '#737DA0' }}>{formatBytes(size)}</Box>
        </Box>
        {value === 100 ? (
          <TickIcon />
        ) : (
          <IconButton style={{ padding: 0 }} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      {customProgressBar || (
        <Box display="flex" alignItems="center" height={18}>
          <LinearProgress value={value} variant={_.isNil(value) ? undefined : 'determinate'} />
        </Box>
      )}
    </Box>
  );
};

export default ImportProgressCard;
