import React from 'react';
import VisionTab from '../tab';

const SafeSearch = ({ visionTab, image, info, isNew }) => {
  return (
    <VisionTab
      index="safeSearch"
      tabValue={visionTab}
      image={image}
      pictureWidth={7}
      info={info}
      isNew={isNew}
    >
      <p><b>Adult:</b> {info?.safeSearchAnnotation?.adult}  <b>confidence:</b> {info?.safeSearchAnnotation?.adultConfidence}</p>
      <p><b>Spoof:</b> {info?.safeSearchAnnotation?.spoof}  <b>confidence:</b> {info?.safeSearchAnnotation?.spoofConfidence}</p>
      <p><b>Medical:</b> {info?.safeSearchAnnotation?.medical}  <b>confidence:</b> {info?.safeSearchAnnotation?.medicalConfidence}</p>
      <p><b>Violence:</b> {info?.safeSearchAnnotation?.violence}  <b>confidence:</b> {info?.safeSearchAnnotation?.violenceConfidence}</p>
      <p><b>Racy:</b> {info?.safeSearchAnnotation?.racy}  <b>confidence:</b> {info?.safeSearchAnnotation?.racyConfidence}</p>
      <p><b>NSFW confidence:</b> {info?.safeSearchAnnotation?.nsfwConfidence}</p>
    </VisionTab>
  );
};

export default SafeSearch;
