import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { head } from 'ramda';
import { Query } from 'react-apollo';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { ListItemAvatar, Avatar } from '@material-ui/core';

import withContainer from '../../hoc/withContainer';
import { SUPERUSER_PRODUCERS_NOT_APPROVED_QUERY } from '../../graphql/queries';
import { useOrElse, isDefined } from '../../utils/helpers';
import { IMAGE_SIZES, getImageWithSizeSuffix } from 'utils/images2';
import COLORS from '../../constants/colors';
import useSearchParams from 'hooks/useSearchParams';

const ProducerListItem = ({ producer }) => {
  const primaryTitle = producer?.name || 'NO NAME AVAILABLE';
  const secondaryTitle = producer.username;
  const linkTo = `/superuser/producer/${producer.id}/edit`;

  let image = head(useOrElse(producer.profiles, []));
  image = isDefined(image) ? getImageWithSizeSuffix(image, IMAGE_SIZES.XSMALL) : '';

  return (
    <>
      <Link variant="inherit" underline="none" color="inherit" component={RouterLink} to={linkTo}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <img src={image} alt="P" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={primaryTitle} secondary={secondaryTitle} />
        </ListItem>
      </Link>
      <Divider />
    </>
  );
};

const ProducerList = () => {
  const { searchParams, updateSearchParams } = useSearchParams();
  let refetch2;
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [query, setQuery] = React.useState(searchParams.search || "");
  React.useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true);
    } else {
      if (refetch2) {
        refetch2({
          textQuery: query,
        });
      }
    }
  }, [query]);
  return (
    <Paper>
      <Query query={SUPERUSER_PRODUCERS_NOT_APPROVED_QUERY} variables={{ textQuery: searchParams.search }}>
      {({ loading, error, networkStatus, data, refetch }) => {
        refetch2 = refetch; // TODO
        return (
          <>
          {(!query || query.trim() === '') && (!data?.superuserProducers || data?.superuserProducers.length < 1) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                style={{ fontSize: 18, textAlign: 'center', marginTop: 20, color: COLORS.mainGreen, fontWeight: 'bold' }}
              >
                All producers approved. Nothing to do here! :)
              </span>
            </div>
          )}
          <TextField
              placeholder="Search by Producer Name"
              variant="outlined"
              value={query}
              // disabled={loading}
              style={{marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, width: 500}}
              onChange={(e) => {
                  setQuery(e.target.value);
                  updateSearchParams({ search: e.target.value });
              }}
          />
          <List dense>{data?.superuserProducers && data?.superuserProducers.map(p => <ProducerListItem producer={p} key={p.id} />)}</List>
          </>
        );
      }}
      </Query>
    </Paper>
  );
};

export default ProducerList;
