import React from 'react';
import { useQuery } from '@apollo/react-hooks';
/**
 * By giving query, variables to the enhance function (hoc), you get a new component, it doensn't have any thing special for outside of the component, but it affects the rendered component with extra props
 * the original wrapped component will be given the query result props
 * Whatever props you give to the enhanced component will be linked to the wrapped component
 */
export default (query, variables, fetchPolicy) => WrappedComponent => {
    return (props) => {
        const result = useQuery(query, { variables, fetchPolicy });
        return (<WrappedComponent {...result} {...props} />)
    };
}
