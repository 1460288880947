import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import OrderDetails from './OrderDetails';
import OrderListItem from './OrderListItem';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(14),
    },
}));

export default props => {
    const { list, isProducer, organization, logistician } = props;
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {list.map(value => {
                const labelId = `producer-item-${value.producer.id}`;
                return (
                    <React.Fragment key={labelId}>
                        <OrderListItem order={value} isProducer={isProducer} organization={organization} logistician={logistician} />
                        <OrderDetails
                            order={value}
                            className={classes.nested}
                        />
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                );
            })}
        </List>
    );
};
