import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { PRODUCT_TABS } from '../../constants/domain';
import { values } from 'ramda';

const tabIndexToValue = values(PRODUCT_TABS);

interface IDetails {
    numOfPending?: number;
    numOfApproved?: number;
    numOfDisapproved?: number;
}

interface IProductsSummary {
    details?: IDetails;
    total?: number;
}

interface IProducer {
    id?: string;
    name?: string;
    productsSummary?: IProductsSummary;
}
interface IData {
    productsSummary?: IProductsSummary;
    details?: IDetails;
    producer?: IProducer;
}

interface IProductTabsProps {
    data?: IData;
    category: PRODUCT_TABS;
    onTabChange?: (value: string) => void;
}

/**
 *  It parse the props from parent and extract statistic info from the props
 *  Pass records and data info to its children to be displayed by its child
 **/
class ProductTabs extends React.Component<IProductTabsProps> {
    // Delegate to controller to set a new filter with the tab
    // TODO any
    handleTabChange = (event: any, value: number) => {
        if (this.props.onTabChange) {
            this.props.onTabChange(tabIndexToValue[value]);
        }
    };

    render() {
        const { category, data } = this.props;
        const details = data?.productsSummary?.details 
            || data?.details
            || data?.producer?.productsSummary?.details
            || {};
        const { numOfPending, numOfApproved, numOfDisapproved } = details;
        console.log('!numOfDisapproved', numOfDisapproved);

        const tabIndex = tabIndexToValue.indexOf(category);
        return (
            <Tabs
                value={tabIndex}
                onChange={this.handleTabChange}
                centered
                variant="fullWidth"
            >
                <Tab
                    label={
                        <Badge color="secondary" badgeContent={numOfPending}>
                            Pending
                        </Badge>
                    }
                />

                <Tab
                    label={
                        <Badge color="secondary" badgeContent={numOfApproved}>
                            Approved
                        </Badge>
                    }
                />

                <Tab
                    label={
                        <Badge
                            color="secondary"
                            badgeContent={numOfDisapproved}
                        >
                            Disapproved
                        </Badge>
                    }
                />
            </Tabs>
        );
    }
}

export default ProductTabs;
