import React from 'react';
import { path } from 'ramda';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormHelperText,
  InputAdornment,
  TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { isDefined } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  selectPaper: {
    '& .MuiMenu-list': {
      paddingTop: 0
    },
  },
  menuPaper: {
    width: 'min-content'
  },
  menuItem: {
    textWrap: 'pretty'
  },
  searchWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: '8px 16px',
    backgroundColor: '#ffffff'
  },
}));

export interface IListItem {
  value: string | number;
  displayName: string;
  key?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export interface ICategoryItem {
  label?: string;
  listData?: IListItem[];
}

interface IMultipleGroupedSelectProps {
  label?: string;
  categoryData?: ICategoryItem[];
  handleChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  errorText?: string;
  value?: any;
  targetName?: string;
  style?: React.CSSProperties;
  displayEmpty?: boolean;
  disableLabel?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
}

const containsText = (text?: string, searchText?: string) => {
  if (!searchText || searchText.trim() === '') {
    return true;
  }
  if (!text || text.trim() === '') {
    return false;
  }
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const getFormattedValue = (value: ((string | number | undefined)[]), categoryData: ICategoryItem[] = []) => {
  const selectedItems: string[] = [];

  categoryData.forEach(category => {
    category.listData?.forEach(item => {
      if (value.includes(item.value)) selectedItems.push(item.displayName);
    });
  });

  return selectedItems.join(', ');
}

const MultipleGroupedSelect = ({
  label,
  categoryData,
  handleChange: handleChangeProp,
  errorText,
  value,
  targetName,
  style,
  displayEmpty,
  disableLabel,
  showSearch,
  disabled,
}: IMultipleGroupedSelectProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const searchWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const isError = !!(errorText && errorText.trim() !== '');

  const filteredData = React.useMemo(() => {
    if (!categoryData) {
      return [];
    }
    if (!searchText || searchText.trim() === '') {
      return categoryData;
    }

    const filteredData = categoryData.map(item => ({
      ...item,
      listData: item?.listData?.filter(element => containsText(element?.displayName, searchText))
    }));
    
    return filteredData;
  }, [categoryData, searchText]);

  const handleOpen = () => {
    setSearchText('');
    setOpen(true);
  };

  const handleClose = (event: React.ChangeEvent<{}>) => {
    if (!searchWrapperRef.current?.contains(event.nativeEvent.target as Node)) {
      setOpen(false);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => {
    if (!searchWrapperRef.current?.contains(event.nativeEvent.target as Node)) {
      handleChangeProp?.(event, child);
    }
  };
  
  return (
    <div style={style}>
      <FormControl
        fullWidth
        size='medium'
        variant="outlined"
        error={isError}
        disabled={disabled}
        style={{ background: "#FFFFFF" }}
      >
        {!disableLabel && (
          <InputLabel id="multiply-grouped-select-label">
            {label}
          </InputLabel>
        )}
        <Select
          labelId="multiply-grouped-select"
          id="multiply-grouped-select"
          value={value}
          renderValue={() => getFormattedValue(value, categoryData)}
          open={open}
          onChange={handleChange}
          onOpen={handleOpen}
          onClose={handleClose}
          label={label}
          displayEmpty={displayEmpty}
          name={targetName}
          multiple
          MenuProps={{
            autoFocus: !!value,
            classes:{paper: classes.menuPaper},
            PaperProps: {
              className: showSearch ? classes.selectPaper : undefined,
            },
          }}
        >
          {showSearch && (
            <div ref={searchWrapperRef} className={classes.searchWrapper}>
              <TextField
                fullWidth
                size="small"
                autoFocus={!value}
                variant="outlined"
                placeholder="Type to search..."
                InputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill (off not working in Chrome)
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behavior)
                    e.stopPropagation();
                  }
                }}
              />
            </div>
          )}
          {displayEmpty && (
            <MenuItem value={undefined}>
              <em style={{ visibility: 'hidden' }}>None</em>
            </MenuItem>
          )}
          {filteredData?.map(item => item.listData?.length !== 0 ? 
            [
              <ListSubheader
                onClickCapture={(e) => e.stopPropagation()}
                style={{ background: "#FFFFFF", color: '#000000', fontWeight: 'bold' }}
              >
                {item.label}
              </ListSubheader>,
              item.listData?.map(item => (
                <MenuItem
                  value={item.value}
                  key={item.key || item.value}
                  disabled={item.disabled}
                  style={{ fontSize: 16, ...item.style}}
                >
                  <Checkbox color="primary" checked={value.includes(item.value)} />
                  <em className={classes.menuItem}>{isDefined(path(['displayName'], item)) ? item.displayName : item.value}</em>
                </MenuItem>
              ))
            ] : []
          )}
        </Select>
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default MultipleGroupedSelect;
