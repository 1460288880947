import { useContext, useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";

import { path } from "ramda";

import { KeycloakContext } from "components/Secured";
import { restEndpoints } from "constants/domain";
import { extractURLParam } from "utils/urlUtils";

const IcaExportProducts = () => {
  const keycloakCtx = useContext(KeycloakContext);

  // const eans = extractURLParam('eans', window.location.toString());
  // const eansQs = (eans && eans.trim() !== '') ? `&eans=${eans}`: '';

  const username = extractURLParam('username', window.location.toString());
  const usernameQs = (username && username.trim() !== '') ? `&username=${username}` : '';

  const includeAll = extractURLParam('includeAll', window.location.toString()) === "1";
  const includeDisapprovedQs = includeAll ? '&includeAll=1' : '';

  const endpoint = `${restEndpoints.icaExcelDownload}?${usernameQs}${includeDisapprovedQs}`;

  const [url, setUrl] = useState('');

  const downloadExcel = async () => {
    const token = path(['keycloak', 'token'], keycloakCtx);

    fetch(endpoint, {
      method: 'POST',
      headers: {
      Authorization: `Bearer ${token}`,
      },
    })
    .then(r => r.blob())
    .then(b => {
      const url = window.URL.createObjectURL(new Blob([b]));
      setUrl(url);
    });
  };
  useEffect(() => {
    downloadExcel();
  }, []);
  return (
    <Paper>
      <a href={url} download="produkter.xlsx">Download Excel</a>
    </Paper>
  );
};

export default IcaExportProducts;
