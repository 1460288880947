import { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import InputTextField from "components/inputfields/InputTextField";
import IProduct from "models/product";
import FloatInput from "../FloatInput";
import { isDefined } from "utils/helpers-ts";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const isEmpty = (product?: IProduct) => {
  if (!product) return true;
  if (
    // (product.storePackUOMType && product.storePackUOMType.trim() !== '')
    (product.storePackEANForStorePack && product.storePackEANForStorePack.trim() !== '')
    || product.storePackOrderableUnit
    || product.storePackDespatchUnit
    || isDefined(product.storePackTradeItemMeasurementsLengthDepth)
    || isDefined(product.storePackTradeItemMeasurementsWidth)
    || isDefined(product.storePackTradeItemMeasurementsHeight)
    || isDefined(product.storePackGrossWeight)
  ) {
    return false;
  }
  return true;
};

interface IStorePackProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const scrollWithOffset = (elementId: string) => {
  const el = document.getElementById(elementId);
  if (el) {
    el.focus();
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const StorePack = ({ state, setState }: IStorePackProps) => {
  const classes = useStyles();
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });
  const onLabelChanged = ({ target }: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setState({...state, [target.name]: checked }); 
  };
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    setExpanded(!isEmpty(state));
  }, []);
  const handleAccordionChange = (event: any, isExpanded?: boolean) => {
    setExpanded(!!isExpanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>Butiksförpackning / Store pack</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {/* <InputTextField
            state={state}
            setState={setState}
            inputName="storePackUOMType"
            value={state.storePackUOMType}
            label="Unit of measure type"
            onChange={handleChange}
            withLabel
          /> */}
          <InputTextField
            state={state}
            setState={setState}
            inputName="storePackEANForStorePack"
            value={state.storePackEANForStorePack}
            label="EAN/GTIN for store pack"
            onChange={handleChange}
            withLabel
          />
          <p><b>Quantity of base item</b> & <b>Quantity of next lower item</b>: Value same as <a href="#" onClick={(e) => {e.preventDefault(); scrollWithOffset("consumer_size_to_order_size_ratio"); }}>B-packstorlek (Kfp/Dfp)</a></p>
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.storePackOrderableUnit}
                onChange={onLabelChanged}
                name="storePackOrderableUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Orderable Unit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.storePackDespatchUnit}
                onChange={onLabelChanged}
                name="storePackDespatchUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Despatch Unit"
          />
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <FloatInput
              name="storePackTradeItemMeasurementsLengthDepth"
              label="Depth/Length"
              state={state}
              setState={setState}
              dense={false}
            />
            <span style={{alignSelf: 'center', fontSize: 25}}>&nbsp;x&nbsp;</span>
            <FloatInput
              name="storePackTradeItemMeasurementsWidth"
              label="Width"
              state={state}
              setState={setState}
              dense={false}
            />
            <span style={{alignSelf: 'center', fontSize: 25}}>&nbsp;x&nbsp;</span>
            <FloatInput
              name="storePackTradeItemMeasurementsHeight"
              label="Height"
              state={state}
              setState={setState}
              dense={false}
            />
            <h3 style={{alignSelf: 'center'}}>&nbsp;millimeter</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <FloatInput
              name="storePackGrossWeight"
              label="Gross weight"
              state={state}
              setState={setState}
              dense={false}
            />
            <h3 style={{alignSelf: 'center'}}>&nbsp;gram</h3>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default StorePack;
