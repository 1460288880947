import React from "react";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CircularProgress from "@material-ui/core/CircularProgress";

import get from "lodash/get";

import { ITopProducts } from "./types";
import Statistic from "../../../components/Statistic";

interface IRowProps {
  row: ITopProducts;
  days?: number;
}

const Row = ({row, days}: IRowProps) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<ITopProducts[]>([]);
  const keys = Object.getOwnPropertyNames(row).filter(x => x !== 'id' && x !== 'ean');
  return (
    <>
      {loading ? (
        <Box style={{display: "flex", alignItems:"center", justifyContent: "center", flexDirection: "column", padding: 10}}>
          <CircularProgress />
          <Typography>Loading lots of data...</Typography>
          <Typography>This might take a while</Typography>
        </Box>
      ) : (
        <>
          <TableRow key={row.id}>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {keys.map((key) => (
              <TableCell align={key === "name" ? "left" : "right"}>
                {get(row, key)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={keys.length + 1}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Statistic 
                    ean={row.ean} 
                    days={days} 
                    showRevenueChart={true} 
                    columns={['checkouts', 'revenue']}
                    hideZeroValues={true}
                  />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default Row;
