import React, { useState } from 'react';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';

import { useQuery } from '@apollo/react-hooks';
import { GET_ORDERNUMBER, GET_PRODUCER_NOTES } from '../../graphql/queries';
import { isDefined } from '../../utils/helpers';

const defView = (showNotes, suggest, onChange, producerId, notes, estimateText, estimateDate) => (
  <div>
  {showNotes && (
    <>
      <br />
      <TextField
        // style={style}
        fullWidth
        // multiline={multiline}
        // rows={multilineRows}
        // rowsMax={multilineMax}
        id={`notes-${producerId}`}
        // label={label}
        margin="normal"
        name="notes"
        // disabled={disabled}
        // required={required}
        // autoFocus={autoFocus}
        value={notes}
        variant="outlined"
        onChange={onChange}
        placeholder="Skicka med ett meddelande till producenten..."
      />
    </>
  )}
  {suggest && (
    <>
      <br />
      <span style={{color: "darkgrey"}}>{estimateText} </span><span style={{color: "red"}}>{estimateDate}</span>.
    </>
  )}
  </div>
);

export default function ProducerLatestOrder(props) {
    const { producerId, suggest, daysLeft, isNegativeDays, lastOrderDaysAgo, showNotes } = props;
    let ordersQuery = GET_ORDERNUMBER;
    const [notes, setNotes] = useState(localStorage.getItem(`notes-${producerId}`));

    const { loading: notesLoading, error: notesError, data: notesData } = useQuery(GET_PRODUCER_NOTES);

    // This is for product estimates
    let estimateText;
    let estimateDate;
    if (suggest) {
      let lastOrderDaysAgoText = moment().subtract(Math.abs(lastOrderDaysAgo), "d").fromNow();
      estimateText = "Product was last ordered " + lastOrderDaysAgoText + " and we estimate it sold out";

      if (isNegativeDays) {
        estimateDate = moment().subtract(Math.abs(daysLeft), "d").fromNow();
        estimateText = "Product was last ordered " + lastOrderDaysAgoText + " and we estimate it sold out";
      } else {
        estimateDate = "in " + parseInt(Math.abs(daysLeft)) + " days"; // moment(Math.abs(daysLeft), "d").fromNow();
        estimateText = "Product was last ordered " + lastOrderDaysAgoText + " and we estimate it will sell out";
      }
    }
    const onChange = (e) => {
      const value = e.target.value;
      let items = localStorage.getItem("PRODUCER_NOTES");
      items = !!items ? JSON.parse(items) : [];
      if (!items.includes(producerId)) {
        items = [...items, producerId];
      }
      localStorage.setItem(`notes-${producerId}`, value);
      setNotes(value);
      localStorage.setItem("PRODUCER_NOTES", JSON.stringify(items));
    };

    const { loading, error, data } = useQuery(ordersQuery, {
        variables: {
            Filter: {
                ordersFilter: {
                    producerId: producerId,
                }
            },
            Pagination: {
                type: "TOP",
                limit: 1,
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    if (loading) {
      return <span />;
    }
    if (error) {
      return defView(showNotes, suggest, onChange, producerId, notes, estimateText, estimateDate);
      //return <span />;
    }
    if (!isDefined(data) || data.groupOrders.data.length === 0) {
      return defView(showNotes, suggest, onChange, producerId, notes, estimateText, estimateDate);
      //return <span />;
    }

    const createdAt = data.groupOrders.data[0].createdAt;
    let createAgo = moment(createdAt, "x").fromNow();
    const groupOrderNumber = data.groupOrders.data[0].groupOrderNumber;


    return (
        <span style={{fontWeight: "bold"}}>
          (
            <>
              <span style={{color: "darkgrey"}}>Most recent order to this producer was placed </span><span style={{color: "red"}}>{createAgo}</span>.
              <> <a href={`/notification/${groupOrderNumber}`} target="_blank" rel="noopener noreferrer">See it here &rarr;</a></>
            </>
          )
          {showNotes && (
            <>
              <br />
              <TextField
                // style={style}
                fullWidth
                // multiline={multiline}
                // rows={multilineRows}
                // rowsMax={multilineMax}
                id={`notes-${producerId}`}
                // label={label}
                margin="normal"
                name="notes"
                // disabled={disabled}
                // required={required}
                // autoFocus={autoFocus}
                value={notes}
                variant="outlined"
                onChange={onChange}
                placeholder="Skicka med ett meddelande till producenten..."
              />
            </>
          )}
          {suggest && (
            <>
              <br />
              <span style={{color: "darkgrey"}}>{estimateText} </span><span style={{color: "red"}}>{estimateDate}</span>.
            </>
          )}
        </span>
    );
}
