import { FC } from 'react';
import { makeStyles } from '@material-ui/core';

import { IProductExcel } from '../../../types';
import useDataContext from 'components/product/BulkImport/hooks/useDataContext';

import TrashIcon from 'components/icons/TrashIcon';
import ReviewTableHeaderCell from '../ReviewTableHeaderCell';

import { useStyles } from '../index.style';

const useCustomStyles = makeStyles(() => ({
  deleteButton: {
    padding: 4,
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
}));

interface ActionHeaderCellProps {
  isReview: boolean;
}

export const ActionHeaderCell: FC<ActionHeaderCellProps> = ({ isReview }) => {
  if (isReview) return null;

  return (
    <ReviewTableHeaderCell
      title=""
      id="action"
      field=""
      resizable={false}
      isFieldUpdatable={false}
      isReview={isReview}
      showField={false}
      minWidth={40}
      dataType="string"
    />
  );
};

interface ActionBodyCellProps {
  isReview: boolean;
  data: IProductExcel;
}

const ActionBodyCell: FC<ActionBodyCellProps> = ({ isReview, data }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const { onRemoveData } = useDataContext();

  if (isReview || !data.id) return null;

  return (
    <td className={classes.bodyCell}>
      <div className={customClasses.deleteButton} onClick={() => onRemoveData(data.id || '')}>
        <TrashIcon width={20} height={20} style={{ display: 'block', color: 'red' }} />
      </div>
    </td>
  );
};

const ActionColumn = {
  HeaderCell: ActionHeaderCell,
  BodyCell: ActionBodyCell,
};

export default ActionColumn;
