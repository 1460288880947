import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import NoData from './NoData';
import Chart from '../pages/superuser/data/chart';

const useStyles = makeStyles(theme => ({
  table: {
    // minWidth: 500,
    maxWidth: '70%',
  },
  lineView: {
    marginBottom: theme.spacing(2)
  }
}));

const STATISTIC_ITEMS = (columns: (keyof TStatisticItem)[]) => gql`
  query statistic($skuFilter: ProductSKU, $days: Int) {
    statisticItems(filter: $skuFilter, days: $days) {
      id
      date
      ${columns.join('\n')}
    }
  }
`;

type TSkuFilter = {
  SKU?: string;
};

type TStatisticVars = {
  skuFilter: TSkuFilter;
  days?: number;
};

type TStatisticItem = {
  id?: number;
  date?: string;
  checkouts?: number;
  listView?: number;
  revenue?: number;
};

type TStatisticRoot = {
  statisticItems?: TStatisticItem[];
};

interface IStatisticProps {
  ean?: string;
  days?: number;
  showRevenueChart?: boolean;
  showQuantityChart?: boolean;
  showListViewChart?: boolean;
  columns: (keyof TStatisticItem)[];
  hideZeroValues?: boolean;
}

export const Statistic = ({ 
  ean, 
  days, 
  showRevenueChart,
  showQuantityChart,
  showListViewChart,
  columns,
  hideZeroValues,
}: IStatisticProps) => {
  //const [state, setState] = React.useState({ items: {} });
  const classes = useStyles();
  

  const {loading, error, data, refetch} = useQuery<TStatisticRoot, TStatisticVars>(STATISTIC_ITEMS(columns), {
    variables: {
      skuFilter: { SKU: ean },
      days,
    }
  });

  if (!ean) {
    return <>No EAN code provided</>;
  }

  let items = data?.statisticItems;
  if (items && hideZeroValues) {
    items = items.filter((x) => {
      const allZero = columns.every(column => !x[column]);
      return !allZero;
    });
  }
  return (
    <>
        {loading && (
          <CircularProgress />
        )}
        {error && (
          <p>Error :(</p>
        )}
        {!loading && !error && (
          <>
            {data && Array.isArray(items) && items.length > 0 ? (
              <>
                {showRevenueChart && (
                  <Chart
                    data={data?.statisticItems?.map((x) => ({...x, date: moment(x.date).format('YYYY-MM-DD')}))}
                    items={[
                      { key: "revenue", stroke: "#8884d8" },
                      // { key: "listView", stroke: "#82ca9d" },
                      // { key: "clicks", stroke: "blue" },
                    ]}
                  />
                )}
                {showQuantityChart && (
                  <Chart
                    data={data?.statisticItems?.map((x) => ({...x, date: moment(x.date).format('YYYY-MM-DD')}))}
                    items={[
                      { key: "checkouts", stroke: "#8884d8" },
                      // { key: "listView", stroke: "#82ca9d" },
                      // { key: "clicks", stroke: "blue" },
                    ]}
                  />
                )}
                {showListViewChart && (
                  <Chart
                    data={data?.statisticItems?.map((x) => ({...x, date: moment(x.date).format('YYYY-MM-DD')}))}
                    items={[
                      { key: "listView", stroke: "#8884d8" },
                      // { key: "listView", stroke: "#82ca9d" },
                      // { key: "clicks", stroke: "blue" },
                    ]}
                  />
                )}
                {/* <ProductSalesChartContainer
                  className={classes.lineView} 
                  data={data.statisticItems} 
                  width={"95%"} 
                  height={300} 
                /> */}

                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        {columns.includes('checkouts') && <TableCell align="right">Quantity</TableCell>}
                        {columns.includes('revenue') && <TableCell align="right">Revenue</TableCell>}
                        {columns.includes('listView') && <TableCell align="right">ListView</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items?.map(row => (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                          >
                            {row.date && new Date(row.date).toLocaleDateString()}
                          </TableCell>
                          {columns.includes('checkouts') && (
                            <TableCell align="right">
                              {row.checkouts}
                            </TableCell>
                          )}
                          {columns.includes('revenue') && (
                            <TableCell align="right">
                              {row.revenue}
                            </TableCell>
                          )}
                          {columns.includes('listView') && (
                            <TableCell align="right">
                              {row.listView}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>) : <NoData />}
          </>
        )}
    </>
  );
};

export default Statistic;
