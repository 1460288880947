import React, { ReactNode, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { isDefined } from '../../utils/helpers';

interface SnackBarProps {
  message: string;
  duration: number;
  onClose?: () => void;
}

const SnackBar = ({ message, duration, onClose }: SnackBarProps) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose?.();
    };

    const autoHideDuration = isDefined(duration) ? duration : 1200;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{message}</span>}
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
            style={{ zIndex: 1300 }}
        />
    );
};
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface IResponseSnackBarProps {
    title: string;
    message: ReactNode;
    success: boolean;
    additionalMessage?: string;
    additionalSuccess?: boolean;
    duration?: number;
    onSnackBarClicked?: any;
    onClosedCallback?: any;
}

export const ResponseSnackBar = ({
     message,
     success,
     additionalMessage,
     additionalSuccess,
     duration = 7000,
     onSnackBarClicked,
     onClosedCallback,
     title,
 }: IResponseSnackBarProps) => {
    const [open, setOpen] = useState(true);

    const handleClose = (event: any, reason: string) => {
        if (isDefined(onClosedCallback)) {
            setOpen(false);
            onClosedCallback();
            return;
        }

        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            onClick={isDefined(onSnackBarClicked) ? onSnackBarClicked : () => {}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div>
                <div style={isDefined(onSnackBarClicked) ? { cursor: 'pointer' } : {}}>
                    <Alert severity={success ? 'info' : 'warning'} onClose={handleClose}>
                        {isDefined(title) && (<AlertTitle>
                            {title}
                        </AlertTitle>)}
                        {message}
                    </Alert>
                </div>
                {isDefined(additionalMessage) && (
                    <div style={{ marginTop: 15 }}>
                        <Alert severity={additionalSuccess ? 'info' : 'warning'} onClose={handleClose}>
                            {additionalMessage}
                        </Alert>
                    </div>
                )}
            </div>
        </Snackbar>
    );
};

export default SnackBar;
