import React from 'react';
import Typography from '@material-ui/core/Typography';

const SimpleText = ({data}: {data?: string}) => {
  return(
    <Typography variant="h3">{ data ? data : '-'}</Typography>
  );
}

export default SimpleText;
