import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import FreeBreakfastOutlinedIcon from '@material-ui/icons/FreeBreakfastOutlined';
const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
}));
export default function(props) {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <FreeBreakfastOutlinedIcon />
            <Typography variant="body2"> No data </Typography>
        </Box>
    );
}
