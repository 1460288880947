import React, { Component } from 'react';
import ErrorBox from './Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Box } from '@material-ui/core';

const REACT_APP_AUTH_ENV = process.env.REACT_APP_AUTH_ENV;
export const KeycloakContext = React.createContext();

export const withAuthorization = WrappedComponent => {
    return class extends Component {
        static contextType = KeycloakContext;
        constructor(props) {
            super(props);
            this.state = { authenticated: undefined };
        }

        componentDidMount() {
            const keycloakPromise = this.context.keycloak.init({
                onLoad: 'login-required',
                promiseType: 'native',
                checkLoginIframe: REACT_APP_AUTH_ENV === 'local' ? false : true,
            });
            keycloakPromise
                .then(
                    authenticated => {
                        this.setState({ authenticated: authenticated });
                    },
                    error => {
                        this.setState({
                            authenticated: null,
                            error: error ? error : 'Failed to authenticate',
                        });
                    }
                )
                .catch(exception =>
                    this.setState({
                        authenticated: null,
                        error: exception
                            ? exception
                            : 'Failed to start authentication process',
                    })
                );
        }

        render() {
            if (this.state.authenticated) {
                return <WrappedComponent {...this.props} />;
            } else if (this.state.error) {
                return <ErrorBox message={this.state.error} />;
            }
            
            return <Box style={{position:'fixed',top:'50%',left:'50%', marginLeft:'-100px', marginTop:'-50px'}}><CircularProgress /><Typography> Loading ...</Typography> </Box>
        }
    };
};
