import { useMemo, useState } from 'react';

import IProduct from 'models/product';

import { Box, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';

import useControlContext from '../../hooks/useControlContext';
import useDataContext from '../../hooks/useDataContext';

import { LOCAL_STORE_ITEMS, PARSE_IMAGE_BY } from '../../constants';
import { testImageRegexString } from '../../utils';

const useStyles = makeStyles(() => ({
  autoCompleteInput: {
    '& .MuiAutocomplete-input': {
      height: 20,
      fontSize: 14,
      lineHeight: 20,
    },
  },
}));

type TRegexOption = {
  title: string;
  field: string;
  regex: RegExp;
  matches: number;
};

const RegexFilter = () => {
  const classes = useStyles();

  const { imageSettings, onRegexValueChange, onImageSettingChange } = useControlContext();
  const { allImageFileDataList } = useDataContext();

  const [value, setValue] = useState(localStorage.getItem(LOCAL_STORE_ITEMS.REGEX) || '');

  const regexOptions = useMemo<TRegexOption[]>(() => {
    const options = [
      {
        title: 'Parse EAN',
        field: PARSE_IMAGE_BY.EAN,
        regex: new RegExp(/^\s*(\d{8,13})\s*$/),
      },
      {
        title: 'Parse EAN, but if several images for one product',
        field: PARSE_IMAGE_BY.EAN,
        regex: new RegExp(/^\s*(\d{8,13}(_\d)?)\s*$/),
      },
      {
        title: 'Parse article',
        field: PARSE_IMAGE_BY.ARTICLE,
        regex: new RegExp(/^\s*(\d{1,7}(?:[-_]\d{1,7})*)\s*/),
      },
    ];

    return options.map(option => ({
      ...option,
      matches: allImageFileDataList.filter(({ name, assignProductId }) => {
        const regexStr = option.regex
          .toString()
          .slice(2, imageSettings.parseImageBy === PARSE_IMAGE_BY.ARTICLE ? -1 : -2);
        return assignProductId || testImageRegexString(regexStr, imageSettings.parseImageBy, name);
      }).length,
    }));
  }, [imageSettings.parseImageBy, allImageFileDataList]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={regexOptions}
      getOptionLabel={option => option.regex?.toString() || ''}
      filterOptions={options => options}
      renderOption={(option: TRegexOption) => (
        <Box display="flex" alignItems="center" width="100%" sx={{ fontSize: 14 }}>
          <Typography color="textSecondary" style={{ fontSize: 'inherit' }}>
            ({option.regex.toString()})
          </Typography>
          <Box mx={1}>|</Box>
          <Box>{option.title}</Box>
          <Box flexGrow={1} display="flex" justifyContent="end">
            ({option.matches}/{allImageFileDataList.length})
          </Box>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.autoCompleteInput}
          size="small"
          inputProps={{ ...params.inputProps, value }}
          label="Regex"
          variant="outlined"
          onChange={event => {
            const { value } = event.target;
            setValue(value);
            onRegexValueChange(value);
          }}
        />
      )}
      onChange={(_, newValue) => {
        // remove {/^} and {$/}
        let newRegexValue = newValue?.regex?.toString().slice(2, -2) || '';
        if (newValue?.field === PARSE_IMAGE_BY.ARTICLE) {
          // remove {/^} and {/}
          newRegexValue = newValue?.regex?.toString().slice(2, -1) || '';
        }
        setValue(newRegexValue);
        onRegexValueChange(newRegexValue);
        if (newValue) {
          onImageSettingChange({ parseImageBy: newValue.field as keyof IProduct });
        }
      }}
      style={{ background: '#FFFFFF' }}
    />
  );
};

export default RegexFilter;
