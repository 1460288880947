// import JSZip from 'jszip';
// import jimp from 'jimp';
import axios from 'axios';
import FormData from 'form-data';

import { restEndpoints } from 'constants/domain';

// TODO return type
export const removeBg = async (imageFile: File, token: string, timeout?: number) => {
  console.log('===removeBg===');

  const form = new FormData();
  
  form.append('image_file', imageFile);
  // form.append('size', 'auto');
  // form.append('crop', true);
  // form.append('format', 'zip');

  const response = await axios.post(
    restEndpoints.removebg,
    form,
    {
      timeout,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: 'arraybuffer',
    }
  );
  // const response = await axios.post(
  //   'https://api.remove.bg/v1.0/removebg',
  //   form,
  //   {
  //     headers: {
  //       'X-Api-Key': 'wWztquswGRJ1NAkdTJnTeb8R',
  //     },
  //     // encoding: null,
  //     responseType: 'arraybuffer',
  //   },
  // );
  const { data } = response;
  return data;
};

// export const unzip = async (data) => {
//   console.log('===unzip===');
//   const zip = new JSZip();
//   // const file = await readFile(filePath);
//   // const file = await getBinaryContent(zipPath);
//   const { files } = await zip.loadAsync(data);
//   // console.log(files);
//   return {
//     color: files['color.jpg'],
//     alpha: files['alpha.png'],
//   };
// };

// const getBuffer = async (file) => {
//   const data = await file.async('arraybuffer');
//   const buffer = Buffer.from(data);
//   return buffer;
// };

// export const createMask = async (color, alpha) => {
//   console.log('===createMask===');
//   const colorBuffer = await getBuffer(color);
//   const alphaBuffer = await getBuffer(alpha);

//   const image = await jimp.read(colorBuffer);
//   const mask = await jimp.read(alphaBuffer);

//   const result = image.mask(mask);
//   const buffer = await result.getBufferAsync(jimp.MIME_PNG);
//   return buffer;
// };

// const main = async () => {
//   const data = await removeBg('https://foodlab2b.imgix.net/Anton/Products/1616330058105_649-2.JPG');
//   const { color, alpha } = await unzip(data);
//   const result = await createMask(color, alpha);
//   await result.writeAsync('result.png');
// };
