import React from "react";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProductListItem from './item';
import IProduct from "models/product";
import { IProductDictionary } from "./queries";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

interface IProductsErrorsProps {
  products?: IProduct[];
  groupedProducts?: IProductDictionary[];
  filter: string;
  title: string;
  errorField?: keyof IProduct|(keyof IProduct)[];
  description?: string;
  groupBy?: keyof IProduct;
}

const ProductsErrors = ({ products, filter, title, errorField, description, groupBy, groupedProducts }: IProductsErrorsProps) => {
  const PRODUCTS_COUNT = 20;
  const [productsCount, setProductsCount] = React.useState(PRODUCTS_COUNT);
  // const [displayedProducts, setDisplayedProducts] = React.useState<IProduct[]>([]);
  const classes = useStyles();

  let show = false;
  let count = 0;

  const filteredProducts = products?.filter(p => {
    const item = p?.errors?.find((e) => e.name === filter);
    if (item) {
      return item.state;
    }
    return false;
  });
  if (filteredProducts && filteredProducts.length > 0) {
    show = true;
    count = filteredProducts.length;
  }
  const displayedProducts = filteredProducts?.slice(0, productsCount);

  let gp: IProductDictionary[] = [];
  if (groupedProducts) {
    gp = groupedProducts.slice(0, productsCount);
  }
  if (gp && gp.length > 0) {
    show = true;
    count = gp.length;
  }

  return (
    <>
      {show && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{title} ({count})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              {description && (
                <ListItem>
                  <ListItemText><i>{description}</i></ListItemText>
                </ListItem>
              )}
              {!groupBy && displayedProducts?.map(p => (
                 <>
                  <ProductListItem 
                    product={p} 
                    key={p.id} 
                    showNotApproved={false}
                    errorField={errorField}
                  />
                </>
              ))}
              {groupBy && gp?.map(p => (
                <>
                  <b>{p.EAN}{p.isStore && (<i> (Store)</i>)}</b>
                  {p.products.map(product => (
                    <ProductListItem 
                      product={product} 
                      key={product.id} 
                      showNotApproved={false}
                      // errorField={errorField}
                    />
                  ))}
                </>
              ))}
              {!groupBy && filteredProducts && displayedProducts && filteredProducts?.length > displayedProducts?.length && (
                <ListItem>
                  <ListItemText>
                    <Button onClick={() => setProductsCount(productsCount + PRODUCTS_COUNT)}>LOAD MORE...</Button>
                  </ListItemText>
                </ListItem>
              )}
              {groupBy && groupedProducts && gp && groupedProducts?.length > gp?.length && (
                <ListItem>
                  <ListItemText>
                    <Button onClick={() => setProductsCount(productsCount + PRODUCTS_COUNT)}>LOAD MORE...</Button>
                  </ListItemText>
                </ListItem>
              )}
              </List>
            </AccordionDetails>
          </Accordion>
      )}
    </>
  );
};

export default ProductsErrors;
