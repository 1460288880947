import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const toStringSearchValues = (searchParams: Record<string, string | number>) => {
  const result: Record<string, string> = {};
  Object.entries(searchParams).forEach(([key, value]) => (result[key] = String(value)));
  return result;
};

const getSearchParams = (href: string) => {
  try {
    const urlSearchParams = new URL(href).searchParams || {};
    return Object.fromEntries(urlSearchParams.entries());
  } catch {
    return {};
  }
}

const useSearchParams = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const keepRef = useRef<{ searchParams: Record<string, string> }>({ searchParams: {} });
  const [windowHref, setWindowHref] = useState(window.location.href);

  keepRef.current.searchParams = useMemo(() => {
    return getSearchParams(windowHref);
  }, [windowHref]);

  const updateSearchParams = useCallback(
    (newSearchParams: Record<string, string | number>, options?: { replace: boolean }) => {
      const search = new URLSearchParams(
        toStringSearchValues({ ...keepRef.current.searchParams, ...newSearchParams })
      ).toString();

      if (options?.replace) {
        history.replace({ pathname, search });
      } else {
        history.push({ pathname, search });
      }
    },
    [pathname, history]
  );

  const setSearchParams = useCallback(
    (newSearchParams: Record<string, string | number>, options?: { replace: boolean }) => {
      const search = new URLSearchParams(toStringSearchValues(newSearchParams)).toString();

      if (options?.replace) {
        history.replace({ pathname, search });
      } else {
        history.push({ pathname, search });
      }
    },
    [pathname, history]
  );

  // trigger when "href" change
  const newWindowHref = window.location.href;
  useEffect(() => {
    setWindowHref(newWindowHref);
  }, [newWindowHref]);

  return {
    searchParams: keepRef.current.searchParams,
    updateSearchParams,
    setSearchParams,
  };
};

export default useSearchParams;
