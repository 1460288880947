import React from 'react';
import withCondition from './withCondition';
import { CircularProgress } from '@material-ui/core';

const DefaultLoading = props => {
    return <CircularProgress />
}

export default WrappedComponent => {
    return (props) => {
        const Component = withCondition(props.loading, DefaultLoading)(WrappedComponent)
        return <Component {...props}/>
    } 
}