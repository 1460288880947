import { FC } from 'react';
import clsx from 'clsx';

import MuiButton from '@material-ui/core/Button';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonOutlined: {
    backgroundColor: '#FFFFFF',
    borderColor: '#56ABA9',
    color: '#56ABA9',
    boxShadow: 'none',
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#56ABA9',
      boxShadow: 'none',
    },
  },
  buttonContained: {
    backgroundColor: '#56ABA9',
    borderColor: '#56ABA9',
    color: '#FFFFFF',
    boxShadow: 'none',
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#56ABA9',
      borderColor: '#56ABA9',
      boxShadow: 'none',
    },
  },
}));

interface ButtonProps extends MuiButtonProps {
  component?: string;
  download?: string;
}

const Button: FC<ButtonProps> = ({ className, variant, color, ...props }) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant={variant}
      color={color}
      {...props}
      className={clsx(
        !color && variant === 'outlined' && classes.buttonOutlined,
        !color && variant === 'contained' && classes.buttonContained,
        className
      )}
    />
  );
};

export default Button;
