import React from 'react';

import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { isDefined } from '../../utils/helpers';
import { last } from 'ramda';
import COLORS from '../../constants/colors';

const PDFRow = ({ pdf, onButtonClick, pdfURL, organizationView }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: '#c4c4c4',
      padding: 10,
      marginBottom: 5,
    }}
  >
    <div style={{ flex: 0.2 }}>
      <span>{pdf}</span>
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', flex: 0.8, justifyContent: 'flex-end' }}>
      <div style={{ margin: 2 }}>
        <Button
          onClick={() => onButtonClick(pdfURL, 'view')}
          size="medium"
          variant="outlined"
          color="primary"
          startIcon={<VisibilityIcon />}
        >
          <span style={{ fontSize: 12, fontWeight: 'bolder' }}>View</span>
        </Button>
      </div>
      {/*
        <div style={{ margin: 2 }}>
          <Button onClick={() => onButtonClick(pdfURL, 'download')} size="medium" variant="outlined" color="primary" startIcon={<VisibilityIcon />}>
            <span style={{ fontSize: 12, fontWeight: 'bolder' }}>Download</span>
          </Button>
        </div>
      */}
      {!organizationView && (
        <div style={{ margin: 2 }}>
          <Button
            onClick={() => onButtonClick(pdfURL, 'delete')}
            size="medium"
            variant="outlined"
            color="primary"
            startIcon={<VisibilityIcon />}
          >
            <span style={{ fontSize: 12, fontWeight: 'bolder', color: COLORS.mainRed }}>Delete</span>
          </Button>
        </div>
      )}
    </div>
  </div>
);

const PDFList = ({ files, onButtonClicked, organizationView }) => (
  <>
    {isDefined(files) ? (
      <div style={{ width: 700 }}>
        {files.map(f => {
          let name = f.split('/');
          name = last(name);
          return (
            <PDFRow
              key={name}
              pdf={name}
              onButtonClick={onButtonClicked}
              pdfURL={f}
              organizationView={organizationView}
            />
          );
        })}
      </div>
    ) : (
      <></>
    )}
  </>
);

export default PDFList;
