import { contains, values } from "ramda";
import { getBackendHost } from "utils/urlUtils";

export const STRIPE_BILLING_URL = "https://billing.stripe.com/p/login/test_14kcNs3lsbHl9I4eUU";

export const LINKS = {
  IMAGE_GUIDE: 'https://foodla.nu/en/foodla-faq-fota-en-produktbild/',
};

export const restEndpoints = {
  removebg: '/api/removebg',
  tiff: '/api/tiff',
  uploadImage: '/api/uploadImage',
  excelDownload: '/api/export/products',
  icaExcelDownload: '/api/export/ica-products',
  approvedProductsDownload: '/api/export/productsWithImages',
  superuserProductsDownload: '/api/export/superuserProductsWithImages',
  apotea: '/api/export/apotea',
  ica: '/api/export/ica',
  coop: '/api/export/coop',
  aiProduct: '/api/ai/product',
  aiImage: '/api/ai/image',
  aiCategory: '/api/ai/category',
  surveyKnowProductOnline: '/api/survey/know-product-online'
}

// TODO
// export const restEndpoints = {
//   removebg: getBackendHost('/api/removebg') || '/api/removebg',
//   tiff: getBackendHost('/api/tiff') || '/api/tiff',
//   uploadImage: getBackendHost('/api/uploadImage') || '/api/uploadImage',
//   excelDownload: getBackendHost('/api/export/products') || '/api/export/products',
//   approvedProductsDownload: getBackendHost('/api/export/productsWithImages') || '/api/export/productsWithImages',
//   superuserProductsDownload: getBackendHost('/api/export/superuserProductsWithImages') || '/api/export/superuserProductsWithImages',
// };

export enum PRODUCT_TABS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
}

export const SUPERUSER_APPROVAL = {
  ADMIN_APPROVED: 'ADMIN_APPROVED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  ADMIN_DISAPPROVED: 'ADMIN_DISAPPROVED',
};

export const PRODUCT_TAGSTATES = PRODUCT_TABS;

// TODO enum
export const PAGINATION = {
  SORTING_TYPES: {
    NONE: 'none',
    APPROVED: 'approved',
    CATEGORY: 'foodlaCategory',
    DATE: 'created_at',
    TITLE: 'title',
    NAME: 'name',
    DISTANCE: 'distance',
  },
  SORT_ORDERS: {
    ASC: 'ASC',
    DESC: 'DESC',
  }
};

export const IS_FOOD_LOCAL_STORAGE_KEY = "isFood";

export const isValidSortType = (sortType: string | null) => contains(sortType, values(PAGINATION.SORTING_TYPES));
export const isValidSortOrder = (sortOrder: string | null) => contains(sortOrder, values(PAGINATION.SORT_ORDERS));

interface ICountry {
  code: number;
  sv: string;
  start?: boolean;
}

// https://gs1.se/guider/dokumentation/kodlistor/t3784-landskod/
// http://www.gs1.se/services/codelist/3.1/T3784.xml
export const countries: ICountry[] = [
  {code: 1, sv: 'Global marknad', start: true},
  {code: 4, sv: 'Afghanistan'},
  {code: 8, sv: 'Albanien'},
  {code: 10, sv: 'Antarktis'},
  {code: 12, sv: 'Algeriet'},
  {code: 16, sv: 'Amerikanska Samoa'},
  {code: 20, sv: 'Andorra'},
  {code: 24, sv: 'Angola'},
  {code: 28, sv: 'Antigua och Barbuda'},
  {code: 31, sv: 'Azerbajdzjan'},
  {code: 32, sv: 'Argentina'},
  {code: 36, sv: 'Australien'},
  {code: 40, sv: 'Österrike'},
  {code: 44, sv: 'Bahamas'},
  {code: 48, sv: 'Bahrain'},
  {code: 50, sv: 'Bangladesh'},
  {code: 51, sv: 'Armenien'},
  {code: 52, sv: 'Barbados'},
  {code: 56, sv: 'Belgien'},
  {code: 60, sv: 'Bermuda'},
  {code: 64, sv: 'Bhutan'},
  {code: 68, sv: 'Bolivia'},
  {code: 70, sv: 'Bosnien och Hercegovina'},
  {code: 72, sv: 'Botswana'},
  {code: 74, sv: 'Bouvetön'},
  {code: 76, sv: 'Brasilien'},
  {code: 84, sv: 'Belize'},
  {code: 86, sv: 'Brittiska territoriet i Indiska Oceanen'},
  {code: 90, sv: 'Salomonöarna'},
  {code: 92, sv: 'Brittiska Jungfruöarna'},
  {code: 96, sv: 'Brunei'},
  {code: 97, sv: 'Europeiska unionen'},
  {code: 100, sv: 'Bulgarien'},
  {code: 104, sv: 'Burma'},
  {code: 108, sv: 'Burundi'},
  {code: 112, sv: 'Vitryssland'},
  {code: 116, sv: 'Kambodja'},
  {code: 120, sv: 'Kamerun'},
  {code: 124, sv: 'Kanada'},
  {code: 132, sv: 'Kap Verde'},
  {code: 136, sv: 'Caymanöarna'},
  {code: 140, sv: 'Centralafrikanska republiken'},
  {code: 144, sv: 'Sri Lanka'},
  {code: 148, sv: 'Tchad'},
  {code: 152, sv: 'Chile'},
  {code: 156, sv: 'Kina'},
  {code: 158, sv: 'Taiwan'},
  {code: 162, sv: 'Julön'},
  {code: 166, sv: 'Kokosöarna'},
  {code: 170, sv: 'Colombia'},
  {code: 174, sv: 'Komorerna'},
  {code: 175, sv: 'Mayotte'},
  {code: 178, sv: 'Kongo-Brazzaville'},
  {code: 184, sv: 'Cooköarna'},
  {code: 180, sv: 'Kongo-Kinshasa'},
  {code: 188, sv: 'Costa Rica'},
  {code: 191, sv: 'Kroatien'},
  {code: 192, sv: 'Kuba'},
  {code: 196, sv: 'Cypern'},
  {code: 203, sv: 'Tjeckien'},
  {code: 204, sv: 'Benin'},
  {code: 208, sv: 'Danmark'},
  {code: 231, sv: 'Etiopien'},
  {code: 212, sv: 'Dominica'},
  {code: 214, sv: 'Dominikanska republiken'},
  {code: 218, sv: 'Ecuador'},
  {code: 222, sv: 'El Salvador'},
  {code: 226, sv: 'Ekvatorialguinea'},
  {code: 232, sv: 'Eritrea'},
  {code: 233, sv: 'Estland'},
  {code: 234, sv: 'Färöarna'},
  {code: 238, sv: 'Falklandsöarna'},
  {code: 239, sv: 'Sydgeorgien och Sydsandwichöarna'},
  {code: 242, sv: 'Fiji'},
  {code: 246, sv: 'Finland'},
  {code: 249, sv: 'France métropolitaine (Frankrike, europeiska delen)'},
  {code: 248, sv: 'Åland'},
  {code: 250, sv: 'Frankrike'},
  {code: 254, sv: 'Franska Guyana'},
  {code: 258, sv: 'Franska Polynesien'},
  {code: 260, sv: 'Franska södra territorierna'},
  {code: 262, sv: 'Djibouti'},
  {code: 266, sv: 'Gabon'},
  {code: 268, sv: 'Georgien'},
  {code: 270, sv: 'Gambia'},
  {code: 275, sv: 'Palestinskt territorium, ockuperat'},
  {code: 276, sv: 'Tyskland'},
  {code: 288, sv: 'Ghana'},
  {code: 292, sv: 'Gibraltar'},
  {code: 296, sv: 'Kiribati'},
  {code: 300, sv: 'Grekland'},
  {code: 304, sv: 'Grönland'},
  {code: 308, sv: 'Grenada'},
  {code: 312, sv: 'Guadeloupe'},
  {code: 316, sv: 'Guam'},
  {code: 320, sv: 'Guatemala'},
  {code: 324, sv: 'Guinea'},
  {code: 328, sv: 'Guyana'},
  {code: 332, sv: 'Haiti'},
  {code: 334, sv: 'Heard- och McDonaldsöarna'},
  {code: 336, sv: 'Vatikanstaten'},
  {code: 340, sv: 'Honduras'},
  {code: 344, sv: 'Hongkong'},
  {code: 348, sv: 'Ungern'},
  {code: 352, sv: 'Island'},
  {code: 356, sv: 'Indien'},
  {code: 360, sv: 'Indonesien'},
  {code: 364, sv: 'Iran'},
  {code: 368, sv: 'Irak'},
  {code: 372, sv: 'Irland'},
  {code: 376, sv: 'Israel'},
  {code: 380, sv: 'Italien'},
  {code: 384, sv: 'Elfenbenskusten'},
  {code: 388, sv: 'Jamaica'},
  {code: 392, sv: 'Japan'},
  {code: 398, sv: 'Kazakstan'},
  {code: 400, sv: 'Jordanien'},
  {code: 404, sv: 'Kenya'},
  {code: 408, sv: 'Nordkorea'},
  {code: 410, sv: 'Sydkorea'},
  {code: 414, sv: 'Kuwait'},
  {code: 417, sv: 'Kirgizistan'},
  {code: 418, sv: 'Laos'},
  {code: 422, sv: 'Libanon'},
  {code: 426, sv: 'Lesotho'},
  {code: 428, sv: 'Lettland'},
  {code: 430, sv: 'Liberia'},
  {code: 434, sv: 'Libyen'},
  {code: 438, sv: 'Liechtenstein'},
  {code: 440, sv: 'Litauen'},
  {code: 442, sv: 'Luxemburg'},
  {code: 446, sv: 'Macau'},
  {code: 450, sv: 'Madagaskar'},
  {code: 454, sv: 'Malawi'},
  {code: 458, sv: 'Malaysia'},
  {code: 462, sv: 'Maldiverna'},
  {code: 466, sv: 'Mali'},
  {code: 470, sv: 'Malta'},
  {code: 474, sv: 'Martinique'},
  {code: 478, sv: 'Mauretanien'},
  {code: 480, sv: 'Mauritius'},
  {code: 484, sv: 'Mexiko'},
  {code: 492, sv: 'Monaco'},
  {code: 496, sv: 'Mongoliet'},
  {code: 498, sv: 'Moldavien'},
  {code: 499, sv: 'Montenegro'},
  {code: 500, sv: 'Montserrat'},
  {code: 504, sv: 'Marocko'},
  {code: 508, sv: 'Moçambique'},
  {code: 512, sv: 'Oman'},
  {code: 516, sv: 'Namibia'},
  {code: 520, sv: 'Nauru'},
  {code: 524, sv: 'Nepal'},
  {code: 528, sv: 'Nederländerna'},
  {code: 531, sv: 'Curaçao'},
  {code: 533, sv: 'Aruba'},
  {code: 534, sv: 'Sint Maarten (Holländska delen)'},
  {code: 535, sv: 'Karibiska Nederländerna'},
  {code: 540, sv: 'Nya Kaledonien'},
  {code: 548, sv: 'Vanuatu'},
  {code: 554, sv: 'Nya Zeeland'},
  {code: 558, sv: 'Nicaragua'},
  {code: 562, sv: 'Niger'},
  {code: 566, sv: 'Nigeria'},
  {code: 570, sv: 'Niue'},
  {code: 574, sv: 'Norfolkön'},
  {code: 578, sv: 'Norge'},
  {code: 580, sv: 'Nordmarianerna'},
  {code: 581, sv: 'USA:s yttre öar'},
  {code: 583, sv: 'Mikronesiska federationen'},
  {code: 584, sv: 'Marshallöarna'},
  {code: 585, sv: 'Palau'},
  {code: 586, sv: 'Pakistan'},
  {code: 591, sv: 'Panama'},
  {code: 598, sv: 'Papua Nya Guinea'},
  {code: 600, sv: 'Paraguay'},
  {code: 604, sv: 'Peru'},
  {code: 608, sv: 'Filippinerna'},
  {code: 612, sv: 'Pitcairnöarna'},
  {code: 616, sv: 'Polen'},
  {code: 620, sv: 'Portugal'},
  {code: 624, sv: 'Guinea Bissau'},
  {code: 626, sv: 'Östtimor'},
  {code: 630, sv: 'Puerto Rico'},
  {code: 634, sv: 'Qatar'},
  {code: 638, sv: 'Réunion'},
  {code: 642, sv: 'Rumänien'},
  {code: 643, sv: 'Ryssland'},
  {code: 646, sv: 'Rwanda'},
  {code: 652, sv: 'Saint Barthélemy'},
  {code: 654, sv: 'Sankta Helena'},
  {code: 659, sv: 'Saint Kitts och Nevis'},
  {code: 660, sv: 'Anguilla'},
  {code: 662, sv: 'Saint Lucia'},
  {code: 663, sv: 'Saint Martin, Frankrike'},
  {code: 666, sv: 'Saint-Pierre och Miquelon'},
  {code: 670, sv: 'Saint Vincent och Grenadinerna'},
  {code: 674, sv: 'San Marino'},
  {code: 678, sv: 'São Tomé och Príncipe'},
  {code: 682, sv: 'Saudiarabien'},
  {code: 686, sv: 'Senegal'},
  {code: 688, sv: 'Serbien'},
  {code: 690, sv: 'Seychellerna'},
  {code: 694, sv: 'Sierra Leone'},
  {code: 702, sv: 'Singapore'},
  {code: 703, sv: 'Slovakien'},
  {code: 704, sv: 'Vietnam'},
  {code: 705, sv: 'Slovenien'},
  {code: 706, sv: 'Somalia'},
  {code: 710, sv: 'Sydafrika'},
  {code: 716, sv: 'Zimbabwe'},
  {code: 724, sv: 'Spanien'},
  {code: 732, sv: 'Västsahara'},
  {code: 736, sv: 'Sudan'},
  {code: 740, sv: 'Surinam'},
  {code: 744, sv: 'Svalbard och Jan Mayen'},
  {code: 748, sv: 'Swaziland'},
  {code: 752, sv: 'Sverige', start: true},
  {code: 756, sv: 'Schweiz'},
  {code: 760, sv: 'Syrien'},
  {code: 762, sv: 'Tadzjikistan'},
  {code: 764, sv: 'Thailand'},
  {code: 768, sv: 'Togo'},
  {code: 772, sv: 'Tokelauöarna'},
  {code: 776, sv: 'Tonga'},
  {code: 780, sv: 'Trinidad och Tobago'},
  {code: 784, sv: 'Förenade Arabemiraten'},
  {code: 788, sv: 'Tunisien'},
  {code: 792, sv: 'Turkiet'},
  {code: 795, sv: 'Turkmenistan'},
  {code: 796, sv: 'Turks- och Caicosöarna'},
  {code: 798, sv: 'Tuvalu'},
  {code: 800, sv: 'Uganda'},
  {code: 804, sv: 'Ukraina'},
  {code: 807, sv: 'Nordmakedonien'},
  {code: 818, sv: 'Egypten'},
  {code: 826, sv: 'Storbritannien'},
  {code: 831, sv: 'Guernsey'},
  {code: 832, sv: 'Jersey'},
  {code: 833, sv: 'Isle of Man'},
  {code: 834, sv: 'Tanzania'},
  {code: 840, sv: 'USA'},
  {code: 850, sv: 'Amerikanska Jungfruöarna'},
  {code: 854, sv: 'Burkina Faso'},
  {code: 858, sv: 'Uruguay'},
  {code: 860, sv: 'Uzbekistan'},
  {code: 862, sv: 'Venezuela'},
  {code: 876, sv: 'Wallis- och Futunaöarna'},
  {code: 882, sv: 'Samoa'},
  {code: 887, sv: 'Jemen'},
  {code: 894, sv: 'Zambia'},
  // NON_EU	Ej EU Land som inte finns med i Europeiska Unionen
];
