import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";

import IProduct from "models/product";
import { isComplementarySupplements, isCompleteFeed, isFeedMixtures } from "./category";
import { IFoodlaCategoryOption } from "models/category";

interface IFeedFieldsDataProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory?: IFoodlaCategoryOption;
};

const FeedFields = ({ approve, state, setState, rootCategory }: IFeedFieldsDataProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    
    setState({ ...state, [name]: value });
  };

  return (
    <div style={{width: '100%'}}>
      <InputLabel
        heading="Fodersammansättning"
        text="Ange fodersammansättning som deklareras på förpackningen. Läs mer om kraven på sidan 123."
        link="https://gs1.se/wp-content/uploads/sites/2/2023/08/handledning-artikelinformation_3.1.24_1_1.pdf"
        required={(
          isComplementarySupplements(state?.foodlaCategory, rootCategory) ||
          isFeedMixtures(state?.foodlaCategory, rootCategory) ||
          isCompleteFeed(state?.foodlaCategory, rootCategory)
        )}
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="animalFoodIngredients"
        value={state.animalFoodIngredients}
        placeholder="Ex. Laxprotein 20%, Potatis 8%, Havre 5%, Laxolja 1%. Lista separerad med komma"
        approve={approve && !!state?.approve?.find(x => x.field === 'animalFoodIngredients')}
        onChange={handleChange}
      />
      <InputLabel
        heading="Fodertillsatser"
        text="Lista över tillsatta ämnen som ingår i foderets sammansättning. Ange tillsatser såsom det deklareras på förpackningen. Läs mer om kraven på sidan 123."
        link="https://gs1.se/wp-content/uploads/sites/2/2023/08/handledning-artikelinformation_3.1.24_1_1.pdf"
        required={(
          isComplementarySupplements(state?.foodlaCategory, rootCategory) ||
          isFeedMixtures(state?.foodlaCategory, rootCategory) ||
          isCompleteFeed(state?.foodlaCategory, rootCategory)
        )}
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="feedAdditives"
        value={state.feedAdditives}
        placeholder="Ex. Vitamin A: 12 500 IE/kg. Lista separerad med komma"
        onChange={handleChange}
        approve={approve && !!state?.approve?.find(x => x.field === 'feedAdditives')}
      />
      <InputLabel
        heading="Utfodringsinstruktioner"
        text="Beskrivning i fritext av hur djuret ska utfodras. Läs mer om kraven på sidan 123."
        link="https://gs1.se/wp-content/uploads/sites/2/2023/08/handledning-artikelinformation_3.1.24_1_1.pdf"
        required
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="feedingInstructions"
        value={state.feedingInstructions}
        placeholder="Instruktioner för matning och dosering, inklusive eventuella försiktighetsåtgärder"
        onChange={handleChange}
        approve={approve && !!state?.approve?.find(x => x.field === 'feedingInstructions')}
      />
      <InputLabel
        heading="Analytiska beståndsdelar"
        text="Ange den mängd som garanteras av olika näringsämnen, fram till sista förbrukningsdatum (t.ex. av vitaminer). Läs mer om kraven på sidan 123."
        link="https://gs1.se/wp-content/uploads/sites/2/2023/08/handledning-artikelinformation_3.1.24_1_1.pdf"
        required={(
          isComplementarySupplements(state?.foodlaCategory, rootCategory) ||
          isFeedMixtures(state?.foodlaCategory, rootCategory) ||
          isCompleteFeed(state?.foodlaCategory, rootCategory)
        )}
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="analyticalConstituentsFeed"
        value={state.analyticalConstituentsFeed}
        placeholder="Protein: 25, Fett: 15. Lista separerad med komma"
        onChange={handleChange}
        approve={approve && !!state?.approve?.find(x => x.field === 'analyticalConstituentsFeed')}
      />
    </div>
  );
}

export default FeedFields;
