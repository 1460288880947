import React, { FC, useEffect, useMemo, useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

import ReviewTableTemplateHeaderCell from '../ReviewTableTemplateHeaderCell';
import TruncateText from 'components/TruncateText';
import UploadErrorIcon from '../UploadErrorIcon';

import { useBulkImportContext } from '../../../BulkImportContext';
import { IProductExcel, STATUS_COLORS } from 'utils/excelUtils-old';
import { setWidthHeight } from 'utils/imgix';

import { useStyles } from '../index.style';

const IMAGE_WIDTH = 100;
const TOOLTIP_IMAGE_WIDTH = 250;
const IMAGE_GAP = 8;

const useCustomStyles = makeStyles(() => ({
  bodyImageCellWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: IMAGE_GAP,
  },
  bodyImageCell: {
    display: 'block',
    maxWidth: IMAGE_WIDTH,
    maxHeight: IMAGE_WIDTH,
  },
  bodyImageCellLarge: {
    display: 'block',
    maxWidth: TOOLTIP_IMAGE_WIDTH,
    maxHeight: TOOLTIP_IMAGE_WIDTH,
  },
  tooltipImageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageInfoWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: IMAGE_GAP,
  },
  imageIconChip: {
    cursor: 'pointer',
    height: 24,
    '& span': {
      padding: '0 8px',
    },
  },
}));

interface ImageTemplateHeaderCellProps {
  isReview?: boolean;
  dataList: IProductExcel[];
}

export const ImageTemplateHeaderCell: FC<ImageTemplateHeaderCellProps> = ({ isReview, dataList }) => {
  const classes = useStyles();
  const { productImageDataSet } = useBulkImportContext();

  const firstImageErrorCellId = useMemo(
    () => dataList.find(({ id = '' }) => !productImageDataSet[id])?.id || 0,
    [dataList, productImageDataSet]
  );
  const isImageError = Number(firstImageErrorCellId) > 0;

  return (
    <ReviewTableTemplateHeaderCell
      field="image"
      minWidth={140}
      className={classes.headCell}
      style={{ background: isImageError ? STATUS_COLORS.ERROR : STATUS_COLORS.SUCCESS }}
    >
      <TruncateText title="Bilder" fixedAfter={!isReview && isImageError ? `(${firstImageErrorCellId})` : ''} />
    </ReviewTableTemplateHeaderCell>
  );
};

export const ImageFieldHeaderCell: FC = () => {
  const classes = useStyles();

  return (
    <TableCell className={classes.headCell}>
      <div data-field="image">
        <TruncateText title="image" />
      </div>
    </TableCell>
  );
};

interface ImageCellContentProps {
  isReview?: boolean;
  width: number;
  data: IProductExcel;
}

const ImageCellContent: FC<ImageCellContentProps> = ({ isReview, width, data }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { uploadProgress, productImageDataSet } = useBulkImportContext();

  const imageFileDataList = useMemo(() => {
    return productImageDataSet[String(data.id || '')] || [];
  }, [data.id, productImageDataSet]);

  const isUploadedBefore = useMemo(() => {
    return imageFileDataList.every(
      imageData => !!imageData.url && !imageData.uploadError && !imageData.generateAiError && !imageData.uploadAiError
    );
  }, [imageFileDataList]);

  const uploadedList = useMemo(() => {
    return imageFileDataList.filter(({ uploadError }) => !uploadError);
  }, [imageFileDataList]);

  const failedList = useMemo(() => {
    return imageFileDataList.filter(({ uploadError }) => uploadError);
  }, [imageFileDataList]);

  const isUploaded = useMemo(() => {
    return !!uploadProgress.uploaded.find(productId => productId === data.id);
  }, [data.id, uploadProgress.uploaded]);

  const isUploading = useMemo(() => {
    return uploadProgress.uploading.length && !isUploaded && !isUploadedBefore;
  }, [isUploaded, isUploadedBefore, uploadProgress.uploading.length]);

  const limitDisplay = useMemo(() => {
    const isFailed = !!failedList.length;
    const numberOfImages = imageFileDataList.length;

    const spaceBetween = numberOfImages > 1 ? IMAGE_GAP : 0; // flex-gap
    let spaceForImages = width - 8; // padding
    if (isFailed) spaceForImages -= 42; // UploadErrorIcon

    let result = Math.floor(Math.max(spaceForImages, 0) / (IMAGE_WIDTH + spaceBetween));
    if (result >= numberOfImages) return result;

    spaceForImages -= 40; // Chip
    return Math.floor(Math.max(spaceForImages, 0) / (IMAGE_WIDTH + spaceBetween));
  }, [width, failedList, imageFileDataList]);

  if (!imageFileDataList.length) {
    return <div className={classes.bodyCellBox} style={{ background: STATUS_COLORS.ERROR }} />;
  }

  if (isReview) {
    return (
      <div className={classes.bodyCellBox}>
        {imageFileDataList.map(imageFileData => (
          <div key={imageFileData.id}>
            <Link href={imageFileData.url} target="_blank">
              <TruncateText title={imageFileData.url || ''} />
            </Link>
          </div>
        ))}
      </div>
    );
  }

  if (isUploading) {
    return (
      <div className={classes.bodyCellBox} style={{ textAlign: 'center' }}>
        <CircularProgress size={20} />
      </div>
    );
  }

  let background: string | undefined = STATUS_COLORS.NORMAL;
  if (failedList.length) {
    background = STATUS_COLORS.ERROR;
  } else if (isUploaded || isUploadedBefore) {
    background = STATUS_COLORS.SUCCESS;
  }

  return (
    <div className={classes.bodyCellBox} style={{ background, padding: 4 }}>
      <div className={customClasses.bodyImageCellWrapper}>
        {uploadedList.slice(0, limitDisplay).map(({ id, url, source, fileName }) => (
          <Tooltip
            key={id}
            arrow
            placement="top"
            title={
              <div className={customClasses.tooltipImageWrapper}>
                <img
                  className={customClasses.bodyImageCellLarge}
                  src={url ? setWidthHeight(url, TOOLTIP_IMAGE_WIDTH, TOOLTIP_IMAGE_WIDTH) : source}
                  alt=""
                />
                <p style={{ textAlign: 'center' }}>{fileName}</p>
              </div>
            }
          >
            <img
              className={customClasses.bodyImageCell}
              src={url ? setWidthHeight(url, IMAGE_WIDTH, IMAGE_WIDTH) : source}
              alt=""
            />
          </Tooltip>
        ))}

        <div className={customClasses.imageInfoWrapper}>
          <UploadErrorIcon errors={failedList.map(data => ({ label: data.fileName, message: data.uploadError! }))} />
          {!!uploadedList.slice(limitDisplay).length && (
            <Chip className={customClasses.imageIconChip} label={`+${uploadedList.slice(limitDisplay).length}`} />
          )}
        </div>
      </div>
    </div>
  );
};

interface ImageCellProps extends Omit<ImageCellContentProps, 'width'> {
  onClick: (id: string) => void;
}

const ImageCell: FC<ImageCellProps> = ({ isReview, data, onClick }) => {
  const classes = useStyles();

  const [width, setWidth] = useState(0);
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!wrapperElement) return;
    const observer = new ResizeObserver(() => setWidth(wrapperElement.offsetWidth));
    observer.observe(wrapperElement);
    return () => observer.disconnect();
  }, [wrapperElement]);

  return (
    <td className={classes.bodyCell}>
      <div
        ref={setWrapperElement}
        data-field="image"
        className={classes.bodyCellImport}
        onClick={() => onClick(String(data.id || ''))}
      >
        <ImageCellContent isReview={isReview} data={data} width={width} />
      </div>
    </td>
  );
};

const ImageColumn = {
  TemplateHeaderCell: ImageTemplateHeaderCell,
  FieldHeaderCell: ImageFieldHeaderCell,
  BodyCell: ImageCell,
};

export default ImageColumn;
