import InputTextField from "components/inputfields/InputTextField";
import IProducer from "models/producer";

interface INameProps {
  state?: IProducer;
  setState: (state: IProducer) => void;
}

const ProducerName = ({state, setState}: INameProps) => {
  // TODO: any
  const handleChange = ({ target }: any) => setState({ ...state, name: target.value });
  return (
    <InputTextField<IProducer>
      inputName="name"
      label="Namn som visas"
      value={state?.name}
      onChange={handleChange}
      required={true}
      showRed={true}
      withLabel={true}
      labelOptions={{
        heading: 'Namn som visas',
        text: 'Detta är ditt synliga visningsnamn som kommer stå i anslutning till dina produkter.',
      }}
      errorText='Vi vill gärna att du fyller i det här fältet innan du kan uppdatera din profil, tack!'
    />
  );
};

export default ProducerName;
