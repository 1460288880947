import React, { useContext } from 'react';
import isNil from 'lodash/isNil';

import withContainer from '../../hoc/withContainer';
import SimpleText from './SimpleText';
import { PRODUCER_TOTAL_QUERY, PRODUCER_TOTAL_QUERY_ADMIN } from '../../graphql/queries';
import { parseBoolean } from '../../utils/helpers';
import { KeycloakContext } from '../../components/Secured';

interface IProducerSummary {
  total?: number;
}

interface IProducers {
  summary?: IProducerSummary;
}

interface IData {
  totalProducersAdmin?: IProducerSummary;
  producers?: IProducers;
}

// TODO add parent org
interface IVariables {
  isFood?: boolean;
  isNonFood?: boolean;
  showAllProducers?: boolean;
  onlyApproved?: boolean;
  filterStoresByOrg?: boolean;
  filterStoresByParentOrg?: boolean;
  showAllNoFilter?: boolean;
  showAll?: boolean;
}

const validator = (data: IData) => {
  return data?.producers?.summary?.total;
}

const resolver = (data: IData) => {
  // TODO fix
  if (data?.totalProducersAdmin?.total) {
    return data?.totalProducersAdmin?.total;
  } else {
    return data?.producers?.summary?.total;
  }
}

const ProducerTotal = (isFood: boolean | string | undefined, showAll?: boolean, showAllNoFilter?: boolean) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isAdmin: boolean = keycloakCtx.keycloak.hasResourceRole('admin');
  const isStore: boolean = keycloakCtx.keycloak.hasResourceRole('store');
  let variables: IVariables = {};
  if (showAllNoFilter && !isAdmin) {
    variables.showAllNoFilter = true;
  } else if (showAll) {
    variables.showAll = true;
  } else {
    if (!isStore) {
      if (!isNil(isFood)) {
        if (typeof isFood === 'boolean') {
          variables.isFood = isFood;
        } else if (typeof isFood === 'string') {
          variables.isFood = parseBoolean(isFood);
        }
      }
    }
    if (isAdmin) {
      if (isNil(isFood) || isFood === 'true' || isFood === true) {
        variables.isFood = true;
      } else {
        variables.isFood = false;
      }
      variables.showAllProducers = false;
      variables.onlyApproved = true;
      variables.filterStoresByOrg = true;        
    }  
    // TODO add parent org
  }
  let query = PRODUCER_TOTAL_QUERY;
  if (isAdmin) {
    query = PRODUCER_TOTAL_QUERY_ADMIN;
    variables = { isNonFood: !variables?.isFood };
  }
  return withContainer({ query: query, variables, validator, resolver })(SimpleText);
}

export default ProducerTotal;
