import React, { FC } from 'react';

const DocumentReviewIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <rect width="24" height="24" rx="2" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50915 4.5C5.95179 4.5 5.5 4.94771 5.5 5.5V15.5C5.5 16.0523 5.95179 16.5 6.50915 16.5H9.27745C9.45445 16.2649 9.56261 16.1192 9.62849 16.0304C9.70515 15.9271 9.72457 15.9009 9.72862 15.9009L9.72961 15.8996L9.73226 15.896L9.74008 15.8858L9.7657 15.8528C9.78714 15.8255 9.81726 15.7876 9.85569 15.7408C9.93238 15.6471 10.0426 15.517 10.1829 15.3624C10.4623 15.0547 10.8671 14.6435 11.3705 14.2301C12.3485 13.4267 13.8377 12.5 15.5824 12.5C15.9295 12.5 16.2666 12.5367 16.5915 12.6018V5.5C16.5915 4.94771 16.1397 4.5 15.5824 4.5H6.50915ZM7.51831 6.5C7.23966 6.5 7.01373 6.72386 7.01373 7C7.01373 7.27614 7.23966 7.5 7.51831 7.5H14.5732C14.8519 7.5 15.0778 7.27614 15.0778 7C15.0778 6.72386 14.8519 6.5 14.5732 6.5H7.51831ZM7.51831 8.5C7.23966 8.5 7.01373 8.72386 7.01373 9C7.01373 9.27614 7.23966 9.5 7.51831 9.5H14.5732C14.8519 9.5 15.0778 9.27614 15.0778 9C15.0778 8.72386 14.8519 8.5 14.5732 8.5H7.51831ZM7.01373 11C7.01373 10.7239 7.23966 10.5 7.51831 10.5H14.5732C14.8519 10.5 15.0778 10.7239 15.0778 11C15.0778 11.2761 14.8519 11.5 14.5732 11.5H7.51831C7.23966 11.5 7.01373 11.2761 7.01373 11ZM7.51831 12.5C7.23966 12.5 7.01373 12.7239 7.01373 13C7.01373 13.2761 7.23966 13.5 7.51831 13.5H9.52746C9.80611 13.5 10.032 13.2761 10.032 13C10.032 12.7239 9.80611 12.5 9.52746 12.5H7.51831ZM15.5824 19.5C17.8332 19.5 19.7398 17.5429 20.3898 16.7901C20.5355 16.6214 20.5355 16.3786 20.3898 16.2099C19.7398 15.4571 17.8332 13.5 15.5824 13.5C13.3316 13.5 11.425 15.4571 10.7749 16.2099C10.6292 16.3786 10.6292 16.6214 10.7749 16.7901C11.425 17.5429 13.3316 19.5 15.5824 19.5ZM15.5824 18.5C16.697 18.5 17.6007 17.6046 17.6007 16.5C17.6007 15.3954 16.697 14.5 15.5824 14.5C14.4677 14.5 13.5641 15.3954 13.5641 16.5C13.5641 17.6046 14.4677 18.5 15.5824 18.5ZM16.5915 16.5C16.5915 17.0523 16.1397 17.5 15.5824 17.5C15.025 17.5 14.5732 17.0523 14.5732 16.5C14.5732 15.9477 15.025 15.5 15.5824 15.5C16.1397 15.5 16.5915 15.9477 16.5915 16.5Z"
        fill="#737DA0"
      />
    </svg>
  );
};

export default DocumentReviewIcon;
