import React from "react";
import isNil from "lodash/isNil";
import minValue from "lodash/min";
import maxValue from "lodash/max";
import { colord, HsvColor } from 'colord';
import { ColorBox } from 'material-ui-color';
import rgbHex from "rgb-hex";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ShadeSlider from '@uiw/react-color-shade-slider';
import { makeStyles } from "@material-ui/core";

import ZoomImage from './ZoomImage';
import ImgixBuilder from "./ImgixBuilder";
import useViewport from "hooks/useViewports";

const useStyles = makeStyles(() => ({
    paperMd: { minWidth: 900, minHeight: 650 },
}));
interface IEditImageSliderProps {
    value?: number;
    setValue: (value: number) => void;
    title?: string;
    min?: number;
    max?: number;
    step?: number;
}

const EditImageSlider = ({ value, setValue, title, min, max, step }: IEditImageSliderProps) => {
    const { mdUp } = useViewport();

    const handleChange = (event: any, newValue: number|number[]) => {
        let value = 0;
        if (Array.isArray(newValue)) {
            if (newValue.length > 0) {
                value = newValue[0];
            }
        } else {
            value = newValue;
        }
        setValue(value);
    };
    const values = [];
    if (!isNil(min)) values.push(min);
    if (!isNil(max)) values.push(max);
    if (!values.includes(0)) values.push(0);
    const marks = values.sort().map((x) => ({value: x, label: x}));
    return (
        <Grid container={true} spacing={2} style={{ marginTop: 0, marginLeft: mdUp ? 10 : 0 }}>
            <Grid item={true} xs={mdUp ? undefined : 12} style={{width: 80}}>{title}</Grid>
            <Grid item={true} xs={mdUp}>
                <Slider
                    defaultValue={0}
                    step={step || 1}
                    min={minValue(values)}
                    max={maxValue(values)}
                    value={value}
                    onChange={handleChange}
                    style={{ width: 200 }}
                    marks={marks}
                />
            </Grid>
            <Grid item={true} style={{ padding: 0, marginLeft: mdUp ? 0 : 30 }}>
                <TextField
                    value={value}
                    style={{
                        width: 42,
                    }}
                />
            </Grid>
        </Grid>
    );
};

interface IEditImageDialogProps {
    open: boolean;
    url?: string;
    onClose: () => void;
    onSave: (url?: string) => void;
}

const EditImageDialog = ({ open, url, onClose, onSave }: IEditImageDialogProps) => {
    const classes = useStyles();
    const [currentUrl, setCurrentUrl] = React.useState<string|undefined>(undefined);
    const [contrast, setContrast] = React.useState(0);
    const [vibrance, setVibrance] = React.useState(0);
    const [sharpen, setSharpen] = React.useState(0);

    const [hue, setHue] = React.useState(0);
    const [saturation, setSaturation] = React.useState(0);
    const [brightness, setBrightness] = React.useState(0);

    const [red, setRed] = React.useState(0);
    const [green, setGreen] = React.useState(0);
    const [blue, setBlue] = React.useState(0);
    // const [alpha, setAlpha] = React.useState(0);
    const [fullScreen, setFullScreen] = React.useState(false);

    const handleSave = () => {
        const imgix = ImgixBuilder.parse(currentUrl);
        onSave(imgix.getUrl(false));
    };

    // React.useEffect(() => {
    //     const imgix = ImgixBuilder.parse(url);
    //     setCurrentUrl(imgix.getUrl());
    //     setBrightness(imgix.brightness);
    //     setContrast(imgix.contrast);
    //     setSaturation(imgix.saturation);
    //     setSharpen(imgix.sharpen);

    //     setRed(imgix.red);
    //     setGreen(imgix.green);
    //     setBlue(imgix.blue);
    //     setAlpha(isNil(imgix.alpha) ? 0 : imgix.alpha * 100);
    // }, [url]);

    React.useEffect(() => {
        if (!open) {
            setCurrentUrl(undefined);
            setBrightness(0);
            setContrast(0);
            setSaturation(0);
            setSharpen(0);
    
            // setRed(0);
            // setGreen(0);
            // setBlue(0);
            // setAlpha(0);
        } else {
            const imgix = ImgixBuilder.parse(url);
            setCurrentUrl(imgix.getUrl());
            setBrightness(imgix.brightness);
            setContrast(imgix.contrast);
            setSaturation(imgix.saturation);
            setSharpen(imgix.sharpen);
    
            // setRed(imgix.red);
            // setGreen(imgix.green);
            // setBlue(imgix.blue);
            // setAlpha(isNil(imgix.alpha) ? 0 : imgix.alpha * 100);
        }
    }, [open]);

    React.useEffect(() => {
        if (currentUrl) {
            const imgix = ImgixBuilder.parse(currentUrl);
            imgix.brightness = brightness;
            // alert(JSON.stringify(imgix));
            setCurrentUrl(imgix.getUrl());
        }
    }, [brightness]);

    React.useEffect(() => {
        if (currentUrl) {
            const imgix = ImgixBuilder.parse(currentUrl);
            imgix.contrast = contrast;
            // alert(JSON.stringify(imgix));
            setCurrentUrl(imgix.getUrl());
        }
    }, [contrast]);

    React.useEffect(() => {
        if (currentUrl) {
            const imgix = ImgixBuilder.parse(currentUrl);
            imgix.saturation = saturation;
            setCurrentUrl(imgix.getUrl());
        }
    }, [saturation]);

    React.useEffect(() => {
        if (currentUrl) {
            const imgix = ImgixBuilder.parse(currentUrl);
            imgix.vibrance = vibrance;
            setCurrentUrl(imgix.getUrl());
        }
    }, [vibrance]);

    React.useEffect(() => {
        if (currentUrl) {
            const imgix = ImgixBuilder.parse(currentUrl);
            imgix.sharpen = sharpen;
            setCurrentUrl(imgix.getUrl());
        }
    }, [sharpen]);

    // React.useEffect(() => {
    //     if (currentUrl) {
    //         const imgix = ImgixBuilder.parse(currentUrl);
    //         imgix.red = red;
    //         imgix.green = green;
    //         imgix.blue = blue;
    //         imgix.alpha = !isNil(alpha) ? alpha / 100 : 0;
    //         const imgixUrl = imgix.getUrl();
    //         setCurrentUrl(imgixUrl);
    //     }
    // }, [red, green, blue, alpha]);

    const { mdUp } = useViewport();

    React.useLayoutEffect(() => {
      if (open) {
        setFullScreen(false);
      }
    }, [open]);

    return (
        <Dialog fullScreen={fullScreen || !mdUp} open={open} classes={{ paper: mdUp ? classes.paperMd : '' }}>
            <DialogTitle>Edit image</DialogTitle>
            <DialogContent>
                <Grid container={true}>
                    <Grid item={true} xs={fullScreen || !mdUp ? 12 : undefined} style={{ maxWidth: '100%' }}>
                        <ZoomImage
                          fullScreen={fullScreen}
                          src={currentUrl}
                          onToggleFullScreen={setFullScreen}
                        />
                    </Grid>
                    <Grid item={true} style={{ display: fullScreen ? 'none' : undefined }}>
                        <EditImageSlider
                            value={brightness}
                            setValue={setBrightness}
                            title="Brightness"
                            min={-100}
                            max={100}
                        />
                        <EditImageSlider
                            value={contrast}
                            setValue={setContrast}
                            title="Contrast"
                            min={-100}
                            max={100}
                        />
                        <EditImageSlider
                            value={saturation}
                            setValue={setSaturation}
                            title="Saturation"
                            min={-100}
                            max={100}
                        />
                        <EditImageSlider
                            value={vibrance}
                            setValue={setVibrance}
                            title="Vibrance"
                            min={-100}
                            max={100}
                        />
                        <EditImageSlider
                            value={sharpen}
                            setValue={setSharpen}
                            title="Sharpen"
                            min={0}
                            max={100}
                        />
                        {/* <EditImageSlider
                            value={red}
                            setValue={setRed}
                            title="Red"
                            min={0}
                            max={0xff}
                        />
                        <EditImageSlider
                            value={green}
                            setValue={setGreen}
                            title="Green"
                            min={0}
                            max={0xff}
                        />
                        <EditImageSlider
                            value={blue}
                            setValue={setBlue}
                            title="Blue"
                            min={0}
                            max={0xff}
                        />
                        <EditImageSlider
                            value={alpha}
                            setValue={setAlpha}
                            title="Alpha"
                            min={0}
                            max={100}
                        /> */}
                        {/* <ColorBox
                            defaultValue="transparent"
                            value={color}
                            inputFormats={[]}
                            onChange={(color) => {
                                // setRed(color.rgb[0]);
                                // setGreen(color.rgb[1]);
                                // setBlue(color.rgb[2]);
                                // setAlpha(color.alpha);
                            }}
                        /> */}
                        {/* <Grid container={true} spacing={2} style={{ marginTop: 10, marginLeft: 10 }}>
                            <Grid item={true} style={{width: 80}}>Red</Grid>
                            <Grid item={true} xs={true}>
                                <ShadeSlider
                                    hsva={{
                                        h: 0, 
                                        s: colord({r: red, g: 0, b: 0}).toHsv().s, 
                                        v: colord({r: red, g: 0, b: 0}).toHsv().v, 
                                        a: 1,
                                    }}
                                    style={{ marginTop: 10, marginLeft: 10 }}
                                    onChange={(newShade) => {
                                        const color: HsvColor = {
                                            h: 0,
                                            s: newShade.s,
                                            v: newShade.v,
                                        };
                                        const { r } = colord(color).toRgb();
                                        setRed(r);
                                    }}
                                />
                            </Grid>
                            <Grid item={true} style={{ padding: 0 }}>
                                <TextField
                                    value={red}
                                    style={{
                                        width: 42,
                                    }}
                                />
                            </Grid>
                        </Grid> */}
                        {/* <ShadeSlider
                            hsva={{h: 0, s: 100, v: 100, a: 1}}
                            style={{ marginTop: 10, marginLeft: 10 }}
                        /> */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditImageDialog;
