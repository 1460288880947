import styled from 'styled-components';

import {PLAIN_COLORS} from '../../constants/colors';

export type TColorProps = 'mainBlack' | 'mainGreen' | 'mainOrange' | 'mainRed';

type PropsType = {
    color?: TColorProps;
    inline?: boolean;
    fontWeight?: number | 'bold';
    fontSize?: number;
    lineHeight?: number;
    textAlign?: string;
};

export const BasicTitle = styled.div<PropsType>`
  display: ${({inline}) => (inline ? 'inline-block' : 'block')};
  font-weight: ${({fontWeight}) =>
    fontWeight ? fontWeight : 'bold'};
  color: ${({color}) => color ? PLAIN_COLORS[color] : PLAIN_COLORS.mainGreen};
  font-size: ${({fontSize}) => `${fontSize || 18}px`};
  line-height: ${({lineHeight}) => `${lineHeight || 20}px`};
  text-align: ${({textAlign}) => textAlign || 'left'};
  padding: 10px;
`;
