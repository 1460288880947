import React, { FC } from 'react';

const AddIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333Z"
        fill="#EEF7F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 17V22H17V17L22 17V15L17 15V10H15V15L10 15V17L15 17Z"
        fill="#56ABA9"
      />
    </svg>
  );
};

export default AddIcon;
