import { makeStyles } from '@material-ui/core';

export const CELL_SPACING = {
  X: 16,
  Y: 8,
};

export const useStyles = makeStyles(() => ({
  tableContainer: {
    borderLeft: '1px solid #D7DAE4',
  },
  table: {
    minWidth: 650,
    borderCollapse: 'separate',
    '& tr th:last-child .resize-bar': {
      right: 0,
    },
  },
  headCell: {
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: `8px ${CELL_SPACING.X}px`,
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #D7DAE4',
    borderRight: '1px solid #D7DAE4',
    borderBottom: '2px solid #D7DAE4',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    left: 'auto',
  },
  headerCellTitle: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#737DA0',
    marginBottom: 4,
  },
  bodyCell: {
    display: 'table-cell',
    whiteSpace: 'nowrap',
    padding: `${CELL_SPACING.Y}px ${CELL_SPACING.X}px`,
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid #D7DAE4',
    borderBottom: '1px solid #D7DAE4',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'left',
  },
  bodyCellBox: {
    borderRadius: 3,
  },
  bodyCellImport: {
    cursor: 'pointer',
  },
}));
