import React from 'react';

import FileUploader from '../fileuploader/PdfFileUploader';
import { isDefined } from '../../utils/helpers';
import COLORS from '../../constants/colors';
import PDFList from '../pdf/PDFList';
import CircularSpinner from '../loadingIndicator/CircularSpinner';

const ProducerView = ({ onAddCertificate, onRemoveCertificate, isUploading, certificates, onPDFButtonClicked }) => {
  const newCerts = certificates && certificates.map((cert) => cert.replace('#', '%23'));
  return (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <FileUploader
      onAddCertificate={onAddCertificate}
      onRemoveCertificate={onRemoveCertificate}
      containerStyle={{ width: 500 }}
      isUploading={isUploading}
    />
    {isUploading && (
      <div style={{ marginTop: 20 }}>
        <CircularSpinner />
      </div>
    )}
    {isDefined(newCerts) && !isUploading && (
      <>
        <span style={{ marginTop: 40, marginBottom: 20, fontWeight: 'bold', fontSize: 14, color: COLORS.mainGreen }}>
          Current saved certificates{' '}
        </span>
        <PDFList files={newCerts} onButtonClicked={onPDFButtonClicked} />
      </>
    )}
    {!isDefined(newCerts) && !isUploading && (
      <span style={{ fontSize: 14, fontWeight: 'bold', marginTop: 20, color: COLORS.mainOrange }}>
        You currently have no certificates uploaded. Drop them above to upload!
      </span>
    )}
  </div>
  );
};

const OrganizationView = ({ certificates, onPDFButtonClicked }) => {
  const newCerts = certificates && certificates.map((cert) => cert.replace('#', '%23'));
  return (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    {isDefined(newCerts) && (
      <>
        <span style={{ marginTop: 40, marginBottom: 20, fontWeight: 'bold', fontSize: 14, color: COLORS.mainGreen }}>
          Uploaded certificates{' '}
        </span>
        <PDFList files={newCerts} onButtonClicked={onPDFButtonClicked} organizationView />
      </>
    )}
    {!isDefined(newCerts) && (
      <span style={{ fontSize: 14, fontWeight: 'bold', marginTop: 20, color: COLORS.mainOrange }}>
        No uploaded certificates.
      </span>
    )}
  </div>
  );
};

const CertificationsTab = ({
  certificates,
  isUploading,
  onAddCertificate,
  onPDFButtonClicked,
  onRemoveCertificate,
  organizationView,
}) => {
  const newCerts = certificates && certificates.map((cert) => cert.replace('#', '%23'));
  return organizationView ? (
    <OrganizationView certificates={newCerts} onPDFButtonClicked={onPDFButtonClicked} />
  ) : (
    <ProducerView
      certificates={newCerts}
      isUploading={isUploading}
      onAddCertificate={onAddCertificate}
      onPDFButtonClicked={onPDFButtonClicked}
      onRemoveCertificate={onRemoveCertificate}
    />
  );
}

export default CertificationsTab;
