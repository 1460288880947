import { APPLICATION_ROLES } from '../components/constants';

export const getUserRole = (keycloakContext) => {
    if (keycloakContext.keycloak.hasResourceRole('admin')) return APPLICATION_ROLES.ADMIN;
    if (keycloakContext.keycloak.hasResourceRole('producer')) return APPLICATION_ROLES.PRODUCER
    if (keycloakContext.keycloak.hasResourceRole('logistician')) return APPLICATION_ROLES.LOGISTICIAN
    if (keycloakContext.keycloak.hasResourceRole('superuser')) return APPLICATION_ROLES.SUPERUSER
    if (keycloakContext.keycloak.hasResourceRole('')) return APPLICATION_ROLES.QUALITY
    if (keycloakContext.keycloak.hasResourceRole('store')) return APPLICATION_ROLES.STORE
    return APPLICATION_ROLES.UNAUTHORIZED;
}

export const isSuperuser = (userRole) => userRole === APPLICATION_ROLES.SUPERUSER;
export const isProducer = (userRole) => userRole === APPLICATION_ROLES.PRODUCER;
export const isLogistician = (userRole) => userRole === APPLICATION_ROLES.LOGISTICIAN;
export const isAdmin = (userRole) => userRole === APPLICATION_ROLES.ADMIN;
export const isQuality = (userRole) => userRole === APPLICATION_ROLES.QUALITY;
export const isStore = (userRole) => userRole === APPLICATION_ROLES.STORE;