import React from 'react';

import Viewer from 'react-viewer';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import ImageWithFallback from '../ImageWithFallback';

import { makeStyles } from '@material-ui/core/styles';
import { map, compose } from 'ramda';
import { removeUrlParams } from '../../utils/urlUtils';
import { IMAGE_SIZES, getImageWithSizeSuffix } from '../../utils/images2';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
}));

const ImageGridList = ({ profiles }) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        viewerVisible: false,
        activeIndex: 0,
    });

    const handleClose = () => {
        setState({ viewerVisible: false });
    };

    // Some images may contain params for width and height in DB (legacy).
    // We clean the src url to provide the full image resolution in slider.
    const paramCleanedImages = compose(map(src => ({ src })), map(p => removeUrlParams(p)))(profiles);

    const smallImages = map(p => {
      const cleaned = removeUrlParams(p);
      const sized = getImageWithSizeSuffix(cleaned, IMAGE_SIZES.MEDIUM);
      return sized;
    }, profiles)

    return (
        <div className={classes.root}>
            <GridList cellHeight={160} className={classes.gridList} cols={3}>
                {smallImages &&
                    smallImages.map((i, index) => (
                        <GridListTile key={index} cols={1}>
                            <div
                                onClick={() =>
                                    setState({
                                        viewerVisible: true,
                                        activeIndex: index,
                                    })
                                }
                            >
                                <ImageWithFallback
                                    style={{
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                    }}
                                    src={i}
                                >
                                    {src => {
                                        return (
                                            <img
                                                style={{
                                                    maxHeight: '100%',
                                                    maxWidth: '100%',
                                                    borderRadius: '4px',
                                                }}
                                                src={src}
                                                alt=""
                                            />
                                        );
                                    }}
                                </ImageWithFallback>
                            </div>
                        </GridListTile>
                    ))}
            </GridList>
            <Viewer
                zIndex={10000}
                visible={state.viewerVisible}
                onClose={handleClose}
                images={paramCleanedImages}
                activeIndex={state.activeIndex}
            />
        </div>
    );
};

export default ImageGridList;
