import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { ErrorOutline } from "@material-ui/icons";

interface IErrorProps {
    error?: string | Error;
    message?: string;
}

export const Error = ({ error, message }: IErrorProps) => {
    let msg = '';
    if (message) {
        msg = message;
    } else if (typeof error === 'string') {
        msg = error;
    } else if (error) {
        msg = error.message;
    }
    return <Box>
        <ErrorOutline />
        <Typography variant="body2">{`Sorry, something went wrong: ${msg}`}</Typography>
    </Box>
};

export default Error;
