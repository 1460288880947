import React from 'react';
import { ProductEditGroup, ProductReviewGroup, ProductSelectGroup } from './ProductGroup';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_GET_PRODUCTS_BY_IDS } from '../graphql/queries';
import AsyncDataHandler from './AsyncDataHandler';

const ProductGroupListDisplay = props => {
    const { data, removeAction, editable, selectable, selectAction, selected, productData } = props;
    console.log("ProductGroupListDisplay ", data);
    console.log("ProductData ", productData);
    let result;
    const getProductByEan = (ean) => {
        if (data && data.productsBy && data.productsBy.data) {
            const result = data.productsBy.data.find(x => x.EAN === ean);
            return result;
        }
        return undefined;
    };
    if (selectable) {
        result = [];
        productData
        && data && data.productsBy && data.productsBy.data
        && productData.forEach(x => {
            const found = getProductByEan(x.sku);
            if (found) {
                found.daysLeft = x.days_left_new;
                found.isNegativeDays = x.days_left_negative;
                found.lastOrderDaysAgo = x.last_order_days_ago;
                if (found.producer) {
                    found.producerId = found.producer.id;
                    found.producerName = found.producer.name;
                }
                found.productId = found.id;
            }
            if (found) {
                result.push(found);
            }
        });
        // Right now it doesn't collapse same producer products, do we want that?
        result = result.map((x) => ({
            id: x.producerId,
            productId: x.productId,
            name: x.producerName,
            daysLeft: x.daysLeft,
            isNegativeDays: x.isNegativeDays,
            lastOrderDaysAgo: x.lastOrderDaysAgo,
            products: [x]
        }));
    } else {
        result = data &&
        data.productsBy.data &&
        data.productsBy.data
            .reduce((acc, product) => {
                let found = acc.find(o => o.id === product.producer.id);
                if (found) {
                    found.products.push(product);
                } else {
                    acc.push({
                        id: product.producer.id,
                        name: product.producer.name,
                        deliverySchedules: product.producer.deliverySchedules,
                        products: [product],
                        delivery: product.producer.delivery,
                    });
                }
                return acc;
            }, []);
    }
    return (
        <>
            {result &&
                    result.map(item => {
                        if (editable) {
                            return (
                                <ProductEditGroup
                                    key={item.id}
                                    title={item.name}
                                    list={item.products}
                                    removeAction={removeAction}
                                    producerId={item.id}
                                    deliverySchedules={item.deliverySchedules}
                                    delivery={item.delivery}
                                />
                            );
                        } else if (selectable) {
                            return (
                                <ProductSelectGroup
                                    key={item.productId}
                                    title={item.name}
                                    list={item.products}
                                    selectAction={selectAction}
                                    producerId={item.id}
                                    deliverySchedules={item.deliverySchedules}
                                    delivery={item.delivery}
                                    selected={selected}
                                    productData={productData}
                                    daysLeft={item.daysLeft}
                                    isNegativeDays={item.isNegativeDays}
                                    lastOrderDaysAgo={item.lastOrderDaysAgo}
                                />
                            );
                        } else {
                            return (
                                <ProductReviewGroup
                                    key={item.id}
                                    title={item.name}
                                    list={item.products}
                                    producerId={item.id}
                                    delivery={item.delivery}
                                    deliverySchedules={item.deliverySchedules}
                                />
                            );
                        }
                    })}
        </>
    );
};

export default function ProductGroupList(props) {
    const { list, removeAction, editable, selectable, selectAction, selected, productData, searchByEans, saveOrder } = props;

    const filterValue = list?.filter(list => list != null) || [];
    const productFilter = searchByEans ? { eans: filterValue } : { ids: filterValue };
    const loadingData = useQuery(QUERY_GET_PRODUCTS_BY_IDS, {
        variables: {
            productFilter,
            saveOrder,
        },
        fetchPolicy: 'no-cache',
    });
    return (
        <AsyncDataHandler {...loadingData}>
            <ProductGroupListDisplay
                removeAction={removeAction}
                selectAction={selectAction}
                editable={editable}
                selectable={selectable}
                selected={selected}
                productData={productData}
            />
        </AsyncDataHandler>
    );
}

ProductGroupList.defaultProps = {
    list: [],
};
