class IndexedDB {
  constructor(params: { databaseName: string; objectStoreNameList: string[] }) {
    this.databaseName = params.databaseName;
    this.objectStoreNameList = params.objectStoreNameList;
  }

  databaseName: string;
  objectStoreNameList: string[];
  database: IDBDatabase | null = null;

  closeDatabase() {
    if (this.database) this.database.close();
  }

  deleteDatabase(): Promise<{}> {
    const databaseName = this.databaseName;
    const database = this.database;

    return new Promise((resolve, reject) => {
      database?.close();
      const request = window.indexedDB.deleteDatabase(databaseName);
      request.onsuccess = () => resolve({});
      request.onerror = error => reject({ error });
      request.onblocked = error => reject({ error });
    });
  }

  openDatabase(): Promise<{ data: IndexedDB }> {
    const databaseName = this.databaseName;
    const objectStoreNameList = this.objectStoreNameList;
    const _this = this;

    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(databaseName, 1);
      request.onupgradeneeded = event => {
        const database = (event.target as IDBOpenDBRequest).result;
        this.database = database;
        objectStoreNameList.forEach(objectStoreName => {
          if (!database.objectStoreNames.contains(objectStoreName)) {
            database.createObjectStore(objectStoreName, { keyPath: 'id' });
          }
        });
      };
      request.onsuccess = event => {
        this.database = (event.target as IDBOpenDBRequest).result;
        resolve({ data: _this });
      };
      request.onerror = error => reject({ error });
    });
  }

  clearObjectStore(objectStoreName: string): Promise<{}> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      objectStore.clear();

      transaction.oncomplete = () => resolve({});
      transaction.onerror = error => reject({ error });
    });
  }

  count(objectStoreName: string): Promise<{ data: number }> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      const request = objectStore.count();

      transaction.oncomplete = () => resolve({ data: request.result });
      transaction.onerror = error => reject({ error });
    });
  }

  read<TValue>(objectStoreName: string, id: string): Promise<{ data: { id: string | number; value: TValue } }> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      const request = objectStore.get(id);

      transaction.oncomplete = () => resolve({ data: request.result });
      transaction.onerror = error => reject({ error });
    });
  }

  readAll<TValue>(objectStoreName: string): Promise<{ data: { id: string | number; value: TValue }[] }> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      const request = objectStore.getAll();

      transaction.oncomplete = () => resolve({ data: request.result });
      transaction.onerror = error => reject({ error });
    });
  }

  write<TValue>(objectStoreName: string, data: { id: string | number; value: TValue }): Promise<{}> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      objectStore.add(data);

      transaction.oncomplete = () => resolve({ data: data.id });
      transaction.onerror = error => reject({ error });
    });
  }

  delete(objectStoreName: string, id: string | number): Promise<{}> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      objectStore.delete(id);

      transaction.oncomplete = () => resolve({ data: id });
      transaction.onerror = error => reject({ error });
    });
  }

  writeList<TValue>(objectStoreName: string, dataList: TValue[]): Promise<{}> {
    const database = this.database;

    return new Promise((resolve, reject) => {
      if (!database) return reject({ error: 'database is null' });

      const transaction = database!.transaction(objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(objectStoreName);
      dataList.forEach((data, index) => {
        objectStore.add({ id: index, value: data });
      });

      transaction.oncomplete = () => resolve({});
      transaction.onerror = error => reject({ error });
    });
  }
}

export default IndexedDB;
