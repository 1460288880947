import { FC, ReactNode, useMemo } from 'react';
import clsx from 'clsx';

import ErrorInfoIcon from '../../../components/ErrorInfoIcon';
import ReviewTableBodyCell from '../ReviewTableBodyCell';
import ReviewTableHeaderCell from '../ReviewTableHeaderCell';
import { Link, makeStyles } from '@material-ui/core';

import useDataContext from '../../../hooks/useDataContext';
import useControlContext from '../../../hooks/useControlContext';

import { IImportColumn, IProductExcel } from '../../../types';
import { STATUS_COLORS, STATUS_COLORS_OPACITY } from '../../../constants';
import { getErrorRowCells } from '../../../utils/excel';

import { useStyles, CELL_SPACING } from '../index.style';

export const PIN_COLUMN = 'title';
const COLUMN_WIDTH = {
  FIRST: 80,
};

export const useCustomStyles = makeStyles(() => ({
  fixedFirstColumnCell: {
    left: 0,
    zIndex: 10,
    position: 'sticky',
  },
  highlightBorder: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    borderLeft: '3px solid transparent',
  },
  fixedSecondColumnCell: {
    left: COLUMN_WIDTH.FIRST + 1, // 1px for Border
    zIndex: 10,
    borderRightWidth: 2,
    position: 'sticky',
  },
  bodyCellIdWrapper: {
    position: 'relative',
    width: COLUMN_WIDTH.FIRST - CELL_SPACING.X * 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
}));

interface BaseProps {
  isReview: boolean;
  isFakeCell?: boolean;
  columnList: IImportColumn[];
}

export const FixedHeaderCellList: FC<BaseProps> = ({ isReview, isFakeCell, columnList }) => {
  const customClasses = useCustomStyles();

  const { setEndPinnedCellElement } = useControlContext();

  const pinColumn = useMemo(() => columnList.find(({ field }) => field === PIN_COLUMN), [columnList]);

  // const handleChangeColumnIdWidth = (newWidth: number) => {
  //   const tableCellIdWidth = newWidth + CELL_SPACING.X * 2;
  //   document.querySelectorAll<HTMLElement>(`[data-field='${pinColumn?.field}']`).forEach(element => {
  //     const parentElement = element.parentElement as HTMLTableElement;
  //     parentElement.style.left = `${tableCellIdWidth + 1}px`; // 1px for Border
  //   });
  // };

  if (isReview) return null;

  return (
    <>
      <ReviewTableHeaderCell
        title="#"
        id="id"
        field="id"
        minWidth={COLUMN_WIDTH.FIRST}
        isFakeCell={isFakeCell}
        resizable={false}
        isFieldUpdatable={false}
        showField={false}
        isReview={isReview}
        dataType="string"
        className={customClasses.fixedFirstColumnCell}
        wrapperProps={{ style: { width: COLUMN_WIDTH.FIRST - CELL_SPACING.X * 2 } }}
      />

      {pinColumn && (
        <ReviewTableHeaderCell
          title={String(pinColumn.title)}
          id={pinColumn._id}
          field={pinColumn.field}
          isFakeCell={isFakeCell}
          isError={pinColumn.isError}
          showField={!isReview}
          isReview={isReview}
          dataType="string"
          className={customClasses.fixedSecondColumnCell}
        />
      )}

      {/* end pinned cell */}
      <th ref={setEndPinnedCellElement} style={{ padding: 0 }} />
    </>
  );
};

interface FixedBodyCellListProps extends BaseProps {
  isApproved?: boolean;
  data: IProductExcel;
}

const FixedBodyCellList: FC<FixedBodyCellListProps> = ({ isReview, columnList, data }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { onScrollToColumn } = useControlContext();
  const { uploadedProductStatusSet, productImageDataSet } = useDataContext();

  const pinColumn = useMemo(() => columnList.find(({ field }) => field === PIN_COLUMN), [columnList]);

  const uploadedProductStatus = useMemo(
    () => uploadedProductStatusSet[data.id || ''] || {},
    [data.id, uploadedProductStatusSet]
  );

  const errorList = useMemo(() => {
    const result: { label?: ReactNode; message?: ReactNode }[] = [];

    if (uploadedProductStatus.error) {
      result.push({ label: 'Error Upload Product', message: uploadedProductStatus.error });
    }

    if (!productImageDataSet[data.id || '']?.length && !data.image_src) {
      result.push({
        label: (
          <span style={{ display: 'block', cursor: 'pointer' }} onClick={() => onScrollToColumn('image')}>
            <Link underline="none">Bilder</Link>
          </span>
        ),
        message: 'Missing image',
      });
    }

    const errorCellList = getErrorRowCells(columnList, data);
    errorCellList.forEach(errorCell =>
      result.push({
        label: (
          <span
            style={{ display: 'block', cursor: 'pointer' }}
            onClick={() => onScrollToColumn(errorCell.column.field)}
          >
            <Link underline="none">{errorCell.column.title}</Link>
          </span>
        ),
        message: errorCell.message,
      })
    );

    return result;
  }, [columnList, data, uploadedProductStatus, productImageDataSet, onScrollToColumn]);

  if (isReview) return null;

  let borderColor = STATUS_COLORS.SUCCESS;
  let background = STATUS_COLORS_OPACITY.SUCCESS;
  if (errorList.length) {
    borderColor = STATUS_COLORS.ERROR;
    background = STATUS_COLORS_OPACITY.ERROR;
  }

  return (
    <>
      {/* Id column */}
      <td
        className={clsx(classes.bodyCell, customClasses.fixedFirstColumnCell)}
        style={{ background, borderLeftColor: borderColor }}
      >
        <div className={customClasses.highlightBorder} style={{ borderColor }} />
        <div data-field="id" className={clsx(classes.bodyCellBox, customClasses.bodyCellIdWrapper)}>
          <div style={{ width: '100%' }}>{data.id || ''}</div>
          {!!errorList.length && <ErrorInfoIcon errors={errorList} />}
        </div>
      </td>

      {/* pinned column */}
      {pinColumn && (
        <ReviewTableBodyCell
          isReview={isReview}
          className={customClasses.fixedSecondColumnCell}
          column={pinColumn}
          data={data}
        />
      )}

      {/* end pinned cell */}
      <td style={{ padding: 0 }} />
    </>
  );
};

const FixedColumnList = {
  HeaderCellList: FixedHeaderCellList,
  BodyCellList: FixedBodyCellList,
};

export default FixedColumnList;
