import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import SuggestContent from '../components/SuggestContent';
import SelectContent from '../components/SelectContent';
import ReviewContent from '../components/ReviewContent';
import NotificationContent from '../components/NotificationContent';
import { KeycloakContext } from '../components/Secured';
import { useHistory, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { buildOrderContactInfo } from '../utils/orders';

const useStyles = makeStyles(theme => ({
    stepper: {
        backgroundColor: '#fafafa',
    },
}));
function getSteps() {
    return ['Automatic Suggestions', 'Select', 'Review', 'Notification'];
}

export default function Order(props) {
    const classes = useStyles();
    const steps = getSteps();
    const keycloakCtx = useContext(KeycloakContext);

    // Build order contact info.
    const keycloakIdInfo = keycloakCtx?.keycloak?.idTokenParsed;
    const orderContactInfo = buildOrderContactInfo(keycloakIdInfo);

    const {
        match: { params },
    } = props;
    const history = useHistory();

    const activeStep = () => {
        let subpath = params.step;
        if (subpath === 'suggestions') {
            return 0;
        } else if (subpath === 'select') {
            return 1;
        } else if (subpath === 'review') {
            return 2;
        } else if (subpath === 'notification') {
            return 3;
        }
        return 0;
    };

    const select = () => {
        history.push(`/order/select`);
    };

    const review = list => {
        if (Array.isArray(list) && list.length) {
            history.push('/order/review');
        }
    };

    const confirm = orderNumber => {
        history.push(`/order/notification/${orderNumber}`);
    };

    return (
        <>
            <Stepper
                activeStep={activeStep()}
                alternativeLabel
                className={classes.stepper}
            >
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper>
                <Route
                    path="/order/suggest"
                        render={props => {
                            return <SuggestContent {...props} nextAction={select} />;
                        }}
                    />
                    <Route
                    path="/order/select"
                    render={props => {
                        return <SelectContent {...props} nextAction={review} />;
                    }}
                />
                <Route
                    path="/order/review"
                    render={props => {
                        return (
                            <ReviewContent
                                {...props}
                                confirmAction={confirm}
                                orderContactInfo={orderContactInfo}
                            />
                        );
                    }}
                />
                <Route
                    path="/order/notification/:groupOrderNumber"
                    render={props => {
                        return <NotificationContent {...props} />;
                    }}
                />
            </Paper>
        </>
    );
}
