import { useState } from 'react';
import svLocale from 'date-fns/locale/sv';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import styled from 'styled-components';

import IProduct from 'models/product';

import { TextField } from '@material-ui/core';
import LocalizationProvider from '@material-ui/pickers/LocalizationProvider';
import { DesktopDatePicker } from '@material-ui/pickers';
import InputLabel from './InputLabel';

interface ILabelOptions {
  heading?: string;
  text?: string;
  link?: string;
}

const StyledTextField = styled(TextField)`
  margin-bottom: 5px;
  & .MuiFormHelperText-root {
    display: none;
  }
`;

interface DateFieldProps<T extends IProduct> {
  name: keyof T;
  value?: number | null;
  labelOptions?: ILabelOptions;
  placeholder?: string;
  onChange: (name: keyof T, dateTime: number | null) => void;
}

const DateField = <T extends IProduct>({ name, value, labelOptions, placeholder, onChange }: DateFieldProps<T>) => {
  const [isValid, setValid] = useState(true);

  return (
    <div>
      {labelOptions && <InputLabel {...labelOptions} />}

      <LocalizationProvider dateAdapter={DateFnsUtils} locale={svLocale}>
        <DesktopDatePicker
          clearable
          inputFormat="yyyy-MM-dd"
          value={value}
          renderInput={params => (
            <StyledTextField
              {...params}
              error={!isValid}
              fullWidth
              variant="outlined"
              inputProps={{ ...params.inputProps, placeholder }}
            />
          )}
          onChange={newValue => {
            const newDate = newValue as unknown as Date;
            if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
              setValid(true);
              onChange(name, newDate.getTime());
            } else {
              setValid(false);
              onChange(name, null);
            }
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateField;
