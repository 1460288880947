import React from 'react';
import permissions from '../permissions';

const withRoleNavItems = role => WrappedComponent => {
    return props => {
        const rolePermissions = permissions[role];
        let navItems
        if (Array.isArray(rolePermissions) && rolePermissions.length) {
            navItems = rolePermissions.filter(rp => rp.menu && rp.menu !== null && rp.menu !== undefined).map(rp => { return { link: rp.route.getNavPath?.() || rp.route.path, ...rp.menu } })
        }
        return <WrappedComponent navItems={navItems} {...props} />
    }
}

export default withRoleNavItems;