import React, { FC } from 'react';

const FilledQuestion: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.943359 6C0.943359 3.24 3.05691 1 5.6611 1C8.2653 1 10.3788 3.24 10.3788 6C10.3788 8.76 8.2653 11 5.6611 11C3.05691 11 0.943359 8.76 0.943359 6ZM5.20683 7.05127H5.97761C5.97976 6.87809 6.00234 6.72542 6.04534 6.59326C6.08834 6.4611 6.17111 6.33691 6.29367 6.2207C6.43342 6.08171 6.56349 5.94385 6.68389 5.80713C6.80429 5.67041 6.90104 5.52344 6.97414 5.36621C7.04725 5.20671 7.0838 5.02669 7.0838 4.82617C7.0838 4.5459 7.02789 4.30664 6.91609 4.1084C6.80644 3.91016 6.64627 3.75977 6.43557 3.65723C6.22702 3.55241 5.97546 3.5 5.68091 3.5C5.41431 3.5 5.17351 3.55013 4.95851 3.65039C4.74351 3.74837 4.57258 3.89762 4.44573 4.09814C4.31888 4.29639 4.25223 4.54476 4.24578 4.84326H5.10041C5.10471 4.69971 5.13373 4.5835 5.18748 4.49463C5.24123 4.40348 5.31219 4.33626 5.40034 4.29297C5.48849 4.24967 5.58201 4.22803 5.68091 4.22803C5.80131 4.22803 5.90129 4.25309 5.98084 4.30322C6.06254 4.35107 6.12381 4.42171 6.16466 4.51514C6.20766 4.60856 6.22917 4.72477 6.22917 4.86377C6.22917 4.98682 6.20659 5.09733 6.16144 5.19531C6.11629 5.29102 6.05394 5.3833 5.97439 5.47217C5.89699 5.55876 5.80884 5.65104 5.70994 5.74902C5.58524 5.87435 5.48634 5.9974 5.41324 6.11816C5.34014 6.23665 5.28746 6.36995 5.25521 6.51807C5.22511 6.66618 5.20898 6.84391 5.20683 7.05127ZM5.25521 7.77246C5.16921 7.86361 5.12621 7.97754 5.12621 8.11426C5.12621 8.24642 5.16921 8.35807 5.25521 8.44922C5.34336 8.54036 5.46161 8.58594 5.60996 8.58594C5.75831 8.58594 5.87549 8.54036 5.96149 8.44922C6.04964 8.35807 6.09371 8.24642 6.09371 8.11426C6.09371 7.97754 6.04964 7.86361 5.96149 7.77246C5.87549 7.68131 5.75831 7.63574 5.60996 7.63574C5.46161 7.63574 5.34336 7.68131 5.25521 7.77246Z"
        fill="#9CA3BC"
      />
    </svg>
  );
};

export default FilledQuestion;
