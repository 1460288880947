import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Theme, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

import ImageWithFallback from '../ImageWithFallback';
import { isDefined } from '../../utils/helpers';
import { path } from 'ramda';
import { KeycloakContext } from 'components/Secured';
import { restEndpoints } from 'constants/domain';

const styles = (theme: Theme) => ({
    actions: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 200,
    },
});

interface IProducerCardProps {
    superuser?: boolean;
    name?: string;
    username?: string;
    description?: string;
    profiles?: string[];
    classes: any; // TODO
    id?: string;
    productsSummary?: IProductsSummary;
    exportUrl?: string;
}

interface IDetails {
    numOfPending?: number;
    numOfApproved?: number;
    numOfDisapproved?: number;
}

interface IProductsSummary {
    details?: IDetails;
}

const ProducerCard = ({
    id,
    name,
    username,
    profiles,
    description,
    productsSummary,
    classes,
    superuser,
    exportUrl,
}: IProducerCardProps) => {
    const profileImageUrl = isDefined(profiles) ? profiles && profiles[0] : '';

    const linkTo = superuser
        ? `/superuser/producer/${id}/edit`
        : `/producers/${id}/show`;

    // const keycloakCtx = useContext(KeycloakContext);
    // const endpoint = `${restEndpoints.excelDownload}?username=${username}&onlyExcel=1&includeAll=1`;
    // const eansQs = (eans && eans.trim() !== '') ? `&eans=${eans}`: '';
    // const usernameQs = (username && username.trim() !== '') ? `&username=${username}` : '';
    // const onlyExcelQs = onlyExcel ? '&onlyExcel=1' : '';
    // const includeDisapprovedQs = includeAll ? '&includeAll=1' : '';
    // const endpoint = `${ restEndpoints.approvedProductsDownload }?onetype=1&nonfood=${isNonFood ? 1 : 0}${eansQs}${onlyExcelQs}${usernameQs}${includeDisapprovedQs}`;
    // const downloadExcel = async () => {
    //     const token = path(['keycloak', 'token'], keycloakCtx);

    //     fetch(endpoint, {
    //         method: 'POST',
    //         headers: {
    //         Authorization: `Bearer ${token}`,
    //         },
    //     })
    //     .then(r => r.blob())
    //     .then(b => {
    //         const url = window.URL.createObjectURL(new Blob([b]));
    //         const dLink = document.createElement('a');
    //         dLink.href = url;
    //         dLink.setAttribute('download', `${username}.xlsx`);
    //         document.body.appendChild(dLink);
    //         dLink.click();
    //         dLink.parentNode?.removeChild(dLink);
    //     })
    //     // .catch(e => setExportError(() => [true, e]))
    //     // .finally(() => setIsDownloading(() => false));
    // };
    return (
        <Card key={id} className={classes.card}>
            <CardActionArea component={Link} to={linkTo}>
                <CardMedia
                    style={{
                        height: 200,
                        maxWidth: 345,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    <ImageWithFallback
                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                        src={profileImageUrl}
                    >
                        {(src: string) => {
                            return (
                                <img
                                    style={{
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                    }}
                                    src={src}
                                    alt=""
                                />
                            );
                        }}
                    </ImageWithFallback>
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="subtitle1" noWrap>
                        {name}
                    </Typography>
                    <Typography component="p" noWrap>
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {!superuser && (
                <CardActions classes={{ root: classes.actions }}>
                    <Badge
                        color="primary"
                        badgeContent={productsSummary?.details?.numOfPending}
                        className={classes.margin}
                    >
                        <Button size="small" variant="outlined" href={`/producers/${id}/products?productTab=PENDING`}>
                            Pending
                        </Button>
                    </Badge>
                    <Badge
                        color="secondary"
                        badgeContent={productsSummary?.details?.numOfApproved}
                        className={classes.margin}
                    >
                        <Button size="small" variant="outlined" href={`/producers/${id}/products?productTab=APPROVED`}>
                            Approved
                        </Button>
                    </Badge>
                </CardActions>
            )}
            {/* {exportUrl && (
                <CardActions classes={{ root: classes.actions }}>
                        <Button size="small" variant="outlined" onClick={downloadExcel}>
                            Export Excel
                        </Button>
                </CardActions>
            )} */}
        </Card>
    );
}

export default withStyles(styles)(ProducerCard);
