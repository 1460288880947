import React, { FC } from 'react';

const ArrowLeftIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#56ABA9" />
    </svg>
  );
};

export default ArrowLeftIcon;
