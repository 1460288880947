import { makeStyles } from '@material-ui/core/styles';

export const SPACING = 24;
export const ARROW_COLUMN_WIDTH = 38;

export const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING,
  },
  mainCell: {
    flex: '1 0 0',
    maxWidth: `calc(33% - ${SPACING}px - ${ARROW_COLUMN_WIDTH / 3}px)`,
  },
  arrowCell: {
    width: ARROW_COLUMN_WIDTH,
  },
  fieldSelect: {
    '& .MuiSelect-root': {
      padding: '8px 32px 8px 8px',
    },
  },
}));
