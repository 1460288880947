import React, { useCallback, useEffect, useState } from 'react';

import IProduct from '../../../../models/product';
import InputLabel from '../../../inputfields/InputLabel';
import FloatInput from '../FloatInput';
import useViewport from 'hooks/useViewports';
import ThreeWaySwitch from 'components/inputfields/ThreeWaySwitch';

interface IGrossDimensionsProps {
  approve: boolean;
  state: IProduct;
  setState: React.Dispatch<React.SetStateAction<IProduct>>;
}

export const checkDecimal = (value?: string | number) => {
  const strValue = String(value);
  return strValue.includes(',') || strValue.includes('.');
}

const REQUIRED_ERROR = 'Fill required dimension fields';

export const checkRequireDimenstions = (state: IProduct) => {
  const { gross_height, gross_width, gross_depth } = state;
  if (String(gross_height) && String(gross_width) && String(gross_depth)) {
    return '';
  } else if (!String(gross_height) && !String(gross_width) && !String(gross_depth)) {
    return '';
  } else {
    return REQUIRED_ERROR;
  }
};

const GrossDimensions = ({ approve, state, setState }: IGrossDimensionsProps) => {
  const { mdUp } = useViewport();

  const [error, setError] = useState('');

  const handleCheckError = useCallback(() => {
    let newError = '';

    if (
      checkDecimal(state.gross_height) ||
      checkDecimal(state.gross_width) ||
      checkDecimal(state.gross_depth)
    ) {
      newError = 'Use only whole numbers for product dimensions';
    }

    // check requirement
    if (checkRequireDimenstions(state)) {
      newError = REQUIRED_ERROR;
    }

    setError(newError);
  }, [state]);

  useEffect(() => {
    handleCheckError();
  }, [handleCheckError]);

  useEffect(() => {
    setState((oldState) => ({ ...oldState, showDimensionsError: !!error }))
  }, [error, setState]);

  const canApprove = state?.approve?.find((x) => x.field === 'gross_height');

  return (
    <>
      <InputLabel
        heading='Konsumentförpackningens dimensioner'
        text='Ange produktens höjd, bredd och djup inklusive emballage. Måtten anges i millimeter.'
        required
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <div style={{ display: 'flex', flexDirection: mdUp ? 'row' : 'column', flex: 1 }}>
          {!mdUp && <InputLabel heading="Höjd" />}
          <FloatInput
            forceRed={
              checkDecimal(state.gross_height) ||
              (!!checkRequireDimenstions(state) && !String(state?.gross_height))
            }
            name="gross_height"
            label="Höjd"
            state={state}
            setState={setState}
            dense={false}
            style={{ maxWidth: 150, width: '100%', flex: 1 }}
          />
          {mdUp && <span style={{alignSelf: 'center', fontSize: 25}}>&nbsp;x&nbsp;</span>}
          {!mdUp && <InputLabel heading="Bredd" />}
          <FloatInput
            forceRed={
              checkDecimal(state.gross_width) ||
              (!!checkRequireDimenstions(state) && !String(state?.gross_width))
            }
            name="gross_width"
            label="Bredd"
            state={state}
            setState={setState}
            dense={false}
            style={{ maxWidth: 150, width: '100%', flex: 1 }}
          />
          {mdUp && <span style={{alignSelf: 'center', fontSize: 25}}>&nbsp;x&nbsp;</span>}
          {!mdUp && <InputLabel heading="Djup" />}
          <FloatInput
            forceRed={
              checkDecimal(state.gross_depth) ||
              (!!checkRequireDimenstions(state) && !String(state?.gross_depth))
            }
            name="gross_depth"
            label="Djup"
            state={state}
            setState={setState}
            dense={false}
            style={{ maxWidth: 150, width: '100%', flex: 1 }}
          />
          <h3 style={{ alignSelf: mdUp ? 'center' : 'start', marginTop: mdUp ? undefined : 0 }}>
            &nbsp;millimeter
          </h3>
        </div>

        {approve && canApprove && (
          <ThreeWaySwitch
            name='gross_height'
            state={state}
            setState={setState}
          />
        )}
      </div>
      {!!error && (
        <span style={{ fontWeight: 'bold', color: 'orangered' }}>
          {error}
        </span>
      )}
    </>
  );
};

export default GrossDimensions;
