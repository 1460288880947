// Dependencies
import React from 'react';
import GoogleMapReact from 'google-map-react';

// Utils
import { getShowcaseImage } from '../../utils/producers';

const ProducerMapList = (props) => {
    const { list } = props;

    const getInfoWindowString = producer => `
    <div>
      <div style="font-size: 16px;">
        <a href="/producers/${producer.id}/show" target="_blank">
          ${producer.name} 
        </a>
      </div>
      <img src=${getShowcaseImage(producer)} />
    </div>`;

    // Refer to https://github.com/google-map-react/google-map-react#use-google-maps-api
    const handleApiLoaded = (map, maps, producers) => {
        const markers = [];
        const infowindows = [];

        producers.forEach(producer => {
            markers.push(
                new maps.Marker({
                    position: {
                        lat: producer.latitude, //place.geometry.location.lat,
                        lng: producer.longitude, //place.geometry.location.lng,
                    },
                    map,
                }),
            );

            infowindows.push(
                new maps.InfoWindow({
                    content: getInfoWindowString(producer),
                }),
            );
        });

        markers.forEach((marker, i) => {
            marker.addListener('click', () => {
                infowindows[i].open(map, marker);
            });
        });
    };

    return (<div
            style={{
                position: 'relative',
                height: '600px',
            }}
        >
            {list && <GoogleMapReact
                style={{
                    height: '100%',
                    width: '100%',
                }}
                defaultZoom={7}
                defaultCenter={[
                    59.354624,
                    17.677911,
                ]}
                bootstrapURLKeys={{
                    key:
                    process.env
                        .REACT_APP_GOOGLE_API_KEY,
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(
                        map,
                        maps,
                        list,
                    )
                }
            />}
        </div>
    );
};

export default ProducerMapList;
