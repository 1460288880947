import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import ListDropDown from "components/inputfields/ListDropDown";
import ThreeWaySwitch from "components/inputfields/ThreeWaySwitch";

import {
  fishProductionMethod,
  fishCatchMethod,
  catchArea
} from '../../../constants-ts';

import IProduct from "models/product";

interface IFishDataProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
};

const FishFields = ({ approve, state, setState}: IFishDataProps) => {
  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
      const val = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: val });
  };

  return (
    <div style={{width: '100%'}}>
      <InputLabel
        heading="Produktionsmetod"
        text="Företag som säljer fiskerivaror i detaljhandeln måste ge konsumenten skriftlig information om handelsbeteckning, produktionsform , fångstområde/ursprungsland och redskapskategori för fångade och odlade arter."
        link="https://www.livsmedelsverket.se/om-oss/lagstiftning1/gallande-lagstiftning/eu-forordning-13792013"
      />
      <div style={{ display: 'flex', width: '100%' }}>
        <ListDropDown
          disableLabel={true}
          listData={fishProductionMethod}
          value={state?.fishProductionMethod}
          targetName="fishProductionMethod"
          handleChange={handleDropdownChange}
          displayEmpty={true}
          style={{ flexGrow: 1, overflow: 'hidden' }}
        />
        {approve && state?.approve?.find(x => x.field === 'fishProductionMethod') && (
          <ThreeWaySwitch
            state={state}
            style={{ height: 56 }}
            setState={setState}
            name="fishProductionMethod"
          />
        )}
      </div>
      <InputLabel
        heading="Fiskemetod"
        text="Välj produktionsmetod och/eller redskapskategori som används för fångad/fiskad eller odlad fisk."
        link="https://www.livsmedelsverket.se/foretagande-regler-kontroll/regler-for-livsmedelsforetag/information-markning-och-pastaenden/tillaggsmarkning-av-fiskprodukter"
      />
      <div style={{ display: 'flex', flex: 1 }}>
        <ListDropDown
          disableLabel={true}
          listData={fishCatchMethod}
          value={state?.fishCatchMethod}
          targetName="fishCatchMethod"
          handleChange={handleDropdownChange}
          displayEmpty={true}
          style={{ flexGrow: 1, overflow: 'hidden' }}
        />
        {approve && state?.approve?.find(x => x.field === 'fishCatchMethod') && (
          <ThreeWaySwitch
            state={state}
            style={{ height: 56 }}
            setState={setState}
            name="fishCatchMethod"
          />
        )}
      </div>
      <InputLabel
        heading="Fångstområde"
        text="För havsfångad fisk, tång och alger är det obligatoriskt att ange platsen för fångsten/skörden med användandet av det delområde som finns i FAO:s förteckning av fiskeområden."
        link="https://www.livsmedelsverket.se/foretagande-regler-kontroll/regler-for-livsmedelsforetag/information-markning-och-pastaenden/tillaggsmarkning-av-fiskprodukter"
      />
      <div style={{ display: 'flex', flex: 1 }}>
        <ListDropDown
          disableLabel={true}
          listData={catchArea}
          value={state?.catchArea}
          targetName="catchArea"
          handleChange={handleDropdownChange}
          displayEmpty={true}
          style={{ flexGrow: 1, overflow: 'hidden' }}
        />
        {approve && state?.approve?.find(x => x.field === 'catchArea') && (
          <ThreeWaySwitch
            state={state}
            style={{ height: 56 }}
            setState={setState}
            name="catchArea"
          />
        )}
      </div>
    </div>
  );
}

export default FishFields;
