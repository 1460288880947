import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { IFoodlaCategoryOption } from 'models/category';
import { ROOT_CATEGORY_ID } from 'components/constants-ts';

interface ICategoryData {
  id: string;
  name: string;
  parentCategoryId?: string;
  includeFields?: string[];
  excludeFields?: string[];
}

export const GET_ALL_CATEGORIES = gql`
  query getAllCategories {
    allCategories {
      id
      name
      parentCategoryId
    }
  }
`;

const getChildrens = (category: ICategoryData, categories: ICategoryData[]): IFoodlaCategoryOption[] => {
  const children = categories.filter(({ parentCategoryId }) => parentCategoryId === category.id);
  if (!children.length) return [];
  return categories
    .filter(({ parentCategoryId }) => parentCategoryId === category.id)
    // remove [parentCategoryId] field
    .map(({ parentCategoryId, ...category }) => ({ ...category, children: getChildrens(category, categories) }));
};

function useCategoriesData() {
  const { data, loading, error } = useQuery<{ allCategories: ICategoryData[] }>(GET_ALL_CATEGORIES);

  const categories = useMemo(() => {
    if (loading || error) return [];
    return data?.allCategories || [];
  }, [data, loading, error]);

  const rootCategory = useMemo<IFoodlaCategoryOption | undefined>(() => {
    if (!categories.length) return undefined;

    const rootData = categories.find(({ id }) => id === ROOT_CATEGORY_ID);
    if (!rootData) return undefined;
    // remove [parentCategoryId] field
    const { parentCategoryId, ...root } = rootData;

    return { ...root, children: getChildrens(root, categories) };
  }, [categories]);

  return { categories, rootCategory, loading, error: error?.message };
}

export default useCategoriesData;
