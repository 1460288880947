import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import { DateRangePicker, DateRange, DateRangeDelimiter, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import svLocale from "date-fns/locale/sv";

import { BasicTitle } from "components/typography/Title";
import ErrorBox from 'components/Error';
import IProduct from "models/product";
import PagePagination from "components/Pagination";
import { addOrChangeQueryParam, extractURLParam, pushToWindowHistory } from "utils/urlUtils";
import { isDefined, toSafeNumber } from "utils/helpers-ts";

import HistoryItem from "./item";
import ExportHistoryDialog from "./dialog";
import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import endOfDay from "date-fns/endOfDay";
import { startOfDay } from "date-fns/esm";
import useViewport from "hooks/useViewports";

const INIT_PAGE_PARAM = 1;
const INIT_LIMIT_PARAM = 12;

const EXPORT_HISTORY = gql`
  query apiExportHistory($textQuery: String, $pagePagination: PagePagination, $showOnlyWithHistory: Boolean, $start: Date, $end: Date) {
    apiExportHistory(textQuery: $textQuery, pagePagination: $pagePagination, showOnlyWithHistory: $showOnlyWithHistory, start: $start, end: $end) {
      count
      edges {
        id
        title
        pictureLinkName
        name
        apiExportAt
        apoteaExportAt
        EAN
        EAN2
        image_src
        producer {
          name
          status
        }
        foodlaCategory {
          id
          name
        }
        brand_food
        brand
        versions {
          type
          createdAt
          data {
            id
            EAN
            title
            productDescription
            createdAt
            updatedAt
            manufacturer {
              gln
              location
              latitude
              longitude
            }
            ingredientStatement
            placeOfOrigin
            # countryOfOrigin
            countryOfManufacturing
            # countryOfManufacturingString
            additionalRequiredInformation
            baseCategory
            foodlaCategory {
              id
              name
            }
            alcoholPercentage
            ageLimit
            classification
            orderKfpDfp
            enabled
            minTemperature
            maxTemperature
            tradeItemTemperatureInformation
            netQuantityAmount
            netQuantityUnit
            netQuantityAmountExtra
            nutrientsInformation {
              basisQuantity
              basisUnit
              nutrients {
                type
                unit
                value
                precision
                DRI
              }
            }
            brand
            color
            material
            capacity
            nonFoodIngredients
            packaging {
              grossWeight
              grossHeight
              grossWidth
              grossDepth
              packageSize
              packageSizeUnit
              consumerDimensionsAdditional
            }
            searchTerms
            ItemCategoriesOnline
            ItemSymbolsConsumerInfo
            ItemInternalInfoOnline
            ItemWeightPerPiece
            ItemWeightPerPieceUnit
            ItemValidooPackNetContentUnit
            ItemValidooPackageSize
            ItemRequiresAppendix
            ItemValidooWeightVolume
            ItemTextConsumerInfo
            ItemSuppliersReportingCategories
            media {
              articleImages
              planogramImages
            }
            price
            priceEAN
            issueActiveDate
            lastreturndate
            totalNumberIssues
            currentIssueNumber
            currentIssueYear
            isMagazine
            alternativeTitle
          }
        }
      }
    }
  }
`;

type TExportHistoryItem = {
  count?: number;
  edges?: IProduct[];
}

type TExportHistoryRoot = {
  apiExportHistory?: TExportHistoryItem;
};

interface IExportHistoryProps {
  classes: any;
}

const styles = (theme: any) => ({
  toolbarBox: {
      flexGrow: 1,
  },
  title: {
      flexGrow: 1,
  },
});

export const ExportHistory = ({classes}: IExportHistoryProps) => {
  const { mdUp } = useViewport();
  const [showOnlyWithHistory, setShowOnlyWithHistory] = useState(false);
  // const [limit, setLimit] = useState(INIT_LIMIT_PARAM);
  // const [currentPage, setCurrentPage] = useState(1);
  const [textQuery, setTextQuery] = useState<string|undefined>(undefined);
  const [limit, setLimit] = useState(INIT_LIMIT_PARAM);

  const [currentPage, setCurrentPage] = useState(INIT_PAGE_PARAM);
  const [range, setRange] = useState<DateRange<Date>>([null, null]);

  // let limitInitStr = extractURLParam('limit', (window as any).location);
  // let limitInit: number|undefined = INIT_LIMIT_PARAM;
  // const lsLimit = localStorage.getItem('export-history-limit');
  // if (isDefined(limitInit)) {
  //     limitInit = toSafeNumber(limitInitStr, undefined);
  // }
  // if (!isDefined(limitInit) && isDefined(lsLimit)) {
  //     limitInit = toSafeNumber(lsLimit, undefined);
  // }
  // if (!isDefined(limitInit)) {
  //     limitInit = INIT_LIMIT_PARAM;
  // }
  // // limitInit = toSafeNumber(limitInit, INIT_LIMIT_PARAM);
  // const [limit, setLimit] = useState(limitInit);

  const [start, end] = range;
  const { data, error, refetch, networkStatus, loading } = useQuery<TExportHistoryRoot>(EXPORT_HISTORY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      showOnlyWithHistory,
      textQuery,
      pagePagination: {
        limit,
        page: currentPage,
      },
      start: start && startOfDay(start),
      end: end && endOfDay(end),
    },
  });
  useEffect(() => {
    const limitParam = extractURLParam('limit', (window as any).location);
    const limitParamNum = toSafeNumber(limitParam, INIT_LIMIT_PARAM) || INIT_LIMIT_PARAM;
    setLimit(limitParamNum);

    const pageParam = extractURLParam('page', (window as any).location);
    const pageParamNum = toSafeNumber(pageParam, INIT_PAGE_PARAM) || INIT_PAGE_PARAM;
    const numberOfPages = data?.apiExportHistory?.count ? Math.ceil(data?.apiExportHistory?.count / limit) : INIT_PAGE_PARAM;
    const startPageCount = pageParamNum > numberOfPages ? numberOfPages : pageParamNum;
    setCurrentPage(startPageCount);
  }, []);

  useEffect(() => {
    if (refetch) {
      // TODO add checking max pages
      // let page = currentPage;
      // if (data?.apiExportHistory?.count) {
      //   const maxPages = Math.ceil(data?.apiExportHistory?.count / limit);
      //   if (page > maxPages) {
      //     page = maxPages;
      //   }
      // } else {
      //   page = INIT_PAGE_PARAM;
      // }
      // setCurrentPage(page);
      refetch({
        showOnlyWithHistory,
        textQuery,
        pagePagination: {
          limit,
          page: currentPage,
        },
        start: start && startOfDay(start),
        end: end && endOfDay(end),
      });
    }
  }, [showOnlyWithHistory, limit, currentPage, textQuery, range]);



  const [currentProduct, setCurrentProduct] = useState<IProduct|undefined>(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setCurrentProduct(undefined);
    }
  }, [open]);

  // When user paginates.
  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);

    const newURL = addOrChangeQueryParam('page', `${page}`, window.location);
    pushToWindowHistory(newURL, window, 'ProductPaginationPage');

      // const qVariables = {
      //     ...queryVariables,
      //     textQuery: query,
      //     ...(showPagination || showSorting ? {
      //         pagePagination: {
      //             ...(showPagination ? {limit, page} : {}),
      //             ...(showSorting ? {
      //                 sorting,
      //                 sortOrder,
      //                 longitude: coordinates.longitude,
      //                 latitude: coordinates.latitude,
      //             } : {}),
      //         },
      //     } : {}),
      // };

      // refetch(qVariables);
  };

      // When user changes the limit of products/page.
      const onLimitChanged = (changeToLimit: number) => {
        setLimit(changeToLimit);
        localStorage.setItem('export-history-limit', `${changeToLimit}`);

        const newURL = addOrChangeQueryParam('limit', `${changeToLimit}`, window.location);
        pushToWindowHistory(newURL, window, 'ProductPaginationLimit');

        const newPageCount = data?.apiExportHistory?.count ? Math.ceil(data?.apiExportHistory?.count/changeToLimit) : INIT_PAGE_PARAM;

        if (currentPage > newPageCount) {
            setCurrentPage(newPageCount);
        }
    };
  const [dateError, setDateError] = useState([null, null]);

  return (
    <Paper>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.toolbarBox}>
          <Typography variant="body1" className={classes.title}>
            <BasicTitle>
              Export history ({data?.apiExportHistory?.count || 0})
            </BasicTitle>
          </Typography>
        </Box>
      </Toolbar>
      <Grid container={true} style={{padding: 10}}>
        <Grid item={true} xs={4}>
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyWithHistory}
                onChange={() => setShowOnlyWithHistory(!showOnlyWithHistory)}
                name="showOnlyWithHistory"
                color="primary"
              />
            }
            label={!showOnlyWithHistory ? 'Show only with export history' : 'Show without export history'}
          />
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={6}>
          <TextField
            placeholder="Search by EAN or Product Name or Producer Name"
            variant="outlined"
            value={textQuery}
            style={{ margin: 10, width: 500 }}
            onChange={(e) => {
              setTextQuery(e.target.value);
            }}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <LocalizationProvider dateAdapter={DateFnsUtils} locale={svLocale}>
            <DateRangePicker
              startText="Start"
              endText="End"
              onError={(reason: any[], [start, end]: [any, any]) => {
                if (reason[0] === 'invalidRange' && isSameDay(start, end)) {
                  setDateError([null, null]);
                  return;
                }
           
                setDateError(reason);
              }}
              value={range}
              clearable={true}
              disableFuture={true}
              onChange={(newValue) => setRange(newValue)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} error={Boolean(dateError[0])} />
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField {...endProps}  error={Boolean(dateError[1])} />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>      
      {/* TODO any */}
      {(error || networkStatus === 8) && (
        <ErrorBox message={`${error ? (error as any).message : 'Network error'}`} />
      )}
      {!error && loading && networkStatus !== 8 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <CircularProgress size={70} variant="indeterminate" />
        </div>
      )}
      {!error && !loading && networkStatus && (
        <>    
          <Card>
            <PagePagination
              page={currentPage}
              onPageChange={onPageChange}
              count={data?.apiExportHistory?.count || 0}
              limit={limit || INIT_LIMIT_PARAM}
              disablePagination={!mdUp}
              onLimitChanged={onLimitChanged}
              // disablePagination={!showPagination}
              // showSortingOptions={showSorting}
              // onSortChanged={onSortChanged}
              // sorting={sorting}
              // sortOrder={sortOrder}
            />
          </Card>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>EAN</TableCell>
                <TableCell>Last export</TableCell>
                <TableCell>History</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.apiExportHistory?.edges?.map((p) => <HistoryItem textQuery={textQuery} product={p} setCurrentProduct={setCurrentProduct} setOpen={setOpen} />)}
            </TableBody>
          </Table>
          <Card>
            <PagePagination
              page={currentPage}
              onPageChange={onPageChange}
              count={data?.apiExportHistory?.count || 0}
              limit={limit || INIT_LIMIT_PARAM}
              onLimitChanged={onLimitChanged}
              // disablePagination={!showPagination}
              // showSortingOptions={showSorting}
              // onSortChanged={onSortChanged}
              // sorting={sorting}
              // sortOrder={sortOrder}
            />
          </Card>
          <ExportHistoryDialog product={currentProduct} open={open} onClose={() => {setOpen(false);}} />
        </>
      )}
    </Paper>
  );
};

export default withStyles(styles)(ExportHistory);
