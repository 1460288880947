import Button from "@material-ui/core/Button";
import styled from "styled-components";

export const AutogeneratedStyle = styled.p`
  text-align: center;
  font-size: 14px;
  margin: 2px;
`;

export const HiddenAutogeneratedStyle = styled(AutogeneratedStyle)`
  visibility: hidden;
`;

export const MainImageStyle = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: bolder;
  margin: 2px;
`;

interface IListStyleProps {
  isDraggingOver?: boolean;
}

export const ListStyle = styled.div<IListStyleProps>`
  margin-top: 20px;
  margin-bottom: 20px;
  background: ${(props) => (props.isDraggingOver ? 'rgba(173, 213, 255, 0.5)' : 'lightgrey')};
  border-radius: 3px;
  display: flex;
  padding: grid;
  overflow: auto;
`;

export const StyledButton = styled(Button)
  .attrs({
    variant: 'contained',
    color: 'default',
  })`
  margin-top: 5px;
  width: 100%;
`;
