import { useEffect, useLayoutEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fixedHeaderWrapper: {
    display: 'block',
    '& thead': {
      height: 0,
      top: 0,
      borderLeft: '1px solid #D7DAE4',
    },
  },
  fixedWrapper: {
    position: 'fixed',
    top: 0,
    zIndex: 20,
    width: '100%',
    overflowX: 'auto',
  },
  fixedHeadContent: {
    display: 'flex',
    overflowX: 'scroll',
    zIndex: 10,
    '& td': {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 0,
      flexShrink: 0,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

interface UseFixedTableHeaderProps {
  tableWrapper?: HTMLDivElement | null;
  top?: number;
}

const useFixedTableHeader = ({ tableWrapper, top = 0 }: UseFixedTableHeaderProps) => {
  const classes = useStyles();

  const [fixedRowElement, setFixedRowElement] = useState<HTMLTableRowElement | null>(null);
  const [fixedWrapper, setFixedWrapper] = useState<HTMLElement | null>(null);

  // generate fixed table, table head
  useLayoutEffect(() => {
    if (!tableWrapper || !tableWrapper.parentNode) return;
    const fixedTableElement = document.createElement('table');
    const fixedTableHeaderElement = document.createElement('thead');

    fixedTableElement.classList.add(classes.fixedHeaderWrapper);
    fixedTableHeaderElement.style.top = `${top}px`;
    fixedTableElement.appendChild(fixedTableHeaderElement);
    tableWrapper.parentNode.appendChild(fixedTableElement);

    setFixedWrapper(fixedTableHeaderElement);
    return () => {
      tableWrapper.parentNode?.removeChild(fixedTableElement);
    };
  }, [tableWrapper, classes, top]);

  // handle scroll
  useEffect(() => {
    if (!fixedRowElement || !tableWrapper) return;
    // add class to fixedRowElement
    if (!fixedRowElement.classList.contains(classes.fixedHeadContent)) {
      fixedRowElement.classList.add(classes.fixedHeadContent);
    }

    const handleTableScroll = () => {
      if (tableWrapper.scrollLeft !== fixedRowElement.scrollLeft) {
        fixedRowElement.scrollTo({ left: tableWrapper.scrollLeft });
      }
    };
    const handleFixedRowScroll = () => {
      if (tableWrapper.scrollLeft !== fixedRowElement.scrollLeft) {
        tableWrapper.scrollTo({ left: fixedRowElement.scrollLeft });
      }
    };

    tableWrapper.addEventListener('scroll', handleTableScroll);
    fixedRowElement.addEventListener('scroll', handleFixedRowScroll);
    return () => {
      tableWrapper.removeEventListener('scroll', handleTableScroll);
      fixedRowElement.removeEventListener('scroll', handleFixedRowScroll);
    };
  }, [classes, fixedRowElement, tableWrapper]);

  // update fixedWrapper's width
  useEffect(() => {
    if (!fixedWrapper || !tableWrapper) return;
    if (!fixedWrapper.classList.contains(classes.fixedWrapper)) {
      fixedWrapper.classList.add(classes.fixedWrapper);
    }
    const observer = new ResizeObserver(([entry]) => {
      fixedWrapper.style.width = `${entry.contentRect.width}px`;
    });

    observer.observe(tableWrapper);
    return () => observer.disconnect();
  }, [classes, fixedWrapper, tableWrapper]);

  // watch start element
  // hide "Virtual scroll bar" when start element is visible in the screen
  // show "Virtual scroll bar" when start element is NOT visible in the screen
  useEffect(() => {
    if (!fixedWrapper || !tableWrapper || !tableWrapper.parentNode) return;
    const startElement = document.createElement('div');
    // insert [startElement] right after [targetElement]
    tableWrapper.parentNode.insertBefore(startElement, tableWrapper);

    const observer = new IntersectionObserver(
      ([entry]) => {
        const { intersectionRatio, boundingClientRect } = entry || {};
        if (intersectionRatio === 1 || boundingClientRect.bottom > top) {
          fixedWrapper.style.height = '0';
        } else {
          fixedWrapper.style.height = 'auto';
        }
      },
      { threshold: [0, 1], rootMargin: `-${top}px` }
    );

    observer.observe(startElement);
    return () => {
      observer.disconnect();
      tableWrapper.parentNode?.removeChild(startElement);
    };
  }, [top, fixedWrapper, tableWrapper]);

  return { fixedWrapper, setFixedRowElement };
};

export default useFixedTableHeader;
