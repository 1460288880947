import InputTextField from "components/inputfields/InputTextField";
import IProducer from "models/producer";

interface IWhatToDoProps {
  state?: IProducer;
  setState: (state: IProducer) => void;
}

const WhatToDo = ({ state, setState }: IWhatToDoProps) => {
  const handleChange = ({ target }: any) => setState({ ...state, whattodo: target.value });

  return (
    <InputTextField<IProducer>
      inputName="whattodo"
      // label="Description"
      value={state?.whattodo}
      onChange={handleChange}
      // required
      multiline
      multilineRows={4}
      multilineMax={10}
      withLabel={true}
      labelOptions={{
      heading: 'Vad är viktigt för er i det ni gör? Har ni några värdeord eller löften till konsumenter som köper era produkter?',
      //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
      }}
    />
  );
};

export default WhatToDo;
