import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { Mutation } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import { TAG_PRODUCT, MUTATION_ACCEPT_PRODUCT_UPDATE } from '../../graphql/mutations';
import {
    QUERY_PRODUCER_PRODUCTS,
    QUERY_PRODUCT_SUMMARY,
    PRODUCT_CARD_LIST_QUERY,
    PRODUCT_APPROVAL_SUMMARY,
    GET_PRODUCT_BY_ID,
    ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY,
} from '../../graphql/queries';

import CircularProgress from '@material-ui/core/CircularProgress';
import { concat } from 'ramda';

const useStyles = makeStyles(theme => ({
    buttonText: {
        fontSize: 12,
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonBox: {
        marginLeft: theme.spacing(1),
    },
}));

interface IApproveButtonProps {
    productState: string;
    productId: string;
    producerId: string;
    size: any;
    usedByProducerPage: boolean;
    updatedProduct: any;
    refetch?: any;
    summaryRefetch: any;
    setDeleted: any;
}

const ApproveButton = ({
   productState,
   productId,
   producerId,
   size,
   usedByProducerPage,
   updatedProduct,
   // refetch,
   summaryRefetch,
   setDeleted,
}: IApproveButtonProps) => {
    const classes = useStyles();

    const [sendAcceptProductUpdate] = useMutation(MUTATION_ACCEPT_PRODUCT_UPDATE, {
        refetchQueries: refetchQueries(),
    });

    const acceptUpdate = () => {
        sendAcceptProductUpdate({
            variables: {
                productId,
            },
        });
    };

    function approve(tagProduct: any) {
        // If product is marked with update, we also want to accept update once we approve product.
        if (updatedProduct) acceptUpdate();
        tagProduct({
            variables: {
                input: {
                    state: 'APPROVED',
                    productId: productId,
                    producerId: producerId,
                },
            },
        });
    }

    function disapprove(tagProduct: any) {
        if (updatedProduct) acceptUpdate();
        tagProduct({
            variables: {
                input: {
                    state: 'DISAPPROVED',
                    productId: productId,
                    producerId: producerId,
                },
            },
        });
    }

    function refetchQueries() {
        // We need to fetch all product states.

        let queries: any = [
            {
                query: ORGANIZATION_PRODUCT_UPDATE_NOTIFICATIONS_QUERY,
            },
            {
                query: GET_PRODUCT_BY_ID,
                variables: { id: productId },
            },
            {
                query: PRODUCT_APPROVAL_SUMMARY,
            },
        ];

        if (usedByProducerPage) {
            const additionalQueries = [
                {
                    query: QUERY_PRODUCT_SUMMARY,
                    variables: { id: producerId },
                },
                {
                    query: QUERY_PRODUCER_PRODUCTS,
                    variables: {
                        id: producerId,
                        filter: { tagState: 'PENDING' },
                    },
                },
                {
                    query: QUERY_PRODUCER_PRODUCTS,
                    variables: {
                        id: producerId,
                        filter: { tagState: 'APPROVED' },
                    },
                },
                {
                    query: QUERY_PRODUCER_PRODUCTS,
                    variables: {
                        id: producerId,
                        filter: { tagState: 'DISAPPROVED' },
                    },
                },
            ];

            queries = concat(queries, additionalQueries);
        } else {
            const additionalQueries = [
                {
                    query: PRODUCT_CARD_LIST_QUERY,
                    variables: { filter: { tagState: 'PENDING' } },
                },
                {
                    query: PRODUCT_CARD_LIST_QUERY,
                    variables: { filter: { tagState: 'APPROVED' } },
                },
                {
                    query: PRODUCT_CARD_LIST_QUERY,
                    variables: { filter: { tagState: 'DISAPPROVED' } },
                },
            ];

            queries = concat(queries, additionalQueries);
        }

        return queries;
    }

    const updateButton = updatedProduct ? (
        <Button variant="outlined" size={size} color="primary" className={classes.button} onClick={acceptUpdate}>
            <span className={classes.buttonText}>Accept update</span>
        </Button>
    ) : (
        <></>
    );

    const refetchAction = () => {
        if (setDeleted && summaryRefetch) {
            setDeleted(true);
            summaryRefetch();
        }
    }
    return (
        <Mutation
            mutation={TAG_PRODUCT}
            // @ts-ignore
            refetchQueries={() => refetchAction()}
            awaitRefetchQueries
        >
            {(tagProduct, { data, loading, error }) => {
                if (loading) return <CircularProgress />;
                if (error) return <p>Error :(</p>;

                if (productState === 'DISAPPROVED')
                    return (
                        <Box>
                            <Button
                                variant="outlined"
                                size={size}
                                color="primary"
                                className={classes.button}
                                onClick={() => approve(tagProduct)}
                            >
                                <span className={classes.buttonText}>Approve</span>
                            </Button>
                        </Box>
                    );
                else if (productState === 'APPROVED')
                    return (
                        <Box>
                            <Button
                                variant="outlined"
                                size={size}
                                color="secondary"
                                className={classes.button}
                                onClick={() => disapprove(tagProduct)}
                            >
                                <span className={classes.buttonText}>Disapprove</span>
                            </Button>
                            {updateButton}
                        </Box>
                    );
                else
                    return (
                        <Box>
                            <Button variant="outlined" size={size} color="primary" onClick={() => approve(tagProduct)}>
                                <span className={classes.buttonText}>Approve</span>
                            </Button>
                            <Button
                                className={classes.buttonBox}
                                variant="outlined"
                                size={size}
                                color="secondary"
                                onClick={() => disapprove(tagProduct)}
                            >
                                <span className={classes.buttonText}>Disapprove</span>
                            </Button>
                        </Box>
                    );
            }}
        </Mutation>
    );
};

export default ApproveButton;
