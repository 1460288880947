import React, { FC } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import emptyImage from './product-cart-list-empty.png';
import useViewport from 'hooks/useViewports';

const ProductCartListEmpty: FC = () => {
  const { customUp } = useViewport(480);
  const match = useRouteMatch();
  const itsMe = match?.url?.startsWith('/me/products');
  const addProductUrl = itsMe ? '/me/products/addproduct' : '/products/addproduct';

  return (
    <Box display="flex" justifyContent="center" position="relative" style={{ height: customUp ? 350 : 'auto' }}>
      <img src={emptyImage} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      <Box position="absolute" bottom={40} display="flex" justifyContent="center" width="100%">
        <Button
          component={RouterLink}
          to={addProductUrl}
          variant="contained"
          size="medium"
          color="primary"
          startIcon={<AddIcon />}
        >
          Lägg till product
        </Button>
      </Box>
    </Box>
  );
};

export default ProductCartListEmpty;
