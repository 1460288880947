import React, { useContext } from 'react';
import {useQuery} from '@apollo/react-hooks';
import { path } from 'ramda';

import ExcelExporter from '../../components/export/ExcelExporter';
import Paper from '@material-ui/core/Paper';
import ProductCardList from '../../components/product/ProductCardList/ProductCardList';

import { isDefined } from '../../utils/helpers';
import { extractURLParam } from '../../utils/urlUtils';
import { PRODUCT_TABS, restEndpoints } from '../../constants/domain';

import {
    NEW_PRODUCTS_FOR_EXPORT,
    ORGANIZATION_ALL_PRODUCTS_UPDATE_NOTIFICATIONS_QUERY,
    EXPORT_SUMMARY,
} from '../../graphql/queries';
import {ERemoveProductHandlerKey} from "../../components/product/ProductCard/types";
import { KeycloakContext } from '../../components/Secured';


const ExportProducts = ({ match }) => {
    // const category = PRODUCT_TABS.APPROVED;
    // const isNonFood = extractURLParam('nonfood', window.location) === "1";
    const eans = extractURLParam('eans', window.location);
    const username = extractURLParam('username', window.location);
    // const onlyExcel = extractURLParam('onlyExcel', window.location) === "1";
    const includeAll = extractURLParam('includeAll', window.location) === "1";

    // Get Product Summary Info(Tab Info)
    // const { data: summaryData } = useQuery(EXPORT_SUMMARY, {
    //   fetchPolicy: 'cache-and-network',
    // });

    // const { data: notificationsData } = useQuery(ORGANIZATION_ALL_PRODUCTS_UPDATE_NOTIFICATIONS_QUERY, {
    //   fetchPolicy: 'cache-and-network',
    // });

    // const queryVariables = { oneType: true, nonFood: isNonFood };

    // const notificationResponse = path(['allProductsUpdateNotifications'], notificationsData);
    // const productNotifications = isDefined(notificationResponse) ? notificationResponse : [];

    const keycloakCtx = useContext(KeycloakContext);
    const eansQs = (eans && eans.trim() !== '') ? `&eans=${eans}`: '';
    const usernameQs = (username && username.trim() !== '') ? `&username=${username}` : '';
    const onlyExcelQs = '&onlyExcel=1';
    // // const onlyExcelQs = onlyExcel ? '&onlyExcel=1' : '';
    const includeDisapprovedQs = includeAll ? '&includeAll=1' : '';
    const endpoint = `${restEndpoints.excelDownload}?${eansQs}${onlyExcelQs}${usernameQs}${includeDisapprovedQs}`;
    const [url, setUrl] = React.useState('');
    const downloadExcel = async () => {
        const token = path(['keycloak', 'token'], keycloakCtx);

        fetch(endpoint, {
            method: 'POST',
            headers: {
            Authorization: `Bearer ${token}`,
            },
        })
        .then(r => r.blob())
        .then(b => {
            const url = window.URL.createObjectURL(new Blob([b]));
            // const dLink = document.createElement('a');
            // dLink.href = url;
            setUrl(url);
            // dLink.setAttribute('download', `produkter.xlsx`);
            // document.body.appendChild(dLink);
            // dLink.click();
            // dLink.parentNode?.removeChild(dLink);
        })
        // .catch(e => setExportError(() => [true, e]))
        // .finally(() => setIsDownloading(() => false));
    };
    React.useEffect(() => {
      downloadExcel();
    }, []);
    return (
      <Paper>
        {/* <p>test</p> */}
        <a href={url} download="produkter.xlsx">Download Excel</a>
      </Paper>
    );
  };

export default ExportProducts;
