/* eslint-disable no-empty-pattern */

// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLazyQuery } from '@apollo/react-hooks';
import { PRODUCT_SEARCH_WITH_PRODUCER } from '../graphql/queries'
// import { useDebouncedCallback } from "use-debounce";
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import { transformCategoryRead } from 'utils/products';

export default function Asynchronous(props) {
  // Create abort controller
  // const controller = new window.AbortController();

  // const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  const [query, setQuery] = React.useState("");
  //const loading = open && options.length === 0;
  // const [loading, setLoading] = React.useState(false);
  // const [suggestions, setSuggestions] = React.useState([])
  const [value, setValue] = React.useState('')
  const [opts, setOpts] = React.useState([]);

  const { tryToAddItemToOrder, selectedItems } = props;
  const style = props.style || {};

  // React.useEffect(() => {
  //   if (!loading) {
  //     return undefined;
  //   }
  //   // (async () => {
  //   //   if (active) {
  //   //     // setOptions(Object.keys(countries).map(key => countries[key].item[0]));
  //   //     // setOptions([{title: "AB", EAN: 112344}, {title: "ABC"}]);
  //   //   }
  //   // })();
  //   return () => {};
  // }, [loading]);

  // React.useEffect(() => {
  //   if (!open) {
  //     // Abort query
  //     controller.abort();

  //     setOpts([]);
  //     setOptions([]);
  //   }
  // }, [open]);

  // Sort data by group. To fix autocomplete bug when groups not sorted
  // TODO refactor
  // const sortOptions = (options) => {
  //   const items = [];
  //   options.forEach((option) => {
  //     const item = items.find(x => x.producer.id === option.producer.id);
  //     if (!item) {
  //       const result = options.filter((x) => x.producer.id === option.producer.id);
  //       if (result && result.length > 0) {
  //         items.push(...result);
  //       }
  //     }
  //   })
  //   return items;
  // };

  // Rickard working on
  const [
      getProductByEANorSearchByTextOrByProducer,
      {
        loading,
        // data,
          // loading: findingProduct,
          // options: data,
      },
  ] = useLazyQuery(PRODUCT_SEARCH_WITH_PRODUCER, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: {
        string: value,
      },
      onCompleted: productsData => {
          console.log("productsData", productsData)
          let { data } = productsData.productByEANorSearchByTextOrByProducer;
          // data = sortOptions(data);
          // setOptions(data);
          transformCategoryRead(data);
          setOpts(data);
          // console.log("options", options);
          // setLoading(false);
      },
  });

  // React.useEffect(() => {
  //   setSuggestions(data?.productByEANorSearchByTextOrByProducer?.data || [])
  // }, [value]);

  React.useEffect(() => {
    console.log("Query changed", query);
    if (!query || query.trim() === "") {
      setOpts([]);
      // controller.abort()
    } else if (loading) {
      // controller.abort();
    }
    if (query && query.trim() !== "") {
      getProductByEANorSearchByTextOrByProducer({
        variables: {
          string: query,
        },
        // context: {
        //   fetchOptions: {
        //     signal: controller.signal
        //   }
        // },
      });
    }
  }, [query]);


  // React.useEffect(() => {
  //   if (!loading) {
  //     setSuggestions(data?.productByEANorSearchByTextOrByProducer?.data || [])
  //   }
  // }, [data, loading]);

  const onChange = (event, value) => {
    console.log("change", event, value)
    // const value = values && Array.isArray(values) && values.pop();
    if (value && value.EAN) {
      console.log("EAN", value.EAN)
      tryToAddItemToOrder(value);
      // setOpen(true);
    }
  };

  const renderResultItem = (option) => {
    // const primaryText = (
    //   <>
    //     {option?.title}, EAN: {option?.EAN}, 
    //     <span style={{color: "grey"}}>{option?.producer?.name}</span>
    //   </>
    // );
    let selected = false;
    if (option && selectedItems) {
      selected = selectedItems.includes(option.id);
    }
    return (
      // <ListItem>
      //   <ListItemAvatar>
      //     <Avatar src={option?.pictures?.small?.url} />
      //   </ListItemAvatar>
      //   <ListItemText
      //     primary={primaryText}
      //   />
      // </ListItem>
      <div style={{display: "flex", flexDirection: "col", alignItems: "center", marginBottom: 5}}>
        <CheckIcon style={{marginRight: 3, color: green[500], visibility: selected ? undefined : 'hidden'}} />
        <img style={{marginRight: 3}}
            alt="complex"
            src={option.pictures.small.url}
        />
        <p>
        {option.title + ", EAN: " + option.EAN + ", "} <span style={{color: "grey"}}>{option.producer.name}</span>
        </p>
        {//<Button
        //     //onClick={() => review(data.cartItems)}
        //     variant="outlined"
        //     size="medium"
        //     color="primary"
        // >
        //     Click to add
        // </Button>
        }
      </div>
    );
  }

  // prevent call graphql in short time
  // const debounced = useDebouncedCallback(
  //   (value) => {
  //     // Abort query
  //     // controller.abort();
  //     console.log("onInputChange", value);
  //     if (value && value.length > 0) {
  //       // setLoading(true);
  //       getProductByEANorSearchByTextOrByProducer({
  //         variables: {
  //           string: value,
  //         },
  //         context: {
  //           fetchOptions: {
  //             signal: controller.signal
  //           }
  //         },
  //       });
  //     } else {
  //       setOptions([]);
  //     }
  //   }, 300
  // );

  return (
    <Autocomplete
      disableClearable={true}
      disableCloseOnSelect={true}
      disabledItemsFocusable={true}
      clearOnEscape={false}
      // limitTags={0}
      // multiple={true}
      // renderTags={(value, getTagProps) => (<></>)}
      id="asynchronous-demo"
      //style={{ width: 600 }}
      style={{...style, display: "flex"}}
      // open={open}
      // onOpen={(event) => {
      //   setOpen(true);
      // }}
      // onClose={() => {
      //   setOpen(false);
      // }}
      getOptionDisabled={(option) => selectedItems && option && selectedItems.includes(option.id)}
      noOptionsText="No results"
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.title}|${option.EAN}|${option.producer.name}`}
      options={opts}
      loading={loading}
      // freeSolo={true}
      // multiple={true}
      // onFocus={e => {}}
      // onInputChange={(e, value, reason) => setQuery(value)}
      onChange={onChange}
      inputValue={query}
      groupBy={option => (
        option.producer.name
      )}
      renderOption={(option, state) => (
        renderResultItem(option)
      )}
      renderInput={params => (
        <TextField
          {...params}
          onChange={(e) => setQuery(e.target.value)}
          label="Search by EAN or Product Name or Producer Name"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      />
  );
}
