import { useMemo, useRef, useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useBulkImportContext } from '../BulkImportContext';

import { PRODUCT_INFO_FOR_UPDATE_QUERY } from 'graphql/queries';
import { MUTATION_ADD_PRODUCT, MUTATION_SUPERUSER_APPROVE, MUTATION_UPDATE_PRODUCT_NOTES } from 'graphql/mutations';
import { IImageFileSource } from 'utils/excelUtils-old';
import { mapUploadProductList } from '../bulkImportUtils';
import { getProductMutationInput } from 'components/product/ProductUpdateForm/utils';
import IProduct from 'models/product';
import { ORGANIZATION_SPECIFIC_FIELDS } from 'components/constants';
import { NON_FOOD_CATEGORY_ID } from 'components/constants-ts';
import { isDefined } from 'utils/helpers';
import { reorderImages } from '../bulkImportUtils';
import { TUploadedProductStatus } from '../BulkImportContext/BulkImportContext';
import { transformCategoryRead } from 'utils/products';

interface UploadProductButtonProps {
  onUploaded: (params: { count: number }) => void;
}

function UploadProductButton({ onUploaded }: UploadProductButtonProps) {
  const apoloClient = useApolloClient();
  const [updateProductNotes] = useMutation(MUTATION_UPDATE_PRODUCT_NOTES);
  const [createProductMutation] = useMutation(MUTATION_ADD_PRODUCT);
  const [updateProductMutation] = useMutation(MUTATION_SUPERUSER_APPROVE);

  const {
    // Todo
    // tempApproveEanStatus,
    dataList,
    producerData,
    productType,
    uploadProgress,
    imageSettings,
    productImageDataSet,
    setUploadProgress,
    onUploadedProductStatusSetChange,
  } = useBulkImportContext();

  const keepRef = useRef({ uploadedProductCount: 0 });

  const [uploading, setUploading] = useState(false);

  const isAllRowHasEAN = useMemo(() => !!dataList.length && !dataList.some(({ EAN }) => !EAN), [dataList]);

  const savedProductList = useMemo(
    () => dataList.map(({ _savedData }) => _savedData).filter(_savedData => !!_savedData) as IProduct[],
    [dataList]
  );

  // Todo
  // const isApprovedAll = useMemo(
  //   () => savedProductList.every(({ EAN }) => tempApproveEanStatus[EAN || ''] === SUPERUSER_APPROVAL.ADMIN_APPROVED),
  //   [savedProductList, tempApproveEanStatus]
  // );

  // Todo
  // const isDisApprovedAll = useMemo(
  //   () => savedProductList.every(({ EAN }) => tempApproveEanStatus[EAN || ''] === SUPERUSER_APPROVAL.ADMIN_DISAPPROVED),
  //   [savedProductList, tempApproveEanStatus]
  // );

  const prepareDataToUpload = () => {
    const uploadingDataList = dataList.map(dataItem => {
      const { _excelData, ...uploadingData } = dataItem;

      return {
        ...uploadingData,
        title: dataItem.title || '',
        location: dataItem.location || '',
        short_text: dataItem.short_text || '',
      } as IProduct;
    });

    return mapUploadProductList(uploadingDataList, productType === NON_FOOD_CATEGORY_ID);
  };

  const getProduct = async (id?: string): Promise<IProduct | null> => {
    if (!id) return null;

    let uploadedProduct: IProduct | null = null;

    try {
      const response = await apoloClient.query<{ product: IProduct }>({
        query: PRODUCT_INFO_FOR_UPDATE_QUERY,
        variables: { id },
      });
      if (response?.data?.product) {
        uploadedProduct = response.data.product;
        transformCategoryRead(uploadedProduct);
      }
    } catch {
      uploadedProduct = null;
    }

    return uploadedProduct;
  };

  const uploadProduct = async (
    dataItem: IProduct,
    imageDataList: IImageFileSource[]
  ): Promise<TUploadedProductStatus> => {
    const result = { dataId: dataItem.id || '', productId: '', error: '' };

    let uploadingProduct: IProduct = { ...dataItem };
    const imageUrlList = imageDataList.map(imageData => imageData.url).filter(url => !!url);

    // get existed product
    const { id } = savedProductList.find(product => dataItem.EAN && product.EAN === dataItem.EAN) || {};
    // check product existed before create/update to prevent error
    // Case:
    // - Product was created
    // - User updating product
    // - Another user delete this product
    // - User click "Import product"
    const existedProductData = await getProduct(id);

    // ** generate variables **
    const mutationInput = getProductMutationInput(
      existedProductData,
      {
        ...uploadingProduct,
        producerUsername: producerData.username,
        producerName: producerData.name,
        producerEmail: producerData.email,
      },
      'superuser',
      imageUrlList,
      !existedProductData
    );
    const variables = { ...mutationInput.variables, producerUsername: producerData.username };

    // ** update product **
    if (existedProductData) {
      delete variables.producerUsername;
      try {
        await updateProductMutation({ variables });
        keepRef.current.uploadedProductCount += 1;
      } catch (error: any) {
        result.error = error.message;
      }
      result.productId = existedProductData.id || '';
      return result;
    }

    // ** create product **
    try {
      const createProductResponse = await createProductMutation({ variables });
      const { success, message, productId: newProductId } = createProductResponse?.data?.createProduct || {};

      if (success) {
        result.productId = newProductId;
        keepRef.current.uploadedProductCount += 1;
      } else {
        result.error = message;
      }
    } catch {
      result.productId = '';
      result.error = `Error Upload Product: upload product failed`;
    }

    if (result.productId && dataItem.ItemCategoriesOnline) {
      // upload productNotes
      const productNotes: Record<string, any>[] = [];
      ORGANIZATION_SPECIFIC_FIELDS.COOP.forEach(f => {
        if (isDefined(dataItem[f.fieldName as keyof IProduct])) {
          const note = {
            fieldName: f.fieldName,
            [f.inputType]: f.transformer(dataItem[f.fieldName as keyof IProduct]),
          };
          productNotes.push(note);
        }
      });
      try {
        await updateProductNotes({ variables: { id: result.productId, productNotes } });
      } catch {
        result.error = `Error Upload Product: upload product's notes failed`;
      }
    }

    return result;
  };

  const handleUploadData = async () => {
    setUploading(true);
    setUploadProgress({ total: 0, uploadedTotal: 0, uploaded: [], uploading: [], isUploading: true });

    const uploadedSet: Record<string, TUploadedProductStatus> = {};
    const uploadingDataList = prepareDataToUpload();

    const uploadProductPromises = uploadingDataList.map(async dataItem => {
      const imageDataList = productImageDataSet[dataItem.id || ''] || [];
      const reorderedImageDataList = reorderImages(imageDataList, imageSettings.aiMain);
      const uploadedProductStatus = await uploadProduct(dataItem, reorderedImageDataList);

      if (dataItem.id) {
        uploadedSet[dataItem.id] = uploadedProductStatus;
      }
    });

    await Promise.all(uploadProductPromises);

    onUploadedProductStatusSetChange(uploadedSet);
    onUploaded({ count: keepRef.current.uploadedProductCount });
    keepRef.current.uploadedProductCount = 0;

    setUploading(false);
    setUploadProgress(state => ({ ...state, isUploading: false }));
  };

  const disabledImport = !producerData.id || uploadProgress.isUploading;
  // Todo
  // const disabledImport = !producerData.id || uploadProgress.isUploading || (!isApprovedAll && !isDisApprovedAll);

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      style={{ backgroundColor: disabledImport || isAllRowHasEAN ? undefined : '#56aba9' }}
      disabled={uploading || disabledImport}
      onClick={handleUploadData}
    >
      {uploading ? <CircularProgress size={16} style={{ margin: 4 }} /> : 'Import products'}
    </Button>
  );
}

export default UploadProductButton;
