import React, { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';

import { CELL_SPACING, useStyles } from './index.style';

const MIN_WIDTH = 100 - CELL_SPACING * 2;
const DEFAULT_MAX_WIDTH = 250;

const useCustomStyles = makeStyles(() => ({
  resizeBar: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    width: 8,
    right: -4,
    top: 0,
    height: '100%',
    cursor: 'ew-resize',
  },
  resizeLine: {
    width: 1,
    height: '100%',
    background: '#fff',
  },
}));

const resizeColumn = (dataField: string, width: number) => {
  const cellWrapperNodeList = document.querySelectorAll<HTMLElement>(`[data-field='${dataField}']`);
  cellWrapperNodeList.forEach(element => {
    element.style.width = `${width}px`;
    element.dataset.width = String(width);
  });
};

export interface ReviewTableTemplateHeaderCellProps extends TableCellProps {
  minWidth?: number;
  field: string;
  wrapperProps?: HTMLAttributes<HTMLElement>;
  onChangeWidth?: (width: number) => void;
}

const ReviewTableTemplateHeaderCell: FC<ReviewTableTemplateHeaderCellProps> = ({
  minWidth: minWidthProp,
  field,
  className,
  children,
  wrapperProps,
  onChangeWidth,
  ...props
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const keepRef = useRef({ oldLeft: 0, oldWidth: 0 });

  const [isResizing, setResizing] = useState(false);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null);

  const minWidth = useMemo(() => {
    return minWidthProp ? Math.max(minWidthProp - CELL_SPACING * 2, 0) : MIN_WIDTH;
  }, [minWidthProp]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!wrapperElement) return;
    keepRef.current = { oldWidth: wrapperElement.offsetWidth, oldLeft: event.pageX };
    setResizing(true);
  };

  // handle resize
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!wrapperElement || !isResizing) return;
      const { oldWidth, oldLeft } = keepRef.current;
      const newWidth = oldWidth + event.pageX - oldLeft;

      keepRef.current = { oldWidth: newWidth, oldLeft: event.pageX };
      resizeColumn(field, Math.max(newWidth, minWidth));
      onChangeWidth?.(Math.max(newWidth, minWidth));
    };

    if (isResizing) document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [field, minWidth, wrapperElement, isResizing, onChangeWidth]);

  // handle stop resizing
  useEffect(() => {
    const handleMouseUp = () => {
      keepRef.current = { oldWidth: 0, oldLeft: 0 };
      setResizing(false);
    };

    if (isResizing) document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, [isResizing]);

  // Make table non-selectable while user is resizing
  useEffect(() => {
    const tableElement = document.getElementById('review-table');
    if (tableElement) tableElement.style.userSelect = isResizing ? 'none' : '';
  }, [isResizing]);

  useEffect(() => {
    // make sure set default width 1 time
    if (!wrapperElement || wrapperElement?.dataset?.width) return;

    // get the largest columns' width
    let maxWidth = wrapperElement.offsetWidth;
    const headerRows = document.querySelectorAll<HTMLElement>(`[data-field='${field}']`);
    headerRows.forEach(headerRow => (maxWidth = Math.max(maxWidth, headerRow.offsetWidth)));

    // set default columns' width
    // adding 2 pixel will help [TruncateText] work correctly
    const width = Math.max(Math.min(maxWidth, DEFAULT_MAX_WIDTH), minWidth) + 2;
    resizeColumn(field, width);
    onChangeWidth?.(width);
  }, [field, minWidth, wrapperElement, onChangeWidth]);

  return (
    <TableCell {...props} className={clsx(classes.headCell, className)}>
      <div ref={setWrapperElement} data-field={field} {...wrapperProps}>
        {children}
      </div>
      <div className={customClasses.resizeBar} onMouseDown={handleMouseDown}>
        <div className={customClasses.resizeLine} />
      </div>
    </TableCell>
  );
};

export default ReviewTableTemplateHeaderCell;
