import { useContext, useEffect, useMemo, useState } from "react";

import XMLViewer from 'react-xml-viewer';
import axios from "axios";
import { decode } from 'js-base64';
import { path } from "ramda";
import tail from 'lodash/tail';
import head from 'lodash/head';
import isNil from 'lodash/isNil';

import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";

import IProduct from "models/product";
import { toSafeFloat } from "utils/helpers";
import { restEndpoints } from "constants/domain";
import { KeycloakContext } from "components/Secured";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

interface IImage {
  id?: string;
  imgSrc?: string;
  isExisting?: boolean;
  isNew?: boolean;
  pictureUrl?: string;
  isArtwork?: boolean;
};

interface IApoteaXmlProps {
  state?: IProduct;
  images?: IImage[];
}

const parseIntValue = (value: string|number|undefined|null) => {
  if (isNil(value)) return null;
  if (typeof value === 'string') {
    if (value.trim() === '') return null;
    return parseInt(value.replace(',', '.'));
  }
  if (typeof value === 'number') {
    return value;
  }
  return null;
};

const parseNumber = (value: string|number|undefined|null) => {
  if (isNil(value)) return null;
  if (typeof value === 'string') {
    if (value.trim() === '') return null;
    return parseFloat(value.replace(',', '.'));
  }
  if (typeof value === 'number') {
    return value;
  }
  return null;
};

const applyArtwork = (image?: IImage) => {
  let pictureUrl = image?.pictureUrl;
  if (image && pictureUrl) {
    const url = new URL(pictureUrl);
    if (image.isArtwork) {
      url.searchParams.set("ARTWORK", "1");
    } else {
      url.searchParams.delete("ARTWORK");
    }
    pictureUrl = url.toString();
  }
  return pictureUrl;
};

const ApoteaXml = ({ state, images }: IApoteaXmlProps) => {
  const classes = useStyles();
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx);

  // TODO: avoid rerendering
  const existingImages = images?.filter((x) => x.isExisting);
  const image = head(existingImages);
  const pictureUrl = applyArtwork(image);

  const additionalImages = tail(existingImages)?.map(applyArtwork);
  const newState = useMemo(() => ({
    ...state,
    created_at: parseIntValue(state?.created_at),
    updated_at: parseIntValue(state?.updated_at),
    additionalImages,
    // adminStatus: state?.adminStatus,
    // foodlaCategory: state?.foodlaCategory,
    // title: state?.title,
    image_src: pictureUrl,
    creator: state?.producerId ? decode(state.producerId) : undefined,
    descriptive_size_amount: parseNumber(state?.descriptive_size_amount),
    descriptive_size_unit: state?.descriptive_size_unit && state?.descriptive_size_unit.trim() !== '' ? state?.descriptive_size_unit : null,
    // EAN: state?.EAN,
    // EAN2: state?.EAN2,
    // purchasingDataSupplierArticleNo: state?.purchasingDataSupplierArticleNo,
    // short_text: state?.short_text,
    // shortSalesDescription: state?.shortSalesDescription,
    // ingredient_statement: state?.ingredient_statement,
    // animalFoodIngredients: state?.animalFoodIngredients,
    // non_food_ingredients: state?.non_food_ingredients,
    // labels: state?.labels,
    // additionalCharacteristics: state?.additionalCharacteristics,
    // nutritional_unit: state?.nutritional_unit,
    energi_kj: parseNumber(state?.energi_kj),
    energi_kcal: parseNumber(state?.energi_kcal),
    fett: parseNumber(state?.fett),
    mattatFett: parseNumber(state?.mattatFett),
    enkelomattatFett: parseNumber(state?.enkelomattatFett),
    flerromattatFett: parseNumber(state?.flerromattatFett),
    kolhydrat: parseNumber(state?.kolhydrat),
    sockerarter: parseNumber(state?.sockerarter),
    polyoler: parseNumber(state?.polyoler),
    starkelse: parseNumber(state?.starkelse),
    fiber: parseNumber(state?.fiber),
    protein: parseNumber(state?.protein),
    salt: parseNumber(state?.salt),
    vitaminA: parseNumber(state?.vitaminA),  
    vitaminD: parseNumber(state?.vitaminD),
    vitaminE: parseNumber(state?.vitaminE),
    vitaminK: parseNumber(state?.vitaminK),
    vitaminC: parseNumber(state?.vitaminC),
    tiamin: parseNumber(state?.tiamin),
    riboflavin: parseNumber(state?.riboflavin),
    niacin: parseNumber(state?.niacin),
    vitaminB6: parseNumber(state?.vitaminB6),
    folsyra: parseNumber(state?.folsyra),
    vitaminB12: parseNumber(state?.vitaminB12),
    biotin: parseNumber(state?.biotin),
    pantotensyra: parseNumber(state?.pantotensyra),
    kalium: parseNumber(state?.kalium),
    klorid: parseNumber(state?.klorid),
    kalcium: parseNumber(state?.kalcium),
    fosfor: parseNumber(state?.fosfor),
    magnesium: parseNumber(state?.magnesium),
    jarn: parseNumber(state?.jarn),
    zink: parseNumber(state?.zink),
    koppar: parseNumber(state?.koppar),
    mangan: parseNumber(state?.mangan),
    fluorid: parseNumber(state?.fluorid),
    selen: parseNumber(state?.selen),
    krom: parseNumber(state?.krom),
    molybden: parseNumber(state?.molybden),
    jod: parseNumber(state?.jod),
    // package_size: parseNumber(state?.package_size),
    // min_temperature: state?.min_temperature,
    // max_temperature: state?.max_temperature,
    // trade_item_temperature_information: state?.trade_item_temperature_information,
    // place_of_item_activity: state?.place_of_item_activity,
    // fishProductionMethod: state?.fishProductionMethod,
    // fishCatchMethod: state?.fishCatchMethod,
    // catchArea: state?.catchArea,
    // eggWeightClass: state?.eggWeightClass,
    // eggPackerApprovalNumber: state?.eggPackerApprovalNumber,
    // country_of_manufacturing: state?.country_of_manufacturing,
    // search_terms: state?.search_terms,
    consumer_size_to_order_size_ratio: toSafeFloat(state?.consumer_size_to_order_size_ratio),
    gross_weight_num: parseNumber(state?.gross_weight_num),
    gross_height: parseNumber(state?.gross_height),
    gross_width: parseNumber(state?.gross_width),
    gross_depth: parseNumber(state?.gross_depth),
    // brand: state?.brand,
    // brand_food: state?.brand_food,
    // producer: {
    //   name: state?.producerName,
    //   username: state?.producerUsername,
    //   phone: state?.producerPhone,
    //   email: state?.producerEmail,
    // },
    // manufacturer: {
    //   gln: state?.manufacturerGln,
    // },
    // location: state?.location,
    // classification: state?.classification,
    alcoholPercentage: parseNumber(state?.alcoholPercentage),
    // color: state?.color,
    // material: state?.material,
    // dimensions: state?.dimensions,
    // capacity: state?.capacity,
    // recyclingType: state?.recyclingType,
    // dosageAndUsageInstructions: state?.dosageAndUsageInstructions,
    // preparationInstructions: state?.preparationInstructions,
    // signalWords: state?.signalWords,
    // hazardStatements: state?.hazardStatements,
    // securityNotices: state?.securityNotices,
    // hazardSymbols: state?.hazardSymbols,
    // responsibleLabelingApprovalNumber: state?.responsibleLabelingApprovalNumber,
    // animalCategoriesForFeed: state?.animalCategoriesForFeed,
    // feedAdditives: state?.feedAdditives,
    // analyticalConstituentsFeed: state?.analyticalConstituentsFeed,
    // feedingInstructions: state?.feedingInstructions,
    // animalAgeGroup: state?.animalAgeGroup,
    // preparedForm: state?.preparedForm,
    // cmrSubstance: state?.cmrSubstance,
    // CMRSubstances: state?.CMRSubstances,
    // reachSubstance: state?.reachSubstance,
    // bestBeforeDate: state?.bestBeforeDate,
    // childrenUnder16: state?.childrenUnder16,
    // complianceEg12232009: state?.complianceEg12232009,
    // qrLabelingSwedish: state?.qrLabelingSwedish,
    // qrMedicalClaims: state?.qrMedicalClaims,
    // qrMarketingClaimsCosmetic: state?.qrMarketingClaimsCosmetic,
    // sunProtectionFactor: state?.sunProtectionFactor,
    // complianceSunscreenRegulation: state?.complianceSunscreenRegulation,
    // qrEfsaUlValues: state?.qrEfsaUlValues,
    // qrNutritionClaims: state?.qrNutritionClaims,
    // qrHealthClaims: state?.qrHealthClaims,
    // qrFoodLabeling: state?.qrFoodLabeling,
    // qrMisleadingSalesTerms: state?.qrMisleadingSalesTerms,
    // qrFoodHygiene: state?.qrFoodHygiene,
    // categoryWeightloss: state?.categoryWeightloss,
    // qrClassificationLabelingPackaging: state?.qrClassificationLabelingPackaging,
    // complianceReach19072006: state?.complianceReach19072006,
    // reachSubstancesAnnex19072006: state?.reachSubstancesAnnex19072006,
    // regulatedSubstance: state?.regulatedSubstance,
    // complianceBiocid: state?.complianceBiocid,
    // hazardousSubstancesOver1: state?.hazardousSubstancesOver1,
    // informationHazardousSubstances: state?.informationHazardousSubstances,
    // complianceProductSafety2004451: state?.complianceProductSafety2004451,
    // ceMarking: state?.ceMarking,
    // specifyCEStandards: state?.specifyCEStandards,
    // complianceBiocidalTreated: state?.complianceBiocidalTreated,
    // qrElectronic: state?.qrElectronic,
    // complianceToyDirective: state?.complianceToyDirective,
    // complianceMetalsSkinContact: state?.complianceMetalsSkinContact,
    // complianceMedicalTechProducts: state?.complianceMedicalTechProducts,
    // checkUniqueIdentification: state?.checkUniqueIdentification,
    // explanationNoUDI: state?.explanationNoUDI,
    // complianceHaccp: state?.complianceHaccp,
    // approvalDietFeed: state?.approvalDietFeed,
    // qrAdditivesInFeed: state?.qrAdditivesInFeed,
    // qrFeedLabeling: state?.qrFeedLabeling,
    // qrRelevantFeedCertifications: state?.qrRelevantFeedCertifications,
    // plasticTrayPackaging: state?.plasticTrayPackaging,
    basicDataTaxClassification: parseNumber(state?.basicDataTaxClassification),
    // palletQtyOfNextLowerItem: state?.palletQtyOfNextLowerItem,
    basicDataAvailabilityDateFrom: parseNumber(state?.basicDataAvailabilityDateFrom),
    // palletEANForStorePack: state?.palletEANForStorePack,
    // consumerPackOrderableUnit: state?.consumerPackOrderableUnit,
    // consumerPackDespatchUnit: state?.consumerPackDespatchUnit,
    // storePackOrderableUnit: state?.storePackOrderableUnit,
    // storePackEANForStorePack: state?.storePackEANForStorePack,
  }), [state, additionalImages, pictureUrl]);
  
  const [xml, setXml] = useState<string>('<nodata />');
  useEffect(() => {
    if (newState && token) {
      const formData = new FormData();
      formData.append('product', JSON.stringify(newState));
      axios.post(
        restEndpoints.apotea,
        formData,
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            ContentType: 'multipart/form-data',
          } 
        }
      )
      .then((res) => {
        if (res && res.data) {
          // console.log('!!!XML', res.data);
          setXml(res.data);
        } else {
          setXml('<nodata />');
        }
      });
    }
  }, [newState, token]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>Apotea XML preview</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          <XMLViewer
            xml={xml} 
            collapsible={true}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ApoteaXml;
