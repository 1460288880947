import { ReactNode, createContext } from 'react';

import IndexedDB from 'utils/indexedDB';

import { DEFAULT_LIMIT, PARSE_IMAGE_BY } from '../constants';
import { FOOD_CATEGORY_ID } from 'components/constants-ts';
import IProducer from 'models/producer';
import IProduct from 'models/product';
import { IImportColumn } from '../types';

export type TUploadProgress = {
  total: number;
  uploadedTotal: number;
  failedTotal?: number;
  isUploading: boolean;
  uploaded: string[];
  uploading: string[];
};

export type TUploadedProductStatus = { dataId: string; productId?: string; error?: string };

export type TImageSettings = {
  aiMain: boolean;
  aiIgnore: boolean;
  parseImageBy: keyof IProduct;
};

export type TError = {
  step: number;
  message: ReactNode;
};

export type TControlContextValue = {
  userRole: string;

  loadingLocal: boolean;
  setLoadingLocal: React.Dispatch<React.SetStateAction<boolean>>;
  step: number;
  onStepChange: (step: number) => void;
  indexedDB: IndexedDB;

  selectedCategoryTypeId: string;
  setSelectedCategoryTypeId: React.Dispatch<React.SetStateAction<string>>;
  categoryColumnList: IImportColumn[];

  // excel
  limit: number;
  onLimitChange: (limit: number) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  productType: string;
  onProductTypeChange: (productType: string) => void;
  producerList: IProducer[];
  setProducerList: React.Dispatch<React.SetStateAction<IProducer[]>>;
  producerData: IProducer;
  onProducerIdChange: (producerId: string) => void;

  // product images
  hasClickedUploadImage: boolean;
  setHasClickedUploadImage: React.Dispatch<React.SetStateAction<boolean>>;
  isConvertingImages: boolean;
  setConvertingImages: React.Dispatch<React.SetStateAction<boolean>>;
  regexValue: string;
  onRegexValueChange: (newRegexValue: string) => void;
  uploadProgress: TUploadProgress;
  setUploadProgress: React.Dispatch<React.SetStateAction<TUploadProgress>>;
  imageSettings: TImageSettings;
  onImageSettingChange: (setting: Partial<TImageSettings>) => void;

  tableContainerElement: HTMLDivElement | null;
  setTableContainerElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  tableDownloadContainerElement: HTMLDivElement | null;
  setTableDownloadContainerElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;

  endPinnedCellElement: HTMLDivElement | null;
  setEndPinnedCellElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  onScrollToColumn: (field: string) => void;

  errorList: TError[];
  setErrorList: React.Dispatch<React.SetStateAction<TError[]>>;

  onReset: (isNotAll?: boolean) => void;
};

export const contextDefaultValue: TControlContextValue = {
  userRole: '',

  loadingLocal: true,
  setLoadingLocal: () => {},
  step: 1,
  onStepChange: () => {},
  indexedDB: {} as unknown as IndexedDB,

  selectedCategoryTypeId: '',
  setSelectedCategoryTypeId: () => {},
  categoryColumnList: [],

  limit: DEFAULT_LIMIT,
  onLimitChange: () => {},
  page: 1,
  setPage: () => {},
  productType: FOOD_CATEGORY_ID,
  onProductTypeChange: () => {},
  producerList: [],
  setProducerList: () => {},
  producerData: {},
  onProducerIdChange: () => {},

  hasClickedUploadImage: false,
  setHasClickedUploadImage: () => {},
  isConvertingImages: false,
  setConvertingImages: () => {},
  regexValue: '',
  onRegexValueChange: () => {},
  uploadProgress: { total: 0, uploadedTotal: 0, isUploading: false, uploaded: [], uploading: [] },
  setUploadProgress: () => {},
  imageSettings: { aiMain: true, aiIgnore: false, parseImageBy: PARSE_IMAGE_BY.EAN },
  onImageSettingChange: () => {},

  tableContainerElement: null,
  setTableContainerElement: () => {},
  tableDownloadContainerElement: null,
  setTableDownloadContainerElement: () => {},
  endPinnedCellElement: null,
  setEndPinnedCellElement: () => {},
  onScrollToColumn: (field: string) => {},

  errorList: [],
  setErrorList: () => {},

  onReset: () => {},
};

export const ControlContext = createContext<TControlContextValue>(contextDefaultValue);
