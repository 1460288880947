import React from 'react';

import InputTextField from '../../../inputfields/InputTextField';
import IProduct from '../../../../models/product';
import { IFoodlaCategoryOption } from 'models/category';
import { isCosmetic, isOTC, isSunscreen } from './category';

interface INonFoodIngredientsProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory?: IFoodlaCategoryOption;
}

export const NonFoodIngredients = ({ approve, state, setState, rootCategory }: INonFoodIngredientsProps) => {
  // TODO any
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });

  return (
    <InputTextField<IProduct>
      multiline
      approve={approve}
      state={state}
      setState={setState}
      label="Innehållsförteckning"
      dense={false}
      inputName={'non_food_ingredients'}
      value={state.non_food_ingredients}
      multilineRows={2}
      multilineMax={20} 
      onChange={handleChange}
      withLabel
      labelOptions={{
        heading: 'Innehållsförteckning',
        required: isCosmetic(state?.foodlaCategory, rootCategory)
          || isSunscreen(state?.foodlaCategory, rootCategory)
          || isOTC(state?.foodlaCategory, rootCategory)
      }}
    />
  );
};

export default NonFoodIngredients;
