export const GROUP_CATEGORY_IDS = {
  LEKSAK: 'leksak',
  ELEKTRISKA_PRODUKTER: 'elektriska-produkter',
  SMYCKE_KLADER_GLASOGON_ACCESSOARER: 'smycke_klader_glasogon_accessoarer',
  VAROR: 'varor',
  MEDICINTEKNISKA_PRODUKTER: 'medicintekniska-produkter',
  KEMISKA_PRODUKTER: 'kemiska-produkter',
  TVATTMEDEL_DISKMEDEL: 'tvattmedel_diskmedel',
  KOSMETISKA_PRODUKTER: 'kosmetiska-produkter',
  SOLSKYDDSMEDEL: 'solskyddsmedel',
  KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT: 'kompletteringsfoder-och-fodertillskott',
  FODERRAVARA: 'foderravara',
  HELFODER_OCH_FODERBLANDNINGAR: 'helfoder-och-foderblandningar',
  RECEPTFRIA_LAKEMEDEL: 'receptfria-lakemedel',
  OVRIGT_LIVSMEDEL: 'ovrigt-livsmedel',
  FISK_OCH_FISK_PRODUKTER: 'fisk-och-fisk-produkter',
  SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39:
    'special-livsmedel-enligt-definitionen-i-direktiv-eg-2009-39',
  DRYCKER: 'drycker',
  AGG_OCH_AGG_PRODUKTER: 'agg-och-agg-produkter',
  KOSTTILLSKOTT: 'kosttillskott',
  FRUKT_OCH_GRÖNSAKER: 'frukt-och-grönsaker',
  KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER: 'kott_kryddor_soppor_saser_sallader-och-proteinprodukter',
  ALLMANT_LIVSMEDEL: 'allmant_livsmedel',
};

export const ALL_GROUP_CATEGORY_IDS = [
  GROUP_CATEGORY_IDS.LEKSAK,
  GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
  GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
  GROUP_CATEGORY_IDS.VAROR,
  GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
  GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
  GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
  GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
  GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
  GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
  GROUP_CATEGORY_IDS.FODERRAVARA,
  GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
  GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
  GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
  GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
  GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
  GROUP_CATEGORY_IDS.DRYCKER,
  GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
  GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
  GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
  GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
  GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
];
