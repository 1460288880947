import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Asynchronous from './Autocomplete';

const useStyles = makeStyles(theme => ({
    toolbarBox: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    toolbarButton: {
        marginLeft: theme.spacing(2),
    },
}));

export default function(props) {
    const { searchAction, children, title, tryToAddItemToOrder, selectedItems } = props;
    const classes = useStyles();
    return (
        <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarBox}>
                {searchAction && (

                    <Asynchronous
                      searchAction={searchAction}
                      tryToAddItemToOrder={tryToAddItemToOrder}
                      selectedItems={selectedItems}
                    />
                    // <SearchInput
                    //     className={classes.searchInput}
                    //     searchAction={searchAction}
                    // />
                )}
                {title && (
                    <Typography variant="body1" className={classes.title}>
                        {title}
                    </Typography>
                )}
            </Box>
            <div>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        className: classes.toolbarButton,
                    })
                )}
            </div>
        </Toolbar>
    );
}
