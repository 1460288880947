import React, { FC } from 'react';

const ErrorOutlineIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height="16"
      width="16"
      {...props}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
  );
};

export default ErrorOutlineIcon;
