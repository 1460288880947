// Dependencies
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

// Constants
import COLORS from '../../constants/colors';

interface IStockToggleButtonProps {
  handleCheck?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  quantity_in_stock: number;
}

export const StockToggleButton = ({ handleCheck, quantity_in_stock }: IStockToggleButtonProps) => (
  <Typography component="div">
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid
        item
        style={{ color: quantity_in_stock === 0 ? 'grey' : COLORS.mainGreen }}
      >
        Aktiv
      </Grid>
      <Grid item>
        <Switch checked={quantity_in_stock === 0} onChange={handleCheck} name="quantity_in_stock" />
      </Grid>
      <Grid
        item
        style={{ color: quantity_in_stock === 0 ? COLORS.mainRed : 'grey' }}
      >
        Inaktiv
      </Grid>
    </Grid>
  </Typography>
);

export default StockToggleButton;
