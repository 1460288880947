import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import { IImportColumn } from '../types';
import { Box } from '@material-ui/core';
import Header from './Header';
import MappingRow from './MappingRow';
import FooterWrapper from '../components/FooterWrapper';

import useDataContext from '../hooks/useDataContext';

import { useStyles } from './styles';

const correctOrder = (sortedColumnList: IImportColumn[], columnList: IImportColumn[]) => {
  if (!sortedColumnList.length) return columnList;
  const result: IImportColumn[] = [];
  let leftUpdatedColumnList = _.cloneDeep(columnList);

  sortedColumnList.forEach(oldColumn => {
    const newColumn = leftUpdatedColumnList.find(({ _id }) => _id === oldColumn._id);
    if (!newColumn) return;
    result.push(newColumn);
    leftUpdatedColumnList = leftUpdatedColumnList.filter(({ _id }) => _id !== newColumn._id);
  });

  return [...result, ...leftUpdatedColumnList];
};

const FieldsMapping = () => {
  const classes = useStyles();
  const { excelColumnList, defaultExcelColumnList } = useDataContext();
  const keepRef = useRef({ sortedColumnList: excelColumnList });

  const [showErrorOnly, setShowErrorOnly] = useState(false);

  const columnList = useMemo(() => {
    const result = excelColumnList.filter(({ isMissing, isExtra }) => !isMissing && !isExtra);
    return correctOrder(keepRef.current.sortedColumnList, result);
  }, [excelColumnList]);

  const defaultColumnList = useMemo(() => {
    const result = defaultExcelColumnList.filter(({ isMissing, isExtra }) => !isMissing && !isExtra);
    return correctOrder(keepRef.current.sortedColumnList, result);
  }, [defaultExcelColumnList]);

  const errorCount = useMemo(() => {
    return columnList.filter(({ field }) => !field).length;
  }, [columnList]);

  const defaultErrorCount = useMemo(() => {
    return defaultColumnList.filter(({ field }) => !field).length;
  }, [defaultColumnList]);

  useEffect(() => {
    if (!keepRef.current.sortedColumnList?.length) {
      keepRef.current.sortedColumnList = excelColumnList;
    }
  }, [excelColumnList]);

  useEffect(() => {
    setShowErrorOnly(!!errorCount);
  }, [errorCount]);

  return (
    <Box>
      <Header
        showErrorOnly={showErrorOnly}
        errorCount={errorCount}
        defaultErrorCount={defaultErrorCount}
        count={columnList.length}
        onShowErrorChange={setShowErrorOnly}
      />

      <Box p={2}>
        <Box className={classes.row}>
          <Box className={classes.mainCell}>Importfilen kolumnrubrik</Box>
          <Box className={classes.arrowCell} />
          <Box className={classes.mainCell}>Foodla kolumnrubrik</Box>
          <Box className={classes.mainCell}>Matchningsstatus</Box>
        </Box>
      </Box>

      <Box bgcolor="#FFFFFF" border="1px solid #EDEEF3" borderRadius={4} p={2}>
        <Box display="flex" flexDirection="column" gridGap={16}>
          {columnList.map(column => (
            <MappingRow key={column._id} column={column} showErrorOnly={showErrorOnly} />
          ))}
        </Box>
      </Box>

      <FooterWrapper />
    </Box>
  );
};

export default FieldsMapping;
