import IProduct from '../../../../models/product';
import InputTextField from '../../../inputfields/InputTextField';

const getControlDigit = (ean: string) => {
  if (ean) {
    const EAN = ean.trim();
    if (EAN.length === 14 || EAN.length === 12) {
      const total =
        3 * (
          parseInt(EAN[0])
          + parseInt(EAN[2])
          + parseInt(EAN[4])
          + parseInt(EAN[6])
          + parseInt(EAN[8])
          + parseInt(EAN[10])
          + parseInt(EAN[12])
        ) +
        1 *
          (parseInt(EAN[1]) +
            parseInt(EAN[3]) +
            parseInt(EAN[5]) +
            parseInt(EAN[7]) +
            parseInt(EAN[9]) +
            parseInt(EAN[11]));
      let control = 10 - (total % 10);
      if (control === 10) control = 0;
      return control;
    }
  }
  return undefined;
};

const checkEAN = (ean: string) => {
  const EAN = ean.trim();
  if (EAN.length === 14) {
    const control = getControlDigit(ean);
    return control === parseInt(EAN[13]);
  }
  return true;
};

const getEanErrorMessage = (
  ean: string,
  options: { ignoreEANLengthError?: boolean; producerIsStore?: boolean; uniqResult?: boolean }
) => {
  const { ignoreEANLengthError, producerIsStore, uniqResult } = options || {};
  let eanLengthError;
  let eanControlError;
  // let eanWrongCoopStoreError;
  // let nonUniqError;

  // if (ean && ean.trim() !== '' && !uniqResult) {
  //   if (producerIsStore) {
  //     nonUniqError =
  //       'Det verkar som om att du redan använt denna EAN-kod på en av dina produkter i Foodla, vänligen ange en annan EAN-kod.';
  //   } else {
  //     nonUniqError =
  //       'Det verkar som om att EAN-koden du försöker ange redan existerar i Foodla, vänligen ange en annan EAN-kod. Kontakta oss på support@foodla.nu om det verkar som om någon annan har använt din produkts EAN-kod!';
  //   }
  // }
  if (ean && ean.trim() !== '' && ean.trim().length !== 14) {
    if (!ignoreEANLengthError) {
      eanLengthError = 'Vänligen ange EAN-kod med 14 siffror.';
    }
  } else if (
    ean &&
    ean.trim().length === 14 &&
    // !ean.trim().startsWith('23') &&
    // !ean.trim().startsWith('2000') &&
    // !ean.trim().startsWith('2097') &&
    !checkEAN(ean)
  ) {
    eanControlError =
      'Kontrollsiffran (sista siffran) i EAN-koden verkar inte stämma överens med övriga siffror. Säkerställ gärna att övriga siffror i EAN-koden är rätt och uppdatera EAN-koden om något blivit fel.';
  } else {
    eanLengthError = undefined;
    eanControlError = undefined;
  }

  return eanLengthError || eanControlError; // || eanWrongCoopStoreError || nonUniqError;
};

interface IEANForStorePackProps {
  uniqResult: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
}

export const EANForStorePack = ({ uniqResult, state, setState }: IEANForStorePackProps) => {
  const getEanError = (ean?: string) => {
    if (!ean) return '';
    return getEanErrorMessage(ean, {
      ignoreEANLengthError: state.ignoreEANLengthError,
      producerIsStore: state.producerIsStore,
      uniqResult,
    });
  };

  const handleChange = ({ target: { value, name } }: any) => {
    setState({ ...state, [name]: value });
  };

  // Distributionsförpackning
  const isStorePack = state.storePackOrderableUnit || state.storePackDespatchUnit;
  // Transportförpackning
  const isTransport = state.transportOrderableUnit || state.transportDespatchUnit;
  // Pall
  const isPallet = state.palletOrderableUnit || state.palletDespatchUnit;

  return (
    <>
      {isStorePack && (
        <InputTextField
          withLabel
          inputName="storePackEANForStorePack"
          value={state.storePackEANForStorePack}
          placeholder="Ex. 17300000000001"
          forceRed={!!getEanError(state.storePackEANForStorePack)}
          labelOptions={{
            heading: 'EAN-kod distributionsförpackning (14 siffror)',
            text: 'Ange EAN/GTIN som distributionsförpackning är märkt med. Ange med 14 siffror, Foodla kommer automatiskt checka att din kontrollsiffra stämmer.',
          }}
          state={state}
          onChange={handleChange}
        />
      )}
      <span style={{ fontWeight: 'bold', color: 'orangered' }}>{getEanError(state.storePackEANForStorePack)}</span>

      {isTransport && (
        <InputTextField
          withLabel
          state={state}
          inputName="transportEANForStorePack"
          value={state.transportEANForStorePack}
          placeholder="Ex. 17300000000001"
          forceRed={!!getEanError(state.transportEANForStorePack)}
          labelOptions={{
            heading: 'EAN-kod transportförpackning (14 siffror)',
            text: 'Ange EAN/GTIN som transportförpackning är märkt med. Ange med 14 siffror, Foodla kommer automatiskt checka att din kontrollsiffra stämmer.',
          }}
          onChange={handleChange}
        />
      )}
      <span style={{ fontWeight: 'bold', color: 'orangered' }}>{getEanError(state.transportEANForStorePack)}</span>

      {isPallet && (
        <InputTextField
          withLabel
          state={state}
          inputName="palletEANForStorePack"
          value={state.palletEANForStorePack}
          placeholder="Ex. 17300000000001"
          forceRed={!!getEanError(state.palletEANForStorePack)}
          labelOptions={{
            heading: 'EAN-kod pall (14 siffror)',
            text: 'Ange EAN/GTIN som pallen är märkt med. Ange med 14 siffror, Foodla kommer automatiskt checka att din kontrollsiffra stämmer.',
          }}
          onChange={handleChange}
        />
      )}
      <span style={{ fontWeight: 'bold', color: 'orangered' }}>{getEanError(state.palletEANForStorePack)}</span>
    </>
  );
};

export default EANForStorePack;
