import { FC, useEffect } from 'react';

import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Button from 'components/button';
import useDataContext from '../hooks/useDataContext';
import { readExcelDataFromIndexedDB } from '../utils';
import useControlContext from '../hooks/useControlContext';

export interface ConfirmContinueModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ConfirmContinueModal: FC<ConfirmContinueModalProps> = ({ open, setOpen }) => {
  const { indexedDB } = useControlContext();
  const { onReset } = useDataContext();

  const handleReset = () => {
    onReset();
    setOpen?.(false);
  };

  useEffect(() => {
    if (!indexedDB) return;
    readExcelDataFromIndexedDB(indexedDB).then(({ excelFile }) => {
      setOpen(!!excelFile);
    });
  }, [indexedDB, setOpen]);

  return (
    <Dialog open={open}>
      <DialogContent>Vill du fortsätta där du slutade eller starta ny import?</DialogContent>
      <DialogActions>
        <Box display="flex" gridGap={16} px={2} py={1}>
          <Button size="small" variant="outlined" onClick={handleReset}>
            Starta ny
          </Button>
          <Button size="small" variant="contained" onClick={() => setOpen(false)}>
            Fortsätt
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmContinueModal;
