import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemAvatar, Avatar } from '@material-ui/core';

import { SUPERUSER_ORGANIZATIONS_QUERY } from '../../graphql/queries';
import withContainer from '../../hoc/withContainer';

const useStyles = makeStyles(theme => ({
  toolbarBox: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  toolbarButton: {
    marginLeft: theme.spacing(2),
  },
}));

interface IOrganization {
  name?: string;
  groupIdentifier?: string;
  id: string;
}

interface IOrganizationListItemProps {
  organization: IOrganization;
}

const OrganizationListItem = ({ organization }: IOrganizationListItemProps) => {
  const primaryTitle = organization?.name || 'NO TITLE';
  const secondaryTitle = organization?.groupIdentifier || 'NO IDENTIFIER';
  const linkTo = `/superuser/organization/${organization.id}/edit`;

  return (
    <Link
      variant="inherit"
      underline="none"
      color="inherit"
      component={RouterLink}
      to={linkTo}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            O
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primaryTitle}
          secondary={secondaryTitle}
        />
      </ListItem>
      <Divider />
    </Link>
  );
};

interface IOrganizationsListData {
  exists?: IOrganization[];
  failed?: IOrganization[];
}

interface IOrganizationsListProps {
  data?: IOrganizationsListData;
}

const OrganizationsList = ({ data }: IOrganizationsListProps) => {
  const classes = useStyles();

  return (
    <Paper>
      <Toolbar>
      {/* TODO <Toolbar className={classes.toolbar}> */}
        <Box className={classes.toolbarBox}>
          <Typography variant="body1" className={classes.title}>
            Organizations
          </Typography>
        </Box>
        <div>
          <Button
            className={classes.toolbarButton}
            component={RouterLink}
            to="/superuser/addorganization"
            variant="outlined"
            size="medium"
            color="primary"
            startIcon={<AddIcon />}
          >
            New
          </Button>
          </div>
        </Toolbar>
        {!data?.exists || data.exists.length < 1 && (
          <div style={{ margin: 10 }}>
              <h3>There are no organizations created yet.</h3>
          </div>
        )}
        <List dense>
          {data?.exists &&
            data.exists.map(o => (
              <OrganizationListItem organization={o} key={o.name} />
            )
          )}
        </List>
        <List dense>
          {data?.failed &&
            data.failed.map(o => (
              <div>
                Organization <b>{o.groupIdentifier}</b> not exists
              </div>
            )
          )}
        </List>
      </Paper>
    );
};

interface IData {
  superuserOrganizations?: IOrganization[];
  superuserOrganizationsFailed?: string[];
}

export default withContainer({
  query: SUPERUSER_ORGANIZATIONS_QUERY,
  validator: (data: IData) => !!data,
  resolver: (data: IData) => {
    const result = { exists: data?.superuserOrganizations, failed: data?.superuserOrganizationsFailed };
    return result;
  },
  variables: {},
  fetchPolicy: 'cache-and-network',
})(OrganizationsList);
