import { FC, useMemo } from 'react';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import clsx from 'clsx';

import { IImportColumn, IProductExcel } from '../../../types';
import { getCellValue } from '../../../utils/excel';

import { useStyles as useCustomStyles } from './styles';

export interface DateFieldProps {
  column: IImportColumn;
  data: IProductExcel;
  onChange: (value: string) => void;
}

export const formatDateValue = (value: unknown) => {
  if (_.isNil(value)) return '';
  if (!dateFns.isDate(value as Date)) return String(value);
  return dateFns.format(value as Date, 'yyyy-MM-dd');
};

const DateField: FC<DateFieldProps> = ({ column, data, onChange }) => {
  const customClasses = useCustomStyles();

  const value = useMemo(() => getCellValue(column, data), [column, data]);

  const displayValue = useMemo(() => formatDateValue(value), [value]);

  return (
    <div className={customClasses.inputDateWrapper}>
      <div>{displayValue}</div>
      <input
        className={clsx(customClasses.input, customClasses.inputDate)}
        type="date"
        value={displayValue}
        onChange={event => onChange(event.target.value)}
      />
    </div>
  );
};

export default DateField;
