import React, { FC } from 'react';

const TickIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <rect width="20" height="20" rx="2" fill="#EEF7F6" />
      <path
        d="M5 10.5L7.5 13.5L15 6.5"
        stroke="#56ABA9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickIcon;
