import axios from "axios";
import IProduct from "models/product";
import { isNotDefined } from "./helpers";

export enum IMAGE_SIZES {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  XSMALL = 'xsmall',
};

export const getBlobDataFromImageURL = async (url: string) => {
  const res = await axios.get(url, { 
    responseType: 'arraybuffer',
  });
  const blob = new Blob([res.data]);

  return blob;
};

const largeImageSuffix = 'w=512&h=512';
const mediumImageSuffix = 'w=256&h=256';
const smallImageSuffix = 'w=128&h=128';
const xSmallImageSuffix = 'w=64&h=64';

export const getImageWithSizeSuffix = (url: string, size: IMAGE_SIZES) => {
  const addImageParam = (urlParam: string) => {
    try {
      const { search } = new URL(url);

      if (search) {
        return `${url}&${urlParam}`
      } else {
        return `${url}?${urlParam}`;
      }
    } catch {
      return `${url}?${urlParam}`;
    }
  }
  switch (size) {
    case IMAGE_SIZES.LARGE:
      return addImageParam(largeImageSuffix);
    case IMAGE_SIZES.MEDIUM:
      return addImageParam(mediumImageSuffix);
    case IMAGE_SIZES.SMALL:
      return addImageParam(smallImageSuffix);
    case IMAGE_SIZES.XSMALL:
      return addImageParam(xSmallImageSuffix);
    default:
      return url;
  }
};

export const extractProductImagesToSingleArray = (product: IProduct) => {
  if (isNotDefined(product)) return [];

  const { image_src, additionalImages } = product;
  const arr = [];
  if (image_src && image_src.trim() !== '') {
    arr.push(image_src);
  }
  if (additionalImages && additionalImages.length > 0) {
    arr.push(...additionalImages);
  }
  return arr;
};
