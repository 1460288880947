import React, {useEffect} from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Script from 'react-load-script';

import LoadingSpinner from '../loadingIndicator/CircularSpinner';
import InputLabel from '../inputfields/InputLabel';
import COLORS from '../../constants/colors';

import { isDefined, toReadableCoordinate } from '../../utils/helpers';

const Autocomplete = ({
  containerStyle = {},
  dropDownStyle = {},
  inputFieldStyle = {},
  onFailureLoadingComponent,
  onSelectAddress,
  withLabel,
  labelOptions,
  currentAddress,
  showLoadingIndicator = true,
  loadingIndicatorPositionLeft = -30,
  loadingIndicatorPositionTop = 35,
  showLatLngInfo = false,
  latitude = 0,
  longitude = 0,
}) => {
  const getAddress = () => isDefined(currentAddress) ? currentAddress : '';
  const [address, setAddress] = React.useState(getAddress());
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const handleChange = address => {
    setAddress(address);
  };

  useEffect(() => {
    setAddress(getAddress());
  }, [currentAddress]);

  const handleSelect = address => {
    setAddress(address);

    // Get coordinates.
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onSelectAddress(address, latLng);
      })
      .catch(error => {
        console.log(`Could not fetch coordinates for address: ${address}`);
        onSelectAddress(address, {});
      });
  };

  const onScriptBeginLoad = () => {
    setScriptLoaded(false);
  };

  const onScriptLoadedSuccess = () => {
    setScriptLoaded(true);
  };

  const onScriptLoadedError = () => {
    setScriptLoaded(true);
    onFailureLoadingComponent();
  };

  const onMapsAPIError = (_, clearSuggestions) => {
    clearSuggestions();
  };

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  return (
    <div style={{ position: 'relative', width: '100%', marginTop: 7, ...containerStyle }}>
      {withLabel && <InputLabel {...labelOptions} />}
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
        onError={onScriptLoadedError}
        onLoad={onScriptLoadedSuccess}
        onCreate={onScriptBeginLoad}
      />
      {!scriptLoaded && <span>Loading address search...</span>}
      {scriptLoaded && (
        <>
        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} onError={onMapsAPIError}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
            {loading && showLoadingIndicator && (
                  <div style={{ position: 'absolute', top: loadingIndicatorPositionTop, left: loadingIndicatorPositionLeft }}>
                    <LoadingSpinner size={20} />
                  </div>
                )}
              <input
                {...getInputProps({
                  placeholder: ' Sök adress...',
                  className: 'location-search-input',
                  style: {
                    paddingLeft: 10,
                    width: '100%',
                    fontSize: 16,
                    borderRadius: 5,
                    height: 50,
                    ...inputFieldStyle,
                  },
                })}
              />
              <div
                className="autocomplete-dropdown-container"
                style={
                  isDefined(suggestions) && suggestions.length > 0
                    ? {
                        position: 'absolute',
                        width: '100%',
                        zIndex: 1000,
                        padding: 4,
                        fontSize: 16,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        borderStyle: 'solid',
                        borderWidth: 2,
                        borderColor: 'gray',
                        ...dropDownStyle,
                      }
                    : { height: 0 }
                }
              >
                {suggestions.map(suggestion => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  const style = suggestion.active
                    ? {
                        backgroundColor: COLORS.mainGreen,
                        borderRadius: 5,
                        cursor: 'pointer',
                        opacity: 1,
                        padding: 2,
                        fontWeight: 'bold',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        opacity: 1,
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {showLatLngInfo && (<p style={{ marginTop: 2, marginLeft: 2 }}>Coordinates: {toReadableCoordinate(latitude)}, {toReadableCoordinate(longitude)}</p>)}
        </>
      )}
    </div>
  );
};

export default Autocomplete;
