import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { ProductItemReview, ProductItemEdit, ProductItemSelect } from './ProductItem';
import Box from '@material-ui/core/Box';
import ProducerLatestOrder from './orders/ProducerLatestOrder';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2),
    },
}));

const DeliveryInfo = ({delivery, deliverySchedules}) => {
    return (
        <div style={{color: "darkgrey", fontSize: 16}}>
            {delivery && delivery.map((d) => {
                return (
                    <p style={{margin: 0}}><b>Leveransområde {d.deliveryArea}</b> - Beställningsdag: {d.orderDay}, Stopptid: {d.stopTime}, Leveransdag: {d.deliveryDay}</p>
                );
            })}
            {deliverySchedules && (
                <p>{deliverySchedules}</p>
            )}
        </div>
    );
};

export function ProductSelectGroup(props) {
    const classes = useStyles();
    const { list, title, removeAction, producerId, delivery, selectAction, selected, productData, daysLeft, isNegativeDays, lastOrderDaysAgo, deliverySchedules } = props;
    const [values, setValues] = React.useState({});

    const setFormData = (id, value) => {
        const newValues = { ...values };
        newValues[id] = value;
        setValues(newValues);
    };


    return (
        <Box className={classes.root}>
            <Card>
                <CardHeader title={title}
                    subheader={
                      <Box>
                        <ProducerLatestOrder producerId={producerId} daysLeft={daysLeft} lastOrderDaysAgo={lastOrderDaysAgo} isNegativeDays={isNegativeDays} suggest={true} />
                        <DeliveryInfo delivery={delivery} deliverySchedules={deliverySchedules} />
                      </Box>
                    }
                />
                <CardContent>
                    {list.map((product, index) => {
                        let productFromGA;
                        if (productData && Array.isArray(productData)) {
                            productFromGA = productData.find(x => x.sku === product.EAN);
                        }
                        return (
                            <ProductItemSelect
                                key={index}
                                product={product}
                                selected={selected}
                                removeAction={removeAction}
                                selectAction={selectAction}
                                setFormData={setFormData}
                                productData={productFromGA}
                            />
                        );
                    })}
                </CardContent>
            </Card>
        </Box>
    );
}

export function ProductEditGroup(props) {
    const classes = useStyles();
    const { list, title, removeAction, producerId, delivery, deliverySchedules } = props;
    const [values, setValues] = React.useState({});

    const setFormData = (id, value) => {
        const newValues = { ...values };
        newValues[id] = value;
        setValues(newValues);
    };

    return (
        <Box className={classes.root}>
            <Card>
                <CardHeader title={title}
                    subheader={
                      <Box>
                        <ProducerLatestOrder producerId={producerId} showNotes={true} />
                        <DeliveryInfo delivery={delivery} deliverySchedules={deliverySchedules} />
                      </Box>
                    }
                />
                <CardContent>
                    {list.map((product, index) => {
                        return (
                            <ProductItemEdit
                                key={index}
                                product={product}
                                removeAction={removeAction}
                                setFormData={setFormData}
                            />
                        );
                    })}
                </CardContent>
            </Card>
        </Box>
    );
}

export function ProductReviewGroup(props) {
    const classes = useStyles();
    const { list, title, producerId, delivery, deliverySchedules } = props;

    return (
        <Box className={classes.root}>
            <Card>
                <CardHeader title={title}
                  subheader={
                    <Box>
                      <ProducerLatestOrder producerId={producerId} showNotes={true} />
                      <DeliveryInfo delivery={delivery} deliverySchedules={deliverySchedules} />
                    </Box>
                  }
                />
                <CardContent>
                    {list.map((product, index) => {
                        return (
                            <ProductItemReview key={index} product={product} />
                        );
                    })}
                </CardContent>
            </Card>
        </Box>
    );
}

export default { ProductEditGroup, ProductReviewGroup };
