import gql from 'graphql-tag';
import IProduct from 'models/product';

export interface ISuperuserErrors {
  superuserProductsErrors?: IProduct[];
}

export const SUPERUSER_PRODUCTS_ERRORS = gql`
  query superuserProductsErrors($showNotApproved: Boolean) {
    superuserProductsErrors(showNotApproved: $showNotApproved) {
      id
      EAN
      category
      foodlaCategory {
        id
        name
      }
      title
      trade_item_temperature_information
      descriptive_size_amount
      descriptive_size_unit
      package_size
      package_size_unit
      brand
      brand_food
      producer {
        name
      }
      image_src
      errors {
        name
        state
      }
    }
  }
`;

export interface IProductDictionary {
  EAN: string;
  isStore?: boolean;
  products: IProduct[];
}

export interface INonUniqErrors {
  superuserNonUniqErrors?: IProductDictionary[];
}

export const SUPERUSER_NON_UNIQ_ERRORS = gql`
  {
    superuserNonUniqErrors {
      EAN
      isStore
      products {
        id
        EAN
        title
        image_src
      }
    }
  }
`;
