import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import IProduct from 'models/product';
import { NON_FOOD_CATEGORY_ID } from 'components/constants-ts';

import { IImportColumn, IProductExcel } from '../../types';
import { COLUMN_DETAILS, QUESTION_FIELDS, STATUS_COLORS } from '../../constants';
import { checkEnumType, getDataType, isEmptyCell, isErrorCell } from './cell';

export const correctColumnOrders = (columnList: IImportColumn[]) => {
  const result: IImportColumn[] = [];
  let leftColumnList = _.cloneDeep(columnList);

  COLUMN_DETAILS.forEach(({ field }) => {
    const column = leftColumnList.find(column => column.field === field);
    if (!column) return;

    result.push(column);
    leftColumnList = leftColumnList.filter(column => column.field !== field);
  });

  return [...result, ...leftColumnList];
};

export const checkExtraCorrespondingColumn = (field: keyof IProduct): boolean => {
  return COLUMN_DETAILS.some(({ extraCorrespondingField }) => extraCorrespondingField === field);
};

export const addExtraCorrespondingColumn = (columnList: IImportColumn[], selectedCategoryTypeId: string) => {
  const newColumnList = _.cloneDeep(columnList);

  columnList.forEach(({ field }) => {
    const columnDetail = COLUMN_DETAILS.find(column => column.field === field);
    const correspondingColumnDetail = COLUMN_DETAILS.find(
      column => column.field === columnDetail?.extraCorrespondingField
    );
    const isExisting = columnList.some(column => column.field === correspondingColumnDetail?.field);
    if (!correspondingColumnDetail || isExisting) return;

    newColumnList.push({
      _id: uuid(),
      isMandatory: correspondingColumnDetail.mandatoryInCategories.includes(selectedCategoryTypeId),
      field: correspondingColumnDetail.field,
      title: correspondingColumnDetail.title,
      exportable: false,
      isEnumType: checkEnumType(correspondingColumnDetail.field),
      dataType: getDataType(correspondingColumnDetail.field),
      defaultValue: correspondingColumnDetail.defaultValue,
    });
  });

  return correctColumnOrders(newColumnList);
};

export const mapExcelColumn = (column: IImportColumn, dataList: IProductExcel[]): IImportColumn => {
  let color = STATUS_COLORS.NORMAL;

  const isError = dataList.some(data => isErrorCell(column, data));
  const emptyCellList = dataList.filter(data => isEmptyCell(column, data));
  const isEmptyColumn = emptyCellList.length === dataList.length;

  if (!column.field) {
    color = STATUS_COLORS.NORMAL;
  } else if (isError) {
    color = STATUS_COLORS.ERROR;
  } else {
    color = STATUS_COLORS.SUCCESS;
  }

  return {
    ...column,
    color,
    isError,
    isEmptyColumn,
  };
};

export const getColumnsByCategoryType = (categoryTypeId: string) => {
  const list = COLUMN_DETAILS.filter(({ showInCategories }) => showInCategories.includes(categoryTypeId)).map(
    ({ title, field, isExtra, mandatoryInCategories, defaultValue, mandatoryDefaultValue }) => {
      const dataType = getDataType(field);
      const isMandatory = mandatoryInCategories.includes(categoryTypeId);
      let mappedDefaultValue = defaultValue;
      if (isMandatory && mandatoryDefaultValue !== undefined) {
        mappedDefaultValue = mandatoryDefaultValue;
      }

      if (!mappedDefaultValue === undefined && dataType === 'boolean') {
        if (isMandatory) {
          mappedDefaultValue = 'false';
        }
      }
      const column: IImportColumn = {
        _id: uuid(),
        title,
        field,
        isExtra,
        isMandatory,
        isMissing: false,
        dataType,
        isEnumType: checkEnumType(field),
        defaultValue: mappedDefaultValue,
      };
      return column;
    }
  );

  return list;
};

export const insertColumnList = (columnList: IImportColumn[], additionalColumnList: IImportColumn[], index: number) => {
  let columnIndex = index;
  if (columnIndex < 0) columnIndex = 0;

  return [...columnList.slice(0, columnIndex), ...additionalColumnList, ...columnList.slice(columnIndex)];
};

export const updateMandatoryColumns = (params: { columnList: IImportColumn[]; dataList: IProductExcel[] }) => {
  const { columnList, dataList } = params;
  let newColumnList = _.cloneDeep(columnList);

  // Nutritional
  const kjColumn = columnList.find(({ field }) => field === 'energi_kj');
  const kcalColumn = columnList.find(({ field }) => field === 'energi_kcal');

  if (kjColumn && kcalColumn) {
    const isHasKjValue = dataList.some(data => !isEmptyCell(kjColumn, data));
    const isHasKcalValue = dataList.some(data => !isEmptyCell(kcalColumn, data));

    if (isHasKjValue || isHasKcalValue) {
      const fieldList = ['fett', 'mattatFett', 'kolhydrat', 'sockerarter', 'protein', 'salt'];
      newColumnList = newColumnList.map(column => {
        if (!fieldList.includes(column.field)) return column;
        return { ...column, isMandatory: true };
      });
    }
  }

  // Signal Words
  const signalWords = ['fara', 'varning'];
  const hasCorrectSignalWord = dataList.some(data => {
    return signalWords.includes(String(data.signalWords || '').toLowerCase());
  });

  const fieldList = ['hazardSymbols', 'hazardStatements', 'securityNotices'];
  newColumnList = newColumnList.map(column => {
    if (!fieldList.includes(column.field)) return column;
    return { ...column, isMandatory: hasCorrectSignalWord };
  });

  return newColumnList;
};

export const removeUnnecessaryColumns = (params: { productType: string; columnList: IImportColumn[] }) => {
  const { productType, columnList } = params;
  let newColumnList = _.cloneDeep(columnList);

  // 'Nettokvantitet' and 'Enhet'
  let removingFields: string[] = [];
  if (productType === NON_FOOD_CATEGORY_ID) {
    removingFields = ['descriptive_size_amount_extra', 'animalFoodIngredients'];
  } else {
    removingFields = ['non_food_ingredients'];
  }
  newColumnList = newColumnList.filter(({ field }) => !removingFields.includes(field));

  return newColumnList;
};

export const getFirstQuestionColumn = (columnList: IImportColumn[]) => {
  return COLUMN_DETAILS.find(({ field }) => {
    return QUESTION_FIELDS.includes(field) && columnList.some(column => column.field === field);
  });
};
