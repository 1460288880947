import { FC, createContext, useMemo, useState } from 'react';

export type TUploadedProductStatus = { dataId: string; productId?: string; error?: string };

export type TLayoutContextValue = {
  isCheckingProducer: boolean;
  setCheckingProducer: React.Dispatch<React.SetStateAction<boolean>>;
};

export const contextDefaultValue: TLayoutContextValue = {
  isCheckingProducer: false,
  setCheckingProducer: () => {},
};

export const LayoutContext = createContext<TLayoutContextValue>(contextDefaultValue);

export const LayoutProvider: FC = ({ children }) => {
  const [isCheckingProducer, setCheckingProducer] = useState(false);

  const value = useMemo(() => ({ isCheckingProducer, setCheckingProducer }), [isCheckingProducer, setCheckingProducer]);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};
