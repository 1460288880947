import IProduct from '../../../../models/product';
import FloatInput from '../FloatInput';

interface IQtyOfBaseItemFieldsProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const QtyOfBaseItemFields = ({ state, setState }: IQtyOfBaseItemFieldsProps) => {
  // Distributionsförpackning
  const isStorePack = state.storePackOrderableUnit || state.storePackDespatchUnit;
  // Transportförpackning
  const isTransport = state.transportOrderableUnit || state.transportDespatchUnit;
  // Pall
  const isPallet = state.palletOrderableUnit || state.palletDespatchUnit;
  // Distributionsförpackning + Transportförpackning ++ Pall
  const isAll = isStorePack && isTransport && isPallet;

  return (
    <>
      {isStorePack && (
        <FloatInput
          name="consumer_size_to_order_size_ratio"
          placeholder="Ex. 24"
          state={state}
          setState={setState}
          dense={false}
          withLabel
          disabled={state?.isMagazine}
          labelOptions={{
            heading: 'B-packstorlek, antal konsumentförpackningar per distributionsförpackning (kfp/dfp)',
            text: 'Ange hur många konsumentförpackningar som packas i en distributionsförpackning. Används vid orderläggning.',
            required: true,
          }}
        />
      )}

      {isStorePack && isPallet && (
        <FloatInput
          withLabel
          dense={false}
          name="palletQtyOfStorePack"
          placeholder="Ex. 100"
          labelOptions={{
            heading: 'Antal distributionsförpackningar per pall (dfp/pall)',
            text: 'Ange hur många distributionsförpackningar som packas på en pall. Används vid orderläggning.',
          }}
          state={state}
          setState={setState}
        />
      )}

      {isStorePack && isTransport && (
        <FloatInput
          withLabel
          dense={false}
          name="transportQtyOfNextLowerItem"
          placeholder="Ex. 10"
          labelOptions={{
            heading: 'Antal distributionsförpackningar per transportförpackning (dfp/tfp)',
            text: 'Ange hur många distributionsförpackningar som packas i en transportförpackning. Används vid orderläggning.',
          }}
          state={state}
          setState={setState}
        />
      )}

      {!isAll && isTransport && isPallet && (
        <FloatInput
          withLabel
          dense={false}
          name="palletQtyOfNextLowerItem"
          placeholder="Ex. 10"
          labelOptions={{
            heading: 'Antal transportförpackningar per pall (tfp/pall)',
            text: 'Ange hur många transportförpackningar som packas på en pall. Används vid orderläggning.',
          }}
          state={state}
          setState={setState}
        />
      )}

      {!isAll && isTransport && (
        <FloatInput
          withLabel
          dense={false}
          name="transportQtyOfBaseItem"
          placeholder="Ex. 240"
          labelOptions={{
            heading: 'Antal konsumentförpackningar per transportförpackning (kfp/tfp)',
            text: 'Ange hur många konsumentförpackningar som packas i en transportförpackning. Används vid orderläggning.',
          }}
          state={state}
          setState={setState}
        />
      )}

      {!isAll && isPallet && (
        <FloatInput
          withLabel
          dense={false}
          name="palletQtyOfBaseItem"
          placeholder="Ex. 2400"
          labelOptions={{
            heading: 'Antal konsumentförpackningar per pall (kfp/pall)',
            text: 'Ange hur många konsumentförpackningar som packas på en pall. Används vid orderläggning.',
          }}
          state={state}
          setState={setState}
        />
      )}
    </>
  );
};

export default QtyOfBaseItemFields;
