import { countries } from "../../../../constants/domain";
import IProduct from "../../../../models/product";
import ListDropDown from "../../../inputfields/ListDropDown";
import DetailedInputLabel from '../../../inputfields/InputLabel';
import { isMedical } from "./category";
import { IFoodlaCategoryOption } from "models/category";
import ThreeWaySwitch from 'components/inputfields/ThreeWaySwitch';

interface ICountryOfOriginProps {
  approve: boolean;
  state: IProduct;
  setState: (state: Partial<IProduct>) => void;
  rootCategory?: IFoodlaCategoryOption;
}

const data = countries
.sort((a, b) => {
  if (a.start) {
    return -1;
  }
  if (!a.start && b.start) {
    return 1;
  }
  return ('' + a.sv).localeCompare(b.sv);
})
.map((c) => ({
  value: c.code,
  displayName: c.sv,
}));

export const CountryOfManufacturing = ({ approve, state, setState, rootCategory }: ICountryOfOriginProps) => {
  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const stateName = event.target.name;
    let stateValue = event.target.value;

    console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
    const val = !stateValue || stateValue === '' ? null : stateValue;
    stateName && setState({ ...state, [stateName]: val });
  };
  return (
    <>
      <DetailedInputLabel
        id="country_of_manufacturing"
        heading="Tillverkningsland"
        text="Artikelns tillverkningsland är det land där den genomgick den sista väsentliga bearbetningen."
        // link={
        // 'https://eur-lex.europa.eu/legal-content/SV/TXT/PDF/?uri=CELEX:02008R1333-20191028&qid=1583398059728&from=SV'
        // }
        required={isMedical(state?.foodlaCategory, rootCategory)}
      />
      <div style={{ display: 'flex', flex: 1 }}>
        <ListDropDown
          // label="Tillverkningsland"
          showSearch
          listData={data}
          value={state.country_of_manufacturing}
          targetName="country_of_manufacturing"
          handleChange={handleChange}
          disabled={state?.isMagazine}
          displayEmpty={true}
          style={{ flexGrow: 1, overflow: 'hidden' }}
        />
        {approve && state?.approve?.find(x => x.field === 'country_of_manufacturing') && (
          <ThreeWaySwitch
            state={state}
            style={{ height: 56 }}
            setState={setState}
            name="country_of_manufacturing"
          />
        )}
      </div>
    </>
  );
};

export default CountryOfManufacturing;
