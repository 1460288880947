import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Query } from 'react-apollo';

import ProductUpdateForm from '../../components/product/ProductUpdateForm';
import ProductNavigator from '../../components/product/ProductUpdateForm/product-navigator';

import { PRODUCT_INFO_FOR_UPDATE_QUERY } from '../../graphql/queries';
import { APPLICATION_ROLES } from '../../components/constants';
import { transformCategoryRead } from 'utils/products';
import DraftProductAlert from 'components/product/ProductUpdateForm/DraftProductAlert';

const ApprovalForm = ({ match }) => {
    const { id } = match.params;
    return (
        <>
            <Box mb={2}>
              <ProductNavigator id={id} />
            </Box>

            <Query query={PRODUCT_INFO_FOR_UPDATE_QUERY} variables={{ id }}>
                {({ data, loading, error, networkStatus, refetch }) => {
                    return (
                        <div>
                            <DraftProductAlert product={data?.product} />

                            {loading && <CircularProgress size={70} variant="indeterminate" />}
                            {!loading && (
                                <ProductUpdateForm
                                    key={id}
                                    productLoading={loading}
                                    userRole={APPLICATION_ROLES.SUPERUSER}
                                    product={transformCategoryRead(data?.product)}
                                    approve={true}
                                    refetchQuery={refetch}
                                    refetchVariables={{ id }}                                                                        
                                />
                            )}
                        </div>
                    );
                }}
            </Query>

            <Box mt={2}>
              <ProductNavigator id={id} />
            </Box>
        </>
    );
};

export default ApprovalForm;
