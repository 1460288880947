import React, { FC } from 'react';

const HealthHazard: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 170 170"
    fill="none"
    {...props}
  >
    <g clipPath="url(#HealthHazard_1)">
      <path
        fill="#EB1F26"
        d="m161.515 84.575-76.35 76.35-76.349-76.35 76.348-76.35 76.351 76.35Z"
      />
      <path
        stroke="#ED1C24"
        strokeWidth={0.905}
        d="m161.515 84.575-76.35 76.35-76.349-76.35 76.348-76.35 76.351 76.35Z"
      />
      <path
        fill="#fff"
        d="m149.294 84.58-64.13 64.129-64.129-64.13 64.13-64.128 64.129 64.129Z"
      />
      <mask
        id="HealthHazard_2"
        width={157}
        height={157}
        x={7}
        y={6}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M7.007 162.735h156.318V6.417H7.007v156.318Z" />
      </mask>
      <g fill="#231F20" mask="url(#HealthHazard_2)">
        <path d="M85.346 45.4c-3.679 0-9.196 1.288-10.485 5.701-1.288 4.416 4.782 36.974 5.334 39.366.552 2.39 3.127 3.678 5.151 3.864 2.023.182 4.967-1.288 5.335-2.578.368-1.286 5.333-31.452 5.518-34.213.184-2.759 1.287-6.805-1.656-9.197-2.942-2.391-5.334-2.943-9.197-2.943ZM94.08 108.31c0 4.977-3.789 9.013-8.462 9.013-4.675 0-8.463-4.036-8.463-9.013 0-4.978 3.788-9.014 8.463-9.014 4.673 0 8.463 4.036 8.463 9.014Z" />
      </g>
    </g>
    <defs>
      <clipPath id="HealthHazard_1">
        <path fill="#fff" d="M0 0h170v170H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default HealthHazard;
