import { Tooltip, TooltipProps, styled } from '@material-ui/core';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  '& .MuiTooltip-tooltip': {
    padding: '8px 16px',
    fontSize: 13,
    backgroundColor: '#FFFFFF',
    color: '#383D52',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  '& .MuiTooltip-arrow': {
    color: '#FFFFFF',
  },
}));

export default LightTooltip;
