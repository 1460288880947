import React from 'react';
import {useMutation} from '@apollo/react-hooks';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from '@material-ui/core';

import {MUTATION_REMOVE_PRODUCT_FROM_EXPORT} from '../../../../graphql/mutations';
import {TRemoveProductDialogProps} from '../types';

const RemoveProductFromExportsDialog = ({
    productToRemoveId,
    closeRemoveProductModal,
    setDeleted,
}: TRemoveProductDialogProps) => {
    const [removeProductFromExport, {loading}] = useMutation(MUTATION_REMOVE_PRODUCT_FROM_EXPORT, {
        variables: {
            id: productToRemoveId,
        },
    });

    const removeProductHandlerKey = async () => {
        if (productToRemoveId) {
            try {
                await removeProductFromExport();
                setDeleted(true);
            } finally {
                closeRemoveProductModal();
            }
        }
    };

    return (
        <Dialog
            open={!!productToRemoveId}
            onClose={closeRemoveProductModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Remove product</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you want to remove product?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeRemoveProductModal}
                    color="primary">
                    No
                </Button>
                <Button
                    disabled={loading}
                    onClick={removeProductHandlerKey}
                    color="primary"
                    autoFocus
                >
                    {loading ? 'Removing...' : 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveProductFromExportsDialog;
