import React from 'react';
import permissions from '../permissions';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from '../components/NotFound';
import GoogleAnalyticsTracker from 'GoogleAnalyticsTracker';

const withRoleRoutes = WrappedComponent => {
    return props => {
        const rolePermissions = permissions[props.role];
        let routes;
        if (Array.isArray(rolePermissions) && rolePermissions.length) {
            routes = rolePermissions.filter(rp => rp.route).map(rp => rp.route)
        }

        return (
            <Router>
                <GoogleAnalyticsTracker />
                <WrappedComponent {...props} >
                    <Switch>
                        {
                            routes.map(route => {
                                return (
                                    <Route path={route.path} exact={route.exact} component={route.component} key={route.path} />
                                );
                            })
                        }
                        <Route path="/404" component={NotFound} />
                        <Route component={NotFound} />
                    </Switch>
                </WrappedComponent>
            </Router>
        );
    }
};

export default withRoleRoutes;
