import { FC, useEffect, useMemo } from 'react';

import IProduct from 'models/product';
import useControlContext from '../../hooks/useControlContext';
import useDataContext from '../../hooks/useDataContext';

import { Box, Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
  complianceBiocid,
  complianceBiocidalTreated,
  complianceMetalsSkinContact,
  complianceSunscreenRegulation,
  complianceToyDirective,
  confirmGuidanceSwedishElectronics,
} from 'components/constants-ts';
import { ALL_GROUP_CATEGORY_IDS, GROUP_CATEGORY_IDS } from '../../constants';

interface FieldProps {
  field: keyof IProduct;
  label: string;
  hasNullValue?: boolean;
  defaultValue?: string | boolean;
  checkboxOptions: { value: boolean | string | null; displayName: string }[];
  showInCategories: string[];
}

const defaultCheckboxOptions = [
  { displayName: 'Ja', value: true },
  { displayName: 'Nej', value: false },
  { displayName: 'Ej valt', value: null },
];

const BooleanRadioField: FC<FieldProps> = ({
  field,
  label,
  hasNullValue,
  defaultValue,
  showInCategories,
  checkboxOptions,
}) => {
  const { selectedCategoryTypeId } = useControlContext();
  const { commonQuestionData, setCommonQuestionData } = useDataContext();

  const isShow = useMemo(
    () => showInCategories.includes(selectedCategoryTypeId),
    [selectedCategoryTypeId, showInCategories]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = checkboxOptions.find(option => String(option.value) === event.target.value);
    if (option) {
      setCommonQuestionData(state => ({ ...state, [field]: option.value }));
    }
  };

  useEffect(() => {
    if (defaultValue === undefined || !isShow) return;
    setCommonQuestionData(state => {
      if (state[field] !== undefined) return state;
      return { ...state, [field]: defaultValue };
    });
  }, [isShow, field, defaultValue, setCommonQuestionData]);

  if (!isShow) return null;

  return (
    <Box display="flex" flexDirection="column">
      <Box fontSize={16}>{label}</Box>
      <RadioGroup
        value={String(commonQuestionData?.[field] ?? null)}
        style={{ flexDirection: 'row', marginLeft: 32, gap: 16 }}
        onChange={handleChange}
      >
        {checkboxOptions.slice(0, hasNullValue ? 3 : 2).map(({ value, displayName }, index) => (
          <FormControlLabel key={index} label={displayName} value={String(value)} control={<Radio color="primary" />} />
        ))}
      </RadioGroup>
    </Box>
  );
};

const CommonQuestions: FC = () => {
  return (
    <Box display="flex" flexDirection="column" gridGap={8} mt={3}>
      <Box mb={1}>
        <h3>Kvalitets frågor som gäller alla dina produkter</h3>
        <Divider style={{}} />
      </Box>

      <BooleanRadioField
        field="complianceEg12232009"
        label="Överenstämmer dina produkter men kraven i kosmetikaförordningen (EG) 1223/2009?"
        defaultValue={false}
        checkboxOptions={defaultCheckboxOptions}
        showInCategories={[GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER, GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL]}
      />
      <BooleanRadioField
        hasNullValue
        field="complianceSunscreenRegulation"
        label="Om produkterna är solskyddsmedel produkter, uppfyller de preciserade reglerna?"
        defaultValue="NO"
        checkboxOptions={complianceSunscreenRegulation}
        showInCategories={[GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER, GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL]}
      />
      <BooleanRadioField
        field="complianceReach19072006"
        label="Överensstämmer dina produkter med REACH- förordningen (EG) 1907/2006?"
        defaultValue={false}
        checkboxOptions={defaultCheckboxOptions}
        showInCategories={[
          GROUP_CATEGORY_IDS.LEKSAK,
          GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
          GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
        ]}
      />
      <BooleanRadioField
        hasNullValue
        field="complianceBiocid"
        label="Om produkterna är biocidprodukter, uppfyller de kraven i EUs biocidförordning 528/2012?"
        defaultValue="NO"
        checkboxOptions={complianceBiocid}
        showInCategories={[
          GROUP_CATEGORY_IDS.LEKSAK,
          GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
          GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
        ]}
      />
      <BooleanRadioField
        field="complianceProductSafety2004451"
        label="Följer produkterna Produktsäkerhetslagen 2004:451?"
        defaultValue={false}
        checkboxOptions={defaultCheckboxOptions}
        showInCategories={[
          GROUP_CATEGORY_IDS.LEKSAK,
          GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
          GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
          GROUP_CATEGORY_IDS.VAROR,
        ]}
      />
      <BooleanRadioField
        hasNullValue
        field="complianceBiocidalTreated"
        label="Om produkterna är biocidbehandlade, uppfyller de kraven i EUs biocidförordning 528/2012?"
        defaultValue="NO"
        checkboxOptions={complianceBiocidalTreated}
        showInCategories={[
          GROUP_CATEGORY_IDS.LEKSAK,
          GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
          GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
          GROUP_CATEGORY_IDS.VAROR,
          GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
        ]}
      />
      <BooleanRadioField
        field="complianceMetalsSkinContact"
        label="Uppfyller produkterna reglerna om skadliga tungmetaller (t.ex. nickel, kadmium eller bly) i bilaga XVII till Reach-förordningen?"
        defaultValue="NO"
        checkboxOptions={complianceMetalsSkinContact}
        showInCategories={[GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER]}
      />
      <BooleanRadioField
        field="complianceMedicalTechProducts"
        label="Överensstämmer medicintekniska produkterna kraven i förordningen (EU) 2017/745 eller (EU) 2017/746?"
        defaultValue={false}
        checkboxOptions={defaultCheckboxOptions}
        showInCategories={[GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER]}
      />
      <BooleanRadioField
        hasNullValue
        field="confirmGuidanceSwedishElectronics"
        label="Om produkterna är elektriska/elektroniska, finns det svensk bruksanvisning bifogad på förpackningen?"
        defaultValue="NO"
        checkboxOptions={confirmGuidanceSwedishElectronics}
        showInCategories={[
          GROUP_CATEGORY_IDS.LEKSAK,
          GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
          GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
        ]}
      />
      <BooleanRadioField
        hasNullValue
        field="complianceToyDirective"
        label="Uppfyller produkterna den kraven i leksaksdirektivet 2009/48/EG?"
        defaultValue="NO"
        checkboxOptions={complianceToyDirective}
        showInCategories={[GROUP_CATEGORY_IDS.LEKSAK, GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER]}
      />
      <BooleanRadioField
        field="complianceHaccp"
        label="Är foder/fodertillskott framställt i enlighet med HACCP?"
        defaultValue={false}
        checkboxOptions={defaultCheckboxOptions}
        showInCategories={[
          GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
          GROUP_CATEGORY_IDS.FODERRAVARA,
          GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
        ]}
      />
      <BooleanRadioField
        field="plasticTrayPackaging"
        label="Levereras produkterna i plasttråg?"
        defaultValue={false}
        checkboxOptions={defaultCheckboxOptions}
        showInCategories={ALL_GROUP_CATEGORY_IDS}
      />
    </Box>
  );
};

export default CommonQuestions;
