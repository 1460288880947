import { FC, useMemo } from 'react';

import IProduct from 'models/product';

import { Box, Divider, FormControl, IconButton, RadioGroup, makeStyles } from '@material-ui/core';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import InfoIcon from 'components/icons/InfoIcon';
import Pagination from '../../components/Pagination';
import FormControlCheckbox from '../../components/FormControlCheckbox';
import ErrorSwitch from '../../components/ErrorSwitch';
import ListDropDown from 'components/inputfields/ListDropDown';
import LinearProgress from '../../components/LinearProgress';
import RegexFilter from './RegexFilter';

import useControlContext from '../../hooks/useControlContext';

import { APPLICATION_ROLES, NON_FOOD_CATEGORY_ID, FOOD_CATEGORY_ID, SUPERUSER_APPROVAL } from 'components/constants-ts';
import { IImportColumn, IProductExcel } from '../../types';
import { PARSE_IMAGE_BY } from '../../constants';
import MoveToQuestionButton from './MoveToQuestionButton';

const useCustomStyles = makeStyles(theme => ({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}));

interface HeaderProps {
  showErrorOnly: boolean;
  errorCount: number;
  columnList: IImportColumn[];
  dataList: IProductExcel[];
  onShowErrorChange: (bol: boolean) => void;
}

const Header: FC<HeaderProps> = ({ errorCount, showErrorOnly, columnList, dataList, onShowErrorChange }) => {
  const customClasses = useCustomStyles();
  const {
    userRole,
    step,
    productType,
    producerList,
    producerData,
    imageSettings,
    uploadProgress,
    errorList,
    onStepChange,
    onProducerIdChange,
    onProductTypeChange,
    onImageSettingChange,
  } = useControlContext();

  const productTypeOptions = [
    { value: FOOD_CATEGORY_ID, displayName: 'Food' },
    { value: NON_FOOD_CATEGORY_ID, displayName: 'Non Food' },
  ];

  const producerOptions = useMemo(() => {
    const optionList = producerList.map(({ id, status, name, username }) => ({
      key: id,
      value: id,
      displayName: `${name} (${username})`.trim(),
      style: { color: status === SUPERUSER_APPROVAL.ADMIN_APPROVED ? 'inherit' : 'gray' },
    }));

    return optionList.sort((prev, next) => (prev.displayName > next.displayName ? 1 : -1));
  }, [producerList]);

  const count = dataList.length;
  const isProducerRole = userRole === APPLICATION_ROLES.PRODUCER;
  const isStoreRole = userRole === APPLICATION_ROLES.STORE;

  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <Box display="flex" alignItems="center" gridGap={16}>
        <IconButton style={{ padding: 4, marginTop: 18, marginBottom: 'auto' }} onClick={() => onStepChange(step - 1)}>
          <ArrowLeftIcon />
        </IconButton>

        <Box flexGrow={1} flexShrink={0} mb="auto">
          <Box mb={1} sx={{ fontSize: 10, color: '#737DA0', fontWeight: 600 }}>
            Steg 4 av 4
          </Box>
          <Box display="flex" justifyItems="center" gridGap={8} mb={1} sx={{ fontSize: 20, lineHeight: 1 }}>
            Data Reviewing
            <InfoIcon />
          </Box>
          <Box mb={1} sx={{ fontSize: 14, lineHeight: 1 }}>
            {`${count} ${count > 1 ? 'Records' : 'Record'}`}
          </Box>
        </Box>

        {!!errorCount && (
          <>
            <Divider orientation="vertical" flexItem style={{ marginTop: 10, marginBottom: 10 }} />
            <Box flexGrow={1} flexShrink={0}>
              <ErrorSwitch
                errorCount={errorCount}
                label="Visa bara produkter med errors"
                checked={showErrorOnly}
                onChange={onShowErrorChange}
              />
            </Box>
          </>
        )}

        {!isStoreRole && !isProducerRole && (
          <>
            <Divider orientation="vertical" flexItem style={{ marginTop: 10, marginBottom: 10 }} />
            <Box flexGrow={1} display="flex" flexDirection="column">
              <Box sx={{ fontSize: 14, fontWeight: 500 }}>Parse image filename by</Box>
              <FormControl style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
                <RadioGroup
                  value={imageSettings.parseImageBy}
                  onChange={(_, value) => onImageSettingChange({ parseImageBy: value as keyof IProduct })}
                  style={{ flexDirection: 'row', gridGap: 16 }}
                >
                  <FormControlCheckbox type="radio" label="EAN" value={PARSE_IMAGE_BY.EAN} />
                  <FormControlCheckbox type="radio" label="Artikel" value={PARSE_IMAGE_BY.ARTICLE} />
                </RadioGroup>
              </FormControl>
            </Box>
          </>
        )}

        <Divider orientation="vertical" flexItem style={{ marginTop: 10, marginBottom: 10 }} />
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Box sx={{ fontSize: 14, fontWeight: 500 }}>Image AI</Box>
          <FormControl style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
            <FormControlCheckbox
              label="Ignore AI"
              checked={imageSettings.aiIgnore}
              onChange={event =>
                onImageSettingChange({
                  aiIgnore: event.target.checked,
                  aiMain: event.target.checked ? false : imageSettings.aiMain,
                })
              }
            />
            <FormControlCheckbox
              label="AI Main Image"
              disabled={imageSettings.aiIgnore}
              checked={imageSettings.aiMain}
              onChange={event => onImageSettingChange({ aiMain: event.target.checked })}
            />
          </FormControl>
        </Box>

        <Divider orientation="vertical" flexItem style={{ marginTop: 10, marginBottom: 10 }} />
        <Pagination />
      </Box>

      {!isStoreRole && !isProducerRole && (
        <Box className={customClasses.actionsWrapper} gridGap={8}>
          <Box flexGrow={1} display="flex" gridGap={16}>
            <Box flexShrink={0} flexBasis={150}>
              <ListDropDown
                label="Produkt typ"
                size="small"
                value={productType}
                listData={productTypeOptions}
                handleChange={event => onProductTypeChange(event.target.value as string)}
              />
            </Box>
            <Box flexShrink={0} flexBasis={200}>
              <ListDropDown
                label="Producent namn"
                size="small"
                value={producerData.id || ''}
                listData={producerOptions}
                showSearch
                handleChange={event => onProducerIdChange(event.target.value as string)}
              />
            </Box>
            <RegexFilter />
          </Box>
        </Box>
      )}

      {!!uploadProgress.total && (
        <Box display="flex" flexDirection="column" gridGap={8}>
          <Box flexGrow={1} flexBasis={0} display="flex" flexDirection="column" gridGap={8}>
            <Box display="flex" justifyContent="space-between" gridGap={16}>
              <span>{uploadProgress.uploadedTotal !== uploadProgress.total ? 'Loading Images' : 'Loaded Images'}</span>
              <Box display="flex" gridGap={8}>
                {!!uploadProgress.failedTotal && (
                  <Box sx={{ color: 'red' }}>(Failed: {uploadProgress.failedTotal})</Box>
                )}
                <span>{`${uploadProgress.uploadedTotal}/${uploadProgress.total}`}</span>
              </Box>
            </Box>
            <LinearProgress
              variant="determinate"
              value={Math.ceil((uploadProgress.uploadedTotal / uploadProgress.total) * 100)}
            />
          </Box>
        </Box>
      )}

      {errorList
        .filter(error => error.step === step)
        .map(({ message }, index) => (
          <Box key={index} sx={{ color: 'red', fontWeight: 700 }}>
            {message}
          </Box>
        ))}

      <MoveToQuestionButton columnList={columnList} />
    </Box>
  );
};

export default Header;
