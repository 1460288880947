import { createContext } from 'react';

import { IImageFileSource, IImportColumn, IProductExcel } from '../types';
import { TExcelDataRow } from 'models/excel';
import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';

export type TUploadedProductStatus = { dataId: string; productId?: string; error?: string };

export type TDataContextValue = {
  // excel
  rootCategory: IFoodlaCategoryOption;
  allCategories: IFoodlaCategoryOption[];

  // product data for Checkbox foolter
  commonQuestionData: IProduct;
  setCommonQuestionData: React.Dispatch<React.SetStateAction<IProduct>>;

  isLoadedImageFromIndexDB: boolean;
  isLoadedSavedProduct: boolean;
  setLoadedSavedProduct: React.Dispatch<React.SetStateAction<boolean>>;
  excelFile: File | null;
  setExcelFile: (file: File | null) => void;
  excelFileData: TExcelDataRow[];
  setExcelFileData: (list: TExcelDataRow[]) => void;
  dataList: IProductExcel[];
  setDataList: React.Dispatch<React.SetStateAction<IProductExcel[]>>;
  onChangeDataValue: (id: string, field: keyof IProduct, value: string) => void;
  onChangeAllDataValue: (field: keyof IProduct, value: string, options?: { idList?: string[] }) => void;
  onRemoveData: (id: string) => void;
  isUploadedSuccessfully: boolean;
  uploadedProductStatusSet: Record<string, TUploadedProductStatus>;
  onUploadedProductStatusSetChange: (set: Record<string, TUploadedProductStatus>) => void;
  excelColumnList: IImportColumn[];
  defaultExcelColumnList: IImportColumn[];
  onRemoveColumn: (id: string) => void;
  onUpdateColumnField: (id: string, field: keyof IProduct) => void;
  // product images
  rejectedImageFiles: File[];
  setRejectedImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
  editingImageProductData: IProductExcel | null;
  setEditingImageProductData: React.Dispatch<React.SetStateAction<IProductExcel | null>>;
  matchedImageFileDataList: IImageFileSource[];
  allImageFileDataList: IImageFileSource[];
  onAllImageFileDataChange: (callback: (oldImageDataList: IImageFileSource[]) => IImageFileSource[]) => Promise<void>;
  productImageDataSet: Record<string, IImageFileSource[]>;
  setProductImageDataSet: React.Dispatch<React.SetStateAction<Record<string, IImageFileSource[]>>>;
  onProductImageChange: (fileList: File[]) => void;
  tempApproveEanStatus: Record<string, string>;
  setTempApproveEanStatus: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  isReseted: boolean;
  onReset: (isNotAll?: boolean) => void;
};

export const contextDefaultValue: TDataContextValue = {
  rootCategory: { id: 'root', name: 'Root', children: [] },
  allCategories: [],

  commonQuestionData: {},
  setCommonQuestionData: () => {},

  isLoadedImageFromIndexDB: false,
  isLoadedSavedProduct: false,
  setLoadedSavedProduct: () => {},
  excelFile: null,
  setExcelFile: () => {},
  excelFileData: [],
  setExcelFileData: () => {},
  dataList: [],
  setDataList: () => {},
  onChangeDataValue: () => {},
  onChangeAllDataValue: () => {},
  onRemoveData: () => {},
  isUploadedSuccessfully: false,
  uploadedProductStatusSet: {},
  onUploadedProductStatusSetChange: () => {},
  excelColumnList: [],
  defaultExcelColumnList: [],
  onRemoveColumn: () => {},
  onUpdateColumnField: () => {},
  rejectedImageFiles: [],
  setRejectedImageFiles: () => {},
  editingImageProductData: null,
  setEditingImageProductData: () => {},
  matchedImageFileDataList: [],
  allImageFileDataList: [],
  onAllImageFileDataChange: () => Promise.resolve(),
  productImageDataSet: {},
  setProductImageDataSet: () => {},
  onProductImageChange: () => {},
  tempApproveEanStatus: {},
  setTempApproveEanStatus: () => {},
  isReseted: false,
  onReset: () => {},
};

export const DataContext = createContext<TDataContextValue>(contextDefaultValue);
