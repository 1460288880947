import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import IProduct from 'models/product';
import { QUERY_GET_PRODUCTS_FOR_UPDATE_BY_FILTER } from 'graphql/queries';
import { transformCategoryRead } from 'utils/products';

function useProductData() {
  const apoloClient = useApolloClient();

  const [isLoadingProductsByEans, setLoadingProductsByEans] = useState(false);

  const getProductsByEans = useCallback(
    async (eans: string[]): Promise<IProduct[]> => {
      setLoadingProductsByEans(true);
      try {
        const response = await apoloClient.query<{ productsBy: { data: IProduct[] } }>({
          fetchPolicy: 'no-cache',
          query: QUERY_GET_PRODUCTS_FOR_UPDATE_BY_FILTER,
          variables: { productFilter: { eans } },
        });
        return (response.data.productsBy.data || []).map(transformCategoryRead);
      } catch {
        return [];
      } finally {
        setLoadingProductsByEans(false);
      }
    },
    [apoloClient]
  );

  return { isLoadingProductsByEans, getProductsByEans };
}

export default useProductData;
