import { isNil, path } from "ramda";
import { Link as RouterLink } from 'react-router-dom';

import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import IProduct from "models/product";
import { IMAGE_SIZES, getImageWithSizeSuffix } from "utils/images2";
import COLORS from "constants/colors";
import { GreenBlinkingBadge, RedBlinkingBadge } from "components/badges/badges";
import { isDefined } from "utils/helpers-ts";
import { SUPERUSER_APPROVAL } from "components/constants-ts";

interface IProductListItemProps {
  product: IProduct;
  showNotApproved?: boolean;
  errorField?: keyof IProduct|(keyof IProduct)[];
}
  
const ProductListItem = ({ product, showNotApproved, errorField }: IProductListItemProps) => {
  const producerStatus = path(['producer', 'status'], product);
  const producerNotAccepted = isDefined(producerStatus) && producerStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL;
  if (!showNotApproved && producerNotAccepted) return <></>;

  const primaryTitle = product?.title || 'NO TITLE';
  // let secondaryTitle = product?.producer?.name || 'NO PRODUCER NAME';
  const linkTo = `/superuser/products/${product.id}/approval`;

  const image = product.image_src && isDefined(product.image_src) ? getImageWithSizeSuffix(product.image_src, IMAGE_SIZES.XSMALL) : '';
  // if (producerNotAccepted) secondaryTitle = concat(secondaryTitle, ' (PRODUCER NOT ENABLED)');
  let secondaryTitle = <></>;
  if (errorField && product) {
    let value: string | undefined;
    if (Array.isArray(errorField)) {
      if (errorField.length > 0) {
        value = errorField.map((field) => product[field]).join(', ');
      }
    } else {
      const val = product[errorField];
      if (!isNil(val)) {
        if (typeof val === 'object' && val !== null) {
          value = JSON.stringify(val);
        } else {
          value = val?.toString();
        }
      }
    }
    if (value && value.trim() !== '') {
      secondaryTitle = <div style={{color: COLORS.mainRed}}>{value}</div>;
    }
  }
  return (
    <>
      <Link variant="inherit" underline="none" color="inherit" component={RouterLink} to={linkTo}>
        <ListItem>
          <ListItemAvatar>
            {producerNotAccepted ? (
              <RedBlinkingBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <img src={image} alt="P" />
                </Avatar>
              </RedBlinkingBadge>
            ) : (
              <GreenBlinkingBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <img src={image} alt="P" />
                </Avatar>
              </GreenBlinkingBadge>
            )}
          </ListItemAvatar>
          <ListItemText
            style={producerNotAccepted ? { color: COLORS.mainRed } : {}}
            primary={primaryTitle}
            secondary={secondaryTitle}
          />
        </ListItem>
      </Link>
      <Divider />
    </>
  );
};

export default ProductListItem;
