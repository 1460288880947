import React, { useEffect, useState } from 'react';

import InputTextField from '../../../inputfields/InputTextField';
import { isFoodCategory } from '../utils';
import { APPLICATION_ROLES } from '../../../constants';
import IProduct from '../../../../models/product';

interface ITitleProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  userRole?: string;
  approve?: boolean;
}

export const Title = ({ userRole, state, setState, approve }: ITitleProps) => {
  const [value, setValue] = useState(state.title || '');

  const isFood = isFoodCategory(state)
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;

  const labelOptionsFood = {
    heading: 'Produktnamn',
    text: 'Namn som beskriver produkten och kommer att visas för konsumenterna.',
    link: 'https://kontrollwiki.livsmedelsverket.se/artikel/40/livsmedlets-beteckning',
    required: true,
  }

  const labelOptionsNonFood = {
    heading: 'Produktnamn / Beteckning',
    text: 'Namn som beskriver vad det är för slags produkt.',
    required: true,
  };

  const handleBlur = () => {
    let titleValue = value;
    if (value.toLowerCase() !== (state.title || '').toLowerCase()) {
      titleValue = value
        .split(' ')
        .map(text => (text[0] || '').toUpperCase() + (text.slice(1) || '').toLowerCase())
        .join(' ');
    }
    setValue(titleValue);
    setState({ ...state, title: titleValue });
  };

  useEffect(() => {
    setValue(state.title || '');
  }, [state.title])

  return (
    <>
      <InputTextField
        inputName="title"
        value={value}
        label={ isFood ? "Ex. Fläskkarre Med Ben Skivad" : "Produktnamn"}
        onChange={event => setValue(event.target.value || '')}
        onBlur={handleBlur}
        withLabel
        disabled={state?.isMagazine}
        setState={setState}
        approve={isSuperuser && approve}
        state={state}
        inputClassName="data-hj-allow"
        labelOptions={isFood ? labelOptionsFood : labelOptionsNonFood}
        // ignoreTabs={true}
      />
    </>
  );
}

export default Title;
