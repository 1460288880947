// Dependencies
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles(theme => ({
    breadcrumb: {
        marginBottom: theme.spacing(2),
    }
}));

interface IBreadCrumb {
    title: string;
    linkTo: string;
}

interface IBreadCrumbsProps {
    label?: string;
    breadCrumbs: IBreadCrumb[];
}

// Component
const BreadCrumbs = ({ breadCrumbs, label }: IBreadCrumbsProps) => {
    const classes = useStyles();
    return (
        <Breadcrumbs
            className={classes.breadcrumb}
            separator="›"
            aria-label="Breadcrumb"
        >
            {breadCrumbs.map((b) => (
                <Link color="inherit" component={RouterLink} to={b.linkTo} key={b.title}>
                    {b.title}
                </Link>
            ))}
            <Typography color="textPrimary">{label}</Typography>
        </Breadcrumbs>
    );
};

// Export
export default BreadCrumbs;
