import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { KeycloakContext } from 'components/Secured';
import { APPLICATION_ROLES } from 'components/constants-ts';

import useUnpaidInvoiceData from 'hooks/useUnpaidInvoiceData';
import { getUserRole } from 'utils/userrole';

const useStyles = makeStyles(() => ({
  cardContent: {
    maxWidth: '900px',
    margin: '0 auto',
    '&:last-child': {
      padding: 16,
    },
  },
  description: {
    textAlign: 'center',
    color: '#E39D20',
  },
  space: {
    marginRight: 4,
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  invoiceAmount: {
    fontWeight: 700,
  },
  invoiceStatus: {
    borderRadius: 3,
    padding: '2px 6px',
    fontSize: 13,
  },
  invoiceStatusPastDue: {
    color: 'rgb(180, 12, 62)',
    backgroundColor: 'rgb(180, 12, 62, 0.1)',
  },
}));

const formatAmount = (amount: number) => {
  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const formatedString = formatter.format(amount).replaceAll(',', '.');
  const numberList = formatedString.split('.');
  const fractionDigits = numberList.pop();
  const integerDigits = numberList.join('.');
  return `${integerDigits},${fractionDigits}`;
};

const UnpaidInvoices = () => {
  const keycloakContext = useContext(KeycloakContext);
  const role = getUserRole(keycloakContext);
  const classes = useStyles();

  const { list: unpaidInvoiceList, loading, error } = useUnpaidInvoiceData();

  if (loading) {
    return (
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  if (!unpaidInvoiceList.length || error) return null;

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container justifyContent="center">
              <Typography className={classes.description} color="textSecondary" variant="subtitle1">
                <span>
                  Du har en eller flera fakturor som har förfallit, vänligen betala snarast möjligt. Du kan hantera dina
                  fakturor här
                </span>
                <span>
                  {role === APPLICATION_ROLES.PRODUCER ? ', ange din mail för fakturering när du loggar in.' : '.'}
                </span>
                <span className={classes.space} />
                <span>Hör av dig till</span>
                <span className={classes.space} />
                <Link underline="always" target="_blank" href="mailto:support@foodla.nu">
                  support@foodla.nu
                </Link>
                <span className={classes.space} />
                <span>om du har någon fundering så hjälper vi dig.</span>
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {role === APPLICATION_ROLES.PRODUCER && (
        <Grid item xs={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="right">
                        AMOUNT
                      </TableCell>
                      <TableCell className={classes.tableCell}>CURRENCY</TableCell>
                      <TableCell className={classes.tableCell}>STATUS</TableCell>
                      <TableCell className={classes.tableCell}>INVOICE NUMBER</TableCell>
                      <TableCell className={classes.tableCell}>DUE DATE</TableCell>
                      <TableCell className={classes.tableCell}>CREATED DATE</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {unpaidInvoiceList.map(invoice => (
                      <TableRow key={invoice.id}>
                        <TableCell className={classes.tableCell} align="right">
                          <span className={classes.invoiceAmount}>
                            {formatAmount((invoice.amount_due || 0) / 100)} {invoice.currency}
                          </span>
                        </TableCell>
                        <TableCell className={classes.tableCell}>{invoice.currency}</TableCell>
                        <TableCell className={classes.tableCell}>
                          <span className={clsx(classes.invoiceStatus, classes.invoiceStatusPastDue)}>Past due</span>
                        </TableCell>
                        <TableCell className={classes.tableCell}>{invoice.number}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {format((invoice.due_date || 0) * 1000, 'dd MMM yyyy')}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {format((invoice.created || 0) * 1000, 'dd MMM yyyy')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default UnpaidInvoices;
