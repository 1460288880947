import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { Box, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const MAX_WIDTH = 'calc(100vw - 80px)';
const MAX_HEIGHT = 'calc(100vh - 150px)';

const ControllButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  background: ${props => (props.color === 'red' ? '#ff0000bf' : '#3f51b5bf')};
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: #fff;
  transition: all 200ms ease-in-out;

  &:hover:not(:disabled) {
    background: ${props => (props.color === 'red' ? '#ff0000' : '#3f51b5')};
    transform: scale(1.1);
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Image = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-width: ${MAX_WIDTH};
  max-height: ${MAX_HEIGHT};
  padding: 5%;
`;

interface ZoomImageProps {
  fullScreen?: boolean;
  src?: string;
  onToggleFullScreen?: (bol: boolean) => void;
}

const ZoomImage: FC<ZoomImageProps> = ({ fullScreen, src, onToggleFullScreen }) => {
  const [loading, setLoading] = useState(false);
  const [canZoomIn, setCanZoomIn] = useState(true);
  const [canZoomOut, setCanZoomOut] = useState(false);

  const imageSrc = useMemo(() => {
    if (!fullScreen) return src;

    try {
      const url = new URL(src || '');
      url.searchParams.delete('h');
      return url.toString();
    } catch {
      return src;
    }
  }, [src, fullScreen]);

  useEffect(() => {
    setLoading(true);
  }, [imageSrc]);

  if (!imageSrc) return null;

  const width = fullScreen ? MAX_WIDTH : 'auto';
  const height = fullScreen ? MAX_HEIGHT : 'auto';
  const zoomPanKey = fullScreen ? 'large' : 'small';

  return (
    <Box
      id="zoom-image"
      maxHeight={height}
      maxWidth={width}
      position="relative"
      border="1px solid black"
      bgcolor="#969696"
      mr={4}
    >
      <TransformWrapper
        key={zoomPanKey}
        initialScale={1}
        minScale={0.7}
        panning={{ velocityDisabled: true }}
        onTransformed={(_, state) => {
          setCanZoomIn(state.scale < 8);
          setCanZoomOut(state.scale > 0.7);
        }}
      >
        {({ zoomIn, zoomOut }) => {
          return (
            <Fragment>
              <Box position="absolute" zIndex={10} top={0} right={-32}>
                <ControllButton disabled={!canZoomIn} onClick={() => zoomIn()}>
                  <AddIcon fontSize="small" />
                </ControllButton>
                <ControllButton disabled={!canZoomOut} color="red" onClick={() => zoomOut()}>
                  <RemoveIcon fontSize="small" />
                </ControllButton>
                {!!onToggleFullScreen && (
                  <ControllButton onClick={() => onToggleFullScreen(!fullScreen)}>
                    {fullScreen ? <FullscreenExitIcon fontSize="small" /> : <FullscreenIcon fontSize="small" />}
                  </ControllButton>
                )}
              </Box>

              <TransformComponent
                key={zoomPanKey}
                wrapperStyle={{ width, height }}
                contentStyle={{ width, height, alignItems: 'center', justifyContent: 'center' }}
              >
                <Image key={zoomPanKey} src={imageSrc} alt="" onLoad={() => setLoading(false)} />
              </TransformComponent>
            </Fragment>
          );
        }}
      </TransformWrapper>

      {fullScreen && loading && (
        <Box position="absolute" top={5} right={5} p={1} bgcolor="#FFFFFF4D" borderRadius={4}>
          <CircularProgress size={24} style={{ display: 'block' }} />
        </Box>
      )}
    </Box>
  );
};

export default ZoomImage;
