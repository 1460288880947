import { ICategory } from '../CategorySelect/models';

import { Box } from '@material-ui/core';

// Livsmedel
import shoppingBag1 from './icons/shopping-bag-1.png';
import fish1 from './icons/fish-1.png';
import softdrink1 from './icons/softdrink-1.png';
import vegetables1 from './icons/vegetables-1.png';
import barbecueSaladSsoup1 from './icons/barbecue-salad-soup-1.png';
import eggs1 from './icons/eggs-1.png';
import proteinGlutenFood1 from './icons/protein-gluten-food-1.png';
import sushiSandwich1 from './icons/sushi-sandwich-1.png';
// Ej livsmedel häsla produkter
import bandLenOrthpedic1 from './icons/band-len-prthpedic-1.png';
import medicine1 from './icons/medicine-1.png';
import vitamins1 from './icons/vitamins-1.png';
import sunProtection1 from './icons/sun-protection-1.png';
// Foder
import fishChickenCatFood1 from './icons/fish-chicken-cat-food-1.png';
import mincedMeat1 from './icons/minced-meat-1.png';
import vitamins2 from './icons/vitamins-2.png';
// Övriga produkter
import products1 from './icons/products-1.png';
import cleaningProducts1 from './icons/cleaning-products-1.png';
import plugin1 from './icons/plug-in-1.png';
import toys1 from './icons/toys-1.png';
import dishSoapDetergent1 from './icons/dish-soap-detergent-1.png';
import glassesJewelryScarf1 from './icons/glasses-jewelry-scarf-1.png';
import hairbrushDiningChairDrink1 from './icons/hairbrush-dining-chair-drink-1.png';
import { GROUP_CATEGORY_IDS } from './groupCategoryIds';

export const CATEGORY_GROUPS = ['Livsmedel', 'Ej livsmedel häsla produkter', 'Foder', 'Övriga produkter'];

export const CATEGORY_TYPE_DETAILS: ICategory[] = [
  // Livsmedel
  {
    id: GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    name: 'Allmänt livsmedel',
    categoryGroup: 'Livsmedel',
    icon: shoppingBag1,
    defaultCategory: {
      qualityGroupName: 'Bageriprodukter',
      parentCategoryName: 'Asiatiska köket',
    },
    descriptions: (
      <Box>
        <Box>Inkluderar</Box>
        <ul style={{ paddingLeft: 12, margin: 0 }}>
          <li>Mjölk (och motsvarande icke mjölkbaserade produkter)</li>
          <li>Fetter och oljor</li>
          <li>Glass och dessert varor</li>
          <li>Spannmålsprodukter</li>
          <li>Bageriprodukter </li>
          <li>Sockerarter, sirup, honung, bordsötningsmedel</li>
          <li>Konsumptionsfärdiga snacks</li>
        </ul>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
    name: 'Fisk och fisk produkter',
    categoryGroup: 'Livsmedel',
    icon: fish1,
    defaultCategory: {
      qualityGroupName: 'Fisk och fiskeriprodukter',
      parentCategoryName: 'Fisk & skaldjur färsk',
    },
    descriptions: (
      <Box>
        <Box mb={1}>
          Fisk och skaldjur med inga andra ingredienser tillsätts och en begränsad mängd på fiskfetter, socker, och
          salt.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.livsmedelsverket.se/foretagande-regler-kontroll/regler-for-livsmedelsforetag/information-markning-och-pastaenden/nyckelhalet---foretagsinformation/nyckelhalsregler-vad-kravs-for-att-mat-ska-fa-markas-med-nyckelhalet/fisk-och-fiskprodukter"
          >
            Las mer här
          </a>
        </Box>
        <Box>Ex. färska och djupfrysta fisk, fiskbullar, fiskburgare, rökt lax, kaviar, sill</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.DRYCKER,
    name: 'Drycker',
    categoryGroup: 'Livsmedel',
    icon: softdrink1,
    defaultCategory: {
      qualityGroupName: 'Drycker',
      parentCategoryName: 'Chokladdryck',
    },
    descriptions: 'Ex. vatten, läsk, kaffe, smoothie, vin',
  },
  {
    id: GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
    name: 'Frukt och grönsaker',
    categoryGroup: 'Livsmedel',
    icon: vegetables1,
    defaultCategory: {
      qualityGroupName: 'Frukter, bär och grönsaker',
      parentCategoryName: 'Apelsiner',
    },
    descriptions: (
      <Box>
        <Box mb={1}>Inkluderar alla konserverade, färska eller frysta frukter och grönsaker.</Box>
        <Box>Ex. bär, svamp, grönsakskonserv</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
    name: 'Kött, kryddor, soppor, såser, sallader och proteinprodukter',
    categoryGroup: 'Livsmedel',
    icon: barbecueSaladSsoup1,
    defaultCategory: {
      qualityGroupName: 'Kött',
      parentCategoryName: 'Delikatesschark',
    },
    descriptions:
      'Ex. korv lufttorkad, kyckling färsk, texmex sås, vegetariska biffar, sojaprodukter, kryddpeppar, pesto, soppa torr',
  },
  {
    id: GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
    name: 'Ägg och ägg produkter',
    categoryGroup: 'Livsmedel',
    icon: eggs1,
    defaultCategory: {
      qualityGroupName: 'Ägg och äggprodukter',
      parentCategoryName: 'Ägg',
    },
    descriptions: 'Ex. ägg, äggröra, äggnudlar',
  },
  {
    id: GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
    name: 'Special livsmedel enligt definitionen i direktiv (EG) 2009/39',
    categoryGroup: 'Livsmedel',
    icon: proteinGlutenFood1,
    defaultCategory: {
      qualityGroupName: 'Livsmedel för särskilda näringsändamål',
      parentCategoryName: 'Barn mellanmål & desserter',
    },
    descriptions: (
      <Box>
        <Box>Inkluderar</Box>
        <ul style={{ paddingLeft: 12, margin: 0 }}>
          <li>Livsmedel avsedda för viktminskningsdieter</li>
          <li>Barnmat för spädbarn och småbarn</li>
          <li>Mat för gravida kvinnor (inkluderar bröstmjölksersättning och tillskottsnäring)</li>
          <li>Glutenfri mat</li>
          <li>Sportnäring</li>
          <li>Livsmedel för specifika allergier eller intoleranser</li>
        </ul>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
    name: 'Övrigt livsmedel',
    categoryGroup: 'Livsmedel',
    icon: sushiSandwich1,
    defaultCategory: {
      qualityGroupName: 'Övrigt livsmedel',
      parentCategoryName: 'Asiatiska köket',
    },
    descriptions: (
      <Box>
        <Box mb={1}>Matvaror som inte tillhör någon av kategorierna.</Box>
        <Box>Ex. olika sorts färdigmat, snus, tuggummi, sushi</Box>
      </Box>
    ),
  },

  // Ej livsmedel häsla produkter
  {
    id: GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
    name: 'Medicintekniska produkter',
    categoryGroup: 'Ej livsmedel häsla produkter',
    icon: bandLenOrthpedic1,
    defaultCategory: {
      qualityGroupName: 'Medicinteknik',
      parentCategoryName: 'Barn kroppsvård',
    },
    descriptions: (
      <Box>
        <Box mb={1}>Produkter som är avsedd att användas på människor för ett eller flera medicinska ändamål.</Box>
        <Box>Ex. plåster, kontaktlinser, rullstolar</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    name: 'Receptfria läkemedel',
    categoryGroup: 'Ej livsmedel häsla produkter',
    icon: medicine1,
    defaultCategory: {
      qualityGroupName: 'Receptfria läkemedel',
      parentCategoryName: 'Allergi',
    },
    descriptions: (
      <Box>
        <Box mb={1}>Läkemedel som kan köpas utan recept från en läkare.</Box>
        <Box>Ex. hostmedicin, antihistaminer, självtester</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
    name: 'Kosttillskott',
    categoryGroup: 'Ej livsmedel häsla produkter',
    icon: vitamins1,
    defaultCategory: {
      qualityGroupName: 'Kosttillskott enligt definitionen i direktiv 2002/46/EG',
      parentCategoryName: 'Kosttillskott',
    },
    descriptions: 'Kosttillskott enligt definitionen i direktiv (EG) 2002/46.',
  },
  {
    id: GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    name: 'Solskyddsmedel',
    categoryGroup: 'Ej livsmedel häsla produkter',
    icon: sunProtection1,
    defaultCategory: {
      qualityGroupName: 'Solskyddsmedel',
      parentCategoryName: 'Hudvård',
    },
    descriptions:
      'Produkt som appliceras för att minska skadlig exponering av huden för ultraviolett (UV) strålning från solen.',
  },

  // Foder
  {
    id: GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    name: 'Helfoder och foderblandningar',
    categoryGroup: 'Foder',
    icon: fishChickenCatFood1,
    defaultCategory: {
      qualityGroupName: 'Helfoder',
      parentCategoryName: 'Djurfoder',
    },
    descriptions: (
      <Box>
        <Box mb={1}>
          Foderkälla som innehåller alla nödvändiga näringsämen för ett djur, eller en sammansättning av olika
          ingredienser som används som en del av djurs kost för all tillhandahålla specifika näringsämnen och energi.
        </Box>
        <Box>Ex. hundfoder, hästhelfoder, kaninfoderblanding </Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.FODERRAVARA,
    name: 'Foderråvara',
    categoryGroup: 'Foder',
    icon: mincedMeat1,
    defaultCategory: {
      qualityGroupName: 'Foderråvara',
      parentCategoryName: 'Djurfoder',
    },
    descriptions: (
      <Box>
        <Box mb={1}>Enskild ingrediens eller komponent som används för att tillverka djurfoder.</Box>
        <Box>Ex. majs, sojabönor, köttmjöl </Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
    name: 'Kompletteringsfoder och fodertillskott',
    categoryGroup: 'Foder',
    icon: vitamins2,
    defaultCategory: {
      qualityGroupName: 'Kompletteringsfoder & Fodertillskott',
      parentCategoryName: 'Djurfoder',
    },
    descriptions:
      'Foder som ges till djur för att tillföra nödvändiga näringsämnen och komplettera deras huvudsakliga diet, eller som tillsätts i djurets kost för att tillgodose specifika näringsbehov eller hälsoproblem.',
  },

  // Övriga produkter
  {
    id: GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
    name: 'Kosmetiska produkter',
    categoryGroup: 'Övriga produkter',
    icon: products1,
    defaultCategory: {
      qualityGroupName: 'Kosmetiska produkter',
      parentCategoryName: 'Amma & mata',
    },
    descriptions: (
      <Box>
        <Box mb={1}>
          Kemiska ämnen eller blandningar som används för att rengöra, parfymera och skydda kroppens yttre delar såsom
          hud, hår, naglar, läppar och könsorgan, samt tänder och munhåla.
        </Box>
        <Box>Ex. hudkräm, munskölj, våtservetter, hårfärg, hårspray, smink, deodorant</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
    name: 'Kemiska produkter',
    categoryGroup: 'Övriga produkter',
    icon: cleaningProducts1,
    defaultCategory: {
      qualityGroupName: 'Kemiska produkter',
      parentCategoryName: 'Datortillbehör',
    },
    descriptions: (
      <Box>
        <Box mb={1}>
          Ett ämne eller en blandning av två eller flera ämnen. Exempel på ämnen är aceton, ättiksyra och etanol.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.kemi.se/lagar-och-regler/introduktion-till-kemikaliereglerna/kort-om-reglerna-for-kemiska-produkter"
          >
            Läs mer här
          </a>
        </Box>
        <Box>Ex. målarfärg, lim och rengöringsmedel.</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
    name: 'Elektriska produkter',
    categoryGroup: 'Övriga produkter',
    icon: plugin1,
    defaultCategory: {
      qualityGroupName: 'Elektrisk',
      parentCategoryName: 'Amma & mata',
    },
    descriptions: (
      <Box>
        <Box mb={1}>
          Produkter med batteri, som ansluts med stickpropp, eller som är fast anslutna till elanlägnningen.
        </Box>
        <Box>Ex. kablar, dammsugare, vissa rakapparate, värmeborstar</Box>
      </Box>
    ),
  },
  {
    id: GROUP_CATEGORY_IDS.LEKSAK,
    name: 'Leksak',
    categoryGroup: 'Övriga produkter',
    icon: toys1,
    defaultCategory: {
      qualityGroupName: 'Leksak',
      parentCategoryName: 'Leksaker',
    },
    descriptions: 'Varor utformade eller avsedda att användas för lek av barn under 14 år.',
  },
  {
    id: GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
    name: 'Tvättmedel/diskmedel',
    categoryGroup: 'Övriga produkter',
    icon: dishSoapDetergent1,
    defaultCategory: {
      qualityGroupName: 'Tvättmedel/Diskmedel',
      parentCategoryName: 'Disk & tillbehör',
    },
    descriptions: 'Ex. tvättmedel, maskindiskmedel, handdiskmedel',
  },
  {
    id: GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
    name: 'Smycken, kläder, glasögon, accessoarer',
    categoryGroup: 'Övriga produkter',
    icon: glassesJewelryScarf1,
    defaultCategory: {
      qualityGroupName: 'Smycken, kläder, glasögon, accessoarer',
      parentCategoryName: 'Amma & mata',
    },
    descriptions: 'Ex. sockar, läsglasögon, handskar, kepsar',
  },
  {
    id: GROUP_CATEGORY_IDS.VAROR,
    name: 'Varor',
    categoryGroup: 'Övriga produkter',
    icon: hairbrushDiningChairDrink1,
    defaultCategory: {
      qualityGroupName: 'Varor',
      parentCategoryName: 'Accessoarer',
    },
    descriptions: (
      <Box>
        <Box mb={1}>Icke-matvaror som inte tillhör ovanstående kategorier</Box>
        <Box>Ex. borstar, ljus, termoskannor, böcker, växter, möbler, skor</Box>
      </Box>
    ),
  },
];
