import { FC, useLayoutEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import ErrorInfoIcon from '../../../components/ErrorInfoIcon';

import useDataContext from '../../../hooks/useDataContext';

import { IImportColumn, IProductExcel } from '../../../types';
import { getCellValue, getErrorCell, isErrorCell } from '../../../utils/excel';

import CheckboxField from './CheckboxField';
import DateField from './DateField';

import { useStyles } from '../index.style';
import { useStyles as useCustomStyles } from './styles';

export interface ReviewTableBodyCellProps {
  isReview: boolean;
  column: IImportColumn;
  data: IProductExcel;
  className?: string;
}

/**
 *
 * don't use MUI's component in table body
 * - Using <TableCell>: x2 render time
 * - Using <Box>: x2 render time
 */
const ReviewTableBodyCell: FC<ReviewTableBodyCellProps> = ({ isReview, column, data, className }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { onChangeDataValue } = useDataContext();

  const isDateData = column.dataType === 'date';
  const isBooleanData = column.dataType === 'boolean';
  const isStringData = !isDateData && !isBooleanData;

  const [cellBoxElement, setCellBoxElement] = useState<HTMLDivElement | null>(null);

  const value = useMemo(() => getCellValue(column, data), [column, data]);
  const isError = useMemo(() => isErrorCell(column, { ...data, [column.field]: value }), [column, data, value]);
  const errorMessage = useMemo(() => getErrorCell(column, data), [column, data]);

  const handleChange = (value: string) => {
    onChangeDataValue(data.id || '', column.field, value);
  };

  // When changes "page", "limit",... [ReviewTableBodyCell] will be remounted
  // Need to reset width for new [cellBoxElement] element
  useLayoutEffect(() => {
    if (!cellBoxElement || cellBoxElement.dataset.width) return;

    const headerCellElement = document.querySelector(`th [data-field='${column.field}']`) as HTMLElement;
    if (!headerCellElement || !headerCellElement.dataset.width) return;
    const { width } = headerCellElement.dataset;
    setTimeout(() => (cellBoxElement.style.width = `${parseFloat(width) + 2}px`), 0);
    cellBoxElement.dataset.width = width;
  }, [column.field, cellBoxElement]);

  const renderErrorInfo = () => {
    if (!isError) return null;
    return (
      <div style={{ position: isBooleanData ? 'absolute' : undefined, right: 0 }}>
        <ErrorInfoIcon key="error" errors={[{ label: errorMessage }]} />
      </div>
    );
  };

  return (
    <td className={clsx(classes.bodyCell, className)}>
      <div
        ref={setCellBoxElement}
        className={customClasses.cellWrapper}
        data-field={column.field}
        style={{ display: 'flex', alignItems: 'center', gap: 12 }}
      >
        <div className={isBooleanData ? '' : customClasses.newWrapper} style={{ flexGrow: 1 }}>
          {isDateData && <DateField column={column} data={data} onChange={handleChange} />}
          {isBooleanData && <CheckboxField column={column} data={data} onChange={handleChange} />}

          {isStringData && (
            <input
              className={customClasses.input}
              value={_.isNil(value) ? '' : String(value)}
              onChange={event => handleChange(event.target.value)}
            />
          )}
        </div>
        {!isBooleanData && renderErrorInfo()}
      </div>
    </td>
  );
};

export default ReviewTableBodyCell;
