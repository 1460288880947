import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';

const Styled = {
  ScrollContainer: styled.div<{ bottom?: number }>`
    position: sticky;
    z-index: 100;
    overflow-y: auto;
    bottom: ${props => `${props.bottom || 0}px`};
    height: 0;
  `,
  ScrollChildren: styled.div<{ width?: number }>`
    height: 1px;
    width: ${props => `${props.width || 0}px`};
  `,
};

interface VirtualScrollbarProps {
  targetElement: HTMLElement | null;
  bottom?: number;
}

const VirtualScrollbar: FC<VirtualScrollbarProps> = ({ targetElement, bottom = 0, children }) => {
  const stateRef = useRef<{ scrollingElement?: HTMLElement }>({});
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  // virtual scrollbar controls target element
  useEffect(() => {
    const scollContainerElement = scrollContainerRef.current;
    if (!targetElement || !scollContainerElement) return;

    const handleContainerMouseDown = () => (stateRef.current.scrollingElement = scollContainerElement);
    const handleContainerMouseUp = () => (stateRef.current.scrollingElement = undefined);
    const handleContainerScroll = () => {
      if (stateRef.current.scrollingElement === scollContainerElement) {
        // [setTimeout] is used to wait for [targetElement] was drawed in DOM,
        //   "scrollLeft" value will be correct
        setTimeout(() => targetElement.scroll({ left: scollContainerElement.scrollLeft }), 0);
      }
    };

    scollContainerElement.addEventListener('mousedown', handleContainerMouseDown);
    scollContainerElement.addEventListener('mouseup', handleContainerMouseUp);
    scollContainerElement.addEventListener('scroll', handleContainerScroll);
    return () => {
      scollContainerElement.removeEventListener('mousedown', handleContainerMouseDown);
      scollContainerElement.removeEventListener('mouseup', handleContainerMouseUp);
      scollContainerElement.removeEventListener('scroll', handleContainerScroll);
    };
  }, [targetElement]);

  // target element contorls virtual scrollbar
  useEffect(() => {
    const scollContainerElement = scrollContainerRef.current;
    if (!targetElement || !scollContainerElement) return;

    const handleTargetScroll = () => {
      if (stateRef.current.scrollingElement !== scollContainerElement) {
        // [setTimeout] is used to wait for [targetElement] was drawed in DOM,
        //   "scrollLeft" value will be correct
        setTimeout(() => scollContainerElement.scroll({ left: targetElement.scrollLeft }), 0);
      }
    };

    targetElement.addEventListener('scroll', handleTargetScroll);
    return () => {
      targetElement.removeEventListener('scroll', handleTargetScroll);
    };
  }, [targetElement]);

  // watch end element
  // hide "Virtual scroll bar" when end element is visible in the screen
  // show "Virtual scroll bar" when end element is NOT visible in the screen
  useEffect(() => {
    const scollContainerElement = scrollContainerRef.current;
    if (!targetElement?.parentNode || !scollContainerElement) return;
    const endElement = document.createElement('div');
    // insert [endElement] right after [targetElement]
    targetElement.parentNode.insertBefore(endElement, targetElement.nextSibling);

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) {
          scollContainerElement.style.height = entry.intersectionRatio === 1 ? '0' : 'auto';
        }
      },
      { threshold: [0, 1], rootMargin: `${bottom * -1}px` }
    );
    observer.observe(endElement);
    return () => {
      observer.disconnect();
    };
  }, [bottom, targetElement]);

  return (
    <Box position="relative">
      {children}
      <Styled.ScrollContainer ref={scrollContainerRef} bottom={bottom - 1}>
        <Styled.ScrollChildren width={targetElement?.scrollWidth || 0} />
      </Styled.ScrollContainer>
    </Box>
  );
};

export default VirtualScrollbar;
