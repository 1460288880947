import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
// import { makeStyles } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ProductReviewGroupList from './ProductGroupList';
import { GET_SELECTED_EANS, GET_PRODUCER_NOTES } from '../graphql/queries'
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_ORDER, CLEAR_CART, CLEAR_NOTES } from '../graphql/queries';
import { CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from './OrderToolbar';
import Button from '@material-ui/core/Button';
import ErrorBox from './Error';

/*
const useStyles = makeStyles(theme => ({
    nextstep: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
    },
}));
*/

export default function ReviewContent(props) {
    const { confirmAction, orderContactInfo: orderContact } = props;
    const { loading, error, data } = useQuery(GET_SELECTED_EANS);
    const { loading: notesLoading, error: notesError, data: notesData } = useQuery(GET_PRODUCER_NOTES);
    const [clearCart] = useMutation(CLEAR_CART, {
        refetchQueries: [{ query: GET_SELECTED_EANS }],
    });
    const [clearNotes] = useMutation(CLEAR_NOTES, {
        refetchQueries: [{ query: GET_PRODUCER_NOTES }],
    });
    const [createGroupOrder, {
        loading: mutationLoading, error: mutationError
    }] = useMutation(CREATE_ORDER, {
        onCompleted: data => {
            clearCart();
            clearNotes();
            confirmAction(data.createGroupOrder.payload.groupOrderNumber);
        },
    });

    if (error) {
        return <ErrorBox message={`Error! ${error.message}`} />;
    } else if (mutationError) {
        return <ErrorBox message={`Error! ${mutationError.message}`} />;
    }

    const onSubmit = event => {
        event.preventDefault();
        let items = data.cartItems
            .map(id => {
                // let input = document.getElementById(id);
                // console.log('!id', id);
                // console.log('!input', input);
                let quantity = 0;
                if (id) {
                    quantity = localStorage.getItem(id) || 0;
                    try {
                        quantity = parseInt(quantity);
                    } catch (e) {
                        quantity = 0;
                    }
                }
                return { productId: id, quantity };
                // if (!!input) {
                //     return { productId: id, quantity: parseInt(input.value) };
                // } else {
                //     return { productId: id, quantity: 0 };
                // }
            })
            .filter(item => item.quantity !== 0 && item.quantity_in_stock !== 0);
        let notes = notesData && notesData.producerNotes && notesData.producerNotes
            .filter(id => {
                const notesItem = localStorage.getItem(`notes-${id}`);
                return notesItem && notesItem.trim() !== ""
            })
            .map(id => {
                const notesItem = localStorage.getItem(`notes-${id}`);
                return {id, value: notesItem};
            });
        if (Array.isArray(items) && items.length) {
            createGroupOrder({
                variables: { orderInput: { items, orderContact, notes } },
            });
        }
    };
    return (
        <Box component="form" onSubmit={onSubmit}>
            <Toolbar>
                <Button
                    component={Link}
                    to="/order/select"
                    size="medium"
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                >
                    Edit
                </Button>
                <Button
                    type="submit"
                    size="medium"
                    color="primary"
                    variant="outlined"
                    startIcon={<DoneAllIcon />}
                >
                    Submit
                </Button>
            </Toolbar>
            {(loading || mutationLoading) && <CircularProgress />}

            {!loading && !mutationLoading && data && data.cartItems && (
                <ProductReviewGroupList
                    saveOrder={true}
                    list={data.cartItems}
                    editable={false}
                />
            )}
        </Box>
    );
}

ReviewContent.defaultProps = {
    list: [],
    confirmAction: () => {},
};
