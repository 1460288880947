import { FC, ReactNode } from 'react';

import { Box } from '@material-ui/core';

interface LoadingBackdropProps {
  open: boolean;
  label: ReactNode;
}

const LoadingBackdrop: FC<LoadingBackdropProps> = ({ open, label }) => {
  if (!open) return null;

  return (
    <Box
      position="fixed"
      bgcolor="rgba(0, 0, 0, 0.3)"
      color="#FFFFFF"
      fontSize={20}
      fontWeight={700}
      zIndex={1100}
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      py={10}
      pl={30}
      style={{ backdropFilter: 'blur(3px)' }}
    >
      {label}
    </Box>
  );
};

export default LoadingBackdrop;
