import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import Face from '@material-ui/icons/Face';
import Mail from '@material-ui/icons/Mail';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuList from '@material-ui/core/MenuList';

import { withRouter } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import { KeycloakContext } from '../components/Secured';
import { Typography } from '@material-ui/core';

export default withRouter(props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const keycloakContext = React.useContext(KeycloakContext);

    /*
  function handleChange(event) {
    setAuth(event.target.checked);
  }
*/
    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleLogout(client) {
        client.resetStore();
        keycloakContext.keycloak.logout();
        //localStorage.removeItem("token")
        //props.history.push("/login")
    }

    const { name: userName, email: userEmail } = keycloakContext?.keycloak?.tokenParsed || {} ;

    return (
        /*props.IsUserLoggedIn && props.IsUserLoggedIn.isLoggedIn &&*/ <ApolloConsumer>
            {client => (
                <>
                    <IconButton color="inherit" onClick={handleMenu}>
                        <AccountCircle />
                    </IconButton>
                    <MenuList>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem disabled>
                                <ListItemIcon>
                                    <Face />
                                </ListItemIcon>
                                <Typography style={{ fontSize: 12 }}>{userName}</Typography>
                            </MenuItem>
                            <MenuItem disabled>
                                <ListItemIcon>
                                    <Mail />
                                </ListItemIcon>
                                <Typography style={{ fontSize: 12 }}>{userEmail}</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => handleLogout(client)}>
                                <ListItemIcon>
                                    <PowerSettingsNewOutlinedIcon />
                                </ListItemIcon>
                                <Typography>Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </MenuList>
                </>
            )}
        </ApolloConsumer>
    );
});
