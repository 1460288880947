import axios from 'axios';
import { map, find, compose, path, concat, uniqBy } from 'ramda';
import { isDefined } from './helpers';
// import { setDpi } from './imgix';
import { removeBg } from './removebg';
// import jimp from 'jimp';
import { IMAGE_SIZES, getBlobDataFromImageURL, getImageWithSizeSuffix } from './images2';

export const getPicturePaths = signedRequests => map(s => s.picturePath, signedRequests);

export const findFileForSignedRequest = (request, files) => {
  return find(f => f.name === request.fileName, files);
};

export const uploadImages = async (imageFiles, signedRequests) => {
  console.log('===uploadImages===');
  // console.log('===uploadImages===', imageFiles, signedRequests);
  // const files = await Promise.all(ima§geFiles.map(setDpi));
  const files = imageFiles;
  const putRequests = [];

  for (const request of signedRequests) {
    const file = findFileForSignedRequest(request, files);
    if (file) {
      const { signedRequest } = request;

      const requestOptions = {
        headers: {
          'Content-Type': file.type,
        },
      };
      putRequests.push({ signedRequest, file, requestOptions });
    }
  }
  console.log('signedRequests', signedRequests);

  const axiosPromises = map(r => axios.put(r.signedRequest, r.file, r.requestOptions), putRequests);

  return Promise.all(axiosPromises);
};

const findFileForRequest = (files, fileName) => find(f => f.path === fileName, files);

export const getImagesForSorter = (existing, s3RequestsData, files) => {
  // console.log('===getImagesForSorter===', existing, s3RequestsData, files);
  const newImagesImageSort = isDefined(s3RequestsData)
    ? compose(
        map(r => ({
          pictureUrl: r.pictureUrl,
          id: `${r.pictureUrl}`,
          imgSrc: isDefined(findFileForRequest(files, r.fileName)) ? URL.createObjectURL(findFileForRequest(files, r.fileName)) : '',
          isNew: true,
          isExisting: false,
        })),
        path(['getS3SignRequest', 'requests'])
      )(s3RequestsData)
    : [];

  const existingImagesImageSort = map(i => {
    const url = new URL(i);
    const searchParams = new URLSearchParams(url.search);
    return {
      pictureUrl: i,
      isExisting: true,
      isNew: false,
      isArtwork: searchParams.get('ARTWORK') === '1',
      imgSrc: getImageWithSizeSuffix(i, IMAGE_SIZES.SMALL),
      id: `${i}`,
    };
  })(existing);

  const allImages = concat(
    existingImagesImageSort,
    uniqBy(i => i.pictureUrl, newImagesImageSort)
  );

  // console.log('newImagesImageSort', newImagesImageSort);
  // console.log('existingImagesImageSort', existingImagesImageSort);
  // console.log('allImages', allImages);

  return allImages;
};

// const fileToDataUrl = async (file) => {
//   return new Promise((res, rej) => {
//     const reader = new FileReader();

//     reader.onload = () => {
//       // convert image file to base64 string
//       res(reader.result);
//     };
  
//     if (file) {
//       reader.readAsDataURL(file);
//     }
//   });
// }

export const downloadImage = async (url) => {
  if (!url) {
    return undefined;
  }
  console.log(`Download image ${url}`);
  try {
    const response = await fetch(url);
    if (response.ok) {
      let buffer = await response.buffer();
      return buffer;
    }
    throw new Error(response.statusText);
  } catch (error) {
    console.error(`Image ${url} error:`, error.message);
  }
  return undefined;
};

export const getImageWithBGRemoved = async (imageFile, token, timeout) => {
  console.log('===getImageWithBGRemoved===', imageFile);
  const fileName = isDefined(imageFile.name) ? imageFile.name.split('.')[0] : '';
  // const form = new FormData();

  // const newImage = await setDpi(imageFile);

  // form.append('image_file', imageFile);
  // form.append('size', 'full');
  // form.append('crop', true);
  // form.append('scale', 'original');

  let returned_error_string = '';
  let file;

  // const result = await removeBackgroundFromImageBase64({
  //   base64img: imageFile,
  //   apiKey: 'wWztquswGRJ1NAkdTJnTeb8R',
  //   size: 'auto',
  //   crop: true,
  //   format: 'png',
  //   responseType: 'arraybuffer',
  //   scale: 'original',
  //   // outputFile
  // });
  // const base64 = result.base64img;

  try {
    // get zip file from remove.bg
    // const url = await fileToDataUrl(imageFile);
    // console.log('!!!!url', url);
    // const imageBuffer = await downloadImage(imageFile);
    // const image = await jimp.read(url);
    // const mime = image?.getMIME();
    // let f;
    // if (mime && mime !== jimp.MIME_PNG) {
    //   // const pngImageBuffer = await image.getBufferAsync(jimp.MIME_PNG);
    //   // const pngBlob = new Blob([pngImageBuffer]);
    //   // const pngFile = new File([pngBlob], imageFile.name, { type: 'image/png' });
    //   // f = await removeBg(pngFile, token);
    //   // console.log('!!!!imageFile', imageFile);
    //   // console.log('!!!!pngFile', pngFile);
    // } else {
    //   // f = await removeBg(imageFile, token);
    // }
    // const colorType = image.colorType();
    // console.log('!!!!colorType', colorType);

    // unzip to memory 
    // const { color, alpha } = await unzip(f);
    // merge image and alpha
    // const data = await createMask(color, alpha);
    // const resultImage = await jimp.read(data);
    // resultImage.colorType()
    const f = await removeBg(imageFile, token, timeout);
    // console.log('!!!ff', f);
    // console.log('!!!ff', typeof f);
    let res;
    if (f) {
      const blob = new Blob([f]);
      if (blob) {
        res = new File([blob], `${fileName}AIGENERATED.png`, { type: 'image/png' });
      }
    }
    
    if (res) {
      file = res;
      file.path = `${fileName}AIGENERATED.png`;
      file.preview = URL.createObjectURL(res);
    }
    // file = await setDpi(res);
    // // if (res.type !== 'image/png') {
    // if (file) {
    //   file.path = `${fileName}AIGENERATED.png`;
    //   file.preview = URL.createObjectURL(res);
    // }
  } catch (error) {
    try {
      const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
      const startIndex = decodedString.indexOf('{')
      const endIndex = decodedString.lastIndexOf('}')
      const json = JSON.parse(decodedString.slice(startIndex, endIndex + 1));
      returned_error_string = json.errors[0]['title'].split('.')[0];
    } catch {}
    if (!returned_error_string) {
      if (error.request) {
        // The request was made but no response was received
        returned_error_string = `Error Remove Background: ${error.message}`;
      } else {
        // Something happened in setting up the request that triggered an Error
        returned_error_string = `Errors Remove Background: ${error.message}`;
      }
    }
  }

  // console.log('!!!file', file);
  if (file) {
    return file;
  } else {
    return { error: returned_error_string, fileName };
  }
};

export const downloadImageAsFile = async (url, name, type) => {
  const blob = await getBlobDataFromImageURL(url);
  const file = new File([blob], name, { type });
  file.path = name;

  return file;
};
