import React, { useMemo } from 'react';

import InputLabel from 'components/inputfields/InputLabel';
import MultipleSelect from 'components/inputfields/MultipleSelect';

import IProduct, { OrderableUnitEnum } from 'models/product';

interface IOrderableUnitProps {
  isDespatchUnitsChanged: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
}

interface IOrderableUnit {
  value: keyof IProduct;
  displayName: string;
  hideForStore?: boolean;
}

const orderableUnit: IOrderableUnit[] = [
  { value: 'consumerPackOrderableUnit', displayName: OrderableUnitEnum.CONSUMER_PACK, hideForStore: true },
  { value: 'storePackOrderableUnit', displayName: OrderableUnitEnum.STORE_PACK },
  { value: 'transportOrderableUnit', displayName: OrderableUnitEnum.TRANSPORT },
  { value: 'palletOrderableUnit', displayName: OrderableUnitEnum.PALLET },
];

const getOrderableUnits = (product?: IProduct) => {
  if (!product) return [];
  const isNotStore = !product?.producerIsStore;
  if (isNotStore) {
    return orderableUnit;
  } else {
    return orderableUnit.filter((x) => !x.hideForStore);
  }
}

const OrderableUnitFields = ({ isDespatchUnitsChanged, state, setState }: IOrderableUnitProps) => {
  const placeholder = `(${getOrderableUnits(state).map(({ displayName }) => displayName).join(', ')})`;

  const selectedValues = useMemo(() => {
    return getOrderableUnits(state).filter(({ value }) => state[value]).map(({ value }) => value);
  }, [state]);

  const handleDropdownChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const fields = event.target.value as (keyof IProduct)[];
    const updatedFields: Record<string, any> = {};

    getOrderableUnits(state).forEach(item => {
      updatedFields[item.value] = fields.includes(item.value);
    });

    if (!isDespatchUnitsChanged) {
      // auto fill "Despatch Unit"
      if (fields.includes('consumerPackOrderableUnit')) {
        updatedFields.consumerPackDespatchUnit = true;
      }
      if (fields.includes('storePackOrderableUnit')) {
        updatedFields.storePackDespatchUnit = true;
      }
      if (fields.includes('transportOrderableUnit')) {
        updatedFields.transportDespatchUnit = true;
      }
      if (fields.includes('palletOrderableUnit')) {
        updatedFields.palletDespatchUnit = true;
      }
    }

    setState({ ...state, ...updatedFields });
  };

  return (
    <>
      <InputLabel
        heading="Vilken/Vilka artikelnivå(er) är beställningsbara?"
        text="Här anger du vilken eller vilka artikelnivåer av artikeln som är beställningsbara för dina kunder. Du måste ange minst en nivå som är beställningsbar."
      />
      <MultipleSelect
        disableLabel
        listData={getOrderableUnits(state)}
        value={selectedValues}
        placeholder={placeholder}
        targetName=""
        handleChange={handleDropdownChange}
      />
    </>
  );
};

export default OrderableUnitFields;
