import gql from "graphql-tag";

export const STATISTIC_DATA = gql`
  query statisticData($name: String, $days: Int) {
    statisticData(name: $name, days: $days) {
      by_date {
        id
        date
        ean
        revenue
        checkouts
        removesFromCart
        detailViews
        listView
        clicks
        refundAmount
        refunds
        addsToCart
      }
      top_products {
        id
        name
        ean
        revenue
        checkouts
        removesFromCart
        detailViews
        listView
        clicks
        refundAmount
        refunds
        addsToCart
      }
    }
  }
`;
