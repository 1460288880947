import { FC, useMemo } from 'react';

import { IImportColumn, IProductExcel } from '../../../types';

import { makeStyles } from '@material-ui/core';
import { convertBooleanValue } from 'components/product/BulkImport/utils/excel';
import { Checkbox } from 'components/product/BulkImport/components/Checkbox';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export interface BooleanFieldProps {
  column: IImportColumn;
  data: IProductExcel;
  onChange: (value: string) => void;
}

const BooleanField: FC<BooleanFieldProps> = ({ column, data, onChange }) => {
  const classes = useStyles();

  const value = useMemo(() => convertBooleanValue(column, data) as boolean, [column, data]);

  const handleChange = (checked: boolean) => {
    if (column.isEnumType) {
      onChange(checked ? 'YES' : 'NO');
    } else {
      onChange(String(checked));
    }
  };

  return (
    <div className={classes.wrapper}>
      <Checkbox checked={value ?? false} onChange={handleChange} />
    </div>
  );
};

export default BooleanField;
