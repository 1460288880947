import React from 'react';

import TextField from '@material-ui/core/TextField';

import Checker from './Checker';
import InputLabel from './InputLabel';
import { hasValue } from '../../utils/helpers';
import ThreeWaySwitch from './ThreeWaySwitch';
import IModel from '../../models/model';

interface ILabelOptions {
    heading?: string;
    text?: string;
    link?: string;
    required?: boolean;
}

interface IInputTextFieldProps<T extends IModel> {
    inputName: keyof T;
    value?: any; // TODO
    label?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent) => void;
    disabled?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    multiline?: boolean;
    multilineRows?: number
    multilineMax?: number;
    style?: React.CSSProperties;
    withLabel?: boolean;
    dense?: boolean;
    labelOptions?: ILabelOptions;
    aiButton?: React.ReactNode;
    showRed?: boolean;
    noFullWidth?: boolean;
    maxLength?: number;
    approve?: boolean;
    state?: T;
    setState?: (value: T) => void;
    errorText?: string;
    placeholder?: string;
    forceRed?: boolean;
    ignoreTabs?: boolean;
    shrink?: boolean;
    className?: string;
    inputClassName?: string;   
}

const InputTextField = <T extends IModel, >({
    inputName,
    value,
    label,
    onChange,
    onBlur,
    onKeyPress,
    disabled,
    required,
    autoFocus,
    multiline,
    multilineRows = 2,
    multilineMax = 3,
    style,
    withLabel,
    dense,
    labelOptions,
    aiButton,
    showRed,
    noFullWidth,
    maxLength,
    approve,
    state,
    setState,
    errorText,
    placeholder,
    forceRed,
    ignoreTabs,
    shrink,
    className,
    inputClassName,
}: IInputTextFieldProps<T>) => {
    let error = false;
    if (required && showRed) {
        error = !hasValue(value);
    }
    if (forceRed) {
        error = true;
    }
    let Check;
    
    if (approve && state?.approve?.find((x) => x.field === inputName)) {
        // const value = state?.approve?.find((x) => x.field === inputName)?.state;
        Check = (
            <ThreeWaySwitch
                // value={value}
                name={inputName}
                state={state}
                setState={setState}
            />
        );
    } else {
        Check = !error ? (
            (value || value === 0) ? (
                <Checker showRed={showRed} error={false} />
            ) : (
                <></>
            )
        ) : (
            <Checker showRed={showRed} error={true} />
        );
    }
    return (
        <>
            {withLabel && (
              <>
                {aiButton ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                    <InputLabel {...labelOptions} />
                    {aiButton}
                  </div>
                ) : (
                  <InputLabel {...labelOptions} />
                )}
              </>
            )}
            <TextField
                style={{marginTop: '5px', ...style}}
                placeholder={placeholder}
                error={error}
                fullWidth={!noFullWidth}
                multiline={multiline}
                rows={multilineRows}
                rowsMax={multilineMax}
                id={inputName.toString()}
                label={label}
                margin={dense ? "dense" : "normal"}
                name={inputName.toString()}
                disabled={disabled}
                required={required}
                autoFocus={autoFocus}
                value={value}
                variant="outlined"
                onChange={onChange}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                className={className}
                inputProps={{
                    maxLength,
                }}
                InputProps={{
                    endAdornment: Check,
                    classes: {
                        input: inputClassName  
                    }
                }}
                InputLabelProps={{
                    shrink: shrink,
                }}
                helperText={error && errorText}
            />
            {/* {approve && (
                <ThreeWaySwitch
                    value={null}
                />
            )} */}
        </>
    );
};

export default InputTextField;
