import { useMemo, useState } from 'react';

import { IFoodlaCategoryOption } from 'models/category';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import useViewport from 'hooks/useViewports';

const StyledTreeItem = styled(TreeItem)<{ isSelected: boolean }>`
  & > .MuiTreeItem-content > .MuiTreeItem-label {
    background-color: ${props => (props.isSelected ? 'rgba(0, 0, 0, 0.08)' : 'transparent')} !important;
  },
`;

export const findCategory = (categoryList: IFoodlaCategoryOption[], id: string) => {
  if (!categoryList?.length) return;

  let foundCategory: IFoodlaCategoryOption | undefined;

  categoryList.forEach(category => {
    if (foundCategory) return;
    if (category.id === id) foundCategory = category;
    else foundCategory = findCategory(category.children || [], id);
  });

  return foundCategory;
};

interface CategoryTreeChildrenProps {
  selectedId?: string;
  category: IFoodlaCategoryOption;
  handleToogleExpand: (id: string) => void;
}

const CategoryTreeChildren = ({ selectedId, category, handleToogleExpand }: CategoryTreeChildrenProps) => {
  return (
    <StyledTreeItem
      key={category.id}
      isSelected={selectedId === category.id}
      id={category.id}
      nodeId={category.id}
      label={category.name}
      onClick={event => {
        if (!category.children?.length) return;
        event.stopPropagation();
        handleToogleExpand(category.id);
      }}
      onIconClick={event => {
        event.stopPropagation();
        handleToogleExpand(category.id);
      }}
    >
      {category.children?.map(category => (
        <CategoryTreeChildren
          key={category.id}
          selectedId={selectedId}
          category={category}
          handleToogleExpand={handleToogleExpand}
        />
      ))}
    </StyledTreeItem>
  );
};

interface ICategoryProps {
  loading: boolean;
  error?: string;
  treeCategory?: IFoodlaCategoryOption;
  selectedCategory?: IFoodlaCategoryOption;
  onSelect?: (category: IFoodlaCategoryOption) => void;
}

export const CategoryTree = ({ loading, error, selectedCategory, treeCategory, onSelect }: ICategoryProps) => {
  const { mdUp } = useViewport();

  const [searchValue, setSearchValue] = useState('');
  const [expandedIdList, setExpandedIdList] = useState<string[]>([]);

  const categoryGroupList = useMemo(() => {
    if (loading || error || searchValue) return [];
    return treeCategory?.children || [];
  }, [error, loading, searchValue, treeCategory?.children]);

  const allSearchOptionList = useMemo(() => {
    const list: {
      category: IFoodlaCategoryOption;
      ids: string[];
      parentNames: string[];
      matchedIndex: number;
    }[] = [];

    const mapLastChild = (category: IFoodlaCategoryOption, ids: string[], parentNames: string[]) => {
      if (category?.children?.length) {
        category.children.forEach(childCategory => {
          mapLastChild(childCategory, [...ids, category.id], [...parentNames, category.name]);
        });
      } else {
        list.push({ category, ids: [...ids, category.id], parentNames, matchedIndex: -1 });
      }
    };

    treeCategory?.children?.forEach(groupCaterogy => mapLastChild(groupCaterogy, [], []));
    return list;
  }, [treeCategory]);

  const filteredSearchOptionList = useMemo(() => {
    if (loading || error || !searchValue) return [];

    return allSearchOptionList
      .map(searchOption => {
        const matchedIndex = (searchOption?.category?.name || '')
          .toLowerCase()
          .indexOf(searchValue.toLowerCase().trim());
        const matchSubParentIndex = searchOption?.parentNames?.[1]
          .toLowerCase()
          .indexOf(searchValue.toLowerCase().trim());
        return { ...searchOption, matchedIndex, matchSubParentIndex };
      })
      .filter((item) => item.matchedIndex !== -1 || item.matchSubParentIndex !== -1);
  }, [loading, error, searchValue, allSearchOptionList]);

  const handleExpandSelectedCategory = () => {
    if (!selectedCategory?.id) return;
    const searchOption = allSearchOptionList.find(({ ids }) => ids.includes(selectedCategory.id));

    if (!searchOption?.ids?.length) return;
    setExpandedIdList(searchOption.ids.slice(0, searchOption.ids.indexOf(selectedCategory.id)));
  };

  const handleToogleExpand = (id: string) => {
    const newList = expandedIdList.filter(expandedId => expandedId !== id);
    if (!expandedIdList.includes(id)) newList.push(id);
    setExpandedIdList(newList);
  };

  const handleSelect = (id: string) => {
    const isCategoryGroup = categoryGroupList.some(categoryGroup => categoryGroup.id === id);
    if (!treeCategory || isCategoryGroup) return;
    const selectingCategory = findCategory(treeCategory.children || [], id);
    if (!selectingCategory) throw Error(`Can't find selected category`);
    onSelect?.(selectingCategory);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="category-tree-select">Kategori</InputLabel>
      <Select
        fullWidth
        labelId="category-tree-select"
        label="Kategori"
        value={String(selectedCategory?.id || '')}
        onClose={() => setSearchValue('')}
        onOpen={handleExpandSelectedCategory}
        MenuProps={{ MenuListProps: { style: { paddingTop: 0 } } }}
      >
        {/*
          Because we only use [Select] to render UI only,
          so we need render [MenuItem] to display selected Category
        */}
        <MenuItem value={String(selectedCategory?.id || '')} style={{ display: 'none' }}>
          {selectedCategory?.name}
        </MenuItem>

        <Box position="relative" px={2} maxHeight={700} overflow="auto">
          <Box
            position="sticky"
            hidden={loading || !!error}
            bgcolor="#FFFFFF"
            zIndex={10}
            top={0}
            py={2}
            onClick={event => event.stopPropagation()}
          >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={searchValue}
              disabled={loading || !!error}
              placeholder="Search category"
              onChange={event => setSearchValue(event.target.value)}
            />
          </Box>
          {(loading || !!error) && (
            <Box p={2} pt={3} display="flex" justifyContent="center">
              {loading ? <CircularProgress size={24} /> : <Box>Error: Can't load categories</Box>}
            </Box>
          )}

          <TreeView
            expanded={expandedIdList}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={(_: any, id: string) => handleSelect(id)}
          >
            {categoryGroupList.map(category => (
              <CategoryTreeChildren
                key={category.id}
                selectedId={selectedCategory?.id}
                category={category}
                handleToogleExpand={handleToogleExpand}
              />
            ))}
          </TreeView>

          {filteredSearchOptionList.map(({ category, ids, parentNames, matchedIndex, matchSubParentIndex }, index) => (
            <MenuItem
              key={category.id}
              value={category.id}
              onClick={() => handleSelect(category.id)}
              style={{
                background: selectedCategory?.id === category.id ? 'rgba(0, 0, 0, 0.08)' : undefined,
                borderTop: !mdUp ? '1px solid #eee' : undefined,
                paddingTop: !mdUp ? 10 : undefined,
                maxWidth: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: mdUp ? 'center' : 'start',
                  flexDirection: mdUp ? 'row' : 'column',
                  width: '100%',
                }}
              >
                {parentNames.map((name, index) => (
                  <span key={index} style={{ display: 'flex' }}>
                    {index > 0 && (
                      <span style={{ display: 'flex', marginLeft: mdUp ? 4 : -4, marginRight: 4, marginTop: 2 }}>
                        <ChevronRightIcon fontSize="small" />
                      </span>
                    )}
                    {(index === 0 || matchSubParentIndex === -1) ? (
                      <span style={{ whiteSpace: 'break-spaces' }}>{name}</span>
                    ) : (
                      <span>
                        <span style={{ whiteSpace: 'break-spaces' }}>{name.slice(0, matchSubParentIndex)}</span>
                        <span style={{ background: 'yellow' }}>
                          {name.slice(matchSubParentIndex, matchSubParentIndex + searchValue.length)}
                        </span>
                        <span style={{ whiteSpace: 'break-spaces' }}>
                          {name.slice(matchSubParentIndex + searchValue.length)}
                        </span>
                      </span>
                      )}
                  </span>
                ))}
                <div style={{ display: 'flex', fontWeight: 'bold', alignItems: 'start' }}>
                  <span style={{ flexShrink: 0, display: 'inline-flex', padding: '0 4px', marginTop: 2 }}>
                    <ChevronRightIcon fontSize="small" />
                  </span>
                  {matchedIndex !== -1 ? (
                    <span>
                      <span style={{ whiteSpace: 'break-spaces' }}>{category.name.slice(0, matchedIndex)}</span>
                      <span style={{ background: 'yellow' }}>
                        {category.name.slice(matchedIndex, matchedIndex + searchValue.length)}
                      </span>
                      <span style={{ whiteSpace: 'break-spaces' }}>
                        {category.name.slice(matchedIndex + searchValue.length)}
                      </span>
                    </span>
                  ) : (
                    <span style={{ whiteSpace: 'break-spaces' }}>{category.name}</span>
                  )}
                </div>
              </div>
            </MenuItem>
          ))}
        </Box>
      </Select>
    </FormControl>
  );
};

export default CategoryTree;
