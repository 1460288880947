import IProduct from 'models/product';

export const DEFAULT_LIMIT = 20;

export const PARSE_IMAGE_BY: Record<'EAN' | 'ARTICLE', keyof IProduct> = {
  EAN: 'EAN',
  ARTICLE: 'purchasingDataSupplierArticleNo',
};

export const DB_NAME = 'bulk_import';
export const SAVED_FILE_ID = 'import_file';
export const SAVED_FILE_DATA_ID = 'import_file_data';
export const OBJECT_STORAGE_NAME = 'import_data';
export const SAVED_PRODUCT_IMAGE = 'image_file_data_list';
export const LOCAL_STORE_ITEMS = {
  STEP: 'bulk_import.step',
  CATEGORY_TYPE_ID: 'bulk_import.categroy_type_id',
  PRODUCT_TYPE: 'bulk_import.product_type',
  PRODUCER_ID: 'bulk_import.producer_id',
  REGEX: 'bulk_import.regex',
  PARSE_IMAGE_BY: 'bulk_import.parse_image_by',
  IS_IGNORE_AI: 'bulk_import.is_ignore_ai',
  IS_AI_MAIN: 'bulk_import.is_ai_main',
  UPLOADED_PRODUCT_STATUS_SET: 'bulk_import.uploaded_product_status_set',
  LIMIT: 'bulk_import.limit',
  REMOVED_PRODUCT_INDEXS: 'bulk_import.removed_product_indexs',
};

export const STEP_PATHS: Record<string, string> = {
  '1': 'select-category',
  '2': 'import-file',
  '3': 'field-mapping',
  '4': 'review',
};

export const ALLOWED_IMAGE_FILE_TYPE_LIST = ['image/jpeg', 'image/jpg', 'image/tiff', 'image/png'];

export const QUESTION_FIELDS: (keyof IProduct)[] = [
  'reachSubstance',
  'bestBeforeDate',
  'childrenUnder16',
  'reachSubstancesAnnex19072006',
  'regulatedSubstance',
  'hazardousSubstancesOver1',
  'informationHazardousSubstances',
  'checkUniqueIdentification',
  'explanationNoUDI',
  'cmrSubstance',
  'CMRSubstances',
  'approvalDietFeed',
];

// excel
export const EXCEL_ERROR_VALUES = ['#N/A', '#REF!', '#NAME?', '#DIV/0!', '#NULL!', '#VALUE!', '#NUM!'];

export const HEADER_MAPPING_STATUS = {
  NO_MAPPING: 'no',
  MAPPING_SUCCESS: 'success',
  MAPPING_FAILED: 'failed',
};

export const STATUS_COLORS = {
  ERROR: '#F95E5D',
  WARNING: '#FFFF00',
  SUCCESS: '#9DC683',
  QUESTION_SUCCESS: '#9DD1DA',
  NORMAL: '#FFFFFF',
};

export const STATUS_COLORS_OPACITY = {
  ERROR: '#FFEFEE',
  WARNING: '#FFFFEC',
  SUCCESS: '#F5F9F2',
  QUESTION_SUCCESS: '#9DD1DA',
  NORMAL: '#FFFFFF',
};

export const VALID_DATE_FORMATS = [
  'dd.MM.yy',
  'dd.MM.yyyy',
  'dd/MM/yy',
  'dd/MM/yyyy',
  'dd-MM-yy',
  'dd-MM-yyyy',
  'dd MMM yy',
  'dd MMM yyyy',
  'dd MMM, yy',
  'dd MMM, yyyy',
  'yyyy-MM-dd',
];

export * from './columnDetails';
export * from './categoryTypes';
export * from './groupCategoryIds';
