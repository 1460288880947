export const fallBackContact = {
    firstName: 'Jonna',
    lastName: 'Karppanen',
    mail: 'jonna.karppanen@coop.se',
};

/**
 * Retrieves information from keycloak and builds an order contact info object.
 * Returns the object with correct values, fallback values is used if information in keycloak is missing.
 *
 * @param {Object} keycloakIdParsed the idTokenParsed from keycloak.
 */
export const buildOrderContactInfo = keycloakIdParsed => {
    // Return fallback if keycloak id isn't available or if email on keycloak id isn't set.
    if (
        !keycloakIdParsed ||
        !keycloakIdParsed?.email ||
        keycloakIdParsed.email === ''
    )
        return fallBackContact;

    fallBackContact.firstName = keycloakIdParsed.given_name || '';
    fallBackContact.lastName = keycloakIdParsed.family_name || '';
    fallBackContact.mail = keycloakIdParsed.email;

    return fallBackContact;
};
