import { FC, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import EditProductImageModal from '../EditProductImageModal';

import useControlContext from '../../hooks/useControlContext';
import useDataContext from '../../hooks/useDataContext';
import useFixedTableHeader from '../../hooks/useFixedTableHeader';

import { IImportColumn, IProductExcel } from '../../types';
import { SUPERUSER_APPROVAL } from 'components/constants-ts';
import { checkSameWithSavedBooleanValue, convertBooleanValue } from '../../utils/excel';

import ReviewTableHeaderCell from './ReviewTableHeaderCell';
import ReviewTableBodyCell from './ReviewTableBodyCell';
// special columns
import ActionColumn from './SpecialColumns/ActionColumn';
import FixedColumnList, { PIN_COLUMN } from './SpecialColumns/FixedColumnList';
// import ApproveUpdateColumn from './SpecialColumns/ApproveUpdateColumn';
import ImageColumn from './SpecialColumns/ImageColumn';

import { useStyles } from './index.style';

interface ReviewTableProps {
  dataList: IProductExcel[];
  columnList: IImportColumn[];
  isReview?: boolean;
  showErrorOnly?: boolean;
  errorDataList?: IProductExcel[];
  defaultLimit?: number;
}

const ReviewTable: FC<ReviewTableProps> = ({
  showErrorOnly,
  errorDataList,
  dataList,
  columnList,
  isReview = false,
}) => {
  const classes = useStyles();
  const {
    limit,
    page,
    tableContainerElement,
    tableDownloadContainerElement,
    setTableContainerElement,
    setTableDownloadContainerElement,
  } = useControlContext();
  const { editingImageProductData, tempApproveEanStatus, setEditingImageProductData } = useDataContext();

  const { fixedWrapper, setFixedRowElement } = useFixedTableHeader({
    tableWrapper: isReview ? tableDownloadContainerElement : tableContainerElement,
    top: isReview ? 62 : 64,
  });

  const filteredColumnList = useMemo(() => {
    if (isReview) return columnList.filter(({ field }) => !['image_src'].includes(field));
    // remove pin column and image_src column
    return columnList.filter(({ field }) => ![PIN_COLUMN, 'image_src'].includes(field));
  }, [columnList, isReview]);

  const displayDataList = useMemo(() => {
    let result = dataList.slice((page - 1) * limit, page * limit);

    // Show error products only
    if (!isReview && showErrorOnly && errorDataList?.length) {
      result = errorDataList.slice((page - 1) * limit, page * limit);
    }

    return result;
  }, [isReview, showErrorOnly, errorDataList, dataList, page, limit]);

  const renderTableHeader = useCallback(
    (isFixed?: boolean) => {
      return (
        <>
          <FixedColumnList.HeaderCellList isReview={isReview} isFakeCell={!!isFixed} columnList={columnList} />

          <ImageColumn.HeaderCell isReview={isReview} isFakeCell={!!isFixed} dataList={dataList} />

          {filteredColumnList.map((column, index) => {
            const { _id, field, title, isMissing, isExtra, isError, dataType } = column;
            let isNotCheckedAll = true;
            let checkableRowIdList: string[] = [];

            if (dataType === 'boolean' && field && dataList.length) {
              isNotCheckedAll = dataList.some(data => !convertBooleanValue(column, data));
              checkableRowIdList = dataList
                .filter(data => !checkSameWithSavedBooleanValue(column, data))
                .map(({ id }) => id || '');
            }

            return (
              <ReviewTableHeaderCell
                key={index}
                title={(title || '').split('\n')[0] || ''}
                id={_id}
                isFakeCell={!!isFixed}
                isError={isError}
                isFieldUpdatable={!isMissing && !isExtra}
                isCheckedAll={!isNotCheckedAll}
                field={field}
                isReview={isReview}
                showField={!isReview}
                dataType={dataType}
                checkableRowIdList={checkableRowIdList}
              />
            );
          })}

          <ActionColumn.HeaderCell isReview={isReview} />

          {/* Todo */}
          {/* <ApproveUpdateColumn.HeaderCell dataList={dataList} /> */}
        </>
      );
    },
    [columnList, dataList, filteredColumnList, isReview]
  );

  return (
    <Box position="relative" mt={2}>
      <div>
        {!!fixedWrapper &&
          createPortal(<TableRow ref={setFixedRowElement}>{renderTableHeader(true)}</TableRow>, fixedWrapper)}
      </div>

      <TableContainer
        ref={isReview ? setTableDownloadContainerElement : setTableContainerElement}
        className={classes.tableContainer}
      >
        <Table id="review-table" className={classes.table}>
          <TableHead>
            <TableRow>{renderTableHeader()}</TableRow>
          </TableHead>

          <TableBody>
            {displayDataList.map(data => {
              const adminStatus = tempApproveEanStatus[data.EAN || ''];
              const isApproved = adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED;

              return (
                <TableRow key={data.id}>
                  <FixedColumnList.BodyCellList
                    isReview={isReview}
                    isApproved={isApproved}
                    columnList={columnList}
                    data={data}
                  />

                  <ImageColumn.BodyCell
                    isReview={isReview}
                    data={data}
                    onClick={() => setEditingImageProductData(data)}
                  />

                  {filteredColumnList.map((column, index) => (
                    <ReviewTableBodyCell key={index} isReview={isReview} column={column} data={data} />
                  ))}

                  <ActionColumn.BodyCell isReview={isReview} data={data} />

                  {/* Todo */}
                  {/* <ApproveUpdateColumn.BodyCell data={data} /> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!!editingImageProductData && (
        <EditProductImageModal open data={editingImageProductData} onClose={() => setEditingImageProductData(null)} />
      )}
    </Box>
  );
};

export default ReviewTable;
