import React from 'react';
import styled from 'styled-components';

type THighlightedTextProps = {
    text: string | number;
    searchString?: string;
};

const Highlighted = styled.span`
    background-color: #ffff00;
`;

export const HighlightedText = ({searchString = '', text}: THighlightedTextProps) => {
    const isHighlighted = !!searchString && `${text}`.toLowerCase().includes(searchString.toLowerCase());
    if (!isHighlighted) return <span>{text}</span>;

    const normalizedSearchString = searchString.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const splittedStr = `${text}`.split(new RegExp(`(${normalizedSearchString})`, 'gi'));
    return (
        <>
            {splittedStr.map((substr, index) => {
                return substr.toLowerCase() === `${searchString}`.toLowerCase() ? (
                    <Highlighted key={`search-subtext-${index}`}>
                        {substr}
                    </Highlighted>
                ) : (
                    <span key={`search-subtext-${index}`}>{substr}</span>
                );
            })}
        </>
    );
};

export default HighlightedText;
