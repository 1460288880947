import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress  from '@material-ui/core/LinearProgress';
import Typography  from '@material-ui/core/Typography';

import VisionTab from '../tab';
// import VisionInfo from '../info';
import { fixFloat } from '../utils';

const Objects = ({visionTab, image, info, isNew }) => {
  const vert = info?.localizedObjectAnnotations?.map((item) => ({
    label: item?.name,
    coord: item?.boundingPoly?.normalizedVertices,
  }));
  return (
    <VisionTab
      index="objects"
      tabValue={visionTab}
      image={image}
      info={info}
      vertices={vert}
      normalizedVertices={true}
      showLabels={false}
      pictureWidth={7}
      isNew={isNew}
    >
      {(!info?.localizedObjectAnnotations || info?.localizedObjectAnnotations.length === 0) && (
        <Typography>No objects detected.</Typography>
      )}
      <List>
        {info?.localizedObjectAnnotations?.map((item) => (
        <ListItem>
          <div style={{width: '100%'}}>
            <Typography>{item?.name}<span style={{float: 'right'}}>{fixFloat(item?.score)}%</span></Typography>
            <LinearProgress variant="determinate" value={item?.score * 100} color="primary" />
          </div>
        </ListItem>
      //  <div>
      //     <VisionInfo title="Name" field="name" item={item} />
      //     <VisionInfo title="Score" field="score" item={item} />
      //     {item?.languageCode && item?.languageCode.trim !== "" && (
      //       <p><b>Language code:</b> {item.languageCode}</p>
      //     )}
      //     {item?.boundingPoly?.normalizedVertices?.map((coord) => (
      //       <p> (<b>x:</b> {coord?.x}, <b>y:</b> {coord?.y})</p>
      //     ))}
      //     <br />
      //   </div>
      ))}
      </List>
    </VisionTab>
  );
};

export default Objects;
