import IProduct from "models/product";

export const requiredNutritionalFields: (keyof IProduct)[] = [
  'energi_kj',
  'energi_kcal',
  'fett',
  'mattatFett',
  'kolhydrat',
  'sockerarter',
  'protein',
  'salt',
];

export const descriptiveSizeVariants = [
  30,
  50,
  60,
  80,
  100,
  150,
  200,
  250,
  300,
  350,
  400,
  500,
  600,
  700,
  750,
  800,
  900,
  1000,
  1200,
  1500,
  2000,
];

export const keyIngredients = [
  'kött',
  'kyckling',
  'vilt',
  'svin',
  'fisk',
  'räk',
  'lax',
  'salmo salar',
  'bär',
  'jordgubb',
  'smultron',
  'hallon',
  'lingon',
  'hjortron',
  'nypon',
  'äpp',
  'apelsin',
  'päron',
];

export const packageSizeUnits = [
  'pack',
  'set',
  'par',
  'styck'
]
