import React, { useContext } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery } from '@apollo/react-hooks';

import { KeycloakContext } from '../../../components/Secured';
import { STATISTIC_DATA } from './queries';
import Chart from './chart';
import { ITopProducts } from './types';
import StatisticTable from './table';
import { MenuItem, Select } from '@material-ui/core';

// import { SUPERUSER_ORGANIZATIONS_QUERY } from '../../graphql/queries';

const useStyles = makeStyles(theme => ({
  toolbarBox: {
      flexGrow: 1,
  },
  title: {
      flexGrow: 1,
  },
}));

interface IDaysSelect {
  days: number;
  title: string;
}

const SelectValues: IDaysSelect[] = [
  { title: '1 Month', days: 30 },
  { title: '2 Months', days: 60 },
  { title: '3 Months', days: 90 },
  { title: '4 Months', days: 120 },
  { title: '5 Months', days: 150 },
  { title: '6 Months', days: 180 },
  { title: '1 Year', days: 365 },
  { title: '2 Years', days: 730 },
  { title: '3 Years', days: 1095 },
  { title: '4 Years', days: 1460 },
  { title: '5 Years', days: 1825 },
];

interface IDataProps {

}

const Data = (props: IDataProps) => {
  const [days, setDays] = React.useState(60);
  let username: string|undefined = undefined;
  const keycloakCtx = useContext(KeycloakContext);
  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');
  let groups: string[]|undefined = keycloakCtx?.keycloak?.idTokenParsed?.groups;
  groups = groups?.filter((x) => !x.includes('/ica/'));
  const hasNotIcaOrg = groups && groups?.length > 0;
  // alert(hasNotIcaOrg);
  const isSuperuser = keycloakCtx.keycloak.hasResourceRole('superuser');
  if (isProducer || isStore) {
    username = keycloakCtx?.keycloak?.idTokenParsed?.preferred_username;
  }

  const classes = useStyles();
  console.log(`data page`);

    type TStatisticData = {
      by_date?: ITopProducts[];
      top_products?: ITopProducts[];
    }
    type TStatisticRoot = {
      statisticData?: TStatisticData;
    }
    type TStatisticDataVars = {
      name?: string;
      days: number;
    };
    const { data: gData, loading: statisticLoading, error, refetch, networkStatus } = useQuery<TStatisticRoot, TStatisticDataVars>(STATISTIC_DATA, {
      fetchPolicy: 'cache-and-network',
      variables: {
        name: username,
        days,
      }
    });

    React.useEffect(() => {
      refetch({
        name: username,
        days,
      })
    }, [days, username]);

    
    const chartData = (isStore && !hasNotIcaOrg) ? [] : gData?.statisticData?.by_date;
    const topProducts = (isStore && !hasNotIcaOrg) ? [] : gData?.statisticData?.top_products;
    return (
        <Paper style={{overflowX: "auto"}}>
          {statisticLoading? (
            <Box style={{display: "flex", alignItems:"center", justifyContent: "center", flexDirection: "column", padding: 10}}>
              <CircularProgress />
              <Typography>Loading lots of data...</Typography>
              <Typography>This might take a while</Typography>
            </Box>
          ):(
            <Toolbar>
                <Box className={classes.toolbarBox}>
                  <span>
                    <Select
                      value={days}
                      onChange={(e) => setDays(e.target.value as number)}
                    >
                      {SelectValues.map((select) => (
                        <MenuItem value={select.days}>{select.title}</MenuItem>
                      ))}
                    </Select>
                  </span>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: 5}}>
                      <Typography variant="body1" className={classes.title}>
                          Sales Data
                      </Typography>
                    </div>

                  {chartData && (
                    <>
                      <Chart
                        data={chartData}
                        items={[
                          { key: "revenue", stroke: "#8884d8" },
                          { key: "listView", stroke: "#82ca9d" },
                          { key: "clicks", stroke: "blue" },
                        ]}
                      />
                      <Chart
                        data={chartData}
                        items={[
                          { key: "checkouts", stroke: "#8884d8" },
                          { key: "addsToCart", stroke: "#82ca9d" },
                          { key: "removesFromCart", stroke: "blue" },
                        ]}
                      />
                      {topProducts && (
                        <StatisticTable
                          showTotal={true}
                          data={topProducts}
                          days={days}
                        />
                      )}
                    </>
                  )}
                </Box>
            </Toolbar>
          )}
      </Paper>
    );
};

export default Data;
