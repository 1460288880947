import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import { Link as RouterLink } from 'react-router-dom';
import { Player, ControlBar, ReplayControl, BigPlayButton } from 'video-react';
import printJS from 'print-js'

import 'video-react/dist/video-react.css';

import ImageGridList from '../../components/images/ImageGridList';
import ImageWithFallback from '../../components/ImageWithFallback';
import { QUERY_GET_PRODUCER_BY_ID } from '../../graphql/queries';
import { getShowcaseImage, getProducerImageSliderData } from '../../utils/producers';
import { IMAGE_SIZES, getImageWithSizeSuffix } from 'utils/images2';
import { isDefined } from '../../utils/helpers';
import CertificationsTab from '../../components/producer/CertificationsTab';
import { path } from 'ramda';

import Akkurat from '../../assets/Akkurat-Fett.otf';
import CoopNew from '../../assets/CoopNew-Regular.otf';
import CoopScript from '../../assets/CoopScript-Bold.otf';
import logo from '../../assets/logo.png';
import background from '../../assets/background.jpg';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    with: '100%',
  },
  media: {
    padding: theme.spacing(1),
    height: 160,
  },
  actions: {},
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  rootTab: {
    with: '100%',
    marginTop: theme.spacing(2),
  },
});

const Show = withStyles(styles)(({ match, permission, data, classes }) => {
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }
  let paragraphs = [];
  if (data?.description && data.description.trim() !== "") {
    paragraphs = [...paragraphs, ...data.description.split('\n'), " "];
  }
  if (data?.whattodo && data.whattodo.trim() !== "") {
    paragraphs = [...paragraphs, ...data.whattodo.split('\n'), " "];
  }
  if (data?.awards && data.awards.trim() !== "") {
    paragraphs = [...paragraphs, ...data.awards.split('\n')];
  }
  let breadcrumb;
  if (permission.role === 'admin') {
    breadcrumb = (
      <Breadcrumbs className={classes.breadcrumb} separator="›" aria-label="Breadcrumb">
        <Link color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Link color="inherit" component={RouterLink} to="/producers">
          Producers
        </Link>
        <Typography color="textPrimary">{data.name}</Typography>
      </Breadcrumbs>
    );
  }

  const viewPDF = (pdf) => {
    window.open(pdf, '_blank');
  };

  const certificates = path(['certificates'], data);
  const profileImage = getShowcaseImage(data);
  const sizedProfileImage = isDefined(profileImage) ? getImageWithSizeSuffix(profileImage, IMAGE_SIZES.LARGE) : '';

  const profileImages = getProducerImageSliderData(data);

  let story = [];
  if (data?.shortStory && data.shortStory.trim() !== "") {
    // TODO add empty lines
    story = data.shortStory.split('\n').map(s => s.trim() === "" ? " " : s);
  }

  const stories = story?.map((value, index) => {
    return `<p>${value}</p>`;
  }).join('');
  const img = sizedProfileImage && sizedProfileImage.trim() !== "" && `
  <img class="img long" src="${sizedProfileImage}" alt="" />
  `
  let signText = [];
  if (data?.signText && data?.signText?.trim() !== "") {
    // TODO add empty lines
    signText = data?.signText?.split('\n').map(s => s.trim() === "" ? " " : s);
  }
  const signTexts = signText?.map((value, index) => {
    return `<p>${value}</p>`;
  }).join('');

  const print = `
  <div class="root">
    <img class="background" src="${window.location.origin}${background}" />
    <div class="container">
      ${img}
      <div class="container2">
        <h1 class="title">${data?.name}</h1>
        <div class="description long">
          ${stories}
        </div>
        <div class="description short">
          ${signTexts}
        </div>
        ${isDefined(data?.province) ? `<p class="sign">Smakrikt från ${data?.province}</p>` : ''}
      </div>
      <div class="logo-container">
        <img class="logo" src="${window.location.origin}${logo}" />
      </div>
    </div>
  </div>
  `;

const printStyle = `
  @page {
    margin: 0mm;
  }
  @font-face {
    font-family: 'Coop Script';
    src: url(${CoopScript}) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'CoopNew';
    src: url(${CoopNew}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Akkurat';
    src: url(${Akkurat}) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  body {
    margin: 0px;
  }
  
  .background {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  .img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .container2 {
    width: 100%;
  }
  .title {
    color: #00A036;
    text-align: center;
    font-family: 'CoopNew';
    font-weight: normal;
    font-style: normal;
  }
  
  .description {
    text-align: center;
    font-family: 'Akkurat';
    font-weight: normal;
    font-style: normal;
  }

  .description p {
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .sign {
    text-align: center;
    font-family: 'Coop Script';
    font-weight: normal;
    font-style: normal;
    color: #005E43;
    margin-bottom: 0;
    margin-top: 5pt; 
  }

  .logo-container {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-end;
  }
`;

const a4style = `
${printStyle}
@page {
  size: A4 portrait;
}

.short {
  display: none;
}

.container {
  padding-top: 24.5mm;
  padding-left: 24mm;
  padding-right: 24mm;
}

.img {
  max-width: 170mm;
  max-height: 100mm;
}

.title {
  font-size: 42.46pt;
  margin-bottom: 10px;
}

.description {
  font-size: 19.11pt;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sign {
  margin-top: 10mm;
  padding-top: 0;
  font-size: 23.41pt;
}

.logo-container {
  bottom: 14.5mm;
}

.logo {
  width: 50.46mm;
  height: 50.46mm;
}
`;

const a5style = `
  ${printStyle}
  @page {
    size: A5 portrait;
  }

  .short {
    display: none;
  }

  .container {
    padding-top: 17.15mm;
    padding-left: 12mm;
    padding-right: 12mm;
  }

  .img {
    max-width: 110mm;
    max-height: 70mm;
  }

  .title {
    font-size: 28.66pt;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .description {
    margin-top: 5mm;
    padding-top: 0;
    font-size: 12.90pt;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .sign {
    margin-top: 7mm;
    padding-top: 0;
    font-size: 15.8pt;
  }

  .logo-container {
    bottom: 7.25mm;
  }

  .logo {
    width: 35mm;
    height: 35mm;
  }


`;

const a105style = `
${printStyle}
  @page {
    size: A4 landscape;
    margin: 0mm;
  }
   
  .long {
    display: none;
  }

  .background {
    height: 105mm;
  }

  .container {
    padding-top: 50mm;
    padding-left: 20.5mm;
    padding-right: 19.5mm;
  }

  .container2 {
  }

  .title {
    font-size: 43.31pt;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 0;
    top: 30mm;
    position: fixed;
  }

  .description {
    text-align: left;
    font-family: 'Akkurat';
    font-weight: normal;
    font-style: normal;
    font-size: 19.49pt;
    max-width: 155.85mm;
  }

  .sign {
    text-align: left;
    font-family: 'Coop Script';
    font-weight: normal;
    font-style: normal;
    color: #005E43;
    font-size: 24pt;
    position: fixed;
    bottom: 129.5mm;
  }

  .logo-container {

  }

  .logo {
    width: 78.15mm;
    height: 78.15mm;
    top: 13.425mm;
    right: 19.5mm;
    position: fixed;
  }
`;

const a74Style = `
${printStyle}
  @page {
    size: 210mm 74mm portrait;
  }
   
  .long {
    display: none;
  }

  .background {
    height: 74mm;
  }

  .container {
    padding-top: 35mm;
    padding-left: 14.495mm;
    padding-right: 13.788mm;
  }

  .container2 {
  }

  .title {
    font-size: 29.22pt;
    margin-bottom: 5px;
    text-align: left;
    margin-top: 0;
    top: 21mm;
    position: fixed;
  }
 

  .description {
    text-align: left;
    font-family: 'Akkurat';
    font-weight: normal;
    font-style: normal;
    font-size: 13.15pt;
    max-width:  110.64mm;
  }

  .sign {
    text-align: left;
    font-family: 'Coop Script';
    font-weight: normal;
    font-style: normal;
    color: #005E43;
    font-size: 16.19pt;
    position: fixed;
    bottom: 240.2667mm;
  }

  .logo-container {

  }

  .logo {
    width: 55.077mm;
    height: 55.077mm;
    top: 9.461mm;
    right: 13.788mm;
    position: fixed;
  }
`;

  return (
    <React.Fragment>
      {breadcrumb}
      <Card classes={{ root: classes.root }}>
        <CardContent>
          <div>
            <div style={{ width: '50%', float: 'left' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '10px',
                  flexDirection: 'column',
                }}
              >
                <ImageWithFallback style={{ maxHeight: '100%', maxWidth: '100%' }} src={sizedProfileImage}>
                  {src => {
                    return (
                      <img
                        style={{
                          maxHeight: '100%',
                          maxWidth: '100%',
                          borderRadius: '4px',
                        }}
                        src={src}
                        alt=""
                      />
                    );
                  }}
                </ImageWithFallback>

                {data?.shortStory && story?.length > 0 && (
                  <div style={{alignSelf: 'flex-start', minWidth: 400}}>
                    <h3>Skylttext A4, A5 (max 277 tecken):</h3>
                    {story.map((value, index) => {
                        return <p style={{marginTop: 0, marginBottom: 10}} key={index}>{value}</p>;
                    })}
                  </div>
                )}

                {data?.signText && signText?.length > 0 && (
                  <div style={{alignSelf: 'flex-start', minWidth: 400}}>
                    <h3>Skylttext för balk och hyllkant (max 70 tecken):</h3>
                    {signText?.map((value, index) => {
                        return <p style={{marginTop: 0, marginBottom: 10}} key={index}>{value}</p>;
                    })}
                  </div>
                )}

              </div>
            </div>
            <div style={{ width: '50%', float: 'left' }}>
              <div style={{ margin: '10px' }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {data.name}
                </Typography>
                <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
                  {paragraphs.map((value, index) => {
                    const style = {
                      marginTop: 0,
                      marginBottom: 0,
                    };
                    // if (index + 1 < paragraphs.length - 1) {
                    //   const next = paragraphs[index+1];
                    //   if (!next || next.trim() === "") {
                    //     style.marginBottom = 0;
                    //   }
                    // }
                    // if (!value || value.trim() === "") {
                    //   style.marginTop = 0;
                    //   style.marginBottom = 0;
                    // }
                    return <p style={style} key={index}>{value}</p>;
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </CardContent>
        {permission.role === 'admin' && (
          <CardActions classes={{ root: classes.actions }}>
            <Button component={RouterLink} color="secondary" variant="outlined" to={`/producers/${data.id}/products`}>
              {' '}
              All products{' '}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => printJS({
                // printable: 'print',
                printable: print,
                // type: 'html',
                type: 'raw-html',
                // font_size: '72px',
                style: a4style,
              })}
            >
              {' '}
              Print A4{' '}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => printJS({
                // printable: 'print',
                printable: print,
                // type: 'html',
                type: 'raw-html',
                // font_size: '72px',
                style: a5style,
              })}
            >
              {' '}
              Print A5{' '}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => printJS({
                // printable: 'print',
                printable: print,
                // type: 'html',
                type: 'raw-html',
                // font_size: '72px',
                style: a105style,
              })}
            >
              {' '}
              Print Balkskylt 297x105{' '}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => printJS({
                // printable: 'print',
                printable: print,
                // type: 'html',
                type: 'raw-html',
                // font_size: '72px',
                style: a74Style,
              })}
            >
              {' '}
              Print Balkskylt 210x74{' '}
            </Button>
            {/* <ReactToPrint
              pageStyle={`
                @page {
                  size: A4;
                }
              `}
              trigger={() => (
                <Button
                  color="secondary"
                  variant="outlined"
                  // onClick={handlePrint}
                >
                  {' '}
                  Print A4{' '}
                </Button>
              )}
              content={() => componentRef?.current}
            >
            </ReactToPrint> */}
          </CardActions>
        )}
      </Card>

      <Card className={classes.rootTab}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Info" />
          <Tab label="Certifications" />
          <Tab label="Email" />
          {data.video && <Tab label="Video" />}
        </Tabs>
        <Divider />
        {value === 0 && (
          <TabContainer>
            <ImageGridList profiles={profileImages} />
          </TabContainer>
        )}
        {value === 1 && <TabContainer>
          <CertificationsTab certificates={certificates} organizationView={true} onPDFButtonClicked={viewPDF} />
        </TabContainer>}
        {value === 2 && <TabContainer>{data.email}</TabContainer>}
        {value === 3 && data.video && (
          <TabContainer>
            <Player autoPlay poster={sizedProfileImage} startTime={300} src={data.video}>
              <BigPlayButton position="center" />
              <ControlBar autoHide={false}>
                <ReplayControl seconds={5} order={2.1} />
                <ReplayControl seconds={10} order={2.2} />
                <ReplayControl seconds={30} order={2.3} />
              </ControlBar>
            </Player>
          </TabContainer>
        )}
      </Card>
    </React.Fragment>
  );
});

const ShowController = ({ match, ...rest }) => {
  let producerId;
  if (match.params.id) {
    producerId = match.params.id;
    return (
      <Query query={QUERY_GET_PRODUCER_BY_ID} variables={{ id: producerId }}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error :(</p>;
          return <Show data={data.producer} match={match} permission={{ role: 'admin' }} />;
        }}
      </Query>
    );
  } else {
    return <div> Illegal access </div>;
  }
};

export default ShowController;
