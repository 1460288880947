import React, { FC, HTMLProps, useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import LightTooltip from 'components/lightTooltip';

const useStyles = makeStyles<void, { rows: number }>(() => ({
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  title: {
    flexGrow: 1,
  },
  singleTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  multipleTruncate: ({ rows }) => ({
    display: '-webkit-box',
    '-webkit-line-clamp': rows,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textWrap: 'initial',
  }),
}));

interface TruncateTextProps extends HTMLProps<HTMLDivElement> {
  disableTruncate?: boolean;
  rows?: number;
  title: string;
  fixedBefore?: React.ReactNode | React.ReactNode[];
  fixedAfter?: React.ReactNode | React.ReactNode[];
  fixedBeforeStyles?: React.CSSProperties;
  fixedAfterStyles?: React.CSSProperties;
}

const TruncateText: FC<TruncateTextProps> = ({
  disableTruncate,
  rows = 1,
  title,
  fixedBefore,
  fixedAfter,
  fixedBeforeStyles,
  fixedAfterStyles,
  style,
  ...props
}) => {
  const classes = useStyles({ rows });

  const [isTruncated, setTruncated] = useState(false);
  const [titleElement, setTitleElement] = useState<HTMLElement | null>(null);

  const renderContent = () => {
    let titleClass = classes.title;
    if (!disableTruncate) {
      titleClass = clsx(titleClass, rows === 1 ? classes.singleTruncate : classes.multipleTruncate);
    }

    return (
      <div className={classes.textWrapper}>
        {fixedBefore && <span style={fixedBeforeStyles}>{fixedBefore}</span>}
        <span ref={setTitleElement} className={titleClass}>
          {title}
        </span>
        {fixedAfter && <span style={fixedAfterStyles}>{fixedAfter}</span>}
      </div>
    );
  };

  // detect text is truncated
  useEffect(() => {
    if (!titleElement) return;
    const observer = new ResizeObserver(() => setTruncated(titleElement.offsetWidth < titleElement.scrollWidth));
    observer.observe(titleElement);
    return () => observer.disconnect();
  }, [title, titleElement]);

  if (!isTruncated) {
    return (
      <div {...props} style={{ minWidth: 0, ...style }}>
        {renderContent()}
      </div>
    );
  }

  return (
    <LightTooltip arrow title={title} placement="top">
      <div {...props} style={{ minWidth: 0, ...style }}>
        {renderContent()}
      </div>
    </LightTooltip>
  );
};

export default TruncateText;
