import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  map,
} from 'ramda';

import InputTextField from '../../../inputfields/InputTextField';
import OrganizationProductNotes from '../../OrganizationFields';
import {
  ORGANIZATION_SPECIFIC_FIELDS,
} from '../../../constants';

const useStyles = makeStyles({
  divider: {
    marginBottom: "10px",
  }
});

export const ORGANIZATIONS = [
  "coop", 
  "ica", 
  "apotea",
  "mathem",
];

export const ProducerInfo = ({ state, setState }) => {
  const classes = useStyles();

  const handleChange = event => {
    const stateName = event.target.name;
    let stateValue = event.target.value;

    console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
    setState({ ...state, [stateName]: stateValue });
  };
  const [orgs, setOrgs] = React.useState([]);
  React.useEffect(() => {
    if (!state?.producerOrganizations) {
      setOrgs([
        { name: "coop", value: true },
        { name: "ica", value: false },
        { name: "apotea", value: false },
        { name: "mathem", value: false },
      ]);
    } else {
      setOrgs([
        { name: "coop", value: !!state?.producerOrganizations?.includes("coop") },
        { name: "ica", value: !!state?.producerOrganizations?.includes("ica") },
        { name: "apotea", value: !!state?.producerOrganizations?.includes("apotea") },
        { name: "mathem", value: !!state?.producerOrganizations?.includes("mathem") },
      ]);
    }
  }, [state?.producerOrganizations]);
  return (
    <>
      <>
        <>
          <h3>Producer info</h3>
          <Divider classes={{ root: classes.divider }} />
          <InputTextField
            disabled
            inputName="producerUsername"
            label="Producer username"
            required
            value={state.producerUsername}
          />
          <InputTextField
            disabled
            inputName="producerName"
            label="Producer name"
            required
            value={state.producerName}
          />
          <InputTextField
            disabled
            inputName="producerEmail"
            label="Producer email"
            required
            value={state.producerEmail}
          />
          {orgs.map(org => (
            <FormControlLabel disabled control={<Checkbox name={org.name} checked={org.value} />} label={org.name} />
          ))}
        </>
        
        <div style={{display: 'none'}}>
        <h3>Organization specific product fields</h3>
          <Divider />
          <h4 style={{ marginBottom: 2 }}>Coop</h4>
          <OrganizationProductNotes
            state={state}
            setState={setState}
            fields={map(
              f => ({
                inputName: f.fieldName,
                value: state[`${f.fieldName}`],
                formLabel: f.formLabel,
                info: f.info,
              }),
              ORGANIZATION_SPECIFIC_FIELDS.COOP
            )}
            onFieldValueChanged={handleChange}
        />
      </div>

      </>
    </>
  );
};

export default ProducerInfo;
