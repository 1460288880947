import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

import VirtualScrollbar from 'components/VirtualScrollbar';
import FooterWrapper from '../components/FooterWrapper';
import ReviewTable from './ReivewTable';
import UploadImageButton from './UploadImageButton';
import ImportDataButton from './ImportDataButton';
import Header from './Header';

import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';
import useProductData from 'hooks/useProductData';

import { IImportColumn, IProductExcel } from '../types';
import { ALLOWED_IMAGE_FILE_TYPE_LIST, COLUMN_DETAILS } from '../constants';
import { APPLICATION_ROLES, NON_FOOD_CATEGORY_ID } from 'components/constants-ts';
import { isNewFood, isNewNonFood } from 'components/product/ProductUpdateForm/fields/category';
import { transformCategoryRead } from 'utils/products';
import {
  mapExcelColumn,
  isErrorCell,
  removeUnnecessaryColumns,
  updateMandatoryColumns,
  checkExtraCorrespondingColumn,
  addExtraCorrespondingColumn,
  isEmptyCell,
  parseImageUrl,
} from '../utils/excel';
import { filterAllowedImages, revertProduct } from '../utils';
import CommonQuestions from './CommonQuestions';
import LoadingBackdrop from '../components/LoadingBackdrop';

const useStyles = makeStyles(() => ({
  fixedFooterWrapper: {
    display: 'flex',
    gap: 16,
    height: 0,
    position: 'fixed',
    padding: '8px 16px',
    zIndex: 20,
    bottom: 0,
    background: '#fafafa',
  },
  dropzoneBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    borderWidth: 0,
    color: '#FFFFFF',
  },
  backdrop: {
    position: 'fixed',
    zIndex: 2000,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    color: 'white',
  },
}));

interface DropzoneProductImageProps {}

const DropzoneProductImage: FC<DropzoneProductImageProps> = () => {
  const classes = useStyles();
  const { editingImageProductData, onProductImageChange } = useDataContext();

  const [draggingCount, setDraggingCount] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop: (files: File[]) => {
      const acceptedImages = filterAllowedImages(files);
      setDraggingCount(0);
      onProductImageChange(acceptedImages);
    },
    onDragLeave: () => setDraggingCount(0),
  });

  useEffect(() => {
    const handleDragEnter = (event: DragEvent) => {
      if (!event.dataTransfer?.items?.length) return;
      const { items } = event.dataTransfer;
      let count = 0;
      for (let index = 0; index < items.length; index++) {
        const { type } = items[index];
        if (ALLOWED_IMAGE_FILE_TYPE_LIST.includes(type)) count++;
      }

      if (count) {
        setDraggingCount(count);
      }
    };
    const handleDragLeave = () => setDraggingCount(0);

    if (!editingImageProductData?.id) {
      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragend', handleDragLeave);
    }
    return () => {
      document.removeEventListener('dragenter', handleDragEnter);
      document.removeEventListener('dragend', handleDragLeave);
    };
  }, [editingImageProductData?.id]);

  return (
    <div
      {...getRootProps({ className: classes.dropzoneBackground })}
      style={{
        opacity: draggingCount ? 1 : 0,
        zIndex: draggingCount ? 2000 : -1,
        background: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <input {...getInputProps()} />
      {!!draggingCount && (
        <Box className="dropzone" bgcolor="transparent" height="100%" justifyContent="center">
          <Typography variant="h5" color="inherit">
            {draggingCount} {draggingCount > 1 ? 'images' : 'image'}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const DataReviewing = () => {
  const classes = useStyles();
  const { isLoadingProductsByEans, getProductsByEans } = useProductData();

  const { userRole, selectedCategoryTypeId, productType, isConvertingImages, tableContainerElement } =
    useControlContext();
  const {
    rootCategory,
    isLoadedImageFromIndexDB,
    isLoadedSavedProduct,
    isUploadedSuccessfully,
    setLoadedSavedProduct,
    dataList,
    excelColumnList,
    productImageDataSet,
    setDataList,
    onAllImageFileDataChange,
  } = useDataContext();

  const isProducerRole = userRole === APPLICATION_ROLES.PRODUCER;
  const isStoreRole = userRole === APPLICATION_ROLES.STORE;

  const [showErrorOnly, setShowErrorOnly] = useState(false);

  const filteredDataList = useMemo(() => {
    if (isProducerRole || isStoreRole) return dataList;
    if (!productType) return [];
    return dataList.filter(product => {
      if (productType === NON_FOOD_CATEGORY_ID) {
        return isNewNonFood(product.foodlaCategory, rootCategory);
      } else {
        return isNewFood(product.foodlaCategory, rootCategory);
      }
    });
  }, [isProducerRole, isStoreRole, rootCategory, productType, dataList]);

  const mappedExcelColumnList = useMemo<IImportColumn[]>(() => {
    let columnList = excelColumnList.map(column => {
      let field = column.field;
      if (productType !== NON_FOOD_CATEGORY_ID && field === 'brand') {
        field = 'brand_food';
      }

      return mapExcelColumn({ ...column, field }, dataList);
    });

    columnList = addExtraCorrespondingColumn(columnList, selectedCategoryTypeId);
    columnList = updateMandatoryColumns({ columnList, dataList: filteredDataList });
    columnList = removeUnnecessaryColumns({ productType, columnList });
    return columnList;
  }, [selectedCategoryTypeId, excelColumnList, filteredDataList, productType, dataList]);

  const filteredColumnList = useMemo(() => {
    if (!filteredDataList.length) return mappedExcelColumnList;

    const existedFieldSet = new Set();
    return mappedExcelColumnList.filter(column => {
      const { field, isExtra, isMandatory, isNutritionalFood } = column;
      // don't use [isEmptyColumn] because column can be empty after user edit cells
      const emptyCells = filteredDataList.filter(
        data => isEmptyCell(column, { ...data, ...data._prasedData }) && isEmptyCell(column, data, { byTitle: true })
      );
      const isParsedEmptyColumn = emptyCells.length === filteredDataList.length;

      if (isExtra) return true;
      if (checkExtraCorrespondingColumn(field)) return true;
      if (!field) return false;
      // remove duplicate column by [field]
      if (existedFieldSet.has(field)) return false;
      if (field) existedFieldSet.add(field);

      if (isMandatory || !isParsedEmptyColumn) return true;
      if (productType !== NON_FOOD_CATEGORY_ID && isNutritionalFood) return true;
      return false;
    });
  }, [productType, filteredDataList, mappedExcelColumnList]);

  const errorDataList = useMemo(() => {
    return filteredDataList.filter(data => {
      const imageFileDataList = productImageDataSet[String(data.id || '')] || [];
      return (
        (!data.image_src && !imageFileDataList.length) ||
        filteredColumnList.some(column => column.field !== 'image_src' && isErrorCell(column, data))
      );
    });
  }, [filteredDataList, filteredColumnList, productImageDataSet]);

  const getSavedProductList = useCallback(async () => {
    setLoadedSavedProduct(true);

    const eans = dataList.map(({ EAN }) => _.toString(EAN)).filter(EAN => EAN);

    const savedProducts = await getProductsByEans(eans);

    const pulledImageDataList = dataList.flatMap(data => {
      const savedProduct = savedProducts.find(({ EAN }) => EAN === data.EAN);
      // form saved product
      const imageUrls = savedProduct?.additionalImages || [];
      if (savedProduct?.image_src) imageUrls.unshift(savedProduct?.image_src);
      // from excel
      if (data.image_src) imageUrls.push(data.image_src);

      return imageUrls.map(url => parseImageUrl(url, { assignProductId: data.id, isFromProduct: true }));
    });

    onAllImageFileDataChange(oldList => [...oldList, ...pulledImageDataList]);

    // Merge saved product to dataList
    setDataList(
      dataList.map(oldData => {
        const data = _.cloneDeep(oldData);
        delete data._savedData;
        delete data._revertedSavedData;

        const savedProduct = savedProducts.find(({ EAN }) => EAN === data.EAN);
        if (!savedProduct) return data;

        transformCategoryRead(savedProduct);
        const revertedSavedProduct = revertProduct(savedProduct);
        const notNilData = _.omitBy<IProductExcel>(data, _.isNil);

        // [_.merge] will mutates [revertedSavedProduct]. Should cloneDeep [revertedSavedProduct] it first
        let mergedData = _.merge(_.cloneDeep(revertedSavedProduct), notNilData) as IProductExcel;
        // Check: imported [foodlaCategory] -> existing [foodlaCategory] -> default [foodlaCategory]
        if (!mergedData._prasedExcelData.foodlaCategory && savedProduct.foodlaCategory) {
          mergedData = { ...mergedData, foodlaCategory: savedProduct.foodlaCategory };
        }

        COLUMN_DETAILS.forEach(columnDetail => {
          const hasDefaultValue = !_.isNil(columnDetail.defaultValue);

          const isImported =
            !_.isNil(mergedData[columnDetail.field]) &&
            excelColumnList.some(({ isExtra, field }) => !isExtra && field === columnDetail.field);

          // check if value is [defaultValue]
          if (isImported || !hasDefaultValue) return;

          if (!_.isNil(revertedSavedProduct[columnDetail.field])) {
            // replace [defaultValue] by saved value
            (mergedData[columnDetail.field] as any) = revertedSavedProduct[columnDetail.field];
          }
        });

        mergedData._savedData = savedProduct;
        mergedData._revertedSavedData = revertedSavedProduct;

        return _.omitBy<IProductExcel>(mergedData, _.isNil) as IProductExcel;
      })
    );
  }, [dataList, excelColumnList, setLoadedSavedProduct, getProductsByEans, setDataList, onAllImageFileDataChange]);

  useEffect(() => {
    if (dataList.length && excelColumnList.length && !isLoadedSavedProduct && isLoadedImageFromIndexDB) {
      getSavedProductList();
    }
  }, [isLoadedImageFromIndexDB, isLoadedSavedProduct, dataList, excelColumnList, getSavedProductList]);

  return (
    <Box position="relative">
      <Header
        showErrorOnly={showErrorOnly}
        errorCount={errorDataList.length}
        columnList={filteredColumnList}
        dataList={filteredDataList}
        onShowErrorChange={setShowErrorOnly}
      />

      {!isUploadedSuccessfully && (
        <VirtualScrollbar targetElement={tableContainerElement} bottom={73}>
          <ReviewTable
            showErrorOnly={showErrorOnly}
            errorDataList={errorDataList}
            dataList={filteredDataList}
            columnList={filteredColumnList}
          />
        </VirtualScrollbar>
      )}

      <Box mt={3} fontSize={16}>
        Här kan du korrigera fel och ta bort kolumner som är tomma.
      </Box>

      {!isUploadedSuccessfully && <CommonQuestions />}

      <DropzoneProductImage />

      {isConvertingImages && (
        <div className={classes.backdrop}>
          <CircularProgress size={24} />
          <Typography variant="body1" color="inherit">
            Converting images...
          </Typography>
        </div>
      )}

      <FooterWrapper>
        <UploadImageButton />
        <ImportDataButton dataList={filteredDataList} columnList={filteredColumnList} />
      </FooterWrapper>

      <LoadingBackdrop open={isLoadingProductsByEans} label="Loading product..." />
    </Box>
  );
};

export default DataReviewing;
