import styled from 'styled-components';
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';

export const useDropzoneStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    padding: 20,
    background: '#EDEEF3',
    borderColor: '#B0B5C9',
    border: '1px dashed #B0B5C9',
    borderRadius: 4,
  },
  title: {
    color: '#545D7D',
    fontSize: 16,
    textAlign: 'center',
  },
  orClick: {
    color: '#8188B3',
    fontSize: 14,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;
  .MuiFormControlLabel-label {
    font-size: 14px;
    color: #545d7d;
    user-select: none;
  }
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-thumb {
    color: #ffffff;
  }
  .Mui-checked .MuiSwitch-thumb {
    color: #56aba9;
  }
  .MuiSwitch-track {
    background-color: #d7dae4;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: #bbdddd;
  }
`;
