import React, { useEffect, useMemo } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
  map,
} from 'ramda';

import InputLabel from '../../../inputfields/InputLabel';
import InputTextField from '../../../inputfields/InputTextField';
import ListDropDown from '../../../inputfields/ListDropDown';
import {
  APPLICATION_ROLES,
  getDescriptiveSizeEnumFromValue,
  getDescriptiveSizeValueFromEnum,
} from '../../../constants';
import { DESCRIPTIVE_SIZE_UNITS_MAP } from '../../../constants-ts';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import DescriptiveSize from '../DescriptiveSize';
import {
  descriptiveSizeVariants,
} from '../constants';
import { toSafeFloat } from '../../../../utils/helpers';
import { checkErrorGrossWeightAndNetWeight } from './gross-weight';
import useViewport from 'hooks/useViewports';

export const checkDescriptiveError = (data) => {
  if (checkErrorGrossWeightAndNetWeight(data)) {
    return `Net weight must be smaller than gross weight (currently ${data.gross_weight_num})`;
  }
  return '';
}

export const Descriptive = ({ state, setState, approve, userRole, isFood }) => {
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;

  const { smUp } = useViewport();
  
  const [onlyApproximateUnits, setOnlyApproximateUnits] = React.useState(false);

  const error = useMemo(() => {
    return checkDescriptiveError(state);
  }, [state]);

  useEffect(() => {
    if (
      state?.EAN?.trim().startsWith('23')
      || state?.EAN?.trim().startsWith('2097')
      || state?.EAN?.trim().startsWith('2000')
    ) {
      setOnlyApproximateUnits(true);
    } else {
      setOnlyApproximateUnits(false);
    }
  }, [state, state.EAN]);

  const handleDescriptiveSizeAmountChange = event => {
    // Enforce float if descriptive_size_amount
    const stateName = event.target.name;
    let stateValue = event.target.value;

    stateValue = 0;
    try {
      stateValue = toSafeFloat(event.target.value || 0);
    } catch (e) {
      // Show error
    }
    console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
    setState({ ...state, [stateName]: stateValue });
  };
  const handleDescriptiveSizeUnitChange = event => {
    const enumValue = getDescriptiveSizeEnumFromValue(event.target.value);
    let descriptive_size_amount_extra = state.descriptive_size_amount_extra;
    if (event.target.value !== "GRAM_APPROXIMATE") {
      if (descriptive_size_amount_extra && Array.isArray(descriptive_size_amount_extra) && descriptive_size_amount_extra.length > 0) {
        descriptive_size_amount_extra = [];
      }
    }
    setState({ ...state, [event.target.name]: enumValue, descriptive_size_amount_extra });
  };
  return (
      <>
        <InputLabel
          heading="Nettokvantitet"
          text="Produkten ska märkas med information om volym, storlek eller vikt beroende på typ av produkt (för t.ex. vätskor, livsmedel, varor). Läs mer om nettokvantitet för livsmedel här."
          link="https://kontrollwiki.livsmedelsverket.se/artikel/45/nettokvantitet"
          required
        />
          <div style={{ display: 'flex', flexDirection: smUp ? 'row' : 'column', flex: 1 }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <InputTextField
                fullWidth
                id={'descriptive_size_amount'}
                forceRed={!!error}
                label="Mängd"
                margin="normal"
                inputName={'descriptive_size_amount'}
                value={state.descriptive_size_amount}
                variant="outlined"
                disabled={state?.isMagazine}
                onChange={handleDescriptiveSizeAmountChange}
              style={{ flex: smUp ? 0.3 : 0.5 }}
              />
              <ListDropDown
                label="Enhet"
                disabled={state?.isMagazine}
                listData={map(({ value }) => ({ value }), onlyApproximateUnits ? DESCRIPTIVE_SIZE_UNITS_MAP.filter((x) => x.isEan2) : DESCRIPTIVE_SIZE_UNITS_MAP)}
                handleChange={handleDescriptiveSizeUnitChange}
                value={getDescriptiveSizeValueFromEnum(state.descriptive_size_unit)}
                targetName="descriptive_size_unit"
                style={{ marginLeft: 15, paddingTop: 5, flex: smUp ? 0.7 : 0.5, maxWidth: '100%', overflow: 'hidden' }}
              />
            </div>
            {isSuperuser && approve && state?.approve?.find((x) => x.field === 'nettokvantitet') && (
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <ThreeWaySwitch
                  name="nettokvantitet"
                  state={state}
                  setState={setState}
                  style={{ marginLeft: 15, marginTop: smUp ? 5 : 0, flexShrink: 0, maxHeight: 56, justifySelf: 'end' }}
                />
              </div>
            )}
            </div>
            {!!error && (
              <span style={{ fontWeight: 'bold', color: 'orangered' }}>
                {error}
              </span>
            )}
            {state.descriptive_size_amount_extra && state.descriptive_size_amount_extra.map((dsa, index) => (
               <DescriptiveSize index={index} state={state} setState={setState} />
            ))}
            {state.descriptive_size_unit === "GRAM_APPROXIMATE" && isFood && (
              <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <AddCircleIcon style={{color: "rgb(0, 163, 71)"}} fontSize="small" />
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  const newState = {...state, descriptive_size_amount_extra: [...state.descriptive_size_amount_extra, descriptiveSizeVariants[0]]};
                  setState(newState);
                }}> Extra fält för fler nettokvantiteter av samma produkt med samma EAN</a>
              </div>
            )}
      </>
  );
}

export default Descriptive;
