import React from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell, { SortDirection } from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";

import orderBy from "lodash/orderBy";
import sumBy from 'lodash/sumBy';

import { ITopProducts } from "./types";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Row from "./row";

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

interface IStatisticTableProps {
  data?: ITopProducts[];
  days?: number;
  showTotal?: boolean;
}
  
const StatisticTable = ({ data, days, showTotal }: IStatisticTableProps) => {
  const classes = useStyles();
 
  const [orderProperty, setOrderProperty] = React.useState<keyof ITopProducts>('checkouts');
  const [orderDirection, setOrderDirection] = React.useState<SortDirection>("desc");
  const [topProducts, setTopProducts] = React.useState(data);

  React.useEffect(() => {
    if (data) {
      if (orderDirection && orderProperty) {
        setTopProducts(orderBy(data, [orderProperty], [orderDirection]));
      }
    } else {
      setTopProducts([]);
    }
  }, [orderProperty, orderDirection, data]);

  const onSort = (property: keyof ITopProducts) => {
    const isAsc = orderProperty === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderProperty(property);
  };

  let properties: string[] = [];
  if (topProducts && topProducts.length > 0) {
    properties = Object.getOwnPropertyNames(topProducts[0]).filter(x => x !== 'id' && x !== 'ean');
  }
  return (
    <TableContainer component={Paper} >
      <Table
        // className={classes.table}
        aria-label="simple table"
      >
        <TableHead>
            <TableRow>
              <TableCell />
              { (topProducts && topProducts.length > 0) && properties.map(row => (
                <TableCell
                  key={row}
                  align={row === 'name' ? 'left' : 'right'}
                  sortDirection={orderProperty === row ? orderDirection : false}
                >
                  <TableSortLabel
                    active={orderProperty === row}
                    direction={orderProperty === row ? (orderDirection === false ? undefined : orderDirection) : 'asc'}
                    onClick={() => onSort(row as any)}
                  >
                    {row}
                    {orderProperty === row ? (
                      <span className={classes.visuallyHidden}>
                        {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
        </TableHead>
        <TableBody>
          {(topProducts && topProducts.length > 0) && topProducts.map((row) => (
            <Row row={row} days={days} />
          ))}
          {showTotal && topProducts && topProducts.length > 0 && (
            <>
              <TableCell></TableCell>
              <TableCell align="right"><b>Total:</b></TableCell>
              {properties.map((p) => {
                if (p === 'name') {
                  return undefined
                  // return <TableCell></TableCell>
                } else {
                  return (
                    <TableCell align="right">{sumBy(topProducts, p)}</TableCell>
                  );
                }
              })}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatisticTable;
