import React, { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';
import TruncateText from 'components/TruncateText';

import IProduct from 'models/product';
import { DataType } from '../../types';
import { QUESTION_FIELDS, STATUS_COLORS, STATUS_COLORS_OPACITY } from '../../constants';
import useControlContext from '../../hooks/useControlContext';
import useDataContext from '../../hooks/useDataContext';

import SortIcon from 'components/icons/SortIcon';
import SelectField, { getDisplayFieldName } from '../../components/SelectField';
import { Checkbox } from '../../components/Checkbox';
import ValuesSuggestion from '../../components/ValuesSuggestion';

import { CELL_SPACING, useStyles } from './index.style';

const MIN_WIDTH = 100 - CELL_SPACING.X * 2;
const TITLE_RIGHT_TEXT_WIDTH = 25;
const TITLE_GAP = 8;

const useCustomStyles = makeStyles(() => ({
  resizeBar: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    width: 8,
    right: -4,
    top: 0,
    height: '100%',
    cursor: 'ew-resize',
  },
  highlightBorder: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    borderTop: '3px solid transparent',
  },
  fieldText: {
    lineHeight: '16px',
    color: '#383D52',
  },
}));

const resizeColumn = (dataField: string, width: number) => {
  const cellWrapperNodeList = document.querySelectorAll<HTMLElement>(`[data-field='${dataField}']`);
  cellWrapperNodeList.forEach(element => {
    element.style.width = `${width}px`;
    element.dataset.width = String(width);
  });
};

export interface ReviewTableHeaderCellProps extends Omit<TableCellProps, 'title'> {
  minWidth?: number;
  isReview: boolean;
  resizable?: boolean;
  isFakeCell?: boolean;
  isFieldUpdatable?: boolean;
  isCheckedAll?: boolean;
  isError?: boolean;
  showField?: boolean;
  title?: React.ReactNode;
  dataType: DataType;
  id: string;
  field: string;
  wrapperProps?: HTMLAttributes<HTMLElement>;
  checkableRowIdList?: string[];
}

const ReviewTableHeaderCell: FC<ReviewTableHeaderCellProps> = ({
  minWidth: minWidthProp,
  isReview,
  isFakeCell,
  resizable = true,
  isFieldUpdatable = true,
  isCheckedAll = false,
  isError = false,
  showField = true,
  title = '',
  dataType,
  id,
  field = '',
  className,
  children,
  style,
  wrapperProps,
  checkableRowIdList,
  ...props
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { categoryColumnList } = useControlContext();
  const { onRemoveColumn, onUpdateColumnField, onChangeAllDataValue } = useDataContext();

  const keepRef = useRef({ oldLeft: 0, oldWidth: 0 });

  const [isResizing, setResizing] = useState(false);
  const [isReadyForResize, setReadyForResize] = useState(false);
  const [disableTruncateTitle, setDisableTruncateTitle] = useState(!isFakeCell);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null);

  const minWidth = useMemo(() => {
    return minWidthProp ? Math.max(minWidthProp - CELL_SPACING.X * 2, 0) : MIN_WIDTH;
  }, [minWidthProp]);

  const selectFieldElement = useMemo(() => {
    return (
      <SelectField
        outlined
        columnId={id}
        value={field}
        categoryColumnList={categoryColumnList}
        renderValue={value => (
          <div style={{ display: 'flex', gap: 4, alignItems: 'end' }}>
            <TruncateText
              rows={isReview ? 1 : 2}
              title={getDisplayFieldName(value as keyof IProduct)}
              style={{ fontSize: 12 }}
            />
            <ValuesSuggestion field={field as keyof IProduct} />
          </div>
        )}
        IconComponent={iconProps => <SortIcon {...iconProps} width={16} height={16} />}
        onRemoveColumn={onRemoveColumn}
        onUpdateColumnField={onUpdateColumnField}
      />
    );
  }, [isReview, categoryColumnList, field, id, onRemoveColumn, onUpdateColumnField]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!wrapperElement) return;
    keepRef.current = { oldWidth: wrapperElement.offsetWidth, oldLeft: event.pageX };
    setResizing(true);
  };

  // handle resize
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!wrapperElement || !isResizing) return;
      const { oldWidth, oldLeft } = keepRef.current;
      const newWidth = oldWidth + event.pageX - oldLeft;

      keepRef.current = { oldWidth: newWidth, oldLeft: event.pageX };
      resizeColumn(field, Math.max(newWidth, minWidth));
    };

    if (resizable && isResizing) document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [resizable, field, minWidth, wrapperElement, isResizing]);

  // handle stop resizing
  useEffect(() => {
    const handleMouseUp = () => {
      keepRef.current = { oldWidth: 0, oldLeft: 0 };
      setResizing(false);
    };

    if (resizable && isResizing) document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, [resizable, isResizing]);

  // Make table non-selectable while user is resizing
  useEffect(() => {
    const tableElement = document.getElementById('review-table');
    if (resizable && tableElement) tableElement.style.userSelect = isResizing ? 'none' : '';
  }, [resizable, isResizing]);

  useEffect(() => {
    setReadyForResize(!!wrapperElement);
  }, [wrapperElement]);

  useEffect(() => {
    if (minWidth && !resizable) {
      resizeColumn(field, minWidth);
    }

    // make sure set default width 1 time
    if (!resizable || !wrapperElement || wrapperElement?.dataset?.width || !isReadyForResize) return;

    // get the largest columns' width
    let maxWidth = 0;
    const cells = document.querySelectorAll<HTMLElement>(`[data-field='${field}']`);
    cells.forEach(cell => (maxWidth = Math.max(maxWidth, cell.offsetWidth)));

    // set default columns' width
    let width = Math.max(maxWidth, minWidth) / 2 + TITLE_RIGHT_TEXT_WIDTH + TITLE_GAP;
    setDisableTruncateTitle(false);
    // adding 60 pixel will help [TruncateText] work correctly
    resizeColumn(field, width + 60);
  }, [isReadyForResize, dataType, resizable, field, minWidth, wrapperElement]);

  const isQuestionField = QUESTION_FIELDS.includes(field as keyof IProduct);
  let highlightColor = isQuestionField ? STATUS_COLORS.QUESTION_SUCCESS : STATUS_COLORS.SUCCESS;
  let background = isQuestionField ? STATUS_COLORS_OPACITY.QUESTION_SUCCESS : STATUS_COLORS_OPACITY.SUCCESS;
  if (isError) {
    highlightColor = STATUS_COLORS.ERROR;
    background = STATUS_COLORS_OPACITY.ERROR;
  }
  if (field === 'id') {
    highlightColor = '';
    background = '';
  }
  if (isReview) {
    background = highlightColor;
  }

  return (
    <TableCell
      {...props}
      className={clsx(classes.headCell, className)}
      style={{
        ...style,
        background,
        height: !isFakeCell ? 1 : undefined,
      }}
    >
      <div className={customClasses.highlightBorder} style={{ borderColor: highlightColor }} />

      <div
        ref={setWrapperElement}
        data-field={field}
        {...wrapperProps}
        style={{ display: 'flex', flexDirection: 'column', height: '100%', fontSize: 12 }}
      >
        {!!title && _.isString(title) && (
          <div
            className={clsx(!isReview && field !== 'id' && classes.headerCellTitle)}
            style={{ flex: '1', display: 'flex', gap: TITLE_GAP, justifyContent: 'space-between' }}
          >
            <TruncateText disableTruncate={!isReview && disableTruncateTitle} rows={isReview ? 1 : 2} title={title} />
            {!isReview && dataType === 'boolean' && !!checkableRowIdList?.length && (
              <span style={{ width: TITLE_RIGHT_TEXT_WIDTH }}>Alla</span>
            )}
          </div>
        )}

        {!!title && !_.isString(title) && title}

        {!isReview && showField && (
          <div style={{ flex: '1', display: 'flex', gap: 8, justifyContent: 'space-between', alignItems: 'end' }}>
            {isFieldUpdatable ? (
              selectFieldElement
            ) : (
              <TruncateText
                className={customClasses.fieldText}
                rows={isReview ? 1 : 2}
                title={getDisplayFieldName(field as keyof IProduct)}
              />
            )}
            {dataType === 'boolean' && !!checkableRowIdList?.length && (
              <Checkbox
                checked={isCheckedAll}
                onChange={checked =>
                  onChangeAllDataValue(field as keyof IProduct, String(checked), { idList: checkableRowIdList })
                }
              />
            )}
          </div>
        )}
      </div>
      {resizable && <div className={clsx(customClasses.resizeBar, 'resize-bar')} onMouseDown={handleMouseDown} />}
    </TableCell>
  );
};

export default ReviewTableHeaderCell;
