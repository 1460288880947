import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface IChartItem<T> {
  key: keyof T;
  stroke?: string;
}

interface IChartProps<T> {
  data?: T[];
  items?: IChartItem<T>[];
}

const Chart = <T, >({ data, items }: IChartProps<T>) => {
  return (
    <ResponsiveContainer width={"95%"} height={300} >
      <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          {items && items.map((item) => (
            <Line type="monotone" dataKey={item.key as string} stroke={item.stroke} />
          ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
