import axios from 'axios';
import { path } from 'ramda';

export const uploadFile = async (file, request) => {
  console.log(`Uploading file: ${JSON.stringify(file, null, 2)}`);
  console.log(`Using request: ${JSON.stringify(request, null, 2)}`);

  const requestOptions = {
    headers: {
      'Content-Type': file.type,
    },
  };

  const signedRequest = path(['signedRequest'], request);

  return axios.put(signedRequest, file, requestOptions)
}