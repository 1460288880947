import React from 'react';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';

import { map } from 'ramda';

import { toSafeFloat } from '../../../utils/helpers';
import ListDropDown from '../../inputfields/ListDropDown';
import { descriptiveSizeVariants } from './constants';
import IProduct from '../../../models/product';

interface IDescriptiveSizeProps {
  index?: number;
  state: IProduct;
  setState: (state: IProduct) => void;
}

export const DescriptiveSize = ({index = 0, state, setState}: IDescriptiveSizeProps) => {
  // TODO any
  const handleDropDownAmountChange = (event: any) => {
    let stateValue = 30;
    try {
      stateValue = toSafeFloat(event.target.value);
    } catch (e) {
      // Show error
    }
    const descriptive_size_amount_extra = state.descriptive_size_amount_extra || [];
    descriptive_size_amount_extra[index] = stateValue;
    setState({ ...state, descriptive_size_amount_extra });
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
      <ListDropDown
          label="Mängd"
          listData={map((value) => ({ value }), descriptiveSizeVariants)}
          handleChange={handleDropDownAmountChange}
          value={state?.descriptive_size_amount_extra?.[index]}
          targetName={`descriptive_size_amount_extra[${index}]`}
          style={{ flex: 0.4, marginBottom: 8 }}
      />
      <IconButton
          style={{color: "red", flex: 0.05}}
          onClick={(e) => {
            e.preventDefault();
            const amounts = state.descriptive_size_amount_extra;
            if (amounts) {
              amounts.splice(index, 1);
              // delete amounts[index];
              setState({...state, descriptive_size_amount_extra: amounts});
            }
          }}
      >
        <RemoveCircleIcon fontSize="large" />
      </IconButton>
    </div>
  );
};
  
export default DescriptiveSize;
