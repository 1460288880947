// Dependencies
import React, { useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import isNil from 'lodash/isNil';

// Components
import ErrorBoundary from '../../components/ErrorBoundary';
import ProducerCardList from '../../components/producer/ProducerCardList';
import Breadcrumbs from '../../components/breadCrumbs/BreadCrumbs';
import { KeycloakContext } from '../../components/Secured';

// Queries
import { PRODUCER_CARD_LIST_QUERY, PRODUCERS_ADMIN } from '../../graphql/queries';
import { IS_FOOD_LOCAL_STORAGE_KEY } from '../../constants/domain';
import { parseBoolean } from '../../utils/helpers';

const Producers = () => {
    const keycloakCtx = useContext(KeycloakContext);
    const isAdmin = keycloakCtx.keycloak.hasResourceRole('admin');
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    const vars = {
        filterStoresByOrg: isAdmin,
        filterStoresByParentOrg: isStore,
    };
    const isFood = localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY);
    if (!isNil(isFood)) {
        if (typeof isFood === 'boolean') {
            vars.isFood = isFood;
        } else if (typeof isFood === 'string') {
            vars.isFood = parseBoolean(isFood);
        }
    }
    let query = PRODUCER_CARD_LIST_QUERY;
    if (isAdmin) {
        query = PRODUCERS_ADMIN;
        if (isNil(isFood) || isFood === 'true' || isFood === true) {
            vars.isFood = true;
        } else {
            vars.isFood = false;
        }
        vars.showAllProducers = false;
        vars.onlyApproved = true;   
    }
    return (
        <ErrorBoundary>
            <Breadcrumbs breadCrumbs={[{ title: 'Home', linkTo: '/' }]} label="Producers" />
            <Paper>
                <ProducerCardList showSearch={true} producersQuery={query} variables={vars} />
            </Paper>
        </ErrorBoundary>
    );
};

export default Producers;
