import React from 'react';
import NotAvaialbeImage from '../assets/image_not_available.jpg';

export default class ImageWithFallback extends React.Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.img = new Image();
        this.img.onerror = this.onerror;
        this.img.onload = this.onload;

        this.state = { src: props.src, hasError: false };
    }

    onerror = (event) => {
        if (this._isMount) {
            this.setState({ src: NotAvaialbeImage, hasError: true });
        }
    };

    onload = props => {
        if (this._isMount) {
            this.setState({ src: this.props.src, hasError: false });
        }
    };

    componentDidMount() {
        this._isMount = true;
        this.img.src = this.props.src;
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    render() {
        return this.props.children(this.state.src);
    }
}
