import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import ListDropDown from "components/inputfields/ListDropDown";
import MultipleSelect from "components/inputfields/MultipleSelect";
import MultipleGroupedSelect from "components/inputfields/MultipleGroupedSelect";

import Gas from "components/icons/Gas";
import Explosive from "components/icons/Explosive";
import Oxidizing from "components/icons/Oxidizing";
import Flammable from "components/icons/Flammable";
import Corrosive from "components/icons/Corrosive";
import HealthHazard from "components/icons/HealthHazard";
import AcuteToxicity from "components/icons/AcuteToxicity";
import SeriousHealthHazard from "components/icons/SeriousHealthHazard";
import HazardEnvironment from "components/icons/HazardEnvironment";

import {
  signalWords,
  hazardStatements,
  securityNotices
} from '../../../constants-ts';

import { HazardSymbolsEnum } from "../../../../models/product";
import IProduct from "models/product";
import { IFoodlaCategoryOption } from "models/category";
import { isChemical, isCmrHazardCategory, isPharma, isToy } from "./category";
import ThreeWaySwitch from "components/inputfields/ThreeWaySwitch";

interface INewHazardDataProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory?: IFoodlaCategoryOption;
};

export const hazardSymbols = [
  { value: 'GAS', displayName: HazardSymbolsEnum.GAS, image:<Gas /> },
  { value: 'EXPLOSIVE', displayName: HazardSymbolsEnum.EXPLOSIVE, image: <Explosive /> },
  { value: 'OXIDISING', displayName: HazardSymbolsEnum.OXIDISING, image: <Oxidizing /> },
  { value: 'FLAMMABLE', displayName: HazardSymbolsEnum.FLAMMABLE, image: <Flammable /> },
  { value: 'CORROSIVE', displayName: HazardSymbolsEnum.CORROSIVE, image: <Corrosive /> },
  { value: 'HEALTH_HAZARD', displayName: HazardSymbolsEnum.HEALTH_HAZARD, image: <HealthHazard /> },
  { value: 'ACUTE_TOXICITY', displayName: HazardSymbolsEnum.ACUTE_TOXICITY, image: <AcuteToxicity /> },
  { value: 'SERIOUS_HEALTH_HAZARD', displayName: HazardSymbolsEnum.SERIOUS_HEALTH_HAZARD, image: <SeriousHealthHazard /> },
  { value: 'HAZARDOUS_ENVIRONMENT', displayName: HazardSymbolsEnum.HAZARDOUS_ENVIRONMENT, image: <HazardEnvironment /> }
];

const HazardFields = ({ approve, state, setState, rootCategory }: INewHazardDataProps) => {
    const handleDropdownChange = (
      event: React.ChangeEvent<{
          name?: string | undefined;
          value: unknown;
      }>,
    ) => {
        const stateName = event.target.name;
        let stateValue = event.target.value;
  
        const val = !stateValue || stateValue === '' ? null : stateValue;
        stateName && setState({ ...state, [stateName]: val });
    };

    const shouldShowFields = (
      (isPharma(state?.foodlaCategory, rootCategory) ||
        isCmrHazardCategory(state?.foodlaCategory, rootCategory)
      ) && !(isChemical(state?.foodlaCategory, rootCategory) ||
        isToy(state?.foodlaCategory, rootCategory)
      )
    );

    const shouldShowSignalFields = (isToy(state?.foodlaCategory, rootCategory) || isChemical(state?.foodlaCategory, rootCategory))
      && (state?.signalWords as string === "WARNING" || state?.signalWords as string === "DANGER");

    return (
      <div style={{width: '100%'}}>
        <InputLabel
          heading="Signalord"
          text="En kemisk produkts signal ord anger farans relativa allvarlighet."
          link="https://www.av.se/halsa-och-sakerhet/kemiska-risker-och-luftfororeningar/vagledningen-till-foreskrifterna-om-kemiska-arbetsmiljorisker/information-fran-leverantoren/leverantorens-markning/"
          required={isToy(state?.foodlaCategory, rootCategory) || isChemical(state?.foodlaCategory, rootCategory)}
        />
        <div style={{ display: 'flex', width: '100%' }}>
          <ListDropDown
            disableLabel={true}
            listData={signalWords}
            value={state?.signalWords}
            targetName="signalWords"
            handleChange={handleDropdownChange}
            displayEmpty={true}
            style={{ flexGrow: 1, overflow: 'hidden' }}
          />
          {approve && state?.approve?.find(x => x.field === 'signalWords') && (
            <ThreeWaySwitch
              state={state}
              style={{ height: 56 }}
              setState={setState}
              name="signalWords"
            />
          )}
        </div>
        { (shouldShowFields || shouldShowSignalFields) && (
          <>
            <InputLabel
              heading="Välj ett eller flera faropiktogram"
              text="Alla farliga kemiska produkter ska vara tydligt märkta med farosymbol (faropiktogram) samt risk- och skyddsinformation på svenska. Märkningen informerar om skador som kan uppstå vid användning. Du kan välja flera alternativ."
              link="https://www.av.se/globalassets/filer/halsa-och-sakerhet/luftfororeningar-och-kemiska-risker/kemivagledning/clp-farosymboler-piktogram.pdf"
              required={isToy(state?.foodlaCategory, rootCategory) || isChemical(state?.foodlaCategory, rootCategory)}
            />
            <MultipleSelect
              disableLabel={true}
              listData={hazardSymbols}
              value={state?.hazardSymbols ?? []}
              targetName="hazardSymbols"
              handleChange={handleDropdownChange}
            />
             <InputLabel
                heading="Faroangivelser"
                text="Faroangivelser är standardiserade fraser som informerar om vilka farliga egenskaper ett ämne eller en blandning har. Du kan välja flera alternativ."
                link="https://www.kemi.se/download/18.53bd575e1770fc5d2a22b22/1612190079455/Lista med faroangivelser_2021.pdf"
                required={isToy(state?.foodlaCategory, rootCategory) || isChemical(state?.foodlaCategory, rootCategory)}
              />
              <MultipleGroupedSelect
                disableLabel={true}
                categoryData={hazardStatements}
                value={state?.hazardStatements ?? []}
                targetName="hazardStatements"
                handleChange={handleDropdownChange}
                showSearch
              />
              <InputLabel
                heading="Skyddsangivelser"
                text="Skyddsangivelser är standardiserade fraser som informerar om hur man ska hantera produkten för att skydda sig själv och miljön. Du kan välja flera alternativ."
                link="https://www.kemi.se/download/18.53bd575e1770fc5d2a22b23/1612190094216/Lista på skyddsangivelser_2021.pdf"
                required={isToy(state?.foodlaCategory, rootCategory) || isChemical(state?.foodlaCategory, rootCategory)}
              />
              <MultipleGroupedSelect
                disableLabel={true}
                categoryData={securityNotices}
                value={state?.securityNotices ?? []}
                targetName="securityNotices"
                handleChange={handleDropdownChange}
                showSearch
              />
          </>
        )}
      </div>
      );
}

export default HazardFields;
