import { path, uniq } from 'ramda';
import { isDefined } from './helpers';

export const getShowcaseImage = producer => {
    let imageAvatar = '';

    const profileImages = path(['profiles'], producer);
    if (!isDefined(profileImages) || profileImages.length === 0) imageAvatar = '';
    else imageAvatar = profileImages[0];

    // Some images have sufix for sizing in backend. Clean this here and use what the client decides instead.
    const cleaned = imageAvatar.split('?');
    return isDefined(cleaned) && cleaned.length > 1 ? cleaned[0] : imageAvatar;
};

export const getProducerImageSliderData = producer => {
    const profilePictures = path(['profiles'], producer);
    return isDefined(profilePictures) ? uniq(profilePictures) : [];
};
