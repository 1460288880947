import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';

import useControlContext from '../hooks/useControlContext';

import { CATEGORY_TYPE_DETAILS, CATEGORY_GROUPS, LOCAL_STORE_ITEMS } from '../constants';

import LightTooltip from 'components/lightTooltip';
import FooterWrapper from '../components/FooterWrapper';
import FilledQuestion from 'components/icons/FilledQuestion';
import Header from './Header';

const CategorySelect = () => {
  const { selectedCategoryTypeId, setSelectedCategoryTypeId } = useControlContext();

  const handleSelect = (id: string) => {
    setSelectedCategoryTypeId(id);
    localStorage.setItem(LOCAL_STORE_ITEMS.CATEGORY_TYPE_ID, id);
  };

  return (
    <>
      <Header />
      <Box my={2} flexGrow={1} display="flex" justifyContent="center">
        <h1 style={{ margin: 0 }}>Vilken typ av produkter ska du ladda upp eller uppdatera idag?</h1>
      </Box>
      <Box flexGrow={1} display="flex" justifyContent="center">
        Vänligen välj typ av kategori för dina produkter och tryck på “Nästa” för att ladda ner en mall och säkerställa
        att vi kontrollera alla tillämplig information i vår kvalitetsgranskning.
      </Box>
      <Box flexGrow={1} display="flex" justifyContent="center">
        Tryck på frågetecknet för att se exempel på produkter som ingår i kategorin.
      </Box>

      {CATEGORY_GROUPS.map(categoryGroup => (
        <Box key={categoryGroup} flexGrow={1} display="flex" maxWidth="100%">
          <Box flexGrow={1}>
            <Box mt={2} mb={1} sx={{ fontSize: 16, lineHeight: 1.2 }}>
              {categoryGroup}
            </Box>

            <Grid container spacing={2} style={{ maxWidth: '100%' }}>
              {CATEGORY_TYPE_DETAILS.filter(categoryType => categoryType.categoryGroup === categoryGroup).map(
                categoryType => (
                  <Grid key={categoryType.id} item xs={3}>
                    <Box
                      display="flex"
                      bgcolor="#fff"
                      border={1}
                      borderColor={selectedCategoryTypeId === categoryType.id ? 'blue' : '#D7DAE4'}
                      p={1.5}
                      borderRadius={4}
                      boxShadow="0px 0.5px 4px 0px rgba(0, 0, 0, 0.15)"
                      style={{ gap: 8, cursor: 'pointer' }}
                      onClick={() => handleSelect(categoryType.id)}
                    >
                      <Box
                        flexShrink={0}
                        bgcolor="#FAFBFF"
                        border={1}
                        borderColor="#C5C9D8"
                        flexBasis={65}
                        height={45}
                        borderRadius={4}
                        px={1}
                        py={0.5}
                      >
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                          <img
                            src={categoryType.icon}
                            alt={categoryType.name}
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                          />
                        </Box>
                      </Box>

                      <Box flexGrow={1} display="flex" alignItems="start" width="full" style={{ gap: 6 }}>
                        <Box
                          mt={0.25}
                          p={0.125}
                          flexShrink={0}
                          border={1}
                          borderColor={selectedCategoryTypeId === categoryType.id ? '#000000' : '#C5C9D8'}
                          borderRadius="50%"
                        >
                          <Box
                            height={8}
                            width={8}
                            borderRadius="50%"
                            bgcolor={selectedCategoryTypeId === categoryType.id ? '#000000' : ''}
                          />
                        </Box>

                        <Box flexGrow={1} display="inline" color="#000" fontSize={14} lineHeight={1.2}>
                          {categoryType.name}
                        </Box>

                        <LightTooltip
                          placement="right"
                          interactive
                          title={
                            <Box color="#000" fontSize={14} onClick={event => event.stopPropagation()}>
                              {categoryType.descriptions || ''}
                            </Box>
                          }
                        >
                          <Box display="inline" pt={0.225}>
                            <FilledQuestion />
                          </Box>
                        </LightTooltip>
                      </Box>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Box>
      ))}

      <Box flexGrow={1} display="flex">
        <FooterWrapper isLeftChildren>
          <Box display="flex" alignItems="center" gridGap={4}>
            <Box display="inline" color="#000" fontSize={14} lineHeight={1.2}>
              Har du fler än en typ av produkt att ladda upp?
            </Box>

            <LightTooltip
              placement="top"
              title={
                <Box color="#000" fontSize={14}>
                  För närvarande på vår plattform kan du bara ladda upp en typ av produkt åt gången.
                </Box>
              }
            >
              <Box display="inline" pt={0.5}>
                <FilledQuestion />
              </Box>
            </LightTooltip>
          </Box>
        </FooterWrapper>
      </Box>
    </>
  );
};

export default CategorySelect;
