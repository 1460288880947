import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';

import InputTextField from "components/inputfields/InputTextField";
import IProduct from "models/product";
import FloatInput from "../FloatInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { isDefined } from "utils/helpers-ts";
import { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const isEmpty = (product?: IProduct) => {
  if (!product) return true;
  if (
    // (product.palletLayerUOMType && product.palletLayerUOMType.trim() !== '')
    (product.palletLayerEANForStorePack && product.palletLayerEANForStorePack.trim() !== '')
    || isDefined(product.palletLayerQtyOfBaseItem)
    || isDefined(product.palletLayerQtyOfNextLowerItem)
    || isDefined(product.palletLayerQtyOfNextLowerItem)
    || product.palletLayerOrderableUnit
    || product.palletLayerDespatchUnit
    || isDefined(product.palletLayerTradeItemMeasurementsLengthDepth)
    || isDefined(product.palletLayerTradeItemMeasurementsWidth)
    || isDefined(product.palletLayerTradeItemMeasurementsHeight)
    || isDefined(product.palletLayerGrossWeight)
  ) {
    return false;
  }
  return true;
};

interface IPalletLayerProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const PalletLayer = ({ state, setState }: IPalletLayerProps) => {
  const classes = useStyles();
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });
  const onLabelChanged = ({ target }: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setState({...state, [target.name]: checked }); 
  };
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    setExpanded(!isEmpty(state));
  }, []);
  const handleAccordionChange = (event: any, isExpanded?: boolean) => {
    setExpanded(!!isExpanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>Pall/Pallet Layer</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {/* <InputTextField
            state={state}
            setState={setState}
            inputName="palletLayerUOMType"
            value={state.palletLayerUOMType}
            label="Unit of measure type"
            onChange={handleChange}
            withLabel
          /> */}
          <InputTextField
            state={state}
            setState={setState}
            inputName="palletLayerEANForStorePack"
            value={state.palletLayerEANForStorePack}
            label="EAN/GTIN for store pack"
            onChange={handleChange}
            withLabel
          />
          <FloatInput
            name="palletLayerQtyOfBaseItem"
            label="Qty of base item"
            state={state}
            setState={setState}
            dense={false}
          />
          <FloatInput
            name="palletLayerQtyOfNextLowerItem"
            label="Qty of next lower item"
            state={state}
            setState={setState}
            dense={false}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.palletLayerOrderableUnit}
                onChange={onLabelChanged}
                name="palletLayerOrderableUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Orderable Unit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.palletLayerDespatchUnit}
                onChange={onLabelChanged}
                name="palletLayerDespatchUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Despatch Unit"
          />
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <FloatInput
              name="palletLayerTradeItemMeasurementsLengthDepth"
              label="Depth/Length"
              state={state}
              setState={setState}
              dense={false}
            />
            <span style={{alignSelf: 'center', fontSize: 25}}>&nbsp;x&nbsp;</span>
            <FloatInput
              name="palletLayerTradeItemMeasurementsWidth"
              label="Width"
              state={state}
              setState={setState}
              dense={false}
            />
            <span style={{alignSelf: 'center', fontSize: 25}}>&nbsp;x&nbsp;</span>
            <FloatInput
              name="palletLayerTradeItemMeasurementsHeight"
              label="Height"
              state={state}
              setState={setState}
              dense={false}
            />
            <h3 style={{alignSelf: 'center'}}>&nbsp;millimeter</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <FloatInput
              name="palletLayerGrossWeight"
              label="Gross weight"
              state={state}
              setState={setState}
              dense={false}
            />
            <h3 style={{alignSelf: 'center'}}>&nbsp;gram</h3>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default PalletLayer;
