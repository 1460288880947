import IProduct from 'models/product';
import DateField from 'components/inputfields/DateField';

interface IOrderableUnitProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const PurchaseRelatedFields = ({ state, setState }: IOrderableUnitProps) => {
  const handleChange = (name: string, dateTime: number | null) => {
    setState({ ...state, [name]: dateTime });
  };

  return (
    <>
      <DateField
        name="basicDataAvailabilityDateFrom"
        value={state.basicDataAvailabilityDateFrom}
        placeholder="Ex. 2020-02-04"
        labelOptions={{ heading: 'Lanseringsdag mot konsument för nyheter' }}
        onChange={handleChange}
      />
      <DateField
        name="articleFirstOrderDate"
        value={state.articleFirstOrderDate}
        placeholder="Ex. 2020-02-01"
        labelOptions={{ heading: 'Första beställningsdag för nyheter' }}
        onChange={handleChange}
      />
      <DateField
        name="articleFirstDelivery"
        value={state.articleFirstDelivery}
        placeholder="Ex. 2020-02-03"
        labelOptions={{ heading: 'Första leveransdag för nyheter' }}
        onChange={handleChange}
      />
    </>
  );
};

export default PurchaseRelatedFields;
