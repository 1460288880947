import React, { FC } from 'react';

const InfoIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
        fill="#9CA3BC"
      />
    </svg>
  );
};

export default InfoIcon;
