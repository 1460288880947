import IProduct from 'models/product';
import { ALL_GROUP_CATEGORY_IDS, GROUP_CATEGORY_IDS } from './groupCategoryIds';
import { ReactNode } from 'react';
import { productMarkings } from 'components/product/ProductUpdateForm/fields/labels';
import { countries } from 'constants/domain';
import {
  animalCategoriesForFeed,
  catchArea,
  categoryWeightloss,
  fishCatchMethod,
  fishProductionMethod,
  preparedForm,
  recyclingType,
} from 'components/constants-ts';

const renderSuggestionList = (list: { displayName: string }[], options?: { notTitle?: boolean }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      {!options?.notTitle && <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 6 }}>Acceptabla värden</div>}
      {list.map(({ displayName }, index) => (
        <div key={index}>{displayName}</div>
      ))}
    </div>
  );
};

export const COLUMN_DETAILS: {
  title: string;
  field: keyof IProduct;
  fieldMappingName?: string;
  isExtra?: boolean;
  extraCorrespondingField?: keyof IProduct;
  searchTitleList?: string[];
  showInCategories: string[];
  mandatoryInCategories: string[];
  defaultValue?: string;
  mandatoryDefaultValue?: string;
  suggestions?: ReactNode;
}[] = [
  {
    title: 'Kategori',
    field: 'foodlaCategory',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Produktnamn',
    field: 'title',
    fieldMappingName: 'Produktnamn',
    searchTitleList: [
      'Benämning',
      'Produktnamn',
      'Artikelbenämning',
      'Artikelnamn',
      'Livsmedlets beteckning (produktnamn)',
      'Produktnamn/beteckning',
      'Beteckning',
      'Produkt beteckning',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'EAN-kod (13 eller 8 siffror)',
    field: 'EAN',
    fieldMappingName: 'EAN-kod (13 eller 8 siffror)',
    searchTitleList: [
      'På konsumentförpackningen angiven EAN-kod (13 eller 8 siffror)',
      'EAN-kod konsumentförpackning\nand\nGTIN',
      'EAN-kod (13 eller 8 siffori)',
      'EAN-kod',
      'GTIN',
      'EAN',
      'EAN-kod konsumentförpackning (8 eller 13)',
      'EAN-kod konsumentförpackning (8 eller 13 siffror)',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Bilder',
    field: 'image_src',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Leverantörens artikelnummer',
    field: 'purchasingDataSupplierArticleNo',
    fieldMappingName: 'Leverantörens artikelnummer',
    searchTitleList: ['Artnr'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'Produktbeskrivning',
    field: 'short_text',
    fieldMappingName: 'Produktbeskrivning',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'Skriv en kort säljande beskrivning av produkten (max 25 ord)',
    field: 'shortSalesDescription',
    fieldMappingName: 'Kort säljade beskrivning av produkten (max 25 ord)',
    searchTitleList: [
      'Skriv en kort säljande beskrivning av produkten (max 25 ord)',
      'Kort säljade beskrivning av produkten (max 25 ord)',
      'Säljande beskrivning',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'Ingrediensförteckning',
    field: 'ingredient_statement',
    fieldMappingName: 'Ingrediensförteckning',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
  },
  {
    title: 'Fodersammansättning',
    field: 'animalFoodIngredients',
    fieldMappingName: 'Fodersammansättning',
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
  },
  {
    title: 'Innehållsförteckning',
    field: 'non_food_ingredients',
    fieldMappingName: 'Innehållsförteckning',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    ],
  },
  {
    title: 'Märkningar',
    field: 'labels',
    fieldMappingName: 'Märkningar',
    searchTitleList: ['Eventuella Märkningar (t.ex. Svanen, Certified Vegan)', 'Eventuella Märkningar'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
    suggestions: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 6 }}>Acceptabla värden</div>
        {productMarkings.map(({ label, listData }, index) => (
          <div key={index}>
            {label && <p style={{ fontWeight: 700 }}>{label}</p>}
            {renderSuggestionList(listData, { notTitle: true })}
          </div>
        ))}
      </div>
    ),
  },
  {
    title: 'Näringsvärde per 100 Gram eller milliliter',
    field: 'nutritional_unit',
    fieldMappingName: 'Näringsenhet: Gram eller milliliter (näringsvärde per 100)',
    defaultValue: 'gram',
    searchTitleList: ['Näringsenhet: Gram eller milliliter (näringsvärde per 100)'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin A',
    field: 'vitaminA',
    fieldMappingName: 'Vitamin A',
    searchTitleList: ['Vitamin A μg', 'Vitamin A mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin D',
    field: 'vitaminD',
    fieldMappingName: 'Vitamin D',
    searchTitleList: ['Vitamin D μg', 'Vitamin D mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin E',
    field: 'vitaminE',
    fieldMappingName: 'Vitamin E',
    searchTitleList: ['Vitamin E μg', 'Vitamin E mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin K',
    field: 'vitaminK',
    fieldMappingName: 'Vitamin K',
    searchTitleList: ['Vitamin K μg', 'Vitamin K mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin C',
    field: 'vitaminC',
    fieldMappingName: 'Vitamin C',
    searchTitleList: ['Vitamin C μg', 'Vitamin C mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Tiamin',
    field: 'tiamin',
    fieldMappingName: 'Tiamin',
    searchTitleList: ['Tiamin μg', 'Tiamin mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Riboflavin',
    field: 'riboflavin',
    fieldMappingName: 'Riboflavin',
    searchTitleList: ['Riboflavin μg', 'Riboflavin mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Niacin',
    field: 'niacin',
    fieldMappingName: 'Niacin',
    searchTitleList: ['Niacin μg', 'Niacin mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin B6',
    field: 'vitaminB6',
    fieldMappingName: 'Vitamin B6',
    searchTitleList: ['Vitamin B6 μg', 'Vitamin B6 mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Folsyra',
    field: 'folsyra',
    fieldMappingName: 'Folsyra',
    searchTitleList: ['Folsyra μg', 'Folsyra mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Vitamin B12',
    field: 'vitaminB12',
    fieldMappingName: 'Vitamin B12',
    searchTitleList: ['Vitamin B12 μg', 'Vitamin B12 mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Biotin',
    field: 'biotin',
    fieldMappingName: 'Biotin',
    searchTitleList: ['Biotin μg', 'Biotin mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Pantotensyra',
    field: 'pantotensyra',
    fieldMappingName: 'Pantotensyra',
    searchTitleList: ['Pantoensyra', 'Pantoensyra μg', 'Pantoensyra mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Kalium',
    field: 'kalium',
    fieldMappingName: 'Kalium',
    searchTitleList: ['Kalium μg', 'Kalium mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Klorid',
    field: 'klorid',
    fieldMappingName: 'Klorid',
    searchTitleList: ['Klorid μg', 'Klorid mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Kalcium',
    field: 'kalcium',
    fieldMappingName: 'Kalcium',
    searchTitleList: ['Kalcium μg', 'Kalcium mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Fosfor',
    field: 'fosfor',
    fieldMappingName: 'Fosfor',
    searchTitleList: ['Fosfor μg', 'Fosfor mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Magnesium',
    field: 'magnesium',
    fieldMappingName: 'Magnesium',
    searchTitleList: ['Magnesium μg', 'Magnesium mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Järn',
    field: 'jarn',
    fieldMappingName: 'Järn',
    searchTitleList: ['Järn μg', 'Järn mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Zink',
    field: 'zink',
    fieldMappingName: 'Zink',
    searchTitleList: ['Zink μg', 'Zink mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Koppar',
    field: 'koppar',
    fieldMappingName: 'Koppar',
    searchTitleList: ['Koppar μg', 'Koppar mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Mangan',
    field: 'mangan',
    fieldMappingName: 'Mangan',
    searchTitleList: ['Mangan μg', 'Mangan mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Fluorid',
    field: 'fluorid',
    fieldMappingName: 'Fluorid',
    searchTitleList: ['Fluorid μg', 'Fluorid mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Selen',
    field: 'selen',
    fieldMappingName: 'Selen',
    searchTitleList: ['Selen μg', 'Selen mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Krom',
    field: 'krom',
    fieldMappingName: 'Krom',
    searchTitleList: ['Krom μg', 'Krom mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Molybden',
    field: 'molybden',
    fieldMappingName: 'Molybden',
    searchTitleList: ['Molybden μg', 'Molybden mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Jod',
    field: 'jod',
    fieldMappingName: 'Jod',
    searchTitleList: ['Jod μg', 'Jod mg'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Energi kJ',
    field: 'energi_kj',
    fieldMappingName: 'Energi kJ',
    searchTitleList: ['Energi'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Energi kcal',
    field: 'energi_kcal',
    fieldMappingName: 'Energi kcal',
    searchTitleList: ['Kcal'],
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Fett',
    field: 'fett',
    fieldMappingName: 'Fett',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Mättat fett',
    field: 'mattatFett',
    fieldMappingName: 'Mättat fett',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Enkelomättat fett',
    field: 'enkelomattatFett',
    fieldMappingName: 'Enkelomättat fett',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Fleromättat fett',
    field: 'flerromattatFett',
    fieldMappingName: 'Fleromättat fett',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Kolhydrat',
    field: 'kolhydrat',
    fieldMappingName: 'Kolhydrat',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Sockerarter',
    field: 'sockerarter',
    fieldMappingName: 'Sockerarter',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Polyoler',
    field: 'polyoler',
    fieldMappingName: 'Polyoler',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Stärkelse',
    field: 'starkelse',
    fieldMappingName: 'Stärkelse',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Fiber',
    field: 'fiber',
    fieldMappingName: 'Fiber',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Protein',
    field: 'protein',
    fieldMappingName: 'Protein',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Salt',
    field: 'salt',
    fieldMappingName: 'Salt',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Nettokvantitet',
    field: 'descriptive_size_amount',
    fieldMappingName: 'Nettokvantitet',
    searchTitleList: ['Nettokvantitet mängd'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
  },
  {
    title: 'Nettokvantitet (ytterligare mängd)',
    field: 'descriptive_size_amount_extra',
    fieldMappingName: 'Nettokvantitet (ytterligare mängd gram)',
    searchTitleList: ['Nettokvantitet mängd extra', 'Nettokvantitet (ytterligare mängd gram)'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Nettokvantitet enhet',
    field: 'descriptive_size_unit',
    fieldMappingName: 'Nettokvantitet enhet',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    searchTitleList: ['Enhet'],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
  },
  {
    title: 'Förvaringstemperatur min',
    field: 'min_temperature',
    fieldMappingName: 'Förvaringstemperatur min',
    searchTitleList: ['Förvaringstemperatur Min (°C)'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Förvaringstemperatur max',
    field: 'max_temperature',
    fieldMappingName: 'Förvaringstemperatur max',
    searchTitleList: ['Förvaringstemperatur Max (°C)'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Förvaringsanvisning',
    field: 'trade_item_temperature_information',
    fieldMappingName: 'Förvaringsanvisning',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'Ursprungsland och härkomstplats',
    field: 'place_of_item_activity',
    fieldMappingName: 'Ursprungsland och härkomstplats',
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Produktionsmetod',
    field: 'fishProductionMethod',
    fieldMappingName: 'Produktionsmetod',
    searchTitleList: ['Produktionsmetod\nInlandsfiske, Havsfiske, Odling'],
    showInCategories: [GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER],
    mandatoryInCategories: [],
    suggestions: renderSuggestionList(fishProductionMethod),
  },
  {
    title: 'Fiskemetod',
    field: 'fishCatchMethod',
    fieldMappingName: 'Fiskemetod',
    searchTitleList: ['Fiskemetod\ne.g. Trålar'],
    showInCategories: [GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER],
    mandatoryInCategories: [],
    suggestions: renderSuggestionList(fishCatchMethod),
  },
  {
    title: 'Fångstområde',
    field: 'catchArea',
    fieldMappingName: 'Fångstområde',
    searchTitleList: ['Fångstområde\ne.g. Norra Ishavet'],
    showInCategories: [GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER],
    mandatoryInCategories: [],
    suggestions: renderSuggestionList(catchArea),
  },
  {
    title: 'Ägg viktklass',
    field: 'eggWeightClass',
    fieldMappingName: 'Ägg viktklass',
    searchTitleList: ['Klass A Viktklass\nS, S/M, M, M/L, L, L/XL, XL'],
    showInCategories: [GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER],
    mandatoryInCategories: [],
  },
  {
    title: 'Godkännandenummer för äggpackeri',
    field: 'eggPackerApprovalNumber',
    fieldMappingName: 'Godkännandenummer för äggpackeri',
    searchTitleList: ['Ägg godkännandenummer äggpackeri'],
    showInCategories: [GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER],
  },
  {
    title: 'Frukt och grönt klassifiering',
    field: 'classification',
    fieldMappingName: 'Frukt och grönt klassifiering',
    searchTitleList: ['Klassifiering\nKlass 1, 2, 3', 'Klassifiering: Klass 1, 2, 3'],
    showInCategories: [GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER],
  },
  {
    title: 'Alkoholhalt',
    field: 'alcoholPercentage',
    fieldMappingName: 'Alkoholhalt',
    showInCategories: [GROUP_CATEGORY_IDS.DRYCKER],
    mandatoryInCategories: [],
  },
  {
    title: 'Panttyp',
    field: 'recyclingType',
    fieldMappingName: 'Panttyp',
    searchTitleList: ['Panttyp\ne.g. glasflaska 500 ml 12%'],
    showInCategories: [GROUP_CATEGORY_IDS.DRYCKER],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.DRYCKER],
    suggestions: renderSuggestionList(recyclingType),
  },
  {
    title: 'Tillverkningsland',
    field: 'country_of_manufacturing_string',
    fieldMappingName: 'Tillverkningsland',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER],
    suggestions: renderSuggestionList(countries.map(({ sv }) => ({ displayName: sv }))),
  },
  {
    title: 'Sökbegrepp och nyckelord',
    field: 'search_terms',
    fieldMappingName: 'Sökbegrepp och nyckelord',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'B-packstorlek (Kfp/Dfp)',
    field: 'consumer_size_to_order_size_ratio',
    fieldMappingName: 'B-packstorlek (Kfp/Dfp)',
    searchTitleList: [
      'B-packstorlek (Antal konsumentförpackningar per distributionsförpackning, Kfp/Dfp)',
      'B-packstorlek (Kfp/Dfp)',
      'B-packstorlek, antal konsumentförpackningar per distributionsförpackning (Kfp/Dfp)',
      'B-packstorlek',
      'Bpackstorlek',
      'B packstorlek',
      'Kfp/Dfp',
      'Antal konsumentförpackningar per distributionsförpackning (Kfp/Dfp)',
      'Antal konsumentförpackningar per distributionsförpackning',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Bruttovikt',
    field: 'gross_weight_num',
    fieldMappingName: 'Bruttovikt',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Konsumentförpackningens dimensioner Höjd (MM)',
    field: 'gross_height',
    fieldMappingName: 'Konsumentförpackningens höjd (MM)',
    searchTitleList: [
      'Höjd',
      'Konsumentförpackningens dimensioner Höjd',
      'Konsumentförpackningens höjd (MM)',
      'Förpackningens dimensioner Höjd (MM)',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Konsumentförpackningens dimensioner Bredd (MM)',
    field: 'gross_width',
    fieldMappingName: 'Konsumentförpackningens bredd (MM)',
    searchTitleList: [
      'Bredd',
      'Konsumentförpackningens dimensioner Bredd',
      'Konsumentförpackningens bredd (MM)',
      'Förpackningens dimensioner Bredd (MM)',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Konsumentförpackningens dimensioner Djup (MM)',
    field: 'gross_depth',
    fieldMappingName: 'Konsumentförpackningens djup (MM)',
    searchTitleList: [
      'Djup',
      'Konsumentförpackningens dimensioner Djup',
      'Konsumentförpackningens djup (MM)',
      'Förpackningens dimensioner Djup (MM)',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Varumärke',
    field: 'brand',
    fieldMappingName: 'Varumärke',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Varumärke',
    field: 'brand_food',
    fieldMappingName: 'Varumärke',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Adress till producent',
    field: 'location',
    fieldMappingName: 'Adress till producent',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Färg',
    field: 'color',
    fieldMappingName: 'Färg',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Material',
    field: 'material',
    fieldMappingName: 'Material',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Mått',
    field: 'dimensions',
    fieldMappingName: 'Mått',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Volym',
    field: 'capacity',
    fieldMappingName: 'Volym',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Användningsinstruktioner och dosering',
    field: 'dosageAndUsageInstructions',
    fieldMappingName: 'Användningsinstruktioner och dosering',
    searchTitleList: [
      'Användningsinstruktioner, inklusive eventuella försiktighetsåtgärder',
      'Dosering och användningsinstruktioner',
      'Användningsinstruktioner',
      'Dosering och användsinstruktioner',
    ],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
    ],
  },
  {
    title: 'Tillagningsanvisning',
    field: 'preparationInstructions',
    fieldMappingName: 'Tillagningsanvisning',
    showInCategories: [
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Signalord',
    field: 'signalWords',
    fieldMappingName: 'Signalord',
    searchTitleList: ['Signalord\nFara, Varning, eller Ej Tillämpbar'],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    ],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.LEKSAK, GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER],
  },
  {
    title: 'Ange ett eller flera faropiktogram',
    field: 'hazardSymbols',
    fieldMappingName: 'Ange ett eller flera faropiktogram',
    searchTitleList: ['Ange ett eller flera faropiktogram\ne.g. explosivt, giftigt'],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Faroangivelser Kod',
    field: 'hazardStatements',
    fieldMappingName: 'Faroangivelser kod',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Skyddsangivelser Kod',
    field: 'securityNotices',
    fieldMappingName: 'Skyddsangivelser kod',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Godkännande nummer ansvarig märkning',
    field: 'responsibleLabelingApprovalNumber',
    fieldMappingName: 'Godkännande nummer ansvarig märkning',
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
  },
  {
    title: 'Djurkategori för foder',
    field: 'animalCategoriesForFeed',
    fieldMappingName: 'Djurkategori för foder',
    searchTitleList: ['Djurkategori för foder\ne.g Häst, hund, katt'],
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    suggestions: renderSuggestionList(animalCategoriesForFeed),
  },
  {
    title: 'Fodertillsatser',
    field: 'feedAdditives',
    fieldMappingName: 'Fodertillsatser',
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
  },
  {
    title: 'Analytiska beståndsdelar',
    field: 'analyticalConstituentsFeed',
    fieldMappingName: 'Analytiska beståndsdelar',
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
  },
  {
    title: 'Utfordringsinstruktioner',
    field: 'feedingInstructions',
    fieldMappingName: 'Utfordringsinstruktioner',
    searchTitleList: ['Utfodringsinstruktioner'],
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
  },
  {
    title: 'Ålderskategori',
    field: 'animalAgeGroup',
    fieldMappingName: 'Ålderskategori',
    searchTitleList: ['Ålderskategori\ne.g. 5-8 år'],
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [],
  },
  {
    title: 'Beredningsform',
    field: 'preparedForm',
    fieldMappingName: 'Beredningsform',
    searchTitleList: ['Beredningsform\ne.g tabeletter, spray, våtservett'],
    showInCategories: [
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
    ],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER, GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL],
    suggestions: renderSuggestionList(preparedForm),
  },
  {
    title: 'Solskyddsfaktor (SPF)',
    field: 'sunProtectionFactor',
    fieldMappingName: 'Solskyddsfaktor (SPF)',
    searchTitleList: ['Solskyddsfaktor (SPF)\ne.g. 30, 50, 50+'],
    showInCategories: [GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER, GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL],
  },
  {
    title:
      'I det fall produkten är avsedd för viktminskning, ange kategori (måltidsersättning/LCD/VLCD) samt i tillämpliga fall bifoga tillstånd från Livsmedelsverket för VLCD',
    field: 'categoryWeightloss',
    fieldMappingName: 'Viktminskning kategori (måltidsersättning, LCD, VLCD)',
    searchTitleList: [
      'Viktminskning kategori',
      'Viktminskning kategori (måltidsersättning, LCD, VLCD)',
      'För viktminskning produkter, ange kategori (måltidsersättning/LCD/VLCD) samt i tillämpliga fall ladda upp tillstånd från Livsmedelsverket för VLCD i din Foodla profil',
    ],
    showInCategories: [GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39],
    mandatoryInCategories: [],
    suggestions: renderSuggestionList(categoryWeightloss),
  },
  {
    title: 'Ange CE standarder/krav eller "inte CE märkt"',
    field: 'specifyCEStandards',
    fieldMappingName: 'CE standarder/krav',
    searchTitleList: ['CE standarder/krav'],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
    ],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER],
  },
  {
    title: 'Min Transporttemperatur',
    field: 'transportMinTemperature',
    fieldMappingName: 'Transporttemperatur min',
    searchTitleList: ['Transport-temperatur min', 'Transporttemperatur min'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Max Transporttemperatur',
    field: 'transportMaxTemperature',
    fieldMappingName: 'Transporttemperatur max',
    searchTitleList: ['Transport-temperatur max', 'Transporttemperatur max'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Antal distributionsförpackningar per transportförpackning (dfp/tfp)',
    field: 'transportQtyOfNextLowerItem',
    fieldMappingName: 'Antal distributionsförpackningar per transportförpackning (dfp/tfp)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Antal transportförpackningar per pall (tfp/pall)',
    field: 'palletQtyOfNextLowerItem',
    fieldMappingName: 'Antal transportförpackningar per pall (tfp/pall)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Antal distributionsförpackningar per pall (dfp/pall)',
    field: 'palletQtyOfStorePack',
    fieldMappingName: 'Antal distributionsförpackningar per pall (dfp/pall)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Antal konsumentförpackningar per pall (kfp/pall)',
    field: 'palletQtyOfBaseItem',
    fieldMappingName: 'Antal konsumentförpackningar per pall (kfp/pall)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Antal konsumentförpackningar per transportförpackning (kfp/tfp)',
    field: 'transportQtyOfBaseItem',
    fieldMappingName: 'Antal konsumentförpackningar per transportförpackning (kfp/tfp)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Pall beställningsbara?',
    field: 'palletOrderableUnit',
    fieldMappingName: 'Pall beställningsbara?',
    searchTitleList: ['Pallet Pack- Is Orderable Unit?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Används pall för transport?',
    field: 'palletDespatchUnit',
    fieldMappingName: 'Används pall för transport?',
    searchTitleList: ['Pallet Pack- Is Despatch Unit?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },

  // **** Logistic fields ****
  // EAN codes for packs other than consumer:
  {
    title: 'EAN-kod distributionsförpackning (14 siffror)',
    field: 'storePackEANForStorePack',
    fieldMappingName: 'EAN-kod distributionsförpackning (14 siffror)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'EAN-kod transportförpackning (14 siffror)',
    field: 'transportEANForStorePack',
    fieldMappingName: 'EAN-kod transportförpackning (14 siffror)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'EAN-kod pall (14 siffror)',
    field: 'palletEANForStorePack',
    fieldMappingName: 'EAN-kod pall (14 siffror)',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  // Orderable/despatch booleans:
  {
    title: 'Konsumentförpackning beställningsbara?',
    field: 'consumerPackOrderableUnit',
    fieldMappingName: 'Konsumentförpackning beställningsbara?',
    searchTitleList: ['Consumer pack- Is Orderable Unit?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Används konsumentförpackning för transport?',
    field: 'consumerPackDespatchUnit',
    fieldMappingName: 'Används konsumentförpackning för transport?',
    searchTitleList: ['Consumer pack- Is Despatch Unit?', 'Konsumentförpackning för transport?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Distributionsförpackning beställningsbara?',
    field: 'storePackOrderableUnit',
    fieldMappingName: 'Distributionsförpackning beställningsbara?',
    searchTitleList: ['Store pack- Is Orderable Unit?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Används distributionsförpackning för transport?',
    field: 'storePackDespatchUnit',
    fieldMappingName: 'Används distributionsförpackning för transport?',
    searchTitleList: ['Distributionsförpackning för transport?', 'Store pack- Is Despatch Unit?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Transportförpackning beställningsbara?',
    field: 'transportOrderableUnit',
    fieldMappingName: 'Transportförpackning beställningsbara?',
    searchTitleList: ['Transport Pack- Is Orderable Unit?'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },
  {
    title: 'Transport Pack- Is Despatch Unit?',
    field: 'transportDespatchUnit',
    fieldMappingName: 'Används transportförpackning för transport?',
    searchTitleList: [
      'Transportförpackning för transport? Transportförpackning för transport',
      'Används transportförpackning för transport?',
      'Transportförpackning för transport?',
      'Transport unit för transport',
      'Transport unit för dispatch',
      'Transport unit för despatch',
    ],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: ALL_GROUP_CATEGORY_IDS,
  },

  // **** Date fields ****
  {
    title: 'Första leveransdag för nyheter',
    field: 'articleFirstDelivery',
    fieldMappingName: 'Första leveransdag för nyheter',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'Första beställningsdag för nyheter',
    field: 'articleFirstOrderDate',
    fieldMappingName: 'Första beställningsdag för nyheter',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  {
    title: 'Lanseringsdag mot konsument för nyheter',
    field: 'basicDataAvailabilityDateFrom',
    fieldMappingName: 'Lanseringsdag mot konsument för nyheter',
    searchTitleList: ['Lanseringsdag mot kosument för nyheter'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },
  // **** Tax fields ****
  {
    title: 'Produktens skattesats (6, 12, 25)',
    field: 'basicDataTaxClassification',
    fieldMappingName: 'Produktens skattesats (6, 12, 25)',
    searchTitleList: ['Produktens skattesats'],
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [],
  },

  // **** Boolean question fields (and answer fields) ****
  {
    title: 'Innehåller produkten ett ämne som är förbjudet eller begränsat i REACH-förordningen?',
    field: 'reachSubstance',
    fieldMappingName: 'Innehåller produkten ett ämne som är förbjudet eller begränsat i REACH-förordningen?',
    isExtra: true,
    defaultValue: 'false',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
    ],
  },
  {
    title: 'Har produkten ett bäst före datum?',
    field: 'bestBeforeDate',
    fieldMappingName: 'Har produkten ett bäst före datum?',
    isExtra: true,
    defaultValue: 'false',
    mandatoryDefaultValue: 'true',
    showInCategories: ALL_GROUP_CATEGORY_IDS,
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.FODERRAVARA,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
    ],
  },
  {
    title: 'Är produkten avsedd för barn under 16 år?',
    field: 'childrenUnder16',
    fieldMappingName: 'Är produkten avsedd för barn under 16 år?',
    isExtra: true,
    defaultValue: 'false',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
      GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL,
      GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER,
      GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39,
      GROUP_CATEGORY_IDS.DRYCKER,
      GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER,
      GROUP_CATEGORY_IDS.KOSTTILLSKOTT,
      GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER,
      GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER,
      GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL,
    ],
    mandatoryInCategories: [],
  },
  {
    title:
      'Innehåller produkten något tillståndspliktigt ämne upptaget på bilaga XIV till Reach-förordningen EG 1907/2006?',
    field: 'reachSubstancesAnnex19072006',
    fieldMappingName:
      'Innehåller produkten något ämne med tillståndsplikt som anges i bilaga XIV till REACH-förordningen (EC) 1907/2006? *',
    isExtra: true,
    extraCorrespondingField: 'regulatedSubstance',
    defaultValue: 'false',
    searchTitleList: [
      'Innehåller produkten något ämne med tillståndsplikt som anges i bilaga XIV till REACH-förordningen (EC) 1907/2006? *',
    ],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
    ],
  },
  {
    title:
      'Ange eventuellet något tillståndspliktigt ämne upptaget på bilaga XIV till Reach-förordningen EG 1907/2006.',
    field: 'regulatedSubstance',
    fieldMappingName: 'Ange eventuellet tillståndspliktigt ämne (enligt bilaga XIV till REACH)',
    searchTitleList: [
      'Ange eventuellet tillståndspliktigt ämne (enligt bilaga XIV till REACH)',
      'Ange eventuellet tillståndspliktigt ämne',
    ],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
    ],
  },
  {
    title:
      'Innehåller produkten något ämne på EU:s kandidatlista i en mängd som överstiger 0,1 viktprocent av produktens innehåll?',
    field: 'hazardousSubstancesOver1',
    fieldMappingName:
      'Innehåller produkten något ämne på EU:s kandidatlista i en mängd som överstiger 0,1 viktprocent av produktens innehåll?',
    isExtra: true,
    extraCorrespondingField: 'informationHazardousSubstances',
    defaultValue: 'false',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
    ],
  },
  {
    title:
      'Eventuellt ämne på EUs kandidat lista som överstiger 0,1% of produkts innehåll. Lista ämnen och information för säker användning',
    field: 'informationHazardousSubstances',
    fieldMappingName: 'Eventuellt EU-kandidatlistat ämne och användnings information',
    searchTitleList: ['Eventuellt EU-kandidatlistat ämne och användnings information'],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
    ],
  },
  {
    title: 'Har produkten en unik produktidentifiering (UDI)?',
    field: 'checkUniqueIdentification',
    fieldMappingName: 'Har produkten en unik produktidentifiering (UDI)?',
    isExtra: true,
    extraCorrespondingField: 'explanationNoUDI',
    defaultValue: 'true',
    showInCategories: [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER],
  },
  {
    title: 'Kommentar till Nej-svar',
    field: 'explanationNoUDI',
    fieldMappingName: 'Om produkten inte har UDI, kommentar till nej-svar',
    searchTitleList: ['Om produkten inte har UDI, kommentar till nej-svar'],
    showInCategories: [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER],
    mandatoryInCategories: [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER],
  },
  {
    title: 'Innehåller produkten CMR-klassade ämnen?',
    field: 'cmrSubstance',
    fieldMappingName: 'Innehåller produkten CMR-klassade ämnen?',
    isExtra: true,
    extraCorrespondingField: 'CMRSubstances',
    defaultValue: 'false',
    searchTitleList: ['Eventuellt CMR ämne'],
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    ],
  },
  {
    title: 'Eventuellt CMR-klassat ämne',
    field: 'CMRSubstances',
    fieldMappingName: 'Eventuellt CMR-klassat ämne',
    showInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    ],
    mandatoryInCategories: [
      GROUP_CATEGORY_IDS.LEKSAK,
      GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER,
      GROUP_CATEGORY_IDS.VAROR,
      GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL,
      GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER,
      GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL,
      GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL,
    ],
  },
  {
    title: 'Är produkten godkänt som dietfoder?',
    field: 'approvalDietFeed',
    fieldMappingName: 'Är produkten godkänt som dietfoder?',
    isExtra: true,
    defaultValue: 'NO',
    showInCategories: [
      GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT,
      GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR,
    ],
    mandatoryInCategories: [],
  },
];
