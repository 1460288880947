import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box } from '@material-ui/core';
import ImportProgressCard from './ImportProgressCard';
import Button from 'components/button';
import Header from './Header';
import DocumentIcon from 'components/icons/DocumentIcon';
import DownloadTemplateIcon from 'components/icons/DownloadTemplateIcon';
import FooterWrapper from '../components/FooterWrapper';

import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';

import { TExcelDataRow } from 'models/excel';
import { SAVED_FILE_ID, SAVED_FILE_DATA_ID, OBJECT_STORAGE_NAME, GROUP_CATEGORY_IDS } from '../constants';
import { readExcelFile } from '../utils/excel';

import { useDropzoneStyles } from '../styles';

const FILE_NAME_BY_CATEGORY_ID = {
  [GROUP_CATEGORY_IDS.LEKSAK]: 'Leksak - Template.xlsx',
  [GROUP_CATEGORY_IDS.ELEKTRISKA_PRODUKTER]: 'Elektriska Produkter - Template.xlsx',
  [GROUP_CATEGORY_IDS.SMYCKE_KLADER_GLASOGON_ACCESSOARER]: 'Smycken - Template.xlsx',
  [GROUP_CATEGORY_IDS.VAROR]: 'Varor - Template.xlsx',
  [GROUP_CATEGORY_IDS.MEDICINTEKNISKA_PRODUKTER]: 'Medicintekniska produkter - Template.xlsx',
  [GROUP_CATEGORY_IDS.KEMISKA_PRODUKTER]: 'Kemiska - Template.xlsx',
  [GROUP_CATEGORY_IDS.TVATTMEDEL_DISKMEDEL]: 'Tvattmedel Diskmedel - Template.xlsx',
  [GROUP_CATEGORY_IDS.KOSMETISKA_PRODUKTER]: 'Kosmetiska produkter - Template.xlsx',
  [GROUP_CATEGORY_IDS.SOLSKYDDSMEDEL]: 'Solskyddsmedel - Template.xlsx',
  [GROUP_CATEGORY_IDS.KOMPLETTERINGSFODER_OCH_FODERTILLSKOTT]: 'Kompletteringsfoder & fodertillskott - Template.xlsx',
  [GROUP_CATEGORY_IDS.FODERRAVARA]: 'Foderravara - Template.xlsx',
  [GROUP_CATEGORY_IDS.HELFODER_OCH_FODERBLANDNINGAR]: 'Helfoder och foderblandningar - Template.xlsx',
  [GROUP_CATEGORY_IDS.RECEPTFRIA_LAKEMEDEL]: 'Receptfria Lakemedel - Template.xlsx',
  [GROUP_CATEGORY_IDS.OVRIGT_LIVSMEDEL]: 'Ovrigt livsmedel - Template.xlsx',
  [GROUP_CATEGORY_IDS.FISK_OCH_FISK_PRODUKTER]: 'Fisk och fiskeriprodukter - Template.xlsx',
  [GROUP_CATEGORY_IDS.SPECIAL_LIVSMEDEL_ENLIGT_DEFINITIONEN_I_DIREKTIV_EG_2009_39]: 'Special livsmedel - Template.xlsx',
  [GROUP_CATEGORY_IDS.DRYCKER]: 'Drycker - Template.xlsx',
  [GROUP_CATEGORY_IDS.AGG_OCH_AGG_PRODUKTER]: 'Agg och aggprodukter - Template.xlsx',
  [GROUP_CATEGORY_IDS.KOSTTILLSKOTT]: 'Kosttillskott - Template.xlsx',
  [GROUP_CATEGORY_IDS.FRUKT_OCH_GRÖNSAKER]: 'Frukt och gronsaker - Template.xlsx',
  [GROUP_CATEGORY_IDS.KOTT_KRYDDOR_SOPPOR_SASER_SALLADER_OCH_PROTEINPRODUKTER]:
    'Kott, kryddor, soppor, saser, sallader och proteinprodukter - Template.xlsx',
  [GROUP_CATEGORY_IDS.ALLMANT_LIVSMEDEL]: 'Allmant livsmedel - Template.xlsx',
};

const DataImport = () => {
  const dropzoneClasses = useDropzoneStyles();
  const { indexedDB, selectedCategoryTypeId } = useControlContext();
  const { excelFile, setExcelFile, setExcelFileData, onReset } = useDataContext();

  const [loading, setLoading] = useState(false);

  const templateFileName = useMemo(() => {
    return FILE_NAME_BY_CATEGORY_ID[selectedCategoryTypeId];
  }, [selectedCategoryTypeId]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx',
    multiple: false,
    onDrop: async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) return;
      setLoading(true);
      setExcelFile(file);

      const data = await readExcelFile(file);

      // write data to indexedDB
      if (indexedDB) {
        await indexedDB.clearObjectStore(OBJECT_STORAGE_NAME);
        await Promise.all([
          indexedDB.write<File>(OBJECT_STORAGE_NAME, { id: SAVED_FILE_ID, value: file }),
          indexedDB.write<TExcelDataRow[]>(OBJECT_STORAGE_NAME, { id: SAVED_FILE_DATA_ID, value: data }),
        ]);
      }

      setExcelFileData(data);
      setLoading(false);
    },
  });

  const handleReset = () => {
    onReset(true);
    if (indexedDB) {
      indexedDB.delete(OBJECT_STORAGE_NAME, SAVED_FILE_ID);
      indexedDB.delete(OBJECT_STORAGE_NAME, SAVED_FILE_DATA_ID);
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ height: 1, bgcolor: '#D7DAE4', my: 3 }} />

      <Box flexGrow={1} display="flex">
        <Box flexBasis={1} flexGrow={1}>
          {!!templateFileName && (
            <Box display="flex" gridGap={16} alignItems="start" mb={4}>
              <Box>
                <DownloadTemplateIcon />
              </Box>
              <Box>
                <Box sx={{ fontWeight: 700, fontSize: 16, mb: 1 }}>Ladda ner mallen</Box>
                <Box sx={{ mb: 2 }}>
                  <b>Inte säker på vilken information som är relevant för dina produkter?</b>
                  <br />
                  Du kan använda mallen för att vara säker på att alla fält matchas korrekt. Det spelar ingen roll
                  vilken ordning dina kolumner är i.
                </Box>

                <Button
                  variant="contained"
                  download={templateFileName}
                  href={require(`assets/files/${templateFileName}`)}
                >
                  Ladda ner mallen
                </Button>
              </Box>
            </Box>
          )}

          <Box display="flex" gridGap={16} alignItems="start" mb={4}>
            <Box>
              <DocumentIcon />
            </Box>
            <Box>
              <Box sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}>Import</Box>
              <Box sx={{ fontSize: 13 }}>
                Vänligen importera din fil. Du kan ladda upp filer i CSV-, XLS- och XLSX-format.
                <br />
                Kom ihåg att döpa dina kolumnrubriker så att <b>Foodla</b> förstår dig. Vi använder en Al-motor så vi
                matchar automatiskt fälten för båda filerna men du kan kontrollera det och fixa det manuellt i nästa
                steg.
              </Box>
            </Box>
          </Box>

          {excelFile ? (
            <Box display="flex" flexDirection="column" gridGap={20}>
              <ImportProgressCard
                label={excelFile.name}
                size={excelFile.size}
                value={loading ? undefined : 100}
                customProgressBar={
                  loading ? (
                    <Box display="flex" justifyContent="center">
                      Loading ...
                    </Box>
                  ) : undefined
                }
              />
              <Box display="flex" justifyContent="center">
                <Button variant="outlined" onClick={handleReset}>
                  Återställ
                </Button>
              </Box>
            </Box>
          ) : (
            <Box {...getRootProps({ className: dropzoneClasses.dropzone })}>
              <input {...getInputProps()} />
              <Box className={dropzoneClasses.title}>Dra och släpp filen</Box>
              <Box className={dropzoneClasses.orClick}>eller klicka</Box>
              <Button variant="outlined">Välj fil</Button>
            </Box>
          )}
        </Box>

        <FooterWrapper />
      </Box>
    </>
  );
};

export default DataImport;
