import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CheckIcon from '@material-ui/icons/Check';
import FoodlaIcon from '../assets/FoodlaAIicon_small.png';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const orderNumberStyles = makeStyles(theme => ({
    input: {
        width: theme.spacing(10),
    },
    button: {
        height: theme.spacing(5),
    },
}));

function SelectNumber(props) {
    const classes = orderNumberStyles();
    const { id, product, quantity, setFormData, onChange } = props;

    return (
        <Box>
            <Input
                onChange={onChange}
                size="small"
                min="1"
                variant="outlined"
                id={id}
                type="number"
                className={classes.input}
                value={quantity}
            />
        </Box>
    );
}

function OrderNumber(props) {
    const classes = orderNumberStyles();
    const { id, product, quantity, setFormData, onChange } = props;
    const [value, setValue] = React.useState(quantity);

    const onChangeNew = event => {
        if (event.target.value <= 0) {
            return false;
        }

        localStorage.setItem(product, event.target.value);
        setValue(event.target.value);
        setFormData(id, event.target.value);
    };

    return (
        <Box>
            <Input
                onChange={onChange || onChangeNew}
                size="small"
                min="1"
                variant="outlined"
                id={id}
                type="number"
                className={classes.input}
                value={value}
            />
        </Box>
    );
}
export function ProductItemReview(props) {
    const classes = useStyles();
    const { product } = props;
    let size = "";
    if (product?.consumer_size_to_order_size_ratio) {
        size = `B-packstorlek (Kfp/Dfp): ${product.consumer_size_to_order_size_ratio} st`;
    }
    let quantity = 0;
    if (product?.id) {
        quantity = localStorage.getItem(product.id) || 0;
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <ButtonBase className={classes.image}>
                    <img
                        className={classes.img}
                        alt="complex"
                        src={product.pictures.small.url}
                    />
                </ButtonBase>
            </Grid>
            <Grid container item spacing={1} xs={9}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>{product.name}</Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>EAN: {product.EAN}</Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> {size} </Paper>
                </Grid>
            </Grid>
            {product.quantity_in_stock === 0 ? (
                <Grid justifyContent="center" container item xs={12}>
                    <div style={{color: "mediumvioletred", fontSize: 16, marginBottom: 20}}>
                        Tillfälligt slut hos producenten. Denna produkt inkluderas ej i order.
                    </div>
                </Grid>
            ) : (
                <Grid item xs={1} container justifyContent="center" alignItems="center">
                  <Typography variant="body1" align="center" nowrap="true">
                      x {quantity} <span style={{color: "grey"}}>B-pack</span>
                  </Typography>
                  <Input
                      type="hidden"
                      id={product.id}
                      value={product.quantity}
                  />
                </Grid>
            )}
        </Grid>
    );
}

export function ProductItemEdit(props) {
    const classes = useStyles();
    const { product, removeAction, setFormData } = props;
    let size = "";
    if (product?.consumer_size_to_order_size_ratio) {
        size = `B-packstorlek (Kfp/Dfp): ${product.consumer_size_to_order_size_ratio} st`;
    }
    let quantity = 0;
    if (product?.id) {
        quantity = localStorage.getItem(product.id) || 0;
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <ButtonBase className={classes.image}>
                    <img
                        className={classes.img}
                        alt="complex"
                        src={product.pictures.small.url}
                    />
                </ButtonBase>
            </Grid>
            <Grid container item spacing={1} xs={9}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>{product.name}</Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>EAN: {product.EAN}</Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> {size} </Paper>
                </Grid>
                {product.quantity_in_stock === 0 ? (
                    <Grid justifyContent="center" container item xs={12}>
                        <div style={{color: "mediumvioletred", fontSize: 16, marginBottom: 20}}>
                            Tillfälligt slut hos producenten. Denna produkt inkluderas ej i order.
                        </div>
                    </Grid>
                ) : (
                    <Grid justifyContent="flex-end" container item xs={12}>
                        <OrderNumber
                            id={'order-number-' + product.id}
                            product={product.id}
                            quantity={quantity}
                            setFormData={setFormData}
                        />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", color: "grey"}}>
                        &times; B-pack
                        </div>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={1} container justifyContent="center" alignItems="center">
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        removeAction(product.id);
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export function ProductItemSelect(props) {
    const DEF_SALES_IN_DAYS = 7
    const classes = useStyles();
    const { product, setFormData, selectAction, selected, productData } = props;
    const volumeToDays = (volume) => {
        if (productData) {
            const avg = productData.items_per_day_new;
            const result = Math.floor(volume/avg); // Math.ceil(volume/avg);
            if (result < 1) { return 1};
            return result;
        }
        return 1;
    };
    const daysToVolume = (days) => {
        if (productData) {
            const avg = productData.items_per_day_new;
            const result = Math.ceil(avg * days);
            if (result < 1) { return 1 };
            return result;
        }
        return 1;
    };
    const defVolumeFromDays = 1;
    const [volume, setVolume] = useState(defVolumeFromDays);
    let defDays;
    if (productData) {
      defDays = defVolumeFromDays;
    } else {
      defDays = volumeToDays(defVolumeFromDays); // volumeToDays(1));
    }
    const [days, setDays] = useState(defDays);

    const [volumeChange, setVolumeChange] = useState(true);
    console.log(product);

    let size = "";
    if (product?.consumer_size_to_order_size_ratio) {
        size = `B-packstorlek (Kfp/Dfp): ${product.consumer_size_to_order_size_ratio} st`;
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <ButtonBase className={classes.image}>
                    <img
                        className={classes.img}
                        alt="complex"
                        src={product.pictures.small.url}
                    />
                </ButtonBase>
            </Grid>
            <Grid container item spacing={1} xs={9}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>{product.name}</Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>EAN: {product.EAN}</Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}> {size} </Paper>
                </Grid>

                <Grid item xs={1} style={{height: 72}}>
                    {/* TODO: Image here */}
                    <img src={FoodlaIcon} alt="" />
                </Grid>
                <Grid item xs={11} style={{height: 72}}>
                    {/* TODO: fix text */}
                    <Paper className={classes.paper}>Specify the number in B-pack and see for how many days it will last. Or specify the number of days you want your order to last and see how many B-packs you need to order</Paper>
                </Grid>

                {product.quantity_in_stock === 0 ? (
                    <Grid justifyContent="center" container item xs={12}>
                        <div style={{color: "mediumvioletred", fontSize: 16, marginBottom: 20}}>
                            Tillfälligt slut hos producenten. Denna produkt inkluderas ej i order.
                        </div>
                    </Grid>
                ) : (
                    <Grid justifyContent="flex-end" container item xs={12}>
                        {/* TODO: fix text */}
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", color: "grey"}}>
                            Forsäljning i dagar:
                        </div>
                        <SelectNumber
                            id={'order-number-days-' + product.id}
                            product={product.id}
                            quantity={days}
                            onChange={(e) => {
                                const days = parseInt(e.target.value);
                                const volume = daysToVolume(days);
                                setDays(days);
                                setVolume(volume);
                            }}
                            // setFormData={setFormData}
                        />
                        <SwapHorizIcon />
                        {/*<IconButton
                            onClick={() => {
                                console.log(volume);
                                console.log(productData && productData.items_per_day_new);
                                console.log(volumeToDays(volume));
                                setDays(volumeToDays(volume));
                            }}
                        >
                        </IconButton>*/}
                        <SelectNumber
                            id={'order-number-' + product.id}
                            product={product.id}
                            quantity={volume}
                            onChange={(e) => {
                                const volume = parseInt(e.target.value);
                                const days = volumeToDays(volume);
                                setDays(days);
                                setVolume(volume);
                               // setFormData('order-number-' + product.id, e.target.value);
                            }}
                            // setFormData={setFormData}
                        />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", color: "grey"}}>
                        &times; B-pack
                        </div>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={1} container justifyContent="center" alignItems="center">
                {selected && Array.isArray(selected) && selected.includes(product.id) && (<CheckIcon fontSize="large" style={{ color: green[500] }} />)}
                {(!selected || !Array.isArray(selected) || !selected.includes(product.id)) && (
                    <Button
                        onClick={() => {
                            selectAction(product.id, volume);
                        }}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        // startIcon={<NavigateNextIcon />}
                    >
                        Add to Select+
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

export default { ProductItemEdit, ProductItemReview, ProductItemSelect };
