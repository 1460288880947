import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    margin: '5px',
  },
}))(Tooltip);

const styles = (theme: any) => ({
  asterisk: {
    color: theme.palette.error.main,
  },
});

interface IInputLabelProps {
  heading?: string;
  text?: string;
  link?: string;
  id?: string;
  labelStyles?: React.CSSProperties;
  required?: Boolean;
  classes?: any;
}

const InputLabel = ({
  heading,
  text,
  link,
  id,
  labelStyles,
  required,
  classes
}: IInputLabelProps) => (
  <div
    style={{
      position: 'relative',
      margin: '16px 0px 4px 0px',
      fontSize: 16,
      ...labelStyles,
    }}
    id={id}
  >
    {heading}
    {required && <span className={classes.asterisk}> *</span>}
    {text && text.length > 0 ? (
      <HtmlTooltip
        interactive
        arrow
        placement="top"
        title={
          <React.Fragment>
            <span style={{ fontSize: 16 }}>
              {text}&nbsp;
              {link && link.length > 0 ? (
                <a
                  target="_blank"
                  href={link}
                  rel="noopener noreferrer"
                >
                  Läs mer här
                </a>
              ) : (
                <span />
              )}
            </span>
          </React.Fragment>
        }
      >
        <HelpOutlineIcon style={{
          marginLeft: '5px',
          position: 'absolute'
        }} />
      </HtmlTooltip>
    ) : (
      <span />
    )}
  </div>)

export default withStyles(styles)(InputLabel);
