import { FC, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { FormControl, InputAdornment, MenuItem, Select, SelectProps, TextField, makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import IProduct from 'models/product';
import { IImportColumn } from '../types';
import { COLUMN_DETAILS } from '../constants';
import useDataContext from '../hooks/useDataContext';

export const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
  },
  searchWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    marginTop: -8,
    padding: '8px 16px',
    backgroundColor: '#ffffff',
  },
  fieldSelect: ({ outlined }: { outlined?: boolean }) => ({
    fontSize: 14,
    color: '#383D52',
    ...(!outlined
      ? {
          '& .MuiSelect-root': {
            padding: '8px 32px 8px 8px',
          },
        }
      : {
          '&.MuiOutlinedInput-root.Mui-focused .MuiSelect-root:focus': {
            background: 'transparent',
          },
          '& .MuiSelect-root': {
            padding: 0,
            paddingRight: 16,
          },
          '& .MuiSelect-nativeInput': {
            border: 0,
            padding: 0,
            fontSize: 14,
          },
          '& .MuiSelect-iconOutlined': {
            bottom: 1,
            right: 0,
            top: 'unset',
          },
          '& .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
        }),
  }),
}));

export const getDisplayFieldName = (field: keyof IProduct) => {
  const columnDetails = COLUMN_DETAILS.find(columnDetails => columnDetails.field === field);
  return columnDetails?.fieldMappingName || field;
};

interface SelectFieldProps extends SelectProps {
  outlined?: boolean;
  columnId: string;
  categoryColumnList: IImportColumn[];
  onRemoveColumn: (id: string) => void;
  onUpdateColumnField: (id: string, field: keyof IProduct) => void;
}

const SelectField: FC<SelectFieldProps> = ({
  outlined,
  columnId,
  value,
  categoryColumnList,
  onRemoveColumn,
  onUpdateColumnField,
  className,
  onClose,
  ...props
}) => {
  const classes = useStyles({ outlined });

  const { excelColumnList } = useDataContext();

  const searchRef = useRef<HTMLInputElement | null>(null);
  const searchWrapperRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const mappedField = useMemo(() => excelColumnList.map(({ field }) => field), [excelColumnList]);

  const options = useMemo(() => {
    let filteredCategoryColumnList = categoryColumnList.filter(
      ({ field }) => !['image_src', 'foodlaCategory'].includes(field)
    );

    let result = filteredCategoryColumnList.map(({ field }) => ({ field, displayName: getDisplayFieldName(field) }));
    if (search) {
      result = result.filter(({ displayName }) => displayName.toLowerCase().includes(search.toLowerCase()));
    } else {
      result.unshift({ field: '_remove' as keyof IProduct, displayName: 'Ta bort kolumnen' });
    }

    return result;
  }, [search, categoryColumnList]);

  const handleOpen = () => {
    setOpen(true);
    setSearch('');
  };

  const handleClose = (event: React.ChangeEvent<{}>) => {
    if (!searchWrapperRef.current?.contains(event.nativeEvent.target as Node)) {
      setOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!searchWrapperRef.current?.contains(event.nativeEvent.target as Node)) {
      if (event.target.value === '_remove') {
        onRemoveColumn(columnId);
      } else {
        onUpdateColumnField(columnId, event.target.value as keyof IProduct);
      }
    }
  };

  // keep focus on search input
  useEffect(() => {
    if (open) setTimeout(() => searchRef.current?.focus());
  }, [open, options]);

  return (
    <FormControl className={classes.formControl} variant="outlined" size="small" fullWidth>
      <Select
        className={clsx(classes.fieldSelect, className)}
        {...props}
        open={open}
        value={value}
        displayEmpty
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
      >
        <div ref={searchWrapperRef} className={classes.searchWrapper} onKeyDown={e => e.stopPropagation()}>
          <TextField
            fullWidth
            size="small"
            value={search}
            autoFocus={!value}
            variant="outlined"
            placeholder="Type to search..."
            InputProps={{
              inputRef: searchRef,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={e => setSearch(e.target.value)}
          />
        </div>

        {options.map(({ field, displayName }) => (
          <MenuItem key={field} value={field} style={{ color: mappedField?.includes(field) ? 'gray' : undefined }}>
            <div
              style={
                field === ('_remove' as keyof IProduct)
                  ? { fontSize: 14, fontWeight: 700, fontStyle: 'italic' }
                  : { fontSize: 14 }
              }
            >
              {displayName}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
