import qs from 'query-string';
// import hexRgb from 'hex-rgb';
// import rgbHex from 'rgb-hex';
import isNil from 'lodash/isNil';

class ImgixBuilder {
    private baseUrl?: string;
    private query?: any;

    private _contrast?: number;
    private _sharpen?: number;
    private _vibrance?: number;

    private _brightness?: number;
    private _hue?: number;
    private _saturation?: number;

    // private _red?: number;
    // private _green?: number;
    // private _blue?: number;
    // private _alpha?: number;

    constructor(private url?: string) {
    }

    parse() {
        if (this.url && this.url.trim() !== '') {
            let { url: baseUrl, query } = qs.parseUrl(this.url);
            this.baseUrl = baseUrl;
            if (!query) query = {};

            const hue = this.getNumberValue(query?.hue);
            if (!isNil(hue)) this._hue = hue;

            const sat = this.getNumberValue(query?.sat);
            if (!isNil(sat)) this._saturation = sat;

            const bri = this.getNumberValue(query?.bri);
            if (!isNil(bri)) this._brightness = bri;

            const con = this.getNumberValue(query?.con);
            if (!isNil(con)) this._contrast = con;


            const sharp = this.getNumberValue(query?.sharp);
            if (!isNil(sharp)) this._sharpen = sharp;

            const vib = this.getNumberValue(query?.vib);
            if (!isNil(vib)) this._vibrance = vib;

            // const blendColor = query && query["blend-color"];
            // if (!isNil(blendColor)) {
            //     if (Array.isArray(blendColor)) {
            //         if (blendColor.length > 0) {
            //             let value = blendColor[0];
            //             if (value.length > 6) {
            //                 value = value.slice(2) + value.slice(0, 2);
            //             }
            //             const { red, green, blue, alpha } = hexRgb(value);
            //             this._red = red;
            //             this._green = green;
            //             this._blue = blue;
            //             this._alpha = alpha;
            //         }
            //     } else if (typeof blendColor === 'string') {
            //         let value = blendColor.padStart(8, '0');
            //         if (value.length > 6) {
            //             value = value.slice(2) + value.slice(0, 2);
            //         }
            //         const { red, green, blue, alpha } = hexRgb(value);
            //         this._red = red;
            //         this._green = green;
            //         this._blue = blue;
            //         this._alpha = alpha;
            //     }
            // }
        }
        return this;
    }

    static parse(url?: string) {
        return new ImgixBuilder(url).parse();
    }

    private getNumberValue(value: string | number | undefined | null | string[], isFloat = false) {
        let v: number | undefined = undefined;
        if (typeof value === 'string') {
            v = isFloat ? parseFloat(value) : parseInt(value);
        } else if (typeof value === 'number') {
            v = value;
        } else if (Array.isArray(value)) {
            if (value.length > 0) {
                if (typeof value[0] === "string") {
                    v = isFloat ? parseFloat(value[0]) : parseInt(value[0]);
                }
            }
        }
        return v;
    }

    public get hue(): number {
        if (isNil(this._hue)) return 0;
        return this._hue;
    }

    public set hue(value: string | number | undefined | null | string[]) {
        const v = this.getNumberValue(value);
        this._hue = v;
    }

    public get saturation(): number {
        if (isNil(this._saturation)) return 0;
        return this._saturation;
    }

    public set saturation(value: string | number | undefined | null | string[]) {
        const v = this.getNumberValue(value);
        this._saturation = v;
    }

    public get brightness(): number {
        if (isNil(this._brightness)) return 0;
        return this._brightness;
    }

    public set brightness(value: string | number | undefined | null | string[]) {
        const v = this.getNumberValue(value);
        this._brightness = v;
    }

    public get contrast(): number {
        if (isNil(this._contrast)) return 0;
        return this._contrast;
    }

    public set contrast(value: string | number | undefined | null | string[]) {
        const v = this.getNumberValue(value);
        this._contrast = v;
    }



    public get sharpen(): number {
        if (isNil(this._sharpen)) return 0;
        return this._sharpen;
    }

    public set sharpen(value: string | number | undefined | null | string[]) {
        const v = this.getNumberValue(value);
        this._sharpen = v;
    }

    public get vibrance(): number {
        if (isNil(this._vibrance)) return 0;
        return this._vibrance;
    }

    public set vibrance(value: string | number | undefined | null | string[]) {
        const v = this.getNumberValue(value);
        this._vibrance = v;
    }

    // public get red(): number {
    //     if (isNil(this._red)) return 0;
    //     return this._red;
    // }

    // public set red(value: string | number | undefined | null | string[]) {
    //     const v = this.getNumberValue(value);
    //     this._red = v;
    // }

    // public get green(): number {
    //     if (isNil(this._green)) return 0;
    //     return this._green;
    // }

    // public set green(value: string | number | undefined | null | string[]) {
    //     const v = this.getNumberValue(value);
    //     this._green = v;
    // }

    // public get blue(): number {
    //     if (isNil(this._blue)) return 0;
    //     return this._blue;
    // }

    // public set blue(value: string | number | undefined | null | string[]) {
    //     const v = this.getNumberValue(value);
    //     this._blue = v;
    // }

    // public get alpha(): number {
    //     if (isNil(this._alpha)) return 0;
    //     return this._alpha;
    // }

    // public set alpha(value: string | number | undefined | null | string[]) {
    //     const v = this.getNumberValue(value, true);
    //     this._alpha = v;
    // }

    getUrl(setHeight = true) {
        if (this.baseUrl && this.baseUrl.trim() !== '') {
            let query = this.query;
            if (!query) query = {};

            if (!isNil(this._hue)) {
                query.bri = this._hue?.toString();
            }

            if (!isNil(this._saturation)) {
                query.sat = this._saturation?.toString();
            }

            if (!isNil(this._brightness)) {
                query.bri = this._brightness?.toString();
            }

            if (!isNil(this._contrast)) {
                query.con = this._contrast?.toString();
            }

            if (!isNil(this._sharpen)) {
                query.sharp = this._sharpen?.toString();
            }

            if (!isNil(this._vibrance)) {
                query.vib = this._vibrance?.toString();
            }

            // if (
            //     !isNil(this._red) 
            //     || !isNil(this._green) 
            //     || !isNil(this._blue) 
            //     || (!isNil(this._alpha) && this._alpha !== 0)
            // ) {
            //     let value = rgbHex(
            //         this._red || 0, 
            //         this._green || 0, 
            //         this._blue || 0, 
            //         // !isNil(this._alpha) ? this._alpha / 0xff : 0,
            //     );
            //     let alpha = '';
            //     if (!isNil(this._alpha)) {
            //         alpha = (Math.ceil(0xff * this._alpha)).toString(16);
            //     }
            //     value = alpha + value;
            //     query['blend-color'] = value;
            // }

            if (setHeight) {
                // query.w = 800; // TODO check width
                query.h = 400; // TODO check height
            }

            const url = qs.stringifyUrl({
                url: this.baseUrl, 
                query,
            });
            return url;
        }
        return undefined;
    }

    getThumb() {
        const url = this.getUrl(false);
        if (url) {
            let { url: baseUrl, query } = qs.parseUrl(url);
            if (!query) query = {};
            query.w = '128';
            query.h = '128';
            const newUrl = qs.stringifyUrl({
                url: baseUrl,
                query,
            });
            return newUrl;
        }
        return undefined;
    }
}

export default ImgixBuilder;
