import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        padding: theme.spacing(2),
    },
});

const CardGrid = ({ list, classes, children, xs = 3 }) => {
    return (
        <Grid container spacing={2} className={classes.container}>
            {list.map(r => (
                <Grid key={r.id} item xs={xs}>
                    {children(r)}
                </Grid>
            ))}
        </Grid>
    );
};

export default withStyles(styles)(CardGrid);
