import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import {
  path,
} from 'ramda';
import { Link as RouterLink } from 'react-router-dom';

import { isDefined } from '../../../../utils/helpers';
import { SUPERUSER_APPROVAL } from '../../../constants';
import COLORS from '../../../../constants/colors';
import IProduct from '../../../../models/product';

interface IProducerNotAcceptedProps {
  product?: IProduct;
}

export const ProducerNotAccepted = ({ product }: IProducerNotAcceptedProps) => {
  const producerStatus = product?.producer?.status;
  const producerNotAccepted = isDefined(producerStatus) && producerStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL;
  return (
    <>
      {producerNotAccepted && (
        <Typography>
          <div style={{ marginBottom: 20 }}>
            <span style={{ color: COLORS.mainRed, fontSize: 16, fontWeight: 'bold' }}>
              This producer is not yet accepted. Producer must be accepted before products can be accepted.
            </span>
            <br />
            <span style={{ fontSize: 13 }}>
              Click{' '}
              <Link
                variant="inherit"
                component={RouterLink}
                to={`/superuser/producer/${path(['producer', 'id'], product)}/edit`}
              >
                <span style={{ color: COLORS.mainGreen, fontSize: 13, fontWeight: 'bold' }}>HERE</span>
              </Link>{' '}
              to go to producer approval form to accept producer.
            </span>
          </div>
        </Typography>
      )}
    </>
  );
};

export default ProducerNotAccepted;
