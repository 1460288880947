import { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  checkboxWrapper: {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
  },
  input: {
    cursor: 'pointer',
    position: 'absolute',
    opacity: 0,
    height: 0,
    width: 0,
    '&:checked~label': {
      background: '#3f51b5',
      borderColor: '#3f51b5',
    },
  },
  label: {
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    width: 20,
    height: 20,
    padding: 3,
    borderRadius: 4,
    border: '2px solid #000',
  },
  tick: {
    display: 'block',
    width: '100%',
    height: '60%',
    marginTop: 1,
    borderWidth: '0 0 2px 2px',
    borderColor: '#fff',
    borderStyle: 'solid',
    transform: 'rotate(-45deg)',
  },
}));

interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Checkbox: FC<CheckboxProps> = ({ checked, onChange }) => {
  const [id] = useState(uuid());
  const classes = useStyles();

  return (
    <div className={classes.checkboxWrapper}>
      <input
        className={classes.input}
        id={id}
        type="checkbox"
        color="primary"
        checked={checked}
        onChange={event => onChange?.(event.target.checked)}
      />
      <label className={classes.label} htmlFor={id}>
        {checked && <span className={classes.tick} />}
      </label>
    </div>
  );
};
