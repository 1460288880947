import React, { useRef, useState } from 'react';

import ErrorStrongIcon from 'components/icons/ErrorStrongIcon';
import ErrorOutlineIcon from 'components/icons/ErrorOutlineIcon';
import LightTooltip from 'components/lightTooltip';
import { makeStyles } from '@material-ui/core';

import { STATUS_COLORS, STATUS_COLORS_OPACITY } from '../constants';

const useCustomStyles = makeStyles(() => ({
  uploadErrorIconWrapper: {
    textAlign: 'center',
    lineHeight: 1,
    cursor: 'pointer',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  uploadErrorName: {
    margin: 0,
    padding: '4px 8px',
    borderTop: `3px solid ${STATUS_COLORS.ERROR}`,
    background: STATUS_COLORS_OPACITY.ERROR,
  },
  uploadErrorContent: {
    margin: 0,
    marginTop: 4,
    fontSize: 12,
    borderRadius: 4,
  },
}));

export interface ErrorInfoIconProps {
  type?: 'error' | 'warn';
  customIcon?: React.ReactNode;
  errors: {
    label?: React.ReactNode;
    message?: React.ReactNode;
  }[];
}

const ErrorInfoIcon = ({ type = 'error', errors, customIcon }: ErrorInfoIconProps) => {
  const customClasses = useCustomStyles();

  const keepRef = useRef({ isHoverPopper: false, isHoverTarget: false });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setTimeout(() => {
      const { isHoverPopper, isHoverTarget } = keepRef.current;
      if (!isHoverPopper && !isHoverTarget) {
        setOpen(false);
      }
    }, 0);
  };

  if (!errors.length) return null;

  return (
    <LightTooltip
      open={open}
      arrow
      placement="top"
      title={
        <div className={customClasses.contentWrapper}>
          {errors
            .filter(({ label, message }) => label || message)
            .map(({ label, message }, index) => (
              <div key={index} style={{ padding: '2px 0' }}>
                {label && <p className={customClasses.uploadErrorName}>{label}</p>}
                {message && <p className={customClasses.uploadErrorContent}>{message}</p>}
              </div>
            ))}
        </div>
      }
      PopperProps={{
        onMouseOver: () => (keepRef.current.isHoverPopper = true),
        onMouseLeave: () => {
          keepRef.current.isHoverPopper = false;
          handleClose();
        },
        style: { pointerEvents: 'auto' },
      }}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
    >
      <div
        className={customClasses.uploadErrorIconWrapper}
        onMouseEnter={() => (keepRef.current.isHoverTarget = true)}
        onMouseLeave={() => (keepRef.current.isHoverTarget = false)}
      >
        {customIcon || (
          <>
            {type === 'error' && <ErrorStrongIcon width={24} height={24} />}
            {type === 'warn' && <ErrorOutlineIcon width={24} height={24} style={{ color: 'orange' }} />}
          </>
        )}
      </div>
    </LightTooltip>
  );
};

export default ErrorInfoIcon;
