import { FC } from 'react';

import PagePagination from 'components/Pagination';
import { useBulkImportContext } from '../../BulkImportContext';

export interface ReviewTablePaginationProps {
  limit: number;
  page: number;
  onLimitChanged: (limit: number) => void;
  onPageChange: (page: number) => void;
}

const ReviewTablePagination: FC<ReviewTablePaginationProps> = ({ limit, page, onLimitChanged, onPageChange }) => {
  const { dataList } = useBulkImportContext();

  return (
    <PagePagination
      limit={limit}
      count={dataList.length}
      page={page}
      onLimitChanged={onLimitChanged}
      onPageChange={(_, page) => onPageChange(page)}
      limitStep={10}
      extraLimitList={[200, 300, 400, 500]}
    />
  );
};

export default ReviewTablePagination;
