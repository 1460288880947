import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { Map, List } from 'immutable';
import IModel from '../../models/model';
import IApprove from '../../models/approve';

interface IThreeWaySwitchProps<T extends IModel> {
    style?: React.CSSProperties;
    state?: T;
    name?: keyof T;
    setValue?: (value: any) => void; // TODO any Remove?
    setState?: (value: T) => void;
}

export const ThreeWaySwitch = <T extends IModel, >({ name, setValue, state, setState, style }: IThreeWaySwitchProps<T>) => {
    let immutableState: Map<string, any>;
    if (state) {
        immutableState = Map(state);
    } else {
        immutableState = Map();
    }
    let immutableApprove: List<IApprove>;
    if (state?.approve) {
        immutableApprove = List(state.approve);
    } else {
        immutableApprove = List();
    }
    let approve = immutableApprove?.find((x) => x.field === name);
    // console.log('!approve type', typeof approve);
    // console.log('!approve', approve);
    // const [state, setState] = React.useState(approve?.state);
    // TODO any
    const handleState = (event: any, newState?: boolean) => {
        if (name && immutableState) {
            // setState(newState);
            if (!immutableApprove) {
                immutableApprove = List();
            }
            const approveIndex = immutableApprove.findIndex((x) => x.field === name);
            if (approveIndex >= 0) {
                const oldValue = immutableApprove.get(approveIndex);
                if (oldValue) {
                    immutableApprove = immutableApprove.set(approveIndex, {...oldValue, state: newState});
                }
            } else {
                immutableApprove = immutableApprove.push({field: name.toString(), state: newState});
            }
            immutableState = immutableState.set('approve', immutableApprove);
            // TODO any
            const immutableStateJs: any = immutableState.toJS();
            console.log('!immutableStateJs', immutableStateJs);
            setState && setState(immutableStateJs);
        } 
    };
    const RedToggleButton = withStyles({
        selected: {
            color: 'white !important',
            backgroundColor: 'red !important',
            fontWeight: 'bold !important' as any,
        }
    })(ToggleButton);
    const GreenToggleButton = withStyles({
        selected: {
            color: 'white !important',
            backgroundColor: 'green !important',
            fontWeight: 'bold !important' as any,
        }
    })(ToggleButton);
    const GreyToggleButton = withStyles({
        selected: {
            color: 'white !important',
            backgroundColor: 'grey !important',
            fontWeight: 'bold !important' as any,
        }
    })(ToggleButton);
    return (
        <ToggleButtonGroup
            value={approve?.state}
            exclusive
            size="small"
            onChange={setValue || handleState}
            style={{
                // marginTop: 16,
                // marginBottom: 8,
                // position: 'relative',
                // verticalAlign: 'bottom',
                marginLeft: 5,
                ...style,
            }}
            // aria-label="text alignment"
        >
        <RedToggleButton value={false}>
            <Tooltip title="Failed">
                <ClearIcon />
            </Tooltip>
        </RedToggleButton>
        {/* TODO: fix tooltip */}
        <GreyToggleButton value={null}>
            <Tooltip title="Not reviewed">
                <div style={{visibility: 'hidden'}}>......</div>
            </Tooltip>
        </GreyToggleButton>
        <GreenToggleButton value={true}>
            <Tooltip title="Ok">
                <CheckIcon />
            </Tooltip>
        </GreenToggleButton>
        </ToggleButtonGroup>
    );
};

export default ThreeWaySwitch;
