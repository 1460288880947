import React from 'react';

import IProduct from '../../../../models/product';
import InputTextField from '../../../inputfields/InputTextField';

interface IDimensionsProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

export const Dimensions = ({ state, setState }: IDimensionsProps) => {

  // TODO any
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });

  return (
    <InputTextField<IProduct>
      label="Mått"
      dense={false}
      inputName={'dimensions'}
      value={state.dimensions}
      onChange={handleChange}
      withLabel
      labelOptions={{
        heading: 'Mått',
      }}
    />
  );
};

export default Dimensions;
