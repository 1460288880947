import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

import ErrorStrongIcon from 'components/icons/ErrorStrongIcon';
import InfoIcon from 'components/icons/InfoIcon';

import { getFirstQuestionColumn } from '../../utils/excel';
import useControlContext from '../../hooks/useControlContext';
import useDataContext from '../../hooks/useDataContext';
import { IImportColumn } from '../../types';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    gap: 8,
    marginBottom: -12,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000000CC',
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#fff',
    width: 22,
    height: 22,

    '&:hover': {
      background: '#000',
    },

    '& > svg': {
      display: 'block',
      fontSize: 10,
    },
  },
  dotWrapper: {
    position: 'absolute',
    top: -7,
    right: -7,
    width: 16,
    height: 16,
    background: '#fff',
    borderRadius: '50%',
  },
  dotBackground: {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 12,
    height: 12,
    background: '#000',
    borderRadius: '50%',
  },
  tagContainer: {
    overflow: 'hidden',
    width: '100%',
  },
  tagWrapper: {
    position: 'relative',
    flex: '1',
    overflow: 'hidden',
    height: '100%',
  },
  tag: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    whiteSpace: 'nowrap',
    left: -1000, // default do not show
  },
}));

interface MoveToQuestionButtonProps {
  columnList: IImportColumn[];
}

const MoveToQuestionButton: FC<MoveToQuestionButtonProps> = ({ columnList }) => {
  const classes = useStyles();
  const { tableContainerElement, onScrollToColumn } = useControlContext();
  const { isUploadedSuccessfully } = useDataContext();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const tagContainerRef = useRef<HTMLDivElement | null>(null);
  const tagRef = useRef<HTMLDivElement | null>(null);

  const [isScrolled, setScrolled] = useState(false);

  const firstQuestionColumn = useMemo(() => getFirstQuestionColumn(columnList), [columnList]);

  const handleUpdateTagPosition = useCallback(() => {
    const field = firstQuestionColumn?.field;
    const targetCell = document.querySelector<HTMLElement>(`[data-field='${field}']`)?.parentElement;
    const titleCell = document.querySelector<HTMLElement>(`[data-field='title']`)?.parentElement;

    if (!targetCell || !tagRef.current || !tagContainerRef.current) return;

    const containerX = containerRef.current!.getBoundingClientRect().x;
    const targetX = targetCell.getBoundingClientRect().x;
    const titleCellEndX =
      (titleCell?.getBoundingClientRect().x || 0) +
      (titleCell?.getBoundingClientRect()?.width || 0) -
      (tableContainerElement?.getBoundingClientRect()?.x || 0);

    tagContainerRef.current.style.marginLeft = `${titleCellEndX}px`;
    tagRef.current.style.left = `${targetX - containerX - titleCellEndX}px`;
  }, [firstQuestionColumn?.field, tableContainerElement]);

  const handleClick = () => {
    setScrolled(true);
    onScrollToColumn(firstQuestionColumn?.field || '');
  };

  useEffect(() => {
    tableContainerElement?.addEventListener('scroll', handleUpdateTagPosition);
    const observer = new ResizeObserver(handleUpdateTagPosition);
    if (tableContainerElement) observer.observe(tableContainerElement);
    return () => {
      tableContainerElement?.removeEventListener('scroll', handleUpdateTagPosition);
      return observer.disconnect();
    };
  }, [tableContainerElement, handleUpdateTagPosition]);

  if (!firstQuestionColumn || isUploadedSuccessfully) return null;

  return (
    <div ref={containerRef} className={classes.wrapper}>
      <div className={classes.tagContainer}>
        <div ref={tagContainerRef} className={classes.tagWrapper}>
          <div ref={tagRef} className={classes.tag}>
            <InfoIcon />
            Ytterligare frågor (värden är standardvärden)
          </div>
        </div>
      </div>

      <div className={classes.button} onClick={handleClick}>
        {!isScrolled && (
          <div className={classes.dotWrapper}>
            <div className={classes.dotBackground} />
            <ErrorStrongIcon
              color="orange"
              width={16}
              height={16}
              style={{ position: 'relative', display: 'block', zIndex: 1 }}
            />
          </div>
        )}

        <ArrowForwardIcon />
      </div>
    </div>
  );
};

export default MoveToQuestionButton;
