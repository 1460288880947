import React, { FC } from 'react';

const FileIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <rect width="32" height="32" rx="4" fill="#DDEEEE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 25.2857C23 25.6802 22.6866 26 22.3 26H9.7C9.3134 26 9 25.6802 9 25.2857V6.71429C9 6.3198 9.3134 6 9.7 6H16.8263C17.0816 6 17.3264 6.10348 17.5069 6.28766L22.7181 11.6052C22.8986 11.7894 23 12.0392 23 12.2997V25.2857ZM10.4 24.5714V7.42857H15.3V12.4286C15.3 13.2175 15.9268 13.8571 16.7 13.8571H21.6V24.5714H10.4Z"
        fill="#56ABA9"
      />
    </svg>
  );
};

export default FileIcon;
