import { FC } from 'react';

import { Box, Divider, IconButton } from '@material-ui/core';
import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';
import ErrorSwitch from '../components/ErrorSwitch';

import useControlContext from '../hooks/useControlContext';

interface HeaderProps {
  showErrorOnly: boolean;
  errorCount: number;
  defaultErrorCount: number;
  count: number;
  onShowErrorChange: (bol: boolean) => void;
}

const Header: FC<HeaderProps> = ({ showErrorOnly, errorCount, defaultErrorCount, count, onShowErrorChange }) => {
  const { step, onStepChange } = useControlContext();

  return (
    <Box display="flex" alignItems="center" gridGap={16}>
      <IconButton style={{ padding: 4, marginTop: 18, marginBottom: 'auto' }} onClick={() => onStepChange(step - 1)}>
        <ArrowLeftIcon />
      </IconButton>

      <Box flexGrow={1} flexShrink={0} mb="auto">
        <Box mb={1} sx={{ fontSize: 10, color: '#737DA0', fontWeight: 600 }}>
          Steg 3 av 4
        </Box>
        <Box display="flex" justifyItems="center" gridGap={8} mb={1} sx={{ fontSize: 20, lineHeight: 1 }}>
          Fields Mapping
        </Box>
        <Box mb={1} sx={{ fontSize: 14, lineHeight: 1 }}>
          {`${count} Kolumner att matcha`}
        </Box>
      </Box>

      <Box flexGrow={1} px={2}>
        <div>
          Bekräfta att varje <b>Foodla</b>-artikelattribut matchas till rätt kolumn i din importfil. Om det inte matchas
          korrekt kan du korrigera det manuellt.
        </div>
        <div>
          Om du inte hittar någon matchande <b>Foodla</b>-kategori krävs inte den informationen för din valda kategori
          men denna information kommer fortfarande att sparas.
        </div>
        {!errorCount && (
          <div style={{ color: 'green', marginTop: 8 }}>
            {defaultErrorCount
              ? 'Alla kolumner är matchade!'
              : 'Foodla har automatiskt matchat alla dina kolumner med motsvarande Foodla-kolumn!'}
          </div>
        )}
      </Box>
      {!!errorCount && (
        <>
          <Divider orientation="vertical" flexItem style={{ marginTop: 10, marginBottom: 10 }} />
          <Box flexGrow={1} flexShrink={0}>
            <ErrorSwitch
              errorCount={errorCount}
              label={'Visa bara kolumner med felmappning'}
              checked={showErrorOnly}
              onChange={onShowErrorChange}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Header;
