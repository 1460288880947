import React from 'react';
import { Query } from 'react-apollo';
import Paper from '@material-ui/core/Paper';
import CardGrid from '../../components/CardGrid';
import ProducerCard from '../../components/producer/ProducerCard';
import ErrorBoundary from '../../components/ErrorBoundary';

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import FreeBreakfastOutlined from '@material-ui/icons/FreeBreakfastOutlined';
import Grid from '@material-ui/core/Grid';

import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { GET_PRODUCERS } from '../../graphql/queries';
import ErrorBox from '../../components/Error';
import useSearchParams from 'hooks/useSearchParams';

const styles = theme => ({
  toolbarBox: {
      flexGrow: 1,
  },
  title: {
      flexGrow: 1,
  },
  container: {
    padding: theme.spacing(2),
  },
});

const AllProducers = ({ classes, ...props }) => {
  const { searchParams } = useSearchParams();
    // extract isIca
    return (
        <ErrorBoundary>
            <Paper>
                <Query query={GET_PRODUCERS} variables={{ textQuery: searchParams.search }}>
                    {({ loading, error, networkStatus, data, refetch }) => {

                        if (error || networkStatus === 8) {
                            return (
                                <ErrorBox message={`${error ? error.message : 'Network error'}`} />
                            );
                        }
                        if (data === undefined || data == null || (Array.isArray(data) && data.length === 0)) {
                            return (
                                <Box>
                                    <FreeBreakfastOutlined />
                                    <Typography variant="body2"> No data </Typography>
                                </Box>
                            );
                        }

                        return (
                            <ProducerGridList
                                data={data} 
                                loading={loading} 
                                showSearch={true} 
                                refetch={refetch}
                            />
                        );
                    }}
                </Query>
            </Paper>
        </ErrorBoundary>
    );
};

const ProducerGridList = withStyles(styles)(({ classes, data, showSearch, refetch, loading }) => {
  const { searchParams, updateSearchParams } = useSearchParams();
    const [firstLoad, setFirstLoad] = React.useState(false);
    const [query, setQuery] = React.useState(searchParams.search || "");
    const list = data?.producers?.data;
    const total = data?.producers?.summary?.total;
    React.useEffect(() => {
        if (!firstLoad) {
            setFirstLoad(true);
        } else {
            if (refetch) {
                refetch({
                    textQuery: query,
                });
            }
        }
    }, [query]);
    return (
        <>
              <>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.toolbarBox}>
                    <Typography variant="body1" className={classes.title}>
                        All producers ({total} st)
                    </Typography>
                    </Box>
                    <div>
                        <Button
                            component={RouterLink}
                            to='/superuser/addproducer'
                            variant="outlined"
                            size="medium"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            New
                        </Button>
                    </div>
                </Toolbar>
                <Divider />
                {showSearch && (
                    <TextField
                        placeholder="Search by Producer Name"
                        variant="outlined"
                        value={query}
                        // disabled={loading}
                        style={{marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, width: 500}}
                        onChange={(e) => {
                            setQuery(e.target.value);
                            updateSearchParams({ search: e.target.value });
                        }}
                    />
                )}
                <Divider />
                {loading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: 20,
                            paddingBottom: 20,
                        }}
                    >
                        <CircularProgress size={70} variant="indeterminate" />
                    </div>
                )}
                <Grid container spacing={2} className={classes.container}>
                    {list && list.map(r => (
                        <Grid key={r.id} item xs={3}>
                            <ProducerCard {...r} superuser />
                        </Grid>
                    ))}
                </Grid>
                </>
        </>
    );
    
});
export default AllProducers;
