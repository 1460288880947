import Box from '@material-ui/core/Box';

const Header = () => {
  return (
    <Box display="flex" alignItems="center" gridGap={16}>
      <Box flexGrow={1} flexShrink={0}>
        <Box mb={1} sx={{ fontSize: 10, color: '#737DA0', fontWeight: 600 }}>
          Steg 1 av 4
        </Box>
        <Box display="flex" justifyItems="center" gridGap={8} mb={1} sx={{ fontSize: 20, lineHeight: 1 }}>
          Välj en kategori
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
