import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  badge: props => {
    return ({
      left: -55,
      top: props.showDescriptiveSize ? 120 : 80,
      border: `3px solid rgba(65, 84, 179, 1)`,
      textAlign: 'center',
      fontSize: 16,
      color: "white",
      borderRadius: 50,
      width: 100,
      height: 100,
      backgroundColor: "rgba(65, 84, 179, 0.8)", //COLORS.mainOrangeWithOpacity('0.8'),
      padding: '0 4px',
    });
  }
});

const StyledProductBadge = withStyles((theme) => ({
    badge: props => ({
      left: -55,
      top: props.showDescriptiveSize ? 120 : 80,
      border: `3px solid rgba(65, 84, 179, 1)`,
      textAlign: 'center',
      fontSize: 16,
      color: "white",
      borderRadius: 50,
      width: 100,
      height: 100,
      backgroundColor: "rgba(65, 84, 179, 0.8)", //COLORS.mainOrangeWithOpacity('0.8'),
      padding: '0 4px',
    }),
}))(Badge);

// export default StyledProductBadge;
export default withStyles(styles)(Badge);
