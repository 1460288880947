import IModel from "./model";
import IProducer from "./producer";

export interface ILabel {
    field: string; // TODO make keyof
    state?: boolean;
}

export enum NutritionalUnit {
    GRAM = 'GRAM',
    MILLILITER = 'MILLILITER',
}

export interface IFoodlaCategory {
  id: string;
  name: string;
}

export interface IFullFoodlaCategoryTree {
  id: string;
  name: string;
  child?: IFullFoodlaCategoryTree
}

export enum FishProductionMethodEnum {
  CULTIVATION_IN_WATER = 'Odling i vatten av exempelvis fisk, skaldjur, alger eller vattenväxter',
  INLAND_FISHING = 'Inlandsfiske',
  SEA_FISHING = 'Havsfiske'
}
  
export enum FishCatchMethodEnum {
  SNOOD = 'Snörpvad',
  VAD = 'Vad',
  TRAWLS = 'Trålar',
  DREDGING_GEAR = 'Skrapredskap',
  SINKERS = 'Sänkhåvar',
  ENTANGLING_NETS = 'Botten- och insnärjningsgarn',
  TRAPS = 'Fällor',
  HOOKS_AND_LINES = 'Krokar och revar',
  DREDGES_FROM_BOATS = 'Skrapredskap från båt',
  HAND_HELD_DREDGES = 'Handhållna skrapredskap',
  GEAR_FOR_CATCHING_LEAPING_FISH = 'Redskap för fångst av hoppande fisk',
  CAST_NETS = 'Kastnät',
  DROP_NETS = 'Fallnät (ej specificerade)',
  FIXED_UNCOVERED_GILLNETS = 'Fasta ej täckta bottengarn',
  TINOR = 'Tinor',
  FIXED_HAMMERS = 'Fasta hommor',
  TENSION_NETS = 'Spännät, fasta fällor, bottengarn, etc.',
  FYKE_NETS = 'Ryssjor',
  NETS = 'Nät (ej specificerade)',
  CIRCLE_NETS = 'Nät (cirkelnät)',
  DRIFTING_NETS = 'Nät (drivande)',
  FIXED_GILLNETS = 'Fasta bottengarn (fastgjorda vid pålar)',
  ANCHORED_NETS = 'Förankrade nät',
  COMBINED_GILL_AND_TRAMMEL_NETS = 'Kombinerade botten- och grimgarn',
  GILLNETS = 'Grimgarn',
  HARPOONS = 'Harpuner',
  MECHANICAL_DREDGES = 'Mekaniska skrapredskap',
  PIMPS = 'Pumpar',
  MACHINERY = 'Maskiner (ej specificerade)',
  WITHOUT_CORDAGE = 'Utan snörplinor (lampara)',
  MECHANICAL_JIGGING = 'Pilk- och angelgarn (mekaniska)',
  HAND_OPERATED_JIGGING = 'Pilk- och angelgarn (handstyrda)',
  LONG_LINES = 'Långrevar (ej specificerade)',
  DRIFTING_TRAPS = 'Drivande backor/långrevar',
  ANCHORED_TRAPS = 'Förankrade backor/långrevar',
  CAISSONS_FROM_BOATS = 'Sänkhåvar som används från båtar',
  PORTABLE_DREDGES = 'Bärbara sänkhåvar',
  SHOALSUSED_FROM_THE_SHORE = 'Sänkhåvar som används från stranden',
  TOWLINES = 'Dörjlinor',
  PURSE_SEINES_AND_SINKERS = 'Snörpvad och sänkhåvar (ej specificerade GS1 Code)',
  MISCELLANEOUS_OTHER_GEAR = 'Diverse övriga redskap',
  UNKNOWN_GEAR = 'Okända eller ej specificerade redskap',
  TRAWLS_USED_WITH_TRAWL_TABLES = 'Trålar som används med trålbord (ej specificerade)',
  TRAWL_WITH_TRAWL_TABLE = 'Trål med trålbord',
  PELAGIC_TRAWL = 'Flyttrål',
  DOUBLE_TRAWLS = 'Dubbeltrålar som används med trålbord',
  RINGNOT = 'Ringnot',
  PURSE_SEINE_CONTROLLED_FROM_VESSEL = 'Snörpvad som styrs från ett fartyg',
  TWO_VESSEL_PERSE_SEINE = 'Snörpvad som styrs från två fartyg',
  PAIR_TRAWL = 'Partrål (två fartyg) (ej specificerade)',
  BOTTOM_TRAWL_FOR_PAIR_TRAWLING = 'Bottentrål för partrålning',
  PAIR_TRANSFER_TRAWL = 'Parflyttrål',
  FISHING_GEAR_FOR_SPORT_FISHING = 'Fiskeredskap för sportfiske',
  SHORE_SEINE = 'Landvad',
  PURSE_SEINE = 'Snurrevad',
  DRAGGED_BY_TWO_VESSELS = 'Vad som dras av två fartyg (fly dragging)',
  SCOTTISH_PURSE_SEINE = 'Skotsk snurrevad (fly dragging)',
  PURSE_SEINE_DEPLOYED_FROM_BOATS = 'Notar som används från båtar eller fartyg',
  BOTTOM_TRAWLS = 'Bottentrålar (ej specificerade)',
  BEAM_TRAWLS = 'Bomtrålar',
  NEPHROPS_TRAWLS = 'Trålar för havskräftor',
  SHRIMP_TRAWLS = 'Räktrålar',
  PELAGIC_TRAWLS = 'Flyttrålar (ej specificerade)',
  SHRIMP_TRAWL = 'Räktrål',
  OTHER_TRAWLS = 'Andra trålar (ej specificerade)'
}

export enum CatchAreaEnum {
  ARCTIC_OCEAN = 'Norra ishavet',
  NORTHWEST_ATLANTIC = 'Nordvästatlanten',
  NORTHEAST_ATLANTIC = 'Nordostatlanten',
  BARENTS_SEA = 'Barents hav (Delområde 27.1)',
  AZORES_SHELF = 'Azorernas sockel (Delområde 27.10)',
  NORTH_AZORES = 'Farvattnen norr om Azorerna (Delområde 27.12)',
  EAST_GREENLAND = 'Östra Grönland (Delområde 27.14)',
  NORTHEAST_GREENLAND = 'Nordöstra Grönland Sektion 27.14.a)',
  SOUTH_EAST_GREENLAND = 'Sydöstra Grönland (Sektion 27.14.b)(Sektion 27.14.b)',
  NORWEGIAN_SEA_SVALBARD_BEAR = 'Norska havet och farvattnen kring Svalbard och Björnön (Delområde 27.2)',
  NORWEGIAN_SEA = 'Norska havet (Sektion 27.2.a)',
  SVALBARD_BEAR_ISLAND = 'Farvattnen kring Svalbard och Björnön (Sektion 27.2.b)',
  SKAGERRAK_KATTEGAT_ORESUND_BELT_BALTIC_SEA = 'Skagerack, Kattegatt, Öresund, Bälthavet och Östersjön (Delområde 27.3)',
  SKAGERRAK_KATTEGAT = 'Skagerrak och Kattegatt (Sektion 27.3.a)',
  ORESUND = 'Öresund (Sektion 27.3.b)',
  SOUND_BELT_SEA = 'Sound och Bälthavet eller övergångsområdet',
  BELT_SEA = 'Bälthavet (Sektion 27.3.c)',
  BALTIC_SEA = 'Östersjön (Sektion 27.3.d)',
  NORTH_SEA = 'Nordsjön (Delområde 27.4)',
  NORTHERN_NORTH_SEA = 'Nordsjöns norra del (Sektion 27.4.a)',
  CENTRAL_NORTH_SEA = 'Nordsjöns mellersta del (Sektion 27.4.b)',
  SOUTHERN_NORTH_SEA = 'Nordsjöns södra del (Sektion 27.4.c)',
  ICELANDIC_FAROESE_SHELVES = 'Islands och Färöarnas socklar (Delområde 27.5)',
  ICELANDIC_SHELF = 'Islandssockeln (Sektion 27.5.a)',
  FAROE_SHELF = 'Färösockeln (Sektion 27.5.b)',
  ROCKALL_BANK_HEBRIDEAN = 'Rockall bank och Hebridernas farvatten (Delområde 27.6)',
  NORTH_WEST_SCOTLAND_NORTHERN_IRELAND = 'Skottlands nordvästra kust och Nordirland eller västra Skottland (Sektion 27.6.a)',
  ROCKALL_BANK = 'Rockall bank (Sektion 27.6.b)',
  IRISH_SEA_PORCUPINE_BANK = 'Irländska sjön, farvattnen väster om Irland, Porcupine bank, Engelska kanalens östra och västra del, osv. (Delområde 27.7)',
  IRISH_SEA = 'Irländska sjön (Sektion 27.7.a)',
  WATERS_WEST_IRELAND = 'Farvattnen väster om Irland (Sektion 27.7.b)',
  PORCUPINE_BANK = 'Porcupine Bank (Sektion 27.7.c)',
  EASTERN_CHANNEL = 'Engelska kanalens östra del (Sektion 27.7.d)',
  WESTERN_CHANNEL = 'Engelska kanalens västra del (Sektion 27.7.e)',
  BRISTOL_CHANNEL = 'Bristolkanalen (Sektion 27.7.f)',
  CELTIC_SEA_NORTH = 'Keltiska sjöns norra del (Sektion 27.7.g)',
  SOUTHERN_CELTIC_SEA = 'Keltiska sjöns södra del (Sektion 27.7.h)',
  EASTERN_SOUTHWEST_IRELAND = 'Farvattnen sydväst om Irland - östra delen (Sektion 27.7.j)',
  WESTERN_SOUTHWEST_IRELAND = 'Farvattnen sydväst om Irland - västra delen (Sektion 27.7.k)',
  BAY_BISCAY = 'Biscayabukten (Delområde 27.8)',
  NORTH_BAY_BISCAY = 'Biscayabuktens norra del (Sektion 27.8.a)',
  CENTRAL_BAY_BISCAY = 'Biscayabuktens mellersta del (Sektion 27.8.b)',
  SOUTHERN_BAY_BISCAY = 'Biscayabuktens södra del (Sektion 27.8.c)',
  BISCAY_SEAWARD = 'Biscayabuktens anslutning mot havet (Sektion 27.8.d)',
  WESTERN_BAY_BISCAY = 'Biscayabuktens västra del (Sektion 27.8.e)',
  PORTUGUESE_WATERS = 'Portugals farvatten (Delområde 27.9)',
  EASTERN_PORTUGUESE_WATERS = 'Portugals farvatten - östra delen (Sektion 27.9.a)',
  WESTERN_PORTUGUESE_WATERS = 'Portugals farvatten - västra delen (Sektion 27.9.b)',
  WESTERN_MID_ATLANTIC = 'Västra mellersta Atlanten',
  EASTERN_MID_ATLANTIC = 'Östra mellersta Atlanten',
  MEDITERRANEAN_BLACK_SEA = 'Medelhavet och Svarta havet',
  SOUTHWEST_ATLANTIC = 'Sydvästra Atlanten',
  SOUTHEAST_ATLANTIC = 'Sydöstra Atlanten',
  ATLANTIC_OCEAN_ANTARCTIC = 'Atlanten, Antarktis',
  WEST_INDIAN_OCEAN = 'Västindiska Oceanen',
  EAST_INDIAN_OCEAN = 'Östindiska Oceanen',
  SOUTHERN_INDIAN_OCEAN_ANTARCTICA = 'Södra indiska oceanen Antarktis',
  NORTHWEST_PACIFIC = 'Nordvästra stillahavet',
  NORTHEAST_PACIFIC = 'Nordöstra stillahavet',
  WESTERN_CENTRAL_PACIFIC = 'Västra mellersta stillahavet',
  EASTERN_CENTRAL_PACIFIC = 'Östra mellersta stillahavet',
  SOUTHWEST_PACIFIC = 'Sydvästra stillahavet',
  SOUTHEAST_PACIFIC = 'Sydöstra stillahavet',
  PACIFIC_OCEAN_ANTARCTIC = 'Stillahavet, Antarktis'
}

export enum EggWeightClassEnum {
  XL = 'XL – mycket stora: 73 g eller mer. Klass A',
  L_XL = 'L/XL - 63 up till 73 g eller mer. Klass A',
  L = 'L – stora: 63 till < 73 g. Klass A',
  M_L = 'M/L - 53 up till < 73 g. Klass A',
  M = 'M – medelstora: 53 till < 63 g. Klass A',
  S_M = 'S/M - under 53 g up till < 63 g. Klass A',
  S = 'S – små: under 53 g. Klass A'
}

export enum RecyclingTypeEnum {
  BOTTLE = 'Flaska 330 ml 12% moms',
  GLASS_BOTTLE = 'Glasflaska 500 ml 12% moms',
  RECYCLABLE_PET = 'Återvinningsbar PET över 1000 ml 12% moms',
  RECYCLABLE_PET_UP_TO_1000 = 'Återvinningsbar PET upp till och med 1000 ml 12% moms',
  ALUMINUM_CAN = 'Aluminiumburk 12% moms (1 SEK)'
}

export enum SignalWordsEnum {
  WARNING = 'Varning',
  DANGER = 'Fara',
  NOT_APPLICABLE = 'Ej tillämpbar'
}

export enum PreparedFormEnum {
  FACIAL_TONER = 'Ansiktsvatten',
  BALM = 'Balsam',
  BAR = 'Bar',
  BREAST_TABLET = 'Brustablett',
  DROPS = 'Droppar',
  LIQUID = 'Flytande',
  GEL = 'Gel',
  GRANULES = 'Granulat',
  COLLAR = 'Halsband',
  INHALER = 'Inhalator',
  INJECTION = 'Injektion',
  INSERTS_IMPLANTS = 'Inlägg/implantat',
  WRAPPING = 'Inpackning',
  CAPSULE = 'Kapsel',
  ENEMA = 'Klysma',
  CREAM = 'Kräm',
  CLYSTER = 'Lavemang',
  LINIMENT = 'Liniment',
  LOTION = 'Lotion',
  SOLUTION = 'Lösning',
  MASK = 'Mask',
  MICRO_CLYSTER = 'Mikrolavemang',
  MILK = 'Milk',
  MOUSSE = 'Mousse',
  ORAL_SPRAY = 'Munhålespray',
  MOUTHWASH = 'Munskölj',
  MOUTH_SPRAY = 'Munspray',
  MOUTH_DECAY_TABLET = 'Munsönderfallande tablett',
  NAIL_VARNISH = 'Nagellack',
  NASAL_DROPS = 'Näsdroppar',
  NASAL_SHOWER = 'Näsdusch',
  NASAL_RINSE = 'Nässkölj',
  NASAL_SPRAY = 'Nässpray',
  OIL = 'Olja',
  PASTES = 'Pasta',
  PLASTERS = 'Plåster',
  SACHETS = 'Portionspåsar',
  POWDERS = 'Puder',
  POWDER = 'Pulver',
  TRAVEL_TABLET = 'Resoriblett',
  ROLL_ON = 'Roll-on',
  SALT = 'Salt',
  SALVA = 'Salva',
  SHAMPOO = 'Schampo',
  SCRUB = 'Scrub',
  SERUM = 'Serum',
  SPRAY = 'Spray',
  STICK = 'Stick',
  SUPPOSITORIES = 'Stolpiller',
  LOZENGE = 'Sugtablett',
  TABLET = 'Tablett',
  TOOTHPASTE = 'Tandkräm',
  CHEWING_CAPSULE = 'Tuggkapsel',
  CHEWING_TABLET = 'Tuggtablett',
  CHEWING_GUM = 'Tuggummi',
  SOAP = 'Tvål',
  VAGINAL_TABLET = 'Vaginaltablett',
  VAGITORIUM = 'Vagitorium',
  WAX = 'Vax',
  WET_WIPES = 'Våtservett',
  EYE_DROPS = 'Ögondroppar',
  NOT_APPLICABLE = 'Ej tillämpbar'
}

export enum CategoryWeightlossEnum {
  MEAL_REPLACEMENT = 'Måltidsersättning',
  LCD = 'LCD (Low Calorie Diet)',
  VLCD = 'VLCD (Very Low Calorie Diet)',
  NON_WEIGHT_LOSS = 'Ej viktminskningsprodukt'
}

export enum ComplianceBiocidEnum {
  YES = 'Ja',
  NO = 'Nej',
  NOT_BIOCIDAL = 'Ej biocidprodukt'
}

export enum ComplianceBiocidalTreatedEnum { 
  YES = 'Ja',
  NO = 'Nej',
  NOT_BIOCID_TREATMENT = 'Ej biocidbehandlad'
}

export enum ConfirmGuidanceSwedishElectronicsEnum {
  YES = 'Ja',
  NO = 'Nej',
  NOT_ELECTRICAL = 'Ej elektrisk'
}

export enum ComplianceToyDirectiveEnum {
  YES = 'Ja',
  NO = 'Nej',
  NOT_TOY = 'Ej leksak'
}

export enum ComplianceMetalsSkinContactEnum {
  YES = 'Ja',
  NO = 'Nej',
  NO_DIRECT_CONTACT_WITH_SKIN = 'Produkten kommer ej i direkt/långvarig kontakt med huden'
}

export enum ApprovalDietFeedEnum { 
  YES = 'Ja',
  NO = 'Nej',
  NOT_DIET = 'Ej dietfoder'
}

export enum ComplianceSunscreenRegulationEnum {
  YES = 'Ja',
  NO = 'Nej',
  NO_SUNSCREEN = 'Ej solskyddsmedel'
}

export enum SunProtectionFactorEnum {
  SIX = '6',
  TEN = '10',
  FIFTEEN = '15',
  TWENTY = '20',
  TWENTY_FIVE = '25',
  THIRTY = '30',
  FIFTY = '50',
  FIFTY_PLUS = '50+'
}

export enum AdditionalCharacteristicsEnum {
  ANTI_AGE = 'Anti-age',
  EFFECT_12_HOURS = 'Effekt i 12 timmar',
  EFFECT_1_2_HOURS = 'Effekt i 1-2 timmar',
  EFFECT_24_HOURS = 'Effekt i 24 timmar',
  OILY_SKIN = 'Fet hud',
  DOG = 'Hund',
  HORSE = 'Häst',
  CAT = 'Katt',
  SENSITIVE_SKIN = 'Känslig hud',
  NORMAL_MIXED_SKIN = 'Normal/blandad hud',
  UNSCENTED = 'Oparfymerad',
  PROBLEM_SKIN = 'Problemhud',
  SILICONE_FREE = 'Silikonfri',
  DRY_SKIN = 'Torr hud'
}

export enum HazardSymbolsEnum {
  GAS = 'Gas under tryck',
  EXPLOSIVE = 'Explosiv',
  OXIDISING = 'Oxiderande',
  FLAMMABLE = 'Brandfarlig',
  CORROSIVE = 'Frätande',
  HEALTH_HAZARD = 'Skadlig',
  ACUTE_TOXICITY = 'Giftig',
  SERIOUS_HEALTH_HAZARD = 'Häslofarlig',
  HAZARDOUS_ENVIRONMENT = 'Miljöfarlig'
};

export enum OrderableUnitEnum {
  CONSUMER_PACK = 'Konsumentförpackning',
  STORE_PACK = 'Distributionsförpackning',
  TRANSPORT = 'Transportförpackning',
  PALLET = 'Pall',
};

export enum DespatchUnitEnum {
  CONSUMER_PACK = 'Konsumentförpackning',
  STORE_PACK = 'Distributionsförpackning',
  TRANSPORT = 'Transportförpackning',
  PALLET = 'Pall',
};

export enum HazardStatementsEnum {
  H200 = 'H200 Instabilt, explosivt',
  H201 = 'H201 Explosivt. Fara för massexplosion',
  H202 = 'H202 Explosivt. Allvarlig fara för splitter och kaststycken',
  H203 = 'H203 Explosivt. Fara för brand, tryckvåg eller splitter och kaststycken',
  H204 = 'H204 Fara för brand eller splitter och kaststycken',
  H205 = 'H205 Fara för massexplosion vid brand',
  H206 = 'H206 Fara för brand, tryckvåg eller splitter och kaststycken, ökad explosionsrisk om det okänsliggörande ämnet minskas',
  H207 = 'H207 Fara för brand eller splitter och kaststycken, ökad explosionsrisk om det okänsliggörande ämnet minskas',
  H208 = 'H208 Fara för brand, ökad explosionsrisk om det okänsliggörande ämnet minskas',
  H220 = 'H220 Extremt brandfarlig gas',
  H221 = 'H221 Brandfarlig gas',
  H222 = 'H222 Extremt brandfarlig aerosol',
  H223 = 'H223 Brandfarlig aerosol',
  H224 = 'H224 Extremt brandfarlig vätska och ånga',
  H225 = 'H225 Mycket brandfarlig vätska och ånga',
  H226 = 'H226 Brandfarlig vätska och ånga',
  H228 = 'H228 Brandfarligt fast ämne',
  H229 = 'H229 Tryckbehållare: Kan sprängas vid uppvärmning',
  H230 = 'H230 Kan reagera explosivt även i frånvaro av luft',
  H231 = 'H231 Kan reagera explosivt även i frånvaro av luft vid förhöjt tryck och/eller temperatur',
  H232 = 'H232 Kan spontanantända vid kontakt med luft',
  H240 = 'H240 Explosivt vid uppvärmning',
  H241 = 'H241 Brandfarligt eller explosivt vid uppvärmning',
  H242 = 'H242 Brandfarligt vid uppvärmning',
  H250 = 'H250 Spontanantänder vid kontakt med luft',
  H251 = 'H251 Självupphettande. Kan börja brinna',
  H252 = 'H252 Självupphettande i stora mängder. Kan börja brinna',
  H260 = 'H260 Vid kontakt med vatten utvecklas brandfarliga gaser som kan självantända',
  H261 = 'H261 Vid kontakt med vatten utvecklas brandfarliga gaser',
  H270 = 'H270 Kan orsaka eller intensifiera brand. Oxiderande',
  H271 = 'H271 Kan orsaka brand eller explosion. Starkt oxiderande',
  H272 = 'H272 Kan intensifiera brand. Oxiderande',
  H280 = 'H280 Innehåller gas under tryck. Kan explodera vid uppvärmning',
  H281 = 'H281 Innehåller kyld gas. Kan orsaka svåra köldskador',
  H290 = 'H290 Kan vara korrosivt för metaller.1.2 Faroangivelser för hälsofaror:',
  H300 = 'H300 Dödligt vid förtäring',
  H301 = 'H301 Giftigt vid förtäring',
  H302 = 'H302 Skadligt vid förtäring',
  H304 = 'H304 Kan vara dödligt vid förtäring om det kommer ner i luftvägarna',
  H310 = 'H310 Dödligt vid hudkontakt',
  H311 = 'H311 Giftigt vid hudkontakt',
  H312 = 'H312 Skadligt vid hudkontakt',
  H314 = 'H314 Orsakar allvarliga frätskador på hud och ögon',
  H315 = 'H315 Irriterar huden',
  H317 = 'H317 Kan orsaka allergisk hudreaktion',
  H318 = 'H318 Orsakar allvarliga ögonskador',
  H319 = 'H319 Orsakar allvarlig ögonirritation',
  H330 = 'H330 Dödligt vid inandning',
  H331 = 'H331 Giftigt vid inandning',
  H332 = 'H332 Skadligt vid inandning',
  H334 = 'H334 Kan orsaka allergi- eller astmasymtom eller andningssvårigheter vid inandning',
  H335 = 'H335 Kan orsaka irritation i luftvägarna',
  H336 = 'H336 Kan göra att man blir dåsig eller omtöcknad',
  H340 = 'H340 Kan orsaka genetiska defekter. <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H341 = 'H341 Misstänks kunna orsaka genetiska defekter. <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H350 = 'H350 Kan orsaka cancer. <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H351 = 'H351 Misstänks kunna orsaka cancer. <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H360 = 'H360 Kan skada fertiliteten eller det ofödda barnet. <ange specifik effekt om denna är känd> <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar',
  H361 = 'H361 Misstänks kunna skada fertiliteten eller det ofödda barnet. <ange specifik effekt om denna är känd> <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H362 = 'H362 Kan skada spädbarn som ammas',
  H370 = 'H370 Orsakar organskador. <eller ange vilka organ som påverkas, om detta är känt> <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H371 = 'H371 Kan orsaka organskador. <eller ange vilka organ som påverkas, om detta är känt> <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H372 = 'H372 Orsakar organskador. <eller ange vilka organ som påverkas, om detta är känt> genom lång eller upprepad exponering <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H373 = 'H373 Kan orsaka organskador. <eller ange vilka organ som påverkas, om detta är känt> genom lång eller upprepad exponering <ange exponeringsväg om det är definitivt bevisat att faran inte kan orsakas av några andra exponeringsvägar>',
  H300_H310 = 'H300 + H310 Dödligt vid förtäring eller vid hudkontakt',
  H300_H310_H330 = 'H300 + H310 + H330 Dödligt vid förtäring, hudkontakt eller inandning',
  H300_H330 = 'H300 + H330 Dödligt vid förtäring eller inandning',
  H310_H330 = 'H310 + H330 Dödligt vid hudkontakt eller inandning',
  H310_H310_H330 = 'H310 + H310 + H330 Dödligt vid förtäring, hudkontakt eller inandning',
  H301_H311 = 'H301 + H311 Giftigt vid förtäring eller hudkontakt',
  H301_H331 = 'H301 + H331 Giftigt vid förtäring eller inandning',
  H311_H331 = 'H311 + H331 Giftigt vid hudkontakt eller inandning',
  H301_H311_H331 = 'H301 + H311 + H331 Giftigt vid förtäring, hudkontakt eller inandning',
  H302_H312 = 'H302 + H312 Skadligt vid förtäring eller hudkontakt',
  H302_H332 = 'H302 + H332 Skadligt vid förtäring eller inandning',
  H312_H332 = 'H312 + H332 Skadligt vid hudkontakt eller inandning',
  H302_H312_H332 = 'H302 + H312 + H332 Skadligt vid förtäring, hudkontakt eller inandning.1.3 Faroangivelser för miljöfaror',
  H400 = 'H400 Mycket giftigt för vattenlevande organismer',
  H410 = 'H410 Mycket giftigt för vattenlevande organismer med långtidseffekter',
  H411 = 'H411 Giftigt för vattenlevande organismer med långtidseffekter',
  H412 = 'H412 Skadliga långtidseffekter för vattenlevande organismer',
  H413 = 'H413 Kan ge skadliga långtidseffekter på vattenlevande organismer',
  H420 = 'H420 Skadar folkhälsan och miljön genom att förstöra ozonet i övre delen av atmosfären',
  // Del 2. Kompletterande faroinformation
  EUH014 = 'EUH014 Reagerar häftigt med vatten',
  EUH018 = 'EUH018 Vid användning kan brännbara/explosiva ångluftblandningar bildas',
  EUH019 = 'EUH019 Kan bilda explosiva peroxider',
  EUH029 = 'EUH029 Utvecklar giftig gas vid kontakt med vatten',
  EUH031 = 'EUH031 Utvecklar giftig gas vid kontakt med syra',
  EUH032 = 'EUH032 Utvecklar mycket giftig gas vid kontakt med syra',
  EUH044 = 'EUH044 Explosionsrisk vid uppvärmning i sluten behållare',
  EUH059 = 'EUH059 Farligt för ozonskiktet',
  EUH066 = 'EUH066 Upprepad kontakt kan ge torr hud eller hudsprickor',
  EUH070 = 'EUH070 Giftigt vid kontakt med ögonen',
  EUH071 = 'EUH071 Frätande på luftvägarna',
  // Del 3. Kompletterande märkning/information om vissa ämnen eller blandningar
  EUH201 = 'EUH201 Innehåller bly. Bör inte användas på ytor där barn kan komma åt att tugga eller suga',
  EUH201A = 'EUH201A Varning! Innehåller bly',
  EUH202 = 'EUH202 Cyanoakrylat. Fara. Fäster snabbt på hud och ögon. Förvaras oåtkomligt för barn',
  EUH203 = 'EUH203 Innehåller krom (VI). Kan orsaka en allergisk reaktion',
  EUH204 = 'EUH204 Innehåller isocyanater. Kan orsaka en allergisk reaktion',
  EUH205 = 'EUH205 Innehåller epoxiförening. Kan orsaka en allergisk reaktion',
  EUH206 = 'EUH206 Varning! Får ej användas tillsammans med andra produkter. Kan avge farliga gaser (klor)',
  EUH207 = 'EUH207 Varning! Innehåller kadmium. Farliga ångor bildas vid användning. Se information från tillverkaren. Följ skyddsanvisningarna',
  EUH208 = 'EUH208 Innehåller _____. Kan orsaka en allergisk reaktion',
  EUH209 = 'EUH209 Kan bli mycket brandfarligt vid användning',
  EUH209A = 'EUH209A Kan bli brandfarligt vid användning',
  EUH210 = 'EUH210 Säkerhetsdatablad finns att rekvirera',
  EUH401 = 'EUH401 För att undvika risker för människors hälsa och för miljön, följ bruksanvisningen',
}

export enum SecurityNoticesEnum {
  P101 = 'P101 Ha förpackningen eller etiketten till hands om du måste söka läkarvård',
  P102 = 'P102 Förvaras oåtkomligt för barn',
  P103 = 'P103 Läs etiketten före användning',
  P201 = 'P201 Inhämta särskilda instruktioner före användning',
  P202 = 'P202 Använd inte produkten innan du har läst och förstått säkerhetsanvisningarna',
  P210 = 'P210 Får inte utsättas för värme, heta ytor, gnistor, öppna lågor och andra antändningskällor. Rökning förbjuden',
  P211 = 'P211 Spreja inte över öppen låga eller andra antändningskällor',
  P212 = 'P212 Undvik uppvärmning i sluten behållare eller reducering av det okänsliggörande ämnet',
  P220 = 'P220 Hålls åtskilt från kläder och andra brännbara material',
  P222 = 'P222 Undvik kontakt med luft',
  P223 = 'P223 Undvik all kontakt med vatten',
  P230 = 'P230 Ska hållas fuktigt med…',
  P231 = 'P231 Hantera och förvara innehållet under inert gas/…',
  P232 = 'P232 Skyddas från fukt',
  P233 = 'P233 Behållaren ska vara väl tillsluten',
  P234 = 'P234 Förvaras endast i originalförpackningen',
  P235 = 'P235 Förvaras svalt',
  P240 = 'P240 Jorda och potentialförbind behållare och mottagarutrustning',
  P241 = 'P241 Använd explosionssäker [elektrisk/ventilations-/belysnings-/...]utrustning',
  P242 = 'P242 Använd verktyg som inte ger upphov till gnistor',
  P243 = 'P243 Vidta åtgärder mot statisk elektricitet',
  P244 = 'P244 Håll ventiler och anslutningar fria från olja och fett',
  P250 = 'P250 Får inte utsättas för malning/stötar/friktion/… ',
  P251 = 'P251 Får inte punkteras eller brännas, gäller även tömd behållare',
  P260 = 'P260 Inandas inte damm/rök/gaser/dimma/ångor/sprej',
  P261 = 'P261 Undvik inandas damm/rök/gaser/dimma/ångor/sprej',
  P262 = 'P262 Får inte komma i kontakt med ögonen, huden eller kläderna',
  P263 = 'P263 Undvik kontakt under graviditet och amning',
  P264 = 'P264 Tvätta … grundligt efter användning',
  P270 = 'P270 Ät inte, drick inte och rök inte när du använder produkten',
  P271 = 'P271 Används endast utomhus eller i väl ventilerade utrymmen',
  P272 = 'P272 Nedstänkta arbetskläder får inte avlägsnas från arbetsplatsen',
  P273 = 'P273 Undvik utsläpp till miljön',
  P280 = 'P280 Använd skyddshandskar/skyddskläder/ögonskydd/ansiktsskydd',
  P282 = 'P282 Använd köldisolerande handskar och antingen visir eller ögonskydd',
  P283 = 'P283 Använd brandsäkra eller flamhämmande kläder',
  P284 = 'P284 * [Vid otillräcklig ventilation], använd andningsskydd',
  P231_P232 = 'P231 + P232 * Hantera och förvara innehållet under inert gas/…. Skyddas från fukt',
  P312 = 'P312 Vid obehag, kontakta GIFTINFORMATIONSCENTRALEN/läkare/…',
  P314 = 'P314 Sök läkarhjälp vid obehag',
  P315 = 'P315 Sök omedelbart läkarhjälp',
  P377 = 'P377 Läckande gas som brinner: Försök inte släcka branden om inte läckan kan stoppas på ett säkert sätt',
  P381 = 'P381 Vid läckage, avlägsna alla antändningskällor',
  P390 = 'P390 Sug upp spill för att undvika materiella skador',
  P391 = 'P391 Samla upp spill',
  P301_P310 = 'P301 + P310 * VID FÖRTÄRING: Kontakta genast GIFTINFORMATIONSCENTRALEN/läkare/…',
  P301_P312 = 'P301 + P312 * VID FÖRTÄRING: Vid obehag, kontakta GIFTINFORMATIONSCENTRALEN/läkare…',
  P302_P334 = 'P302 + P334 VID HUDKONTAKT: Skölj under kallt vatten eller använd våta omslag',
  P302_P352 = 'P302 + P352 * VID HUDKONTAKT: Tvätta med mycket vatten/…',
  P304_P340 = 'P304 + P340 VID INANDNING: Flytta personen till frisk luft och se till att andningen underlättas',
  P306_P360 = 'P306 + P360 VID KONTAKT MED KLÄDERNA: Skölj genast nedstänkta kläder och hud med mycket vatten innan du tar av dig kläderna',
  P308_P311 = 'P308 + P311 * VID exponering eller misstanke om exponering: Kontakta GIFTINFORMATIONSCENTRALEN/läkare/…',
  P308_P313 = 'P308 + P313 Vid exponering eller misstanke om exponering: Sök läkarhjälp',
  P332_P313 = 'P332 + P313 Vid hudirritation: Sök läkarhjälp',
  P333_P313 = 'P333 + P313 Vid hudirritation eller utslag: Sök läkarhjälp',
  P336_P315 = 'P336 + P315 Värm det köldskadade området med ljummet vatten. Gnid inte det skadade området. Sök omedelbart läkarhjälp',
  P337_P313 = 'P337 + P313 Vid bestående ögonirritation: Sök läkarhjälp',
  P342_P311 = 'P342 + P311 Vid besvär i luftvägarna: Kontakta GIFTINFORMATIONSCENTRALEN/läkare/…',
  P361_P364 = 'P361 + P364 Ta omedelbart av alla nedstänkta kläder och tvätta dem innan de används igen',
  P362_P364 = 'P362 + P364 Ta av nedstänkta kläder och tvätta dem innan de används igen',
  P370_P376 = 'P370 + P376 Vid brand: Stoppa läckan om det kan göras på ett säkert sätt',
  P370_P378 = 'P370 + P378 * Vid brand: Släck med …',
  P301_P330_P331 = 'P301 + P330 + P331 VID FÖRTÄRING: Skölj munnen. Framkalla INTE kräkning',
  P302_P335_P334 = 'P302 + P335 + P334 VID HUDKONTAKT: Borsta bort lösa partiklar från huden. Skölj under kallt vatten [eller använd våta omslag]',
  P303_P361_P353 = 'P303 + P361 + P353 VID HUDKONTAKT (även håret): Ta omedelbart av alla nedstänkta kläder. Skölj huden med vatten [eller duscha]',
  P305_P351_P338 = 'P305 + P351 + P338 VID KONTAKT MED ÖGONEN: Skölj försiktigt med vatten i flera minuter. Ta ur eventuella kontaktlinser om det går lätt. Fortsätt att skölja',
  P370_P380_P375 = 'P370 + P380 + P375 Vid brand: Utrym området. Bekämpa branden på avstånd på grund av explosionsrisken',
  P371_P380_P375 = 'P371 + P380 + P375 Vid större brand och stora mängder: Utrym området. Bekämpa branden på avstånd på grund av explosionsrisken',
  P370_P372_P380_P373 = 'P370 + P372 + P380 + P373 Vid brand: Explosionsrisk. Utrym området. Försök INTE bekämpa branden när den når explosiva varor',
  P370_P380_P375_P378 = 'P370 + P380 + P375 + P378 Vid brand: Utrym området. Bekämpa branden på avstånd på grund av explosionsrisken. [Släck med …]',
  P402 = 'P402 Förvaras torrt',
  P403 = 'P403 Förvaras på väl ventilerad plats',
  P404 = 'P404 Förvaras i sluten behållare',
  P405 = 'P405 Förvaras inlåst',
  P406 = 'P406 * Förvaras i korrosionsbeständig/… behållare med beständigt innerhölje',
  P407 = 'P407 Se till att det finns luft mellan staplar eller pallar',
  P410 = 'P410 Skyddas från solljus',
  P411 = 'P411 * Förvaras vid högst …°C/…°F',
  P412 = 'P412 Får inte utsättas för temperaturer över 50 °C/122 °F',
  P413 = 'P413 * Bulkprodukter som väger mer än … kg/… lbs förvaras vid högst …°C/…°F',
  P420 = 'P420 Förvaras separat',
  P402_P404 = 'P402 + P404 Förvaras torrt. Förvaras i sluten behållare',
  P403_P233 = 'P403 + P233 Förvaras på väl ventilerad plats. Förpackningen ska förvaras väl tillsluten',
  P403_P235 = 'P403 + P235 Förvaras på väl ventilerad plats. Förvaras svalt',
  P410_P403 = 'P410 + P403 Skyddas från solljus. Förvaras på väl ventilerad plats',
  P410_P412 = 'P410 + P412 Skyddas från solljus. Får inte utsättas för temperaturer över 50 °C/122 °F',
  P501 = 'P501 * Innehållet/behållaren lämnas till…',
  P502 = 'P502 Rådfråga tillverkare eller leverantör om återvinning eller återanvändning'
}

export enum AnimalCategoriesForFeedEnum {
  AMPHIBIANS = 'Groddjur',
  ANOLIS = 'Anolis',
  ARACHNIDS = 'Spindeldjur',
  POGONA = 'Pogona',
  BIRDS = 'Fågel',
  MOLLUSCS = 'Nötdjur',
  CAT = 'Katt',
  WHALE = 'Val',
  CHAMELEON = 'Kameleont',
  CHINCHILLA = 'Chinchilla',
  CRUSTACEAN = 'Kräftdjur',
  DOG = 'Hund',
  FISH = 'Fisk',
  GECKO = 'Gecko',
  GERBIL = 'Gerbil',
  GOAT = 'Get',
  GUINEA_PIG = 'Marsvin',
  HAMSTER = 'Hamster',
  HORSE = 'Häst',
  FERRET = 'Iller',
  HEDGEHOG = 'Igelkott',
  HUMAN = 'Människa',
  IGUANA = 'Leguan',
  INSECT = 'Insekt',
  MOUSE = 'Mus',
  PIG = 'Gris',
  PIGEON = 'Duva',
  PRIMATE = 'Primat',
  RABBIT = 'Kanin',
  RAT = 'Råtta',
  REPTILE = 'Reptil',
  RODENT = 'Gnagare',
  SHEEP = 'Får',
  SNAIL = 'Snigel',
  TURTLE = 'Sköldpadda',
  UNCLASSIFIED = 'Oklassificerad',
  UNIDENTIFIED = 'Oidentifierad'
}

// TODO
interface IPicture {
    url?: string;
}

interface IPictures {
    small?: IPicture;
}

interface IError {
    name?: string;
    state?: boolean;
}

export interface IRecognizeImage {
  url?: string;
  name?: string;
}

export interface IProduct extends IModel {
    id?: string;

    name?: string;

    isDraft?: boolean;
    wasApproved?: boolean;
    EAN?: string;
    EAN2?: string;

    /**
     * Non-food brand
     */
    brand?: string;

    /**
     * Food brand
     */
    brand_food?: string;

    color?: string;

    foodlaCategory?: IFoodlaCategory;

    search_terms?: string;
    ItemCategoriesOnline?: string;

    title?: string;

    adminStatus?: string;

    // TODO not db
    showTempError?: boolean;
    showDimensionsError?: boolean;

    material?: string;


    classification?: string;

    alcoholPercentage?: number;

    trade_item_temperature_information?: string;

    min_temperature?: number;

    max_temperature?: number;

    labels?: ILabel[];

    nutritional_unit?: NutritionalUnit;

    consumer_size_to_order_size_ratio?: number;

    pictures?: IPictures;

    nutrition?: string; // TODO check type

    energi_kj?: number;
    energi_kcal?: number;
    fett?: number;
    mattatFett?: number;
    enkelomattatFett?: number;
    flerromattatFett?: number;
    kolhydrat?: number;
    sockerarter?: number;
    polyoler?: number;
    starkelse?: number;
    fiber?: number;
    protein?: number;
    salt?: number;

    vitamins_minerals?: string;

    vitaminA?: number;
    vitaminD?: number;
    vitaminE?: number;
    vitaminK?: number;
    vitaminC?: number;
    tiamin?: number;
    riboflavin?: number;
    niacin?: number;
    vitaminB6?: number;
    folsyra?: number;
    vitaminB12?: number;
    biotin?: number;
    pantotensyra?: number;
    kalium?: number;
    klorid?: number;
    kalcium?: number;
    fosfor?: number;
    magnesium?: number;
    jarn?: number;
    zink?: number;
    koppar?: number;
    mangan?: number;
    fluorid?: number;
    selen?: number;
    krom?: number;
    molybden?: number;
    jod?: number;

    package_size?: number;
    package_size_unit?: string; // TODO enum

    location?: string;
    latitude?: number;
    longitude?: number;

    gross_weight?: string;
    gross_weight_num?: number;

    gross_height?: number;
    gross_width?: number;
    gross_depth?: number;

    dimensions?: string;

    non_food_ingredients?: string;

    descriptive_size_amount_extra?: number[];

    // not database
    producer?: IProducer;
    timestamp_color?: string; // TODO type
    timestamp?: number;
    timestamp_type?: string;
    newCategory?: IFoodlaCategory;

    image_src?: string;
    created_at?: string;
    updated_at?: number;

    producerUsername?: string;
    producerName?: string;
    producerPhone?: string;
    producerEmail?: string;
    producerIsStore?: boolean;
    producerId?: string;
    producerStoreId?: string;
    producerParentOrg?: string;
    producerOrg?: string;

    manufacturerGln?: string;

    short_text?: string;
    ingredient_statement?: string;
    article?: string;
    additional_required_information?: string;
    quantity_in_stock?: number;
    place_of_item_activity?: string;
    // country_of_origin?: string;
    capacity?: string;
    imageUnderProcess?: boolean;
    ignoreEANLengthError?: boolean;

    country_of_manufacturing?: number;

    descriptive_size_amount?: number;
    descriptive_size_unit?: string; // TODO enum

    errors?: IError[];

    apiExportAt?: string;
    apoteaExportAt?: string;

    versions?: any[]; // TODO

    // Export stuff
    createdAt?: string;
    updatedAt?: string;

    additionalImages?: string[];

    isMagazine?: boolean;
    country_of_manufacturing_string?: string;
    price?: number;
    priceEAN?: string|null;
    issueActiveDate?: number;
    lastreturndate?: number;
    totalNumberIssues?: number;
    currentIssueNumber?: number;
    currentIssueYear?: number;
    alternativeTitle?: string;

    // Details
    // Descriptive size of consumer pack TODO
    // detailsDescriptiveSizeOfConsumerPackAmount?: number;
    // detailsDescriptiveSizeOfConsumerPackUnit?: string; // TODO enum
    // detailsEffectiveDate?: number|null;

    // Basic Data
    // basicDataCategory?: string;
    basicDataMixCategory?: boolean; // TODO
    basicDataTradeItemVariableUnit?: boolean;

    // TODO Enter tax classification (moms) for article:
    // 0 = Excempt (0%)
    // 1 = Full (25%)
    // 2 = Reduced (12%)
    // 3 = Low (6%)
    basicDataTaxClassification?: number|null;

    // Enter price comparison type code (prisjämförelsekod). E.g. if washing deterient enter amount of washes you can get from one package.
    // Usually value would be 1 for articles handled in pieces (st)
    basicDataPriceComparisonContentType?: string|null;
    
    basicDataAvailabilityDateFrom?: number|null; // TODO check
    // basicDataAvailabilityDateTo?: number|null; // TODO check
    articleFirstOrderDate?: number | null;
    articleFirstDelivery?: number | null;

    basicDataCbuIndicator?: boolean;
    
    // Konsumentförpackning / Consumer pack							
    // consumerPackQtyOfBaseItem?: number;
    consumerPackOrderableUnit?: boolean;
    consumerPackDespatchUnit?: boolean;
    consumerPackNetWeight?: number;

    // storePackUOMType?: string;
    storePackEANForStorePack?: string;
    storePackOrderableUnit?: boolean;
    storePackDespatchUnit?: boolean;
    storePackTradeItemMeasurementsLengthDepth?: number;
    storePackTradeItemMeasurementsWidth?: number;
    storePackTradeItemMeasurementsHeight?: number;
    storePackGrossWeight?: number;


    // Transportförpackning / Transport Pack
    // transportUOMType?: string;
    transportEANForStorePack?: string;
    transportQtyOfBaseItem?: number;
    transportQtyOfNextLowerItem?: number;
    transportOrderableUnit?: boolean;
    transportDespatchUnit?: boolean;
    transportTradeItemMeasurementsLengthDepth?: number;
    transportTradeItemMeasurementsWidth?: number;
    transportTradeItemMeasurementsHeight?: number;
    transportGrossWeight?: number;
    transportMinTemperature?: number;
    transportMaxTemperature?: number;

    // Pall/Pallet layer									
    // palletLayerUOMType?: string;
    palletLayerEANForStorePack?: string;
    palletLayerQtyOfBaseItem?: number;
    palletLayerQtyOfNextLowerItem?: number;
    palletLayerOrderableUnit?: boolean;
    palletLayerDespatchUnit?: boolean;
    palletLayerTradeItemMeasurementsLengthDepth?: number;
    palletLayerTradeItemMeasurementsWidth?: number;
    palletLayerTradeItemMeasurementsHeight?: number;
    palletLayerGrossWeight?: number;

    // Pall/Pallet 									
    // palletUOMType?: string;
    palletQtyOfStorePack?: number;
    palletEANForStorePack?: string;
    palletQtyOfBaseItem?: number;
    palletQtyOfNextLowerItem?: number;
    palletOrderableUnit?: boolean;
    palletDespatchUnit?: boolean;
    palletTradeItemMeasurementsLengthDepth?: number;
    palletTradeItemMeasurementsWidth?: number;
    palletTradeItemMeasurementsHeight?: number;
    palletGrossWeight?: number;

    // Price		
    priceListPriceCurrency?: string;
    priceListPrice?: number;
    priceRecommendedConsumerPrice?: number;

    // Purchasing Data
    purchasingDataSupplierArticleNo?: string;
    // purchasingDataSupplierSubrange?: string;
    purchasingDataIncoterm?: string; // TODO enum
    purchasingDataPickupLocation?: string; // TODO enum

    // Quality review
    qrLabelingSwedish?: boolean;
    qrMedicalClaims?: boolean;
    qrMarketingClaimsCosmetic?: boolean;
    qrEfsaUlValues?: boolean;
    qrNutritionClaims?: boolean;
    qrHealthClaims?: boolean;
    qrFoodLabeling?: boolean;
    qrMisleadingSalesTerms?: boolean;
    qrFoodHygiene?: boolean;
    qrClassificationLabelingPackaging?: boolean;
    qrElectronic?: boolean;
    qrAdditivesInFeed?: boolean;
    qrFeedLabeling?: boolean;
    qrRelevantFeedCertifications?: boolean;

    // New string fields
    shortSalesDescription?: string;
    animalFoodIngredients?: string;
    eggPackerApprovalNumber?: string;
    dosageAndUsageInstructions?: string;
    preparationInstructions?: string;
    responsibleLabelingApprovalNumber?: string;
    feedAdditives?: string;
    analyticalConstituentsFeed?: string;
    feedingInstructions?: string;
    animalAgeGroup?: string;
    CMRSubstances?: string;
    informationHazardousSubstances?: string;
    specifyCEStandards?: string;
    explanationNoUDI?: string;
    regulatedSubstance?: string;

    // New boolean fields
    cmrSubstance?: boolean;
    reachSubstance?: boolean;
    bestBeforeDate?: boolean;
    childrenUnder16?: boolean;
    complianceEg12232009?: boolean;
    complianceReach19072006?: boolean;
    reachSubstancesAnnex19072006?: boolean;
    hazardousSubstancesOver1?: boolean;
    complianceProductSafety2004451?: boolean;
    ceMarking?: boolean;
    complianceMedicalTechProducts?: boolean;
    complianceHaccp?: boolean;
    plasticTrayPackaging?: boolean;
    checkUniqueIdentification?: boolean;

    fishProductionMethod?: FishProductionMethodEnum;
    fishCatchMethod?: FishCatchMethodEnum;
    catchArea?: CatchAreaEnum;
    eggWeightClass?: EggWeightClassEnum;
    recyclingType?: RecyclingTypeEnum;
    recycling_amount?: number;
    signalWords?: SignalWordsEnum;
    preparedForm?: PreparedFormEnum;
    categoryWeightloss?: CategoryWeightlossEnum;
    complianceBiocid?: ComplianceBiocidEnum;
    complianceBiocidalTreated?: ComplianceBiocidalTreatedEnum;
    confirmGuidanceSwedishElectronics?: ConfirmGuidanceSwedishElectronicsEnum;
    complianceToyDirective?: ComplianceToyDirectiveEnum;
    complianceMetalsSkinContact?: ComplianceMetalsSkinContactEnum;
    approvalDietFeed?: ApprovalDietFeedEnum;
    complianceSunscreenRegulation?: ComplianceSunscreenRegulationEnum;
    sunProtectionFactor?: SunProtectionFactorEnum;
    additionalCharacteristics?: AdditionalCharacteristicsEnum[];
    hazardSymbols?: HazardSymbolsEnum[];
    hazardStatements?: HazardStatementsEnum[];
    securityNotices?: SecurityNoticesEnum[];
    animalCategoriesForFeed?: AnimalCategoriesForFeedEnum[];

    // new fields
    baseCategory?: string;
    productDescription?: string;
    nonFoodIngredients?: string;
    productMarkings?: string;
    netQuantityAmount?: number;
    netQuantityUnit?: string;
    minTemperature?: string;
    maxTemperature?: number;
    tradeItemTemperatureInformation?: string;
    countryOfOrigin?: string;
    searchTerms?: string;
    placeOfOrigin?: string;

    bulkImportFile?: string;

    recognizeImages?: IRecognizeImage[];
}

export default IProduct;
