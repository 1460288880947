import React, { FC } from 'react';

const MappingArrowIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="38" height="16" viewBox="0 0 38 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7071 0.292893C30.3166 -0.0976311 29.6834 -0.0976311 29.2929 0.292893C28.9024 0.683418 28.9024 1.31658 29.2929 1.70711L34.5858 7H8C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9H34.5858L29.2929 14.2929C28.9024 14.6834 28.9024 15.3166 29.2929 15.7071C29.6834 16.0976 30.3166 16.0976 30.7071 15.7071L37.7071 8.70711C38.0976 8.31658 38.0976 7.68342 37.7071 7.29289L30.7071 0.292893ZM2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default MappingArrowIcon;
