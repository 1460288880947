import { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';

import InputTextField from "components/inputfields/InputTextField";
import IProduct from "models/product";
import FloatInput from "../FloatInput";
import { isDefined } from "utils/helpers-ts";
import ListDropDown from "components/inputfields/ListDropDown";
import InputLabel from "components/inputfields/InputLabel";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

interface IPurchasingDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const isEmpty = (product?: IProduct) => {
  if (!product) return true;
  const isNotStore = !product?.producerIsStore;
  if (
    (isNotStore && product.purchasingDataSupplierArticleNo && product.purchasingDataSupplierArticleNo.trim() !== '')
    // || (product.purchasingDataSupplierSubrange && product.purchasingDataSupplierSubrange.trim() !== '')
    || (product.purchasingDataIncoterm && product.purchasingDataIncoterm.trim() !== '')
    || (product.purchasingDataPickupLocation && product.purchasingDataPickupLocation.trim() !== '')
  ) {
    return false;
  }
  return true;
};

const INCOTERM = [
  { value: 'DDP', displayName: 'DDP (Delivered Duty Paid)' },
  { value: 'FCA', displayName: 'FCA (Free Carrier)' },
];

const PICKUP_LOCATION = [
  { value: 'D100', displayName: 'D100' },
  { value: 'D200', displayName: 'D200' },
  { value: 'D300', displayName: 'D300' },
  { value: 'D210', displayName: 'D210' },
  { value: 'D400', displayName: 'D400' },
  { value: 'D500', displayName: 'D500' },
];

const PurchasingData = ({ state, setState }: IPurchasingDataProps) => {
  const isNotStore = !state?.producerIsStore;
  const classes = useStyles();
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    setExpanded(!isEmpty(state));
  }, []);
  const handleAccordionChange = (event: any, isExpanded?: boolean) => {
    setExpanded(!!isExpanded);
  };
  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
      const val = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: val });
  };  
  return (
    // <Accordion expanded={expanded} onChange={handleAccordionChange}>
    //   <AccordionSummary expandIcon={<ExpandMore />}>
    //     <Typography className={classes.heading}>Purchasing Data	</Typography>
    //   </AccordionSummary>
    //   <AccordionDetails>
        <div style={{width: '100%'}}>
          {isNotStore && (<>
            <InputLabel
              heading="Leverantörens artikelnummer"
            />
            <InputTextField
              state={state}
              setState={setState}
              inputName="purchasingDataSupplierArticleNo"
              value={state.purchasingDataSupplierArticleNo}
              label="Leverantörens artikelnummer"
              inputClassName="data-hj-allow"
              onChange={handleChange}
            />
          </>)}
          {/* <InputLabel
            heading="Incoterm"
            text="What Incoterm that applies according to logistic agreement"
          />
          <ListDropDown
            disableLabel={true}
            label="Incoterm"
            listData={INCOTERM}
            value={state?.purchasingDataIncoterm}
            targetName="purchasingDataIncoterm"
            handleChange={handleDropdownChange}
            displayEmpty={true}
          />
          <br />
          <InputLabel
            heading="Pickup location if FCA or port if FOB, according to the logistics agreement"
            text="Physical location where the supplier delivers to according to logistic agreement. For example Bro, Store or what location is valid for collection of goods (FCA)"
          />
          <ListDropDown
            // label="Pickup location if FCA or port if FOB, according to the logistics agreement"
            disableLabel={true}
            listData={PICKUP_LOCATION}
            value={state?.purchasingDataPickupLocation}
            targetName="purchasingDataPickupLocation"
            handleChange={handleDropdownChange}
            displayEmpty={true}
          /> */}
        </div>
    //   </AccordionDetails>
    // </Accordion>
  );
};

export default PurchasingData;
