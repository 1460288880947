import React from 'react';

import InputTextField from '../../../inputfields/InputTextField';
import { isFoodCategory } from '../utils';
import { APPLICATION_ROLES } from '../../../constants';
import IProduct from '../../../../models/product';

interface IAlternativeTitleProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

export const AlternativeTitle = ({ state, setState }: IAlternativeTitleProps) => {
  const labelOptionsNonFood = {
    heading: 'Alternative title',
    // text: 'Namn som beskriver vad det är för slags produkt.'
  }

  // TODO any
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });

  return (
    <>
      <InputTextField
        inputName="alternativeTitle"
        value={state.alternativeTitle}
        label={"Alternative title"}
        onChange={handleChange}
        // required
        withLabel
        disabled={!state?.isMagazine}
        setState={setState}
        // approve={isSuperuser && approve}
        state={state}
        labelOptions={labelOptionsNonFood}
        // ignoreTabs={true}
      />
   </>
  );
}

export default AlternativeTitle;
