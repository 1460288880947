import { FC } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import {
  Box,
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core';

const StyledLinearProgress = styled(MuiLinearProgress)(() => ({
  flexGrow: 1,
  height: 4,
  borderRadius: 12,
  background: '#EDEEF3',
  '& .MuiLinearProgress-bar': {
    borderRadius: 12,
    background: '#56ABA9',
  },
}));

const LinearProgress: FC<MuiLinearProgressProps> = ({ value, ...linearProgressProps }) => {
  return (
    <Box display="flex" width="100%" gridGap={8} alignItems="center">
      <StyledLinearProgress {...linearProgressProps} value={value} />
      {!_.isNil(value) && <Box sx={{ fontSize: 12, color: '#383D52' }}>{value}%</Box>}
    </Box>
  );
};

export default LinearProgress;
