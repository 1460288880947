import React from 'react';
import { compose } from 'redux'
import { DocumentNode, VariableMap, Resolver } from 'apollo-boost';

import NoDataFound from '../components/NoData';
import withDataResolver from './withDataResolver';
import withApolloQuery from './withApolloQuery';
import withError from './withError';
import withLoading from './withLoading';
import withNoData from './withNoData';
import Error from '../components/Error';

type TArgs = {
    query: DocumentNode;
    variables: VariableMap;
    validator: any;
    resolver: Resolver;
    fetchPolicy?: string;
};

const withContainer = ({ query, variables, validator, resolver, fetchPolicy }: TArgs) => (WrappedComponent: any) => {
    const Container = (props: any) => <WrappedComponent {...props} />
    const container = compose(
        withApolloQuery(query, variables, fetchPolicy),
        withLoading,
        withError(Error),
        withNoData(validator, NoDataFound),
        withDataResolver(resolver),
    )(Container);
    return container;
}

export default withContainer;
