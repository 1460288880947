import { makeStyles } from '@material-ui/core';

export const CELL_SPACING = 16;

export const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    borderCollapse: 'separate',
  },
  headCell: {
    position: 'relative',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    left: 'auto',
    backgroundColor: '#ffffff',
  },
  bodyCell: {
    whiteSpace: 'nowrap',

    // cloned from MuiTableCell-root
    display: 'table-cell',
    padding: CELL_SPACING,
    fontSize: '0.875rem',
    textAlign: 'left',
    lineHeight: '1.43',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
    backgroundColor: '#ffffff',
  },
  bodyCellBox: {
    borderRadius: 3,
    minHeight: 20,
  },
  bodyCellImport: {
    cursor: 'pointer',
  },
}));
