import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, Theme } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import productTotal from '../data/ProductTotal';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
    },
    content: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        fontWeight: 700,
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56,
    },
    icon: {
        height: 32,
        width: 32,
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    differenceIcon: {
        color: theme.palette.success.dark,
    },
    differenceValue: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1),
    },
}));

interface ITotalProductsProps {
  className?: string;
  isFood?: boolean;
  showAll?: boolean;
  showAllNoFilter?: boolean;
}

const TotalProducts = (props: ITotalProductsProps) => {
  const { className, isFood, showAll, showAllNoFilter, ...rest } = props;

  const classes = useStyles();

  const ProductTotal = productTotal(isFood, showAll, showAllNoFilter);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL PRODUCTS
            </Typography>
            <ProductTotal />
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AddShoppingCartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalProducts;
