import { createContext } from 'react';

import { IImageFileSource, IImportColumn, IProductExcel } from 'utils/excelUtils-old';
import IndexedDB from 'utils/indexedDB';
import { TExcelDataRow } from 'models/excel';

import { DEFAULT_LIMIT } from '../bulkImportUtils';
import { FOOD_CATEGORY_ID } from 'components/constants-ts';
import IProducer from 'models/producer';
import IProduct from 'models/product';

export type TUploadProgress = {
  total: number;
  uploadedTotal: number;
  isUploading: boolean;
  uploaded: string[];
  uploading: string[];
};

export type TUploadedProductStatus = { dataId: string; productId?: string; error?: string };

export type TImageSettings = {
  aiMain: boolean;
  aiIgnore: boolean;
  parseImageBy: string;
};

export type TBulkImportContextValue = {
  loadingLocal: boolean;
  setLoadingLocal: React.Dispatch<React.SetStateAction<boolean>>;
  indexedDB: IndexedDB | null;
  // excel
  excelFile: File | null;
  setExcelFile: (file: File | null) => void;
  excelFileData: TExcelDataRow[];
  setExcelFileData: (list: TExcelDataRow[]) => void;
  dataList: IProductExcel[];
  setDataList: React.Dispatch<React.SetStateAction<IProductExcel[]>>;
  onChangeDataValue: (id: string, field: keyof IProduct, value: string) => void;
  uploadedProductStatusSet: Record<string, TUploadedProductStatus>;
  onUploadedProductStatusSetChange: (set: Record<string, TUploadedProductStatus>) => void;
  excelColumnList: IImportColumn[];
  limit: number;
  onLimitChange: (limit: number) => void;
  productType: string;
  onProductTypeChange: (productType: string) => void;
  producerList: IProducer[];
  setProducerList: React.Dispatch<React.SetStateAction<IProducer[]>>;
  producerData: IProducer;
  onProducerIdChange: (producerId: string) => void;
  onReset: () => void;
  // product images
  isConvertingImages: boolean;
  setConvertingImages: React.Dispatch<React.SetStateAction<boolean>>;
  regexValue: string;
  setRegexValue: React.Dispatch<React.SetStateAction<string>>;
  editingImageProductId: string;
  setEditingImageProductId: React.Dispatch<React.SetStateAction<string>>;
  uploadProgress: TUploadProgress;
  setUploadProgress: React.Dispatch<React.SetStateAction<TUploadProgress>>;
  imageSettings: TImageSettings;
  onImageSettingChange: (setting: Partial<TImageSettings>) => void;
  matchedImageFileDataList: IImageFileSource[];
  onRegexValueChange: (newRegexValue: string) => void;
  allImageFileDataList: IImageFileSource[];
  onAllImageFileDataChange: (callback: (oldImageDataList: IImageFileSource[]) => IImageFileSource[]) => Promise<void>;
  productImageDataSet: Record<string, IImageFileSource[]>;
  setProductImageDataSet: React.Dispatch<React.SetStateAction<Record<string, IImageFileSource[]>>>;
  onProductImageChange: (fileList: File[]) => void;
  tempApproveEanStatus: Record<string, string>;
  setTempApproveEanStatus: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

export const contextDefaultValue: TBulkImportContextValue = {
  loadingLocal: true,
  setLoadingLocal: () => {},
  indexedDB: null,

  excelFile: null,
  setExcelFile: () => {},
  excelFileData: [],
  setExcelFileData: () => {},
  dataList: [],
  setDataList: () => {},
  onChangeDataValue: () => {},
  uploadedProductStatusSet: {},
  onUploadedProductStatusSetChange: () => {},
  excelColumnList: [],
  limit: DEFAULT_LIMIT,
  onLimitChange: () => {},
  productType: FOOD_CATEGORY_ID,
  onProductTypeChange: () => {},
  producerList: [],
  setProducerList: () => {},
  producerData: {},
  onProducerIdChange: () => {},
  onReset: () => {},

  isConvertingImages: false,
  setConvertingImages: () => {},
  regexValue: '',
  setRegexValue: () => {},
  editingImageProductId: '',
  setEditingImageProductId: () => {},
  uploadProgress: { total: 0, uploadedTotal: 0, isUploading: false, uploaded: [], uploading: [] },
  setUploadProgress: () => {},
  imageSettings: { aiMain: true, aiIgnore: false, parseImageBy: 'EAN' },
  onImageSettingChange: () => {},
  matchedImageFileDataList: [],
  onRegexValueChange: () => {},
  allImageFileDataList: [],
  onAllImageFileDataChange: () => Promise.resolve(),
  productImageDataSet: {},
  setProductImageDataSet: () => {},
  onProductImageChange: () => {},
  tempApproveEanStatus: {},
  setTempApproveEanStatus: () => {},
};

export const BulkImportContext = createContext<TBulkImportContextValue>(contextDefaultValue);
