import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress  from '@material-ui/core/LinearProgress';
import Typography  from '@material-ui/core/Typography';

import VisionTab from '../tab';
// import VisionInfo from '../info';
import { fixFloat } from '../utils';

const Logos = ({visionTab, image, info, isNew }) => {
  const vert = info?.logoAnnotations?.map((item) => ({
    label: item?.description,
    // id: item?.mid,
    score: item?.score,
    coord: item?.boundingPoly?.vertices,
  }));
  return (
    <VisionTab
      index="logos"
      tabValue={visionTab}
      image={image}
      vertices={vert}
      showLabels={true}
      pictureWidth={8}
      info={info}
      isNew={isNew}
    >
      {(!info?.logoAnnotations || info?.logoAnnotations.length === 0) && (
        <Typography>No logos detected.</Typography>
      )}
      <List>
      {info?.logoAnnotations?.map((item) => (
        <ListItem>
          <div style={{width: '100%'}}>
            <Typography>{item?.description}<span style={{float: 'right'}}>{fixFloat(item?.score)}%</span></Typography>
            <LinearProgress variant="determinate" value={item?.score * 100} color="primary" />
          </div>
        </ListItem>
        // <div>
        //   <VisionInfo title="Description" field="description" item={item} />
        //   <VisionInfo title="Score" field="score" item={item} />
        //   {item?.locale && item?.locale?.trim() !== "" && (
        //     <p><b>Locale:</b> {item.locale}</p>
        //   )}
          // {/* <VisionInfo title="Confidence" field="confidence" item={item} />
          // <VisionInfo title="Topicality" field="topicality" item={item} /> */}
          // {/* {item?.boundingPoly?.vertices?.map((coord) => (
          //   <p> (<b>x</b>: {coord?.x}, <b>y</b>: {coord?.y})</p>
          // ))} */}
          // <br />
        // </div>
      ))}
    </List>
  </VisionTab>
  );
};

export default Logos;
