import Excel from 'exceljs';

export const loadExcelFile = (file: File): Promise<Excel.Workbook | null> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    const workbook = new Excel.Workbook();

    reader.onload = async () => {
      try {
        await workbook.xlsx.load(reader.result as Buffer);
        resolve(workbook);
      } catch {
        resolve(null);
      }
    };

    try {
      reader.readAsArrayBuffer(file);
    } catch {
      resolve(null);
    }
  });
};
