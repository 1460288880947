import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { KeycloakContext } from 'components/Secured';

const HomeRedirect = () => {
  const keycloakCtx = useContext(KeycloakContext);

  const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');

  if (isProducer) {
    return <Redirect to="/products" />;
  }

  if (isStore) {
    return <Redirect to="/me/products" />;
  }

  return <Redirect to="/dashboard" />;
};

export default HomeRedirect;
