import React, { useEffect, useMemo, useState } from 'react';

import InputLabel from 'components/inputfields/InputLabel';
import MultipleSelect from 'components/inputfields/MultipleSelect';

import IProduct from 'models/product';
import { DespatchUnitEnum } from '../../../../models/product';

interface IDespatchUnitProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  setChanged: (bol: boolean) => void;
}

const despatchUnit: { value: keyof IProduct; displayName: string }[] = [
  { value: 'consumerPackDespatchUnit', displayName: DespatchUnitEnum.CONSUMER_PACK },
  { value: 'storePackDespatchUnit', displayName: DespatchUnitEnum.STORE_PACK },
  { value: 'transportDespatchUnit', displayName: DespatchUnitEnum.TRANSPORT },
  { value: 'palletDespatchUnit', displayName: DespatchUnitEnum.PALLET },
];

const placeholder = `(${despatchUnit.map(({ displayName }) => displayName).join(', ')})`;

const DespatchUnitFields = ({ state, setState, setChanged: setChangedProp }: IDespatchUnitProps) => {
  const [isChanged, setChanged] = useState(
    !!state.consumerPackDespatchUnit ||
      !!state.storePackDespatchUnit ||
      !!state.transportDespatchUnit ||
      !!state.palletDespatchUnit
  );

  const selectedValues = useMemo(() => {
    return despatchUnit.filter(({ value }) => state[value]).map(({ value }) => value);
  }, [state]);

  const handleDropdownChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const fields = event.target.value as (keyof IProduct)[];
    const updatedFields: Record<string, any> = {};

    despatchUnit.forEach(item => {
      updatedFields[item.value] = fields.includes(item.value);
    });

    setState({ ...state, ...updatedFields });
    setChanged(true);
  };

  useEffect(() => {
    setChangedProp(isChanged);
  }, [isChanged, setChangedProp]);

  return (
    <>
      <InputLabel
        heading="Vilken/Vilka artikelnivå(er) används för transport?"
        text="Här anger du vilken eller vilka artikelnivåer av artikeln som kan skickas till dina kunder. Du måste ange minst en nivå som kan hanteras i logistiken."
      />
      <MultipleSelect
        disableLabel
        listData={despatchUnit}
        value={selectedValues}
        placeholder={placeholder}
        targetName=""
        handleChange={handleDropdownChange}
      />
    </>
  );
};

export default DespatchUnitFields;
