
// Polyfills for abort controller
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import './index.css';
import App from './App';

// ReactGA.initialize('GTM-5N59953');
ReactGA.initialize('G-HXDF37HR6N');

ReactDOM.render(<App />, document.getElementById('root'));

// force update 2
