import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { CloudUploadOutlined, CloudDownloadOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import { useBulkImportContext } from '../BulkImportContext';
import ListDropDown from 'components/inputfields/ListDropDown';
import { FOOD_CATEGORY_ID, NON_FOOD_CATEGORY_ID, SUPERUSER_APPROVAL } from 'components/constants-ts';

import { GET_PRODUCER_ALL } from 'graphql/queries';
import { ALLOWED_IMAGE_FILE_TYPE_LIST, filterAllowedImages, STORE_NAME } from '../bulkImportUtils';

const useStyles = makeStyles(theme => ({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  linearProgressBar: {
    transition: 'none',
  },
}));

type TRegexOption = {
  title: string;
  field: string;
  regex: RegExp;
  matches: number;
};

const RegexInput = () => {
  const { onRegexValueChange, allImageFileDataList, onImageSettingChange } = useBulkImportContext();

  const [value, setValue] = useState(localStorage.getItem(`${STORE_NAME}.regex`) || '');

  const regexOptions = useMemo<TRegexOption[]>(() => {
    const options = [
      { title: 'Parse EAN', field: 'EAN', regex: new RegExp(/^\s*(\d{13})\s*$/) },
      {
        title: 'Parse EAN, but if several images for one product',
        field: 'EAN',
        regex: new RegExp(/^\s*(\d{13}(_\d)?)\s*$/),
      },
      { title: 'Parse article', field: 'article', regex: new RegExp(/^\s*(\d{1,7}(?:[-_]\d{1,7})*)\s*/) },
    ];

    return options
      .map(option => ({
        ...option,
        matches: allImageFileDataList.filter(
          ({ name, assignProductId }) => assignProductId || option.regex.test(name || '')
        ).length,
      }))
      .sort((prev, next) => next.matches - prev.matches);
  }, [allImageFileDataList]);

  return (
    <Autocomplete
      fullWidth
      options={regexOptions}
      getOptionLabel={option => option.regex?.toString() || ''}
      filterOptions={options => options}
      renderOption={(option: TRegexOption) => (
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" style={{ fontSize: 'inherit' }}>
            ({option.regex.toString()})
          </Typography>
          <Box mx={1}>|</Box>
          <Box>{option.title}</Box>
          <Box mx={1}>|</Box>
          <Box>
            ({option.matches}/{allImageFileDataList.length})
          </Box>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, value }}
          label="Regex"
          variant="outlined"
          onChange={event => {
            const { value } = event.target;
            setValue(value);
            onRegexValueChange(value);
          }}
        />
      )}
      onChange={(_, newValue) => {
        // remove {/^} and {$/}
        let newRegexValue = newValue?.regex?.toString().slice(2, -2) || '';
        if (newValue?.field === 'article') {
          // remove {/^} and {/}
          newRegexValue = newValue?.regex?.toString().slice(2, -1) || '';
        }
        setValue(newRegexValue);
        onRegexValueChange(newRegexValue);
        if (newValue) {
          onImageSettingChange({ parseImageBy: newValue.field });
        }
      }}
    />
  );
};

interface ReviewHeaderProps {
  showErrorOnly: boolean;
  errorCount: number;
  onChangeShowErrorOnly: (show: boolean) => void;
  onDownload: () => void;
}

const ReviewHeader: FC<ReviewHeaderProps> = ({ showErrorOnly, errorCount, onChangeShowErrorOnly, onDownload }) => {
  const classes = useStyles();
  const { data: queryProducerData } = useQuery(GET_PRODUCER_ALL, { variables: { showAll: true } });
  const {
    productType,
    onProductTypeChange,
    producerData,
    producerList,
    setProducerList,
    onProducerIdChange,
    onReset,
    uploadProgress,
    imageSettings,
    onImageSettingChange,
    onProductImageChange,
  } = useBulkImportContext();

  const productTypeOptions = [
    { value: FOOD_CATEGORY_ID, displayName: 'Food' },
    { value: NON_FOOD_CATEGORY_ID, displayName: 'Non Food' },
  ];

  const producerOptions = useMemo(() => {
    const optionList = producerList.map(({ id, status, name, username }) => ({
      key: id,
      value: id,
      displayName: `${name} (${username})`.trim(),
      style: { color: status === SUPERUSER_APPROVAL.ADMIN_APPROVED ? 'inherit' : 'gray' },
    }));

    return optionList.sort((prev, next) => (prev.displayName > next.displayName ? 1 : -1));
  }, [producerList]);

  const handleUploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const acceptedImages = filterAllowedImages(Array.from(event.target.files || []));
    onProductImageChange(acceptedImages);
  };

  useEffect(() => {
    if (queryProducerData?.producers?.data?.length) {
      setProducerList(queryProducerData?.producers.data);
    }
  }, [queryProducerData, setProducerList]);

  return (
    <Box display="flex" flexDirection="column" gridGap={8}>
      <Box className={classes.actionsWrapper} gridGap={8}>
        <Box display="flex">
          <Box width={200}>
            <ListDropDown
              label="Product Type"
              value={productType}
              listData={productTypeOptions}
              handleChange={event => onProductTypeChange(event.target.value as string)}
            />
          </Box>
          <Box width={200} ml={2}>
            <ListDropDown
              label="Producer Name"
              value={producerData.id || ''}
              listData={producerOptions}
              showSearch
              handleChange={event => onProducerIdChange(event.target.value as string)}
            />
          </Box>
        </Box>

        <Box display="flex" gridGap={16}>
          <Button variant="contained" color="secondary" onClick={onReset}>
            Reset
          </Button>
          <Button variant="contained" color="primary" component="label">
            <input
              hidden
              type="file"
              multiple
              accept={ALLOWED_IMAGE_FILE_TYPE_LIST.join(', ')}
              onChange={handleUploadFiles}
            />
            <CloudUploadOutlined style={{ fontSize: 20, marginRight: 8 }} />
            <span>Add Images</span>
          </Button>
          <Button variant="contained" color="primary" onClick={onDownload}>
            <CloudDownloadOutlined style={{ fontSize: 20, marginRight: 8 }} />
            <span>Download</span>
          </Button>
        </Box>
      </Box>

      <Box flexGrow={1} flexBasis={0} display="flex" flexDirection="column" gridGap={8}>
        <RegexInput />
      </Box>

      <Box display="flex" flexDirection="column" gridGap={8}>
        <Box flexGrow={1} flexBasis={0} display="flex" gridColumnGap={64} flexWrap="wrap">
          <FormControl style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <FormLabel>Parse image filename by:</FormLabel>

            <RadioGroup
              value={imageSettings.parseImageBy}
              onChange={(_, value) => onImageSettingChange({ parseImageBy: value })}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel label="EAN" value="EAN" control={<Radio color="primary" />} />
              <FormControlLabel label="Article" value="article" control={<Radio color="primary" />} />
            </RadioGroup>
          </FormControl>

          <FormControl style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <FormLabel>Image AI:</FormLabel>

            <Box>
              <FormControlLabel
                label="Ignore AI"
                control={
                  <Checkbox
                    color="primary"
                    checked={imageSettings.aiIgnore}
                    onChange={event =>
                      onImageSettingChange({
                        aiIgnore: event.target.checked,
                        aiMain: event.target.checked ? false : imageSettings.aiMain,
                      })
                    }
                  />
                }
              />
              <FormControlLabel
                label="AI Main Image"
                disabled={imageSettings.aiIgnore}
                control={
                  <Checkbox
                    color="primary"
                    checked={imageSettings.aiMain}
                    onChange={event => onImageSettingChange({ aiMain: event.target.checked })}
                  />
                }
              />
            </Box>
          </FormControl>
        </Box>

        {!!uploadProgress.total && (
          <Box flexGrow={1} flexBasis={0} display="flex" flexDirection="column" gridGap={8}>
            <Box display="flex" justifyContent="space-between" gridGap={16}>
              <span>Loading Images</span>
              <span>{`${uploadProgress.uploadedTotal}/${uploadProgress.total}`}</span>
            </Box>
            <LinearProgress
              classes={{ bar: classes.linearProgressBar }}
              variant="determinate"
              value={Math.ceil((uploadProgress.uploadedTotal / uploadProgress.total) * 100)}
            />
          </Box>
        )}

        {!!errorCount && (
          <Box>
            <Box sx={{ color: 'red', fontWeight: 'bold', fontSize: 16 }}>
              {errorCount} {errorCount > 1 ? 'products' : 'product'} with error found
            </Box>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked
                  color="primary"
                  checked={showErrorOnly}
                  onChange={event => onChangeShowErrorOnly(event.target.checked)}
                />
              }
              label="Show just products with errors"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReviewHeader;
