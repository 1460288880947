import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import HelpCircle from "@material-ui/icons/Help";
import LiveHelp from '@material-ui/icons/LiveHelp';
import Message from '@material-ui/icons/MessageOutlined';

interface IHelpMenuProps {

}

const HelpMenu = (props: IHelpMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  
  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };
  return (
    <>
      <IconButton color="inherit" onClick={handleMenu}>
        <HelpCircle />
      </IconButton>
      <MenuList>
        <Menu
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          keepMounted={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {/* <MenuItem component="a" href="https://help.foodla.nu/knowledge" target="_blank" onClick={() => handleClose()}>
            <ListItemIcon>
              <MenuBook />
            </ListItemIcon>
            <Typography style={{ fontSize: 12, marginLeft: -20 }}>Vanliga frågor</Typography>
          </MenuItem> */}
          <MenuItem component="a" href="mailto:support@foodla.nu" target="_blank" onClick={() => handleClose()}>
            <ListItemIcon>
              <Message />
            </ListItemIcon>
            <Typography style={{ fontSize: 12, marginLeft: -20 }}>Kontakta oss</Typography>
          </MenuItem>
          <MenuItem component="a" href="https://foodla.nu/en/foodla-faq-startsida/" target="_blank" onClick={() => handleClose()}>
            <ListItemIcon>
              <LiveHelp />
            </ListItemIcon>
            <Typography style={{ fontSize: 12, marginLeft: -20 }}>FAQ</Typography>
          </MenuItem>
        </Menu>
      </MenuList>
    </>
  );
};

export default HelpMenu;
