import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState, useContext, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import ErrorBoundary from 'components/ErrorBoundary';
import { FOOD_CATEGORY_ID, NON_FOOD_CATEGORY_ID } from 'components/constants-ts';
import {
  IImageFileSource,
  IProductExcel,
  computeBaseItems,
  getDataType,
  parseExcelDataToProduct,
} from 'utils/excelUtils-old';
import IndexedDB from 'utils/indexedDB';
import { TExcelDataRow } from 'models/excel';
import IProduct from 'models/product';
import { KeycloakContext } from 'components/Secured';

import {
  parseImageFile,
  readExcelDataFromIndexedDB,
  readImageFileDataFromIndexedDB,
  writeImageFileDataFromIndexedDB,
  createIndededDB,
  DEFAULT_LIMIT,
  STORE_NAME,
  OBJECT_STORAGE_NAME,
  testRegexString,
  reorderImages,
} from '../bulkImportUtils';
import { isNonFoodProduct } from 'utils/products';

import {
  contextDefaultValue,
  BulkImportContext,
  TUploadProgress,
  TImageSettings,
  TBulkImportContextValue,
  TUploadedProductStatus,
} from './BulkImportContext';
import IProducer from 'models/producer';

interface BulkImportProviderBaseProps {
  indexedDB: IndexedDB | null;
  clearLocalStore: () => Promise<void>;
}

export const BulkImportProviderBase: FC<BulkImportProviderBaseProps> = ({ indexedDB, clearLocalStore, children }) => {
  const { keycloak } = useContext(KeycloakContext);

  const keepRef = useRef({ isLoadedImageFromIndexDB: false });

  const [loadingLocal, setLoadingLocal] = useState(!!localStorage.getItem(`${STORE_NAME}.product_type`));
  // excel
  const [dataList, setDataList] = useState<IProductExcel[]>([]);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [excelFileData, setExcelFileData] = useState<TExcelDataRow[]>([]);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [productType, setProductType] = useState('');
  const [producerList, setProducerList] = useState<IProducer[]>([]);
  const [producerData, setProducerData] = useState<IProducer>({});
  const [uploadedProductStatusSet, setUploadedProductStatusSet] = useState<Record<string, TUploadedProductStatus>>({});

  // product images
  const [isConvertingImages, setConvertingImages] = useState(false);
  const [regexValue, setRegexValue] = useState('');
  const [editingImageProductId, setEditingImageProductId] = useState<string>('');
  const [matchedImageFileDataList, setMatchedImageFileDataList] = useState<IImageFileSource[]>([]);
  const [allImageFileDataList, setAllImageFileDataList] = useState<IImageFileSource[]>([]);
  const [productImageDataSet, setProductImageDataSet] = useState<Record<string, IImageFileSource[]>>({});
  const [uploadProgress, setUploadProgress] = useState<TUploadProgress>(contextDefaultValue.uploadProgress);
  const [imageSettings, setImageSettings] = useState<TImageSettings>(contextDefaultValue.imageSettings);
  const [tempApproveEanStatus, setTempApproveEanStatus] = useState<Record<string, string>>({});

  const { columnList: excelColumnList, dataList: defaultDataList } = useMemo(
    () => parseExcelDataToProduct(excelFileData),
    [excelFileData]
  );

  const onChangeDataValue = useCallback((id: string, field: keyof IProduct, value: string) => {
    const dataType = getDataType(field);

    setDataList(oldDataList => {
      const newDataList = cloneDeep(oldDataList);
      const index = newDataList.findIndex(item => item.id === id);
      if (index > -1) {
        let newValue: string | Date = value;
        if (dataType === 'date') newValue = new Date(value);
        newDataList[index] = { ...newDataList[index], [field]: newValue };
        newDataList[index]._editingData = { ...newDataList[index]._editingData, [field]: newValue };
      }
      return computeBaseItems(newDataList);
    });
  }, []);

  const onProductTypeChange = useCallback((newProductType: string) => {
    setProductType(newProductType);
    localStorage.setItem(`${STORE_NAME}.product_type`, newProductType);
  }, []);

  const onProducerIdChange = useCallback(
    (newProducerId: string) => {
      localStorage.setItem(`${STORE_NAME}.producerId`, newProducerId);

      const newProducer = producerList.find(({ id }) => id === newProducerId);
      if (newProducer) setProducerData(newProducer);
    },
    [producerList]
  );

  const onUploadedProductStatusSetChange = useCallback((set: Record<string, TUploadedProductStatus>) => {
    setUploadedProductStatusSet(set);
    localStorage.setItem(`${STORE_NAME}.uploaded_product_status_set`, JSON.stringify(set));
  }, []);

  const onLimitChange = useCallback((newLimit: number) => {
    setLimit(newLimit);
    localStorage.setItem(`${STORE_NAME}.limit`, String(newLimit));
  }, []);

  const onImageSettingChange = useCallback((newImageSettings: Partial<TImageSettings>) => {
    setImageSettings(state => {
      const newState = { ...state, ...newImageSettings };
      localStorage.setItem(`${STORE_NAME}.parse_image_by`, String(newState.parseImageBy));
      localStorage.setItem(`${STORE_NAME}.is_ignore_ai`, String(newState.aiIgnore));
      localStorage.setItem(`${STORE_NAME}.is_ai_main_image`, String(newState.aiMain));
      return newState;
    });
  }, []);

  const updateMatchedImageFileData = useCallback(
    (params: { imageDataList?: IImageFileSource[]; regex?: string; aiIgnore?: boolean }) => {
      const imageDataList = params.imageDataList || allImageFileDataList;
      const aiIgnore = params.aiIgnore || imageSettings.aiIgnore;
      const regex = params.regex || regexValue;

      const newList = imageDataList.filter(({ name, assignProductId }) => {
        if (aiIgnore && name.endsWith('AIGENERATED')) return false;
        if (assignProductId) return true;
        if (!regex) return false;
        if (imageSettings.parseImageBy === 'article') {
          return testRegexString(`^${regex}`, name);
        } else {
          return testRegexString(`^${regex}$`, name);
        }
      });
      setMatchedImageFileDataList(newList);
    },
    [allImageFileDataList, regexValue, imageSettings.aiIgnore, imageSettings.parseImageBy]
  );

  const onRegexValueChange = useCallback(
    (newRegex: string) => {
      setRegexValue(newRegex);
      localStorage.setItem(`${STORE_NAME}.regex`, newRegex);
      updateMatchedImageFileData({ regex: newRegex });
    },
    [updateMatchedImageFileData]
  );

  const onAllImageFileDataChange = useCallback(
    (callback: (oldImageDataList: IImageFileSource[]) => IImageFileSource[]) => {
      return new Promise<void>(resolve => {
        setAllImageFileDataList(state => {
          const imageDataList = callback(state);
          updateMatchedImageFileData({ imageDataList });
          if (indexedDB) writeImageFileDataFromIndexedDB(indexedDB, imageDataList).then(() => resolve());
          return imageDataList;
        });
      });
    },
    [indexedDB, updateMatchedImageFileData]
  );

  const onProductImageChange = useCallback(
    async (fileList: File[]) => {
      const promises = fileList.map(async imageFile =>
        parseImageFile({
          file: imageFile,
          token: keycloak?.token || '',
          startCallback: () => setConvertingImages(true),
        })
      );
      const newImageFileData = await Promise.all(promises);
      setUploadProgress({ total: 0, uploadedTotal: 0, isUploading: false, uploaded: [], uploading: [] });
      await onAllImageFileDataChange(() => newImageFileData);
      setConvertingImages(false);
    },
    [keycloak, onAllImageFileDataChange]
  );

  const onReset = useCallback(async () => {
    await clearLocalStore();

    setExcelFile(null);
    setExcelFileData([]);
    setProductType('');
    setProducerData({});
    setRegexValue('');
    setUploadedProductStatusSet({});

    setEditingImageProductId('');
    setMatchedImageFileDataList([]);
    setAllImageFileDataList([]);
    setProductImageDataSet({});
    setUploadProgress(contextDefaultValue.uploadProgress);
    setImageSettings(contextDefaultValue.imageSettings);
    setTempApproveEanStatus({});
  }, [clearLocalStore]);

  const value = useMemo<TBulkImportContextValue>(
    () => ({
      loadingLocal,
      setLoadingLocal,
      indexedDB,
      // excel
      excelFile,
      setExcelFile,
      excelFileData,
      setExcelFileData,
      dataList,
      setDataList,
      onChangeDataValue,
      uploadedProductStatusSet,
      onUploadedProductStatusSetChange,
      excelColumnList,
      limit,
      onLimitChange,
      productType,
      onProductTypeChange,
      producerList,
      producerData,
      setProducerList,
      onProducerIdChange,
      onReset,
      // product images
      isConvertingImages,
      setConvertingImages,
      regexValue,
      setRegexValue,
      editingImageProductId,
      setEditingImageProductId,
      uploadProgress,
      setUploadProgress,
      imageSettings,
      onImageSettingChange,
      matchedImageFileDataList,
      onRegexValueChange,
      allImageFileDataList,
      onAllImageFileDataChange,
      productImageDataSet,
      setProductImageDataSet,
      onProductImageChange,
      tempApproveEanStatus,
      setTempApproveEanStatus,
    }),
    [
      loadingLocal,
      indexedDB,
      excelFile,
      excelFileData,
      dataList,
      onChangeDataValue,
      uploadedProductStatusSet,
      onUploadedProductStatusSetChange,
      limit,
      onLimitChange,
      productType,
      onProductTypeChange,
      producerList,
      producerData,
      setProducerList,
      onProducerIdChange,
      onReset,
      excelColumnList,
      isConvertingImages,
      regexValue,
      editingImageProductId,
      uploadProgress,
      imageSettings,
      onImageSettingChange,
      matchedImageFileDataList,
      onRegexValueChange,
      allImageFileDataList,
      onAllImageFileDataChange,
      productImageDataSet,
      onProductImageChange,
      tempApproveEanStatus,
    ]
  );

  // set default datalist
  useEffect(() => {
    setDataList(defaultDataList);
  }, [defaultDataList]);

  useEffect(() => {
    updateMatchedImageFileData({ aiIgnore: imageSettings.aiIgnore });
  }, [imageSettings.aiIgnore, updateMatchedImageFileData]);

  useEffect(() => {
    const keyIdMap = new Map();
    dataList.forEach(data => {
      const field = imageSettings.parseImageBy as keyof IProduct;
      if (!data.id || !data[field]) return;
      keyIdMap.set(String(data[field]), String(data.id));
    });

    const acceptedImageDataSet: Record<string, IImageFileSource[]> = {};
    matchedImageFileDataList.forEach(imageFileItem => {
      let productId = imageFileItem.assignProductId;
      for (let [fieldValue, id] of keyIdMap.entries() as unknown as [string, string][]) {
        if (!productId && imageFileItem.name.startsWith(fieldValue)) {
          productId = id;
        }
      }
      if (!productId) return;
      if (!acceptedImageDataSet[productId]) {
        acceptedImageDataSet[productId] = [];
      }
      acceptedImageDataSet[productId].push(imageFileItem);
    });

    // order images
    const orderedImageDataSet: Record<string, IImageFileSource[]> = {};
    Object.entries(acceptedImageDataSet).forEach(([productId, images]) => {
      orderedImageDataSet[productId] = reorderImages(images, imageSettings.aiMain);
    });

    setProductImageDataSet(orderedImageDataSet);
  }, [indexedDB, dataList, matchedImageFileDataList, imageSettings.parseImageBy, imageSettings.aiMain]);

  // load saved productType
  useLayoutEffect(() => {
    if (!dataList.length) return;
    let newProductType = localStorage.getItem(`${STORE_NAME}.product_type`) || '';

    if (!newProductType) {
      // detect foodlaCategory
      const isAllNonFood = dataList.every(isNonFoodProduct);
      newProductType = isAllNonFood ? NON_FOOD_CATEGORY_ID : FOOD_CATEGORY_ID;
    }
    onProductTypeChange(newProductType);
  }, [dataList, onProductTypeChange]);

  // load saved limit and producer
  useEffect(() => {
    if (!dataList.length) return;

    const newLimit = localStorage.getItem(`${STORE_NAME}.limit`) || DEFAULT_LIMIT;
    const newProducerId = localStorage.getItem(`${STORE_NAME}.producerId`) || '';

    if (newLimit && !Number.isNaN(Number(newLimit))) setLimit(Number(newLimit));
    if (newProducerId) {
      const newProducer = producerList.find(({ id }) => id === newProducerId);
      if (newProducer) setProducerData(newProducer);
    }
  }, [dataList, producerList]);

  // load saved regex
  useEffect(() => {
    // loading data from localStorage always finished before loading data from indexDB
    // So don't need to call [updateMatchedImageFileData]
    setRegexValue(localStorage.getItem(`${STORE_NAME}.regex`) || '');
  }, []);

  // load saved uploaded product id list
  useEffect(() => {
    try {
      const newUploadedProductIdSet = JSON.parse(
        localStorage.getItem(`${STORE_NAME}.uploaded_product_status_set`) || '{}'
      );
      setUploadedProductStatusSet(newUploadedProductIdSet);
    } catch {
      setUploadedProductStatusSet({});
    }
  }, []);

  // load saved image's settings
  useEffect(() => {
    const newImageSettings = { ...contextDefaultValue.imageSettings };
    if (localStorage.getItem(`${STORE_NAME}.parse_image_by`)) {
      newImageSettings.parseImageBy = localStorage.getItem(`${STORE_NAME}.parse_image_by`)!;
    }
    if (localStorage.getItem(`${STORE_NAME}.is_ignore_ai`)) {
      newImageSettings.aiIgnore = localStorage.getItem(`${STORE_NAME}.is_ignore_ai`) === 'true';
    }
    if (localStorage.getItem(`${STORE_NAME}.is_ai_main_image`)) {
      newImageSettings.aiMain = localStorage.getItem(`${STORE_NAME}.is_ai_main_image`) === 'true';
    }

    setImageSettings(newImageSettings);
  }, []);

  // load saved excel data
  useEffect(() => {
    if (!indexedDB) return;
    readExcelDataFromIndexedDB(indexedDB).then(({ excelFile, excelFileData }) => {
      setExcelFile(excelFile);
      setExcelFileData(excelFileData);
      setLoadingLocal(false);
    });
  }, [indexedDB, setExcelFile, setExcelFileData]);

  // load saved product images
  useEffect(() => {
    if (!indexedDB || keepRef.current.isLoadedImageFromIndexDB) return;
    readImageFileDataFromIndexedDB(indexedDB).then(imageDataList => {
      setAllImageFileDataList(imageDataList);
      updateMatchedImageFileData({ imageDataList });
      keepRef.current.isLoadedImageFromIndexDB = true;
    });
  }, [indexedDB, updateMatchedImageFileData]);

  return <BulkImportContext.Provider value={value}>{children}</BulkImportContext.Provider>;
};

export const BulkImportProvider: FC = props => {
  const [indexedDB, setIndexedDB] = useState<IndexedDB | null>(null);

  const clearLocalStore = async () => {
    localStorage.removeItem(`${STORE_NAME}.product_type`);
    localStorage.removeItem(`${STORE_NAME}.producerId`);
    localStorage.removeItem(`${STORE_NAME}.regex`);
    localStorage.removeItem(`${STORE_NAME}.parse_image_by`);
    localStorage.removeItem(`${STORE_NAME}.is_ignore_ai`);
    localStorage.removeItem(`${STORE_NAME}.is_ai_main_image`);
    localStorage.removeItem(`${STORE_NAME}.uploaded_product_status_set`);
    await indexedDB?.clearObjectStore(OBJECT_STORAGE_NAME);
  };

  useEffect(() => {
    createIndededDB().then(database => setIndexedDB(database));
  }, [setIndexedDB]);

  return (
    <ErrorBoundary onError={clearLocalStore}>
      <BulkImportProviderBase {...props} indexedDB={indexedDB} clearLocalStore={clearLocalStore} />
    </ErrorBoundary>
  );
};
