import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { Player, ControlBar, ReplayControl, BigPlayButton } from 'video-react';
import { head, path, compose, uniq, concat, without, equals } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import { MUTATION_UPDATE_PRODUCER_PROFILE, S3_SIGNED_REQUEST_MUTATION } from '../../graphql/mutations';
import { QUERY_PRODUCER_PROFILE } from '../../graphql/queries';

import 'video-react/dist/video-react.css';

import CertificationsTab from '../../components/producer/CertificationsTab';
import ImageGridList from '../../components/images/ImageGridList';
import ImageWithFallback from '../../components/ImageWithFallback';
import InputTextField from '../../components/inputfields/InputTextField';
import SnackBar from '../../components/snackbar/SnackBar';
import COLORS from '../../constants/colors';
import { Delivery } from '../../components/superuser/EditProducerForm';

import { isNotDefined, isDefined } from '../../utils/helpers';
import { useMutation } from '@apollo/react-hooks';
import { uploadFile } from '../../utils/awsS3Uploader';
import { SUPERUSER_APPROVAL } from '../../components/constants';
import { KeycloakContext } from '../../components/Secured';

const styles = theme => ({
  root: {
    with: '100%',
  },
  notification: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: COLORS.mainRed,
  },
  media: {
    padding: theme.spacing(1),
    height: 160,
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  rootTab: {
    with: '100%',
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  editContainer: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  editButton: {
    width: '95%',
    margin: 20,
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const Show = withStyles(styles)(({ match, permission, data, classes }) => {
  const [state, setState] = React.useState(data);

  const keycloakCtx = useContext(KeycloakContext);
  const isStore = keycloakCtx.keycloak.hasResourceRole('store');

  let producerCertificates = path(['certificates'], data);
  producerCertificates = isDefined(producerCertificates) ? producerCertificates : [];
  const producerStatus = path(['status'], data);

  const producerNotAccepted = isDefined(producerStatus) && equals(producerStatus, SUPERUSER_APPROVAL.AWAITING_APPROVAL);

  const [value, setValue] = React.useState(0);
  // const startState = isDefined(data.deliverySchedules) ? head(data.deliverySchedules) : [];
  // const [deliverySchedule, setDeliverySchedule] = React.useState(startState);
  const [uploadingCertificates, setUploadingCertificates] = React.useState(false);
  const [newCertificates, setNewCertificates] = React.useState([]);
  const [uploadFailure, setUploadFailure] = React.useState(false);

  const [
    updateProfile,
    { data: updateProfileData, error: errorUpdatingProfile, loading: loadingUpdatingProfile },
  ] = useMutation(MUTATION_UPDATE_PRODUCER_PROFILE, {
    refetchQueries: [{ query: QUERY_PRODUCER_PROFILE }],
  });

  const [s3SignedRequest, { error: requestError, loading: requestLoading }] = useMutation(S3_SIGNED_REQUEST_MUTATION, {
    refetchQueries: [{ query: QUERY_PRODUCER_PROFILE }],
    awaitRefetchQueries: true,
    onCompleted: async data => {
      setUploadingCertificates(true);
      setUploadFailure(false);
      const request = compose(head, path(['getS3SignRequest', 'requests']))(data);
      const urlToCertificate = compose(path(['url']), head, path(['getS3SignRequest', 'requests']))(data);

      try {
        const allCerts = compose(uniq, concat(producerCertificates))([urlToCertificate]);
        await uploadFile(newCertificates[0], request);
        submitCertificate(allCerts);
      } catch (e) {
        setUploadFailure(true);
      } finally {
        setNewCertificates([]);
      }
      setUploadingCertificates(false);
    },
  });

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const submitDeliverySchedule = () => {
    let newDelivery = state?.delivery || [];
    newDelivery = newDelivery.map(x => ({...x, __typename: undefined}));
    updateProfile({
      variables: {
        input: {
          delivery: newDelivery,
          deliverySchedules: [state.deliverySchedules],
        },
      },
    });
  };

  const submitCertificate = certificates => {
    updateProfile({
      variables: {
        input: {
          certificates,
        },
      },
    });
  };

  const handlePDFAction = (pdf, action) => {
    console.log(`${action} PDF - ${pdf}`);
    switch (action) {
      case 'delete': {
        const certs = without([pdf], producerCertificates);
        submitCertificate(certs);
        break;
      }
      case 'view': {
        window.open(pdf, '_blank');
        break;
      }
      default:
        return;
    }
  };

  const onAddCertificate = certificates => {
    setNewCertificates(certificates);
    const files = certificates.map(c => ({ fileName: c.name, fileType: c.type }));
    s3SignedRequest({
      variables: {
        input: {
          files,
          producerName: path(['username'], data),
          folder: 'certificates',
        },
      },
    });
  };

  let paragraphs = [];
  if (data?.description && data.description.trim() !== "") {
    paragraphs = [...paragraphs, ...data.description.split('\n'), " "];
  }
  if (data?.whattodo && data.whattodo.trim() !== "") {
    paragraphs = [...paragraphs, ...data.whattodo.split('\n'), " "];
  }
  if (data?.awards && data.awards.trim() !== "") {
    paragraphs = [...paragraphs, ...data.awards.split('\n')];
  }
  // data.description.split('\n');
  const profiles = path(['profiles'], data);
  const profileImage = head(profiles);

  let story = [];
  if (data?.shortStory && data.shortStory.trim() !== "") {
    // TODO add empty lines
    story = data.shortStory.split('\n').map(s => s.trim() === "" ? " " : s);
  }

  let signText = [];
  if (data?.signText && data?.signText?.trim() !== "") {
    // TODO add empty lines
    signText = data?.signText?.split('\n').map(s => s.trim() === "" ? " " : s);
  }
  return (
    <>
      {isDefined(updateProfileData) && <SnackBar message={'Profile updated!'} duration={3000} />}
      {errorUpdatingProfile && <SnackBar message={'Could not update profile :('} duration={3000} />}
      {(uploadFailure || requestError) && <SnackBar message={'Failed to upload certificate :('} />}
      <Card className={classes.editContainer}>
      <CardContent style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '90%' }}>
        <Button
          className={classes.editButton}
          variant="outlined"
          color="primary"
          component={Link}
          to={`/me/${data.id}/edit`}
        >
          Edit profile
        </Button>
        </div>
        {producerNotAccepted && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <span style={{ fontSize: 16, color: COLORS.mainOrange }}>We are working on accepting you into the application!</span>
        <br /><span style={{ textAlign: 'center', fontSize: 14 }}>Feel free to edit profile and upload products meanwhile.</span>
        </div>}

        </CardContent>
      </Card>
      <Card classes={{ root: classes.root }}>
        <CardContent>
          <div>
            <div style={{ width: '50%', float: 'left' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '10px',
                  flexDirection: 'column',
                }}
              >
                <ImageWithFallback style={{ maxHeight: '100%', maxWidth: '100%' }} src={profileImage}>
                  {src => {
                    return (
                      <img
                        style={{
                          maxHeight: '100%',
                          maxWidth: '100%',
                          borderRadius: '4px',
                        }}
                        src={src}
                        alt=""
                      />
                    );
                  }}
                </ImageWithFallback>
                {data?.shortStory && story?.length > 0 && (
                  <div style={{alignSelf: 'flex-start', minWidth: 400}}>
                    <h3>Skylttext A4, A5 (max 277 tecken):</h3>
                    {story.map((value, index) => {
                        return <p style={{marginTop: 0, marginBottom: 10}} key={index}>{value}</p>;
                    })}
                  </div>
                )}

                {data?.signText && signText?.length > 0 && (
                  <div style={{alignSelf: 'flex-start', minWidth: 400}}>
                    <h3>Skylttext för balk och hyllkant (max 70 tecken):</h3>
                    {signText?.map((value, index) => {
                        return <p style={{marginTop: 0, marginBottom: 10}} key={index}>{value}</p>;
                    })}
                  </div>
                )}
              </div>
            </div>
            <div style={{ width: '50%', float: 'left' }}>
              <div style={{ margin: '10px' }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {data.name}
                </Typography>
                <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
                  {paragraphs.map((value, index) => {
                    const style = {
                      marginTop: 0,
                      marginBottom: 0,
                    };
                    // if (index + 1 < paragraphs.length - 1) {
                    //   const next = paragraphs[index+1];
                    //   if (!next || next.trim() === "") {
                    //     style.marginBottom = 0;
                    //   }
                    // }
                    // if (!value || value.trim() === "") {
                    //   style.marginTop = 0;
                    //   style.marginBottom = 0;
                    // }
                    return <p style={style} key={index}>{value}</p>;
                  })}
                </Typography>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </CardContent>
      </Card>
      {!isStore && (!state?.delivery || state.delivery.length === 0) && (
        <Card className={classes.notification}>
          <CardContent>
            <h4>Notification</h4>
            <p>
              You have not provided a delivery schedule yet. Please go to "Delivery Schedule" tab and do so. Thank you!
            </p>
          </CardContent>
        </Card>
      )}

      <Card className={classes.rootTab}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="Info" value={0} />
          {!isStore && (
            <Tab
            value={1}
            style={
              (!state?.delivery || state.delivery.length === 0)
                ? {
                    backgroundColor: COLORS.mainRed,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }
                : {}
            }
            label="Delivery Schedule"
          ></Tab>
          )}
          <Tab label="Certifications" value={2} />
          <Tab label="Email" value={3} />
          {data.video && <Tab label="Video" value={4} />}
        </Tabs>
        <Divider />
        {value === 0 && (
          <TabContainer>
            <ImageGridList profiles={profiles} />
          </TabContainer>
        )}
        {!isStore && value === 1 && (
          <TabContainer>
            {state?.delivery && state?.delivery.map((delivery, index) => {
              return (
                <Delivery
                  delivery={delivery}
                  state={state}
                  setState={setState}
                  index={index}
                />
              );
            })}
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <AddCircleIcon style={{color: "rgb(0, 163, 71)"}} fontSize="small" />
              <a href="#" onClick={(e) => {
                e.preventDefault();
                // if (!inputDelivery) {
                //   inputDelivery = [];
                // }
                // inputDelivery.push({});
                // console.log('inputDelivery', inputDelivery);
                let delivery = state?.delivery;
                if (!delivery) {
                  delivery = [];
                }
                delivery.push({});
                setState({...state, delivery});
              }}> Ytterligare beställnings och leveranstillfällen</a>
            </div>
            <InputTextField
              inputName="deliverySchedules"
              // label="Description"
              value={state?.deliverySchedules}
              onChange={(e) => {
                e.preventDefault();
                setState({...state, deliverySchedules: e.target.value});
              }}
              // required
              multiline
              multilineRows={4}
              multilineMax={10}
              withLabel={true}
              labelOptions={{
                heading: 'Övriga leveransdetaljer kan anges här:',
              //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
              }}
            />
            {/* <InputTextField
              value={deliverySchedule}
              onChange={e => {
                setDeliverySchedule(e.target.value);
              }}
              required
              inputName="deliverySchedule"
              label="Enter delivery schedule"
              autoFocus
              multiline
              multilineRows={4}
              multilineMax={10}
              withLabel
              disabled={loadingUpdatingProfile}
              labelOptions={{
                heading: 'Delivery Schedule',
                text: 'Enter the delivery schedule for your goods',
              }}
            />
            */}
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              className={classes.button}
              onClick={submitDeliverySchedule}
              // disabled={isNotDefined(deliverySchedule)}
            >
              {' '}
              Update{' '}
            </Button>
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <CertificationsTab
              certificates={producerCertificates}
              onAddCertificate={onAddCertificate}
              isUploading={uploadingCertificates || requestLoading}
              onPDFButtonClicked={handlePDFAction}
            />
          </TabContainer>
        )}
        {value === 3 && <TabContainer>{data.email}</TabContainer>}
        {value === 4 && data.video && (
          <TabContainer>
            <Player autoPlay poster={data.avatar ? data.avatar.url : ''} startTime={300} src={data.video}>
              <BigPlayButton position="center" />
              <ControlBar autoHide={false}>
                <ReplayControl seconds={5} order={2.1} />
                <ReplayControl seconds={10} order={2.2} />
                <ReplayControl seconds={30} order={2.3} />
              </ControlBar>
            </Player>
          </TabContainer>
        )}
      </Card>
    </>
  );
});

const ShowMyProfile = ({ match, ...rest }) => {
  return (
    <Query query={QUERY_PRODUCER_PROFILE}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p> Error {error} </p>;

        if (data?.userProducer) {
          return <Show data={data.userProducer} match={match} permission={{}} />;
        } else {
          return <p>Producer profile does not exist in Foodla. Contact support.</p>;
        }
      }}
    </Query>
  );
};

export default ShowMyProfile;
