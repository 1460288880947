import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress  from '@material-ui/core/LinearProgress';
import Typography  from '@material-ui/core/Typography';

import VisionTab from '../tab';
import { fixFloat } from '../utils';

const Properties = ({visionTab, image, info, isNew }) => {
  return (
    <VisionTab
      index="properties"
      tabValue={visionTab}
      image={image}
      info={info}
      isNew={isNew}
    >
      <div>
        <p><b>Dominant colors:</b></p>
        {(!info?.imagePropertiesAnnotation?.dominantColors?.colors || info?.imagePropertiesAnnotation?.dominantColors?.colors.length === 0) && (
          <Typography>No colors detected.</Typography>
        )}
        <table style={{width: '100%'}}>
          <thead>
            <tr>
              <th style={{width: '50%'}}>Score</th>
              <th style={{width: '50%'}}>Pixel Fraction</th>
            </tr>
          </thead>
          <tbody>
            {info?.imagePropertiesAnnotation?.dominantColors?.colors?.sort((a, b) => b?.score - a?.score).map((item) => (
              <>
              <tr>
                <td>
                  <div style={{
                    width: 30,
                    height: 30,
                    display: 'inline-block',
                    background: `rgba(${item?.color?.red},${item?.color?.green},${item?.color?.blue})`,
                    marginRight: 5,
                  }}></div>
                  {fixFloat(item?.score)}%
                </td>
                <td>{fixFloat(item?.pixelFraction)}%</td>
              </tr>
              <tr>
                <td>
                  <LinearProgress
                    variant="determinate"
                    value={item?.score * 100}
                    color="primary"
                    style={{width: '100%'}}
                  />
                </td>
                <td>
                  <LinearProgress
                    variant="determinate"
                    value={item?.pixelFraction * 100}
                    color="primary"
                    style={{width: '100%'}}
                  />
                </td>
              </tr>
              </>
            ))}
          </tbody>
        </table>
          {/* <List>
          {info?.imagePropertiesAnnotation?.dominantColors?.colors?.map((item) => (
            <ListItem>
              <div style={{width: '100%'}}>
                <div style={{
                  width: 30,
                  height: 30,
                  display: 'inline-block',
                  background: `rgba(${item?.color?.red},${item?.color?.green},${item?.color?.blue})`,
                  marginRight: 5,
                }}></div>


              </div>
            </ListItem>
          ))}
          </List> */}
            {/* <p>
              <div style={{
                width: 30,
                height: 30,
                display: 'inline-block',
                background: `rgba(${item?.color?.red},${item?.color?.green},${item?.color?.blue})`,
                marginRight: 5,
              }}></div>
              <b>Score:</b> {fixFloat(item?.score)}%   <b>Pixel Fraction:</b> {fixFloat(item?.pixelFraction)}%
            </p>
            // <p> ({item?.color.red}, {item?.color.green}, {item?.color.blue}, {item?.color.alpha}) Score: {item?.score}  PixelFraction: {item?.pixelFraction}</p>
      )} */}
      </div>
    </VisionTab>
  );
};

export default Properties;
