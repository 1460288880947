/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import './App.css';
import ApolloClient, { InMemoryCache, gql } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks';
import { LayoutProvider } from './layouts/LayoutContext';
import LayoutContainer from './layouts/LayoutContainer';
import Products from './pages/organization/Organization_Products';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { KeycloakContext, withAuthorization } from './components/Secured';
import Keycloak from 'keycloak-js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getBackendHost } from 'utils/urlUtils';
// import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';

const { REACT_APP_HOTJAR_SITE_ID } = process.env;
if (REACT_APP_HOTJAR_SITE_ID && REACT_APP_HOTJAR_SITE_ID.trim() !== '') {
  Hotjar.init(Number(process.env.REACT_APP_HOTJAR_SITE_ID), 6, { debug: true });
  console.log('Hotjar initialized');
}

// ReactGA.initialize({
//   trackingId: 'G-HXDF37HR6N',
// });

const AUTH_ENV = process.env.REACT_APP_AUTH_ENV || 'dev';

const cache = new InMemoryCache({
  addTypename: false,
});

cache.writeData({
  data: {
    isLoggedIn: !!localStorage.getItem('token'),
    networkStatus: {
      __typename: 'NetworkStatus',
      isConnected: false,
    },
  },
});

const resolvers = {
  Mutation: {
    clearCart: (obj, args, context, info) => {
      let items = localStorage.getItem(CART_ITEMS_KEY);
      items = !!items ? JSON.parse(items) : [];
      items.forEach(item => {
        localStorage.removeItem(item);
      });
      localStorage.removeItem(CART_ITEMS_KEY);
      return items;
    },
    clearNotes: (obj, args, context, info) => {
      let items = localStorage.getItem(NOTES_KEY);
      items = !!items ? JSON.parse(items) : [];
      items.forEach(item => {
        localStorage.removeItem(`notes-${item}`);
      });
      localStorage.removeItem(NOTES_KEY);
      return items;
    },
    addNoteOrRemoveItem: (obj, args, context, info) => {
      const { item, isAdd, text } = args;
      let items = localStorage.getItem(NOTES_KEY);
      items = !!items ? JSON.parse(items) : [];
      if (isAdd) {
        items = [...items, item];
        localStorage.setItem(item, text);
      } else {
        items.splice(items.indexOf(item), 1);
        localStorage.removeItem(item);
      }
      localStorage.setItem(NOTES_KEY, JSON.stringify(items));
      return items;
    },
    addCartOrRemoveItem: (obj, args, context, info) => {
      const { item, isAdd, quantity, addFirst } = args;
      let items = localStorage.getItem(CART_ITEMS_KEY);
      items = !!items ? JSON.parse(items) : [];

      if (isAdd) {
        // add product to begin of cart
        if (addFirst) {
          items = [item, ...items];
        } else {
          items = [...items, item];
        }
        let volume = '1';
        if (quantity) {
          volume = quantity.toString();
        } 
        localStorage.setItem(item, volume);
      } else {
        items.splice(items.indexOf(item), 1);
        localStorage.removeItem(item);
      }
      localStorage.setItem(CART_ITEMS_KEY, JSON.stringify(items));
      return items;
    },
  },
  Query: {
    cartItems: (obj, args, context, info) => {
      let items = localStorage.getItem(CART_ITEMS_KEY);
      items = !!items ? JSON.parse(items) : [];
      return items;
    },
    producerNotes: (obj, args, context, info) => {
      let items = localStorage.getItem(NOTES_KEY);
      items = !!items ? JSON.parse(items) : [];
      return items;
    },
  },
  // Product: {
  //   quantityInCart: (obj, args, context, info) => {
  //     return localStorage.getItem(obj.id) || 0;
  //   },
  // },
};

const typeDefs = gql`
  extend type Query {
    cartItems: [String!]!
    producerNotes: [String!]!
    isLoggedIn: Boolean! @client(always: true)
  }
  # extend type Product {
  #   quantityInCart: Int!
  # }

  extend type Mutation {
    addCartOrRemoveItem(item: String!, isAdd: Boolean, quantity: Int): [String!]!
    addNoteOrRemoveItem(item: String!, isAdd: Boolean, text: String): [String!]!
    clearCart: [String!]!
    clearNotes: [String!]!
  }
`;

const CART_ITEMS_KEY = 'CART_ITEMS';
const NOTES_KEY = 'PRODUCER_NOTES';

const keycloak = Keycloak(`/keycloak.${AUTH_ENV}.json`);
console.log('keycloak', keycloak);

// const url = process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql';

const graphqlHost = getBackendHost('/graphql');
const client = new ApolloClient({
  cache,
  request: operation => {
    if (operation.operationName !== 'LOGIN') {
      operation.setContext({
        headers: {
          authorization: `Bearer ${keycloak.token}` || null,
        },
      });
    }
  },
  typeDefs,
  resolvers,
  uri: graphqlHost,
});

export function ProductListWithSelectedId(props) {
  // return (<Drawer anchor="right" open={open} onClose={onClose} onKeyDown={onClose} ><ShowProduct {...props} /></Drawer>)
  return <Products {...props} />;
}

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#FF0000",
      },
    },
  },
  palette: {
    background: {
      default: '#FAFBFF',
    },
    success: {
      main: '#333',
    },
    error: {
      main: '#FF0000',
    }
  },
  status: {
    pending: '#333',
  },
});

function App() {
  // ReactGA.initialize({
  //   // debug: true,
  //   trackingId: 'G-HXDF37HR6N',
  //   // trackingId: 'G-F50DQTQN4Y',
  //   // testMode: true,
  //   // gaOptions: {
  //   //   cookieDomain: 'none'
  //   // }
  // });
  // React.useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  const SecuredAppRouter = withAuthorization(AppRoleBasedRouter);
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <ApolloHookProvider client={client}>
          <KeycloakContext.Provider value={{ keycloak: keycloak }}>
            <>
              <CssBaseline />
              <SecuredAppRouter />
            </>
          </KeycloakContext.Provider>
        </ApolloHookProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

class AppRoleBasedRouter extends Component {
  static contextType = KeycloakContext;
  stopUpdateToken = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  startUpdateToken = () => {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.doUpdateToken();
      }, 15000);
    }
  };

  doUpdateToken = () => {
    if (this.context.keycloak) {
      this.context.keycloak
        .updateToken(15)
        /*.then(
                    refreshed => {
                        if (refreshed) {
                            console.log('Token was successfully refreshed');
                        } else {
                            console.log('Token is still valid');
                        }
                    },
                    error => {}
                )*/
        .catch(e => {
          this.context.keycloak.clearToken();
          console.log('Failed to refresh the token, or the session has expired');
        })
        .finally(() => {
          //nothing to do here
        });
    }
  };

  componentDidMount = () => {
    this.startUpdateToken();
  };

  componentWillUnmount = () => {
    this.stopUpdateToken();
  };

  handleLogout = () => {
    client.resetStore();
    this.context.keycloak.logout();
    //localStorage.removeItem("token")
    //props.history.push("/login")
  };

  render() {
    console.log(this.context.keycloak);
    if (this.context && this.context.keycloak) {
      const RESOURCE_ROLE_EMPTY = 'EMTPY_ROLE';
      let resourceRole = RESOURCE_ROLE_EMPTY;

      if (this.context.keycloak.hasResourceRole('admin') && this.context.keycloak.hasResourceRole('logistician'))
        resourceRole = 'adminlogistician';
      else if (this.context.keycloak.hasResourceRole('admin')) resourceRole = 'admin';
      else if (this.context.keycloak.hasResourceRole('producer')) resourceRole = 'producer';
      else if (this.context.keycloak.hasResourceRole('logistician')) resourceRole = 'logistician';
      else if (this.context.keycloak.hasResourceRole('superuser')) resourceRole = 'superuser';
      else if (this.context.keycloak.hasResourceRole('quality')) resourceRole = 'quality';
      else if (this.context.keycloak.hasResourceRole('store')) resourceRole = 'store';

      console.log(`keycloak resource role: ${resourceRole}`);

      return resourceRole !== RESOURCE_ROLE_EMPTY ? (
        <LayoutProvider>
          <LayoutContainer role={resourceRole} />
        </LayoutProvider>
      ) : (
        <>
          User has inproper roles to use foodla b2b client App{' '}
          <a href="#" onClick={this.handleLogout}>
            Log out
          </a>
        </>
      );
    } else {
      return <>Illegal access to secured pages</>;
    }
  }
}
export default App;
