import { FC, useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';

import { IImportColumn, IProductExcel, STATUS_COLORS } from 'utils/excelUtils-old';

import { useStyles, CELL_SPACING } from '../index.style';
import { useBulkImportContext } from 'pages/superuser/Superuser_BulkImport/BulkImportContext';
import UploadErrorIcon from '../UploadErrorIcon';
import ReviewTableBodyCell from '../ReviewTableBodyCell';
import TruncateText from 'components/TruncateText';
import ReviewTableTemplateHeaderCell from '../ReviewTableTemplateHeaderCell';

export const PIN_COLUMN = 'title';
const COLUMN_WIDTH = {
  FIRST: 140,
};

export const useCustomStyles = makeStyles(() => ({
  fixedFirstColumnCell: {
    left: 0,
    zIndex: 10,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    position: 'sticky',
  },
  fixedSecondColumnCell: {
    left: COLUMN_WIDTH.FIRST + 1, // 1px for Border
    zIndex: 10,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    position: 'sticky',
  },
  bodyCellIdWrapper: {
    width: COLUMN_WIDTH.FIRST - CELL_SPACING * 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface BaseProps {
  isReview?: boolean;
  columnList: IImportColumn[];
}

export const FixedTemplateHeaderCellList: FC<BaseProps> = ({ isReview, columnList }) => {
  const customClasses = useCustomStyles();

  const pinColumn = useMemo(() => columnList.find(({ field }) => field === PIN_COLUMN), [columnList]);

  const handleChangeColumnIdWidth = (newWidth: number) => {
    const tableCellIdWidth = newWidth + CELL_SPACING * 2;
    document.querySelectorAll<HTMLElement>(`[data-field='${pinColumn?.field}']`).forEach(element => {
      const parentElement = element.parentElement as HTMLTableElement;
      parentElement.style.left = `${tableCellIdWidth + 1}px`; // 1px for Border
    });
  };

  if (isReview) return null;

  return (
    <>
      <ReviewTableTemplateHeaderCell
        field="id"
        className={customClasses.fixedFirstColumnCell}
        onChangeWidth={handleChangeColumnIdWidth}
        wrapperProps={{ style: { width: COLUMN_WIDTH.FIRST - CELL_SPACING * 2 } }}
      >
        <TruncateText title="Template fields" />
      </ReviewTableTemplateHeaderCell>

      {pinColumn && (
        <ReviewTableTemplateHeaderCell
          field={pinColumn.field}
          className={customClasses.fixedSecondColumnCell}
          style={{ background: pinColumn.color }}
        >
          <TruncateText
            title={pinColumn.title}
            fixedAfter={!isReview && pinColumn.isError ? `(${pinColumn.firstErrorCellId})` : ''}
          />
        </ReviewTableTemplateHeaderCell>
      )}
    </>
  );
};

export const FixedFieldHeaderCellList: FC<BaseProps> = ({ isReview, columnList }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const pinColumn = useMemo(() => columnList.find(({ field }) => field === PIN_COLUMN), [columnList]);

  if (isReview) return null;

  return (
    <>
      <TableCell className={clsx(classes.headCell, customClasses.fixedFirstColumnCell)}>
        <div data-field="id" style={{ width: COLUMN_WIDTH.FIRST - CELL_SPACING * 2 }}>
          <TruncateText title="Foodla fields" />
        </div>
      </TableCell>

      {pinColumn && (
        <TableCell className={clsx(classes.headCell, customClasses.fixedSecondColumnCell)}>
          <div data-field={pinColumn.field}>
            <TruncateText title={pinColumn.field} />
          </div>
        </TableCell>
      )}
    </>
  );
};

interface FixedBodyCellListProps extends BaseProps {
  isApproved?: boolean;
  data: IProductExcel;
}

const FixedBodyCellList: FC<FixedBodyCellListProps> = ({ isReview, isApproved, columnList, data }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { uploadedProductStatusSet } = useBulkImportContext();

  const pinColumn = useMemo(() => columnList.find(({ field }) => field === PIN_COLUMN), [columnList]);

  if (isReview) return null;

  let background = '#FFFFFF';
  if (data._savedData) background = '#FFFFC5'; // YELLOW / 0.3
  if (isApproved) background = '#C7EED8'; // GREEN / 0.6

  const uploadedProductStatus = uploadedProductStatusSet[data.id || ''] || {};

  return (
    <>
      {/* Id column */}
      <td
        className={clsx(classes.bodyCell, customClasses.fixedFirstColumnCell)}
        style={{ background: uploadedProductStatus.error ? STATUS_COLORS.ERROR : background }}
      >
        <div data-field="id" className={clsx(classes.bodyCellBox, customClasses.bodyCellIdWrapper)}>
          <div style={{ width: '100%' }}>{data.id || ''}</div>
          {!!uploadedProductStatus.error && (
            <UploadErrorIcon errors={[{ label: 'Error Upload Product', message: uploadedProductStatus.error }]} />
          )}
        </div>
      </td>

      {/* pinned column */}
      {pinColumn && (
        <ReviewTableBodyCell
          isReview={!!isReview}
          className={customClasses.fixedSecondColumnCell}
          column={pinColumn}
          data={data}
        />
      )}
    </>
  );
};

const FixedColumnList = {
  TemplateHeaderCellList: FixedTemplateHeaderCellList,
  FieldHeaderCellList: FixedFieldHeaderCellList,
  BodyCellList: FixedBodyCellList,
};

export default FixedColumnList;
