import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import OfflinePinOutlinedIcon from '@material-ui/icons/OfflinePinOutlined';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/react-hooks';
import { MARK_ORDER_NOTIFIED, ORDER_BY_ORDER_NUMBER } from '../graphql/queries'
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import OrderStatusStepper from './OrderStatusStepper';
import * as OrderStatus from './constants';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from "moment";


const useStyles = makeStyles(theme => ({
    statusBox: {
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export default props => {
    const { order, isProducer, organization, logistician } = props;
    const {
        statusInfo: { status },
        orderNumber,
        producer,
    } = order;
    console.log("ORG", organization)

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const chipOnClick = () => {
        setOpen(!open);
    };
    let StatusIcon;
    let frontStatus;
    let stepStatus;
    switch (status) {
        case OrderStatus.ORDER_STATUS_CREATED:
            StatusIcon = ReportProblemOutlinedIcon;
            frontStatus = OrderStatus.ORDER_STATUS_CREATED_FRONTEND;
            break;

        case OrderStatus.ORDER_STATUS_NOTIFIED_NOTIFYING:
            StatusIcon = CachedOutlinedIcon;
            frontStatus = OrderStatus.ORDER_STATUS_SENDING_FRONTEND;
            break;

        case OrderStatus.ORDER_STATUS_NOTIFIED_DONE:
            StatusIcon = DoneAllOutlinedIcon;
            frontStatus = OrderStatus.ORDER_STATUS_DONE_FRONTEND;
            break;

        case OrderStatus.ORDER_STATUS_NOTIFIED_ERROR:
        case OrderStatus.ORDER_STATUS_NOTIFIED_FAILURE:
            StatusIcon = ErrorOutlineOutlinedIcon;
            frontStatus = OrderStatus.ORDER_STATUS_ERROR_FRONTEND;
            break;

        default:
            frontStatus = OrderStatus.ORDER_STATUS_ERROR_FRONTEND;
            StatusIcon = ErrorOutlineOutlinedIcon;
    }

    let errorState =
        frontStatus === OrderStatus.ORDER_STATUS_ERROR_FRONTEND ||
        frontStatus === OrderStatus.ORDER_STATUS_CREATED_FRONTEND;

    if (errorState) {
        stepStatus = OrderStatus.ORDER_STATUS_SENDING_FRONTEND; // only sending can have error
    } else {
        stepStatus = frontStatus; // otherwise it should be sending or done
    }

    let statusColor = errorState === true ? 'error' : 'primary';
    let steps = OrderStatus.ORDER_STATUS_FRONTEND;

    let secondInfo = [];

    if (organization?.name || organization?.name.trim() !== "") {
        secondInfo.push(organization?.name)
    }

    let senderEmail = logistician?.email;
    if (senderEmail && senderEmail.trim() !== "") {
        secondInfo.push(senderEmail);
    }

    let orderDate = "";
    if (order?.statusInfo?.createdAt) {
        orderDate = moment(parseInt(order?.statusInfo?.createdAt, 0)).format("YYYY-DD-MM hh:mm");
    }
    if (orderDate && orderDate.trim() !== "") {
        secondInfo.push(orderDate);
    }

    let secondInfoComponent = undefined;
    if (secondInfo && secondInfo.length > 0) {
        secondInfoComponent = (
            <>
                <br />
                <span>Orderläggare: {secondInfo.join(" | ")}</span>
            </>
        );
    }

    let sencondaryText = (
        <>
            <span>{!!producer.email ? `${orderNumber} | ${producer.email}` :  `${orderNumber}`}</span>
            {secondInfoComponent}
        </>
    );

    return (
        <ListItem>
            <ListItemIcon>
                <Box className={classes.statusBox}>
                    <Chip
                        clickable
                        onClick={chipOnClick}
                        icon={<StatusIcon color={statusColor} />}
                        color={statusColor}
                        label={frontStatus}
                        size="small"
                    />
                    <Collapse in={open}>
                        <Box>
                            <OrderStatusStepper
                                steps={steps}
                                status={stepStatus}
                                error={errorState}
                            />
                        </Box>
                    </Collapse>
                </Box>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        component={Link}
                        to={`/producers/${producer.id}/show`}
                        target="_blank"
                        color="inherit"
                    >
                        {producer.name}
                    </Typography>
                }
                secondary={sencondaryText}
            >
            </ListItemText>
            <ListItemSecondaryAction>
                <NotifyOperations orderNumber={orderNumber} status={status} isProducer={isProducer} />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const NotifyOperations = props => {
    const { orderNumber, isProducer } = props;
    const [groupOrderNumber] = orderNumber.split('-');

    const [markOrderNotified, { error, loading }] = useMutation(
        MARK_ORDER_NOTIFIED,
        {
            refetchQueries: [
                {
                    query: ORDER_BY_ORDER_NUMBER,
                    variables: { orderNumber: groupOrderNumber },
                },
            ],
        }
    );

    const notify = sendMail => {
        markOrderNotified({
            variables: {
                OrderNumber: orderNumber,
                SendNotification: sendMail,
            },
        });
    };

    if (loading) return <CachedOutlinedIcon color="primary" />;
    if (error) return <ErrorOutlineOutlinedIcon color="secondary" />;

    return (
        isProducer ? <></> : (
            <>
                <Tooltip
                    title="To e-mail or call this producer offline and mark it as done here, useful if resending mail doesn't work"
                    aria-label="markdone"
                >
                    <Button
                        startIcon={<OfflinePinOutlinedIcon color="primary" />}
                        onClick={() => notify(false)}
                    >
                        Mark as Done
                    </Button>
                </Tooltip>
                <Tooltip
                    title="To resend email to this producer if something goes wrong"
                    aria-label="resend"
                >
                    <Button
                        startIcon={<MailOutlineIcon color="secondary" />}
                        onClick={() => notify(true)}
                    >
                        Resend email
                    </Button>
                </Tooltip>
            </>
        )
    );
};
