import React from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import ModalContent from './ShowProduct';
import { IconButton } from '@material-ui/core';
import CloseIcon from 'components/icons/CloseIcon';
import useViewport from 'hooks/useViewports';

const styles = (theme: any) => ({
    modalHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        padding: 10,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'end',
        borderBottom: '1px solid #eee',
    },
    modalContentFullScreen: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.background.paper,
        overflow: 'scroll',
    },
    modalContent: {
        width: 'calc(100% - 20px)',
        maxWidth: theme.spacing(120),
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translateX(-50%)',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        overflow: 'scroll',
    },
});

interface IProductModalProps {
    classes: any;
    modalOpen: boolean;
    onModalClosing: any;
    producerId: string;
    productId: string;
}

const ProductModal = ({
    classes,
    modalOpen,
    onModalClosing,
    producerId,
    productId,
}: IProductModalProps) => {
    const { mdUp } = useViewport();

    return (
        <Modal
            open={modalOpen}
            onClose={onModalClosing}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div id='productModal' className={mdUp ? classes.modalContent : classes.modalContentFullScreen}>
                <div className={classes.modalHeader}>
                  <IconButton onClick={onModalClosing}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <ModalContent producerId={producerId} productId={productId} />
            </div>
        </Modal>
    );
};

export default withStyles(styles as any)(ProductModal);
