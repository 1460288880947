import { useQuery } from "@apollo/react-hooks";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { FIND_SORTED_PRODUCT_APPROVALS } from "graphql/queries";
import IProduct from "models/product";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

interface IApprovementsProps {
  productId: string;
}

const Approvements = ({ productId }: IApprovementsProps) => {
  const classes = useStyles();
  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery(FIND_SORTED_PRODUCT_APPROVALS, {
      variables: {
        productId,
      },
      fetchPolicy: 'cache-and-network',
  });
  // console.log('!!!approvements', data);
  const approvements = data?.findSortedProductApprovals || [];
  let title = `Approvements (${approvements.length})`;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {approvements.length === 0 && <Typography>No approvements</Typography>}
          {approvements.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>approvedAt</TableCell>
                    <TableCell>approvedBy</TableCell>
                    <TableCell>organizationGroupIdentifier</TableCell>
                    <TableCell>state</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvements.map((approvement: any) => (
                    <TableRow key={approvement.productId}>
                      <TableCell>{approvement.approvedAt && new Date(approvement.approvedAt).toUTCString()}</TableCell>
                      <TableCell>{approvement.approvedBy}</TableCell>
                      <TableCell>{approvement.organizationGroupIdentifier}</TableCell>
                      <TableCell>{approvement.state}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Approvements;
