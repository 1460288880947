import React, { FC } from 'react';

interface ImageIconProps extends React.SVGProps<SVGSVGElement> {
  isError?: boolean;
}

const ImageIcon: FC<ImageIconProps> = ({ isError, ...props }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333ZM11.3333 18L14.6667 22.0133L19.3333 16L25.3333 24H6.66667L11.3333 18Z"
        fill={isError ? '#D32F2F' : '#9CA3BC'}
      />
    </svg>
  );
};

export default ImageIcon;
