import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import IProduct from "models/product";

interface IMedicalTechnologyFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
};

const labelStyles = {
  margin: 0,
}

const MedicalTechnologyFields = ({ state, setState}: IMedicalTechnologyFieldsDataProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  return (
    <div style={{width: '100%'}}>
      <FormControl component="fieldset" style={{width: '100%'}}>
        <InputLabel 
          heading="Överensstämmer den medicintekniska produkten kraven i förordningen (EU) 2017/745 eller (EU) 2017/746?"
          text="Säkerheten för medicintekniska produkter och medicintekniska produkter produkter för in vitro-diagnostik, förordningar (EU) 2017/745 och (EU) 2017/746 ställer höga kvalitets- och säkerhetskrav för sådana produkter."
          link="https://www.regeringen.se/contentassets/aa86c9639b6d4ec9a43efe07bdaf5f39/bilaga-1-eu-forordning-2023_607-15-mars-2023.pdf"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.complianceMedicalTechProducts}
          name="complianceMedicalTechProducts"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" style={{width: '100%'}}>
        <InputLabel 
          heading="Har produkten en unik produktidentifiering (UDI)?"
          text="UDI (Unique Device Identification) är en metod för att märka och identifiera medicinsk utrustning för att ge en globalt unik identifiering i både distribution och användning för att öka spårbarheten."
          link="https://gs1.se/din-bransch/halso-och-sjukvard/udi-unique-device-identification/"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.checkUniqueIdentification}
          name="checkUniqueIdentification"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
          />
        </RadioGroup>
      </FormControl>
      <InputLabel
        heading="Kommentar till eventuella nej-svar"
        text="Unik produktidentifierare, eller unique device identifier (UDI), är en kod som möjliggör entydig identifiering av specifika medicintekniska produkter och in vitro-diagnostiska produkter på marknaden."
        link="https://www.lakemedelsverket.se/sv/medicinteknik/tillverka/vagen-till-ce-market/unik-produktidentifierare"
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="explanationNoUDI"
        value={state.explanationNoUDI}
        placeholder="Kommentar"
        onChange={handleChange}
      />
    </div>
  );
}

export default MedicalTechnologyFields;
