import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { TExcelDataRow } from 'models/excel';
import { IFoodlaCategoryOption } from 'models/category';
import { getEAN2 } from 'utils/products';
import { CATEGORY_TYPE_DETAILS, COLUMN_DETAILS } from '../../constants';
import { getItems as getSearchTerms } from 'components/product/ProductUpdateForm/fields/search-terms';
import { IImportColumn, TParseExcelDataToProductReturn } from '../../types';
import { checkEnumType, computeBaseItems, correctCorrespondingValues, getDataType } from './cell';
import { correctColumnOrders, insertColumnList } from './column';
import { isNewNonFood } from 'components/product/ProductUpdateForm/fields/category';
import IProduct from 'models/product';
import { getRemovedProductIndexs } from '.';

const getDefaultCategory = (params: { selectedCategoryTypeId: string; allCategories: IFoodlaCategoryOption[] }) => {
  const { selectedCategoryTypeId, allCategories } = params;

  const categoryTypeDetail = CATEGORY_TYPE_DETAILS.find(({ id }) => id === selectedCategoryTypeId);
  if (!categoryTypeDetail) return;

  const defaultQualityGroup = allCategories.find(
    ({ id, name }) => id?.startsWith('qg-') && name === categoryTypeDetail.defaultCategory.qualityGroupName
  );

  return allCategories.find(
    ({ parentCategoryId, id, name }) =>
      parentCategoryId === defaultQualityGroup?.id &&
      id?.startsWith('parent-') &&
      name === categoryTypeDetail.defaultCategory.parentCategoryName
  );
};

export const getShortTitle = (title: string) => {
  if (!title) return '';
  return (
    String(title)
      .replaceAll('"', '')
      .split('\n')
      .map(item => item.trim())?.[0] || ''
  );
};

const generateValidTitles = (titleStr: string) => {
  if (!titleStr) return [];

  const shortTitle = getShortTitle(titleStr).toLowerCase();
  const longTitle = String(titleStr).replaceAll('\n', ' ').replaceAll('"', '').trim().toLowerCase();

  const removeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const removeRoundBrackets = (text: string) => {
    let result = text;
    if (text.startsWith('(')) {
      result = result.slice(1);
      if (text.endsWith(')')) result = result.slice(0, -1);
    }
    return result;
  };

  return [
    shortTitle,
    removeAccents(shortTitle),
    removeRoundBrackets(shortTitle),
    removeAccents(removeRoundBrackets(shortTitle)),

    longTitle,
    removeAccents(longTitle),
    removeRoundBrackets(longTitle),
    removeAccents(removeRoundBrackets(longTitle)),
  ].map(title => {
    if (title.endsWith(':')) return title.slice(0, -1);
    return title;
  });
};

export const getCategofyColumnByTitle = <T extends { title: string; field: string }>(
  titleParam: string,
  categoryColumnList: T[]
) => {
  if (!titleParam) return;

  const loopCheck = (checkMethod: (excelTitle: string, title: string) => boolean) => {
    let result: T | undefined;

    const sortedCategoryColumnList = _.cloneDeep(categoryColumnList).sort(
      (prev, next) => next.title.length - prev.title.length
    );
    const excelTitleList = generateValidTitles(titleParam);

    sortedCategoryColumnList.forEach(categoryColumn => {
      const categoryColumnTitleList = generateValidTitles(categoryColumn.title);

      const columnDetail = COLUMN_DETAILS.find(({ field }) => field === categoryColumn.field);
      columnDetail?.searchTitleList?.forEach(searchTitle => {
        categoryColumnTitleList.push(...generateValidTitles(searchTitle));
      });

      excelTitleList.forEach(excelTitle => {
        categoryColumnTitleList.forEach(categoryColumnTitle => {
          if (!result && checkMethod(excelTitle, categoryColumnTitle)) {
            result = categoryColumn;
          }
        });
      });
    });

    for (let categoryColumnIdx = 0; categoryColumnIdx < sortedCategoryColumnList.length; categoryColumnIdx++) {
      const categoryColumn = sortedCategoryColumnList[categoryColumnIdx];
      const categoryColumnTitleList = generateValidTitles(categoryColumn.title);

      const columnDetail = COLUMN_DETAILS.find(({ field }) => field === categoryColumn.field);
      columnDetail?.searchTitleList?.forEach(searchTitle => {
        categoryColumnTitleList.push(...generateValidTitles(searchTitle));
      });

      for (let excelTitleIdx = 0; excelTitleIdx < excelTitleList.length; excelTitleIdx++) {
        const excelTitle = excelTitleList[excelTitleIdx];

        for (let categoryTitleIdx = 0; categoryTitleIdx < categoryColumnTitleList.length; categoryTitleIdx++) {
          const categoryColumnTitle = categoryColumnTitleList[categoryTitleIdx];
          if (checkMethod(excelTitle, categoryColumnTitle)) return categoryColumn;
        }
      }
    }

    return result;
  };

  let matchedColumn: T | undefined;

  // compare exactly
  matchedColumn = loopCheck((excelTitle, title) => excelTitle === title);
  // check [Excel title] start with [title]
  // matchedColumn = matchedColumn || loopCheck((excelTitle, title) => excelTitle.startsWith(title));
  // // check [Excel title] end with [title]
  // matchedColumn = matchedColumn || loopCheck((excelTitle, title) => excelTitle.endsWith(title));

  return matchedColumn;
};

export const findExcelHeaderRowIndex = (excelFileData: TExcelDataRow[]) => {
  let result = 0;
  let maxMatchedCount = 0;
  for (let index = 0; index < excelFileData.length; index++) {
    const rowData = excelFileData[index];
    const matchedCount = rowData.filter(title => getCategofyColumnByTitle(String(title), COLUMN_DETAILS)).length;

    // stop checking when 50% columns are title.
    if (matchedCount > rowData.length / 2) return index;

    if (matchedCount > maxMatchedCount) {
      maxMatchedCount = matchedCount;
      result = index;
    }
  }

  return result;
};

export const parseExcelDataToProduct = (params: {
  selectedCategoryTypeId: string;
  excelData: TExcelDataRow[];
  categoryColumnList: IImportColumn[];
  allCategories: IFoodlaCategoryOption[];
  rootCategory: IFoodlaCategoryOption;
}): TParseExcelDataToProductReturn => {
  const { selectedCategoryTypeId, excelData: excelDataProp, categoryColumnList, allCategories, rootCategory } = params;
  const excelData = excelDataProp.slice(findExcelHeaderRowIndex(excelDataProp));

  let excelTitleList = _.uniq(excelData[0] as string[]);
  const excelDataList = excelData.slice(1);
  const parsedValues: TParseExcelDataToProductReturn = { columnList: [], dataList: [] };

  if (!excelTitleList) return parsedValues;

  excelDataList.forEach((_, index) =>
    parsedValues.dataList.push({ id: `${index + 1}`, _index: index, _excelData: {}, _prasedExcelData: {} })
  );

  let leftCategoryColumnList = _.cloneDeep(categoryColumnList);
  excelTitleList.forEach((longTitle, columnIndex) => {
    const title = getShortTitle(longTitle);

    const categoryColumn = getCategofyColumnByTitle(longTitle, leftCategoryColumnList);
    if (categoryColumn) {
      leftCategoryColumnList = leftCategoryColumnList.filter(({ field }) => field !== categoryColumn.field);
    }

    const field = categoryColumn?.field;
    const isExtra = !!categoryColumn?.isExtra;
    const isMandatory = !!categoryColumn?.isMandatory;
    const defaultValue = categoryColumn?.defaultValue;

    if (field) {
      parsedValues.columnList.push({
        _id: uuid(),
        isMandatory,
        field,
        title,
        exportable: true,
        isExtra,
        isEnumType: checkEnumType(field),
        dataType: getDataType(field),
        defaultValue,
      });
    } else {
      parsedValues.columnList.push({
        _id: uuid(),
        isMandatory: false,
        field: '' as unknown as keyof IProduct,
        title,
        exportable: true,
        isExtra,
        isEnumType: false,
        dataType: 'string',
      });
    }

    excelDataList.forEach((row, rowIndex) => {
      if (field) {
        // has value when excel has duplicated columns
        const oldValue = parsedValues.dataList[rowIndex][field];
        let value = row[columnIndex] as never;
        // don't get new value when this field was filled
        if (oldValue || oldValue === 0) {
          value = oldValue as never;
        }
        if (!_.isNil(value) && getDataType(field) === 'string') {
          value = String(value) as never;
        }
        if (field === 'title' && !_.isNil(value)) {
          value = (value as string)
            .split(' ')
            .map(text => (text[0] || '').toUpperCase() + (text.slice(1) || '').toLowerCase())
            .join(' ') as never;
        }
        (parsedValues.dataList[rowIndex][field] as any) = !_.isNil(value) ? value : defaultValue;
        (parsedValues.dataList[rowIndex]._prasedExcelData[field] as any) = value;
      }
      parsedValues.dataList[rowIndex]._excelData[title] = row[columnIndex];
    });
  });

  // update brand_food and transform foodlaCategory
  const defaultCategory = getDefaultCategory({ selectedCategoryTypeId, allCategories });
  parsedValues.dataList.forEach(row => {
    const foodlaCategoryName = (row.foodlaCategory as unknown as string) || '';
    let foundCategory = allCategories.find(({ name }) => name.toLowerCase() === foodlaCategoryName.toLowerCase());
    if (!foundCategory) {
      foundCategory = defaultCategory;
    }
    if (foundCategory) {
      row.foodlaCategory = { id: foundCategory.id, name: foundCategory.name };
    } else {
      row.foodlaCategory = undefined;
    }

    if (!row.foodlaCategory || isNewNonFood(row.foodlaCategory, rootCategory)) return;
    row.brand_food = row.brand;
  });

  // extra columns
  categoryColumnList.forEach(({ isExtra, field, title, isMandatory, defaultValue }) => {
    const existingColumn = parsedValues.columnList.find(column => column.field === field);
    if (existingColumn || !isExtra) return;

    parsedValues.columnList.push({
      _id: uuid(),
      isMandatory,
      field,
      title: getShortTitle(title),
      exportable: false,
      isExtra,
      isEnumType: checkEnumType(field),
      dataType: getDataType(field),
      defaultValue,
    });
  });

  // correct orders
  parsedValues.columnList = correctColumnOrders(parsedValues.columnList);

  // search_terms
  const searchTermsField = 'search_terms';
  const isExcelHasSearchTerms = parsedValues.columnList.find(({ field }) => field === searchTermsField);
  const { title: searchTermsTitle } = COLUMN_DETAILS.find(({ field }) => field === searchTermsField) || { title: '' };
  // add search_terms column
  if (!isExcelHasSearchTerms && searchTermsTitle) {
    parsedValues.columnList.push({
      _id: uuid(),
      isMandatory: false,
      field: searchTermsField,
      title: searchTermsTitle,
      isEnumType: false,
      isMissing: true,
      dataType: getDataType(searchTermsField),
    });
  }
  // add search_terms value
  for (let index = 0; index < parsedValues.dataList.length; index++) {
    const mappedData = parsedValues.dataList[index];
    mappedData[searchTermsField] = getSearchTerms(mappedData);
  }

  // convert EAN to string
  for (let index = 0; index < parsedValues.dataList.length; index++) {
    const mappedData = parsedValues.dataList[index];
    mappedData.EAN = _.toString(mappedData.EAN).trim();
  }

  // EAN2
  const ean2Field = 'EAN2';
  const indexOfEAN = parsedValues.columnList.findIndex(({ field }) => field === 'EAN');
  if (indexOfEAN > -1) {
    const newColumn: IImportColumn = {
      _id: uuid(),
      isMandatory: false,
      field: ean2Field,
      title: 'EAN-kod (13 eller 8 siffror)',
      isEnumType: false,
      isMissing: true,
      dataType: getDataType(ean2Field),
    };

    // add EAN2 column
    parsedValues.columnList = insertColumnList(parsedValues.columnList, [newColumn], indexOfEAN + 1);

    // add EAN2 value
    for (let index = 0; index < parsedValues.dataList.length; index++) {
      const mappedData = parsedValues.dataList[index];
      const ean = _.toString(mappedData.EAN).trim();
      mappedData.EAN2 = getEAN2(ean) || undefined;
    }
  }

  // update default value
  parsedValues.dataList = parsedValues.dataList.map(dataItem => {
    const newDataItem = _.cloneDeep(dataItem);
    parsedValues.columnList.forEach(({ field, defaultValue }) => {
      if (_.isNil(newDataItem[field]) && !_.isNil(defaultValue)) {
        (newDataItem[field] as any) = defaultValue;
      }
    });
    return newDataItem;
  });

  // add [_parsedData]
  parsedValues.dataList = parsedValues.dataList.map(dataItem => {
    return { ...dataItem, _prasedData: dataItem };
  });

  // delete 'removed products'
  const removedProductIndexs = getRemovedProductIndexs();
  parsedValues.dataList = parsedValues.dataList.filter((_, index) => !removedProductIndexs.includes(index));

  // base items
  parsedValues.dataList = computeBaseItems(parsedValues.dataList);
  parsedValues.dataList = correctCorrespondingValues(parsedValues.dataList);

  return parsedValues;
};
