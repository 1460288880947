import InputTextField from "components/inputfields/InputTextField";
import IProducer from "models/producer";

interface IShortStoryProps {
  state?: IProducer;
  setState: (state: IProducer) => void;
}

const ShortStory = ({ state, setState }: IShortStoryProps) => {
  const handleChange = ({ target }: any) => setState({ ...state, shortStory: target.value });

  return (
    <InputTextField<IProducer>
      inputName="shortStory"
      // label="Description"
      value={state?.shortStory}
      onChange={handleChange}
      // required
      multiline
      multilineRows={4}
      multilineMax={10}
      withLabel={true}
      maxLength={277}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      labelOptions={{
        heading: 'Skylttext för A4 och A5 (max 277 tecken):',
      //   text: 'Rikta dig mot konsument då denna beskrivning användas för PR, marknadsföring och försäljning.',
      }}
    />
  );
};

export default ShortStory;
