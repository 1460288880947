import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export const NotFound = () => {
    return (
        <Card>
            <CardHeader title="Oops!" />
            <CardContent>
                <div>
                    <h2>We can't seem to find the page you're looking for.</h2>
                    <p>
                        <span>Error code: 404</span>
                    </p>
                    <Link variant="inherit" component={RouterLink} to="/">
                        Home
                    </Link>
                </div>
            </CardContent>
        </Card>
    );
};

export default NotFound;
