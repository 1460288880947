const mainBlack = '#000000';
const mainGreen = '#56aba9';
const mainOrange = '#e39c19';
const mainRed = '#cf5f57';

const mainGreenWithOpacity = (opacity: number) => `rgba(86, 171, 169, ${opacity})`;
const mainOrangeWithOpacity = (opacity: number) => `rgba(227, 156, 25, ${opacity})`;
const mainRedWithOpacity = (opacity: number) => `rgba(207, 95, 87, ${opacity})`;

export const PLAIN_COLORS = {
    mainBlack,
    mainGreen,
    mainOrange,
    mainRed,
};

const COLORS = {
    ...PLAIN_COLORS,
    mainGreenWithOpacity,
    mainOrangeWithOpacity,
    mainRedWithOpacity,
};

export default COLORS;
