import React from 'react';

import Divider from '@material-ui/core/Divider';

import { isDefined, toReadableCoordinate } from '../../../../utils/helpers';
import COLORS from '../../../../constants/colors';
import GoogleAutoComplete from '../../../maps/GoogleAutoComplete';
import IProduct from '../../../../models/product';

interface IAddressProps {
  isCreatingNew?: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  product?: IProduct;
}

interface ILatLng {
  lat?: number;
  lng?: number;
}

export const Address = ({ isCreatingNew, state, setState, product }: IAddressProps) => {
  const [addressError, setAddressError] = React.useState(false);
 
  const onSelectAddress = (locationInfo: string, latLng: ILatLng) => {
    setState({
      ...state,
      location: locationInfo,
      latitude: latLng.lat || state.latitude,
      longitude: latLng.lng || state.longitude,
    });
  };
  
  const onErrorLoadingAddressComponent = () => {
    setAddressError(true);
  };
  return (
    <>
      {!isCreatingNew && (
        <>
          <Divider />
          <h4 style={{ marginBottom: 2 }}>
            Current address is set to:{' '}
            <span style={{ fontWeight: 'normal' }}>
              {product?.location} (lat: {toReadableCoordinate(product?.latitude)}, lng:{' '}
              {toReadableCoordinate(product?.longitude)})
            </span>
          </h4>
          {state.location !== product?.location && (
            <h4
              style={{
                color: COLORS.mainGreen,
                marginTop: 2,
              }}
            >
              New address will be:{' '}
              <span
                style={{
                  fontWeight: 'normal',
                  color: 'black',
                }}
              >
                {state.location} (lat: {toReadableCoordinate(state?.latitude)}, lng:{' '}
                {toReadableCoordinate(state?.longitude)})
              </span>
            </h4>
          )}
        </>
      )}

      <GoogleAutoComplete
        // containerStyle={{
        //   marginBottom: 40,
        // }}
        onSelectAddress={onSelectAddress}
        currentAddress={isDefined(state.location) ? state.location : ''}
        showLatLngInfo={isCreatingNew}
        latitude={state.latitude}
        longitude={state.longitude}
        onFailureLoadingComponent={onErrorLoadingAddressComponent}
        withLabel
        labelOptions={{
          heading: 'Adress till producent',
          text: 'Denna information används för att matcha producenter med försäljningsomårde.',
          required: true,
        }}
      />

      {addressError && (
        <p>
          Could not load address selection. You can still upload product. Current address on product will still be
          used. Contact Foodla to correct this later.
        </p>
      )}
    </>
  );
};

export default Address;
