import { isNil, isEmpty } from 'ramda';

export const isNotDefined = v => isNil(v) || isEmpty(v);
export const isDefined = v => !isNil(v) && !isEmpty(v);
export const useOrElse = (v, av) => (isDefined(v) ? v : av);
export const toSafeNumber = (n, an, dec) => {
  if (isNotDefined(n) || isNaN(n)) return an;
  try {
    let ret = isDefined(dec) ? floatWithDec(n, dec) : parseInt(n);
    return ret;
  } catch (e) {
    return an;
  }
};

export const hasValue = (value) => {
  return isDefined(value) && (
    (typeof(value) === "string" && value.trim() !== "")
    || (typeof(value) === "number")
  );
};

export const toSafeFloat = (f, af) => {
  let val = undefined;
  if (f && typeof(f) === "string") {
    val = f.replace(",", ".");
  } else if (typeof(f) === "number") {
    val = f;
  }
  if (isNotDefined(val) || (typeof(val) === "string" && isNaN(val.replace(".", "")))) return af;
  try {
    return parseFloat(val);
  } catch (e) {
    return af;
  }
};

export const floatWithDec = (f, dec) => {
  let float = parseFloat(f);
  return parseFloat(float.toFixed(dec));
}

export const isNumber = (n) => !isNaN(n);
export const toReadableCoordinate = (c) => toSafeNumber(c, c, 3);

export const parseBoolean = str => {
  if(typeof str === 'string') {
    return str.toUpperCase() === "TRUE" ? true : str.toUpperCase() === "FALSE" ? false : undefined;
  } else if (typeof str === 'boolean') {
    return str;
  }
  return undefined;
}
