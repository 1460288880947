import { Checkbox, CheckboxProps, Radio } from '@material-ui/core';
import { StyledFormControlLabel } from '../styles';
import { FC } from 'react';

interface FormControlCheckboxProps extends CheckboxProps {
  type?: 'checkbox' | 'radio';
  label: string;
  disabled?: boolean;
}

const FormControlCheckbox: FC<FormControlCheckboxProps> = ({
  type = 'checkbox',
  label,
  disabled,
  ...checkboxProps
}) => {
  return (
    <StyledFormControlLabel
      label={label}
      disabled={disabled}
      control={
        type === 'checkbox' ? (
          <Checkbox size="small" color="primary" {...checkboxProps} />
        ) : (
          <Radio size="small" color="primary" {...checkboxProps} />
        )
      }
    />
  );
};

export default FormControlCheckbox;
