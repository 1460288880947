import { PRODUCT_TABS } from '../constants/domain';

export const getNumberOfPages = (
  activeTab: PRODUCT_TABS,
  itemsPending: number, 
  itemsApproved: number, 
  itemsDisapproved: number, 
  limit: number,
) => {
  switch(activeTab) {
    case PRODUCT_TABS.APPROVED: return Math.ceil(itemsApproved/limit);
    case PRODUCT_TABS.DISAPPROVED: return Math.ceil(itemsDisapproved/limit);
    default: return Math.ceil(itemsPending/limit);
  }
};
