import React, {useEffect, useMemo, useState} from 'react';

import InputLabel from './InputLabel';
import InputTextField from './InputTextField';
import { toSafeNumber } from 'utils/helpers';
import IProduct from 'models/product';
import useViewport from 'hooks/useViewports';

interface IInputLabelProps {
  heading?: string,
  text?: string,
  required?: boolean,
};

interface IInputProps {
  inputName: keyof IProduct,
  onChange: (value: number, name: string) => void,
  setIsChanged?: (value: boolean) => void;
  value?: number,
  defaultValue?: number,
  placeholder?: string,
};

interface ITemperatureFieldProps {
  inputLabelProps: IInputLabelProps,
  minValueProps: IInputProps,
  maxValueProps: IInputProps,
  setTempError?: (value: boolean) => void;
};

const getFormattedValue = (value?: number | string) => {
  if (typeof value === "number") {
    if (value !== undefined && value !== null) {
      return value < 0 ? value.toString() :  "+" + value.toString()
    }
  } else if (typeof value === "string") {
    return (value && !value.includes('+') && !value.includes('-')) ? '+' + value : value
  }

  return ""
};

export const TemperatureField = ({
  inputLabelProps,
  minValueProps,
  maxValueProps,
  setTempError,
}: ITemperatureFieldProps) => {
  const { smUp } = useViewport();

  const [minValue, setMinValue] = useState<string | undefined>(getFormattedValue(""));
  const [maxValue, setMaxValue] = useState<string | undefined>(getFormattedValue(""));

  const [error, setError] = useState<string | undefined>(undefined);
  const [minHighlight, setMinHighlight] = useState(false);
  const [maxHighlight, setMaxHighlight] = useState(false);

  const isError = useMemo(() => !!error, [error]);

  const handleInputChange = (
    { target: { value, name } }: React.ChangeEvent<HTMLInputElement>,
    inputProps: IInputProps,
    setValue:(value: string) => void,
  ) => {
    const trimmedValue = value.trim();
    const currentValue = trimmedValue ? trimmedValue.replace(/[^0-9.,+-]/g, "") : "";
    const formattedValue = toSafeNumber(currentValue, null);

    inputProps.setIsChanged && inputProps.setIsChanged(true);
    inputProps.onChange(formattedValue, name);
    setValue(getFormattedValue(currentValue));
  };

  useEffect(() => {
    setMinValue(getFormattedValue(minValueProps.value));
  }, [minValueProps?.value]);

  useEffect(() => {
    setMaxValue(getFormattedValue(maxValueProps?.value));
  }, [maxValueProps?.value]);

  useEffect(() => {
    const hasMinTemp = minValue !== "" && minValueProps.value !== undefined && minValueProps.value !== null;
    const hasMaxTemp = maxValue !== "" && maxValueProps.value !== undefined && maxValueProps.value !== null;

    if (hasMinTemp && hasMaxTemp && minValueProps.value !== undefined && maxValueProps.value !== undefined && minValueProps.value > maxValueProps.value) {
      setError('Högsta temperatur kan inte vara lägre än lägsta temperatur');
      setMinHighlight(true);
      setMaxHighlight(true);
    } else if (hasMinTemp && !hasMaxTemp) {
      setError('Fill required temperature fields');
      setMinHighlight(false);
      setMaxHighlight(true);
    } else if (!hasMinTemp && hasMaxTemp) {
      setError('Fill required temperature fields');
      setMinHighlight(true);
      setMaxHighlight(false);
    } else {
      setError(undefined);
      setMinHighlight(false);
      setMaxHighlight(false);
    }

  }, [minValue, maxValue, minValueProps.value, maxValueProps.value]);

  useEffect(() => {
    setTempError?.(isError);
  }, [isError, setTempError]);

  return (
    <>
      <InputLabel
        heading={inputLabelProps?.heading}
        text={inputLabelProps?.text}
        required={inputLabelProps?.required}
      />
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 10 }}>
        <div style={{ display: 'flex', flexDirection: smUp ? 'row' : 'column', flex: smUp ? undefined : 1 }}>
          <h3 style={{display: 'flex', alignItems: 'center', margin: '0 10px 0 0'}}>
            Min:
          </h3>
          <InputTextField<IProduct>
            forceRed={minHighlight}
            placeholder={minValueProps?.placeholder}
            inputName={minValueProps.inputName}
            value={minValue}
            style={{ maxWidth: smUp ? 150 : undefined }}
            onChange={(e) => handleInputChange(e, minValueProps, setMinValue)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: smUp ? 'row' : 'column', flex: smUp ? undefined : 1 }}>
          <h3 style={{display: 'flex', alignItems: 'center', margin: '0 10px 0 0'}}>
            Max:
          </h3>
          <InputTextField<IProduct>
            forceRed={maxHighlight}
            placeholder={maxValueProps?.placeholder}
            inputName={maxValueProps.inputName}
            value={maxValue}
            style={{ maxWidth: smUp ? 150 : undefined }}
            onChange={(e) => handleInputChange(e, maxValueProps, setMaxValue)}
          />
        </div>
      </div>
      {error && (
        <span style={{ fontWeight: 'bold', color: 'orangered' }}>
          {error}
        </span>
      )}
    </>
  );
};

export default TemperatureField;
