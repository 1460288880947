import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ProductGroupList from './ProductGroupList';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import gql from 'graphql-tag';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_SELECTED_EANS } from '../graphql/queries'
import {
    CircularProgress,
    Typography,
    Divider,
    Button,
} from '@material-ui/core';
import OrderToolbar from './OrderToolbar';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { transformCategoryRead } from 'utils/products';

const useStyles = makeStyles(theme => ({
    search: {
        justifyContent: 'center',
        display: 'flex',
    },
    noitem: {
        marginBottom: theme.spacing(10),
        textAlign: 'center',
    },
    noitemText: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    nextstep: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
    },
    close: {
        padding: theme.spacing(0.5),
    },
    toolbarBox: {
        flexGrow: 1,
    },
    toolbarButton: {
        marginLeft: theme.spacing(2),
    },
}));

const ADD_ITEM = gql`
    mutation addItem($item: String!, $isAdd: Boolean, $addFirst: Boolean) {
        addCartOrRemoveItem(item: $item, isAdd: $isAdd, addFirst: $addFirst) @client
    }
`;

/*
const GET_PRODUCT_BY_EAN = gql`
    query product($EAN: String!) {
        productByEAN(ean: $EAN) {
            id
            name
            EAN
        }
    }
`;
*/

// const PRODUCT_SEARCH = gql`
//     query product($string: String!) {
//         productByEANorSearchByText(string: $string) {
//             data {
//               id
//               name
//               EAN
//             }
//         }
//     }
// `;

const PRODUCT_SEARCH_WITH_PRODUCER = gql`
    query product($string: String!) {
        productByEANorSearchByTextOrByProducer(string: $string) {
            data {
              id
              name
              EAN
            }
        }
    }
`;

export default function SelectContent(props) {
    const { nextAction } = props;
    const classes = useStyles();
    // const [search, setSearch] = React.useState('');
    const [snackbar, setSnackbar] = React.useState({
        open: false,
        message: '',
    });
    const { data, loading: loadingCarts } = useQuery(GET_SELECTED_EANS);
    const [addCartOrRemoveItem, { loading: cartManageLoading }] = useMutation(
        ADD_ITEM,
        {
            refetchQueries: [{ query: GET_SELECTED_EANS }],
        }
    );
    // const [
    //     getProductByEan,
    //     {
    //         networkStatus,
    //         loading: findingProduct,
    //         data: productByEan,
    //         called,
    //         refetch,
    //     },
    // ] = useLazyQuery(GET_PRODUCT_BY_EAN, {
    //     notifyOnNetworkStatusChange: true,
    //     fetchPolicy: 'no-cache',
    //     onCompleted: eanData => {
    //         const { productByEAN } = eanData;
    //         if (productByEAN != null) {
    //             if (data.cartItems.includes(productByEAN.id)) {
    //                 setSnackbar({
    //                     open: true,
    //                     message:
    //                         productByEAN.EAN + ' has been added to the order',
    //                 });
    //             } else {
    //                 console.log("Found product")
    //                 addItem(productByEAN.id);
    //             }
    //         } else {
    //             setSnackbar({
    //                 open: true,
    //                 message: search + ' cannot be found',
    //             });
    //         }
    //     },
    // });

    // Rickard working on
    const tryToAddItemToOrder = (productByEAN) => {
      if (productByEAN != null) {
          if (data.cartItems.includes(productByEAN.id)) {
              setSnackbar({
                  open: true,
                  message:
                      'This product is already in your order',
              });
          } else {
              console.log("Found product")
              addItem(productByEAN.id, true);
              setSnackbar({
                  open: true,
                  message:
                      'Added product to your order!',
              });
          }
      } else {
          setSnackbar({
              open: true,
              message:'Your search cannot be found',
          });
      }
    }

    const [
        getProductByEANorSearchByTextOrByProducer,
        {
            // eslint-disable-next-line no-unused-vars
            networkStatus,
            // eslint-disable-next-line no-unused-vars
            loading: findingProduct,
            // eslint-disable-next-line no-unused-vars
            data: productsData,
            // eslint-disable-next-line no-unused-vars
            called,
            // eslint-disable-next-line no-unused-vars
            refetch,
        },
    ] = useLazyQuery(PRODUCT_SEARCH_WITH_PRODUCER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted: productsData => {
            console.log("productsData", productsData)
            const { data } = productsData.productByEANorSearchByTextOrByProducer;
            console.log("data", data)
            transformCategoryRead(data);
            return data;
            /*
            if (data != null) {
                return data;
                // if (data.cartItems.includes(productByEAN.id)) {
                //     setSnackbar({
                //         open: true,
                //         message:
                //             productByEAN.EAN + ' has been added to the order',
                //     });
                // } else {
                //     console.log("Found product")
                //     addItem(productByEAN.id);
                // }
            } else {
                // setSnackbar({
                //     open: true,
                //     message: search + ' cannot be found',
                // });
            }
            */
        },
    });


    const review = (list)=> {
        if(Array.isArray(list) && list.length) {
            return nextAction(list)
        }
        setSnackbar({open:true, message: 'No items is selected'})
    }

    const addItem = (item, addFirst) => {
        addCartOrRemoveItem({ variables: { item: item, isAdd: true, addFirst: addFirst } });
    };

    const remoteItemAction = item => {
        addCartOrRemoveItem({ variables: { item: item, isAdd: false } });
    };

    const searchAction = value => {
        if (value == null || value === '') {
            // setSearch('');
            setSnackbar({ open: true, message: 'EAN code cannot be empty' });
        } else {
            // setSearch(value);
            getProductByEANorSearchByTextOrByProducer({
              variables: {
                string: value,
              },
            });

            // getProductByEan({
            //     variables: {
            //         EAN: value,
            //     },
            // });
        }
    };

    const handleSnackbarClose = event => {
        setSnackbar({ open: false, message: '' });
    };

    //if (loadingCarts || findingProduct || cartManageLoading)
    // if (loadingCarts || cartManageLoading)
    //     return <CircularProgress />;

    return (
        <>
            <OrderToolbar
                searchAction={searchAction}
                tryToAddItemToOrder={tryToAddItemToOrder}
                selectedItems={data?.cartItems}
                loading={cartManageLoading}
            >
                <Button
                    onClick={() => review(data.cartItems)}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    startIcon={<NavigateNextIcon />}
                >
                    Review
                </Button>
            </OrderToolbar>
            {(loadingCarts || cartManageLoading) ? (
                <CircularProgress />) :(
                !data || !data.cartItems || data.cartItems.length === 0 ? (
                    <Box className={classes.noitem}>
                        <Divider light />
                        <Typography
                            variant="body2"
                            gutterBottom
                            className={classes.noitemText}
                        >
                            {'No item selected'}
                        </Typography>
                    </Box>
                ) : (
                    <ProductGroupList
                        list={data.cartItems}
                        saveOrder={true}
                        removeAction={remoteItemAction}
                        editable={true}
                    />
                )
            )}


            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbar.open}
                onClose={handleSnackbarClose}
                autoHideDuration={3000}
                message={snackbar.message}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={handleSnackbarClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}

SelectContent.defaultProps = {
    list: [],
    nextAction: () => {},
};
