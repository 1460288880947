import { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';

import InputTextField from "components/inputfields/InputTextField";
import IProduct from "models/product";
import FloatInput from "../FloatInput";
import { isDefined } from "utils/helpers-ts";
import InputLabel from "components/inputfields/InputLabel";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

interface IPriceProps {
  state: IProduct;
  setState: (state: IProduct) => void;
}

const isEmpty = (product?: IProduct) => {
  if (!product) return true;
  if (
    (product.priceListPriceCurrency && product.priceListPriceCurrency.trim() !== '')
    || isDefined(product.priceListPrice)
    || isDefined(product.priceRecommendedConsumerPrice)
  ) {
    return false;
  }
  return true;
};

const Price = ({ state, setState }: IPriceProps) => {
  const classes = useStyles();
  const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    setExpanded(!isEmpty(state));
  }, []);
  const handleAccordionChange = (event: any, isExpanded?: boolean) => {
    setExpanded(!!isExpanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>Price</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          <InputTextField
            state={state}
            setState={setState}
            inputName="priceListPriceCurrency"
            value={state.priceListPriceCurrency}
            label="List price currency"
            onChange={handleChange}
            withLabel
          />
          <InputLabel 
            heading="List price"
            text="Retailer agreed purchase price for the item from the supplier excluding possible discounts"
          />
          <FloatInput
            name="priceListPrice"
            // label="List price"
            withLabel={false}
            state={state}
            setState={setState}
            dense={false}
          />
          <FloatInput
            name="priceRecommendedConsumerPrice"
            label="Recommended consumer price(Retail price Large)"
            state={state}
            setState={setState}
            dense={false}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Price;
