import React from 'react';

import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Mutation } from 'react-apollo';

import SnackBar from '../../components/snackbar/SnackBar';
import OrganizationForm from '../../components/superuser/EditOrganizationForm';
import { MUTATION_SUPERUSER_ORGANIZATION_INPUT } from '../../graphql/mutations';
import { SUPERUSER_ORGANIZATIONS_QUERY } from '../../graphql/queries';


const CreateOrganization = () => {
    const [state, setState] = React.useState({
        name: '',
        groupIdentifier: '',
        invoiceAddress: '',
        deliveryAddress: '',
        contactInfo: '',
    });

    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    function submitCreateOrganization(createOrganization) {
        createOrganization({
            variables: {
                input: {
                    name: state.name,
                    groupIdentifier: state.groupIdentifier,
                    invoiceAddress: state.invoiceAddress,
                    deliveryAddress: state.deliveryAddress,
                    contactInfo: state.contactInfo,
                },
            },
        });
    }

    return (
        <Mutation
            mutation={MUTATION_SUPERUSER_ORGANIZATION_INPUT}
            refetchQueries={[
                {
                    query: SUPERUSER_ORGANIZATIONS_QUERY,
                },
            ]}
        >
            {(createOrganization, { data, loading, error }) => {
                const errorMessage = 'Failed to update product...';
                let feedback = <></>;
                if (loading) feedback = <CircularProgress />;
                if (data && !loading) {
                    const responseMessage =
                        data?.createOrganization?.message || errorMessage;
                    feedback = <SnackBar message={responseMessage} />;
                }
                return (
                    <>
                        {!loading && (
                            <CardContent>
                                <OrganizationForm
                                    handleOnSubmit={() =>
                                        submitCreateOrganization(
                                            createOrganization
                                        )
                                    }
                                    inputName={state.name}
                                    inputGroupIdentifier={state.groupIdentifier}
                                    inputInvoiceAddress={state.invoiceAddress}
                                    inputDeliveryAddress={state.deliveryAddress}
                                    inputContactInfo={state.contactInfo}
                                    handleChange={handleChange}
                                    isCreatingNew
                                />
                                {feedback}
                            </CardContent>
                        )}
                    </>
                );
            }}
        </Mutation>
    );
};

export default CreateOrganization;
