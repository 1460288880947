import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import '../assets/fileuploader.css';
import { gql } from 'apollo-boost';
import { ApolloConsumer, Mutation } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const MUTATION_ADD_PRODUCER = gql`
    mutation ADD_PRODUCER($input: ProducerInput!) {
        createProducer(input: $input) {
            name
        }
    }
`;

const useStyles = makeStyles(theme => ({
    breadcrumb: {
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

function PreviewFileUploader(props) {
    const [files, setFiles] = React.useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: true,
        onDrop: acceptedFiles => {
            acceptedFiles = [
                ...files,
                ...acceptedFiles.filter(
                    e1 => !files.some(e2 => e1.name === e2.name)
                ),
            ];

            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="preview" />
            </div>
        </div>
    ));

    React.useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <span>
                    Drag 'n' drop some files here, or click to select files
                </span>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
        </section>
    );
}

const AddProducerBreadCrumbs = () => {
    const classes = useStyles();
    return (
        <Breadcrumbs
            className={classes.breadcrumb}
            separator="›"
            aria-label="Breadcrumb"
        >
            <Link color="inherit" component={RouterLink} to="/">
                Home
            </Link>
            <Link color="inherit" component={RouterLink} to="/producers">
                Producers
            </Link>
            <Typography color="textPrimary">Add Producer</Typography>
        </Breadcrumbs>
    );
};

const SnackBar = props => {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();

    const handleClose = event => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={1200}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Failed to create new producer</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    );
};

const AddProducerForm = ({ createProducer }) => {
    const classes = useStyles();
    const [state, setState] = React.useState({ name: '', desc: '' });

    const handleSubmit = event => {
        event.preventDefault();
        createProducer({
            variables: {
                input: {
                    username: 'admin',
                    name: state.name,
                    email: '',
                    description: state.desc,
                    latitude: 1.0,
                    longitude: 1.0,
                    pictures: [
                        'http://h1.ioliu.cn/bing/DubaiFountain_ZH-CN7944507087_1920x1080.jpg',
                    ],
                },
            },
        });
    };

    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    return (
        <>
            <AddProducerBreadCrumbs />
            <Paper>
                <Card>
                    <CardContent>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                autoFocus
                                value={state.name}
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={4}
                                name="desc"
                                label="Description"
                                id="desc"
                                value={state.desc}
                                onChange={handleChange}
                            />
                            <PreviewFileUploader />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Submit
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Paper>
        </>
    );
};

const AddProducer = props => {
    return (
        <ApolloConsumer>
            {client => (
                <Mutation mutation={MUTATION_ADD_PRODUCER}>
                    {(createProducer, { loading, error, data }) => {
                        let feedback;
                        if (loading) feedback = <CircularProgress />;
                        if (error) feedback = <SnackBar />;
                        if (data) console.log(data);

                        return (
                            <>
                                <AddProducerForm
                                    createProducer={createProducer}
                                />
                                {feedback}
                            </>
                        );
                    }}
                </Mutation>
            )}
        </ApolloConsumer>
    );
};

export default AddProducer;
