// Dependencies
import React, {ChangeEvent} from 'react';
import Pagination from '@material-ui/lab/Pagination';

// Components
import ListDropDown from './inputfields/ListDropDown';

// Constants
import { PAGINATION } from '../constants/domain';
import useViewport from 'hooks/useViewports';

// Utils
const createSortingTypeListData = (type: string) => {
    const { SORTING_TYPES: { NONE, APPROVED, CATEGORY, DATE, NAME, TITLE, DISTANCE }} = PAGINATION;
    if (type === 'Product') {
        return [
            { value: NONE, displayName: 'None' },
            { value: CATEGORY, displayName: 'Category' },
            { value: DATE, displayName: 'Created' },
            { value: TITLE, displayName: 'Name' },
            { value: DISTANCE, displayName: 'Distance' },
        ];
    }
    if (type === 'Producer') {
        const arr = [
            { value: NONE, displayName: 'None' },
        ];
        arr.push({
            value: APPROVED, displayName: 'Approved products'
        })
        arr.push(
            { value: DATE, displayName: 'Created' },
            { value: NAME, displayName: 'Name' },
            { value: DISTANCE, displayName: 'Distance' },
        );
        return arr;
    }
    return [];
};

const createSortOrderListData = (sortBy: string | null) => {
    const { SORT_ORDERS: { ASC, DESC }, SORTING_TYPES: { NONE, APPROVED, CATEGORY, DATE, NAME, DISTANCE }} = PAGINATION;
    let ascDisplayName;
    let descDisplayName;

    switch(sortBy) {
        case NONE: break;
        case NAME:
        case CATEGORY: {
            ascDisplayName = 'A -> Ö';
            descDisplayName = 'Ö -> A';
            break;
        }
        case DATE: {
            ascDisplayName = 'Oldest first';
            descDisplayName = 'Newest first';
            break;
        }
        case DISTANCE: {
            ascDisplayName = 'Nearest';
            descDisplayName = 'Furthest';
            break;
        }
        case APPROVED: {
            // ascDisplayName = '0 -> 9';
            // descDisplayName = '9 -> 0';
            break;
        }
        default: break;
    }

    return [
        { value: ASC, displayName: ascDisplayName },
        { value: DESC, displayName: descDisplayName }
    ]
};

interface IPagePaginationProps {
    onPageChange: (event: ChangeEvent<unknown>, page: number) => void;
    page: number;
    count: number;
    limit: number;
    onLimitChanged: (limit: number) => void;
    limitStep?: number;
    showSortingOptions?: boolean;
    sorting?: string | null;
    sortOrder?: string | null;
    onSortChanged?: (sort: string, val: string) => void;
    type?: string;
    disablePagination?: boolean;
    extraLimitList?: number[];
}

// Component
const PagePagination = ({
    onPageChange,
    page,
    count,
    limit,
    onLimitChanged,
    limitStep = 12,
    showSortingOptions,
    disablePagination,
    sorting = PAGINATION.SORTING_TYPES.NONE,
    sortOrder,
    onSortChanged,
    type = 'Product',
    extraLimitList = [],
}: IPagePaginationProps) => {
    const { smUp, lgUp } = useViewport();

    const limitList = new Array(10).fill(0).map((_, index) =>(index + 1) * limitStep);
    const limitListData = [...limitList, ...extraLimitList].map((value) =>( { value }));
    const sortingListData = createSortingTypeListData(type);
    const sortOrderListData = createSortOrderListData(sorting);

    const onLimitValueChanged = (event: React.ChangeEvent<any>) => {
        onLimitChanged(event.target.value);
    };

    const onSortingChanged = (event: React.ChangeEvent<any>) => {
        onSortChanged && onSortChanged('sorting', event.target.value);
    };

    const onOrderChanged = (event: React.ChangeEvent<any>) => {
        onSortChanged && onSortChanged('order', event.target.value);
    };

    let pageCount = Math.ceil(count/limit);
    if (!pageCount) {
        pageCount = 1;
    }

    let justifyContentStyle = disablePagination ? 'flex-start' : 'space-between';
    if (!lgUp) {
      justifyContentStyle = 'flex-start'
    }

    const renderSortBy = () => {
      return (
        <ListDropDown
          label="Sort by"
          value={sorting}
          listData={sortingListData}
          handleChange={onSortingChanged}
          targetName="sorting"
          size={lgUp ? 'medium' : 'small'}
          style={{ width: lgUp ? 150 : '100%', flex: 1 }}
          formControlDownStyle={{ marginBottom: 0 }}
        />
      );
    }

    const renderSortOrder = () => {
      if (sorting !== 'none' && sorting !== 'approved') {
        return (
          <ListDropDown
            label="Sort order"
            value={sortOrder}
            listData={sortOrderListData}
            handleChange={onOrderChanged}
            targetName="sortOrder"
            size={lgUp ? 'medium' : 'small'}
            style={{ width: lgUp ? 150 : '100%', flex: 1 }}
            formControlDownStyle={{ marginBottom: 0 }}
          />
        );
      }

      return <div style={{ flex: 0.4 }} />;
    }

    const renderPagination = () => {
      return (
        <Pagination
          count={pageCount}
          variant="outlined"
          color="primary"
          size={lgUp ? 'medium' : 'small'}
          onChange={onPageChange}
          page={page > 0 ? page : 1}
        />
      );
    }

    const renderPaginationLimit = () => {
      return (
        <ListDropDown
          label="Products/page"
          value={limit}
          listData={limitListData}
          handleChange={onLimitValueChanged}
          targetName="limit"
          size={lgUp ? 'medium' : 'small'}
          style={{ width: smUp ? 150 : '100%' }}
          formControlDownStyle={{ marginBottom: 0 }}
        />
      );
    }

    return (
        <div
          style={{
            display: 'flex',
            flexDirection: lgUp ? 'row' : 'column',
            justifyContent: justifyContentStyle,
            alignItems: 'center',
            padding: 20,
            gap: 20
          }}
        >
          {showSortingOptions ? (
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: smUp ? 'row' : 'column',
              flex: disablePagination ? 0.5 : 1,
              gap: 20,
              width: '100%'
            }}>
              {renderSortBy()}
              {renderSortOrder()}
            </div>
          ) : lgUp && !showSortingOptions && !disablePagination ? (
            <div style={{flex: 1}} />
          ) : null}

          {!disablePagination && (
            <div style={{
              flex: 3,
              display: 'flex',
              flexDirection: smUp ? 'row' : 'column',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 20,
              width: '100%'
            }}>
              <div style={{ display: 'flex', justifyContent: 'center', flex: 2, width: '100%' }}>
                {renderPagination()}
              </div>
              <div style={{ flex: lgUp ? 1 : 0, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                {renderPaginationLimit()}
              </div>
            </div>
          )}
        </div>
    );
};

// Export
export default PagePagination;
