import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress  from '@material-ui/core/LinearProgress';
import Typography  from '@material-ui/core/Typography';
import VisionTab from '../tab';
// import VisionInfo from '../info';
import { fixFloat } from '../utils';

const Labels = ({visionTab, image, info, isNew }) => {
  return (
    <VisionTab
      index="labels"
      tabValue={visionTab}
      image={image}
      info={info}
      showLabels={false}
      pictureWidth={8}
      isNew={isNew}
    >
        {(!info?.labelAnnotations || info?.labelAnnotations.length === 0) && (
          <Typography>No labels detected.</Typography>
        )}
        <List>
          {info?.labelAnnotations?.map((item) => (
            <ListItem>
              <div style={{width: '100%'}}>
                <Typography>{item?.description}<span style={{float: 'right'}}>{fixFloat(item?.score)}%</span></Typography>
                <LinearProgress variant="determinate" value={item?.score * 100} color="primary" />
              </div>
            </ListItem>
              // <VisionInfo title="Description" field="description" item={item} />
              // <VisionInfo title="Score" field="score" item={item} />
              // {item?.locale && item?.locale?.trim() !== "" && (
              // <p><b>Locale:</b> {item.locale}</p>
              // )}
              // {/* <VisionInfo title="Confidence" field="confidence" item={item} />
              // <VisionInfo title="Topicality" field="topicality" item={item} /> */}
              // {/* {item?.boundingPoly?.normalizedVertices?.map((coord) => (
              // <p> (<b>x:</b> {coord?.x}, <b>y:</b> {coord?.y})</p>
              // ))} */}
              // <br />
          ))}
      </List>
    </VisionTab>
  );
};

export default Labels;
