import React, { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import debounce from "lodash/debounce";

import TabPanel from './tab-panel';
import { getPictureUrl } from './utils';
import useViewport from 'hooks/useViewports';

// const colorThief = new ColorThief();

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState();
  const ref = useCallback(node => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node !== undefined) {
      let request;
      const measure = () => {
        request = window.requestAnimationFrame(() => {
          const rect = node.getBoundingClientRect();
          setDimensions({
            width: rect.width,
            height: rect.height,
            dpr: window.devicePixelRatio || 1
          });
        });
      };
      measure();

      const resizeListener = debounce(measure, 100);
      window.addEventListener("resize", resizeListener);

      return () => {
        window.removeEventListener("resize", resizeListener);
        window.cancelAnimationFrame(request);
      };
    }
  }, [node]);

  return [ref, dimensions];
};

const convertImageWidth = async ({width, height, canvasWidth, canvasHeight}) => {
  // let { width, height } = await getDimensions(src);
  let x = 0;
  let y = 0;
  const initWidth = width;
  const initHeight = height;
  let w = width;
  let h = height;
  if (initWidth > canvasWidth) {
    w = canvasWidth;
    h = initHeight * (canvasWidth / initWidth);
  }
  if (canvasWidth > initWidth) {
    x = (canvasWidth - initWidth) / 2;
  }
  return { x, y, width: w, height: h, initWidth, initHeight };
};

// const loadImage = async (src) => {
//   return new Promise((resolve, reject) => {
//     let img = new Image();
//     img.onload = () => resolve(img);
//     img.onerror = reject;
//     img.src = src
//   });
// };

const Canvas = ({ vertices, imageSrc, normalizedVertices, tabValue }) => {
  // the screen dimensions are needed to size the canvas correctly
  const [ref, { width, height }] = useDimensions();
  // we use a ref to access the canvas' DOM node
  const canvasRef = React.createRef();
  const rgb = [255, 0, 0];

  React.useEffect(() => {
    const context = canvasRef?.current?.getContext("2d");
    if (context && imageSrc && imageSrc.trim() !== "") {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        convertImageWidth({
          width: image.width,
          height: image.height,
          canvasWidth: context.canvas.width,
          canvasHeight: context.canvas.height,
        }).then(({ x: initX, y: initY, width: w, height: h, initWidth, initHeight }) => {
          context.canvas.height = h;
          context.canvas.width = w;
          context.drawImage(image, initX, initY, w, h);

          if (vertices && vertices.length > 0) {
            context.beginPath();
            const newWidth = normalizedVertices ? w : w / initWidth;
            const newHeight = normalizedVertices ? h : h / initHeight;
            console.log('wh', initX, initY, w, h, initWidth, initHeight);
            vertices.forEach((vertex) => {
              if (vertex.coord && vertex.coord.length > 0) {
                context.moveTo(
                  initX + vertex.coord[0].x * newWidth,
                  initY + vertex.coord[0].y * newHeight,
                );
                vertex.coord.forEach((c, index) => {
                  if (index > 0) {
                    context.lineTo(
                      initX + c.x * newWidth,
                      initY + c.y * newHeight,
                    );
                  }
                });
                context.closePath();
                context.strokeStyle = `rgb(${rgb.join(",")})`;
                context.stroke();
              }
            });
          }

        });
      };
    }
  }, [canvasRef, width, height, tabValue, imageSrc]);

  return (
    <main ref={ref} style={{width: '100%'}}>
      <canvas ref={canvasRef} height={height} width={width} />
    </main>
  ); 
};

const VisionTab = ({
  index,
  tabValue,
  hideImage,
  image,
  vertices,
  showLabels,
  children,
  pictureWidth,
  normalizedVertices,
  info,
  isNew,
}) => {
  const { mdUp } = useViewport();

  let imageWidth = 0;
  let infoWidth = 0;
  let pictureUrl;
  if (hideImage) {
    imageWidth = 0;
  } else {
    if (isNew) {
      if (image) {
        pictureUrl = image;
      }
    } else {
      pictureUrl = getPictureUrl(image);
    }
    if (pictureUrl) {
      if (pictureWidth) {
        imageWidth = pictureWidth;
      } else {
        imageWidth = 6;
      }
    }
  }
  infoWidth = 12 - imageWidth;
  if (!mdUp) {
    infoWidth = 12;
    imageWidth = 12;
  }
  return (
    <TabPanel value={tabValue} index={index}>
      <Grid container={true} spacing={2}>
        {info && !info.error && imageWidth > 0 && (
          <Grid item={true} xs={imageWidth}>
            <Paper elevation={2} style={{padding: 5}}>
              <Canvas
                tabValue={tabValue}
                imageSrc={pictureUrl}
                vertices={vertices}
                normalizedVertices={normalizedVertices}
              />
            </Paper>
          </Grid>
        )}
        {info && !info.error && (
          <Grid item={true} xs={infoWidth}>
            {children}
          </Grid>
        )}
      </Grid>
    </TabPanel>
    );
  };

  export default VisionTab;
