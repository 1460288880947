import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import ImageWithFallback from '../ImageWithFallback';
import useViewport from 'hooks/useViewports';

const styles = theme => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'auto',
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  downloadText: {
    fontSize: 13,
    fontWeight: 'bolder',
  },
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    padding: 20,
  },
});

const HorizontalImageList = ({ classes, imageUrls, height, withActionButtons, onClickActionButton }) => {
  const { mdUp } = useViewport();

  let imageStyles = { maxHeight: height, height: '100%', maxWidth: '100%' };
  if (mdUp) {
    imageStyles = { height: height };
  }

  return (
    <div className={mdUp ? classes.list : classes.listColumn}>
      {imageUrls.map(i => (
        <ImageWithFallback key={i} src={i}>
          {src => {
            return (
              <div className={classes.container}>
                <img style={imageStyles} src={src} alt="" />
                {withActionButtons && (
                  <div className={classes.buttonContainer}>
                    <Button variant="outlined" color="primary" onClick={() => onClickActionButton(src)}>
                      <span className={classes.downloadText}>Download</span>
                    </Button>
                  </div>
                )}
              </div>
            );
          }}
        </ImageWithFallback>
      ))}
    </div>
  );
};

export default withStyles(styles)(HorizontalImageList);
