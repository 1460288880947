import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { KeycloakContext } from 'components/Secured';
import { getUserRole, isStore } from 'utils/userrole';

const GoogleAnalyticsTracker = () => {
  const history = useHistory();
  const keycloakContext = useContext(KeycloakContext); // Access the Keycloak instance from context
  const keycloak = keycloakContext.keycloak;
  const role = getUserRole(keycloakContext);

  // useEffect(() => {
  //   ReactGA.send('pageview');
    
  //   const unlisten = history.listen(() => {
  //     ReactGA.send('pageview');
  //   });

  //   return () => {
  //     unlisten();
  //   };
  // }, [history]);

  useEffect(() => {
    ReactGA.gtag('set', 'user_id', keycloak.tokenParsed?.sub);
  }, [keycloak.tokenParsed?.sub]);
  useEffect(() => {
    console.log('!!!set user properties');
    // console.log('!!!keycloak.tokenParsed', keycloak.tokenParsed);
    // ReactGA.gtag('set', {
    //   user_id: keycloak.tokenParsed?.sub,
    //   user_properties: {
    //     username: keycloak.tokenParsed?.preferred_username,
    //     usertype: role,
    //     isstore: isStore(role),
    //   },
    // });
    ReactGA.gtag('set', 'user_properties', {
      username: keycloak.tokenParsed?.preferred_username,
      usertype: role,
      isstore: isStore(role),
    });
  }, [keycloak.tokenParsed?.preferred_username, role]);

  return null;
};

export default GoogleAnalyticsTracker;
