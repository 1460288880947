import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core';

import { STATUS_COLORS } from 'utils/excelUtils-old';

const useCustomStyles = makeStyles(() => ({
  uploadErrorIconWrapper: {
    textAlign: 'center',
    color: 'white',
    lineHeight: 1,
    cursor: 'pointer',
  },
  uploadErrorName: {
    margin: 0,
    padding: '4px 8px',
    background: STATUS_COLORS.ERROR,
  },
  uploadErrorContent: {
    marginTop: 4,
    color: 'white',
    fontSize: 12,
    borderRadius: 4,
  },
}));

interface UploadErrorIconProps {
  errors: {
    label: string;
    message: string;
  }[];
}

const UploadErrorIcon = ({ errors }: UploadErrorIconProps) => {
  const customClasses = useCustomStyles();

  if (!errors.length) return null;

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <div>
          {errors
            .filter(({ label, message }) => !!label && !!message)
            .map(({ label, message }, index) => (
              <div key={index} style={{ padding: '2px 0' }}>
                <p className={customClasses.uploadErrorName}>{label}</p>
                <p className={customClasses.uploadErrorContent}>{message}</p>
              </div>
            ))}
        </div>
      }
    >
      <div className={customClasses.uploadErrorIconWrapper}>
        <ErrorOutlineIcon />
      </div>
    </Tooltip>
  );
};

export default UploadErrorIcon;
