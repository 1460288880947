import { Box, IconButton } from '@material-ui/core';

import useControlContext from '../hooks/useControlContext';

import ArrowLeftIcon from 'components/icons/ArrowLeftIcon';

const Header = () => {
  const { step, onStepChange } = useControlContext();

  return (
    <Box display="flex" alignItems="center" gridGap={16}>
      <IconButton style={{ padding: 4, marginTop: 18, marginBottom: 'auto' }} onClick={() => onStepChange(step - 1)}>
        <ArrowLeftIcon />
      </IconButton>

      <Box flexGrow={1} flexShrink={0} maxWidth="calc(100% - 50px)">
        <Box mb={1} sx={{ fontSize: 10, color: '#737DA0', fontWeight: 600 }}>
          Steg 2 av 4
        </Box>
        <Box display="flex" justifyItems="center" gridGap={8} mb={1} sx={{ fontSize: 20, lineHeight: 1 }}>
          Import filen
        </Box>

        <Box sx={{ fontSize: 16, mb: 2 }}>
          Importera datafilen enligt instruktionerna för respektive produkttyper nedan. Du får ladda ner en mall som är
          specifik till din valda kategori för att se vilken information är relevant.
        </Box>
        <Box sx={{ fontSize: 16 }}>Du ska lägga till och ladda upp bilder till dina produkter i nästa steg.</Box>
      </Box>
    </Box>
  );
};

export default Header;
