import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

import VirtualScrollbar from 'components/VirtualScrollbar';
import ReviewHeader from './ReviewHeader';
import ReviewTable from './ReivewTable';
import ReviewExportModal from './ReviewExportModal';
import UploadImageButton from './UploadImageButton';
import UploadProductButton from './UploadProductButton';

import { useBulkImportContext } from '../BulkImportContext';
import useProductData from 'hooks/useProductData';
import SnackBar from 'components/snackbar/SnackBar';
import { NON_FOOD_CATEGORY_ID } from 'components/constants-ts';

import { IImportColumn, IProductExcel, ImportExcelHelper, addMissingColumns, isErrorCell } from 'utils/excelUtils-old';
import { ALLOWED_IMAGE_FILE_TYPE_LIST, filterAllowedImages, revertProduct } from '../bulkImportUtils';
import { isNonFoodProduct, transformCategoryRead } from 'utils/products';

const useStyles = makeStyles(() => ({
  fixedFooterWrapper: {
    display: 'flex',
    gap: 16,
    height: 0,
    position: 'fixed',
    padding: '8px 16px',
    zIndex: 20,
    bottom: 0,
    background: '#fafafa',
  },
  dropzoneBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    borderWidth: 0,
    color: '#FFFFFF',
  },
  backdrop: {
    position: 'fixed',
    zIndex: 2000,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    color: 'white',
  },
}));

interface DropzoneProductImageProps {
  isReviewing: boolean;
}

const DropzoneProductImage: FC<DropzoneProductImageProps> = ({ isReviewing }) => {
  const classes = useStyles();
  const { editingImageProductId, onProductImageChange } = useBulkImportContext();

  const [draggingCount, setDraggingCount] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop: (files: File[]) => {
      const acceptedImages = filterAllowedImages(files);
      setDraggingCount(0);
      onProductImageChange(acceptedImages);
    },
    onDragLeave: () => setDraggingCount(0),
  });

  useEffect(() => {
    const handleDragEnter = (event: DragEvent) => {
      if (!event.dataTransfer?.items?.length) return;
      const { items } = event.dataTransfer;
      let count = 0;
      for (let index = 0; index < items.length; index++) {
        const { type } = items[index];
        if (ALLOWED_IMAGE_FILE_TYPE_LIST.includes(type)) count++;
      }

      if (count) {
        setDraggingCount(count);
      }
    };
    const handleDragLeave = () => setDraggingCount(0);

    if (!editingImageProductId && !isReviewing) {
      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragend', handleDragLeave);
    }
    return () => {
      document.removeEventListener('dragenter', handleDragEnter);
      document.removeEventListener('dragend', handleDragLeave);
    };
  }, [editingImageProductId, isReviewing]);

  return (
    <div
      {...getRootProps({ className: classes.dropzoneBackground })}
      style={{
        opacity: draggingCount ? 1 : 0,
        zIndex: draggingCount ? 2000 : -1,
        background: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <input {...getInputProps()} />
      {!!draggingCount && (
        <Box className="dropzone" bgcolor="transparent" height="100%" justifyContent="center">
          <Typography variant="h5" color="inherit">
            {draggingCount} {draggingCount > 1 ? 'images' : 'image'}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const Review = () => {
  const classes = useStyles();
  const { isLoadingProductsByEans, getProductsByEans } = useProductData();

  const { dataList, excelColumnList, productType, isConvertingImages, productImageDataSet, setDataList } =
    useBulkImportContext();

  const keepRef = useRef({ loadedSavedProduct: false });

  const [fixedHeadWrapper, setFixedHeadWrapper] = useState<HTMLDivElement | null>(null);
  const [fixedFoodWrapper, setFixedFoodWrapper] = useState<HTMLDivElement | null>(null);
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null);
  const [isReviewing, setReviewing] = useState(false);
  const [showErrorOnly, setShowErrorOnly] = useState(false);
  const [uploadedProductCount, setUploadedProductCount] = useState(0);

  const filteredDataList = useMemo(() => {
    if (!productType) return [];
    return dataList.filter((product) => {
      if (productType === NON_FOOD_CATEGORY_ID) {
        return isNonFoodProduct(product);
      } else {
        return !isNonFoodProduct(product);
      }
    });
  }, [dataList, productType]);

  const mappedExcelColumnList = useMemo<IImportColumn[]>(() => {
    const excelHelper = new ImportExcelHelper(excelColumnList, filteredDataList);
    excelHelper.init();

    const mappedColumnList = excelHelper.columnList.map(column => {
      const excelComlumnHelper = excelHelper.getColumnHelper(column);
      const mappedColumn = excelComlumnHelper.mapColumn();

      let field = mappedColumn.field;
      if (productType !== NON_FOOD_CATEGORY_ID && field === 'brand') {
        field = 'brand_food';
      }

      return { ...mappedColumn, field };
    });

    return addMissingColumns({ productType, columnList: mappedColumnList, dataList: filteredDataList });
  }, [productType, filteredDataList, excelColumnList]);

  const filteredColumnList = useMemo(() => {
    if (!filteredDataList.length) return mappedExcelColumnList;

    const existedFieldSet = new Set();
    return mappedExcelColumnList.filter(({ field, isMandatory, isNutritionalFood, isEmptyColumn }) => {
      // remove duplicate column by [field]
      if (existedFieldSet.has(field)) return false;
      if (field) existedFieldSet.add(field);

      if (isMandatory || !isEmptyColumn) return true;
      if (productType !== NON_FOOD_CATEGORY_ID && isNutritionalFood) return true;
      return false;
    });
  }, [productType, filteredDataList, mappedExcelColumnList]);

  const errorDataList = useMemo(() => {
    return filteredDataList.filter(data => {
      const imageFileDataList = productImageDataSet[String(data.id || '')] || [];
      return !imageFileDataList.length || filteredColumnList.some(column => isErrorCell(column, data));
    });
  }, [filteredDataList, filteredColumnList, productImageDataSet]);

  const getSavedProductList = useCallback(() => {
    keepRef.current.loadedSavedProduct = true;
    const eans = dataList.map(({ EAN }) => _.toString(EAN)).filter(EAN => EAN);
    getProductsByEans(eans).then(savedProducts => {
      // Merge saved product to dataList
      setDataList(
        dataList.map(data => {
          const savedProduct = savedProducts.find(({ EAN }) => EAN === data.EAN);
          if (!savedProduct) return data;
          transformCategoryRead(savedProduct);
          const revertedSavedProduct = revertProduct(savedProduct);
          const notNilData = _.omitBy<IProductExcel>(data, _.isNil);
          // [_.merge] will mutates [revertedSavedProduct]. Should cloneDeep [revertedSavedProduct] it first
          const mergedData = _.merge(_.cloneDeep(revertedSavedProduct), notNilData) as IProductExcel;
          mergedData._savedData = savedProduct;
          mergedData._revertedSavedData = revertedSavedProduct;
          return _.omitBy<IProductExcel>(mergedData, _.isNil) as IProductExcel;
        })
      );
    });
  }, [dataList, setDataList, getProductsByEans]);

  useEffect(() => {
    const container = fixedFoodWrapper?.parentElement;
    if (!container || !fixedFoodWrapper) return;
    fixedFoodWrapper.style.height = 'auto';

    const observer = new ResizeObserver(() => (fixedFoodWrapper.style.width = `${container.offsetWidth}px`));
    observer.observe(container);
    return () => observer.disconnect();
  }, [fixedFoodWrapper]);

  useEffect(() => {
    if (dataList.length && !keepRef.current.loadedSavedProduct) {
      getSavedProductList();
    }
  }, [dataList, getSavedProductList]);

  return (
    <Box position="relative">
      <div ref={setFixedHeadWrapper} style={{ top: '64px', height: 0 }} />

      <ReviewHeader
        showErrorOnly={showErrorOnly}
        errorCount={errorDataList.length}
        onChangeShowErrorOnly={setShowErrorOnly}
        onDownload={() => setReviewing(true)}
      />

      <VirtualScrollbar targetElement={scrollElement} bottom={52}>
        <ReviewTable
          showErrorOnly={showErrorOnly}
          errorDataList={errorDataList}
          dataList={filteredDataList}
          columnList={filteredColumnList}
          setScrollElement={setScrollElement}
          fixedHeadWrapper={fixedHeadWrapper}
        />
      </VirtualScrollbar>

      {isReviewing && <ReviewExportModal open={isReviewing} onClose={() => setReviewing(false)} />}

      <div ref={setFixedFoodWrapper} className={classes.fixedFooterWrapper}>
        <UploadImageButton />
        <UploadProductButton
          onUploaded={({ count }) => {
            setUploadedProductCount(count);
            getSavedProductList();
          }}
        />
      </div>

      <DropzoneProductImage isReviewing={isReviewing} />

      {(isConvertingImages || isLoadingProductsByEans) && (
        <div className={classes.backdrop}>
          <CircularProgress size={24} />
          <Typography variant="body1" color="inherit">
            {isConvertingImages && 'Converting...'}
            {isLoadingProductsByEans && 'Checking...'}
          </Typography>
        </div>
      )}

      {!!uploadedProductCount && (
        <SnackBar
          message={`Successfully Uploaded ${uploadedProductCount} items`}
          duration={3000}
          onClose={() => setUploadedProductCount(0)}
        />
      )}
    </Box>
  );
};

export default Review;
