import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import ListDropDown from "components/inputfields/ListDropDown";

import {
  complianceMetalsSkinContact,
  confirmGuidanceSwedishElectronics,
  complianceToyDirective,
  complianceBiocid,
  complianceBiocidalTreated,
} from '../../../constants-ts';


import IProduct from "models/product";
import { IFoodlaCategoryOption } from "models/category";
import {
  isChemical,
  isDetergent,
  isElectric,
  isGoods,
  isJewelry,
  isMedical,
  isToy
} from "./category";

interface IAdditionalGroupFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory: IFoodlaCategoryOption;
};

const AdditionalGroupFields = ({ state, setState, rootCategory}: IAdditionalGroupFieldsDataProps) => {
  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      const val = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: val });
  };

  return (
    <div style={{width: '100%'}}>
      {isJewelry(state?.foodlaCategory, rootCategory) && (
        <>
          <InputLabel
            heading="Uppfyller produkten reglerna om skadliga tungmetaller (t.ex. nickel, kadmium eller bly) i bilaga XVII till Reach-förordningen?"
            text="T.ex. smycken, glasögon, klockor, accessoarer, underkläder etc. Eftersom smycken bärs nära huden och ofta är gjorda av flera olika material som kan innehålla skadliga ämnen, finns det en risk att ämnen som är farliga för människor kan komma in i kroppen."
            link="https://www.kemi.se/lagar-och-regler/regler-for-nagra-produktgrupper/smycken---kort-om-regler"
            required
          />
          <ListDropDown
            disableLabel={true}
            listData={complianceMetalsSkinContact}
            value={state?.complianceMetalsSkinContact}
            targetName="complianceMetalsSkinContact"
            handleChange={handleDropdownChange}
            displayEmpty={true}
          />
        </>
      )}
      {(isToy(state?.foodlaCategory, rootCategory) ||
        isElectric(state?.foodlaCategory, rootCategory) ||
        isMedical(state?.foodlaCategory, rootCategory)) && (
          <>
            <InputLabel
              heading="Om produkten är elektrisk/elektronisk, finns det svensk bruksanvisning bifogad på förpackningen?"
              text="Alla elektriska produkter som säljs i Sverige måste ha säkerhetsinformation och bruksanvisning på svenska."
              link="https://www.elsakerhetsverket.se/fragor-och-svar/arkiv-fragor/Svensk-bruksanvisning/"
              required
            />
            <ListDropDown
              disableLabel={true}
              listData={confirmGuidanceSwedishElectronics}
              value={state?.confirmGuidanceSwedishElectronics}
              targetName="confirmGuidanceSwedishElectronics"
              handleChange={handleDropdownChange}
              displayEmpty={true}
            />
          </>
      )}
      {(isToy(state?.foodlaCategory, rootCategory) ||
        isElectric(state?.foodlaCategory, rootCategory)) && (
          <>
            <InputLabel
              heading="Uppfyller produkten som är en leksak kraven i leksaksdirektivet (EG) 2009/48?"
              text="Leksaksdirektivet 2009/48/EG är ett omfattande regelverk för leksaker som gäller i hela EU och handlar om leksakers säkerhet och innehåller bland annat regler om kemiskt innehåll, brännbarhet, elsäkerhet, dokumentation och märkning."
              link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/leksaker---leksaksdirektivet/kort-om-leksaksreglerna---leksaksdirektivet"
              required
            />
            <ListDropDown
              disableLabel={true}
              listData={complianceToyDirective}
              value={state?.complianceToyDirective}
              targetName="complianceToyDirective"
              handleChange={handleDropdownChange}
              displayEmpty={true}
            />
          </>
      )}
      {(isToy(state?.foodlaCategory, rootCategory) ||
        isChemical(state?.foodlaCategory, rootCategory) ||
        isDetergent(state?.foodlaCategory, rootCategory)) && (
          <>
            <InputLabel
              heading="Uppfyller varan som är en biocidprodukt kraven i EUs biocidförordning 528/2012?"
              text="Förordning (EU) 528/2012 avser utsläppande på marknaden och användning av biocidprodukter som används för att skydda människor, djur, material och varor mot skadliga organismer, som skadedjur och bakterier, genom de verksamma ämnena i biocidprodukten."
              link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/regler-for-bekampningsmedel/biocider/regler-for-biocider"
              required
            />
            <ListDropDown
              disableLabel={true}
              listData={complianceBiocid}
              value={state?.complianceBiocid}
              targetName="complianceBiocid"
              handleChange={handleDropdownChange}
              displayEmpty={true}
            />
          </>
      )}
      {(isToy(state?.foodlaCategory, rootCategory) ||
        isElectric(state?.foodlaCategory, rootCategory) ||
        isJewelry(state?.foodlaCategory, rootCategory) ||
        isGoods(state?.foodlaCategory, rootCategory)) && (
          <>
            <InputLabel
              heading="Uppfyller varan som är biocidbehandlad kraven i EUs biocidförordning 528/2012?"
              text="En biocidbehandlad vara är en vara som är behandlad med en typ av bekämpningsmedel, en så kallad biocidprodukt. Syftet med behandlingen är att ge varan en funktion, till exempel att göra en sporttröja luktfri eller en skärbräda antibakteriell."
              link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/regler-for-bekampningsmedel/biocider/biocidbehandlade-varor"
              required={isToy(state?.foodlaCategory, rootCategory)}
            />
            <ListDropDown
              disableLabel={true}
              listData={complianceBiocidalTreated}
              value={state?.complianceBiocidalTreated}
              targetName="complianceBiocidalTreated"
              handleChange={handleDropdownChange}
              displayEmpty={true}
            />
          </>
      )}
      
    </div>
  );
}

export default AdditionalGroupFields;
