import Excel from 'exceljs';
import * as dateFns from 'date-fns';
import _ from 'lodash';

import { TExcelDataRow } from 'models/excel';
import { PRODUCT_DATE_FIELD } from 'components/constants-ts';
import { COLUMN_DETAILS, EXCEL_ERROR_VALUES, VALID_DATE_FORMATS } from '../../constants';
import { loadExcelFile } from './loadExcelFile';
import { findExcelHeaderRowIndex, getShortTitle } from './parseExcelDataToProduct';

const parseDateString = (valueParam: string | number) => {
  let validDate: Date | undefined;
  let value = '';
  if (typeof valueParam === 'number') {
    const date = new Date(0, 0, valueParam - 1, 0, -new Date(0).getTimezoneOffset(), 0);
    value = dateFns.format(date, 'yyyy-MM-dd');
  } else {
    value = valueParam;
  }

  VALID_DATE_FORMATS.forEach(format => {
    if (validDate) return;

    const parsedDate = dateFns.parse(value, format, new Date());
    if (!dateFns.isValid(parsedDate)) return;

    validDate = parsedDate;
  });

  return validDate;
};

const removeSpecialCharactors = (value: string) => {
  const specialCharactorCodes = [8203, 8204, 8205, 8206, 8207];
  if (typeof value !== 'string') return value;

  return value
    .split('')
    .map(character => (specialCharactorCodes.includes(character.charCodeAt(0)) ? '' : character))
    .join('');
};

export const readExcelFile = async (file: File, sheetIndex = 0): Promise<TExcelDataRow[]> => {
  const workbook = await loadExcelFile(file);

  if (!workbook) return [];

  const workSheet = workbook.worksheets[sheetIndex];
  const excelData: TExcelDataRow[] = [];

  const productDateHeaders = COLUMN_DETAILS.filter(({ field }) => PRODUCT_DATE_FIELD.includes(field)).map(
    ({ title }) => title
  );

  const rowValues: TExcelDataRow[] = [];
  workSheet.eachRow(row => rowValues.push(row.values as unknown as TExcelDataRow));
  const headerRowIndex = findExcelHeaderRowIndex(rowValues);

  workSheet.eachRow((row, rowNumber) => {
    const headers = excelData[headerRowIndex] || [];

    const _cells = (row as any)._cells;
    const values = row.values as unknown as Excel.CellValue[];
    const rowData: TExcelDataRow = [];
    for (let colNumber = 1; colNumber < values?.length; colNumber++) {
      const value = values[colNumber] as any;

      const excelTitle = getShortTitle(String(headers[colNumber - 1] || ''));
      const numFmt = _.toString(_cells[colNumber - 1]?.numFmt);
      const isPercent = rowNumber !== 1 && numFmt.endsWith('%');
      const isDate = rowNumber !== 1 && productDateHeaders.includes(excelTitle);
      const isLink = value?.hyperlink;

      if (_.isNil(value)) {
        rowData.push(null);
      } else if (isLink) {
        rowData.push(value.text || null);
      } else if (isDate || value instanceof Date) {
        rowData.push(parseDateString(value) || value);
      } else if (isPercent) {
        if (typeof value === 'number') {
          rowData.push(`${Number(value) * 100}%`);
        } else {
          rowData.push(value);
        }
      } else if (typeof value !== 'object') {
        rowData.push(removeSpecialCharactors(value as string));
      } else if (!_.isNil(value.result)) {
        rowData.push(value.result);
      } else if (value.richText?.length) {
        const newValue = value.richText.map(({ text }: { text: string }) => text).join('');
        rowData.push(removeSpecialCharactors(newValue as string));
      } else {
        console.error(`!!!Error Unknown cell value ${headers[colNumber - 1]} (${typeof value}): ${value}`);
        rowData.push(null);
      }
    }
    excelData.push(rowData);
  });

  const result: TExcelDataRow[] = [];

  for (let rowIndex = 0; rowIndex < excelData.length; rowIndex++) {
    // remove empty row
    if (!excelData[rowIndex]?.length) continue;
    const row = [...excelData[rowIndex]];

    // fill null to 'error' cell
    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      if (EXCEL_ERROR_VALUES.includes(String(row[colIndex]))) {
        row[colIndex] = null;
      }
    }

    // check valid row
    if (row.some(cell => cell !== null && String(cell).trim() !== '')) {
      result.push(row);
    }
  }

  return result;
};
