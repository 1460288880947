import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { getSelectedMenuItemTitle } from '../utils/permissions';
import { KeycloakContext } from '../components/Secured';

interface INavItem {
    link: string;
    title: string;
    component: any; // TODO any
    icon: any; // TODO any
}

interface INavMenuProps {
    navItems?: INavItem[];
}

const NavMenu = ({ navItems }: INavMenuProps) => {
    const keycloakCtx = useContext(KeycloakContext);
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    const isSuperuser = keycloakCtx.keycloak.hasResourceRole('superuser');

    // Get path info to be able to decide selected navigation menu.
    let location = useLocation();
    const selectedMenuItemTitle = getSelectedMenuItemTitle(location?.pathname || '', isStore, isSuperuser);
    
    return (
        <List>
            {Array.isArray(navItems) && navItems.length ? (
                navItems.map(item => {
                    return (
                        <ListItem
                            button
                            component={Link}
                            to={item.link}
                            key={item.title}
                            selected={selectedMenuItemTitle === item.title}
                        >
                            <ListItemIcon>
                                {React.createElement(item.icon)}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    );
                })
            ) : (
                <span>No menu found</span>
            )}
        </List>
    );
};

export default NavMenu;
