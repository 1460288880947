import { useMediaQuery } from '@material-ui/core';

const useViewport = (customSize?: number) => {
  const checkingReady = useMediaQuery('(min-width:10px)');

  const customUp = useMediaQuery(`(min-width:${customSize || 600}px)`);
  const smUp = useMediaQuery('(min-width:600px)');
  const mdUp = useMediaQuery('(min-width:900px)');
  const lgUp = useMediaQuery('(min-width:1200px)');

  return { ready: checkingReady, customUp, smUp, mdUp, lgUp };
};

export default useViewport;
