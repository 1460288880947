import { FC } from 'react';

import { Box } from '@material-ui/core';

import { StyledFormControlLabel, StyledSwitch } from '../styles';

interface ErrorSwitchProps {
  errorCount: number;
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ErrorSwitch: FC<ErrorSwitchProps> = ({ errorCount, label, checked, onChange }) => {
  return (
    <>
      <Box sx={{ color: '#D32F2F', fontWeight: 500, fontSize: 14 }}>
        {errorCount} kolumner matchades inte automatiskt
      </Box>
      <StyledFormControlLabel
        control={<StyledSwitch color="primary" checked={checked} onChange={event => onChange(event.target.checked)} />}
        label={label}
      />
    </>
  );
};

export default ErrorSwitch;
