import { FC, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { useBulkImportContext } from '../BulkImportContext';
import VirtualScrollbar from 'components/VirtualScrollbar';
import ReviewTable from './ReivewTable';
import { ImportExcelHelper, addMissingColumns, writeExcelFile } from 'utils/excelUtils-old';

interface ReviewExportModalProps {
  open: boolean;
  onClose: () => void;
}

const ReviewExportModal: FC<ReviewExportModalProps> = ({ open, onClose }) => {
  const { productType, limit, excelFile, dataList, excelColumnList } = useBulkImportContext();

  const [fixedHeadWrapper, setFixedHeadWrapper] = useState<HTMLDivElement | null>(null);
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null);
  const [isExporting, setExporting] = useState(false);

  const exportColumnList = useMemo(() => {
    const excelHelper = new ImportExcelHelper(excelColumnList, dataList);
    excelHelper.init();

    const columnList = excelHelper.columnList
      .filter(({ exportable }) => exportable)
      .map(column => excelHelper.getColumnHelper(column).mapColumn());

    return addMissingColumns({ productType, columnList, dataList });
  }, [productType, excelColumnList, dataList]);

  const handleDownload = async () => {
    if (!excelFile) return;
    setExporting(true);
    const splitedName = excelFile.name.split('.');
    splitedName.pop();
    const fileName = splitedName.join('.');
    await writeExcelFile(exportColumnList, dataList, excelFile, {
      fileName: `${fileName} - Foodla import check`,
    });
    setExporting(false);
    onClose();
  };

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <Box display="flex" alignItems="center" justifyContent="space-between" px={3} py={2}>
        <Typography variant="h6">Excel download preview</Typography>
        <IconButton size="small" disabled={isExporting} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <div ref={setFixedHeadWrapper} style={{ top: '64px', height: 0 }} />

        {isExporting ? (
          <Box display="flex" justifyContent="center" py={10}>
            Exporting...
          </Box>
        ) : (
          <VirtualScrollbar targetElement={scrollElement} bottom={-8}>
            <ReviewTable
              isReview
              dataList={dataList}
              columnList={exportColumnList}
              defaultLimit={limit}
              setScrollElement={setScrollElement}
              fixedHeadWrapper={fixedHeadWrapper}
            />
          </VirtualScrollbar>
        )}
      </DialogContent>
      <Box display="flex" alignItems="center" justifyContent="end" px={3} py={2}>
        <Button variant="contained" color="primary" size="small" disabled={isExporting} onClick={handleDownload}>
          Download
        </Button>
      </Box>
    </Dialog>
  );
};

export default ReviewExportModal;
