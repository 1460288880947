import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import IProducer from "models/producer";
import { useEffect, useState } from "react";

const ORGANIZATIONS = [
  "coop", 
  "ica", 
  "apotea",
  "mathem",
];

interface IProducerOrganizationsProps {
  state?: IProducer;
  setState: (state?: IProducer) => void;
}

const ProducerOrganizations = ({ state, setState }: IProducerOrganizationsProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target;
    if (state) {
      let newOrganizations = state?.organizations;
      if (!newOrganizations) newOrganizations = ["coop"];
      if (checked) {
        if (!newOrganizations.includes(name)) newOrganizations.push(name);
      } else {
        newOrganizations = newOrganizations.filter((organization) => organization !== name);
      }
      setState({ ...state, organizations: newOrganizations });
    }
  };
  const isOrganizationChecked = (organization: string) => {
    if (state) {
      if (!state.organizations) return organization === "coop";
      if (state.organizations.length === 0) return false;
      return state.organizations.includes(organization);
    }
  };
  return (
    <>
      {ORGANIZATIONS.map((organization) => (
        // <p>{organization}</p>
        <FormControlLabel
          control={
            <Switch
              checked={isOrganizationChecked(organization)}
              onChange={onChange}
              name={organization}
              color="primary"
            />
          }
          label={organization}
        />
      ))}
      {/* <FormControlLabel
        control={
          <Switch
            checked={showOnlyWithHistory}
            onChange={() => setShowOnlyWithHistory(!showOnlyWithHistory)}
            name="showOnlyWithHistory"
            color="primary"
          />
        }
        label={!showOnlyWithHistory ? 'Show only with export history' : 'Show without export history'}
      /> */}
    </>
  );
};

export default ProducerOrganizations;
