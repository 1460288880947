import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

interface IStripeInvoice {
  id: string;
  amount_due?: number;
  currency?: string;
  status?: 'draft' | 'open' | 'void' | 'paid';
  number?: string;
  due_date?: number;
  created?: number;
}

interface IGetUnpaidInvoices {
  unpaidInvoices?: IStripeInvoice[];
}

const LIMIT_UNPAID_STRIPE_INVOICES = 3;

export const GET_PAST_DUE_STRIPE_INVOICES = gql`
  query getUnpaidInvoices($limit: Int) {
    unpaidInvoices(limit: $limit) {
      id
      amount_due
      currency
      status
      number
      due_date
      created
    }
  }
`;

function useUnpaidInvoiceData() {
  const { data, loading, error } = useQuery<IGetUnpaidInvoices>(GET_PAST_DUE_STRIPE_INVOICES, {
    variables: { limit: LIMIT_UNPAID_STRIPE_INVOICES },
  });

  const invoiceList = useMemo<IStripeInvoice[]>(() => {
    if (loading || error) return [];
    return data?.unpaidInvoices || [];
  }, [data, loading, error]);

  return { list: invoiceList, error, loading };
}

export default useUnpaidInvoiceData;
