import React from 'react';
import NavMenu from './NavMenu';
import withRoleNavItems from './withRoleNavItems';

const withMenu = WrappedComponent => {
    return props => {
        const Menu = withRoleNavItems(props.role)(NavMenu);
        return <WrappedComponent menu={Menu} {...props} />
    }
};

export default withMenu;
