import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Check from '@material-ui/icons/Check';

const QontoConnector = withStyles({
    vertical: {
        alignItems: 'left',
        flexDirection: 'row',
        marginLeft: 3,
    },
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles(theme => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: theme.palette.primary.main,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 12,
    },
}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 2,
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export default props => {
    const classes = useStyles();
    const { steps, status, error } = props;
    let active = steps.findIndex(s => s === status);
    return (
        <Stepper
            classes={{ root: classes.root }}
            connector={<QontoConnector />}
            activeStep={active}
            orientation="vertical"
        >
            {steps.map((step, index) => {
                const labelProps = {};
                if (index === active && error === true) {
                    labelProps.error = true;
                }
                return (
                    <Step key={step}>
                        <StepLabel
                            StepIconComponent={QontoStepIcon}
                            {...labelProps}
                        >
                            {step}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};
