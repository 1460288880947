import React, { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from "@material-ui/core/Divider";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";
import IProduct from "models/product";
import { IFoodlaCategoryOption } from "models/category";
import { APPLICATION_ROLES } from "components/constants-ts";
import {
  isChemical,
  isComplementarySupplements,
  isCosmetic,
  isDetergent,
  isElectric,
  isFeedCategory,
  isFoodSupplements,
  isGoods,
  isJewelry,
  isMedical,
  isNewFood,
  isOTC,
  isSunscreen,
  isToy
} from "./category";

interface IQualityReviewFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory: IFoodlaCategoryOption;
  userRole?: string;
};

const useStyles = makeStyles({
  label: {
    color: "#000",
    lineHeight: '24px',

    '&.Mui-focused': {
      color: "#000",
    },
  },
  divider: {
    marginBottom: "10px"
  }
});

const labelStyles = {
  margin: 0,
}

const QualityReviewFields = ({ state, setState, rootCategory, userRole }: IQualityReviewFieldsDataProps) => {
  const isSuperuser = userRole === APPLICATION_ROLES.SUPERUSER;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  const isBestBeforeDateRequired = isComplementarySupplements(state?.foodlaCategory, rootCategory);

  const isNotStore = !state?.producerIsStore;

  const showQrRelevantFeedCertifications = isFeedCategory(state?.foodlaCategory, rootCategory);
  const showQrFoodHygiene = isNotStore && isNewFood(state?.foodlaCategory, rootCategory);
  const showQrElectronic = (isToy(state?.foodlaCategory, rootCategory) ||
    isElectric(state?.foodlaCategory, rootCategory) ||
    isMedical(state?.foodlaCategory, rootCategory));
  const showQrClassificationLabelingPackaging = (isToy(state?.foodlaCategory, rootCategory) ||
  isChemical(state?.foodlaCategory, rootCategory) ||
  isDetergent(state?.foodlaCategory, rootCategory));
  const showQrCosmeticOrSunscreen = (isCosmetic(state?.foodlaCategory, rootCategory) ||
  isSunscreen(state?.foodlaCategory, rootCategory));
  const showQrAdditivesInFeedOrFeedLabeling = isFeedCategory(state?.foodlaCategory, rootCategory);
  const showQrMisleadingSalesTerms = isNotStore && (isFeedCategory(state?.foodlaCategory, rootCategory) || isNewFood(state?.foodlaCategory, rootCategory));
  const showQrNutritionClaims = isNotStore && isNewFood(state?.foodlaCategory, rootCategory);
  const showQrEfsaUlValues = isNotStore && isFoodSupplements(state?.foodlaCategory, rootCategory);
  const showHeader = showQrRelevantFeedCertifications || showQrFoodHygiene || showQrElectronic 
  || showQrClassificationLabelingPackaging || showQrCosmeticOrSunscreen
  || showQrAdditivesInFeedOrFeedLabeling || showQrMisleadingSalesTerms || showQrNutritionClaims 
  || showQrEfsaUlValues;
  // console.log('!!!state', state);
  return (
    <div style={{width: '100%'}}>
      {(isGoods(state?.foodlaCategory, rootCategory) ||
        isJewelry(state?.foodlaCategory, rootCategory) ||
        isToy(state?.foodlaCategory, rootCategory) ||
        isElectric(state?.foodlaCategory, rootCategory) ||
        isMedical(state?.foodlaCategory, rootCategory)) &&
        (
          <>
            <FormControl component="fieldset" style={{width: '100%'}}>
              <InputLabel 
                heading="Är produkten CE-märkt?"
                text="Läs mer om CE-märkningen."
                link="https://www.konsumentverket.se/for-foretag/produktsakerhet/ce-markning/"
                labelStyles={labelStyles}
                required
              />
              <RadioGroup
                value={state?.ceMarking}
                name="ceMarking"
                style={{ flexDirection: "row", gridGap: 16 }}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Ja"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Nej"
                />
                <FormControlLabel
                  value={null}
                  control={<Radio color="primary" />}
                  label="Ej valt"
                />
              </RadioGroup>
            </FormControl>
            {state?.ceMarking && (
              <>
                <InputLabel
                  heading="Ange mot vilka standarder/krav"
                  text="Här anger du vilken standard eller vilka krav din CE-märkning gäller för."
                  link="https://www.sis.se/standarder/ce-markning/"
                  required
                />
                <InputTextField
                  state={state}
                  setState={setState}
                  inputName="specifyCEStandards"
                  value={state.specifyCEStandards}
                  placeholder="SS-EN 71-8:2018 Leksaker - Säkerhetsregler - Del 8: Aktivitetsleksaker för privat bruk"
                  onChange={handleChange}
                />
              </>
            )}
          </>

        )
      }
      {isNotStore && (
      <FormControl component="fieldset">
        <InputLabel 
          heading="Levereras produkten i plasttråg?"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.plasticTrayPackaging}
          name="plasticTrayPackaging"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
          />
        </RadioGroup>
      </FormControl>
      )}
      {isNotStore && (
        <FormControl component="fieldset" style={{width: "100%"}}>
          <InputLabel 
            heading="Har produkten ett bäst före datum?"
            labelStyles={labelStyles}
            required={isBestBeforeDateRequired}
          />
          <RadioGroup
            value={state?.bestBeforeDate}
            name="bestBeforeDate"
            onChange={handleRadioChange}
            style={{ flexDirection: "row", gridGap: 16 }}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
      )}

      {(isNewFood(state?.foodlaCategory, rootCategory) ||
        isToy(state?.foodlaCategory, rootCategory) ||
        isElectric(state?.foodlaCategory, rootCategory) ||
        isJewelry(state?.foodlaCategory, rootCategory) ||
        isGoods(state?.foodlaCategory, rootCategory) ||
        isChemical(state?.foodlaCategory, rootCategory) ||
        isDetergent(state?.foodlaCategory, rootCategory) ||
        isCosmetic(state?.foodlaCategory, rootCategory) ||
        isSunscreen(state?.foodlaCategory, rootCategory) ||
        isOTC(state?.foodlaCategory, rootCategory)) && isNotStore &&
        (
          <FormControl component="fieldset" style={{width: "100%"}}>
            <InputLabel
              heading="Är produkten avsedd för barn under 16 år?"
            />
            <RadioGroup
              value={state?.childrenUnder16}
              name="childrenUnder16"
              onChange={handleRadioChange}
              style={{ flexDirection: "row", gridGap: 16 }}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Nej"
              />
              <FormControlLabel
                value={null}
                control={<Radio color="primary" />}
                label="Ej valt"
              />
            </RadioGroup>
          </FormControl>
        )
      }
      {isSuperuser && showHeader &&(
        <>
          <h3>Ytterligare kvalitetsfrågor för granskaren</h3>
          <Divider classes={{ root: classes.divider }} />
        </>
      )}
      {isSuperuser && showQrRelevantFeedCertifications && (
        <>
        <FormControl component="fieldset" style={{width: '100%', marginTop: '7px'}}>
          <FormLabel
            required
            classes={{ root: classes.label }}
          > 
            Är fodret och/eller emballaget märkt med certifiering inom hållbarhet, t. ex MSC och/eller FSC?
          </FormLabel>
          <RadioGroup
            value={state?.qrRelevantFeedCertifications}
            name="qrRelevantFeedCertifications"
            style={{ flexDirection: "row", gridGap: 16 }}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
        </>
      )}
      {isSuperuser && showQrFoodHygiene && (
        <FormControl component="fieldset" style={{width: '100%', marginTop: '7px'}}>
          {/* {setShowQrHeader(true)} */}
          <FormLabel
            required
            classes={{ root: classes.label }}
          > 
            Är livsmedlet i enlighet med Förordning <a target="_blank" rel="noopener noreferrer" href="https://eur-lex.europa.eu/legal-content/SV/TXT/HTML/?uri=CELEX:02004R0852-20210324&from=EN">(EG) 852/2004</a> om livsmedelshygien?
          </FormLabel>
          <RadioGroup
            value={state?.qrFoodHygiene}
            name="qrFoodHygiene"
            style={{ flexDirection: "row", gridGap: 16 }}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
      )}
      {isSuperuser && showQrElectronic &&
        <FormControl component="fieldset" style={{width: '100%'}}>
          {/* {setShowQrHeader(true)} */}
          <FormLabel
            required
            classes={{ root: classes.label }}
          > 
            I det fall varan är en elektrisk eller elektronisk produkt, uppfyller den kraven i <a target="_blank" rel="noopener noreferrer" href="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/elektrisk-och-elektronisk-utrustning---rohs">RoHS-direktivet EU/2011/65</a>?
          </FormLabel>
          <RadioGroup
            value={state?.qrElectronic}
            name="qrElectronic"
            style={{ flexDirection: "row", gridGap: 16 }}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
      }
      {isSuperuser && showQrClassificationLabelingPackaging &&
        <FormControl component="fieldset" style={{width: '100%'}}>
          {/* {setShowQrHeader(true)} */}
          <FormLabel
            required
            classes={{ root: classes.label }}
          > 
            Uppfyller produkten kraven om klassicifering, märkning och förpackning enligt CLP-förordningen <a target="_blank" rel="noopener noreferrer" href="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/clp-forordningen">(EG) 1272/2008</a>
          </FormLabel>
          <RadioGroup
            value={state?.qrClassificationLabelingPackaging}
            name="qrClassificationLabelingPackaging"
            style={{ flexDirection: "row", gridGap: 16 }}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
      }
      {isSuperuser && showQrCosmeticOrSunscreen &&
        (
          <>
            <FormControl component="fieldset" style={{width: '100%'}}>
              {/* {setShowQrHeader(true)} */}
              <FormLabel
                required
                classes={{ root: classes.label }}
              > 
                Uppfyller märkningen kraven i den svenska <a target="_blank" rel="noopener noreferrer" href="https://www.riksdagen.se/sv/dokument-och-lagar/dokument/svensk-forfattningssamling/forordning-2013413-om-kosmetiska-produkter_sfs-2013-413/"> förordningen 2013:413</a> (inklusive kraven på svensk märkning)?
              </FormLabel>
              <RadioGroup
                value={state?.qrLabelingSwedish}
                name="qrLabelingSwedish"
                style={{ flexDirection: "row", gridGap: 16 }}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Ja"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Nej"
                />
                <FormControlLabel
                  value={null}
                  control={<Radio color="primary" />}
                  label="Ej valt"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" style={{width: '100%'}}>
              <FormLabel
                required
                classes={{ root: classes.label }}
              > 
                Är förpackning och marknadsföring fri från <a target="_blank" rel="noopener noreferrer" href="https://www.lakemedelsverket.se/sv/kosmetika/tillverka-importera-och-salja-kosmetika/marknadsforing-av-kosmetika#hmainbody2">medicinska påståenden</a>?
              </FormLabel>
              <RadioGroup
                value={state?.qrMedicalClaims}
                name="qrMedicalClaims"
                style={{ flexDirection: "row", gridGap: 16 }}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Ja"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Nej"
                />
                <FormControlLabel
                  value={null}
                  control={<Radio color="primary" />}
                  label="Ej valt"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" style={{width: '100%'}}>
              <FormLabel
                required
                classes={{ root: classes.label }}
              > 
                Är förpackning och marknadsföring fri från påståenden (t.ex. "paraben-fri") i enlighet med <a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/docsroom/documents/24847">technical document on cosmetic claims</a>?
              </FormLabel>
              <RadioGroup
                value={state?.qrMarketingClaimsCosmetic}
                name="qrMarketingClaimsCosmetic"
                style={{ flexDirection: "row", gridGap: 16 }}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Ja"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label="Nej"
                />
                <FormControlLabel
                  value={null}
                  control={<Radio color="primary" />}
                  label="Ej valt"
                />
              </RadioGroup>
            </FormControl>
          </>
        )
      }
      {isSuperuser && showQrAdditivesInFeedOrFeedLabeling && (
        <>
          <FormControl component="fieldset" style={{width: '100%'}}>
            {/* {setShowQrHeader(true)} */}
            <FormLabel
              required
              classes={{ root: classes.label }}
            > 
              Är fodret märkt enligt EU:s förordning om fodertillsatser – ("tillsatsförordningen", förordning <a target="_blank" rel="noopener noreferrer" href="https://eur-lex.europa.eu/legal-content/SV/TXT/PDF/?uri=CELEX:32003R1831&from=ET">(EG) 1831/2003</a>)?
            </FormLabel>
            <RadioGroup
              value={state?.qrAdditivesInFeed}
              name="qrAdditivesInFeed"
              style={{ flexDirection: "row", gridGap: 16 }}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Nej"
              />
              <FormControlLabel
                value={null}
                control={<Radio color="primary" />}
                label="Ej valt"
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" style={{width: '100%'}}>
            <FormLabel
              required
              classes={{ root: classes.label }}
            > 
              Är fodret märkt enligt FEDIAF Code of Good Labelling Practice for Pet Food?
            </FormLabel>
            <RadioGroup
              value={state?.qrFeedLabeling}
              name="qrFeedLabeling"
              style={{ flexDirection: "row", gridGap: 16 }}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Nej"
              />
              <FormControlLabel
                value={null}
                control={<Radio color="primary" />}
                label="Ej valt"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      {isSuperuser && showQrMisleadingSalesTerms && (
        <FormControl component="fieldset" style={{width: '100%'}}>
          {/* {setShowQrHeader(true)} */}
          <FormLabel
            required
            classes={{ root: classes.label }}
          > 
            Är förpackning och produktbeskrivning fri från säljande uttryck <a target="_blank" rel="noopener noreferrer" href="https://kontrollwiki.livsmedelsverket.se/artikel/36/saljande-uttryck">säljande uttryck</a> som kan betraktas som vilseledande?
          </FormLabel>
          <RadioGroup
            value={state?.qrMisleadingSalesTerms}
            name="qrMisleadingSalesTerms"
            style={{ flexDirection: "row", gridGap: 16 }}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
      )}

      {isSuperuser && showQrNutritionClaims && (
        <>
          {/* {setShowQrHeader(true)} */}
          {isNotStore && (<FormControl component="fieldset" style={{width: '100%'}}>
            <FormLabel
              required
              classes={{ root: classes.label }}
            > 
              Är eventuella näringspåståenden i enlighet med villkoren i förordning <a target="_blank" rel="noopener noreferrer" href="https://kontrollwiki.livsmedelsverket.se/artikel/471/naringspastaenden">(EG) 1924/2006</a>?
            </FormLabel>
            <RadioGroup
              value={state?.qrNutritionClaims}
              name="qrNutritionClaims"
              style={{ flexDirection: "row", gridGap: 16 }}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Nej"
              />
              <FormControlLabel
                value={null}
                control={<Radio color="primary" />}
                label="Ej valt"
              />
            </RadioGroup>
          </FormControl>)}
          {isNotStore && (<FormControl component="fieldset" style={{width: '100%'}}>
            <FormLabel
              required
              classes={{ root: classes.label }}
            > 
              Är eventuella hälsopåståenden i enlighet med kraven i förordning <a target="_blank" rel="noopener noreferrer" href="https://kontrollwiki.livsmedelsverket.se/artikel/470/halsopastaenden">(EG) 1924/2006</a>?
            </FormLabel>
            <RadioGroup
              value={state?.qrHealthClaims}
              name="qrHealthClaims"
              style={{ flexDirection: "row", gridGap: 16 }}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Nej"
              />
              <FormControlLabel
                value={null}
                control={<Radio color="primary" />}
                label="Ej valt"
              />
            </RadioGroup>
          </FormControl>)}
          {isNotStore && (<FormControl component="fieldset" style={{width: '100%'}}>
            <FormLabel
              required
              classes={{ root: classes.label }}
            > 
              Är märkningen i enlighet med förordning (EU) 1169/2011 och Livsmedelsverkets föreskrifter (<a target="_blank" rel="noopener noreferrer" href="https://kontrollwiki.livsmedelsverket.se/artikel/31/information-och-markning">LIVSFS 2014:4</a>) om livsmedelsinformation?
            </FormLabel>
            <RadioGroup
              value={state?.qrFoodLabeling}
              name="qrFoodLabeling"
              style={{ flexDirection: "row", gridGap: 16 }}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Ja"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Nej"
              />
              <FormControlLabel
                value={null}
                control={<Radio color="primary" />}
                label="Ej valt"
              />
            </RadioGroup>
          </FormControl>
          )}
        </>
      )}
      {isSuperuser && isNotStore && isFoodSupplements(state?.foodlaCategory, rootCategory) && (
        <FormControl component="fieldset" style={{width: '100%'}}>
          {/* {setShowQrHeader(true)} */}
          <FormLabel
            required
            classes={{ root: classes.label }}
          > 
            Innehåller kosttillskottet, i rekommenderad daglig dos, vitaminer eller mineralämnen i en daglig dos som överstiger UL-värden (<a target="_blank" rel="noopener noreferrer" href="https://www.efsa.europa.eu/sites/default/files/assets/UL_Summary_tables.pdf">tolerable upper intake levels</a>) enligt Efsa?
          </FormLabel>
          <RadioGroup
            value={state?.qrEfsaUlValues}
            name="qrEfsaUlValues"
            style={{ flexDirection: "row", gridGap: 16 }}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="Ja"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="Nej"
            />
            <FormControlLabel
              value={null}
              control={<Radio color="primary" />}
              label="Ej valt"
            />
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
}

export default QualityReviewFields;
