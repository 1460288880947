import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import imageCompression from 'browser-image-compression';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Paper from '@material-ui/core/Paper';
import TabsMui from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import RetryIcon from '@material-ui/icons/Replay';
import SvgIcon from '@material-ui/core/SvgIcon';
import { styled } from '@material-ui/core';

import { getPictureUrl } from './utils';

import Objects from './tabs/objects';
import Labels from './tabs/labels';
import Logos from './tabs/logos';
import Properties from './tabs/properties';
import SafeSearch from './tabs/safe-search';
import Text from './tabs/text';
import Ingredients from './tabs/ingredients';

// Mutations
import {
  MUTATION_GET_VISION,
  MUTATION_GET_VISION_BY_URL,
} from '../../graphql/mutations';
import { KeycloakContext } from 'components/Secured';

const Tabs = styled(TabsMui)(() => ({
  '& .MuiTabs-scroller': {
    overflow: 'auto !important',
  }
}))

const VisionItem = ({isFood, image, title, state, setState}) => {
  const keycloakCtx = useContext(KeycloakContext);
  const [expandedVision, setExpandedVision] = React.useState({});
  const [visionTab, setVisionTab] = React.useState('ingredients');
  const [info, setInfo] = React.useState(undefined);
  const [firstExpanded, setFirstExpanded] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [compressedImage, setCompressedImage] = React.useState(undefined);
 
  const [
    getVisionMutation,
    { data: visionData, loading: visionLoading, error: visionError }
  ] = useMutation(
    MUTATION_GET_VISION,
    {
      // refetchQueries: [],
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setInfo(data?.getVision);
      },
    }
  );

  const [
    getVisionMutationByUrl,
    { data: visionByUrlData, loading: visionByUrlLoading, error: visionByUrlError }
  ] = useMutation(
    MUTATION_GET_VISION_BY_URL,
    {
      // refetchQueries: [],
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setInfo(data?.getVisionByUrl);
      },
    }
  );

  const getVision = async (file, ignoreLoaded) => {
    setLoading(true);
    console.log('===getVision===');
    console.log('file', file);
    const pictureUrl = getPictureUrl(file);
    if (!info || ignoreLoaded) {
      // console.log('sorted', sortedImages);
      if (file) {
        if (file.isExisting) {
          setCompressedImage(undefined);
          if (pictureUrl) {
            getVisionMutationByUrl({
              variables: {
                url: pictureUrl,
              }
            });
          } 
        } else {
          if (pictureUrl) {
            console.log('url', pictureUrl);
            if (compressedImage) {
              getVisionMutation({
                variables: {
                  image: compressedImage
                }
              });
            } else {
              const response = await fetch(pictureUrl);
              if (response) {
                const blob = await response.blob();
                if (blob) {
                  // compress file
                  const f = await imageCompression(blob, {
                    maxSizeMB: 2,
                  });
                  const base64 = await imageCompression.getDataUrlFromFile(f);
                  if (base64) {
                    setCompressedImage(base64);
                    getVisionMutation({
                      variables: {
                        image: base64
                      }
                    });
                  }
                }
              }
            }
          }
        }
      } else {
        setCompressedImage(undefined);
      }
      // const base64 = await fileToBase64(file);
      // // const base64 = undefined;
      // if (base64) {
      //   getVisionMutation({
      //     variables: {
      //       input: base64
      //     }
      //   });
      // }
    }
    setLoading(false);
  };

  const handleExpandedChange = (id) => (e, isExpanded) => {
    console.log('expand', isExpanded);
    if (id && id.trim !== "") {
      let expanded = expandedVision;
      if (!expanded) {
        expanded = {};
      }
      if (!expanded[id]) {
        expanded[id] = {};
      }
      expanded[id].expanded = isExpanded;
      setExpandedVision(expanded);
      if (firstExpanded && isExpanded) {
        setFirstExpanded(false);
        getVision(image);
      }
    }
  };

  return (
    <Accordion
      onChange={handleExpandedChange(image?.pictureUrl)}
      expanded={expandedVision[image?.pictureUrl]?.expanded}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <Paper style={{width: '100%'}}>
          {(loading || visionLoading || visionByUrlLoading) && (
            <div
              style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 20,
                  paddingBottom: 20,
              }}
            >
              <CircularProgress size={70} variant="indeterminate" />
            </div>
          )}
          {!loading && !visionLoading && !visionByUrlLoading && (!info || info.error) && (
            <Grid item={true} xs={12} style={{padding: 5}}>
              <Typography>Failed to get image info. Try again...</Typography>
              {info?.error?.message && (
                <Typography style={{color: "red"}}>{info.error.message}</Typography>
              )}
              <Button
                color="default"
                variant="outlined"
                onClick={() => {
                  getVision(image, true);
                }}
              >
                <SvgIcon component={RetryIcon} />
                <span>Retry</span>
              </Button>
            </Grid>
          )}
          {!loading && !visionLoading && !visionByUrlLoading && info && !info.error && (
            <>
              <Tabs
                value={visionTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={(e, val) => setVisionTab(val)}
              >
                <Tab label="Objects" value="objects" />
                <Tab label="Labels" value="labels" />
                <Tab label="Logos" value="logos" />
                <Tab label="Text" value="text" />
                <Tab label="Properties" value="properties" />
                <Tab label="Safe search" value="safeSearch" />
                <Tab label="Ingredients" value="ingredients" />
              </Tabs>
              <Objects
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
              />
              <Labels
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
              />
              <Logos
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
              />
              <Text
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
              />
              <Properties
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
              />
              <SafeSearch
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
              />
              <Ingredients
                visionTab={visionTab}
                image={compressedImage || image}
                info={info}
                isNew={!!compressedImage}
                state={state}
                setState={setState}
              />
            </>
          )}
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default VisionItem;
