import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const Footer = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            &copy; {new Date().getFullYear()} Copyright:{' '}
            <Link color="inherit" href="https://foodla.nu">
                Foodla AB
            </Link>
        </Typography>
    );
};

export default Footer;
