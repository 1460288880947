import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NotificationResult from './NotificationResult';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/react-hooks';
import { ORDER_BY_ORDER_NUMBER, PRODUCER_ORDER_BY_ORDER_NUMBER } from '../graphql/queries'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import OrdersIcon from '@material-ui/icons/LocalShippingOutlined';
import Toolbar from './OrderToolbar';

import { KeycloakContext } from './Secured';

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
    },
    nextstep: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(0.5),
    },
}));
const OrderNotificationPanel = props => {
    const { orderNumber, title } = props;
    // Decide which query to use depending on if user is producer or not.
    const keycloakCtx = useContext(KeycloakContext);
    const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
    const query = isProducer
        ? PRODUCER_ORDER_BY_ORDER_NUMBER 
        : ORDER_BY_ORDER_NUMBER;

    const { data, loading, error, refetch } = useQuery(query, {
        variables: {
            orderNumber,
        },
        fetchPolicy: 'cache-and-network',
    });

    const orderData = isProducer ? data?.producerOrderByOrderNumber?.payload : data?.orderByOrderNumber?.payload;
    let orders;
    if (isProducer) {
        if (orderData) {
            orders = [orderData];
        }
    } else {
        orders = orderData?.orders;
    }

    let content = () => {
        if (error) return `Error! ${error.message}`;
        if (!data) return <>No data found</>;
        if (loading) return <CircularProgress />;
        if (orders?.length > 0)
            return (
                <NotificationResult
                    isProducer={isProducer}
                    list={orders}
                    organization={orderData?.organization}
                    logistician={orderData?.logistician}
                />
            );
    };

    const refresh = () => {
        refetch({
            variables: {
                orderNumber,
            },
        });
    };

    return (
        <Paper>
            <Toolbar title={`${title}:${orderNumber}`}>
                <Button
                    component={Link}
                    to="/orders"
                    variant="outlined"
                    size="medium"
                    color="primary"
                    startIcon={<OrdersIcon />}
                >
                    Orders
                </Button>
                { isProducer ? (<></>) : (
                    <Button
                        component={Link}
                        to="/order/select"
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        New
                    </Button>
                )}
                <Button
                    onClick={refresh}
                    size="medium"
                    variant="outlined"
                    color="primary"
                    startIcon={<RefreshIcon />}
                >
                    Refresh
                </Button>
            </Toolbar>
            {content()}
        </Paper>
    );
};

export default function NotificationContent(props) {
    const {
        match: { params },
    } = props;
    const groupOrderNumber = `${params.groupOrderNumber}`;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <OrderNotificationPanel
                title="Notification"
                orderNumber={groupOrderNumber}
            />
        </Box>
    );
}
