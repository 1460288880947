import React, { useContext, useState } from 'react';
import { head, path, equals } from 'ramda';
import { useMutation } from '@apollo/react-hooks';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { KeycloakContext } from '../Secured';
import { restEndpoints, PRODUCT_TAGSTATES, IS_FOOD_LOCAL_STORAGE_KEY, PRODUCT_TABS } from '../../constants/domain';
import COLORS from '../../constants/colors';
import SnackBar from '../snackbar/SnackBar';
import {
  MUTATION_SET_EXPORTED_PRODUCTS,
} from '../../graphql/mutations';
import { extractURLParam } from '../../utils/urlUtils';
import { parseBoolean } from '../../utils/helpers';

interface IExcelExporterProps {
  onlyExcel?: boolean;
  exportTitle?: string;
  resetButton?: boolean;
  resetTitle?: string;
  isSuperuser?: boolean;
  eans?: string;
  username?: string;
  includeAll?: boolean;
  selectedCategory?: PRODUCT_TABS;
}

const ExcelExporter = ({
  selectedCategory,
  exportTitle,
  resetButton,
  resetTitle,
  isSuperuser,
  eans,
  username,
  onlyExcel,
  includeAll,
}: IExcelExporterProps) => {
  const keycloakCtx = useContext(KeycloakContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const [exportError, setExportError] = useState([false, '']);
  const isAdmin = keycloakCtx.keycloak.hasResourceRole('admin');

  const [openConfirmReset, setOpenConfirmReset] = React.useState(false);

  const isExportSupported = equals(selectedCategory, PRODUCT_TAGSTATES.APPROVED)

  let isNonFood = false;
  // let oneType = false;
  if (isAdmin) {
    const isFood = localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY);
    if(isFood != null) {
      isNonFood = !parseBoolean(isFood);
    }
  } else if (isSuperuser) {
    isNonFood = extractURLParam('nonfood', window.location.toString()) === "1"; // TODO check
  }

  const eansQs = (eans && eans.trim() !== '') ? `&eans=${eans}`: '';
  const usernameQs = (username && username.trim() !== '') ? `&username=${username}` : '';
  const onlyExcelQs = onlyExcel ? '&onlyExcel=1' : '';
  const includeDisapprovedQs = includeAll ? '&includeAll=1' : '';
  // const endpoint = isSuperuser ? `${restEndpoints.superuserProductsDownload}?onetype=1&nonfood=${isNonFood ? 1 : 0}`: restEndpoints.approvedProductsDownload;
  const endpoint = `${isSuperuser ? restEndpoints.superuserProductsDownload : restEndpoints.approvedProductsDownload }?onetype=1&nonfood=${isNonFood ? 1 : 0}${eansQs}${onlyExcelQs}${usernameQs}${includeDisapprovedQs}`;
  const [
    setExportedProductsMutation,
    { data: setExportedProductsData, loading: setExportedProductsLoading, error: setExportedProductsError },
  ] = useMutation(MUTATION_SET_EXPORTED_PRODUCTS, {
    // refetchQueries: messagesRefetch(),
    // awaitRefetchQueries: true,
    variables: {
      oneType: true,
      nonFood: isNonFood,
    },
    onCompleted: (data) => {
      // setMessageText("");
    },
  });

  const downloadExcel = async () => {
    setIsDownloading(() => true);

    const token = path(['keycloak', 'token'], keycloakCtx);

    fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(r => r.blob())
      .then(b => {
        const url = window.URL.createObjectURL(new Blob([b]));
        const dLink = document.createElement('a');
        dLink.href = url;
        dLink.setAttribute('download', 'produkter.zip');
        document.body.appendChild(dLink);
        dLink.click();
        dLink.parentNode?.removeChild(dLink);
      })
      .catch(e => setExportError(() => [true, e]))
      .finally(() => setIsDownloading(() => false));
  };

  const error = head(exportError);

  const title = !exportTitle || exportTitle.trim() === ''
    ? 'Export approved products'
    : exportTitle;

  const rTitle = !resetTitle || resetTitle.trim() === ''
    ? 'Accept and reset new approved and updated products'
    : resetTitle;

  return isExportSupported ? (
    <>
    {error && <SnackBar message={'Failed to export.'} duration={3000} />}
    {setExportedProductsError && <SnackBar message={'Failed to reset export.'} duration={3000} />}
    <Button
      disabled={isDownloading}
      onClick={downloadExcel}
      size="small"
      style={{ margin: 10, backgroundColor: COLORS.mainGreen, }}
      variant="outlined">
        {title}
    </Button>
    {resetButton && (
      <Button
        disabled={isDownloading}
        onClick={() => {
          setOpenConfirmReset(true);
        }}
        size="small"
        style={{ margin: 10, float: 'right' }}
        variant="outlined"
        color="secondary"
      >
          {rTitle}
      </Button>
    )}
      <Dialog
        open={openConfirmReset}
        onClose={() => {
          setOpenConfirmReset(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset Export"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to reset export?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmReset(false);
            }}
            color="primary">
              No
          </Button>
          <Button
            onClick={() => {
              setOpenConfirmReset(false);
              setExportedProductsMutation();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : <></>;
};

export default ExcelExporter;
