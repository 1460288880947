import React, { useContext, useEffect } from 'react';

// TODO: Move to pages.

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';

import NoData from './NoData';
import OrderList from './orderlist/OrderList';
import Toolbar from './OrderToolbar';
import { GET_ORDERS_QUERY, GET_PRODUCER_ORDERS } from '../graphql/queries';
import { KeycloakContext } from './Secured';

import { toSafeNumber } from '../utils/helpers';

import useQueryString from "../utils/useQueryString";
import { getQueryStringValue } from "../utils/queryString";

const INIT_PAGE_PARAM = 1;
const INIT_LIMIT_PARAM = 5;

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 400,
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();

    const [qsLimit, setQsLimit] = useQueryString("limit");
    const [qsPage, setQsPage] = useQueryString("page");

    const [limit, setLimit] = React.useState(toSafeNumber(qsLimit, INIT_LIMIT_PARAM));
    const [page, setPage] = React.useState(toSafeNumber(qsPage, INIT_PAGE_PARAM) - 1);

    useEffect(() => {
        if (pageInfo?.page !== page) {
            if (pageInfo?.page < page) {
                setPageInfo({
                    page,
                    type: 'TOP',
                    limit,
                });
            } else {
                setPageInfo({
                    page,
                    type: 'TOP',
                    limit,
                });
            }
        }
    }, [page]);

    useEffect(() => {
        if (pageInfo?.limit !== limit) {
            const newPageInfo = {
                type: "TOP",
                limit: limit,
                page: 0,
                // cursor: undefined,
            };
            setPageInfo(newPageInfo);
            // setQsLimit(limit);
        }
    }, [limit]);

    useEffect(() => {
        const qsNewPage = getQueryStringValue("page");
        const newPage  = toSafeNumber(qsNewPage, INIT_PAGE_PARAM) - 1;
        if (newPage !== page) {
            setPage(newPage);
        }

        const qsLimitNew = getQueryStringValue("limit");
        const newLimit  = toSafeNumber(qsLimitNew, INIT_LIMIT_PARAM);
        if (newLimit !== limit) {
            // pageInfo.type = "TOP";
            // pageInfo.limit = newLimit;
            // pageInfo.cursor = undefined;
            // setQsLimit(newLimit);
            setLimit(newLimit);
        }
    }, [location.search]);

    const [pageInfo, setPageInfo] = React.useState({
        type: 'TOP',
        limit: limit,
        page: 0,
        // cursor: undefined,
    });

    // Decide which query to use depending on if user is producer or not.
    const keycloakCtx = useContext(KeycloakContext);
    const isProducer = keycloakCtx.keycloak.hasResourceRole('producer');
    let ordersQuery;
    if (isProducer) ordersQuery = GET_PRODUCER_ORDERS;
    else ordersQuery = GET_ORDERS_QUERY;

    const { loading, error, data } = useQuery(ordersQuery, {
        variables: {
            Pagination: {
                type: pageInfo?.type,
                page: page,
                // cursor: pageInfo.cursor,
                limit: limit,
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const orderData = isProducer ? data?.producerOrders : data?.groupOrders;
    console.log("ORDER DATA", orderData);

    const handleChangePage = (event, newPage) => {
        // if (page < newPage) {
        //     setPageInfo({
        //         cursor: data.groupOrders.pageInfo.endCursor,
        //         type: 'TOP',
        //     });
        // } else {
        //     setPageInfo({
        //         cursor: data.groupOrders.pageInfo.startCursor,
        //         type: 'TRAIL',
        //     });
        // }
        // setPage(newPage);
        // setQsPage(newPage);
        if (page !== newPage) {
            history.push({
                search: `?limit=${limit}&page=${newPage + 1}`
            });
        }
    };

    const handleChangeRowsPerPage = event => {
        // const value = parseInt(event.target.value, INIT_LIMIT_PARAM);
        // console.log("NEW LIMIT", event.target.value);
        history.push({
            search: `?limit=${event.target.value}`
        });
        // setQsLimit(event.target.value);
        // setLimit(event.target.value);
        // setPage(0);
        // setPageInfo({ type: 'TOP', limit: value, cursor: undefined });
        // setQsLimit(value);
    };

    if (error) return `Error! ${error.message}`;

    return (
        <Paper>
            <Toolbar title="Order History">
                {isProducer ? (<></>) : (
                    <Button
                        component={Link}
                        to="/order/suggest"
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        New
                    </Button>
                )}
            </Toolbar>
            <Container className={classes.root}>
                {loading === true && <CircularProgress />}
                {!loading &&
                orderData?.data &&
                orderData.data.length > 0 ? (
                    <>
                        <OrderList
                            listData={orderData}
                            isProducer={isProducer}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={
                                handleChangeRowsPerPage
                            }
                            rowsPerPage={limit}
                            page={page}
                        />
                    </>
                ) : (
                    <NoData />
                )}
            </Container>
        </Paper>
    );
}
