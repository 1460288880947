import qs from 'query-string';
import axios from 'axios';
import { parsePngFormat } from 'png-dpi-reader-writer';
import { changeDpiBlob } from 'dpi-tools';
import loadImage from 'blueimp-load-image';

import rewrite_pHYs_chunk from './rewrite-png-pHYs-chunk';

// get url without query string
const getUrl = (url) => {
  if (url && url.trim() !== '') {
    return qs.parseUrl(url).url;
  }
  return undefined;
};

// get query string
const getQueryString = (url) => {
  if (url && url.trim() !== '') {
    return qs.parseUrl(url, { parseNumbers: true }).query;
  }
  return undefined;
};

// get info about image from imgix
export const getInfo = async (url) => {
  if (url && url.trim() !== '') {
    let imageUrl = getUrl(url);
    imageUrl += '?fm=json';
    const response = await axios.get(imageUrl);
    if (response && response.data) {
      return response.data;
    }
  }
  return undefined;
};

export function collectJpgMetaDataF(file){
  return new Promise((resolve, reject) => {
      loadImage.parseMetaData(file, function(data) {
          let metadata = {};
          // let result = {};
          console.log('!data', data);
          if(data.iptc){
              let iptc = data.iptc.getAll()

              if(iptc.Keywords){
                  metadata["keywords"] = iptc.Keywords.split(",")
              }
              if(iptc.ObjectName){
                  metadata["title"] = iptc.ObjectName
              }

          }
          if(data.exif){
              // result["exif"] = data.exif.getAll();
              metadata["exif"] = data.exif.getAll();
              metadata["orientation"] = data.exif.get("Orientation");
          }

          resolve(metadata)
      }, { meta: true });
  });
}

export const fileToDataUrl = async (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = () => {
      // convert image file to base64 string
      res(reader.result);
    };
  
    if (file) {
      reader.readAsDataURL(file);
    }
  });
}

// const rewrite = async (file, dpi) => { DPI
//   const datauri = await fileToDataUrl(file);
//   var pngHeader = "data:image/png;base64,";
//   var base64 = datauri.substr(pngHeader.length);

//   var binary_string = window.atob(base64);
//   var len = binary_string.length;
//   var bytes = new Uint8Array(len);
//   for (var i = 0; i < len; i++) {
//       bytes[i] = binary_string.charCodeAt(i);
//   }

//   // mess with pHYs chunk here

//   // pixels per metre
//   var ppm = Math.round(dpi / 2.54 * 100);
//   bytes = rewrite_pHYs_chunk(bytes, ppm, ppm);
//   // run through again to check CRC :)
//   bytes = rewrite_pHYs_chunk(bytes, ppm, ppm);

//   // re-encode PNG (btoa method)
//   // var b64encoded = btoa(String.fromCharCode(bytes));
//   // console.log(pngHeader + b64encoded);

//   // re-enocde PNG blob method
//   // works for display
//   // but not if you want the base64 string for export
//   // var blob = new File([bytes], file.name, {'type': 'image/png'});
//   //var URL = URL.createObjectURL(blob); //possibly `webkitURL` or another vendor prefix for old browsers.
//   //img.src = URL;
//   //console.log(URL);
//   // return blob;
//   return bytes;
// };

// export const getFileDpi = async (file) => { DPI
//   let dpi;
//   if (file.type === 'image/png') {
//     const ab = await file.arrayBuffer();
//     const result = parsePngFormat(ab);
//     dpi = result.dpi;
//   } else {
//     const meta = await loadImage.parseMetaData(file);
//     dpi = meta?.exif?.get('XResolution');
//   }
//   return dpi;
// };

// export const setDpi = async (file) => { DPI
//   console.log('===setDpi===');
//   console.log('!oldImageSize', file.size);
//   console.log('!dpifile', file)
//   const dpi = await getFileDpi(file);
//   console.log('!dpi', file.name, dpi);
//   if (!dpi || dpi < 300) {
//     console.log('change dpi');
//     let newFile
//     if (file.type === 'image/png') {
//       newFile = await rewrite(file, 300);
//       // const ab = await file.arrayBuffer();
//       // newFile = writePngDpi(ab, 300, { override: true });
//       // newFile = new Uint8Array(newFile);
//     } else {
//       newFile = await changeDpiBlob(file, 300);
//     }
//     // let newFile = await changeDpiBlob(file, 300);
//     // newFile = await changeDpiBlob(newFile);
//     const res = new File([newFile], file.name, { type: file.type });
//     // res.preview = URL.createObjectURL(newFile);
//     // res.path = file.name;
//     console.log('!newImageSize', res.size);
//     return res;
//   }
//   return file;
// };

// get dpi from imgix json info
// export const getDpi = (info) => { DPI
//   if (info) {
//     if (info.DPIWidth) {
//       return info.DPIWidth;
//     }
//     if (info.DPIHeight) {
//       return info.DPIHeight;
//     }
//     const tiff = info.TIFF;
//     if (tiff) {
//       if (tiff.XResolution) {
//         return tiff.XResolution;
//       }
//       if (tiff.YResolution) {
//         return tiff.YResolution;
//       }
//     }
//   }
//   return undefined;
// };

export const encodeLastPathUrl = (url) => {
  const [rootUrl, searchString] = (url || '').split('?');
  const splittedUrlList = rootUrl.split('/');
  const lastPath = splittedUrlList.pop() || '';
  let result = [...splittedUrlList, encodeURIComponent(lastPath)].join('/');
  if (searchString) {
    result = `${result}?${searchString}`;
  }
  return result;
}

export const setWidthHeight = (src, width, height) => {
  // console.log('===setWidthHeight===', src);
  if (src && src.trim() !== '') {
    const url = getUrl(src);

    let q = getQueryString(src);
    if (!q) {
      q = {};
    }
    if (width) {
      q.w = width;
    }
    if (height) {
      q.h = height;
    }

    return qs.stringifyUrl({ url, query: q });
  }
  return '';
};

// eslint-disable-next-line import/prefer-default-export
// export const setImageDpi = async (src, width, height) => {
//   console.log('===setImageDpi===', src);
//   const urlWidthSize = setWidthHeight(src, width, height);
//   console.log('urlWidthSize', urlWidthSize);
//   if (urlWidthSize && urlWidthSize.trim() !== '') {
//     const url = getUrl(urlWidthSize);

//     let q = getQueryString(urlWidthSize);
//     if (!q) {
//       q = {};
//     }
//     if (q.width && width) {
//       q.w = width;
//     }
//     if (q.height && height) {
//       q.h = height;
//     }
//     // if dpi in query string more or equal skip
//     if (q.dpi && q.dpi >= 300) {
//       return qs.stringifyUrl({ url, query: q });
//     }

//     // get image info
//     const info = await getInfo(urlWidthSize);
//     // get image dpi
//     let dpi;
//     if (info) {
//       dpi = getDpi(info);
//     }
//     console.log('dpi', dpi);
//     if (!dpi || dpi < 300) {
//       dpi = 300;
//     } else {
//       // skip if dpi more or equal 300
//       return urlWidthSize;
//     }

//     q.dpi = dpi;
//     // dpi works only with jpg files
//     q.fm = 'jpg';

//     return qs.stringifyUrl({ url, query: q });
//   }
//   return '';
// }; DPI
