import React from 'react';

import { Box } from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';
import ListDropDown from 'components/inputfields/ListDropDown';

import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';

const limitOptions = new Array(14)
  .fill(0)
  .map((_, index) => {
    if (index < 9) return (index + 1) * 10;
    return (index - 8) * 100;
  })
  .map(value => ({ value }));

const Pagination = () => {
  const { limit, page, onLimitChange, setPage } = useControlContext();
  const { dataList } = useDataContext();

  const handleChangeLimit = (event: React.ChangeEvent<{ value: unknown }>) => {
    onLimitChange(event.target.value as number);
    setPage(1);
  };

  return (
    <Box display="flex" gridGap={8} alignItems="center">
      <MuiPagination
        size="small"
        count={Math.ceil(dataList.length / limit)}
        onChange={(_, page) => setPage(page)}
        page={Math.max(1, page)}
      />

      <ListDropDown size="small" value={limit} listData={limitOptions} handleChange={handleChangeLimit} />
    </Box>
  );
};

export default Pagination;
