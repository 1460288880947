import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { HelpOutline } from '@material-ui/icons';

import { BulkImportProvider, useBulkImportContext } from './BulkImportContext';
import Review from './Review';

import { readExcelFile } from 'utils/excelUtils-old';
import { OBJECT_STORAGE_NAME, SAVED_FILE_DATA_ID, SAVED_FILE_ID } from './bulkImportUtils';
import { TExcelDataRow } from 'models/excel';

const BulkImportBase = () => {
  const { loadingLocal, indexedDB, excelFile, setExcelFile, excelFileData, setExcelFileData } = useBulkImportContext();

  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx',
    multiple: false,
    onDrop: async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) return;
      setLoading(true);
      const data = await readExcelFile(file, 0);

      // write data to indexedDB
      if (indexedDB) {
        await indexedDB.clearObjectStore(OBJECT_STORAGE_NAME);
        await Promise.all([
          indexedDB.write<File>(OBJECT_STORAGE_NAME, { id: SAVED_FILE_ID, value: file }),
          indexedDB.write<TExcelDataRow[]>(OBJECT_STORAGE_NAME, { id: SAVED_FILE_DATA_ID, value: data }),
        ]);
      }

      setExcelFile(file);
      setExcelFileData(data);
      setLoading(false);
    },
  });

  if (loading || loadingLocal) {
    return (
      <Box display="flex" justifyContent="center" py={10}>
        Loading...
      </Box>
    );
  }

  if (excelFileData.length && excelFile && indexedDB) {
    return <Review />;
  }

  return (
    <Box>
      <Box display="flex" mb={1}>
        <Typography variant="body1">Produktinformation</Typography>
        <Box ml={1}>
          <HelpOutline />
        </Box>
      </Box>
      <Box {...getRootProps({ className: 'dropzone' })} py={10}>
        <input {...getInputProps()} />
        <Typography variant="body1" color="textSecondary">
          Drag an Excel or CSV-file in this area or click to select from your computer!
        </Typography>
        <Typography variant="body1" color="textSecondary">
          <span>Remember to name your column headers so that Foodla understands you. See instructions</span>{' '}
          <Link color="inherit" underline="always" href="/" onClick={event => event.stopPropagation()}>
            here
          </Link>
          .
        </Typography>
        <Typography variant="body1" color="textSecondary">
          It does not matter which order your columns are in.
        </Typography>
      </Box>
    </Box>
  );
};

const BulkImport = () => (
  <BulkImportProvider>
    <BulkImportBase />
  </BulkImportProvider>
);

export default BulkImport;
