import { equals, map, isEmpty } from "ramda";
import { isDefined } from "../utils/helpers";

import { IExcelHeader } from 'models/excel';
import IProduct, {
  IFoodlaCategory,
  NutritionalUnit,
  EggWeightClassEnum,
  RecyclingTypeEnum,
  PreparedFormEnum,
  AdditionalCharacteristicsEnum,
  AnimalCategoriesForFeedEnum,
  CategoryWeightlossEnum,
  ApprovalDietFeedEnum,
  ComplianceMetalsSkinContactEnum,
  ConfirmGuidanceSwedishElectronicsEnum,
  ComplianceToyDirectiveEnum,
  ComplianceBiocidEnum,
  ComplianceBiocidalTreatedEnum,
  ComplianceSunscreenRegulationEnum,
  SunProtectionFactorEnum,
  FishCatchMethodEnum,
  FishProductionMethodEnum,
  CatchAreaEnum,
  SignalWordsEnum,
  HazardStatementsEnum,
  SecurityNoticesEnum
} from "models/product";
import { IFoodlaCategoryOption } from 'models/category';


export const ORDER_STATUS_CREATED = 'CREATED';
export const ORDER_STATUS_NOTIFIED_DONE = 'NOTIFIED';
export const ORDER_STATUS_NOTIFIED_FAILURE = 'NOTIFY_FAILURE';
export const ORDER_STATUS_NOTIFIED_ERROR = 'NOTIFY_ERROR';
export const ORDER_STATUS_NOTIFIED_NOTIFYING = 'NOTIFYING';

export const ORDER_STATUS_CREATED_FRONTEND = 'Created';
export const ORDER_STATUS_DONE_FRONTEND = 'Done';
export const ORDER_STATUS_SENDING_FRONTEND = 'Sending';
export const ORDER_STATUS_ERROR_FRONTEND = 'Error';
export const ORDER_STATUS_FRONTEND = [
  ORDER_STATUS_CREATED_FRONTEND,
  ORDER_STATUS_SENDING_FRONTEND,
  ORDER_STATUS_DONE_FRONTEND,
];

export const SUPERUSER_APPROVAL = {
  ADMIN_APPROVED: "ADMIN_APPROVED",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  ADMIN_DISAPPROVED: "ADMIN_DISAPPROVED",
};

export const PRODUCT_STATUS = {
  MARKED_FOR_REMOVAL: 'MARKED_FOR_REMOVAL',
  DELETED: 'DELETED',
};

export const APPLICATION_ROLES = {
  PRODUCER: 'producer',
  LOGISTICIAN: 'logistician',
  ADMIN: 'admin',
  SUPERUSER: 'superuser',
  UNAUTHORIZED: 'unauthorized',
  QUALITY: 'quality',
  STORE: 'store',
};

export const ROOT_CATEGORY_ID = 'root';
export const FOOD_CATEGORY_ID = 'food';
export const NON_FOOD_CATEGORY_ID = 'non-food';

export const FOOD_CATEGORY_LIST = [
  { id: `${FOOD_CATEGORY_ID}-1`, name: 'Mjölkprodukter och motsvarande icke mjölkbaserade produkter' },
  { id: `${FOOD_CATEGORY_ID}-2`, name: 'Fetter och oljor samt fett- och oljeemulsioner' },
  { id: `${FOOD_CATEGORY_ID}-3`, name: 'Glassvaror' },
  { id: `${FOOD_CATEGORY_ID}-4`, name: 'Frukter, bär och grönsaker' },
  { id: `${FOOD_CATEGORY_ID}-5`, name: 'Konfektyrvaror' },
  { id: `${FOOD_CATEGORY_ID}-6`, name: 'Spannmål och spannmålsprodukter' },
  { id: `${FOOD_CATEGORY_ID}-7`, name: 'Bageriprodukter' },
  {
    id: `${FOOD_CATEGORY_ID}-8`,
    name: 'Kött',
    children: [
      {
        id: `${FOOD_CATEGORY_ID}-8-1`,
        name: 'Nötkött',
        children: [
          { id: `${FOOD_CATEGORY_ID}-8-1-1`, name: 'Nötkött Chuck' },
          { id: `${FOOD_CATEGORY_ID}-8-1-2`, name: 'Oxbringa' },
          { id: `${FOOD_CATEGORY_ID}-8-1-3`, name: 'Nötkött tallrik' },
        ],
      },
      { id: `${FOOD_CATEGORY_ID}-8-2`, name: 'Fläsk' },
      { id: `${FOOD_CATEGORY_ID}-8-3`, name: 'Kyckling' },
      { id: `${FOOD_CATEGORY_ID}-8-4`, name: 'Getkött' },
    ],
  },
  { id: `${FOOD_CATEGORY_ID}-9`, name: 'Fisk och fiskeriprodukter' },
  { id: `${FOOD_CATEGORY_ID}-10`, name: 'Ägg och äggprodukter' },
  { id: `${FOOD_CATEGORY_ID}-11`, name: 'Sockerarter, olika former av sirap, honung och bordssötningsmedel' },
  { id: `${FOOD_CATEGORY_ID}-12`, name: 'Salt, kryddor, soppor, såser, sallader och proteinprodukter' },
  { id: `${FOOD_CATEGORY_ID}-13`, name: 'Livsmedel för särskilda näringsändamål enligt definitionen i direktiv 2009/39/ EG' },
  {
    id: `${FOOD_CATEGORY_ID}-14`,
    name: 'Drycker',
    children: [
      {
        id: `${FOOD_CATEGORY_ID}-14-1`,
        name: 'Mjölk',
        children: [
          { id: `${FOOD_CATEGORY_ID}-14-1-1`, name: 'Mjölkkor' },
          { id: `${FOOD_CATEGORY_ID}-14-1-2`, name: 'Getmjölk' },
          { id: `${FOOD_CATEGORY_ID}-14-1-3`, name: 'Sojamjölk' },
        ],
      },
      {
        id: `${FOOD_CATEGORY_ID}-14-2`,
        name: 'Kaffe',
        children: [
          {
            id: `${FOOD_CATEGORY_ID}-14-2-1`,
            name: 'Arabica',
            children: [
              { id: `${FOOD_CATEGORY_ID}-14-2-1-1`, name: 'Typica' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-2`, name: 'Bourbon' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-3`, name: 'Caturra' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-4`, name: 'Catimor' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-5`, name: 'Jackson' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-6`, name: 'Kona' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-7`, name: 'Pacamara' },
              { id: `${FOOD_CATEGORY_ID}-14-2-1-8`, name: 'Villabos' },
            ],
          },
          { id: `${FOOD_CATEGORY_ID}-14-2-2`, name: 'Robusta' },
          { id: `${FOOD_CATEGORY_ID}-14-2-3`, name: 'Espresso' },
          { id: `${FOOD_CATEGORY_ID}-14-2-4`, name: 'Cappuccino' },
          { id: `${FOOD_CATEGORY_ID}-14-2-5`, name: 'Latte' },
        ],
      },
      { id: `${FOOD_CATEGORY_ID}-14-3`, name: 'Te' },
      {
        id: `${FOOD_CATEGORY_ID}-14-4`,
        name: 'Juice',
        children: [
          { id: `${FOOD_CATEGORY_ID}-14-4-1`, name: 'Apelsinjuice' },
          { id: `${FOOD_CATEGORY_ID}-14-4-2`, name: 'Appeljuice' },
        ],
      },
    ],
  },
  { id: `${FOOD_CATEGORY_ID}-15`, name: 'Konsumtionsfärdiga snacks' },
  { id: `${FOOD_CATEGORY_ID}-16`, name: 'Desserter, utom produkter som omfattas av kategorierna 1, 3 och 4' },
  { id: `${FOOD_CATEGORY_ID}-17`, name: 'Kosttillskott enligt definitionen i direktiv 2002/46/EG' },
  {
    id: `${FOOD_CATEGORY_ID}-18`,
    name: 'Bearbetade livsmedel som inte omfattas av kategorierna 1–17, utom livsmedel för spädbarn och småbarn',
  },
];

export const NON_FOOD_CATEGORY_LIST = [
  { id: `${NON_FOOD_CATEGORY_ID}-1`, name: 'Ej Livsmedel' },
];

export const PRODUCT_CATEGORY_ROOT: IFoodlaCategoryOption = {
  id: ROOT_CATEGORY_ID,
  name: 'Root',
  children: [
    {
      id: FOOD_CATEGORY_ID,
      name: 'Food',
      children: FOOD_CATEGORY_LIST,
    },
    {
      id: NON_FOOD_CATEGORY_ID,
      name: 'Non Food',
      children: NON_FOOD_CATEGORY_LIST,
    },
  ],
};

interface IDescriptiveSizeUnit {
  enum: string;
  value: string;
  isEan2?: boolean;
}

export const DESCRIPTIVE_SIZE_UNITS_MAP: IDescriptiveSizeUnit[] = [
  { enum: 'GRAM', value: 'gr'},
  { enum: 'KILOGRAM', value: 'kg'},
  { enum: 'MILLILITER', value: 'ml'},
  { enum: 'CENTILITER', value: 'cl'},
  { enum: 'DECILITER', value: 'dl'},
  { enum: 'LITER', value: 'l'},
  { enum: 'ITEM', value: 'st'},
  { enum: 'BAGS', value: 'påsar'},
  { enum: 'PIECE', value: 'bit'},
  { enum: 'GRAM_APPROXIMATE', value: 'gram ungefärlig vikt', isEan2: true },
  // { enum: 'GRAM_PER_ITEM_APPROXIMATE', value: 'gram/st ungefärlig vikt', isEan2: true },
  // { enum: 'GRAM_PER_PIECE_APPROXIMATE', value: 'gram/bit ungefärlig vikt', isEan2: true },

  { enum: 'pack', value: 'pack' },
  { enum: 'set', value: 'set' },
  { enum: 'par', value: 'par' },
];

interface INutritionalUnit {
  enum: string;
  value: string;
}

export const NUTRITIONAL_UNITS_MAP: INutritionalUnit[] = [
  { enum: 'GRAM', value: 'g'},
  { enum: 'GRAM', value: 'gr'},
  { enum: 'GRAM', value: 'gram'},
  { enum: 'MILLILITER', value: 'ml'},
  { enum: 'MILLILITER', value: 'milliliter'},
];

export const getDescriptiveSizeEnumFromValue = (value: string) => {
  const v = DESCRIPTIVE_SIZE_UNITS_MAP.find((x) => x.value === value);
  return isDefined(v) ? v : '';
}

export const getDescriptiveSizeValueFromEnum = (enumType: string) => {
  const v = DESCRIPTIVE_SIZE_UNITS_MAP.find((x) => x.enum === enumType);
  return isDefined(v) ? v : '';
}

export const getNutritionalEnumFromValue = (value: string) => {
  const v = NUTRITIONAL_UNITS_MAP.find((x) => x.value === value);
  return isDefined(v) ? v : '';
}

export const getNutritionalValueFromEnum = (enumType: string) => {
  const v = NUTRITIONAL_UNITS_MAP.find((x) => x.enum === enumType);
  return isDefined(v) ? v : '';
}

const PRODUCT_NOTE_INPUT_TYPES = {
  INT_ARRAY: 'intArrayValue'
}

const intArrayReducer = (ack: string, key: string) => ack.concat(`${key},`);

interface IProductNoteTypesExtractor {
  [key: string]: (value: string[]) => string;
}

export const PRODUCT_NOTE_TYPES_EXTRACTOR: IProductNoteTypesExtractor = {
  'ProductNoteIntArray': (value: string[]) => {
    // TODO
    if(isEmpty(value)) return '';
    return value.reduce(intArrayReducer, '').slice(0, -1);
  }
}

interface IProductNotesTypeTransformers {
  [key: string]: (valueToTransform: string) => number[]
}

// TODO: Implemnt failure handling.
// TODO: Go over this
export const PRODUCT_NOTES_TYPE_TRANSFORMERS: IProductNotesTypeTransformers = {
  [PRODUCT_NOTE_INPUT_TYPES.INT_ARRAY]: (valueToTransform: string) => {
    if (valueToTransform) {
      return map((v: string) => parseInt(v))(valueToTransform.split(','));
    } else {
      return [];
    }
  }
};

interface IOrganizationSpecificField {
  fieldName: string;
  inputType: string; // TODO type
  formLabel: string;
  info: string;
  transformer: (value: string) => number[];
}

interface IOrganizationSpecificFields {
  [key: string]: IOrganizationSpecificField[];
}

export const ORGANIZATION_SPECIFIC_FIELDS: IOrganizationSpecificFields = {
  COOP: [
    {
      fieldName: 'ItemCategoriesOnline',
      inputType: PRODUCT_NOTE_INPUT_TYPES.INT_ARRAY,
      formLabel: 'Item Categories Online',
      info: 'Accepts a list of numbers, each entry should be separated by a comma.',
      transformer: PRODUCT_NOTES_TYPE_TRANSFORMERS[PRODUCT_NOTE_INPUT_TYPES.INT_ARRAY]
    }
  ]
};

// Keep this lowercased
export const ALLERGENS = [
  'abborre', 'aborr', 'alaska pollock', 'ansjovis', 'ariopsis felis', 'atlantlax', 'bergtung', 'bergtunga',
  'blå gurami', 'bläckfisk', 'blötdjur ', 'bonito', 'bredrandig ansjovis', 'cashewnöt',  'dinkel', 'drakfisk',
  'filmjölk', 'fisk', 'fjärsing', 'fläckig havskatt', 'fugu', 'gråsej', 'grädde', 'gräddfil', 'guldsparid', 'gädd',
  'gädda', 'gös', 'hajmal', 'harr', 'hasselnöt', 'havre', 'havsabborre', 'havskatt', 'hoki', 'hundhaj', 'hälleflund',
  'hälleflundra', 'jordnöt', 'kamut', 'kapkummel', 'karp', 'kedjepickerell', 'khorasanvete', 'kolja', 'korn', 'kräft',
  'kummel', 'lake', 'laktos', 'lax', 'litopenaeus vannamei', 'lupin', 'lyrtorsk', 'makadamianöt', 'makrill', 'malt',
  'mandel', 'mangrovesnapper', 'marulk', 'mjölk', 'mulle', 'mussla', 'musslor', 'mört', 'nors', 'ost', 'paranöt',
  'pekannöt', 'pigghaj', 'piggvar', 'pistasch', 'pistaschmandel',  'queenslandsnöt', 'regnbåg', 'regnbåge', 'ruda',
  'råg', 'räk', 'röd snapper', 'rödspätt', 'rödspätta', 'rödtunga', 'sandskädd', 'sandskädda', 'sardin', 'sej', 'selleri',
  'senap', 'sesam', 'sesamfrö', 'sik', 'siklöja', 'sill', 'sjurygg', 'sjötunga', 'skarpsill', 'skrubbskädda', 'slätvar',
  'smör', 'snigel', 'snäck', 'soja', 'spelt', 'strömming', 'sulfit', 'sutare', 'svaveldioxid', 'svärdfisk', 'tilapia',
  'tjockläppad multe', 'tonfisk', 'torsk', 'valnöt', 'vete', 'vitling', 'yoghurt', ' ål', 'ägg', 'öring'
];

export const PRODUCT_CLASSIFICATIONS= ['KLASS ”EXTRA”', 'KLASS 1', 'KLASS 2'];

export const requiresClassification = (foodlaCategory?: IFoodlaCategory) => {
  return foodlaCategory?.name === 'Frukter, bär och grönsaker';
};

interface ILabel {
  title: string;
  field: string;
}

export const MAIN_LABELS: ILabel[] = [
  { title: 'Ekologiskt', field: 'ecological' },
  { title: 'Laktosfri', field: 'lactoseFree' },
  { title: 'Glutenfri, Det överkorsade axet', field: 'glutenFree' },
  { title: 'Veganskt', field: 'vegan' },
  { title: 'I\'m Vegan', field: 'iAmVegan'},
  { title: 'Vegetariskt', field: 'vegetarian' },
  { title: 'Nyckelhål', field: 'nyckelhal' },
  { title: 'Äggfri', field: 'aggfri' },
  { title: 'Låg sockerhalt', field: 'lowSugar' },
  { title: 'Hög fiberhalt', field: 'hogFiberhalt' },
  { title: 'Låg fetthalt', field: 'lowFat' },
  { title: 'Från Sverige', field: 'fromSweden' },
  { title: 'Fairtrade', field: 'fairtrade' },
  { title: 'Svenskt sigill', field: 'svensktSigill' },
  { title: 'Svenskt sigill klimatcertifierad', field: 'svensktSigillKlimatcertifierad' },
  { title: 'Svenskt sigill naturbeteskött', field: 'svensktSigillNaturbeteskott' },
  { title: 'Kött från Sverige', field: 'kottFranSverige' },
  { title: 'Mjölk från Sverige', field: 'milkSweden' },
  { title: 'Svensk fågel', field: 'svenskFagel' },
  { title: 'Äkta Vara', field: 'aktaVara' },
  { title: 'Fri från mjölkprotein', field: 'friFranMjolkprotein' },
  { title: 'ASC', field: 'ASC' },
  { title: 'MSC', field: 'MSC' },
  { title: 'FSC Forest Steward Council 100 percent', field: 'FSC' },
  { title: 'FSC Forest Stewardship Council', field: 'FSCStewardshipCouncil' },
  { title: 'FSC Forest Steward Council Mix', field: 'FSCStewardshipCouncilMix' },
  { title: 'FSC Forest Steward Council Recycled', field: 'FSCStewardshipCouncilRecycled' },
  { title: 'Rainforest alliance', field: 'rainforestAlliance' },
  { title: 'Hög Koffeinhalt', field: 'highCoffein' },
  { title: 'Global Organic Textile Standard', field: 'globalOrganicTextileStandard' },
  { title: 'Rekommenderas av Astma och Allergiförbundet', field: 'approvedByAsthmaAndAllergyAssoc' },
  { title: 'Fairtrade Cotton', field: 'fairtradeCotton' },
  { title: 'Fairtrade Cacao', field: 'fairtradeCacao' },
  { title: 'Fairtrade Sugar', field: 'fairtradeSugar' },
];

// EKO-Märkingar
export const ECO_LABELS: ILabel[] = [
  { title: 'Australian Certified Organic', field: 'australianOrganic' },
  { title: 'BDIH', field: 'BDIH' },
  { title: 'Ecocert Cosmos Natural', field: 'ecocertCosmosNatural' },
  { title: 'Ecocert Cosmos Organic', field: 'ecocertCosmosOrganic' },
  { title: 'EcoControl (COSMOS)', field: 'ecoControl' },
  { title: 'EU-lövet', field: 'EULeaf' },
  { title: 'KRAV', field: 'kravmarkt' },
  { title: 'Natrue', field: 'nature' },
  { title: 'NSF', field: 'NSF' },
  { title: 'Oasis', field: 'oasis' },
  { title: 'Oeko-Tex', field: 'oekoTex' },
  { title: 'OEKO-Tex Made in Green', field: 'oekoTexGreen' },
  { title: 'Soil Association', field: 'soliAssociation' },
  { title: 'USDA', field: 'USDA' },
  { title: 'ECOCERT', field: 'ecoCert' },
  { title: 'Annan EKO märkning/ certifiering', field: 'otherLabelingECO' },
];

// Veganska märkningar
export const VEGAN_LABELS: ILabel[] = [
  { title: 'Vegan Trademark', field: 'veganTrademark' },
  { title: 'Certified Vegan', field: 'certifiedVegan' },
  { title: 'Crueltyfree and Vegan', field: 'crueltyFreeVegan' },
  { title: 'V-label vegan', field: 'vLabelVegan' },
  { title: 'Vegan Society', field: 'veganSociety' },
  { title: 'Annan vegansk märkning/ certifiering', field: 'otherLabelingVegan' },
];

export const ENVIRONMENTAL_LABEL: ILabel[] = [
  { title: 'Bra Miljöval', field: 'braMiljoval' },
  { title: 'Svanen', field: 'svanen' },
  { title: 'EU Ecolabel (Eu-blomman)', field: 'EUEcolabel' },
  { title: 'Annan miljö märkning/ certifiering', field: 'otherLabelingEnvironment' }
];

export const getPercentageValues = () => {
  const values: number[] = new Array(35).fill(0.1);
  const strValues: string[] = new Array(35).fill('');
  for (let i = 1; i < 36; i++) {
    strValues[i - 1] = (values[i - 1] * i).toFixed(1);
  }
  // TODO
  // values.splice(0, 0, 0.0);
  return strValues;
};

export const optionalAlcoholPercentage = (foodlaCategory?: IFoodlaCategory) => {
  return equals(foodlaCategory?.name, 'Drycker');
};

export enum PackageSizeUnit {
  styck = 'styck',
  pack = 'pack',
  set = 'set',
  par = 'par',
};

export const PRODUCT_DATE_FIELD: (keyof IProduct)[] = [
  'basicDataAvailabilityDateFrom',
  'articleFirstOrderDate',
  'articleFirstDelivery',
];

export const PRODUCT_ENUM_FIELD: (keyof IProduct)[] = [
  'basicDataTaxClassification',
  'purchasingDataIncoterm',
  'purchasingDataPickupLocation',
  'package_size_unit',
  'descriptive_size_unit',
  'nutritional_unit',
  'classification',

  // new
  'securityNotices',
  'hazardStatements',
  'animalCategoriesForFeed',
  'labels',
  'hazardSymbols',
  'sunProtectionFactor',
  'categoryWeightloss',
  'preparedForm',
  'signalWords',
  'recyclingType',
  'eggWeightClass',
  'catchArea',
  'fishCatchMethod',
  'fishProductionMethod',
  'additionalCharacteristics',
  'complianceMetalsSkinContact',
  'confirmGuidanceSwedishElectronics',
  'complianceToyDirective',
  'complianceBiocid',
  'complianceBiocidalTreated',
  'complianceSunscreenRegulation',

  // boolean + enum
  'approvalDietFeed',
];

export const PRODUCT_ENUM_FIELD_VALUES: Record<string, { value: string, convertTo?: string | number }[]> = {
  basicDataTaxClassification: [
    // set [percent value] first to convert from saved product
    { value: '0%', convertTo: 0 },
    { value: '6%', convertTo: 6 },
    { value: '12%', convertTo: 12 },
    { value: '25%', convertTo: 25 },
    { value: '0', convertTo: 0 },
    { value: '6', convertTo: 6 },
    { value: '12', convertTo: 12 },
    { value: '25', convertTo: 25 },
  ],
  purchasingDataIncoterm: [
    { value: 'DDP (Delivered Duty Paid)', convertTo: 'DDP' },
    { value: 'DDP' },
    { value: 'FCA (Free Carrier)', convertTo: 'FCA' },
    { value: 'FCA' },
  ],
  purchasingDataPickupLocation: [
    { value: 'D100' },
    { value: 'D200' },
    { value: 'D300' },
    { value: 'D210' },
    { value: 'D400' },
    { value: 'D500' },
  ],
  package_size_unit: [
    { value: 'st', convertTo: PackageSizeUnit.styck },
    { value: 'styck', convertTo: PackageSizeUnit.styck },
    { value: 'pack', convertTo: PackageSizeUnit.pack },
    { value: 'par', convertTo: PackageSizeUnit.par },
  ],
  descriptive_size_unit: [
    { value: 'gram', convertTo: 'GRAM' },
    { value: 'gr', convertTo: 'GRAM' },
    { value: 'g', convertTo: 'GRAM' },
    { value: 'kg', convertTo: 'KILOGRAM' },
    { value: 'st', convertTo: 'ITEM' },
    { value: 'ml', convertTo: 'MILLILITER' },
    { value: 'l', convertTo: 'LITER' },
    { value: 'gram ungefärlig vikt', convertTo: 'GRAM_APPROXIMATE' },
  ],
  nutritional_unit: [
    { value: 'gram', convertTo: NutritionalUnit.GRAM },
    { value: 'gr', convertTo: NutritionalUnit.GRAM },
    { value: 'g', convertTo: NutritionalUnit.GRAM },
  ],
}

export const PRODUCT_NUMBER_FIELD: (keyof IProduct)[] = [
  'gross_weight_num',
  'gross_height',
  'gross_width',
  'gross_depth',
  'consumer_size_to_order_size_ratio',
  'package_size',
  'transportMinTemperature',
  'transportMaxTemperature',

  'min_temperature',
  'max_temperature',
  'descriptive_size_amount',
  'descriptive_size_amount_extra',

  'energi_kj',
  'energi_kcal',
  'fett',
  'mattatFett',
  'kolhydrat',
  'sockerarter',
  'fiber',
  'protein',
  'salt',
  'flerromattatFett',
  'enkelomattatFett',

  // Consumer Pack
  'consumerPackNetWeight',
  // Store Pack
  'storePackTradeItemMeasurementsLengthDepth',
  'storePackTradeItemMeasurementsWidth',
  'storePackTradeItemMeasurementsHeight',
  'storePackGrossWeight',
  // Transport Pack
  'transportQtyOfNextLowerItem',
  'transportTradeItemMeasurementsLengthDepth',
  'transportTradeItemMeasurementsWidth',
  'transportTradeItemMeasurementsHeight',
  'transportGrossWeight',
  // Pallet Layer
  'palletLayerQtyOfNextLowerItem',
  'palletLayerTradeItemMeasurementsLengthDepth',
  'palletLayerTradeItemMeasurementsWidth',
  'palletLayerTradeItemMeasurementsHeight',
  'palletLayerGrossWeight',
  // Pallet
  'palletQtyOfNextLowerItem',
  'palletQtyOfStorePack',
  'palletQtyOfBaseItem',
  'transportQtyOfBaseItem',
  'palletTradeItemMeasurementsLengthDepth',
  'palletTradeItemMeasurementsWidth',
  'palletTradeItemMeasurementsHeight',
  'palletGrossWeight',
  // Price
  'priceListPrice',
  'priceRecommendedConsumerPrice',
];

export const PRODUCT_BOOLEAN_FIELD: (keyof IProduct)[] = [
  'basicDataMixCategory',
  'basicDataTradeItemVariableUnit',
  'basicDataCbuIndicator',

  // Consumer Pack
  'consumerPackOrderableUnit',
  'consumerPackDespatchUnit',
  // Store Pack
  'storePackOrderableUnit',
  'storePackDespatchUnit',
  // Transport Pack
  'transportOrderableUnit',
  'transportDespatchUnit',
  // Pallet Layer
  'palletLayerOrderableUnit',
  'palletLayerDespatchUnit',
  // Pallet
  'palletOrderableUnit',
  'palletDespatchUnit',

  // questions
  'reachSubstance',
  'bestBeforeDate',
  'childrenUnder16',
  'reachSubstancesAnnex19072006',
  'hazardousSubstancesOver1',
  'complianceMedicalTechProducts',
  'checkUniqueIdentification',
  'cmrSubstance',
  'approvalDietFeed',

  'plasticTrayPackaging',
];

export const PRODUCT_EAN_FIELDS: (keyof IProduct)[] = [
  'EAN',
  'storePackEANForStorePack',
  'transportEANForStorePack',
  'palletLayerEANForStorePack',
  'palletEANForStorePack',
];

export const PRODUCT_BOOLEAN_FIELD_VALUES: Record<string, { valueList: string[]; convertTo: boolean }[]> = {
  all: [
    { valueList: ['yes', 'true', '1'], convertTo: true },
    { valueList: ['no', 'false', '0'], convertTo: false },
  ],
  basicDataMixCategory: [
    { valueList: ['single article', 'single'], convertTo: false },
    { valueList: ['mix article', 'mix'], convertTo: true },
  ],
};

export const MANDATORY_FOOD_NUTRITIONAL_FIELD_SET = new Set<keyof IProduct>([
  'energi_kj',
  'energi_kcal',
  'fett',
  'mattatFett',
  'kolhydrat',
  'sockerarter',
  'protein',
  'salt',
  'nutritional_unit',
]);

export const ALWAYS_DISPLAY_FOOD_FIELD_SET = new Set<keyof IProduct>([
  'title', // Produktnamn / Beteckning
  'EAN', // EAN-kod
  'ingredient_statement', // Ingrediensförteckning
  ...Array.from(MANDATORY_FOOD_NUTRITIONAL_FIELD_SET), // Näringsdeklaration
  'descriptive_size_amount', // Nettokvantitet mängd
  'descriptive_size_unit', // Nettokvantitet enhet
  'trade_item_temperature_information', // Förvaringsanvisning
  'place_of_item_activity', // Ursprungsland och härkomstplats
  'brand_food', // Varumärke
]);

export const MANDATORY_FOOD_FIELD_SET = new Set<keyof IProduct>([
  'title',
  'EAN',
  'ingredient_statement',
  'descriptive_size_amount',
  'descriptive_size_unit',
  'trade_item_temperature_information',
  'place_of_item_activity',
  'brand',

  // TODO make main set
  'basicDataMixCategory',
  'basicDataTradeItemVariableUnit',
  'basicDataTaxClassification',
  'basicDataPriceComparisonContentType',
  'basicDataAvailabilityDateFrom',
  'basicDataCbuIndicator',
  // Consumer Pack
  'consumerPackOrderableUnit',
  'consumerPackDespatchUnit',
  'consumerPackNetWeight',
  // Store Pack
  'storePackEANForStorePack',
  'storePackOrderableUnit',
  'storePackDespatchUnit',
  'storePackTradeItemMeasurementsLengthDepth',
  'storePackTradeItemMeasurementsWidth',
  'storePackTradeItemMeasurementsHeight',
  'storePackGrossWeight',
  // Transport Pack
  // Pallet Layer
  'palletLayerQtyOfNextLowerItem',
  'palletLayerOrderableUnit',
  'palletLayerDespatchUnit',
  'palletLayerTradeItemMeasurementsLengthDepth',
  'palletLayerTradeItemMeasurementsWidth',
  'palletLayerTradeItemMeasurementsHeight',
  'palletLayerGrossWeight',
  // Pallet
  'palletEANForStorePack',
  'palletQtyOfNextLowerItem',
  'palletOrderableUnit',
  'palletDespatchUnit',
  'palletTradeItemMeasurementsLengthDepth',
  'palletTradeItemMeasurementsWidth',
  'palletTradeItemMeasurementsHeight',
  'palletGrossWeight',
  // Price
  'priceListPriceCurrency',
  'priceListPrice',
  'priceRecommendedConsumerPrice',
  // Purchasing Data
  'purchasingDataIncoterm',
  'purchasingDataPickupLocation',
]);

export const ALWAYS_DISPLAY_NON_FOOD_FIELD_SET = new Set<keyof IProduct>([
  'title', // Produktnamn / Beteckning
  'EAN', // EAN-kod
  'package_size', // Förpackningsstorlek Mängd
  'package_size_unit', // Förpackningsstorlek Enhet
  'country_of_manufacturing_string', // Tillverkningsland
  'brand', // Varumärke
]);

export const MANDATORY_NON_FOOD_FIELD_SET = new Set<keyof IProduct>([
  'title',
  'EAN',
  'package_size',
  'package_size_unit',
  'country_of_manufacturing_string',
  'brand',

  // TODO make main set
  'basicDataMixCategory',
  'basicDataTradeItemVariableUnit',
  'basicDataTaxClassification',
  'basicDataPriceComparisonContentType',
  'basicDataAvailabilityDateFrom',
  'basicDataCbuIndicator',
  // Consumer Pack
  'consumerPackOrderableUnit',
  'consumerPackDespatchUnit',
  'consumerPackNetWeight',
  // Store Pack
  'storePackEANForStorePack',
  'storePackOrderableUnit',
  'storePackDespatchUnit',
  'storePackTradeItemMeasurementsLengthDepth',
  'storePackTradeItemMeasurementsWidth',
  'storePackTradeItemMeasurementsHeight',
  'storePackGrossWeight',
  // Transport Pack
  // Pallet Layer
  'palletLayerQtyOfNextLowerItem',
  'palletLayerOrderableUnit',
  'palletLayerDespatchUnit',
  'palletLayerTradeItemMeasurementsLengthDepth',
  'palletLayerTradeItemMeasurementsWidth',
  'palletLayerTradeItemMeasurementsHeight',
  'palletLayerGrossWeight',
  // Pallet
  'palletEANForStorePack',
  'palletQtyOfNextLowerItem',
  'palletOrderableUnit',
  'palletDespatchUnit',
  'palletTradeItemMeasurementsLengthDepth',
  'palletTradeItemMeasurementsWidth',
  'palletTradeItemMeasurementsHeight',
  'palletGrossWeight',
  // Price
  'priceListPriceCurrency',
  'priceListPrice',
  'priceRecommendedConsumerPrice',
  // Purchasing Data
  'purchasingDataIncoterm',
  'purchasingDataPickupLocation',
]);

export const ALL_HEADERS: IExcelHeader = {
  'Produktnamn / Beteckning': "title",
  'Livsmedlets beteckning': 'title',
  'Artikelnr': "article",
  'artikelnr': "article",
  'artikel.nr.': "article",
  'article': "article",
  'Färg': "color",
  'Produktbeskrivning': "short_text",
  'B-packstorlek (Kfp/Dfp)': "consumer_size_to_order_size_ratio",
  'Förpackningsstorlek Mängd': "package_size",
  'Förpackningsstorlek': "package_size",
  'Förpackningsstorlek Enhet': 'package_size_unit',
  'Enhet': "package_size_unit",
  'Material': "material",
  'Mått': "dimensions",
  'På produkten angiven EAN-kod': "EAN",
  'ArtNr EAN': 'EAN',
  'EAN-kod (13 eller 8 siffror)': 'EAN',
  'EAN': "EAN",
  'Volym': "capacity",
  'Varumärke': "brand",
  'Bruttovikt': 'gross_weight_num',
  'Förpackningens dimensioner - hight': "gross_height",
  'Förpackningens dimensioner - width': "gross_width",
  'Förpackningens dimensioner - depth': "gross_depth",
  'Sökbegrepp och nyckelord': "search_terms",
  'Kategori': 'foodlaCategory',
  'Produktnamn': 'title',
  'Pa produkten angiven EAN-kod': 'EAN',
  'Ingrediensforteckning': 'ingredient_statement',
  'Ingrediensförteckning': 'ingredient_statement',
  'Temp. Min': 'min_temperature',
  'Förvaringstemperatur Min': 'min_temperature',
  'Temp. Max': 'max_temperature',
  'Förvaringstemperatur Max': 'max_temperature',
  'Förvaringsanvisning': 'trade_item_temperature_information',
  'Ursprungsland och härkomstplats': 'place_of_item_activity',
  'Tillverkningsland (Artikelns tillverkningsland är det land där den genomgick den sista väsentliga bearbetningen.)': 'country_of_manufacturing_string',
  'Tillverkningsland': 'country_of_manufacturing_string',
  'Nettokvantitet mängd': 'descriptive_size_amount',
  'Nettokvantitet Mängd': 'descriptive_size_amount',
  'Nettokvantitet enhet': 'descriptive_size_unit',
  'Nettokvantitet Enhet': 'descriptive_size_unit',
  'Naringsvarde per 100': 'nutritional_unit',
  'Näringsvärde per 100': 'nutritional_unit',
  'Energi kJ': 'energi_kj',
  'Energi kcal': 'energi_kcal',
  'Fett': 'fett',
  'Mattat fett': 'mattatFett',
  'mättat fett': 'mattatFett',
  'enkelomättat fett': 'enkelomattatFett',
  'fleromättat fett': 'flerromattatFett',
  'Kolhydrat': 'kolhydrat',
  'Sockerarter': 'sockerarter',
  'sockerarter': 'sockerarter',
  'Fiber': 'fiber',
  'Protein': 'protein',
  'Salt': 'salt',
  'Bruttovikt (GRAM)': 'gross_weight_num',
  'Höjd - Förpackningens dimensioner (MM)': 'gross_height',
  'Höjd - Förpackningens dimensioner': 'gross_height',
  'Förpackningens dimensioner Höjd (MM)': 'gross_height',
  'Bredd - Förpackningens dimensioner (MM)': 'gross_width',
  'Bredd - Förpackningens dimensioner': 'gross_width',
  'Förpackningens dimensioner Bredd (MM)': 'gross_width',
  'Djup - Förpackningens dimensioner (MM)': 'gross_depth',
  'Djup - Förpackningens dimensioner': 'gross_depth',
  'Förpackningens dimensioner Djup (MM)': 'gross_depth',
  'Coop Item Categories Online': 'ItemCategoriesOnline',

  // MASTER DATA
  // Basic Data
  'Article Category (Single/Mix)': 'basicDataMixCategory',
  'Trade item variable unit': 'basicDataTradeItemVariableUnit',
  'Tax classification': 'basicDataTaxClassification',
  'Price comparison content type': 'basicDataPriceComparisonContentType',
  'Availability date from': 'basicDataAvailabilityDateFrom',
  'CBU Indicator': 'basicDataCbuIndicator',
  // Consumer Pack
  'Consumer pack- Is Orderable Unit?': 'consumerPackOrderableUnit',
  'Consumer pack- Is Despatch Unit?': 'consumerPackDespatchUnit',
  'Net weight(G)': 'consumerPackNetWeight',
  // Store Pack
  'Store pack- EAN/GTIN': 'storePackEANForStorePack',
  'Store pack- Is Orderable Unit?': 'storePackOrderableUnit',
  'Store pack- Is Despatch Unit?': 'storePackDespatchUnit',
  'Store pack- depth/Length(MM)': 'storePackTradeItemMeasurementsLengthDepth',
  'Store pack- width(MM)': 'storePackTradeItemMeasurementsWidth',
  'Store pack- height(MM)': 'storePackTradeItemMeasurementsHeight',
  'Store pack- Gross weight (G)': 'storePackGrossWeight',
  // Transport Pack
  'Transport Pack- EAN/GTIN': 'transportEANForStorePack',
  'Transport Pack- Qty of next lower item': 'transportQtyOfNextLowerItem',
  'Transport Pack- Is Orderable Unit?': 'transportOrderableUnit',
  'Transport Pack- Is Despatch Unit?': 'transportDespatchUnit',
  'Transport Pack- depth/Length(MM)': 'transportTradeItemMeasurementsLengthDepth',
  'Transport Pack- width(MM)': 'transportTradeItemMeasurementsWidth',
  'Transport Pack- height(MM)': 'transportTradeItemMeasurementsHeight',
  'Transport Pack- Gross weight (G)': 'transportGrossWeight',
  // Pallet Layer
  'Pallet Layer- EAN/GTIN': 'palletLayerEANForStorePack',
  'Pallet Layer- Qty of next lower item': 'palletLayerQtyOfNextLowerItem',
  'Pallet Layer- Is Orderable Unit?': 'palletLayerOrderableUnit',
  'Pallet Layer- Is Despatch Unit?': 'palletLayerDespatchUnit',
  'Pallet Layer- depth/Length(MM)': 'palletLayerTradeItemMeasurementsLengthDepth',
  'Pallet Layer- width(MM)': 'palletLayerTradeItemMeasurementsWidth',
  'Pallet Layer- height(MM)': 'palletLayerTradeItemMeasurementsHeight',
  'Pallet Layer- Gross weight (G)': 'palletLayerGrossWeight',
  // Pallet
  'Pallet- EAN/GTIN': 'palletEANForStorePack',
  'Pallet- Qty of next lower item': 'palletQtyOfNextLowerItem',
  'Pallet- Is Orderable Unit?': 'palletOrderableUnit',
  'Pallet- Is Despatch Unit?': 'palletDespatchUnit',
  'Pallet- depth/Length(MM)': 'palletTradeItemMeasurementsLengthDepth',
  'Pallet- width(MM)': 'palletTradeItemMeasurementsWidth',
  'Pallet- height(MM)': 'palletTradeItemMeasurementsHeight',
  'Pallet- Gross weight (G)': 'palletGrossWeight',
  // Price
  'List Price Currency': 'priceListPriceCurrency',
  'List price': 'priceListPrice',
  'Recommended consumer price(Retail price Large)': 'priceRecommendedConsumerPrice',
  // Purchasing Data
  'Incoterm': 'purchasingDataIncoterm',
  'Pickup location if FCA or port if FOB, according to the logistics agreement': 'purchasingDataPickupLocation',
};

export const ORDERED_FIELDS = [
  // Bold
  'foodlaCategory', // 'Kategori'
  'title', // 'Produktnamn / Beteckning'
  'article', // 'Artikelnr'
  'EAN',
  'short_text', // 'Produktbeskrivning'
  'color', // 'Färg'
  'material', // 'Material'
  'ingredient_statement', // 'Ingrediensforteckning'

  // Food
  'nutritional_unit', // 'Naringsvarde per 100'
  'energi_kj', // 'Energi kJ'
  'energi_kcal', // 'Energi kcal'
  'fett', // 'Fett'
  'mattatFett', // 'Mattat fett'
  'enkelomattatFett', // 'enkelomättat fett'
  'flerromattatFett', // 'fleromättat fett'
  'kolhydrat', // 'Kolhydrat'
  'sockerarter', // 'Sockerarter'
  'fiber', // 'Fiber'
  'protein', // 'Protein'
  'salt', // 'Salt'
  'descriptive_size_amount', // 'Nettokvantitet Mängd'
  'descriptive_size_unit', // 'Nettokvantitet Enhet'
  'min_temperature', // 'Förvaringstemperatur Min'
  'max_temperature', // 'Förvaringstemperatur Max'
  'trade_item_temperature_information', // 'Förvaringsanvisning'
  'place_of_item_activity', // 'Ursprungsland och härkomstplats'

  // Non food
  'dimensions', // 'Mått'
  'capacity', // 'Volym'
  'package_size', // 'Förpackningsstorlek Mängd'
  'package_size_unit', // 'Förpackningsstorlek Enhet'

  // Bold
  'country_of_manufacturing_string', // 'Tillverkningsland'
  'search_terms', // 'Sökbegrepp och nyckelord'

  // Non food
  'consumer_size_to_order_size_ratio', // 'B-packstorlek (Kfp/Dfp)'
  'gross_weight_num', // 'Bruttovikt (GRAM)'
  'gross_height', // 'Förpackningens dimensioner - hight'
  'gross_width', // 'Förpackningens dimensioner - width'
  'gross_depth', // 'Förpackningens dimensioner - depth'

  // Bold
  'brand', // 'Varumärke'

  // MASTER DATA ------------------------------
  // Basic Data
  'basicDataMixCategory', // 'Article Category (Single/Mix)'
  'basicDataTradeItemVariableUnit', // 'Trade item variable unit'
  'basicDataTaxClassification', // 'Tax classification'
  'basicDataPriceComparisonContentType', // 'Price comparison content type'
  'basicDataAvailabilityDateFrom', // 'Availability date from'
  'basicDataCbuIndicator', // 'CBU Indicator'
  // Consumer Pack
  'consumerPackOrderableUnit', // 'Consumer pack- Is Orderable Unit?'
  'consumerPackDespatchUnit', // 'Consumer pack- Is Despatch Unit?'
  'consumerPackNetWeight', // 'Net weight(G)'
  // Store Pack
  'storePackEANForStorePack', // 'Store pack- EAN/GTIN'
  'storePackOrderableUnit', // 'Store pack- Is Orderable Unit?'
  'storePackDespatchUnit', // 'Store pack- Is Despatch Unit?'
  'storePackTradeItemMeasurementsLengthDepth', // 'Store pack- depth/Length(MM)'
  'storePackTradeItemMeasurementsWidth', // 'Store pack- width(MM)'
  'storePackTradeItemMeasurementsHeight', // 'Store pack- height(MM)'
  'storePackGrossWeight', // 'Store pack- Gross weight (G)'
  // Transport Pack
  'transportEANForStorePack', // 'Transport Pack- EAN/GTIN'
  'transportQtyOfNextLowerItem', // 'Transport Pack- Qty of next lower item'
  'transportOrderableUnit', // 'Transport Pack- Is Orderable Unit?'
  'transportDespatchUnit', // 'Transport Pack- Is Despatch Unit?'
  'transportTradeItemMeasurementsLengthDepth', // 'Transport Pack- depth/Length(MM)'
  'transportTradeItemMeasurementsWidth', // 'Transport Pack- width(MM)'
  'transportTradeItemMeasurementsHeight', // 'Transport Pack- height(MM)'
  'transportGrossWeight', // 'Transport Pack- Gross weight (G)'
  // Pallet Layer
  'palletLayerEANForStorePack', // 'Pallet Layer- EAN/GTIN'
  'palletLayerQtyOfNextLowerItem', // 'Pallet Layer- Qty of next lower item'
  'palletLayerOrderableUnit', // 'Pallet Layer- Is Orderable Unit?'
  'palletLayerDespatchUnit', // 'Pallet Layer- Is Despatch Unit?'
  'palletLayerTradeItemMeasurementsLengthDepth', // 'Pallet Layer- depth/Length(MM)'
  'palletLayerTradeItemMeasurementsWidth', // 'Pallet Layer- width(MM)'
  'palletLayerTradeItemMeasurementsHeight', // 'Pallet Layer- height(MM)'
  'palletLayerGrossWeight', // 'Pallet Layer- Gross weight (G)'
  // Pallet
  'palletEANForStorePack', // 'Pallet- EAN/GTIN'
  'palletQtyOfNextLowerItem', // 'Pallet- Qty of next lower item'
  'palletOrderableUnit', // 'Pallet- Is Orderable Unit?'
  'palletDespatchUnit', // 'Pallet- Is Despatch Unit?'
  'palletTradeItemMeasurementsLengthDepth', // 'Pallet- depth/Length(MM)'
  'palletTradeItemMeasurementsWidth', // 'Pallet- width(MM)'
  'palletTradeItemMeasurementsHeight', // 'Pallet- height(MM)'
  'palletGrossWeight', // 'Pallet- Gross weight (G)'
  // Price
  'priceListPriceCurrency', // 'List Price Currency'
  'priceListPrice', // 'List price'
  'priceRecommendedConsumerPrice', // 'Recommended consumer price(Retail price Large)'
  // Purchasing Data
  'purchasingDataIncoterm', // 'Incoterm'
  'purchasingDataPickupLocation', // 'Pickup location if FCA or port if FOB, according to the logistics agreement'
  // ------------------------------

  // Bold
  'ItemCategoriesOnline', // 'Coop Item Categories Online'
];

export const eggWeightClass = [
  { value: 'XL', displayName: EggWeightClassEnum.XL },
  { value: 'L_XL', displayName: EggWeightClassEnum.L_XL },
  { value: 'L', displayName: EggWeightClassEnum.L },
  { value: 'M_L', displayName: EggWeightClassEnum.M_L },
  { value: 'M', displayName: EggWeightClassEnum.M },
  { value: 'S_M', displayName: EggWeightClassEnum.S_M },
  { value: 'S', displayName: EggWeightClassEnum.S }
];

export const recyclingType = [
  { value: 'BOTTLE', displayName: RecyclingTypeEnum.BOTTLE },
  { value: 'GLASS_BOTTLE', displayName: RecyclingTypeEnum.GLASS_BOTTLE },
  { value: 'RECYCLABLE_PET', displayName: RecyclingTypeEnum.RECYCLABLE_PET },
  { value: 'RECYCLABLE_PET_UP_TO_1000', displayName: RecyclingTypeEnum.RECYCLABLE_PET_UP_TO_1000 },
  { value: 'ALUMINUM_CAN', displayName: RecyclingTypeEnum.ALUMINUM_CAN }
];

export const animalCategoriesForFeed = [
  {value: 'AMPHIBIANS', displayName: AnimalCategoriesForFeedEnum.AMPHIBIANS},
  {value: 'ANOLIS', displayName: AnimalCategoriesForFeedEnum.ANOLIS},
  {value: 'ARACHNIDS', displayName: AnimalCategoriesForFeedEnum.ARACHNIDS},
  {value: 'POGONA', displayName: AnimalCategoriesForFeedEnum.POGONA},
  {value: 'BIRDS', displayName: AnimalCategoriesForFeedEnum.BIRDS},
  {value: 'MOLLUSCS', displayName: AnimalCategoriesForFeedEnum.MOLLUSCS},
  {value: 'CAT', displayName: AnimalCategoriesForFeedEnum.CAT},
  {value: 'WHALE', displayName: AnimalCategoriesForFeedEnum.WHALE},
  {value: 'CHAMELEON', displayName: AnimalCategoriesForFeedEnum.CHAMELEON},
  {value: 'CHINCHILLA', displayName: AnimalCategoriesForFeedEnum.CHINCHILLA},
  {value: 'CRUSTACEAN', displayName: AnimalCategoriesForFeedEnum.CRUSTACEAN},
  {value: 'DOG', displayName: AnimalCategoriesForFeedEnum.DOG},
  {value: 'FISH', displayName: AnimalCategoriesForFeedEnum.FISH},
  {value: 'GECKO', displayName: AnimalCategoriesForFeedEnum.GECKO},
  {value: 'GERBIL', displayName: AnimalCategoriesForFeedEnum.GERBIL},
  {value: 'GOAT', displayName: AnimalCategoriesForFeedEnum.GOAT},
  {value: 'GUINEA_PIG', displayName: AnimalCategoriesForFeedEnum.GUINEA_PIG},
  {value: 'HAMSTER', displayName: AnimalCategoriesForFeedEnum.HAMSTER},
  {value: 'HORSE', displayName: AnimalCategoriesForFeedEnum.HORSE},
  {value: 'FERRET', displayName: AnimalCategoriesForFeedEnum.FERRET},
  {value: 'HEDGEHOG', displayName: AnimalCategoriesForFeedEnum.HEDGEHOG},
  {value: 'HUMAN', displayName: AnimalCategoriesForFeedEnum.HUMAN},
  {value: 'IGUANA', displayName: AnimalCategoriesForFeedEnum.IGUANA},
  {value: 'INSECT', displayName: AnimalCategoriesForFeedEnum.INSECT},
  {value: 'MOUSE', displayName: AnimalCategoriesForFeedEnum.MOUSE},
  {value: 'PIG', displayName: AnimalCategoriesForFeedEnum.PIG},
  {value: 'PIGEON', displayName: AnimalCategoriesForFeedEnum.PIGEON},
  {value: 'PRIMATE', displayName: AnimalCategoriesForFeedEnum.PRIMATE},
  {value: 'RABBIT', displayName: AnimalCategoriesForFeedEnum.RABBIT},
  {value: 'RAT', displayName: AnimalCategoriesForFeedEnum.RAT},
  {value: 'REPTILE', displayName: AnimalCategoriesForFeedEnum.REPTILE},
  {value: 'RODENT', displayName: AnimalCategoriesForFeedEnum.RODENT},
  {value: 'SHEEP', displayName: AnimalCategoriesForFeedEnum.SHEEP},
  {value: 'SNAIL', displayName: AnimalCategoriesForFeedEnum.SNAIL},
  {value: 'TURTLE', displayName: AnimalCategoriesForFeedEnum.TURTLE},
  {value: 'UNCLASSIFIED', displayName: AnimalCategoriesForFeedEnum.UNCLASSIFIED},
  {value: 'UNIDENTIFIED', displayName: AnimalCategoriesForFeedEnum.UNIDENTIFIED}
];

export const preparedForm = [
  { value: 'FACIAL_TONER', displayName: PreparedFormEnum.FACIAL_TONER },
  { value: 'BALM', displayName: PreparedFormEnum.BALM },
  { value: 'BAR', displayName: PreparedFormEnum.BAR },
  { value: 'BREAST_TABLET', displayName: PreparedFormEnum.BREAST_TABLET },
  { value: 'DROPS', displayName: PreparedFormEnum.DROPS },
  { value: 'LIQUID', displayName: PreparedFormEnum.LIQUID },
  { value: 'GEL', displayName: PreparedFormEnum.GEL },
  { value: 'GRANULES', displayName: PreparedFormEnum.GRANULES },
  { value: 'COLLAR', displayName: PreparedFormEnum.COLLAR },
  { value: 'INHALER', displayName: PreparedFormEnum.INHALER },
  { value: 'INJECTION', displayName: PreparedFormEnum.INJECTION },
  { value: 'INSERTS_IMPLANTS', displayName: PreparedFormEnum.INSERTS_IMPLANTS },
  { value: 'WRAPPING', displayName: PreparedFormEnum.WRAPPING },
  { value: 'CAPSULE', displayName: PreparedFormEnum.CAPSULE },
  { value: 'ENEMA', displayName: PreparedFormEnum.ENEMA },
  { value: 'CREAM', displayName: PreparedFormEnum.CREAM },
  { value: 'CLYSTER', displayName: PreparedFormEnum.CLYSTER },
  { value: 'LINIMENT', displayName: PreparedFormEnum.LINIMENT },
  { value: 'LOTION', displayName: PreparedFormEnum.LOTION },
  { value: 'SOLUTION', displayName: PreparedFormEnum.SOLUTION },
  { value: 'MASK', displayName: PreparedFormEnum.MASK },
  { value: 'MICRO_CLYSTER', displayName: PreparedFormEnum.MICRO_CLYSTER },
  { value: 'MILK', displayName: PreparedFormEnum.MILK },
  { value: 'MOUSSE', displayName: PreparedFormEnum.MOUSSE },
  { value: 'ORAL_SPRAY', displayName: PreparedFormEnum.ORAL_SPRAY },
  { value: 'MOUTHWASH', displayName: PreparedFormEnum.MOUTHWASH },
  { value: 'MOUTH_SPRAY', displayName: PreparedFormEnum.MOUTH_SPRAY },
  { value: 'MOUTH_DECAY_TABLET', displayName: PreparedFormEnum.MOUTH_DECAY_TABLET },
  { value: 'NAIL_VARNISH', displayName: PreparedFormEnum.NAIL_VARNISH },
  { value: 'NASAL_DROPS', displayName: PreparedFormEnum.NASAL_DROPS },
  { value: 'NASAL_SHOWER', displayName: PreparedFormEnum.NASAL_SHOWER },
  { value: 'NASAL_RINSE', displayName: PreparedFormEnum.NASAL_RINSE },
  { value: 'NASAL_SPRAY', displayName: PreparedFormEnum.NASAL_SPRAY },
  { value: 'OIL', displayName: PreparedFormEnum.OIL },
  { value: 'PASTES', displayName: PreparedFormEnum.PASTES },
  { value: 'PLASTERS', displayName: PreparedFormEnum.PLASTERS },
  { value: 'SACHETS', displayName: PreparedFormEnum.SACHETS },
  { value: 'POWDERS', displayName: PreparedFormEnum.POWDERS },
  { value: 'POWDER', displayName: PreparedFormEnum.POWDER },
  { value: 'TRAVEL_TABLET', displayName: PreparedFormEnum.TRAVEL_TABLET },
  { value: 'ROLL_ON', displayName: PreparedFormEnum.ROLL_ON },
  { value: 'SALT', displayName: PreparedFormEnum.SALT },
  { value: 'SALVA', displayName: PreparedFormEnum.SALVA },
  { value: 'SHAMPOO', displayName: PreparedFormEnum.SHAMPOO },
  { value: 'SCRUB', displayName: PreparedFormEnum.SCRUB },
  { value: 'SERUM', displayName: PreparedFormEnum.SERUM },
  { value: 'SPRAY', displayName: PreparedFormEnum.SPRAY },
  { value: 'STICK', displayName: PreparedFormEnum.STICK },
  { value: 'SUPPOSITORIES', displayName: PreparedFormEnum.SUPPOSITORIES },
  { value: 'LOZENGE', displayName: PreparedFormEnum.LOZENGE },
  { value: 'TABLET', displayName: PreparedFormEnum.TABLET },
  { value: 'TOOTHPASTE', displayName: PreparedFormEnum.TOOTHPASTE },
  { value: 'CHEWING_CAPSULE', displayName: PreparedFormEnum.CHEWING_CAPSULE },
  { value: 'CHEWING_TABLET', displayName: PreparedFormEnum.CHEWING_TABLET },
  { value: 'CHEWING_GUM', displayName: PreparedFormEnum.CHEWING_GUM },
  { value: 'SOAP', displayName: PreparedFormEnum.SOAP },
  { value: 'VAGINAL_TABLET', displayName: PreparedFormEnum.VAGINAL_TABLET },
  { value: 'VAGITORIUM', displayName: PreparedFormEnum.VAGITORIUM },
  { value: 'WAX', displayName: PreparedFormEnum.WAX },
  { value: 'WET_WIPES', displayName: PreparedFormEnum.WET_WIPES },
  { value: 'EYE_DROPS', displayName: PreparedFormEnum.EYE_DROPS },
  { value: 'NOT_APPLICABLE', displayName: PreparedFormEnum.NOT_APPLICABLE}
];

export const additionalCharacteristics = [
  { value: 'EFFECT_1_2_HOURS', displayName: AdditionalCharacteristicsEnum.EFFECT_1_2_HOURS },
  { value: 'ANTI_AGE', displayName: AdditionalCharacteristicsEnum.ANTI_AGE },
  { value: 'EFFECT_12_HOURS', displayName: AdditionalCharacteristicsEnum.EFFECT_12_HOURS },
  { value: 'EFFECT_24_HOURS', displayName: AdditionalCharacteristicsEnum.EFFECT_24_HOURS },
  { value: 'OILY_SKIN', displayName: AdditionalCharacteristicsEnum.OILY_SKIN },
  { value: 'DOG', displayName: AdditionalCharacteristicsEnum.DOG },
  { value: 'HORSE', displayName: AdditionalCharacteristicsEnum.HORSE },
  { value: 'CAT', displayName: AdditionalCharacteristicsEnum.CAT },
  { value: 'SENSITIVE_SKIN', displayName: AdditionalCharacteristicsEnum.SENSITIVE_SKIN },
  { value: 'NORMAL_MIXED_SKIN', displayName: AdditionalCharacteristicsEnum.NORMAL_MIXED_SKIN },
  { value: 'UNSCENTED', displayName: AdditionalCharacteristicsEnum.UNSCENTED },
  { value: 'PROBLEM_SKIN', displayName: AdditionalCharacteristicsEnum.PROBLEM_SKIN },
  { value: 'SILICONE_FREE', displayName: AdditionalCharacteristicsEnum.SILICONE_FREE },
  { value: 'DRY_SKIN', displayName: AdditionalCharacteristicsEnum.DRY_SKIN }
];

export const categoryWeightloss = [
  { value: 'MEAL_REPLACEMENT', displayName: CategoryWeightlossEnum.MEAL_REPLACEMENT },
  { value: 'LCD', displayName: CategoryWeightlossEnum.LCD },
  { value: 'VLCD', displayName: CategoryWeightlossEnum.VLCD },
  { value: 'NON_WEIGHT_LOSS', displayName: CategoryWeightlossEnum.NON_WEIGHT_LOSS }
];

export const approvalDietFeed = [
  { value: 'YES', displayName: ApprovalDietFeedEnum.YES },
  { value: 'NO', displayName: ApprovalDietFeedEnum.NO },
  { value: 'NOT_DIET', displayName: ApprovalDietFeedEnum.NOT_DIET }
];

export const complianceMetalsSkinContact = [
  { value: 'YES', displayName: ComplianceMetalsSkinContactEnum.YES },
  { value: 'NO', displayName: ComplianceMetalsSkinContactEnum.NO },
  { value: 'NO_DIRECT_CONTACT_WITH_SKIN', displayName: ComplianceMetalsSkinContactEnum.NO_DIRECT_CONTACT_WITH_SKIN }
];

export const confirmGuidanceSwedishElectronics = [
  { value: 'YES', displayName: ConfirmGuidanceSwedishElectronicsEnum.YES },
  { value: 'NO', displayName: ConfirmGuidanceSwedishElectronicsEnum.NO },
  { value: 'NOT_ELECTRICAL', displayName: ConfirmGuidanceSwedishElectronicsEnum.NOT_ELECTRICAL }
];

export const complianceToyDirective = [
  { value: 'YES', displayName: ComplianceToyDirectiveEnum.YES },
  { value: 'NO', displayName: ComplianceToyDirectiveEnum.NO },
  { value: 'NOT_TOY', displayName: ComplianceToyDirectiveEnum.NOT_TOY }
];

export const complianceBiocid = [
  { value: 'YES', displayName: ComplianceBiocidEnum.YES },
  { value: 'NO', displayName: ComplianceBiocidEnum.NO },
  { value: 'NOT_BIOCIDAL', displayName: ComplianceBiocidEnum.NOT_BIOCIDAL }
];

export const complianceBiocidalTreated = [
  { value: 'YES', displayName: ComplianceBiocidalTreatedEnum.YES },
  { value: 'NO', displayName: ComplianceBiocidalTreatedEnum.NO },
  { value: 'NOT_BIOCID_TREATMENT', displayName: ComplianceBiocidalTreatedEnum.NOT_BIOCID_TREATMENT }
];

export const complianceSunscreenRegulation = [
  { value: 'YES', displayName: ComplianceSunscreenRegulationEnum.YES },
  { value: 'NO', displayName: ComplianceSunscreenRegulationEnum.NO },
  { value: 'NO_SUNSCREEN', displayName: ComplianceSunscreenRegulationEnum.NO_SUNSCREEN }
];

export const sunProtectionFactor = [
  { value: 'SIX', displayName: SunProtectionFactorEnum.SIX },
  { value: 'TEN', displayName: SunProtectionFactorEnum.TEN },
  { value: 'FIFTEEN', displayName: SunProtectionFactorEnum.FIFTEEN },
  { value: 'TWENTY', displayName: SunProtectionFactorEnum.TWENTY },
  { value: 'TWENTY_FIVE', displayName: SunProtectionFactorEnum.TWENTY_FIVE },
  { value: 'THIRTY', displayName: SunProtectionFactorEnum.THIRTY },
  { value: 'FIFTY', displayName: SunProtectionFactorEnum.FIFTY },
  { value: 'FIFTY_PLUS', displayName: SunProtectionFactorEnum.FIFTY_PLUS }
];

export const fishProductionMethod = [
  { value: 'CULTIVATION_IN_WATER', displayName: FishProductionMethodEnum.CULTIVATION_IN_WATER },
  { value: 'INLAND_FISHING', displayName: FishProductionMethodEnum.INLAND_FISHING },
  { value: 'SEA_FISHING', displayName: FishProductionMethodEnum.SEA_FISHING },
];

export const fishCatchMethod = [
  { value: 'SNOOD', displayName: FishCatchMethodEnum.SNOOD },
  { value: 'VAD', displayName: FishCatchMethodEnum.VAD },
  { value: 'TRAWLS', displayName: FishCatchMethodEnum.TRAWLS },
  { value: 'DREDGING_GEAR', displayName: FishCatchMethodEnum.DREDGING_GEAR },
  { value: 'SINKERS', displayName: FishCatchMethodEnum.SINKERS },
  { value: 'ENTANGLING_NETS', displayName: FishCatchMethodEnum.ENTANGLING_NETS },
  { value: 'TRAPS', displayName: FishCatchMethodEnum.TRAPS },
  { value: 'HOOKS_AND_LINES', displayName: FishCatchMethodEnum.HOOKS_AND_LINES },
  { value: 'DREDGES_FROM_BOATS', displayName: FishCatchMethodEnum.DREDGES_FROM_BOATS },
  { value: 'HAND_HELD_DREDGES', displayName: FishCatchMethodEnum.HAND_HELD_DREDGES },
  { value: 'GEAR_FOR_CATCHING_LEAPING_FISH', displayName: FishCatchMethodEnum.GEAR_FOR_CATCHING_LEAPING_FISH },
  { value: 'CAST_NETS', displayName: FishCatchMethodEnum.CAST_NETS },
  { value: 'DROP_NETS', displayName: FishCatchMethodEnum.DROP_NETS },
  { value: 'FIXED_UNCOVERED_GILLNETS', displayName: FishCatchMethodEnum.FIXED_UNCOVERED_GILLNETS },
  { value: 'TINOR', displayName: FishCatchMethodEnum.TINOR },
  { value: 'FIXED_HAMMERS', displayName: FishCatchMethodEnum.FIXED_HAMMERS },
  { value: 'TENSION_NETS', displayName: FishCatchMethodEnum.TENSION_NETS },
  { value: 'FYKE_NETS', displayName: FishCatchMethodEnum.FYKE_NETS },
  { value: 'NETS', displayName: FishCatchMethodEnum.NETS },
  { value: 'CIRCLE_NETS', displayName: FishCatchMethodEnum.CIRCLE_NETS },
  { value: 'DRIFTING_NETS', displayName: FishCatchMethodEnum.DRIFTING_NETS },
  { value: 'FIXED_GILLNETS', displayName: FishCatchMethodEnum.FIXED_GILLNETS },
  { value: 'ANCHORED_NETS', displayName: FishCatchMethodEnum.ANCHORED_NETS },
  { value: 'COMBINED_GILL_AND_TRAMMEL_NETS', displayName: FishCatchMethodEnum.COMBINED_GILL_AND_TRAMMEL_NETS },
  { value: 'GILLNETS', displayName: FishCatchMethodEnum.GILLNETS },
  { value: 'HARPOONS', displayName: FishCatchMethodEnum.HARPOONS },
  { value: 'MECHANICAL_DREDGES', displayName: FishCatchMethodEnum.MECHANICAL_DREDGES },
  { value: 'PIMPS', displayName: FishCatchMethodEnum.PIMPS },
  { value: 'MACHINERY', displayName: FishCatchMethodEnum.MACHINERY },
  { value: 'WITHOUT_CORDAGE', displayName: FishCatchMethodEnum.WITHOUT_CORDAGE },
  { value: 'MECHANICAL_JIGGING', displayName: FishCatchMethodEnum.MECHANICAL_JIGGING },
  { value: 'HAND_OPERATED_JIGGING', displayName: FishCatchMethodEnum.HAND_OPERATED_JIGGING },
  { value: 'LONG_LINES', displayName: FishCatchMethodEnum.LONG_LINES },
  { value: 'DRIFTING_TRAPS', displayName: FishCatchMethodEnum.DRIFTING_TRAPS },
  { value: 'ANCHORED_TRAPS', displayName: FishCatchMethodEnum.ANCHORED_TRAPS },
  { value: 'CAISSONS_FROM_BOATS', displayName: FishCatchMethodEnum.CAISSONS_FROM_BOATS },
  { value: 'PORTABLE_DREDGES', displayName: FishCatchMethodEnum.PORTABLE_DREDGES },
  { value: 'SHOALSUSED_FROM_THE_SHORE', displayName: FishCatchMethodEnum.SHOALSUSED_FROM_THE_SHORE },
  { value: 'TOWLINES', displayName: FishCatchMethodEnum.TOWLINES },
  { value: 'PURSE_SEINES_AND_SINKERS', displayName: FishCatchMethodEnum.PURSE_SEINES_AND_SINKERS },
  { value: 'MISCELLANEOUS_OTHER_GEAR', displayName: FishCatchMethodEnum.MISCELLANEOUS_OTHER_GEAR },
  { value: 'UNKNOWN_GEAR', displayName: FishCatchMethodEnum.UNKNOWN_GEAR },
  { value: 'TRAWLS_USED_WITH_TRAWL_TABLES', displayName: FishCatchMethodEnum.TRAWLS_USED_WITH_TRAWL_TABLES },
  { value: 'TRAWL_WITH_TRAWL_TABLE', displayName: FishCatchMethodEnum.TRAWL_WITH_TRAWL_TABLE },
  { value: 'PELAGIC_TRAWL', displayName: FishCatchMethodEnum.PELAGIC_TRAWL },
  { value: 'DOUBLE_TRAWLS', displayName: FishCatchMethodEnum.DOUBLE_TRAWLS },
  { value: 'RINGNOT', displayName: FishCatchMethodEnum.RINGNOT },
  { value: 'PURSE_SEINE_CONTROLLED_FROM_VESSEL', displayName: FishCatchMethodEnum.PURSE_SEINE_CONTROLLED_FROM_VESSEL },
  { value: 'TWO_VESSEL_PERSE_SEINE', displayName: FishCatchMethodEnum.TWO_VESSEL_PERSE_SEINE },
  { value: 'PAIR_TRAWL', displayName: FishCatchMethodEnum.PAIR_TRAWL },
  { value: 'BOTTOM_TRAWL_FOR_PAIR_TRAWLING', displayName: FishCatchMethodEnum.BOTTOM_TRAWL_FOR_PAIR_TRAWLING },
  { value: 'PAIR_TRANSFER_TRAWL', displayName: FishCatchMethodEnum.PAIR_TRANSFER_TRAWL },
  { value: 'FISHING_GEAR_FOR_SPORT_FISHING', displayName: FishCatchMethodEnum.FISHING_GEAR_FOR_SPORT_FISHING },
  { value: 'SHORE_SEINE', displayName: FishCatchMethodEnum.SHORE_SEINE },
  { value: 'PURSE_SEINE', displayName: FishCatchMethodEnum.PURSE_SEINE },
  { value: 'DRAGGED_BY_TWO_VESSELS', displayName: FishCatchMethodEnum.DRAGGED_BY_TWO_VESSELS },
  { value: 'SCOTTISH_PURSE_SEINE', displayName: FishCatchMethodEnum.SCOTTISH_PURSE_SEINE },
  { value: 'PURSE_SEINE_DEPLOYED_FROM_BOATS', displayName: FishCatchMethodEnum.PURSE_SEINE_DEPLOYED_FROM_BOATS },
  { value: 'BOTTOM_TRAWLS', displayName: FishCatchMethodEnum.BOTTOM_TRAWLS },
  { value: 'BEAM_TRAWLS', displayName: FishCatchMethodEnum.BEAM_TRAWLS },
  { value: 'NEPHROPS_TRAWLS', displayName: FishCatchMethodEnum.NEPHROPS_TRAWLS },
  { value: 'SHRIMP_TRAWLS', displayName: FishCatchMethodEnum.SHRIMP_TRAWLS },
  { value: 'PELAGIC_TRAWLS', displayName: FishCatchMethodEnum.PELAGIC_TRAWLS },
  { value: 'SHRIMP_TRAWL', displayName: FishCatchMethodEnum.SHRIMP_TRAWL },
  { value: 'OTHER_TRAWLS', displayName: FishCatchMethodEnum.OTHER_TRAWLS }
];

export const catchArea = [
  { value: 'ARCTIC_OCEAN', displayName: CatchAreaEnum.ARCTIC_OCEAN },
  { value: 'NORTHWEST_ATLANTIC', displayName: CatchAreaEnum.NORTHWEST_ATLANTIC },
  { value: 'NORTHEAST_ATLANTIC', displayName: CatchAreaEnum.NORTHEAST_ATLANTIC },
  { value: 'BARENTS_SEA', displayName: CatchAreaEnum.BARENTS_SEA },
  { value: 'AZORES_SHELF', displayName: CatchAreaEnum.AZORES_SHELF },
  { value: 'NORTH_AZORES', displayName: CatchAreaEnum.NORTH_AZORES },
  { value: 'EAST_GREENLAND', displayName: CatchAreaEnum.EAST_GREENLAND },
  { value: 'NORTHEAST_GREENLAND', displayName: CatchAreaEnum.NORTHEAST_GREENLAND },
  { value: 'SOUTH_EAST_GREENLAND', displayName: CatchAreaEnum.SOUTH_EAST_GREENLAND },
  { value: 'NORWEGIAN_SEA_SVALBARD_BEAR', displayName: CatchAreaEnum.NORWEGIAN_SEA_SVALBARD_BEAR },
  { value: 'NORWEGIAN_SEA', displayName: CatchAreaEnum.NORWEGIAN_SEA },
  { value: 'SVALBARD_BEAR_ISLAND', displayName: CatchAreaEnum.SVALBARD_BEAR_ISLAND },
  { value: 'SKAGERRAK_KATTEGAT_ORESUND_BELT_BALTIC_SEA', displayName: CatchAreaEnum.SKAGERRAK_KATTEGAT_ORESUND_BELT_BALTIC_SEA },
  { value: 'SKAGERRAK_KATTEGAT', displayName: CatchAreaEnum.SKAGERRAK_KATTEGAT },
  { value: 'ORESUND', displayName: CatchAreaEnum.ORESUND },
  { value: 'SOUND_BELT_SEA', displayName: CatchAreaEnum.SOUND_BELT_SEA },
  { value: 'BELT_SEA', displayName: CatchAreaEnum.BELT_SEA },
  { value: 'BALTIC_SEA', displayName: CatchAreaEnum.BALTIC_SEA },
  { value: 'NORTH_SEA', displayName: CatchAreaEnum.NORTH_SEA },
  { value: 'NORTHERN_NORTH_SEA', displayName: CatchAreaEnum.NORTHERN_NORTH_SEA },
  { value: 'CENTRAL_NORTH_SEA', displayName: CatchAreaEnum.CENTRAL_NORTH_SEA },
  { value: 'SOUTHERN_NORTH_SEA', displayName: CatchAreaEnum.SOUTHERN_NORTH_SEA },
  { value: 'ICELANDIC_FAROESE_SHELVES', displayName: CatchAreaEnum.ICELANDIC_FAROESE_SHELVES },
  { value: 'ICELANDIC_SHELF', displayName: CatchAreaEnum.ICELANDIC_SHELF },
  { value: 'FAROE_SHELF', displayName: CatchAreaEnum.FAROE_SHELF },
  { value: 'ROCKALL_BANK_HEBRIDEAN', displayName: CatchAreaEnum.ROCKALL_BANK_HEBRIDEAN },
  { value: 'NORTH_WEST_SCOTLAND_NORTHERN_IRELAND', displayName: CatchAreaEnum.NORTH_WEST_SCOTLAND_NORTHERN_IRELAND },
  { value: 'ROCKALL_BANK', displayName: CatchAreaEnum.ROCKALL_BANK },
  { value: 'IRISH_SEA_PORCUPINE_BANK', displayName: CatchAreaEnum.IRISH_SEA_PORCUPINE_BANK },
  { value: 'IRISH_SEA', displayName: CatchAreaEnum.IRISH_SEA },
  { value: 'WATERS_WEST_IRELAND', displayName: CatchAreaEnum.WATERS_WEST_IRELAND },
  { value: 'PORCUPINE_BANK', displayName: CatchAreaEnum.PORCUPINE_BANK },
  { value: 'EASTERN_CHANNEL', displayName: CatchAreaEnum.EASTERN_CHANNEL },
  { value: 'WESTERN_CHANNEL', displayName: CatchAreaEnum.WESTERN_CHANNEL },
  { value: 'BRISTOL_CHANNEL', displayName: CatchAreaEnum.BRISTOL_CHANNEL },
  { value: 'CELTIC_SEA_NORTH', displayName: CatchAreaEnum.CELTIC_SEA_NORTH },
  { value: 'SOUTHERN_CELTIC_SEA', displayName: CatchAreaEnum.SOUTHERN_CELTIC_SEA },
  { value: 'EASTERN_SOUTHWEST_IRELAND', displayName: CatchAreaEnum.EASTERN_SOUTHWEST_IRELAND },
  { value: 'WESTERN_SOUTHWEST_IRELAND', displayName: CatchAreaEnum.WESTERN_SOUTHWEST_IRELAND },
  { value: 'BAY_BISCAY', displayName: CatchAreaEnum.BAY_BISCAY },
  { value: 'NORTH_BAY_BISCAY', displayName: CatchAreaEnum.NORTH_BAY_BISCAY },
  { value: 'CENTRAL_BAY_BISCAY', displayName: CatchAreaEnum.CENTRAL_BAY_BISCAY },
  { value: 'SOUTHERN_BAY_BISCAY', displayName: CatchAreaEnum.SOUTHERN_BAY_BISCAY },
  { value: 'BISCAY_SEAWARD', displayName: CatchAreaEnum.BISCAY_SEAWARD },
  { value: 'WESTERN_BAY_BISCAY', displayName: CatchAreaEnum.WESTERN_BAY_BISCAY },
  { value: 'PORTUGUESE_WATERS', displayName: CatchAreaEnum.PORTUGUESE_WATERS },
  { value: 'EASTERN_PORTUGUESE_WATERS', displayName: CatchAreaEnum.EASTERN_PORTUGUESE_WATERS },
  { value: 'WESTERN_PORTUGUESE_WATERS', displayName: CatchAreaEnum.WESTERN_PORTUGUESE_WATERS },
  { value: 'WESTERN_MID_ATLANTIC', displayName: CatchAreaEnum.WESTERN_MID_ATLANTIC },
  { value: 'EASTERN_MID_ATLANTIC', displayName: CatchAreaEnum.EASTERN_MID_ATLANTIC },
  { value: 'MEDITERRANEAN_BLACK_SEA', displayName: CatchAreaEnum.MEDITERRANEAN_BLACK_SEA },
  { value: 'SOUTHWEST_ATLANTIC', displayName: CatchAreaEnum.SOUTHWEST_ATLANTIC },
  { value: 'SOUTHEAST_ATLANTIC', displayName: CatchAreaEnum.SOUTHEAST_ATLANTIC },
  { value: 'ATLANTIC_OCEAN_ANTARCTIC', displayName: CatchAreaEnum.ATLANTIC_OCEAN_ANTARCTIC },
  { value: 'WEST_INDIAN_OCEAN', displayName: CatchAreaEnum.WEST_INDIAN_OCEAN },
  { value: 'EAST_INDIAN_OCEAN', displayName: CatchAreaEnum.EAST_INDIAN_OCEAN },
  { value: 'SOUTHERN_INDIAN_OCEAN_ANTARCTICA', displayName: CatchAreaEnum.SOUTHERN_INDIAN_OCEAN_ANTARCTICA },
  { value: 'NORTHWEST_PACIFIC', displayName: CatchAreaEnum.NORTHWEST_PACIFIC },
  { value: 'NORTHEAST_PACIFIC', displayName: CatchAreaEnum.NORTHEAST_PACIFIC },
  { value: 'WESTERN_CENTRAL_PACIFIC', displayName: CatchAreaEnum.WESTERN_CENTRAL_PACIFIC },
  { value: 'EASTERN_CENTRAL_PACIFIC', displayName: CatchAreaEnum.EASTERN_CENTRAL_PACIFIC },
  { value: 'SOUTHWEST_PACIFIC', displayName: CatchAreaEnum.SOUTHWEST_PACIFIC },
  { value: 'SOUTHEAST_PACIFIC', displayName: CatchAreaEnum.SOUTHEAST_PACIFIC },
  { value: 'PACIFIC_OCEAN_ANTARCTIC', displayName: CatchAreaEnum.PACIFIC_OCEAN_ANTARCTIC }
];

export const hazardStatements = [
  {
    label: '1.1 Fargoangivelser för fysikaliska faror',
    listData: [
      {value: 'H200', displayName: HazardStatementsEnum.H200},
      {value: 'H201', displayName: HazardStatementsEnum.H201},
      {value: 'H202', displayName: HazardStatementsEnum.H202},
      {value: 'H203', displayName: HazardStatementsEnum.H203},
      {value: 'H204', displayName: HazardStatementsEnum.H204},
      {value: 'H205', displayName: HazardStatementsEnum.H205},
      {value: 'H206', displayName: HazardStatementsEnum.H206},
      {value: 'H207', displayName: HazardStatementsEnum.H207},
      {value: 'H208', displayName: HazardStatementsEnum.H208},
      {value: 'H220', displayName: HazardStatementsEnum.H220},
      {value: 'H221', displayName: HazardStatementsEnum.H221},
      {value: 'H222', displayName: HazardStatementsEnum.H222},
      {value: 'H223', displayName: HazardStatementsEnum.H223},
      {value: 'H224', displayName: HazardStatementsEnum.H224},
      {value: 'H225', displayName: HazardStatementsEnum.H225},
      {value: 'H226', displayName: HazardStatementsEnum.H226},
      {value: 'H228', displayName: HazardStatementsEnum.H228},
      {value: 'H229', displayName: HazardStatementsEnum.H229},
      {value: 'H230', displayName: HazardStatementsEnum.H230},
      {value: 'H231', displayName: HazardStatementsEnum.H231},
      {value: 'H232', displayName: HazardStatementsEnum.H232},
      {value: 'H240', displayName: HazardStatementsEnum.H240},
      {value: 'H241', displayName: HazardStatementsEnum.H241},
      {value: 'H242', displayName: HazardStatementsEnum.H242},
      {value: 'H250', displayName: HazardStatementsEnum.H250},
      {value: 'H251', displayName: HazardStatementsEnum.H251},
      {value: 'H252', displayName: HazardStatementsEnum.H252},
      {value: 'H260', displayName: HazardStatementsEnum.H260},
      {value: 'H261', displayName: HazardStatementsEnum.H261},
      {value: 'H270', displayName: HazardStatementsEnum.H270},
      {value: 'H271', displayName: HazardStatementsEnum.H271},
      {value: 'H272', displayName: HazardStatementsEnum.H272},
      {value: 'H280', displayName: HazardStatementsEnum.H280},
      {value: 'H281', displayName: HazardStatementsEnum.H281},
      {value: 'H290', displayName: HazardStatementsEnum.H290}
    ],
  },
  {
    label: '1.2 Faroangivelser för hälsofaror',
    listData: [
      {value: 'H300', displayName: HazardStatementsEnum.H300},
      {value: 'H301', displayName: HazardStatementsEnum.H301},
      {value: 'H302', displayName: HazardStatementsEnum.H302},
      {value: 'H304', displayName: HazardStatementsEnum.H304},
      {value: 'H310', displayName: HazardStatementsEnum.H310},
      {value: 'H311', displayName: HazardStatementsEnum.H311},
      {value: 'H312', displayName: HazardStatementsEnum.H312},
      {value: 'H314', displayName: HazardStatementsEnum.H314},
      {value: 'H315', displayName: HazardStatementsEnum.H315},
      {value: 'H317', displayName: HazardStatementsEnum.H317},
      {value: 'H318', displayName: HazardStatementsEnum.H318},
      {value: 'H319', displayName: HazardStatementsEnum.H319},
      {value: 'H330', displayName: HazardStatementsEnum.H330},
      {value: 'H331', displayName: HazardStatementsEnum.H331},
      {value: 'H332', displayName: HazardStatementsEnum.H332},
      {value: 'H334', displayName: HazardStatementsEnum.H334},
      {value: 'H335', displayName: HazardStatementsEnum.H335},
      {value: 'H336', displayName: HazardStatementsEnum.H336},
      {value: 'H340', displayName: HazardStatementsEnum.H340},
      {value: 'H341', displayName: HazardStatementsEnum.H341},
      {value: 'H350', displayName: HazardStatementsEnum.H350},
      {value: 'H351', displayName: HazardStatementsEnum.H351},
      {value: 'H360', displayName: HazardStatementsEnum.H360},
      {value: 'H361', displayName: HazardStatementsEnum.H361},
      {value: 'H362', displayName: HazardStatementsEnum.H362},
      {value: 'H370', displayName: HazardStatementsEnum.H370},
      {value: 'H371', displayName: HazardStatementsEnum.H371},
      {value: 'H372', displayName: HazardStatementsEnum.H372},
      {value: 'H373', displayName: HazardStatementsEnum.H373},
      {value: 'H300_H310', displayName: HazardStatementsEnum.H300_H310},
      {value: 'H300_H310_H330', displayName: HazardStatementsEnum.H300_H310_H330},
      {value: 'H300_H330', displayName: HazardStatementsEnum.H300_H330},
      {value: 'H310_H330', displayName: HazardStatementsEnum.H310_H330},
      {value: 'H310_H310_H330', displayName: HazardStatementsEnum.H310_H310_H330},
      {value: 'H301_H311', displayName: HazardStatementsEnum.H301_H311},
      {value: 'H301_H331', displayName: HazardStatementsEnum.H301_H331},
      {value: 'H311_H331', displayName: HazardStatementsEnum.H311_H331},
      {value: 'H301_H311_H331', displayName: HazardStatementsEnum.H301_H311_H331},
      {value: 'H302_H312', displayName: HazardStatementsEnum.H302_H312},
      {value: 'H302_H332', displayName: HazardStatementsEnum.H302_H332},
      {value: 'H312_H332', displayName: HazardStatementsEnum.H312_H332},
      {value: 'H302_H312_H332', displayName: HazardStatementsEnum.H302_H312_H332}
    ],
  },
  {
    label: '1.3 Faroangivelser för miljöfaror',
    listData: [
      {value: 'H400', displayName: HazardStatementsEnum.H400},
      {value: 'H410', displayName: HazardStatementsEnum.H410},
      {value: 'H411', displayName: HazardStatementsEnum.H411},
      {value: 'H412', displayName: HazardStatementsEnum.H412},
      {value: 'H413', displayName: HazardStatementsEnum.H413},
      {value: 'H420', displayName: HazardStatementsEnum.H420}
    ],
  },
  {
    label: 'Del 2. Kompletterande faroinformation',
    listData: [
      {value: 'EUH014', displayName: HazardStatementsEnum.EUH014},
      {value: 'EUH018', displayName: HazardStatementsEnum.EUH018},
      {value: 'EUH019', displayName: HazardStatementsEnum.EUH019},
      {value: 'EUH029', displayName: HazardStatementsEnum.EUH029},
      {value: 'EUH031', displayName: HazardStatementsEnum.EUH031},
      {value: 'EUH032', displayName: HazardStatementsEnum.EUH032},
      {value: 'EUH044', displayName: HazardStatementsEnum.EUH044},
      {value: 'EUH059', displayName: HazardStatementsEnum.EUH059},
      {value: 'EUH066', displayName: HazardStatementsEnum.EUH066},
      {value: 'EUH070', displayName: HazardStatementsEnum.EUH070},
      {value: 'EUH071', displayName: HazardStatementsEnum.EUH071},
    ]
  },
  {
    label: 'Del 3. Kompletterande märkning/information om vissa ämnen eller blandningar',
    listData: [
      {value: 'EUH201', displayName: HazardStatementsEnum.EUH201},
      {value: 'EUH201A', displayName: HazardStatementsEnum.EUH201A},
      {value: 'EUH202', displayName: HazardStatementsEnum.EUH202},
      {value: 'EUH203', displayName: HazardStatementsEnum.EUH203},
      {value: 'EUH204', displayName: HazardStatementsEnum.EUH204},
      {value: 'EUH205', displayName: HazardStatementsEnum.EUH205},
      {value: 'EUH206', displayName: HazardStatementsEnum.EUH206},
      {value: 'EUH207', displayName: HazardStatementsEnum.EUH207},
      {value: 'EUH208', displayName: HazardStatementsEnum.EUH208},
      {value: 'EUH209', displayName: HazardStatementsEnum.EUH209},
      {value: 'EUH209A', displayName: HazardStatementsEnum.EUH209A},
      {value: 'EUH210', displayName: HazardStatementsEnum.EUH210},
      {value: 'EUH401', displayName: HazardStatementsEnum.EUH401},
    ]
  },
];

export const signalWords = [
  { value: 'WARNING', displayName: SignalWordsEnum.WARNING },
  { value: 'DANGER', displayName: SignalWordsEnum.DANGER },
  { value: 'NOT_APPLICABLE', displayName: SignalWordsEnum.NOT_APPLICABLE }
];

export const securityNotices = [
  {
    label: 'Skyddsangivelser – Allmänt',
    listData: [
      { value: 'P101', displayName: SecurityNoticesEnum.P101 },
      { value: 'P102', displayName: SecurityNoticesEnum.P102 },
      { value: 'P103', displayName: SecurityNoticesEnum.P103 },
    ]
  },
  {
    label: 'Skyddsangivelser – Förebyggande',
    listData: [
      {value: 'P201', displayName: SecurityNoticesEnum.P201},
      {value: 'P202', displayName: SecurityNoticesEnum.P202},
      {value: 'P210', displayName: SecurityNoticesEnum.P210},
      {value: 'P211', displayName: SecurityNoticesEnum.P211},
      {value: 'P212', displayName: SecurityNoticesEnum.P212},
      {value: 'P220', displayName: SecurityNoticesEnum.P220},
      {value: 'P222', displayName: SecurityNoticesEnum.P222},
      {value: 'P223', displayName: SecurityNoticesEnum.P223},
      {value: 'P230', displayName: SecurityNoticesEnum.P230},
      {value: 'P231', displayName: SecurityNoticesEnum.P231},
      {value: 'P232', displayName: SecurityNoticesEnum.P232},
      {value: 'P233', displayName: SecurityNoticesEnum.P233},
      {value: 'P234', displayName: SecurityNoticesEnum.P234},
      {value: 'P235', displayName: SecurityNoticesEnum.P235},
      {value: 'P240', displayName: SecurityNoticesEnum.P240},
      {value: 'P241', displayName: SecurityNoticesEnum.P241},
      {value: 'P242', displayName: SecurityNoticesEnum.P242},
      {value: 'P243', displayName: SecurityNoticesEnum.P243},
      {value: 'P244', displayName: SecurityNoticesEnum.P244},
      {value: 'P250', displayName: SecurityNoticesEnum.P250},
      {value: 'P251', displayName: SecurityNoticesEnum.P251},
      {value: 'P260', displayName: SecurityNoticesEnum.P260},
      {value: 'P261', displayName: SecurityNoticesEnum.P261},
      {value: 'P262', displayName: SecurityNoticesEnum.P262},
      {value: 'P263', displayName: SecurityNoticesEnum.P263},
      {value: 'P264', displayName: SecurityNoticesEnum.P264},
      {value: 'P270', displayName: SecurityNoticesEnum.P270},
      {value: 'P271', displayName: SecurityNoticesEnum.P271},
      {value: 'P272', displayName: SecurityNoticesEnum.P272},
      {value: 'P273', displayName: SecurityNoticesEnum.P273},
      {value: 'P280', displayName: SecurityNoticesEnum.P280},
      {value: 'P282', displayName: SecurityNoticesEnum.P282},
      {value: 'P283', displayName: SecurityNoticesEnum.P283},
      {value: 'P284', displayName: SecurityNoticesEnum.P284},
      {value: 'P231_P232', displayName: SecurityNoticesEnum.P231_P232},
      {value: 'P312', displayName: SecurityNoticesEnum.P312},
      {value: 'P314', displayName: SecurityNoticesEnum.P314},
      {value: 'P315', displayName: SecurityNoticesEnum.P315},
      {value: 'P377', displayName: SecurityNoticesEnum.P377},
      {value: 'P381', displayName: SecurityNoticesEnum.P381},
      {value: 'P390', displayName: SecurityNoticesEnum.P390},
      {value: 'P391', displayName: SecurityNoticesEnum.P391},
      {value: 'P301_P310', displayName: SecurityNoticesEnum.P301_P310},
      {value: 'P301_P312', displayName: SecurityNoticesEnum.P301_P312},
      {value: 'P302_P334', displayName: SecurityNoticesEnum.P302_P334},
      {value: 'P302_P352', displayName: SecurityNoticesEnum.P302_P352},
      {value: 'P304_P340', displayName: SecurityNoticesEnum.P304_P340},
      {value: 'P306_P360', displayName: SecurityNoticesEnum.P306_P360},
      {value: 'P308_P311', displayName: SecurityNoticesEnum.P308_P311},
      {value: 'P308_P313', displayName: SecurityNoticesEnum.P308_P313},
      {value: 'P332_P313', displayName: SecurityNoticesEnum.P332_P313},
      {value: 'P333_P313', displayName: SecurityNoticesEnum.P333_P313},
      {value: 'P336_P315', displayName: SecurityNoticesEnum.P336_P315},
      {value: 'P337_P313', displayName: SecurityNoticesEnum.P337_P313},
      {value: 'P342_P311', displayName: SecurityNoticesEnum.P342_P311},
      {value: 'P361_P364', displayName: SecurityNoticesEnum.P361_P364},
      {value: 'P362_P364', displayName: SecurityNoticesEnum.P362_P364},
      {value: 'P370_P376', displayName: SecurityNoticesEnum.P370_P376},
      {value: 'P370_P378', displayName: SecurityNoticesEnum.P370_P378},
      {value: 'P301_P330_P331', displayName: SecurityNoticesEnum.P301_P330_P331},
      {value: 'P302_P335_P334', displayName: SecurityNoticesEnum.P302_P335_P334},
      {value: 'P303_P361_P353', displayName: SecurityNoticesEnum.P303_P361_P353},
      {value: 'P305_P351_P338', displayName: SecurityNoticesEnum.P305_P351_P338},
      {value: 'P370_P380_P375', displayName: SecurityNoticesEnum.P370_P380_P375},
      {value: 'P371_P380_P375', displayName: SecurityNoticesEnum.P371_P380_P375},
      {value: 'P370_P372_P380_P373', displayName: SecurityNoticesEnum.P370_P372_P380_P373},
      {value: 'P370_P380_P375_P378', displayName: SecurityNoticesEnum.P370_P380_P375_P378}
    ],
  },
  {
    label: 'Skyddsangivelser – Förvaring',
    listData: [
      {value: 'P402', displayName: SecurityNoticesEnum.P402},
      {value: 'P403', displayName: SecurityNoticesEnum.P403},
      {value: 'P404', displayName: SecurityNoticesEnum.P404},
      {value: 'P405', displayName: SecurityNoticesEnum.P405},
      {value: 'P406', displayName: SecurityNoticesEnum.P406},
      {value: 'P407', displayName: SecurityNoticesEnum.P407},
      {value: 'P410', displayName: SecurityNoticesEnum.P410},
      {value: 'P411', displayName: SecurityNoticesEnum.P411},
      {value: 'P412', displayName: SecurityNoticesEnum.P412},
      {value: 'P413', displayName: SecurityNoticesEnum.P413},
      {value: 'P420', displayName: SecurityNoticesEnum.P420},
      {value: 'P402_P404', displayName: SecurityNoticesEnum.P402_P404},
      {value: 'P403_P233', displayName: SecurityNoticesEnum.P403_P233},
      {value: 'P403_P235', displayName: SecurityNoticesEnum.P403_P235},
      {value: 'P410_P403', displayName: SecurityNoticesEnum.P410_P403},
      {value: 'P410_P412', displayName: SecurityNoticesEnum.P410_P412},
    ]
  },
  {
    label: '  Skyddsangivelser – Avfall',
    listData: [
      {value: 'P501', displayName: SecurityNoticesEnum.P501},
      {value: 'P502', displayName: SecurityNoticesEnum.P502}
    ]
  }
];

export const TAX_CLASSIFICATION = [
  { value: 6, displayName: '6%' },
  { value: 12, displayName: '12%' },
  { value: 25, displayName: '25%' },
];
