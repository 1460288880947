import isNil from 'lodash/isNil';
import toString from 'lodash/toString';

import { PRODUCT_TABS } from '../constants/domain';
import { NON_FOOD_CATEGORY_LIST } from 'components/constants-ts';
import { extractURLParam } from './urlUtils';
import IProduct from 'models/product';

export const isNonFoodProduct = (product?: IProduct) => {
  return NON_FOOD_CATEGORY_LIST.some(({ id }) => id === product?.foodlaCategory?.id);
};

export const transformCategoryRead = (product: IProduct) => {
  return product;
};

export const transformCategoryWrite = (product: IProduct) => {
  return product;
};

// Check tab param if it is valid tab.
export const isValidTabParam = (param: PRODUCT_TABS) => param === PRODUCT_TABS.PENDING || param === PRODUCT_TABS.APPROVED || param === PRODUCT_TABS.DISAPPROVED;

// Check URL for search param with information about selected tab.
export const getProductTabFromUrl = () => {
  // TODO any
  const tabParam: any = extractURLParam('productTab', window.location.href);
  return isValidTabParam(tabParam) ? tabParam : PRODUCT_TABS.PENDING;
};

export const getControlDigit = (ean: string) => {
  if (ean) {
    const EAN = ean.trim();
    if (EAN.length === 13 || EAN.length === 12) {
      const total =
        (parseInt(EAN[0]) + parseInt(EAN[2]) + parseInt(EAN[4]) + parseInt(EAN[6]) + parseInt(EAN[8]) + parseInt(EAN[10]))
      + 3 * (parseInt(EAN[1]) + parseInt(EAN[3]) + parseInt(EAN[5]) + parseInt(EAN[7]) + parseInt(EAN[9]) + parseInt(EAN[11]));
      let control = 10 - total % 10;
      if (control === 10) control = 0;
      return control;
    }
  }
  return undefined;
};

export const getEAN2 = (ean: string) => {
  if (
    ean
    && ean.length === 13
    && (ean.startsWith('23') || ean.startsWith('2097') || ean.startsWith('2000'))
  ) {
    if (ean[8] !== '0' || ean[9] !== '0' || ean[10] !== '0' || ean[11] !== '0') {
      let newEan = ean.substring(0, 8) + '0000';
      const control = getControlDigit(newEan);
      if (!isNil(control)) {
        newEan += control.toString();
      }
      return newEan;
    } else if (ean[8] === '0' && ean[9] === '0' && ean[10] === '0' && ean[11] === '0') {
      const control = getControlDigit(ean);
      if (!isNil(control)) {
        if (control.toString() !== ean[12]) {
          const newEan = ean.substring(0, 12) + control.toString();
          return newEan;
        }
      }
    }
  }
  return null
};

export const isValidEAN = (ean: string, field?: keyof IProduct) => {
  if (isNil(ean)) return false;

  const eanStr = toString(ean).trim();

  if (!/^\d+$/.test(eanStr)) return false;

  const storePackEanField = [
    'storePackEANForStorePack',
    'transportEANForStorePack',
    'palletLayerEANForStorePack',
    'palletEANForStorePack',
  ];

  if (field === 'EAN') return [8, 13].includes(eanStr.length);
  if (field && storePackEanField.includes(field)) return eanStr.length === 14;

  return [8, 12, 13, 14, 18].includes(eanStr.length);
};
