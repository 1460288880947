import React from 'react';

import { path } from 'ramda';
import { useQuery } from '@apollo/react-hooks';

import '../../assets/fileuploader.css';

import { QUERY_PRODUCER_PROFILE } from '../../graphql/queries';
import { isDefined } from '../../utils/helpers';
import ProductUpdateForm from '../../components/product/ProductUpdateForm';
import CircularSpinner from '../../components/loadingIndicator/CircularSpinner';
import {APPLICATION_ROLES} from '../../components/constants';

const AddProduct = props => {

  const {
    loading: producerInfoLoading,
    error: producerInfoError,
    data: producerInfoData,
  } = useQuery(QUERY_PRODUCER_PROFILE, { fetchPolicy: 'cache-and-network' });

  const producerData =
    isDefined(producerInfoData) && !producerInfoError ? path(['userProducer'], producerInfoData) : {};

  return (
    <>
    {producerInfoLoading && <CircularSpinner />}
    {producerInfoError && <ProductUpdateForm isCreatingNew producerData={{}} userRole={APPLICATION_ROLES.PRODUCER} />}
    {producerInfoData && <ProductUpdateForm isCreatingNew producerData={producerData} userRole={APPLICATION_ROLES.PRODUCER} />}
    </>
  )
};

export default AddProduct;
