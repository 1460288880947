import React from 'react';
import IModel from '../../../models/model';

import { isDefined } from '../../../utils/helpers';
import InputTextField from '../../inputfields/InputTextField';
import { requiredNutritionalFields } from './constants';

// TODO move
interface ILabelOptions {
  heading?: string;
  text?: string;
  link?: string;
  required?: boolean;
}

interface IFloatInputProps<T extends IModel> {
  forceRed?: boolean;
  state: T;
  setState: (state: T) => void;
  name: keyof T;
  required?: boolean;
  dense?: boolean;
  withLabel?: boolean;
  labelOptions?: ILabelOptions;
  label?: string;
  noFullWidth?: boolean;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}

export const FloatInput = <T extends IModel, >({
  forceRed,
  state,
  setState,
  name,
  required,
  dense = true,
  withLabel,
  labelOptions,
  label,
  noFullWidth,
  disabled,
  placeholder,
  style,
}: IFloatInputProps<T>) => {
  // TODO any
  const handleChange = (event: any) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      if (isDefined(stateValue) && typeof stateValue === "string") {
      let val = stateValue;
      val = val.trim();
      if (val) {
          stateValue = val.replace(/[^0-9.,]/g, '')
      } else {
          stateValue = val;
      }
      }
      console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
      setState({ ...state, [stateName]: stateValue });
  };
  return (
    <InputTextField
      forceRed={forceRed}
      noFullWidth={noFullWidth}
      inputName={name}
      required={required}
      showRed={required && state?.showRed && requiredNutritionalFields.includes((name as any).toString())}
      dense={dense}
      value={state[name]}
      onChange={handleChange}
      disabled={disabled}
      style={{ flex: 0.15, ...style }}
      // type="number"
      withLabel={withLabel}
      label={label}
      labelOptions={labelOptions}
      placeholder={placeholder}
      // inputProps={{pattern: [0-9]}}
    />
  );
};

export default FloatInput;
