import React, { PropsWithChildren } from 'react';

import { CircularProgress, Typography, Box } from '@material-ui/core';
import { FreeBreakfastOutlined } from '@material-ui/icons';

import ErrorBox from './Error';

interface IAsyncDataHandlerProps {
    loading?: boolean;
    withoutSpinner?: boolean;
    error?: Error;
    networkStatus?: number; // TODO enum
    data?: any; // TODO generic?
}

export const AsyncDataHandler = ({
    loading,
    error,
    networkStatus,
    data,
    children,
    withoutSpinner,
}: PropsWithChildren<IAsyncDataHandlerProps>) => {
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 20,
                    paddingBottom: 20,
                }}
            >
                {!withoutSpinner && (
                    <CircularProgress size={70} variant="indeterminate" />
                )}
            </div>
        );
    }
    if (error || networkStatus === 8) {
        return (
            <ErrorBox message={`${error ? error.message : 'Network error'}`} />
        );
    }
    if (data === undefined || data == null || (Array.isArray(data) && data.length === 0) || !children) {
        return (
            <Box>
                <FreeBreakfastOutlined />
                <Typography variant="body2"> No data </Typography>
            </Box>
        );
    }
    // TODO
    return React.cloneElement(React.Children.only<any>(children), { data });
};

export default AsyncDataHandler;
