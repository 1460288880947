import { v4 as uuid } from 'uuid';
import { uniqBy } from 'ramda';

import { LOCAL_STORE_ITEMS } from '../../constants';
import { IImageFileSource } from '../../types';

export const parseImageUrl = (url: string, data?: Partial<IImageFileSource>) => {
  const fileName = url.split('/').pop()?.split('?')?.shift();
  const [name, extension] = fileName?.split('.') || [];
  return {
    ...data,
    id: uuid(),
    fileName,
    extension,
    name,
    url,
  } as IImageFileSource;
};

export const getRemovedProductIndexs = () => {
  let removedPorductIndexs: number[] = [];
  try {
    removedPorductIndexs = JSON.parse(localStorage.getItem(LOCAL_STORE_ITEMS.REMOVED_PRODUCT_INDEXS) || '[]').map(
      (index: string) => Number(index)
    );
  } catch {
    removedPorductIndexs = [];
  }

  return removedPorductIndexs;
};

export const addRemovedProductIds = (index: number) => {
  const removedPorductIndexs = getRemovedProductIndexs();

  localStorage.setItem(
    LOCAL_STORE_ITEMS.REMOVED_PRODUCT_INDEXS,
    JSON.stringify(uniqBy(item => item, [...removedPorductIndexs, index]))
  );
};

export * from './cell';
export * from './column';
export * from './loadExcelFile';
export * from './readExcelFile';
export * from './writeExcelFile';
export * from './parseExcelDataToProduct';
