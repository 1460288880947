import React from 'react';

import IProduct from '../../../../models/product';

import { Divider } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

interface IBulkImportFileProps {
  product: IProduct;
}

const BulkImportFile = ({ product }: IBulkImportFileProps) => {
  if (!product?.bulkImportFile) return null;

  const pathname = new URL(product.bulkImportFile)?.pathname || '';
  let fileName = pathname?.split('/')?.slice(-1)?.[0] || '';
  if (fileName) {
    fileName = decodeURI(fileName);
  }

  return (
    <div>
      <h3>Bulk Import File</h3>
      <Divider />
      <div style={{ marginTop: 8, marginBottom: 24 }}>
        <a
          href={product.bulkImportFile}
          download="bulk_import.xlsx"
          style={{ display: 'flex', alignItems: 'center', gap: 12 }}
        >
          <CloudDownloadOutlinedIcon fontSize="small" />
          <span>{fileName || 'Download'}</span>
        </a>
      </div>
    </div>
  );
};

export default BulkImportFile;
