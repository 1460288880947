import React, { useState, useEffect } from 'react';

import InputLabel from '../../../inputfields/InputLabel';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import IProduct, { ILabel } from '../../../../models/product';
import MultipleGroupedSelect from 'components/inputfields/MultipleGroupedSelect';
import {
  MAIN_LABELS,
  ECO_LABELS,
  VEGAN_LABELS,
  ENVIRONMENTAL_LABEL
} from 'components/constants-ts';;

interface ILabelsProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  approve?: boolean;
};

interface ILabelField {
  title: string;
  field: string;
};

const getListData = (labels: ILabelField[]) => {
  return labels.map((label: ILabelField) => {
    return { value: label.field, displayName: label.title }
  })
};

const getProductMarkingsValue = (value: ILabel[] | undefined) => {
  return value?.reduce((result: string[], item) => {
    if (item.state) {
      result.push(item.field);
    }
    return result;
  }, []);
};

export const productMarkings = [
  {
    label: '',
    listData: getListData(MAIN_LABELS)
  },
  {
    label: 'EKO-Märkingar',
    listData: getListData(ECO_LABELS)
  },
  {
    label: 'Veganska märkningar',
    listData: getListData(VEGAN_LABELS)
  },
  {
    label: 'Miljömärkningar',
    listData: getListData(ENVIRONMENTAL_LABEL)
  },
];

export const Labels = ({ state, setState, approve }: ILabelsProps) => {
  const [labels, setLabels] = useState<string[] | undefined>([]);

  const hasApprove = (field: string) => {
    if (!approve) return false;
    if (!state?.approve) return false;
    if (state.approve.length === 0) return false;

    const approveItem = state.approve.find((x) => x.field === field);    

    if (!approveItem) return false;
    
    return true;
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const stateName = event.target.name;
    const stateValue = event.target.value;
    const convertedValue = (stateValue as string[]).map(field => ({field: field, state: true}))
    const value = !stateValue || stateValue === '' ? null : convertedValue;

    stateName && setState({ ...state, [stateName]: value });
  };

  useEffect(() => {
    const productMarkingsValue = getProductMarkingsValue(state.labels);

    setLabels(productMarkingsValue);
  }, [state.labels])

  return (
    <>
      <div style={{width: '100%', marginBottom: '5px'}}>
        <InputLabel
          heading="Märkningar"
          text="Välj relevanta märkningar för din produkt. Observera att vissa kräver certifiering."
        />
        <MultipleGroupedSelect
          disableLabel={true}
          categoryData={productMarkings}
          value={labels}
          targetName="labels"
          handleChange={handleDropdownChange}
          showSearch
        />
      </div>
      {hasApprove('labels') && (
        <ThreeWaySwitch
          state={state}
          style={{marginLeft: 0}}
          setState={setState}
          name="labels"
        />
      )}
    </>
  );
};

export default Labels;
