export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const getPictureUrl = (image) => {
  let pictureUrl;
  if (image) {
    if (image.isExisting) {
      pictureUrl = image.pictureUrl;
    } else {
      pictureUrl = image.imgSrc;
    }
  } 
  if (pictureUrl && pictureUrl.trim === "") {
    pictureUrl = undefined;
  }
  return pictureUrl;
};

export const fixFloat = (value) => {
  return Math.round(value *  10000) / 100;
};
