import React from 'react';
import withContainer from '../../hoc/withContainer';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { TEMP_SUPERUSER_UPDATE_DESC_SIZE_FIELD } from '../../graphql/queries';
import Divider from '@material-ui/core/Divider';
import { isDefined, isNotDefined } from '../../utils/helpers';
import { IMAGE_SIZES, getImageWithSizeSuffix } from 'utils/images2';
import { ListItemAvatar, Avatar } from '@material-ui/core';
import COLORS from '../../constants/colors';
import { path } from 'ramda';
import { SUPERUSER_APPROVAL } from '../../components/constants';

const ProductListItem = ({ product, showNotApproved }) => {
  const producerStatus = path(['producer', 'status'], product);
  const producerNotAccepted = isDefined(producerStatus) && producerStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL;
  if (!showNotApproved && producerNotAccepted) return <></>;

  const primaryTitle = product?.title || 'NO TITLE';
  const linkTo = `/superuser/products/${product.id}/approval`;

  const image = isDefined(product.image_src) ? getImageWithSizeSuffix(product.image_src, IMAGE_SIZES.XSMALL) : '';

  return (
    <>
      <Link variant="inherit" underline="none" color="inherit" component={RouterLink} to={linkTo}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <img src={image} alt="P" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            style={producerNotAccepted ? { color: COLORS.mainRed } : {}}
            primary={primaryTitle}
          />
        </ListItem>
      </Link>
      <Divider />
    </>
  );
};

const ProductList = ({ data: { products } }) => {
  if(isNotDefined(products)) return <></>
  return (
    <Paper>
      <span
        style={{
          fontSize: 18,
          textAlign: 'center',
          marginTop: 20,
          color: COLORS.mainGreen,
          fontWeight: 'bold',
          padding: 10,
        }}
      >
        Products missing correct descriptive size information ({products.length})
      </span>
      {products.length < 1 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span
            style={{ fontSize: 18, textAlign: 'center', marginTop: 20, color: COLORS.mainGreen, fontWeight: 'bold' }}
          >
            All products approved. Nothing to do here! :)
          </span>
        </div>
      ) : (
        <>
          <List dense>
            {products && products.map(p => <ProductListItem product={p} key={p.id} showNotApproved={true} />)}
          </List>
        </>
      )}
    </Paper>
  );
};

// Use with hooks instead.
export default withContainer({
  query: TEMP_SUPERUSER_UPDATE_DESC_SIZE_FIELD,
  validator: data => !!data,
  resolver: data => ({ products: data.allProducts })
})(ProductList);
