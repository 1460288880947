import React from 'react';
import COLORS from '../../constants/colors';
import { PRODUCT_STATUS } from '../constants';
import { path } from 'ramda';
import { Button } from '@material-ui/core';

const getAsReadableText = productStatus => {
  switch (productStatus) {
    case PRODUCT_STATUS.MARKED_FOR_REMOVAL:
      return 'to be removed';
    case PRODUCT_STATUS.DELETED:
      return 'as deleted';
    default:
      return productStatus;
  }
};

const MarkedProduct = ({ product, showActionButton, onActionButtonPressed, actionButtonTitle }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', padding: 20 }}>
    <span style={{ fontSize: 16, color: COLORS.mainRed, fontWeight: 'bold' }}>
      This product is marked {getAsReadableText(path(['productStatus'], product))}. No changes can be made.
    </span>
    {showActionButton && (
      <Button style={{ marginTop: 20 }} onClick={onActionButtonPressed} fullWidth variant="contained" color="primary">
        {actionButtonTitle}
      </Button>
    )}
  </div>
);

export default MarkedProduct;
