import { FC, useMemo } from 'react';

import InfoIcon from 'components/icons/InfoIcon';
import LightTooltip from 'components/lightTooltip';

import IProduct from 'models/product';
import { COLUMN_DETAILS } from '../constants';

interface ValuesSuggestionProps {
  field: keyof IProduct;
}

const ValuesSuggestion: FC<ValuesSuggestionProps> = ({ field }) => {
  const suggestions = useMemo(() => {
    const columnDetail = COLUMN_DETAILS.find(col => col.field === field);
    return columnDetail?.suggestions;
  }, [field]);

  if (!suggestions) return null;

  return (
    <LightTooltip
      placement="right"
      interactive
      title={
        <div style={{ maxHeight: '50vh', overflow: 'auto', margin: '-8px -16px', padding: '8px 16px' }}>
          {suggestions}
        </div>
      }
    >
      <span style={{ marginBottom: 1 }}>
        <InfoIcon width={14} height={14} style={{ display: 'block' }} />
      </span>
    </LightTooltip>
  );
};

export default ValuesSuggestion;
