import React from 'react';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import CircularSpinner from './CircularSpinner';
import CardGrid from '../CardGrid';

import {EListViewType} from '../product/ProductCardList/types';

interface ILoadingSkeleton {
  count: number;
  listViewType?: EListViewType;
}

interface IListItem {
  id: number;
}

// Component
const LoadingSkeleton = ({ count, listViewType = EListViewType.CARD }: ILoadingSkeleton) => {
  const list: IListItem[] = [];

  for(let i = 0; i < count; i++) {
    list.push({ id: i })
  }

  if (listViewType === EListViewType.CARD) {
    return (
        <CardGrid list={list}>
          {(props: IListItem) => (
              <Card key={props.id} style={{ maxWidth: 345, height: 360, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularSpinner />
              </Card>
          )}
        </CardGrid>
    );
  } else if (listViewType === EListViewType.LIST) {
    return (
        <List dense>
          {list && list.map((product: IListItem) => (
              <ListItem key={`${product.id}`}>
                <CircularSpinner />
              </ListItem>
          ))}
        </List>
    );
  }
  return null;
};

// Export
export default LoadingSkeleton;
