import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  cellWrapper: {
    position: 'relative',
  },
  oldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderRadius: 4,
    padding: '4px 0',
    color: 'red',
  },
  newWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderRadius: 3,
    padding: '2px 4px',
    margin: '-2px -5px', // -4px for padding, -1px for border
    border: '1px dashed #D7DAE4',
    color: 'green',
  },
  inputDateWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 20,
  },
  input: {
    width: '100%',
    outline: 0,
    background: 'transparent',
    borderWidth: 0,
    padding: 0,
    color: 'green',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
  },
  inputDate: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    minHeight: 20,
    '&::-webkit-datetime-edit': {
      opacity: 0,
      width: 0,
    },
    '&::-webkit-calendar-picker-indicator': {
      cursor: 'pointer',
      width: '100%',
      backgroundPosition: 'right center',
    },
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
