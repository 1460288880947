// Dependencies
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Backdrop, Button, Modal, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { filter, isEmpty, path } from 'ramda'
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// Components
import ApproveButton from '../ApproveButton';
import ImageWithFallback from '../../ImageWithFallback';
import ProductCardBadge from '../ProductCardBadge';
import ProductModal from '../ProductModal';
import RemoveProductFromExportsDialog from './removeProductDialogs/RemoveProductFromExportsDialog';

// Utils
import { isDefined } from '../../../utils/helpers';

// Mutation
import {
    MUTATION_RESTORE_PRODUCT,
    MUTATION_UPDATE_OUT_OF_STOCK
} from '../../../graphql/mutations';

// Queries
import { ALL_PRODUCTS_QUERY } from '../../../graphql/queries';

// Constants
import { SUPERUSER_APPROVAL, APPLICATION_ROLES, PRODUCT_STATUS, getDescriptiveSizeValueFromEnum } from '../../constants';
import { PRODUCT_TAGSTATES } from '../../../constants/domain';
import StockToggleButton from '../StockToggleButton';
import HighlightedText from '../../highlightedText/HighlightedText';
import COLORS from '../../../constants/colors';
import {ERemoveProductHandlerKey} from './types';
import Alert from '@material-ui/lab/Alert';
import { isStore } from 'utils/userrole';
import useViewport from 'hooks/useViewports';
import IProduct, { IFoodlaCategory } from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';
import { isNewFood } from '../ProductUpdateForm/fields/category';

const MODAL_STATE_OPENING = 'MODAL_OPENING';
const MODAL_STATE_CLOSING = 'MODAL_CLOSING';

const styles = (theme: any) => ({
    producerContainer: {
        padding: theme.spacing(2),
    },
    card: {
        position: 'relative',
        maxWidth: 345,
        height: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    emptyCard: {
        maxWidth: 345,
        height: 400,
        backgroundColor: '#fafafa',
    },
    actionRoot: {
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
    },
    media: {
        height: 200,
    },
    editButton: {
        width: '50%',
        border: 'lightgrey solid 2px',
        borderRadius: 4,
        padding: 4,
        color: 'darkgrey',
        fontSize: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    removeIcon: {
        position: 'absolute',
        top: 8,
        right: 8,
        color: COLORS.mainRed,
        cursor: 'pointer',
    },
});

interface IComparativePriceData {
    b2bPrice?: number;
    b2cPrice?: number;
}

interface ICoopAssortment {
    availableOnline?: boolean;
    comparativePriceData?: IComparativePriceData;
    comparativePriceText?: string;
}

interface IProductCardProps {
    id: string;
    producerId: string;
    name: string;
    EAN: string;
    EAN2: string;
    state: any;
    producer: any;
    description: string;
    pictures: any;
    classes: any;
    selectedProductId: string;
    userResourceRole: string;
    adminStatus: string;
    overrideOnClickCallback?: any;
    notificationsData: any;
    currentCategory: string;
    productStatus: string;
    quantity_in_stock: number;
    isExport: boolean;
    showDescriptiveSize: boolean;
    descriptive_size_amount: number;
    descriptive_size_unit: number;
    refetch: any;
    summaryRefetch: any;
    showEdit: boolean;
    showSuperuserNotifications: boolean;
    storeMe: boolean;
    searchString?: string;
    removeProductHandlerKey?: ERemoveProductHandlerKey;
    assortment?: ICoopAssortment;
    isDraft?: boolean;
    rootCategory?: IFoodlaCategoryOption;
    foodlaCategory?: IFoodlaCategory;
    brand?: string;
    brand_food?: string;
}

const dialogs: Record<string, any> = {
    [ERemoveProductHandlerKey.REMOVE_PRODUCT_FROM_EXPORTS]: RemoveProductFromExportsDialog,
};

export const mapProductSecondaryTitle = (
  params: {
    product?: IProduct,
    rootCategory?: IFoodlaCategoryOption,
    secondaryTitle?: string;
  }
) => {
  const { product, rootCategory, secondaryTitle } = params;
  const isFood = isNewFood(product?.foodlaCategory, rootCategory);
  let brandText = product?.brand || '';
  if (isFood) {
    brandText = product?.brand_food || '';
  }
  const producerName = product?.producer?.name || '';
  if (brandText?.toLocaleLowerCase() === producerName?.toLocaleLowerCase()) {
    brandText = '';
  }
  let result = secondaryTitle || producerName || '';
  if (result && brandText) {
    result = `${result}, ${brandText}`;
  } else {
    result = result || brandText || '';
  }

  return result;
}

function ProductCard({
     id,
     producerId,
     name,
     EAN,
     EAN2,
     state,
     producer,
     description,
     pictures,
     classes,
     selectedProductId,
     userResourceRole,
     adminStatus,
     overrideOnClickCallback,
     notificationsData,
     currentCategory,
     productStatus,
     quantity_in_stock,
     isExport,
     showDescriptiveSize,
     descriptive_size_amount,
     descriptive_size_unit,
     refetch,
     summaryRefetch,
     showEdit,
     showSuperuserNotifications,
     storeMe,
     searchString = '',
     removeProductHandlerKey,
     assortment,
     isDraft,
     rootCategory,
     foodlaCategory,
     brand,
     brand_food,
 }: IProductCardProps) {
    const { smUp } = useViewport();
    const [deleted, setDeleted] = useState(false);
    const [checked, setChecked] = useState(quantity_in_stock);
    const [productToRemoveId, setProductToRemoveId] = useState('');
    // Open modal if we come here with a product id in path and the id matches this card.
    const openModalOnEntry: boolean = !!selectedProductId && selectedProductId === id;
    const [modalOpen, setModalOpen] = useState<boolean>(openModalOnEntry);
    const [assortmentError, setAssortmentError] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    // const [showNotificationIcon, setShowNotificationIcon] = React.useState(false);

    const assortmentClose = `assortment-close-${id}`;

    React.useEffect(() => {
        const item = localStorage.getItem(assortmentClose);
        setShowNotification(item !== '1');
    }, []);

    React.useEffect(() => {
        if (storeMe 
            && assortment 
            && !assortment.availableOnline 
            && adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED
            && (!assortment?.comparativePriceData?.b2cPrice || (!assortment?.comparativePriceText || assortment?.comparativePriceText.trim() === ''))
        ) {
            setAssortmentError(true);
        } else {
            setAssortmentError(false);
            localStorage.removeItem(assortmentClose);
        }
    }, [adminStatus, assortment, assortmentClose, storeMe]);

    React.useEffect(() => {
        if (showNotification) {
            localStorage.removeItem(assortmentClose);
        } else {
            localStorage.setItem(assortmentClose, '1');
        }
    }, [assortmentClose, showNotification]);

    // React.useEffect(() => {
    //     if (storeMe 
    //         && assortment 
    //         && !assortment.availableOnline 
    //         && (!assortment?.comparativePriceData?.b2cPrice || (!assortment?.comparativePriceText || assortment?.comparativePriceText.trim() === ''))
    //     ) {
    //         setAssortmentError(true);
    //         if (showNotification) {
    //             setShowNotification(true);
    //             setShowNotificationIcon(false);
    //             localStorage.removeItem(assortmentClose);
    //         } else {
    //             setShowNotification(false);
    //             setShowNotificationIcon(true);
    //             localStorage.setItem(assortmentClose, '1');
    //         }
    //     } else {
    //         setAssortmentError(false);
    //         setShowNotification(false);
    //         setShowNotificationIcon(false);
    //         localStorage.removeItem(assortmentClose);
    //     }
    // }, [assortment, storeMe]);

    const [restoreProductMutation] = useMutation(MUTATION_RESTORE_PRODUCT, {
        refetchQueries: [{ query: ALL_PRODUCTS_QUERY, variables: { filter: { tagState: PRODUCT_TAGSTATES.PENDING } }}],
        awaitRefetchQueries: true,
    });

    const [updateProduct] = useMutation(MUTATION_UPDATE_OUT_OF_STOCK, {
        refetchQueries: [{ query: ALL_PRODUCTS_QUERY, variables: { filter: { tagState: PRODUCT_TAGSTATES.PENDING } } }],
        awaitRefetchQueries: true
    });

    const producerName = producer?.name;

    const restoreProduct = () => {
        restoreProductMutation({
            variables: {
                productId: id,
            },
        });
    };

    const handleCheck = (event: any) => {
        const { email, name, username } = producer;
        const quantity = event.target.checked ? 0 : 1;
        const inputFields = {
            quantity_in_stock: quantity,
            id,
            producer: { email, name, username }
        };

        updateProduct({
            variables: {
                input: inputFields,
            },
        });
        setChecked(quantity);
    };

    // Return nothing if currentCategory not equals the products state. Ex PENDING tab !== APPROVED state of product.
    if (isDefined(currentCategory) && currentCategory !== path(['state'], state))
        return <Card className={classes.emptyCard} />;

    const userHasProducerResourceRole = userResourceRole === 'producer' || userResourceRole === 'store';
    const userHasStoreResourceRole = userResourceRole === 'store';

    const onModalClosing = () => {
        handleWindowHistoryOnUserInteraction(MODAL_STATE_CLOSING);
        setModalOpen(false);
    };
    const onCardClicked = () => {
        if (!deleted) {
            if (overrideOnClickCallback) {
                overrideOnClickCallback({
                    id,
                    producerId,
                    name,
                    state,
                    producer,
                    description,
                    pictures,
                    adminStatus,
                });
                return;
            }
            handleWindowHistoryOnUserInteraction(MODAL_STATE_OPENING);
            setModalOpen(true);
        }
    };

    // We want to handle the windows path on modal opening and closing to make routing work on page refresh.
    const handleWindowHistoryOnUserInteraction = (modalState: string) => {
        const producerIdIsAvailable = !!producerId;

        let pathName;
        if (storeMe) {
            pathName = '/me/products';
        } else {
            pathName = '/products';
        }
        if (!userHasProducerResourceRole && producerIdIsAvailable) pathName = `/producers/${producerId}/products`;

        if (modalState === MODAL_STATE_OPENING) pathName = pathName.concat(`/${id}/show`);
        window.history.pushState({}, '', pathName);
    };

    // Badge information to producers.
    let badgeProps: {badgeContent?: string; invisible: boolean} = { invisible: true };
    if (isDraft) {
        badgeProps = { badgeContent: 'Draft', invisible: false };
    } else if (userHasProducerResourceRole && adminStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL) {
        badgeProps = { badgeContent: 'Under review', invisible: false };
    } else if (userHasProducerResourceRole && adminStatus === SUPERUSER_APPROVAL.ADMIN_DISAPPROVED) {
        badgeProps = { badgeContent: 'Failed review', invisible: false };
    } else if (userHasStoreResourceRole && adminStatus === SUPERUSER_APPROVAL.ADMIN_APPROVED) {
        // badgeProps = { 
        //     badgeContent: 'Gå in på produkten i ert butikssystem och ange jämförelsefaktor samt jämförelsetext för att aktivera den online.', 
        //     invisible: false 
        // };
    }

    // Let marked for removal.
    let markedForRemoval = false;
    if (userHasProducerResourceRole && productStatus === PRODUCT_STATUS.MARKED_FOR_REMOVAL) {
        markedForRemoval = true;
        badgeProps = { badgeContent: 'Awaiting removal', invisible: false };
    }

    const producerProductLinkToEdit = storeMe
        ? `/me/products/${id}/edit`
        : `/products/${id}/edit`;

    // Check if product has been updated.
    const notifications = isDefined(notificationsData) ? filter((n: any) => n.value === id, notificationsData) : [];
    let productUpdateNotification = <></>;
    if (!isDraft && !isEmpty(notifications)) {
        productUpdateNotification = <ProductCardBadge showDescriptiveSize={showDescriptiveSize} badgeContent="Updated product!" />;
    }

    let descriptive;
    if (descriptive_size_unit && descriptive_size_amount) {
        descriptive = `${descriptive_size_amount} ${getDescriptiveSizeValueFromEnum(descriptive_size_unit)}`;
    }

    const deletedStyle = deleted ? {filter: 'blur(3px) grayscale(100%)', opacity: 0.4}: {};

    const visibleEan = EAN && EAN2
        ? <>
            <div>
                <HighlightedText text={EAN} searchString={searchString} />
            </div>
            <div>
                (<HighlightedText text={EAN2} searchString={searchString} />)
            </div>
        </>
        : EAN || EAN2
            ? <HighlightedText text={EAN || EAN2} searchString={searchString} />
            : '---';

    const RemoveProductDialog = !!removeProductHandlerKey && dialogs[removeProductHandlerKey];

    const openRemoveProductModal = (id: string) => () => {
        setProductToRemoveId(id);
    };

    const closeRemoveProductModal = () => {
        setProductToRemoveId('');
    };


    const onDoneClick = () => {
        setShowNotification(false);
    };

    const secondaryTitle = mapProductSecondaryTitle({
      product: { brand, brand_food, foodlaCategory, producer },
      rootCategory,
    });

    const maxWidth = smUp ? 345 : '100%';

    const assortmentTooltip = 'Gå in på produkten i ert butikssystem och ange jämförelsefaktor samt jämförelsetext för att aktivera den online.';
    return (
        <>
            <ProductModal
                modalOpen={modalOpen}
                onModalClosing={onModalClosing}
                productId={id}
                producerId={producerId}
            />
            <div style={deletedStyle}>
                <Card key={id} className={classes.card} style={{ maxWidth }}>
                    <CardActionArea onClick={onCardClicked}>
                        <CardContent>
                            {productUpdateNotification}
                            <ProductCardBadge {...badgeProps} showDescriptiveSize={showDescriptiveSize}>
                                <Typography gutterBottom variant="subtitle1" align="center">
                                    <HighlightedText text={name} searchString={searchString} />
                                </Typography>
                                <Typography component="p" noWrap>
                                    <HighlightedText text={description} searchString={searchString} />
                                </Typography>
                            </ProductCardBadge>
                            {showDescriptiveSize && descriptive && (
                                <p
                                    style={{
                                        fontStyle: 'italic',
                                        fontFamily: 'Roboto',
                                        fontSize: 16,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 5,
                                        marginBottom: 5,
                                    }}
                                >
                                    {descriptive}
                                </p>
                            )}
                            {showDescriptiveSize && !descriptive && (
                                <p
                                    style={{
                                        fontSize: 20,
                                        visibility: 'hidden',
                                        marginTop: 5,
                                        marginBottom: 5,
                                    }}>
                                    .
                                </p>
                            )}
                        </CardContent>
                        {/* <Backdrop open={true} style={{maxWidth: 300}}>
                            <p>Gå in på produkten i ert butikssystem och ange jämförelsefaktor samt jämförelsetext för att aktivera den online.</p>
                        </Backdrop> */}
                        <CardMedia
                            style={{
                                height: 200,
                                maxWidth,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 5,
                            }}
                        >
                            {assortmentError && !showNotification && (
                                <Tooltip title={assortmentTooltip}>
                                    <ErrorOutlineIcon
                                        onClick={(e) => {e.stopPropagation(); setShowNotification(true);}}
                                        style={{
                                            position: 'absolute',
                                            right: 5,
                                            top: 5,
                                            color: '#fcc637',
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {assortmentError && showNotification && (
                                <div
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        position: 'absolute',
                                        // zIndex: 2000,
                                        // top: 0,
                                        // left: 0,
                                        // bottom: 0,
                                        // right: 0,
                                        width: '90%',
                                        backgroundColor: '#fcc637c0',
                                        padding: 10,
                                        borderColor: '#fcc637',
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderRadius: 5,
                                        textAlign: 'center',
                                        cursor: 'default',
                                        // fontWeight: 14,
                                    }}
                                >
                                    <b>{assortmentTooltip}</b>
                                    <br />
                                    <Button 
                                        size='small' 
                                        variant='outlined'
                                        startIcon={<DoneIcon />}
                                        onClick={onDoneClick}
                                    >
                                        Hide
                                    </Button>
                                </div>
                            )}
                            <ImageWithFallback style={{ maxHeight: '100%', maxWidth: '100%' }} src={pictures.medium.url}>
                                {(src: string) => {
                                    return (
                                        <img
                                            style={{
                                                maxHeight: '100%',
                                                maxWidth: '100%',
                                                borderRadius: '4px',
                                            }}
                                            src={src}
                                            alt=""
                                        />
                                    );
                                }}
                            </ImageWithFallback>
                        </CardMedia>
                    </CardActionArea>
                    <div style={{ color: 'grey', textAlign: 'center', marginTop: 8 }}>
                        {!!secondaryTitle && (
                            <div>
                                <HighlightedText text={secondaryTitle} searchString={searchString} />
                            </div>
                        )}
                        <div style={{ display: 'inline-flex' }}>
                            <div style={{ marginRight: '4px' }}>EAN:</div>
                            <div style={{ textAlign: 'left' }}>{visibleEan}</div>
                        </div>
                    </div>
                    {!deleted && userResourceRole === APPLICATION_ROLES.ADMIN && (
                        <CardActions className={classes.actionRoot}>
                            <ApproveButton
                                productId={id}
                                producerId={producer.id}
                                productState={state.state}
                                size="small"
                                usedByProducerPage={producerId != null}
                                updatedProduct={!isEmpty(notifications)}
                                refetch={refetch}
                                setDeleted={setDeleted}
                                summaryRefetch={summaryRefetch}
                            />
                        </CardActions>
                    )}
                    {(userResourceRole === APPLICATION_ROLES.PRODUCER || (userResourceRole === APPLICATION_ROLES.STORE && showEdit)) && !markedForRemoval && (
                        <CardActions className={classes.actionRoot}>
                            <Link
                                variant="inherit"
                                id='edit-product-button'
                                underline="none"
                                component={RouterLink}
                                to={producerProductLinkToEdit}
                                className={classes.editButton}
                            >
                                Edit
                            </Link>

                            <StockToggleButton quantity_in_stock={checked} handleCheck={handleCheck} />
                        </CardActions>
                    )}

                    {userResourceRole === APPLICATION_ROLES.PRODUCER && markedForRemoval && (
                        <CardActions className={classes.actionRoot}>
                            <Button onClick={restoreProduct} variant="contained" size="medium" color="primary">Restore</Button>
                        </CardActions>
                    )}

                    {removeProductHandlerKey ? (
                        <RemoveCircleIcon
                            fontSize="large"
                            className={classes.removeIcon}
                            onClick={openRemoveProductModal(id)}
                        />
                    ) : null}
                </Card>

                {productToRemoveId && RemoveProductDialog ? (
                    <RemoveProductDialog
                        closeRemoveProductModal={closeRemoveProductModal}
                        productToRemoveId={productToRemoveId}
                        setDeleted={setDeleted}
                    />
                ) : null}
            </div>
        </>
    );
}

ProductCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(ProductCard);
