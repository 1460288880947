import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CircularProgress,
    // Box,
    // Divider,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Toolbar from './OrderToolbar';
import ProductGroupList from './ProductGroupList';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GET_SELECTED_EANS } from '../graphql/queries'
import axios from 'axios';
import { useQuery } from '@apollo/react-hooks';

import { KeycloakContext } from './Secured';

const useStyles = makeStyles(theme => ({
    search: {
        justifyContent: 'center',
        display: 'flex',
    },
    noitem: {
        marginBottom: theme.spacing(10),
        textAlign: 'center',
    },
    noitemText: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    nextstep: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(1),
    },
    close: {
        padding: theme.spacing(0.5),
    },
    toolbarBox: {
        flexGrow: 1,
    },
    toolbarButton: {
        marginLeft: theme.spacing(2),
    },
}));

const ADD_ITEM = gql`
    mutation addItem($item: String!, $isAdd: Boolean, $quantity: Int) {
        addCartOrRemoveItem(item: $item, isAdd: $isAdd, quantity: $quantity) @client
    }
`;

export default function SuggestContent(props) {
    const { nextAction } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState([]);
    // TODO rename pData. Items in carts
    const { data: pData } = useQuery(GET_SELECTED_EANS);
    // const classes = useStyles();
    const keycloakCtx = useContext(KeycloakContext);
    
    const groups = keycloakCtx?.keycloak?.idTokenParsed?.groups;
    const organizations = [];
    if (groups && groups.length > 0) {
        const regex = /\/organizations\/(.*)/i;
        for (const group of groups) {
            const result = group.match(regex);
            if (result && result.length > 1) {
                organizations.push(result[1]);
            }
        }
    }
    console.log("KEYCLOAK organizations", organizations);
    useEffect(() => {
        setLoading(true);
        axios.get(`/data/get_suggested_products?organizations=${organizations.join(";")}`)
        .then((res) => {
            console.log("!!!foodladata");
            let products = [];
            if (res && res.data && !res.data.error && res.data.data) {
                products = res.data.data.products.map(x => x.sku);
                setProductData(res.data.data.products);
                setData(products);
            } else {
                // TODO: throw error
                setProductData([]);
                setData([]);
            }
        })
        .catch((e) => {
            console.error("foodladata fetch failed:", e.message);
            setData([]);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    const select = (list)=> {
        return nextAction(list);
    };

    const [addCartOrRemoveItem, { loading: cartManageLoading }] = useMutation(
        ADD_ITEM,
        {
            refetchQueries: [{ query: GET_SELECTED_EANS }],
        }
    );
    const addItem = (item, volume) => {
        console.log("VOLUME", volume);
        addCartOrRemoveItem({ variables: { item: item, isAdd: true, quantity: volume } });
    };

    if (loading)
        return <CircularProgress />;
    return (
        <>
            <Toolbar>
                <div>
                    <Button
                        onClick={() => select(data)}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        startIcon={<NavigateNextIcon />}
                    >
                        Next
                    </Button>
                </div>
            </Toolbar>

            <ProductGroupList
                list={data}
                selectAction={(item, volume) => {
                    addItem(item, volume);
                }}
                selectable={true}
                searchByEans={true}
                selected={pData?.cartItems}
                productData={productData}
            />
        </>
    );
};

SuggestContent.defaultProps = {
    nextAction: () => {},
};
