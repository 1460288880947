import React from 'react';

import Paper from '@material-ui/core/Paper';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography  from '@material-ui/core/Typography';

import VisionTab from '../tab';

const getParagraph = (paragraph) => {
  let result = "";
  for (let wordIndex = 0; wordIndex < paragraph?.words?.length; wordIndex++) {
    const word = paragraph?.words[wordIndex];
    for (let symbolIndex = 0; symbolIndex < word?.symbols?.length; symbolIndex++) {
      const symbol = word?.symbols[symbolIndex];
      if (symbol) {
        result += symbol.text;
      }
    }
    result += " ";
  }
  return result;
};

const Text = ({visionTab, image, info, isNew }) => {
  const vert = [];
  const pages = info?.fullTextAnnotation?.pages;
  if (pages) {
    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
      const page = pages[pageIndex];
      const blocks = page?.blocks;
      if (blocks) {
        for (let blockIndex = 0; blockIndex < blocks.length; blockIndex++) {
          const block = blocks[blockIndex];
          const paragraphs = block?.paragraphs;
          if (paragraphs) {
            for (let paragraphIndex = 0; paragraphIndex < paragraphs.length; paragraphIndex++) {
              const paragraph = paragraphs[paragraphIndex];
              if (paragraph) {
                vert.push({
                  label: getParagraph(paragraph),
                  coord: paragraph?.boundingBox?.vertices,
                  id: `text-${pageIndex}-${blockIndex}-${paragraphIndex}`,
                });
              } 
            }
          }
        }
      }
    }
  }
  console.log('vertices_1', vert);
  const getIds = () => {
    const result = [];
    const pages = info?.fullTextAnnotation?.pages;
    if (pages) {
      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const page = pages[pageIndex];
        result.unshift(`page-${pageIndex}`);
        const blocks = page?.blocks;
        if (blocks) {
          for (let blockIndex = 0; blockIndex < blocks.length; blockIndex++) {
            const block = blocks[blockIndex];
            result.unshift(`block-${pageIndex}-${blockIndex}`);
            const paragraphs = block?.paragraphs;
            if (paragraphs) {
              for (let paragraphIndex = 0; paragraphIndex < paragraphs.length; paragraphIndex++) {
                const paragraph = paragraphs[paragraphIndex];
                if (paragraph) {
                  result.unshift(`paragraph-${pageIndex}-${blockIndex}-${paragraphIndex}`);
                  // vert.push({
                  //   label: getParagraph(paragraph),
                  //   coord: paragraph?.boundingBox?.vertices,
                  //   id: `text-${pageIndex}-${blockIndex}-${paragraphIndex}`,
                  // });
                } 
              }
            }
          }
        }
      }
    }
    // return [];
    return result;
  };
  // const [selectedId, setSelectedId] = React.useState(undefined);
  // const [expanded, setExpanded] = React.useState(getIds());
  // const handleToggle = (event, nodeIds) => {
  //   console.log('!!!expanded', nodeIds);
  //   setExpanded(nodeIds);
  // };
  return (
    <VisionTab
      index="text"
      tabValue={visionTab}
      image={image}
      pictureWidth={7}
      vertices={vert}
      showLabels={true}
      info={info}
      isNew={isNew}
    >
      {(!info?.fullTextAnnotation?.pages || info?.fullTextAnnotation?.pages.length === 0) && (
        <Typography>No text detected.</Typography>
      )}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={getIds()}
        // expanded={expanded}
        // onNodeToggle={handleToggle}
      >
        {info?.fullTextAnnotation?.pages?.map((page, pageIndex) => (
          <TreeItem nodeId={`page-${pageIndex}`} label={`Page ${pageIndex+1}`} style={{color: 'red'}}>
            {page?.blocks?.map((block, blockIndex) => (
              <TreeItem nodeId={`block-${pageIndex}-${blockIndex}`} label={`Block ${blockIndex+1}`} style={{color: 'blue'}}>
                {block?.paragraphs?.map((paragraph, paragraphIndex) => (
                  <TreeItem nodeId={`paragraph-${pageIndex}-${blockIndex}-${paragraphIndex}`} label={`Paragraph ${paragraphIndex+1}`} style={{color: 'green'}}>
                    <Paper elevation={2}>
                      <p>{getParagraph(paragraph)}</p>
                    </Paper>
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
      {/* <div>
        {info?.fullTextAnnotation?.pages?.map((page, pageIndex) => (
          <div>
            <p><b>Page {++pageIndex}</b></p>
            {page?.blocks?.map((block, blockIndex) => (
              <div>
                <p><b>Block {++blockIndex}</b></p>
                {block?.paragraphs?.map((paragraph, paragraphIndex) => (
                  <div>
                    <p><b>Paragraph {++paragraphIndex}</b></p>
                    <p>{getParagraph(paragraph)}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}  
      </div> */}
    </VisionTab>
  );
};

export default Text;
