import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import IProduct from 'models/product';
import useSearchParams from 'hooks/useSearchParams';
import { ProductListItem } from 'pages/superuser/Superuser_ProductsApprovalPage';

import { Box, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const SUPERUSER_PRODUCTS_PAGE_LIST = 'superuser_products_page_list';

const ProductButton = styled(Button)`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  padding: 0;
  text-decoration: none;
  text-transform: initial;
  color: initial;
  > svg {
    display: block;
  };
`;

interface ProductNavigatorProps {
  id: string;
}

const ProductNavigator: FC<ProductNavigatorProps> = ({ id }) => {
  const { searchParams } = useSearchParams();

  const [previousProduct, setPreviousProduct] = useState<IProduct | null>(null);
  const [nextProduct, setNextProduct] = useState<IProduct | null>(null);

  useEffect(() => {
    setPreviousProduct(null);
    setNextProduct(null);

    if (!id || searchParams.navigator !== 'true') return;

    try {
      const productList: IProduct[] = JSON.parse(sessionStorage.getItem(SUPERUSER_PRODUCTS_PAGE_LIST) || '[]');
      const idList = productList.map(({ id }) => id);
      if (!idList.includes(id)) return;

      const idIndex = idList.indexOf(id);
      const previousIdIndex = idIndex - 1;
      const nextIdIndex = idIndex + 1;

      setPreviousProduct(productList[previousIdIndex]);
      setNextProduct(productList[nextIdIndex] || '');
    } catch {
      setPreviousProduct(null);
      setNextProduct(null);
    }
  }, [id, searchParams.navigator]);

  if (!previousProduct && !nextProduct) return null;

  const previousLink = `/superuser/products/${previousProduct?.id}/approval?navigator=true`;
  const nextLink = `/superuser/products/${nextProduct?.id}/approval?navigator=true`;

  return (
    <Box display="flex" justifyContent="space-between" gridGap={16}>
      <div>
        {!!previousProduct && (
          <ProductButton href={previousLink} style={{ paddingLeft: 16 }}>
            <ArrowBackIcon fontSize="medium" />
            <ProductListItem minimize product={previousProduct} />
          </ProductButton>
        )}
      </div>

      <div>
        {!!nextProduct && (
          <ProductButton href={nextLink} style={{ paddingRight: 16 }}>
            <ProductListItem minimize product={nextProduct} />
            <ArrowForwardIcon fontSize="medium" />
          </ProductButton>
        )}
      </div>
    </Box>
  );
};

export default ProductNavigator;
