import React from 'react';

import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Mutation } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';

import SnackBar from '../../components/snackbar/SnackBar';
import OrganizationForm from '../../components/superuser/EditOrganizationForm';
import { MUTATION_SUPERUSER_ORGANIZATION_EDIT } from '../../graphql/mutations';
import { ORGANIZATION_BY_ID_QUERY } from '../../graphql/queries';

const EditOrganization = ({ match }) => {
    const {
        data: organizationData,
        loading: organizationDataLoading,
        error: organizationDataError,
    } = useQuery(ORGANIZATION_BY_ID_QUERY, {
        variables: {
            id: match.params.id,
        },
        fetchPolicy: 'cache-and-network',
    });

    const [state, setState] = React.useState({
        loaded: false,
        name: '',
        groupIdentifier: '',
        invoiceAddress: '',
        deliveryAddress: '',
        contactInfo: '',
    });

    const handleChange = event => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    function submitEditOrganization(updateOrganization) {
        updateOrganization({
            variables: {
                input: {
                    id: match.params.id,
                    name: state.name,
                    groupIdentifier: state.groupIdentifier,
                    invoiceAddress: state.invoiceAddress,
                    deliveryAddress: state.deliveryAddress,
                    contactInfo: state.contactInfo,
                },
            },
        });
    }

    const errorLoadingOrgData =
        !organizationDataLoading && organizationDataError;
    const successLoadingOrgData =
        !errorLoadingOrgData && !organizationDataLoading && organizationData;

    if (!state.loaded && successLoadingOrgData) {
        const { organization } = organizationData;
        setState({
            loaded: true,
            name: organization.name,
            groupIdentifier: organization.groupIdentifier,
            invoiceAddress: organization.invoiceAddress,
            deliveryAddress: organization.deliveryAddress,
            contactInfo: organization.contactInformation,
        });
    }

    return (
        <>
            {organizationDataLoading && <CircularProgress />}
            {errorLoadingOrgData && <h3>Failed to load organization data.</h3>}
            {successLoadingOrgData && (
                <Mutation
                    mutation={MUTATION_SUPERUSER_ORGANIZATION_EDIT}
                    refetchQueries={[
                        {
                            query: ORGANIZATION_BY_ID_QUERY,
                            variables: { id: match.params.id },
                        },
                    ]}
                >
                    {(createOrganization, { data, loading, error }) => {
                        const errorMessage = 'Failed to update product...';
                        let feedback = <></>;
                        if (loading) feedback = <CircularProgress />;
                        if (data && !loading) {
                            const responseMessage = data?.updateOrganization?.success
                                ? data?.updateOrganization?.message
                                : errorMessage;
                            feedback = <SnackBar message={responseMessage} />;
                        }
                        return (
                            <>
                                {!loading && (
                                    <CardContent>
                                        <OrganizationForm
                                            handleOnSubmit={() =>
                                                submitEditOrganization(
                                                    createOrganization
                                                )
                                            }
                                            inputName={state.name}
                                            inputGroupIdentifier={
                                                state.groupIdentifier
                                            }
                                            inputInvoiceAddress={
                                                state.invoiceAddress
                                            }
                                            inputDeliveryAddress={
                                                state.deliveryAddress
                                            }
                                            inputContactInfo={state.contactInfo}
                                            handleChange={handleChange}
                                        />
                                        {feedback}
                                    </CardContent>
                                )}
                            </>
                        );
                    }}
                </Mutation>
            )}
        </>
    );
};

export default EditOrganization;
