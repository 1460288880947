import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import MultipleSelect from "components/inputfields/MultipleSelect";
import InputTextField from "components/inputfields/InputTextField";

import IProduct from "models/product";
import { animalCategoriesForFeed } from "../../../constants-ts";
import { isComplementarySupplements, isCompleteFeed, isFeedMixtures } from "./category";
import { IFoodlaCategoryOption } from "models/category";
import ThreeWaySwitch from "components/inputfields/ThreeWaySwitch";

interface IAnimalFieldsDataProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory?: IFoodlaCategoryOption;
};

const AnimalFields = ({ approve, state, setState, rootCategory }: IAnimalFieldsDataProps) => {
  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      const stateValue = event.target.value;

      const val = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: val });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    
    setState({ ...state, [name]: value });
  };

  return (
    <div style={{width: '100%'}}>
      <InputLabel
        heading="Djurkategori för foder"
        text="Välj vilken djurart eller djurkategori(er) som foderråvaran är avsedd för. Detta är ett märkningskrav för foder enligt förordning (EG) 776/2009."
        link="https://eur-lex.europa.eu/legal-content/SV/TXT/?uri=celex%3A32009R0767"
        required={(
          isComplementarySupplements(state?.foodlaCategory, rootCategory) ||
          isFeedMixtures(state?.foodlaCategory, rootCategory) ||
          isCompleteFeed(state?.foodlaCategory, rootCategory)
        )}
      />
      <div style={{ display: 'flex', width: '100%' }}>
        <MultipleSelect
          disableLabel={true}
          listData={animalCategoriesForFeed}
          value={state?.animalCategoriesForFeed ?? []}
          targetName="animalCategoriesForFeed"
          handleChange={handleDropdownChange}
          style={{ flexGrow: 1, overflow: 'hidden' }}
        />
        {approve && state?.approve?.find(x => x.field === 'animalCategoriesForFeed') && (
          <ThreeWaySwitch
            state={state}
            style={{ height: 56 }}
            setState={setState}
            name="animalCategoriesForFeed"
          />
        )}
      </div>
      <InputLabel
        heading="Ålderskategori"
        text="Den ålderskategori som fodret är anpassat för. Läs mer om kraven på sidan 124."
        link="https://gs1.se/wp-content/uploads/sites/2/2023/08/handledning-artikelinformation_3.1.24_1_1.pdf"
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="animalAgeGroup"
        value={state.animalAgeGroup}
        placeholder="Ex. vuxen, 5-8 år"
        onChange={handleChange}
        approve={approve && !!state?.approve?.find(x => x.field === 'animalAgeGroup')}
      />
    </div>
  );
}

export default AnimalFields;
