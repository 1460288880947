import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import withContainer from '../../hoc/withContainer';
import { Link } from 'react-router-dom';

import { SUPERUSER_STATISTICS_QUERY } from '../../graphql/queries';
import { filter } from 'ramda';
import { isNotDefined } from '../../utils/helpers';
import { SUPERUSER_APPROVAL } from '../../components/constants';
import { gql } from 'apollo-boost';

interface IProduct {
  // descriptive_size_unit?: string;
  // descriptive_size_amount?: number;
  adminStatus?: string;
}

interface IProducer {

}

interface IOrganization {

}

interface IData {
  notApprovedProductsCount?: number;
  failedProductsCount?: number;
  notApprovedProducersCount?: number;
  notExistsOrganizationsCount?: number;
}

interface ISuperuserDashboardStatisticsProps {
  data?: IData;
}


const SuperuserDashboardStatistics = ({ data }: ISuperuserDashboardStatisticsProps) => (
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <div>
            <h1>Todo list</h1>
            <h4>Products</h4>
            {!!(data?.notApprovedProductsCount && data.notApprovedProductsCount > 0) && (
              <CardActionArea component={Link} to="/superuser/products">
                <div
                  style={{
                    backgroundColor: '#e39c19',
                    borderRadius: 5,
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 22,
                      padding: 5,
                      margin: 10,
                    }}
                  >
                    {data.notApprovedProductsCount}{' '}
                  </span>
                  product(s) needs your attention.
                </div>
              </CardActionArea>
            )}
            {!data?.notApprovedProductsCount && (
              <div
                style={{
                  backgroundColor: '#56aba9',
                  borderRadius: 5,
                  width: '100%',
                  padding: 10,
                }}
              >
                All looks fine!
              </div>
            )}
            <h4>Products with failed review</h4>
            {!!(data?.failedProductsCount && data.failedProductsCount > 0) && (
              <CardActionArea component={Link} to="/superuser/failed">
                <div
                  style={{
                    backgroundColor: '#e39c19',
                    borderRadius: 5,
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 22,
                      padding: 5,
                      margin: 10,
                    }}
                  >
                    {data?.failedProductsCount}{' '}
                  </span>
                  product(s) with failed review.
                </div>
              </CardActionArea>
            )}
            {/* <h4>Descriptive size on products</h4>
            {temp.length > 0 && (
              <CardActionArea component={Link} to="/superuser/descriptivesize">
                <div
                  style={{
                    backgroundColor: '#e39c19',
                    borderRadius: 5,
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 22,
                      padding: 5,
                      margin: 10,
                    }}
                  >
                    {temp.length}{' '}
                  </span>
                  product(s) needs update of descriptive fields.
                </div>
              </CardActionArea>
            )} */}
            {!data?.failedProductsCount && (
              <div
                style={{
                  backgroundColor: '#56aba9',
                  borderRadius: 5,
                  width: '100%',
                  padding: 10,
                }}
              >
                All looks fine!
              </div>
            )}
            <h4>Producers</h4>
            {!!(data?.notApprovedProducersCount && data.notApprovedProducersCount > 0) && (
              <CardActionArea component={Link} to="/superuser/producers">
                <div
                  style={{
                    backgroundColor: '#e39c19',
                    borderRadius: 5,
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 22,
                      padding: 5,
                      margin: 10,
                    }}
                  >
                    {data?.notApprovedProducersCount}{' '}
                  </span>
                  producer(s) needs your attention.
                </div>
              </CardActionArea>
            )}
            {!data?.notApprovedProducersCount && (
              <div
                style={{
                  backgroundColor: '#56aba9',
                  borderRadius: 5,
                  width: '100%',
                  padding: 10,
                }}
              >
                All looks fine!
              </div>
            )}
            <h4>Organizations</h4>
            {!!(data?.notExistsOrganizationsCount && data.notExistsOrganizationsCount > 0) &&   (
              <CardActionArea component={Link} to="/superuser/organizations">
                <div
                  style={{
                    backgroundColor: '#e39c19',
                    borderRadius: 5,
                    width: '100%',
                    padding: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 22,
                      padding: 5,
                      margin: 10,
                    }}
                  >
                    {data.notExistsOrganizationsCount}{' '}
                  </span>
                  organization(s) needs your attention.
                </div>
              </CardActionArea>
            )}
            {(!data?.notExistsOrganizationsCount || data.notExistsOrganizationsCount === 0) && (
              <div
                style={{
                  backgroundColor: '#56aba9',
                  borderRadius: 5,
                  width: '100%',
                  padding: 10,
                }}
              >
                All looks fine!
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

// Temporary filters for handling update of descriptive_size field
// const isMissingDescSizeInfo = (allProducts?: IProduct[]) => {
//   if (allProducts) {
//     return filter((p) => isNotDefined(p.descriptive_size_unit) || isNotDefined(p.descriptive_size_amount), allProducts);
//   }
//   return [];
// };

const isFailedProductReview = (allProducts?: IProduct[]) => {
  if (allProducts) {
    return filter((p) => p.adminStatus === SUPERUSER_APPROVAL.ADMIN_DISAPPROVED, allProducts);
  }
  return [];
};

const SUPERUSER_STATISTICS = gql`
  query {
    superuserStatistics {
      notApprovedProductsCount
      failedProductsCount
      notApprovedProducersCount
      notExistsOrganizationsCount
    }
  }
`;

interface IDataResolve {
  superuserStatistics?: IData;
}

export default withContainer({
  query: SUPERUSER_STATISTICS,
  validator: (data: IDataResolve) => !!data,
  resolver: (data: IDataResolve) => ({
    notApprovedProductsCount: data?.superuserStatistics?.notApprovedProductsCount || 0,
    failedProductsCount: data?.superuserStatistics?.failedProductsCount || 0,
    notApprovedProducersCount: data?.superuserStatistics?.notApprovedProducersCount || 0,
    notExistsOrganizationsCount: data?.superuserStatistics?.notExistsOrganizationsCount || 0,
  }),
  variables: {},
}
)(SuperuserDashboardStatistics);
