import React, { FC } from 'react';

const ErrorStrongIcon: FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" color="#D32F2F" {...props}>
      <path
        d="M7.99999 1.33331C4.31999 1.33331 1.33333 4.31998 1.33333 7.99998C1.33333 11.68 4.31999 14.6666 7.99999 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 7.99999 1.33331ZM8.66666 11.3333H7.33333V9.99998H8.66666V11.3333ZM8.66666 8.66665H7.33333V4.66665H8.66666V8.66665Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ErrorStrongIcon;
